'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddVendorItemsCtrl
 * @description
 * # AddVendorItemsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddVendorItemsCtrl', function($scope, $filter, items, vendorService, products, saleOrderService, $moment, $global, growl, $uibModalInstance) {
    
    $scope.categoryNames = [];
    var a = [];
    
    // retrieve all the vendor items
    vendorService.retrieveAllItems()
      .then(function(res) {
        if (res.status === "SUCCESS" && res.data && res.data.vendorItems && res.data.vendorItems.length > 0) {
          $scope.vendorItems_list = res.data.vendorItems;
        } else {
          growl.error("Failed to retrieve all vendor products");
          console.error(res);
        }
      })
      .catch(function(err) {
        growl.error("Failed to retrieve all vendor products");
        console.error(err);
      });
    
    // retrieve all the consumer products
    products.getProducts().then(function(res) {
      if (res.status === 'SUCCESS') {
        $scope.categories = res.data.categories;
        $scope.categories.forEach(function(element, index) {
          a.push(element.name)
        });
        $scope.categoryNames = a.filter(onlyUnique);
      }
    });
    
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    
    $scope.cancel = function() {
      $uibModalInstance.close('Close');
    };
    
    $scope.addVendorItems = function() {
      let itemsData = {
        "itemName": $scope.selectedItem,
        "uom": $scope.uom,
        "selectedCategoryItem": $scope.selectedCategoryItem
      };
      
      vendorService.addVendorItems(itemsData)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            growl.success("Added Vendor Product Successfully.");
            $uibModalInstance.close('Close');
          } else {
            growl.error(res.error.message);
            console.error(res)
          }
        })
        .catch(function(err) {
          growl.error("Failed to add vendor product");
          console.error(err)
        });
    };
  });
