'use strict';

// const { init } = require("grunt");

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:WholesalersCtrl
 * @description
 * # WholesalersCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller("AssignedLeadCtrl", function ($scope,$state, $rootScope,dashboard,consumer, $global, $uibModal, $window, SellNow, growl, $log, salesAgentService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment, agentService) {

        // return;
        var searchData = {}, skip = 0, limit = 50, skipfactor = 50;
        $scope.itemsPerPage = 50;

        // ag-grid init
        (function () {
            var columnDefs = [
                {
                    headerName: "Mobile",
                    // cellRenderer: HideMobileNumber,
                    field: "mobileNo",
                    cellStyle: { 'text-align': 'center','padding-right':'30px' },
                    // checkboxSelection: true,
                },
                {
                    headerName: "Name",
                    field: "name",
                    cellStyle: { 'text-align': 'left','padding-left':'40px' }
                },
                {
                    headerName: "User Type",
                    field: "userType",
                    cellStyle: { 'text-align': 'left','padding-left':'40px' }
                },
                {
                    headerName: "Customer Type",
                    field: "categories.value",
                    cellStyle: { 'text-align': 'left','padding-left':'40px' }
                },
                {
                    headerName: "Sales Person Name",
                    field: "personIncharge.name",
                    cellStyle: { 'text-align': 'left','padding-left':'40px' }
                   
                },
                {
                    headerName: "Area",
                    field: "address.address",
                    cellStyle: { 'text-align': 'left', 'margin-left': '10px' },
                    // hide:true
                },
                {
                    headerName: "Inception Date",
                    field: "createdAt",
                    hide:true,
                    cellRenderer: DateCellRenderer,
                },
                {
                    headerName: "Status",
                    field: "leadStatus",
                    cellStyle: { 'text-align': 'left','padding-left':'30px', }
                },
                {
                    headerName: "Date",
                    field: "appointmentDate",
                    cellStyle: { 'text-align': 'left', 'margin-left': '10px' },
                    cellRenderer : AppointmentDateCellRenderer,
                    // hide:true
                },
                // {
                //     headerName: "Appointment Time",
                //     field: "appointmentTime",
                //     cellStyle: { 'text-align': 'left', 'margin-left': '10px' },
                //     cellRenderer : TimeCellRenderer,
                //     // hide:true
                // },
                {
                    headerName: "Type",
                    field: "appointmentType",
                    cellStyle: { 'text-align': 'left', 'margin-left': '10px' },
                    // hide:true
                },
                {
                    headerName: "Duration",
                    field: "appointmentDuration",
                    cellStyle: { 'text-align': 'left', 'margin-left': '10px' },
                    // hide:true
                },
                {
                    headerName: "Edit",
                    field: "0",
                    cellRenderer: EditCellRenderer,
                    reresizable: false,
                    maxWidth: 30,
                    skipHeader: true,
                    pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
                },
                {
                    headerName: "Delete",
                    field: "1",
                    cellRenderer: DeleteCellRenderer,
                    resizable: false,
                    maxWidth: 30,
                    skipHeader: true,
                    pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
                },
                {
                    headerName: "Unassign Lead",
                    field: "2",
                    cellRenderer: UnAssignCellRenderer,
                    resizable: false,
                    maxWidth: 30,
                    skipHeader: true,
                    pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
                },
                {
                    headerName: "Book Appointment",
                    field: "3",
                    cellRenderer: defaultPriceCellRenderer,
                    resizable: false,
                    maxWidth: 50,
                    skipHeader: true,
                    pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'padding': '0', 'color': "#449c45" }
                },
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true
                },

                autoGroupColumnDef: {
                    headerName: 'Athlete',
                    field: 'athlete',
                    minWidth: 250,
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                      checkbox: true,
                    },
                  },
                  rowSelection: 'multiple',
                  groupSelectsChildren: true,
                  suppressRowClickSelection: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                // paginationAutoPageSize: true,
                rowData: rowData,
                suppressCsvExport: true,
                suppressExcelExport: true,
                // suppressPaginationPanel:true,
                paginationPageSize: 50,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    params.api.sizeColumnsToFit();
                    params.columnApi.autoSizeAllColumns();
                    $scope.gridOptions.api.setRowData($scope.lead_list);
                },
                onCellClicked: function (event) {
                    if (event && event.data && event.column.colId == "0") {
                        // console.log("what is the condition....",event.data.status = "Yes")
                        if (event.data.status = "Yes") {
                            $scope.openModalAction('edit',event.data);
                        }
                    }
                    if (event && event.data && event.column.colId == "1") {
                        if(confirm("Do you want to Delete the Lead?")){
                            $scope.deleteLead(event.data);
                          }
                    }
                    if (event && event.data && event.column.colId == "2") {
                        if(confirm("Do you want to UnAssign the Lead?")){
                            // $scope.deleteLead(event.data);
                            $scope.unAssignLead(event.data);
                            // console.log("What is the event.data",event.data);
                          }
                    }
                    if (event && event.data && event.column.colId == "3") {
                        // console.log("what is the condition....",event.data.status = "Yes")
                        $scope.openModalAction('assign',event.data,'single');
                    }
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            };

        })();



        function EditCellRenderer() {
        }

        EditCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            // console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                // $scope.actionButtonText = "Pay";
                // $scope.actionButtonClassList = "";
                // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

                let iconsDiv = `<i class="md md-mode-edit"></i>`;
                this.eGui.innerHTML = iconsDiv;
                this.eGui.setAttribute('title','Edit lead')
            }
        };
        EditCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function DeleteCellRenderer() {
        }

        DeleteCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            // console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                $scope.actionButtonText = "Pay";
                $scope.actionButtonClassList = "";
                // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";
                let iconsDiv = `<i class="md md-delete"></i>`;
                this.eGui.innerHTML = iconsDiv;
                this.eGui.setAttribute('title','Delete lead')
            }
        };
        DeleteCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function UnAssignCellRenderer() {
        }

        UnAssignCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            // console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {

               let iconsDiv = `<i class="md md-assignment-ind" style="margin: 0 auto;color:red"></i>`;
                this.eGui.innerHTML = iconsDiv;
                this.eGui.setAttribute('title','Un-assign lead')
            }
        };
        UnAssignCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function defaultPriceCellRenderer() {
        }

        defaultPriceCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            // this.eGui.setAttribute('data-toggle','tooltip');
            // this.eGui.setAttribute('tooltip-popup-delay', '0');
            // this.eGui.setAttribute('data-placement', 'top');
            this.eGui.style.textAlign = 'center';
            this.eGui.style.width = '100%';
            // console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                let iconsDiv;
                // if (params.data.leadStatus == "ASSIGNED") {
                //     iconsDiv = `<i class="md md-assignment-ind" style="margin: 0 auto;color:red"></i>`;
                //     this.eGui.setAttribute('title','The default Price Capsule!')
                // } else {
                    iconsDiv = `<i class="md md-assignment-turned-in"  style="margin: 0 auto"></i>`;
                    this.eGui.setAttribute('title','Create appointment')
                // }
                this.eGui.innerHTML = iconsDiv;
            }
        };
        defaultPriceCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function DateCellRenderer() {
        }

        DateCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            if (params.data != "" || params.data != undefined || params.data != null) {
              // console.log("params.data.createdAt = ", params.data.createdAt);
              let dateModified = $moment(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss');
              // console.log("dateModified = ", dateModified);
              var iconsDiv = "<div>" + dateModified + "</div>";
              this.eGui.innerHTML = iconsDiv;
            }
        };
        DateCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };
        
        function AppointmentDateCellRenderer() {
        }

        AppointmentDateCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            if (params.data != "" || params.data != undefined || params.data != null) {
                if (params.value) {
                    // console.log("params.data.appointmentDate = ", params.data.appointmentDate);
                    let dateModified = $moment(params.data.appointmentDate).format('YYYY-MM-DD HH:mm:ss');
                    // console.log("appointmentDateModified = ", dateModified);
                    var iconsDiv = "<div>" + dateModified + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                }
            }
        };
        AppointmentDateCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        // function TimeCellRenderer() {
        // }

        // TimeCellRenderer.prototype.init = function (params) {
        //     this.eGui = document.createElement('div');
        //     console.log("what are the params for Date", params);
        //     if (params.value != undefined ) {
        //         params.value = $moment(params.value).format("HH-mm");
        //         this.eGui.innerHTML = params.value;
        //     }
        // };
        // TimeCellRenderer.prototype.getGui = function () {
        //     return this.eGui;
        // };

        // get all the sub areas of a city and send that array to sales/searchuser API request body
        const getSubAreasPromise = () => {
            return new Promise(((resolve, reject) => {
                dashboard.getAllCity()
                    .then(function (res) {
                        $scope.subAreasOfCurrentCity = [];
                        if (res.status === 'SUCCESS' && res.data && res.data.cities) {
                            $rootScope.allCityData = res.data.cities;
                            $rootScope.allCityData.forEach(function (elem, idx) {
                                if (elem.name === $rootScope.loginData.city && !$scope.subAreasOfCurrentCity.includes(elem.subarea)) {
                                    $scope.subAreasOfCurrentCity.push(elem.subarea);
                                }
                            });
                            resolve();
                        }
                    })
                    .catch(function (err) {
                        console.error("Error in fetching city details", err);
                        reject();
                    })
            }))
        };



        $scope.currentPage = 1;
        $scope.sortAscName = true;
        $scope.sortDscDate = true;


        $scope.loadLead = function (refresh) {

            if ($scope.dateSortActivated) {
                if ($scope.sortAscDate) {
                    searchData.sort = {
                        "byCreatedDate": 1
                    };
                }
                if ($scope.sortDscDate) {
                    searchData.sort = {
                        "byCreatedDate": -1
                    };
                }
            }

            $scope.totalLeads = 0;
            $scope.assignedCount = 0;
            $scope.appoinmentCount = 0;
            getSubAreasPromise()
                .then(function () { // no res needed
                    if ($scope.subAreasOfCurrentCity) {
                        searchData.subAreasOfCurrentCity = $scope.subAreasOfCurrentCity;
                    }
                    if (refresh) {
                        $scope.paging = false;
                        searchData.skip = skip;
                        searchData.limit = limit;
                    }

                    consumer.loadLead(searchData).then(function (res) {
                        // console.log("what is the Lead response", res);

                        if (res.status === "Success") {
                            $scope.initCalledOnce = true;
                            if (refresh) {
                                $scope.paging = true;
                                $scope.currentPage = 1;
                                //   $scope.totalRecords = res.total;
                                //   $scope.totalPending = res.pending;
                                //   $scope.totalCompleted = res.completed;
                                //   $scope.totalApproved = res.approved;
                            }
                            if (res.data.length) {
                                $scope.lead_list = res.data;
                                //ag-grid will display $scope.consumer_list in the data table

                                //change the date and time format of appointment
                                // $scope.lead_list.forEach((val,i) => {
                                //     $scope.lead_list[i].appointmentDate = $moment().format("DD-MMM-YYYY");
                                //     $scope.lead_list[i].appointmentTime = $moment().format("HH:mm");

                                // });
                                $scope.gridOptions.api.setRowData($scope.lead_list);

                                $scope.totalLeads = res.total;

                                res.data.forEach(val => {
                                    if(val.leadStatus == "APPOINTMENT"){
                                        $scope.appoinmentCount++;
                                    } else if(val.leadStatus == "ASSIGNED"){
                                        $scope.assignedCount++;
                                    }
                                })

                                $scope.noConsumerFound = false;
                                if (res.data.length) {
                                    $scope.lead_list = res.data;
                                    $scope.noConsumerFound = false;
                                    for (var i in res.data) {
                                        try {
                                            var addrsplit = res.data[i].address.formattedAddress.split(/\,+/);
                                            res.data[i]["area"] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                                        } catch (e) {
                                        }
                                    }
                                }
                            } else {
                                $scope.noConsumerFound = true;
                                $scope.lead_list = false;
                            }
                        } else {
                            $scope.noConsumerFound = true;
                            $scope.lead_list = false;
                        }
                    }, function (err) {
                        $scope.noConsumerFound = true;
                        $scope.lead_list = false;
                    });
                })
                .catch(function (err) {
                    console.error("Error in Lead search user API", err);
                })
        }

        // open modal to add leads
        $scope.openModalAction = function (action,userData,mode) {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-lead.html',
                controller: 'AddLeadCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    items: function () {
                        return 'CONSUMER';
                    },
                    action: function (){
                        return action;
                    },
                    userData: function (){
                        return userData;
                    },
                    mode: function () {
                        return mode;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $state.go('root.assigned-lead', {}, {reload: true});
                // reloadPage();
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $scope.loadConsumer();
            });

        };

        //Delete the Lead
        $scope.deleteLead = function(leadData){
            let data = {};
            data.id = leadData._id;
            data.mobileNo = leadData.mobileNo;
            consumer.deleteLead(data).then(function(res){
                $state.go('root.assigned-lead', {}, {reload: true});
            })
        };

        //unAssign Lead
        $scope.unAssignLead = function (leadData){
            let data = {};
            data.id = leadData._id;
            // data.name = leadData.name;
            // data.organizationName = leadData.organizationName;
            // data.categories = leadData.categories;
            // data.leadStatus = "LEAD";
            consumer.unAssignLead(data).then(function (res) {
                console.log("What is the res of unassigned lead",res);
                if (res && res.status == "Success") {
                    $state.go('root.assigned-lead', {}, {reload: true});
                }
              })


        }


         //Init function self calling
    function init() {
        searchData = {
          fromDate: null,
          toDate: null,
          leadStatus: ["ASSIGNED","APPOINTMENT"],//keep the value in array as the db query is for multiple values
          sort: {
            "byCreatedDate": -1
          },
          isApproved: false,
          skip: skip,
          limit: limit
        };
        $scope.viewsList = true;
        $scope.viewsDetails = false;
        $scope.date = {
          startDate: null,
          endDate: null
        };
        //Load the LEADS
        $scope.loadLead(true);
        $scope.nameSortActivated = false;
        $scope.dateSortActivated = true;
      }
      
      init();
    });
