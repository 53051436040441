'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ViewAccountsPayableModalCtrl
 * @description
 * # ViewAccountsPayableModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('ViewAccountsPayableModalCtrl', function($scope, $rootScope, $log, $global,$moment, $uibModalInstance, growl, $filter, viewAccountsPayableModalData, platformSetting, consumer, $uibModal, SellNow, NgMap, payoutService, $state, $timeout) {
    

    (function() {
 //     $scope.payment_modes = [{name: "Cash"}, {name: "Cheque"}, {name: "Draft"}, {name: "NEFT"}, {name: "IMPS"}];
      $scope.amountPattern = "^(-?0[.]\\d+)$|^(-?[1-9]+\\d*([.]\\d+)?)$|^0$";
      // $scope.hideModalBody = true;
      $scope.hideInputSection = true;
      $scope.hideHistoryTableTop = true;
      $scope.hideHistoryTableMid = true;
    })();

    (function() {

      $scope.dateInput = $moment().format("DD-MMM-YYYY");
      $scope.opts = {
        format: "DD-MMM-YYYY",
        singleDatePicker: true
      };
      $scope.submitBtnDisabled = false;
      $scope.invoiceId = viewAccountsPayableModalData.invoiceId;
      payoutService.getViewAccountsPayableModalDetails(viewAccountsPayableModalData.invoiceId)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            if (res.data && res.data.viewAccountsPayableModalDetails) {
              let accountsPayableResponseData = res.data.viewAccountsPayableModalDetails;
              $scope.accountsPayable = {};
              $scope.accountsPayable["pendingAmount"] = accountsPayableResponseData.pendingAmount ? accountsPayableResponseData.pendingAmount : 0;
              $scope.accountsPayable["totalCommission"] = accountsPayableResponseData.totalCommission ? accountsPayableResponseData.totalCommission : 0;
              $scope.accountsPayable["totalPayableAmount"] = accountsPayableResponseData.totalPayableAmount ? accountsPayableResponseData.totalPayableAmount : 0;
              $scope.accountsPayable["totalAmount"] = accountsPayableResponseData.totalAmount ? accountsPayableResponseData.totalAmount : 0;
              $scope.accountsPayable["totalGSTAmount"] = accountsPayableResponseData.totalGSTAmount ? accountsPayableResponseData.totalGSTAmount : 0;
              $scope.accountsPayable["itemDetails"] = accountsPayableResponseData.productDetails ? accountsPayableResponseData.productDetails : null;
              $scope.accountsPayable["pickUpDate"] = accountsPayableResponseData.pickUpDate;
              $scope.accountsPayable["createdOn"] = accountsPayableResponseData.createdOn;
              $scope.accountsPayable["paymentStatus"] = accountsPayableResponseData.paymentStatus;
              $scope.accountsPayable["totalAmountPaid"] = accountsPayableResponseData.totalAmountPaid ? accountsPayableResponseData.totalAmountPaid : 0;
              $scope.switchPaymentHistoryTable($scope.accountsPayable.paymentStatus);
              $scope.accountsPayable["paymentActivities"] = accountsPayableResponseData.paymentActivities ? accountsPayableResponseData.paymentActivities :  null;
              $scope.paymentAmount = accountsPayableResponseData.pendingAmount ? accountsPayableResponseData.pendingAmount : 0;
              // from warehouse info
              $scope.accountsPayable["fromWarehouse"] = accountsPayableResponseData.fromWarehouse;
              //console.log("$scope.accountsPayable[\"fromWarehouse\"] = ", $scope.accountsPayable["fromWarehouse"]);
              
              // billed to info
              $scope.accountsPayable["billedTo"] = accountsPayableResponseData.billedTo;
              
              // delivery to info - could toWarehouse or vendor based on TO vs SO
              $scope.accountsPayable["deliveredTo"] = accountsPayableResponseData.deliveredTo;
              $scope.accountsPayable["toWarehouse"] = accountsPayableResponseData.toWarehouse;
              
              
            } else {
              $scope.switchPaymentHistoryTable("YET TO PAY");
              $scope.accountsPayable["totalCommission"] = 0;
              $scope.accountsPayable["totalPayableAmount"] = $scope.accountsPayable["pendingAmount"];
              $scope.accountsPayable["itemDetails"] = null;
              $scope.accountsPayable["paymentActivities"] = null;
              growl.error("No Details Found");
            }
          } else {
            $scope.switchPaymentHistoryTable("YET TO PAY");
            $scope.accountsPayable["totalCommission"] = 0;
            $scope.accountsPayable["totalPayableAmount"] = $scope.accountsPayable["pendingAmount"];
            $scope.accountsPayable["itemDetails"] = null;
            $scope.accountsPayable["paymentActivities"] = null;
            growl.error("Failed");
          }
        })
        .catch(function(err) {
          console.error("Err :", err);
        });
    })();
    
    //Getting Payment Modes from platform settings
    (function(){

      $scope.payment_modes=[];
      let isVendorPayments = true;
      platformSetting.getEnabledSettings(isVendorPayments).then(function(res) {
        if (res.status==="SUCCESS") {
          if (!res.data) {
            growl.error("No Data Found");
          }
          if (res.data.platformSettings) {
            let paymentSettings = res.data.platformSettings.paymentSettings ? res.data.platformSettings.paymentSettings : [];
            paymentSettings.forEach(settings => {
              if (settings.toggleVendorPayments) {
                $scope.payment_modes.push(settings.paymentType);
              }
            });
          } else {
            growl.error("No Settings Found");
          }
        } else {
          growl.error("Error Retrieving Platform Settings Data");
        }
    })
    .catch(function(err) {
      console.error("Err :", err);
    });
    })();


    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };


    // Shift Payment History Table's position based on payment status
    $scope.switchPaymentHistoryTable = function(status) {
      $scope.hideHistoryTableMid = true;
      if(status === "PAID") {
          $scope.hideInputSection = true;
          $scope.hideHistoryTableTop = false;
      }
      else if(status === "YET TO PAY") {
          $scope.hideInputSection = false;
          $scope.hideHistoryTableTop = true;
      }
      else {
          $scope.hideInputSection = false;
          $scope.hideHistoryTableTop = true;
          $scope.hideHistoryTableMid = false;
      }
      // $scope.hideModalBody = false;
  }

  $scope.openViewAccountsPayableModal = function(invoiceId) {
    let viewAccountsPayableModalData = {};
    $timeout(function(){
      viewAccountsPayableModalData.invoiceId = invoiceId;
    
      var modalInstance = $uibModal.open({
        templateUrl: 'views/view-accounts-payable-modal.html',
        controller: 'ViewAccountsPayableModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          viewAccountsPayableModalData: function() {
            return viewAccountsPayableModalData;
          }
        }
      });
    },700);
  };
    
    // POSTs Payment Info when submit button is clicked
    $scope.makeInvoicePayment = function() {

      let invalid = false;
      if (!$scope.accountsPayable.paymentType) {
        growl.error("Select Payment Type");
        invalid = true;
      }
      if (!$scope.accountsPayable.paymentRefNo) {
      }
      if (!$scope.dateInput) {
        growl.error("Invalid characters in Date Input");
        invalid = true;
      }
      if (!$scope.invoiceId) {
        growl.error("Invalid characters in Invoice Id");
        invalid = true;
      }
      if (!$scope.accountsPayable.totalPayableAmount) {
        growl.error("Invalid characters in Payable Amount");
        invalid = true;
      }
      if (!$scope.paymentAmount) {
        growl.error("Invalid payment Amount");
        invalid = true;
      }

      $scope.currentDate = new Date();
      //dateInput can only be in the past or present
      if($moment($scope.currentDate).diff($moment($scope.dateInput))<=0){
       growl.error("Payment Date Input cannot be in the future");
       invalid = true;
      }
      $scope.dateInput = $moment($scope.dateInput._d).format("DD-MMM-YYYY");

      if($scope.paymentAmount > $scope.accountsPayable.totalPayableAmount){
        growl.error("Payment Amount can't be greater than Invoice Amount");
        invalid = true;
      }

      if($scope.paymentAmount > $scope.accountsPayable.pendingAmount){
        growl.error("Payment Amount can't be greater than Pending Amount");
        invalid = true;
      }

      if (invalid) {
        return;
      }

      let objToSend = {};
      objToSend.invoiceId = $scope.invoiceId;
      objToSend.paymentDate = $scope.dateInput;
      objToSend.amountPaid = $scope.paymentAmount;
      objToSend.paymentMode = $scope.accountsPayable.paymentType;

      if($scope.accountsPayable.paymentRefNo) {
        objToSend.paymentReferenceNumber = $scope.accountsPayable.paymentRefNo;
      }

      payoutService.payInvoiceAmount(objToSend).then(function(res) {
        //console.log("Res:", res);
        if (res.status === "SUCCESS") {
          growl.success(res.data.message);
          $uibModalInstance.dismiss("submitted");
          $scope.openViewAccountsPayableModal($scope.invoiceId);
        } else if (res.error && res.error.message) {
          growl.error(res.error.message);
          objToSend = null;
        } else {
          growl.error("Failed");
          objToSend = null;
        }
      }).catch(function(err) {
        objToSend = null;
        console.error("Err : ", err);
      });
    }
  });
