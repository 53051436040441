'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddWarehouseCtrl
 * @description
 * # AddWarehouseCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddWarehouseCtrl', function ($scope, authentication, warehouseService, wholesalerService, agentService, $window, $stateParams, $global, growl, $state, $timeout, NgMap, $rootScope) {

    /*===================================================Global variables==============================================================*/
    let citymatcher = "";
    var items = "WAREHOUSE";

    /*===================================================Default variables/service calls init==============================================================*/

    (function () {
      $scope.nameRegex = /^[a-zA-Z\s.]*$/;
      $scope.mobileRegex = /^([0-9]{10})+$/;
      $scope.cityRegex = /^[a-zA-Z\s.]*$/;
      $scope.emailRegex = /^([a-zA-Z0-9_.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      $scope.passwordRegex = /^[0-9a-zA-Z]*$/;
    })();

    // default init of view models
    (function () {
      $global.displayLog('Inside IIFE checking values for $rootScope.loginData', '$rootScope.loginData', $rootScope.loginData);
      $scope.data = {};
      $scope.vm = {};
      $scope.vm.isPrimary = true;
      $scope.location = {
        latitude: 0,
        longitude: 0
      };
      $scope.category = null;
      $scope.maps = [];
    })();

    // DI value
    (function () {
      $global.displayLog('Inside IIFE DI value for items', 'items', items);
      if (items) {
        $scope.setHeader = items;
      } else {
        console.error('Missing DI value for items');
      }
    })();

    (function () {
      $global.displayLog('Inside IIFE checking value for $rootScope.allCityData', '$rootScope.allCityData', $rootScope.allCityData);
      $rootScope.allCityData ? $rootScope.allCityData : [].forEach(function (elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
      console.log("citymatcher = ", citymatcher);
    })();

    //populate warehouse type values
    (function () {
      //assuming the ids are string
      $scope.warehouseTypeOptions = [
        { id: "0", name: 'Wholesalers' },
        { id: "1", name: 'MasterFranchise' },
        { id: "2", name: 'ScrapQ' },
        { id: "3", name: 'Agent' },
      ];
      $scope.disablePrimaryCheck = false;
    })();

    $scope.change = function () {
      $scope.disablePrimaryCheck = false;
      $scope.data.warehouseTypeValue = null;
      console.clear();
      let selectedWarehouseTypeId = $scope.data.warehouseType;

      if (selectedWarehouseTypeId === "0") { //get all wholesalers, create a new field called warehouseType value, populate it with dropdown of values
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 0', $scope.warehouseTypeOptions[$scope.data.warehouseType]);
        wholesalerService.usersLoadAll('WHOLESALER')
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = true;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.wholesalerSelectedOptions = res.data.users;
            } else {
              console.error('Failed to get a list of all wholesalers ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "1") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 1', $scope.warehouseTypeOptions[$scope.data.warehouseType]);
        warehouseService.retrieveMasterFranchise()
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.masterFranchiseOptions = res.data.users;
            } else {
              console.error('Failed to get mf user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });
      } else if (selectedWarehouseTypeId === "2") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 2', $scope.warehouseTypeOptions[$scope.data.warehouseType]);
        // $scope.scrapQOptions = [
        //   {id: "0", name: 'ScrapQ'}
        // ];
        warehouseService.retrieveSuperAdmin()
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.isScrapQOptionSelected = true;
              $scope.scrapQOptions = res.data.users;
            } else {
              console.error('Failed to get a list of all admins ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "3") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 3', $scope.warehouseTypeOptions[$scope.data.warehouseType]);

        agentService.usersLoadAll('AGENT')
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.agentOptions = res.data.users;
            } else {
              console.error('Failed to get agent user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });

      } else {
        console.error('Invalid warehouse selected ', selectedWarehouseTypeId, $scope.data.warehouseType);
        $scope.isWholesalerOptionSelected = false;
        $scope.isMasterFranchiseOptionSelected = false;
        $scope.isScrapQOptionSelected = false;
        $scope.isAgentOptionSelected = false;
      }
    };

    // init map function
    (function () {
      let map;
      NgMap.getMap()
        .then(function (evtMap) {
          map = evtMap;
        })
        .catch(function (err) {
          console.error('Failed to getMap() ', err);
        })
    })();

    /**
     * // address field can be populated from three ways
     // 1. when user starts typing and the auto-suggestion comes up where the user selects one option
     // 2. when user drags around the marker in the google map
     // 3. Entering manual text ignoring the auto-suggestion
     // we need this validation here to check the third case
     */
    const geocoderPromise = function (address) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results);
          } else {
            console.error("Failed to find lat, long for the address, please enter a better address");
            reject("Failed to find lat, long for the address, please enter a better address");
          }
        });
      })
    };

    const addWarehouseObjectPromise = function () {
      return new Promise((resolve, reject) => {
        warehouseService.addWarehouses($scope.data)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              resolve(res);
            } else {
              console.error("Failed to save warehouse object");
              reject("Failed to save warehouse object");
            }
          })
          .catch(function (err) {
            console.error('Failed to save warehouse object ', err);
            reject("Failed to save warehouse object");
          });
      });
    };

    const addWarehouseAddressPromise = function (addresses) {
      return new Promise((resolve, reject) => {
        warehouseService.saveAddress(addresses)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              resolve(res);
            } else {
              console.error("Failed to add Warehouse address");
              reject("Failed to add Warehouse address");
            }
          })
          .catch(function (err) {
            console.error('Failed to save warehouse address ', err);
            reject("Failed to add Warehouse address");
          });
      });
    };

    const getGlobalAddressObjPromise = function (address) {
      return new Promise((resolve, reject) => {
        $timeout(function () {
          let addressObj = $global.getAddressObj(address);
          if (addressObj) {
            resolve(addressObj);
          } else {
            reject('Failed to retrieve addressObj');
          }
        })
      })
    };

    /*===================================================Core functions==============================================================*/
    /**
     * We get latitude and longitude from either of the 2 approaches:
     * One is from when user starts typing in the address field, second is one when user changes drags around the marker location
     */
    $scope.addWarehouses = function () {
      let addresses;
      geocoderPromise($scope.vm.formattedAddress)
        .then(function (results) {
          return getGlobalAddressObjPromise(results[0]);
        })
        .then(function (res) {
          addresses = res;
          $scope.data.registerType = "CRM";
          $scope.data.userType = "WAREHOUSEUSER";
          $scope.data.city = $rootScope.loginData.city;
          $scope.data.formattedAddress = $scope.vm.formattedAddress;
          // if ($scope.warehouseTypeOptions[$scope.data.warehouseType].name === 'ScrapQ') {
          //   $scope.data.warehouseTypeValue = 'ScrapQ';
          // }
          $scope.data.warehouseType = $scope.warehouseTypeOptions[$scope.data.warehouseType].name;
          $scope.data.isPrimaryWarehouse = $scope.vm.isPrimary;
          return addWarehouseObjectPromise();
        })
        .then(function (res) {
          try {
            $scope.warehouseid = res.data.warehouseId;
            $scope.userid = $scope.warehouseid;
            addresses.latitude = $scope.latitude;
            addresses.longitude = $scope.longitude;
            addresses.warehouseId = $scope.userid;
            addresses.userType = $scope.data.userType;
            addresses.landmark = $scope.data.landmark;
            addresses.city = $scope.data.city;
            if ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) { // where flat number is present
              addresses.formattedAddress = $scope.vm.customadd + ', ' + $scope.vm.formattedAddress;
            } else { // where flat number is NOT present
              addresses.formattedAddress = $scope.vm.formattedAddress;
            }
            addresses.categories = [];
            return addWarehouseAddressPromise(addresses);
          } catch (err) {
            console.error('Exception in populating addresses object ', err);
          }
        })
        .then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success('Warehouse created successfully');
            // $uibModalInstance.dismiss('Ok');
            $window.history.back();
          } else {
            console.error("Failed to add Warehouse address ", res);
            growl.error("Failed to add Warehouse address");
          }
        })
        .catch(function (err) {
          growl.error("Failed to save Warehouse ", err);
        });
    };

    /*===================================================Map functions==============================================================*/

    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng)
        .then(function (res) {
          $scope.place = res;
          $scope.vm.formattedAddress = res.formatted_address;
          $scope.chosenPlace = res.formatted_address;
        })
        .catch(function (err) {
          console.error('Failed to getLocationByLatLng ', err);
        })
    }

    function reRenderMap() {
      $timeout(function () {
        angular.forEach($scope.maps, function (map) {
          const currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }

    $scope.checkForPrimary = function (id) {
      $scope.disablePrimaryCheck = false;
      let usersToCheck = null;
      if ($scope.data.warehouseType == "0") { //wholesalers
        if ($scope.wholesalerSelectedOptions && $scope.wholesalerSelectedOptions.length > 0) {
          usersToCheck = $scope.wholesalerSelectedOptions;
        }
      } else if ($scope.data.warehouseType == "1") { //MFA
        if ($scope.masterFranchiseOptions && $scope.masterFranchiseOptions.length > 0) {
          usersToCheck = $scope.masterFranchiseOptions;
        }
      } else if ($scope.data.warehouseType == "2") { // ScrapQ
        if ($scope.scrapQOptions && $scope.scrapQOptions.length > 0) {
          usersToCheck = $scope.scrapQOptions;
        }
      } else { //Agent
        if ($scope.agentOptions && $scope.agentOptions.length > 0) {
          usersToCheck = $scope.agentOptions;
        }
      }

      if (usersToCheck && usersToCheck.length > 0) {
        for (let i = 0; i < usersToCheck.length; i++) {
          if (id === usersToCheck[i].registrationId && usersToCheck[i].isPrimaryWarehouse) {
            $scope.vm.isPrimary = false;
            $scope.disablePrimaryCheck = true;
            growl.error("Primary Warehouse Already Exists");
            break;
          }
        }
      }
    }

    $scope.setCurrentLocation = function () {
      $global.getCurrentLocation()
        .then(function (res) {
          $scope.center = $scope.getLatLng(res);
          const rem = {};
          rem.lat = res.latitude;
          rem.lng = res.longitude;
          reRenderMap();
        }).catch(function (err) {
          console.error('failed to getCurrentLocation ', err);
        })

    };

    //FIXME this doesn't need to be a $scope function
    $scope.getLatLng = function (obj) {
      if (obj && obj.latitude && obj.longitude) {
        const latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      } else {
        console.error('Missing latitude and longitude in getLatLng()');
      }
    };

    $scope.placeChanged = function () {
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        const obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.latitude = obj.lat;
        $scope.longitude = obj.lng;
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";
      }
    };

    $scope.setLocation = function (obj) {
      const center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
    };

    $scope.$on('mapInitialized', function (evt, evtMap) {
      $scope.maps.push(evtMap);
    });

    //FIXME empty function body
    $scope.setLocations = function () {
      if ($scope.locationadd) {

      }
    };

    $scope.setCurrentLocation();

    $scope.markerDragEnd = function (event) {
      $timeout(function () {
        const latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

        $scope.latitude = latlng.lat;
        $scope.longitude = latlng.lng;

        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);
      })
    };

    $scope.cancel = function () {
      // $uibModalInstance.close('Close');
      $window.history.back();
    };

    $scope.searchForLocation = function () {
      let data = $scope.vm.formattedAddress;
      console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress)
      authentication.getLocations(data).then(function (res) {
        console.log("response", res);
        $scope.predictions = res.predictions;
        $scope.showPredictions = true;
      }).catch(function (err) {
        console.log("err", err);
      })
    };

    $scope.locationSelected = function (obj) {
      console.log("clicked item", obj);
      $scope.vm.formattedAddress = obj.description;
      $scope.showPredictions = false;
      authentication.getLatLng(obj.place_id).then(function (res) {
        console.log("response", res);
        if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
          let place = res.result;
          let obj = {};
          obj.lat = place.geometry.location.lat;
          obj.lng = place.geometry.location.lng;
          $scope.latitude = obj.lat;
          $scope.longitude = obj.lng;
          $scope.setLocation(obj);
        } else {
          growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
          $scope.vm.formattedAddress = "";
          $scope.chosenPlace = "";
        }
      }).catch(function (err) {
        console.log("err", err);
      });
    }
  });
