'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddLynkBookingCtrl
 * @description
 * # AddLynkBookingCtrl
 * Controller of the scrapQApp
 */


 angular.module('scrapQcrmApp')
    .controller('AddLynkBookingCtrl', function($scope, authentication, SellNow, warehouseService, logisticsService, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope) {
        $scope.data = {}
        $scope.vm = {};
        $scope.location = {
            latitude: 0,
            longitude: 0
        }
        $scope.markers = [];

        $scope.setCurrentLocation = function() {
                //alert('sfdsdfsdfsd');
            };
            var map, destmap;
            NgMap.getMap({id:'mapIdSrc'}).then(function(evtMap) {
                console.log("IN ng map")
                map = evtMap;
            });
             NgMap.getMap({id:'mapIdDest'}).then(function(evtMap) {
                destmap = evtMap;
            });
            $scope.maps = [];
            $scope.destmaps = [];
            $scope.$on('mapInitialized', function(evt, evtMap) {
                $scope.maps.push(evtMap);
            });
            $scope.reRenderMap = function() {
                $timeout(function() {
                    angular.forEach($scope.maps, function(map) {
                        // google.maps.setCenter($scope.center);
                        var currCenter = map.getCenter();
                        google.maps.event.trigger(map, 'resize');
                        map.setCenter(currCenter);
                    });
                }, 500);
            }
            $scope.reRenderMaps = function() {
                $timeout(function() {
                    angular.forEach( $scope.destmaps, function(map) {
                        // google.maps.setCenter($scope.center);
                        var currCenter = map.getCenter();
                        google.maps.event.trigger(destmap, 'resize');
                        map.setCenter(currCenter);
                    });
                }, 500);
            }
            var geocoder = new google.maps.Geocoder;

            $scope.setMap = function(location, drop) {
                if (location) {
                    var obj = {};
                    $scope.data.consumerAddressId = location.addressId;
                    obj.lat = location.latitude;
                    obj.lng = location.longitude;
                    if (drop) {
                        getAgents(obj);
                    }
                    $scope.setLocation(obj);
                } else {
                    $scope.data.consumerAddressId = null;
                }
            }
            $scope.setLocation = function(obj) {
                var center = [];
                center.push(obj.lat);
                center.push(obj.lng);
                $scope.center = center.join();
            }

            function setPlaceObject(latlng,srcOrDes) {
                console.log("srcOrDes", srcOrDes);
                $global.getLocationByLatLng(latlng).then(function(res) {
                    console.log(res, srcOrDes);
                    $scope.place = res;
                    if (srcOrDes === 'src') {
                        $scope.vm.formattedAddress = res.formatted_address;
                    } else{
                        $scope.vm.formattAddress = res.formatted_address;
                    }
                    

                })

            }
            $scope.setCurrentLocation = function() {
                $scope.srcOrDes = "dest";
                $global.getCurrentLocation().then(function(latlng) {
                    $scope.centers = latlng.lat + "," + latlng.lng;
                    $scope.reRenderMap();
                    setPlaceObject(latlng,$scope.srcOrDes);
                })

            }
            $scope.setCurrentLocations = function() {
                $scope.srcOrDes = "src";
                $global.getCurrentLocation().then(function(latlng) {
                    $scope.center = latlng.lat + "," + latlng.lng;
                    $scope.reRednerMaps();
                    setPlaceObject(latlng,$scope.srcOrDes);
                })

            }
            $scope.markerDragEnd = function(event) {
                 $scope.srcOrDes = "dest";
                $timeout(function() {
                    var latlng = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng()
                    };
                    setPlaceObject(latlng,$scope.srcOrDes);
                    $scope.centers = latlng.lat + "," + latlng.lng;
                })

            }
            $scope.markerDragedEnd = function(event) {
                $scope.srcOrDes = "src";
                $timeout(function() {
                    var latlng = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng()
                    };
                    setPlaceObject(latlng,$scope.srcOrDes);
                    $scope.center = latlng.lat + "," + latlng.lng;
                })

            }

        warehouseService.getWarehouse().then(function(res) {
            console.log("Warehouse Query sent");
            console.log(res);
            if (res.status == "SUCCESS") {
                if(res.data.warehouses.length > 1){
                    $scope.warehouses = res.data.warehouses;
                    $scope.warehouses = {
                            warehouseid: null,
                            availableOptions: res.data.warehouses
                        };
                    $scope.warehouse_list = res.data.warehouses;;
                    if(res.data.message == "No Warehouse found"){
                        $scope.noWarehouseFound = true;
                    }
                }else {
                    $scope.warehouse_list = res.data.warehouses;
                }

            } else if (res.status == "FAILURE") {
                $scope.noWarehouseFound = true;
            } else {
                $scope.noWarehouseFound = true;
            }
        }, function(err) {

        })


        // if(items.logisticsData){
        //     $scope.setHeader = items.setHeader;
        //     $scope.data.sourceAddress = items.logisticsData.sourceAddress;
        //     $scope.data.destinationAddress = items.logisticsData.mobileNo;
        //     $scope.data.sourceLatLong = items.logisticsData.sourceLatLong;
        //     $scope.data.destinationLatLong = items.logisticsData.destinationLatLong;
       	// 	$scope.data.truckCategory = items.logisticsData.truckCategory;
        //     var center = [];
        //     center.push(items.logisticsData.address.sourceLat);
        //     center.push(items.logisticsData.address.sourceLong);
        //     $scope.center = center.join();
        //     $scope.category = items.logisticsData.categories.key;
        //     $scope.location = {
        //         latitude: items.logisticsData.address.latitude,
        //         longitude: items.logisticsData.address.longitude
        //     }
        //     $scope.chosenPlace = items.logisticsData.address.address;
        // }else{
        //     $scope.setHeader = items;
        // }


        var sourceAddress,destinationAddress;
        var sourceLat;
        var sourceLong;
        var sourceLatLong, destinationLatLong;

        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };

        $scope.truckSize = ["XS", "S", "M", "L", "XL"];
        
        

        $scope.bookTrip = [{
            "sourceLatLong": "13.0447821,80.2682076",
            "destinationLatLong": "13.106744799999998,80.0969511",
            "sourceAddress": "Hari & Co, 94, Dr Radha Krishnan Salai, Jagadambal colony, Sripuram, Krishnapuram, Mylapore, Chennai, Tamil Nadu 600004, India",
            "destinationAddress": "Avadi",
            "pickUpTime": "/Date(1529504785898)/",
            "TruckCategory": "XS"
        }];

        console.log($scope.bookTrip)

        $scope.AddLynkBooking = function() {
            // console.log("IN AddLynkBooking")
            // console.log($scope.data.appointmentId)
            SellNow.getSellById($scope.data.appointmentId).then(function(res){
                if (res.status == "SUCCESS") {
                    // console.log(res.status)
                    // console.log(res.data.sell.length)
                    // if (res.data.sell.length > 1) {

                        $scope.sells = res.data.sell;
                        sourceAddress = $scope.sells.consumerAddress.formattedAddress;
                        // console.log($scope.sells.consumerAddress.formattedAddress)
                        sourceLat = $scope.sells.consumerAddress.latitude;
                        sourceLong = $scope.sells.consumerAddress.longitude;
                        sourceLatLong = sourceLat.toString()+","+sourceLong.toString();
                    // }
                }
                // console.log(sourceLatLong);
                // console.log(sourceAddress);

                warehouseService.getProfile($scope.data.warehouseId).then(function(res){
                    console.log("IN warehouse service")
                    if (res.status == "SUCCESS") {
                        $scope.warehouses = res.data;
                        destinationAddress = res.data.warehouse.address.formattedAddress;
                        destinationLatLong = res.data.warehouse.address.latitude.toString()+","+res.data.warehouse.address.longitude.toString();
                        console.log(destinationAddress)
                        if(res.data.message == "No records found"){
                            $scope.noWarehouseFound = true;
                        }
                    }

                    var truckCategory = $scope.data.truckCategory;
                    console.log($scope.data.truckCategory);

                    for (var i = 0; i < $scope.bookTrip.length; i++){
                        var tempObj = {
                            "sourceLatLong":sourceLatLong,
                            "sourceAddress":sourceAddress,
                            "destinationLatLong":destinationLatLong,
                            "destinationAddress":destinationAddress,
                            "pickUpTime":$scope.data.date,
                            "truckCategory":truckCategory
                        };
                        logisticsService.addLynkBooking(tempObj);
                        // console.log(tempObj);
                    }
                })
            })
            // logisticsService.addLynkBooking(tempObj);
        }
        
        $scope.AddNormalLynkBooking = function() {
            sourceAddress = $scope.vm.formattedAddress;
            destinationAddress = $scope.vm.formattAddress;
            sourceLatLong = $scope.center;
            destinationLatLong = $scope.centers;

            var truckCategory = $scope.data.truckCategory;
            console.log($scope.data.truckCategory);
            
            for (var i = 0; i < $scope.bookTrip.length; i++){
                var tempObj = {
                    "sourceLatLong":sourceLatLong,
                    "sourceAddress":sourceAddress,
                    "destinationLatLong":destinationLatLong,
                    "destinationAddress":destinationAddress,
                    "pickUpTime":$scope.data.date,
                    "truckCategory":truckCategory
                };
                logisticsService.addLynkBooking(tempObj);
                
            }
      
        }
    });
