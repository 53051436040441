'use strict';

/**
 * Called when
 * 1.complete request was clicked in apartment-appointments list (Doesn't work)
 * 2.complete request was clicked in appointment-modal via appointments section (Works)
 * 3.complete request was clicked in consumer-pickups-modal via consumers > detailed user > appointment section (Works)
 * 4. No call action, but there's a function that calls this modal from wholesalers module (Not in use and it won't be needed if I'm not wrong)
 * 5. Clicking on edit action button from the agents > detailed view > transaction module > edit icon for any transaction (Needs to be working)
 *
 *
 * DI values
 *
 * items: confirmationId
 * item: detailed sell obj information
 * address: consumer address
 */
angular.module('scrapQcrmApp')
  .controller('AddItemsCtrl', function($scope, item, $global, $filter, $uibModalInstance, platformSetting, authentication, agentService, SellNow, products, $state, growl, items, address) {
    
    $global.displayLog('Inside add items controller');
    $global.displayLog('item DI value ', 'item', item);
    $global.displayLog('products DI value ', 'products', products);
    $global.displayLog('items DI value ', 'items', items);
    $global.displayLog('address DI value ', 'address', address);
    
    /*===========================================default init values==================================================================*/
    $scope.items = item;
    $scope.items.agentObjId = item.agentObjId;
    // $scope.paymentModes = $global.paymentModes;
  
    /*===========================================default service calls==================================================================*/

    (function () {

      $scope.data = {};

      $scope.retrieveSellDetails = function () {
        return new Promise((resolve, reject) => {
          if (items) {
            SellNow.getSellById(items)
              .then(function (res) {
                $global.displayLog('SellNow.getSellById ', 'res', res);
                $scope.sell = res.data.sell;
                $scope.data = prePareSellItemsObj(res.data.sell);
                resolve($scope.sell);
              })
              .catch(function (err) {
                console.error('Error in getting sell item by it\'s id', err);
              });
          } else {
            console.error('Missing items value coming from DI');
          }
        });
      };

    })();

    (function () {
      if (address) {
        // For community appiontments, Use different API
        $scope.retrieveSellDetails().then(sell => {
          if (sell.communityAppointmentId) {
            // call community appintment pricing API
            const sellAddress = sell.communityAddressId;
            $scope.retrieveCommunityAppointmentsSellPricing(sellAddress);
          } else {
            // call regular sell pricing API
            $scope.retrieveSellPricing(address);
          }
        });
      } else {
        console.error('Missing address value coming in from DI');
      }

      $scope.retrieveSellPricing = function (address) {
        products.getProductsForConsumer(address.addressId)
          .then(function (res) {
            if (res.status === 'SUCCESS') {
              $scope.categories = res.data.categories;
            }
          })
          .catch(function (err) {
            console.error('Error in getting products for consumer ', err);
          });
      }

      $scope.retrieveCommunityAppointmentsSellPricing = function (addressId) {
        products.getProductsForCommunitySell(addressId)
          .then(function (res) {
            if (res.status === 'SUCCESS') {
              $scope.categories = res.data.categories;
            }
          })
          .catch(function (err) {
            console.error('Error in getting products for consumer ', err);
          });
      }
    })();

    (function (){
      platformSetting.getEnabledSettings("false").then(function(res) {
        if (res.status === "SUCCESS" && res.data && res.data.platformSettings && res.data.platformSettings.paymentSettings && res.data.platformSettings.paymentSettings.length > 0) {
            $scope.paymentSettingsOptions = res.data.platformSettings.paymentSettings;
            $scope.paymentSettingsArr = [];
            $scope.paymentSettingsOptions.forEach(function(element) {
                if (element.toggle) {
                    $scope.paymentSettingsArr.push(element.paymentType);
                }
            })
            $scope.paymentModes = $scope.paymentSettingsArr;
        }
    });
    })();
  
  
    $scope.openSellItemsModal = function() {
      $scope.showItemsGrid = true;
    };
    $scope.removeSellItem = function(index) {
      $scope.data.items.splice(index, 1);
    };
    $scope.ok = function(data) {
      $uibModalInstance.close(data);
    };
    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };
    
    function prePareSellItemsObj(items) {
      if (!items.preferredPaymentMethod) {
        items.preferredPaymentMethod = $scope.paymentModes[0].value;
      }
      var obj = {
        'sellObjId': items.sellObjId,
        'agentObjId': items.agentObjId,
        'preferredPaymentMethod': items.preferredPaymentMethod,
        'items': []
      };
      for (var i = items.items.length - 1; i >= 0; i--) {
        var category = {
          'categoryId': items.items[i].categoryId,
          'categoryName': items.items[i].categoryName,
          'image': items.items[i].image,
          'pricePerUnit': items.items[i].pricePerUnit,
          'quantity': items.items[i].quantity
        };
        obj.items.push(category);
      }
      return obj;
    }
    
    
    function checkSellItemIsAdded(categoryId) {
      return $filter('filter')($scope.data.items, {
        categoryId: categoryId
      })[0];
    }
    
    $scope.getTotoal = function() {
      var tot = 0;
      if ($scope.data && $scope.data.items)
        for (var i = $scope.data.items.length - 1; i >= 0; i--) {
          tot = tot + parseFloat($scope.data.items[i].pricePerUnit * $scope.data.items[i].quantity);
        }
      return tot;
    };
    
    $scope.addItems = function() {
      var items = $scope.modaldata;
      for (var i = items.length - 1; i >= 0; i--) {
        if (items[i] && items[i].items && items[i].items.length > 0) {
          var item = items[i].items[0];
          var category = {
            'categoryId': item.categoryId,
            'categoryName': item.name,
            'image': item.image,
            'pricePerUnit': item.price,
            'quantity': items[i].qty
          };
          if (!checkSellItemIsAdded(category.categoryId)) {
            // $scope.categories.push(categories[i]);
            $scope.data.items.push(category);
          }
        }
      }
      $scope.modaldata = [];
      $scope.showItemsGrid = false;
    };
    
    $scope.modaldata = [];
    $scope.modaldecreaseQty = function(index) {
      if ($scope.modaldata[index].qty > 1)
        $scope.modaldata[index].qty = parseInt($scope.modaldata[index].qty) - 1;
    };
    
    $scope.modalincreaseQty = function(index) {
      $scope.modaldata[index].qty = parseInt($scope.modaldata[index].qty) + 1;
    };
    
    $scope.decreaseQty = function(index) {
      if ($scope.data.items[index].quantity > 1)
        $scope.data.items[index].quantity = parseInt($scope.data.items[index].quantity) - 1;
    };
    
    $scope.increaseQty = function(index) {
      $scope.data.items[index].quantity = parseInt($scope.data.items[index].quantity) + 1;
    };
    
    $scope.decreasePrice = function(index) {
      if ($scope.data.items[index].pricePerUnit > 1)
        $scope.data.items[index].pricePerUnit = parseFloat($scope.data.items[index].pricePerUnit) - 1;
    };
    
    $scope.increasePrice = function(index) {
      $scope.data.items[index].pricePerUnit = parseFloat($scope.data.items[index].pricePerUnit) + 1;
    };
    
    $scope.updateSellItems = function() {
      SellNow.updateSellItems($scope.data)
        .then(function(res) {
          if (res.status === 'SUCCESS') {
            var obj = {
              'sellObjId': $scope.data.sellObjId,
              'agentObjId': $scope.data.agentObjId,
              'actualPaymentMethod': $scope.data.preferredPaymentMethod
            };
            $scope.items.agentObjId = true;
            console.log("OBJ from ADD ITEMS::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::---")
            console.log(obj);
            SellNow.completeSellItems(obj)
              .then(function(res) {
                if (res.status === 'SUCCESS') {
                  growl.success("Successfully Updated");
                  $scope.ok(res);
                } else {
                  if (res && res.error && res.error.errors.length > 0 && res.error.errors[0].message) {
                    growl.error(res.error.errors[0].message);
                  } else {
                    growl.error(res.error);
                  }
                }
              })
              .catch(function(err) {
                console.error('Error in completing sell items ', err);
              })
            
          } else if (res.status === 'FAILURE') {
            growl.error(res.error.message);
          }
        })
        .catch(function(err) {
          console.error('Error in updating sell items ', err);
        })
    }
  });
