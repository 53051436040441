'use strict';


/**
 * @ngdoc function
 * @name scrapQApp.controller:ConsumersCtrl
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('ConsumerCtrl', function ($scope, $rootScope, $global, pricingService, $stateParams, $http, $state, dashboard, $uibModal, $log, consumer, growl, BidService, DTOptionsBuilder, DTColumnDefBuilder, products) {

    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    $scope.pageTitle = $stateParams.pageTitle;

    if($scope.pageTitle.includes('-')) {
      $scope.pageTitle = $scope.pageTitle.replace('-', ' ');
    }

    // address type logic
    if ($stateParams.type) {
      $scope.consumerbuckettype = $stateParams.type;
      // if ($scope.consumerbuckettype == "RWA") {
      //   $scope.pageTitle = "Community";
      // }
    } else {
      $scope.consumerbuckettype = null;
    }

    $scope.enableExports = function () {
      if ($rootScope && $rootScope.loginData) {
        if ($rootScope.loginData.role && $rootScope.loginData.role === "ADMIN") {
          return false;
        }
      }
      return true
    }

    let columnDefs = [ // TODO cancel button throws error
      {
        headerName: "",
        field: "action",
        maxWidth: 30,
        cellRenderer: ActionCellRenderer
      },
      {
        headerName: "",
        field: "bookAppointment",
        maxWidth: 30,
        // cellStyle: {"color": "green"},
        cellRenderer: BookAppointmentCellRenderer
      },
      {
        headerName: "CP",
        field: "customPricingEnabled",
        maxWidth: 75,
        cellRenderer: customPriceCellRenderer,
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'], // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
          values: params => {
            const values = [new Boolean(true), new Boolean(false)];
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
        cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
      },
      {
        headerName: "Mobile Number",
        field: "mobile",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
      },
      {
        headerName: "Name",
        field: "name",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        minWidth: 150,
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "Contact",
        field: "contact",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        minWidth: 150,
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "GST",
        field: "gstNumber",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        hide: true,
        minWidth: 150,
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "PAN",
        field: "pannumber",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        hide: true,
        minWidth: 150,
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "UPI",
        field: "upiId",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        hide: true,
        minWidth: 150,
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "Type",
        field: "addressType",
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'], // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
          values: params => {
            const values = $scope.uniqueAddressTypeValues; // fetch values from server
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "Community Type",
        field: "communityCategoryTypeName",
        hide: $scope.consumerbuckettype !== 'RWA'
      },
      {
        headerName: "Area",
        field: "area",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'] // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
        },
        cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: "Source",
        field: "source",
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel'], // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
          values: params => {
            const values = $scope.uniqueSourceValues; // fetch values from server
            params.success(values); // supply values to the set filter
          }
        },
        maxWidth: 150,
        cellStyle: { 'text-align': 'center' }
      },
      {
        headerName: "Inception Date",
        field: "inceptionDate",
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'], // https://www.ag-grid.com/javascript-grid/filter-provided/#apply-clear-reset-and-cancel-buttons
          closeOnApply: true,
        },
        cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
      },
    ];

    if ($scope.consumerbuckettype === 'RWA') {

      let col = {
        headerName: "",
        field: "bookGroupAppointment",
        maxWidth: 30,
        // cellStyle: {"color": "green"},
        cellRenderer: BookGroupAppointmentCellRenderer
      };

      columnDefs.splice(2, 0, col);
    }

    $scope.gridOptions = {

      columnDefs: columnDefs,

      defaultColDef: {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true
      },

      rowStyle: { background: '#f4f4f4' },

      suppressCsvExport: $scope.enableExports(),

      suppressExcelExport: $scope.enableExports(),

      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },

      rowModelType: 'serverSide', // use the server-side row model instead of the default 'client-side'

      pagination: true,

      cacheBlockSize: 25,

      paginationPageSize: 1000,

      onGridReady: function (params) {
        $scope.gridOptions.api.setServerSideDatasource(dataSource);
      },
      onCellClicked: function (event) {
        console.log('event : ', event);
        if (event && event.column.colId === "customPricingEnabled") {
          $scope.userId = event.data.userId;
          $scope.addCustomPricing();
        } else if (event && event.data && event.column.colId === "action") {
          console.log('in esle if : ', event);
          (getConsumerProfile(event.data.userId))
            .then((val) => {
              $scope.editProfile();
            })
        } else if (event && event.data && event.column.colId === "bookAppointment") {
          getConsumerProfile(event.data.userId)
            .then((val) => {
              $scope.openconsumersCategories();
            });
        } else if (event && event.data && event.column.colId === "bookGroupAppointment") {
          getConsumerProfile(event.data.userId)
            .then((val) => {
              $scope.bookAppointment();
            });
        } else {
          $scope.consumerData = event.data;
          $scope.consumerDetails(event.data);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }

    };
    function ActionTruckCellRenderer() {
    }

    ActionTruckCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";
        let iconsDiv = `<i class="fa fa-truck" style="color:#95c53e"></i>`;

        if (params.data.preferredSlotExpired) {
          iconsDiv = `<span class="expired-anim"><i class="fa fa-truck " ></i></span>`;
        }
        this.eGui.innerHTML = iconsDiv;
      }
    };
    ActionTruckCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function BookAppointmentCellRenderer() {
    }
    BookAppointmentCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      this.eGui.style.width = '100%';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        let iconsDiv = `<i class="md md-assignment-turned-in"  style="margin: 0 auto"></i>`;
        this.eGui.setAttribute('title', 'Create appointment')
        this.eGui.innerHTML = iconsDiv;
      }
    };
    BookAppointmentCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function BookGroupAppointmentCellRenderer() {
    }
    BookGroupAppointmentCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      this.eGui.style.width = '100%';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        let iconsDiv = `<i class="md md-local-convenience-store"  style="margin: 0 auto"></i>`;
        this.eGui.setAttribute('title', 'Create group appointment')
        this.eGui.innerHTML = iconsDiv;
      }
    };
    BookGroupAppointmentCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    $scope.bookAppointment = function () {

      var data = {
        consumerId: $scope.profileEdit.consumerId,
        apartmentId: $scope.profileEdit.consumerId,
        address: $scope.profileEdit.address,
        confirmationId: ""
      };
      var modalInstance = $uibModal.open({
        templateUrl: 'views/apartment-appointment-create.html',
        controller: 'ApartmentAppointmentCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          params: function () {
            return data;
          }
        }

      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
        $scope.retriveCommunityAppointments($scope.communityId);
      }, function (res) {
        $scope.consumerDetails($scope.consumerData);
        $scope.retriveCommunityAppointments($scope.communityId);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };


    function stringStyleFormatter(params) {
      if (params.column.colId == "confirmationId") {
        if (params.data.completedAfterPreferredSlotExpired) {
          return { color: 'red', textAlign: 'center' };
        } else {
          return { textAlign: 'center' }
        }
      }
      if (params.column.colId == "status") {
        return $scope.getStatusColor(params.data);
      }
    }
    function stringFormatter(params) {
      if (params.column.colId == "requestedDate") {
        return params.data.requestedDate + " " + params.data.requestedTime
      }
      if (params.column.colId == "preferredDate") {
        return params.data.preferredDate + " " + params.data.preferredSlot;
      }
      if (params.column.colId == "GroupPreferredDate") {
        return params.data.preferredDate + " " + params.data.preferredStartTime + "-" + params.data.preferredEndTime;
      }
      if (params.column.colId == "completedTime") {
        return (params.data.completedDate ? params.data.completedDate : "") + " " + (params.data.completedTime ? params.data.completedTime : "");
      }
    }
    var appointmentsColumnDefs = [
      {
        headerName: "",
        field: "truck",
        cellRenderer: ActionTruckCellRenderer,
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "CONF.ID",
        field: "confirmationId",
        cellStyle: stringStyleFormatter,
      },
      {
        headerName: "INITIATED",
        field: "requestedDate",
        cellStyle: { textAlign: 'center' },
        valueFormatter: stringFormatter,
      },
      {
        headerName: "APPOINTMENT SLOT",
        field: "preferredDate",
        cellStyle: { textAlign: 'center' },
        valueFormatter: stringFormatter,
      },
      {
        headerName: "REO",
        field: "agentName",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "STATUS",
        field: "status",
        cellStyle: stringStyleFormatter,
      },
      {
        headerName: "FULFILLED ON",
        field: "completedTime",
        valueFormatter: stringFormatter,
        cellStyle: { textAlign: 'center' },
      }
    ];
    $scope.appointmentsGridOptions = {
      columnDefs: appointmentsColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
            $scope.appointmentsGridOptions.api.setRowData($scope.pickupsEdit);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    // Appointments Grid Init
    var GroupAppointmentsColumnDefs = [
      {
        headerName: "",
        field: "truck",
        cellRenderer: ActionTruckCellRenderer,
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "CONF.ID",
        field: "communityAppointmentId",
        cellStyle: stringStyleFormatter,
      },
      {
        headerName: "INITIATED",
        field: "requestedDate",
        cellStyle: { textAlign: 'center' },
        valueFormatter: stringFormatter,
      },
      {
        headerName: "APPOINTMENT SLOT",
        field: "GroupPreferredDate",
        cellStyle: { textAlign: 'center' },
        valueFormatter: stringFormatter,
      },
      {
        headerName: "REO",
        field: "agentName",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "STATUS",
        field: "status",
        cellStyle: stringStyleFormatter,
      },
      {
        headerName: "FULFILLED ON",
        field: "completedTime",
        valueFormatter: stringFormatter,
        cellStyle: { textAlign: 'center' },
      }
    ];
    let rowData = [];
    $scope.GroupAppointmentsGridOptions = {
      columnDefs: GroupAppointmentsColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: rowData,
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.GroupAppointmentsGridOptions && $scope.GroupAppointmentsGridOptions.api) {
            $scope.GroupAppointmentsGridOptions.api.setRowData($scope.communityAppointments);
          }
        }, 1500)
      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    function ActionCellRenderer() {
    }

    ActionCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="md md-mode-edit"></i>`;
        this.eGui.innerHTML = iconsDiv;
        this.eGui.setAttribute("title", "Edit Consumer");
      }
    };

    ActionCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function customPriceCellRenderer() {
    }

    customPriceCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      let iconsDiv;
      if (params.value) {
        iconsDiv = `<i class="md md-check" style="margin: 0 auto; color: green"></i>`;
        this.eGui.innerHTML = iconsDiv;
      } else if (params.value == false) {
        iconsDiv = `<i class="md md-info" style="margin: 0 auto;"></i>`;
        this.eGui.innerHTML = iconsDiv;
        this.eGui.setAttribute("title", "Info");
      }
    };

    customPriceCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    // implements ag-grid dataSource interface
    const dataSource = {
      getRows(params) {
        console.log("params.request = ", JSON.stringify(params.request, null, 2));

        let userAddressType = $scope.consumerbuckettype;
        let city = $rootScope.loginData.city;

        let requestBody = {};
        if (userAddressType && userAddressType !== "RWA") {
          requestBody.userAddressType = userAddressType;
        }
        if (city) {
          requestBody.city = city;
        }

        let startRow = params.request.startRow;
        let endRow = params.request.endRow;
        let sortModel = params.request.sortModel;
        let filterModel = params.request.filterModel;

        let agGridRequestParams = {};

        // pagination is present by default, no validation needed here
        agGridRequestParams.startRow = startRow;
        agGridRequestParams.endRow = endRow;

        if (sortModel && sortModel.length > 0) {
          agGridRequestParams.sortModel = sortModel;
        }

        if (filterModel) {
          agGridRequestParams.filterModel = filterModel;
          if ($scope.consumerbuckettype == "RWA") {
            agGridRequestParams.filterModel = Object.assign(agGridRequestParams.filterModel, { "addressType": { "values": ["RWA"], "filterType": "set" } })
          }
        }

        requestBody.agGridRequestParams = agGridRequestParams;

        console.log("requestBody = ", JSON.stringify(requestBody, null, 2));

        consumer.getCustomersAgGrid(requestBody)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.customerDetails && res.data.customerDetails.length > 0) {

              // badges count
              $scope.residenceCount = res.data.totalResidenceCustomersCount;
              $scope.commercialCount = res.data.totalCommercialCustomersCount;
              $scope.totalAgentRecords = res.data.totalCustomersCount; // TODO rename rename agent to customers

              // set filtering values
              $scope.uniqueAddressTypeValues = res.data.uniqueAddressTypeValues;
              $scope.uniqueSourceValues = res.data.uniqueSourceValues;

              params.successCallback(res.data.customerDetails, res.data.totalCustomersCount)
            }
            else {
              console.error(res);
              growl.error("No results found");
              params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
            }
          })
          .catch(function (err) {
            console.error(err);
            growl.error("No results found");
            params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
          })
      }
    };
    $scope.back = function () {
      $scope.consumerId = false;
    }
    $scope.getStatusColor = function (item) {
      let obj = {
        "color": "black",
        textAlign: 'center'
      };
      switch (item.status) {
        case "Assigned To REO":
          obj.color = "yellow";
          break;
        case "Accepted By Agent":
          obj.color = "blue";
          break;
        case "Request Completed":
          obj.color = "green";
          break;
        case "Cancelled By Consumer":
          obj.color = "black";
          break;
        case "Declined By Agent":
          obj.color = "red";
          break;
        case "Request Initiated":
          obj.color = "orange";
          break;
      }
      return obj;
    }
    $scope.loadConsumer = function () {
      $state.go($state.current, {}, { reload: true });
    };

    $scope.addProfileImage = function (consumerid, checkIfCorp) {
      $scope.documentconsumerid = null;

      if (!checkIfCorp) {
        checkIfCorp = null;
      }
      $scope.documentconsumerid = consumerid;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-profileimage.html',
        controller: 'ProfileImageCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return consumerid;
          },
          invokedby: function () {
            return "consumer";
          },
          corpimage: function () {
            return checkIfCorp;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
      });

    };

    consumer.userCategories().then(function (res) {
      if (res.status === "SUCCESS") {
        var categories = res.data.categories;
        $scope.categoriesList = [];
        categories.forEach(function (element, index) {
          var temp = {
            "id": element.consumerCategoryId,
            "label": element.name
          };
          $scope.categoriesList.push(temp);
        });
        // console.log($scope.categoriesList)
      } else if (res.status === "FAILURE") {
        growl.error(res.error.message)
      }

    });
    $scope.myDropdownSettings = {
      // checkBoxes: true,
      // styleActive: true,
      // checkBoxes: true,
      smartButtonTextProvider(selectionArray) {
        if (selectionArray.length === 1) {
          return selectionArray[0].label;
        } else {
          return selectionArray.length + ' Selected';
        }
      }
    };
    $scope.myDropdownModel = [];

    $scope.fetchConsumers = function () {
      // console.log($scope.myDropdownModel)
      $scope.userBucketType = [];
      $scope.user_List = "";
      if ($scope.myDropdownModel.length > 0) {
        $scope.myDropdownModel.forEach(function (element, index) {
          $scope.userBucketType.push(element.label);
          if (index === 0) {
            $scope.user_List += element.label + " + ";
          } else if (index === $scope.myDropdownModel.length - 1) {
            $scope.user_List += element.label;
          } else {
            $scope.user_List += element.label + " + ";
          }
        });
        var data = {
          "userType": "CONSUMER",
          "userBucketType": $scope.userBucketType,
          "page": $scope.vm.params.page
        };
        consumer.retrieveRegistrationsByCategories(data).then(function (res) {
          // console.log(res)
          if (res.status === "SUCCESS") {
            $scope.totalAgentRecords = res.data.totalRecords;
            $scope.consumers = res.data;
            $scope.consumerlistloading = false;
            if (res.data.users.length === 1) {
              $scope.length0 = true;
              // $scope.consumerDetails(0);
            } else {
              res.data.users.forEach(function (elem, idx) {
                try {
                  var addrsplit = elem.address.formattedAddress.split(/\,+/);
                  res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                } catch (e) {

                }
              });
              // console.log(res.data.users);
              $scope.consumer_list = res.data.users;
              $scope.consumerlistloading = false;
              $scope.length0 = false;
              $scope.consumerId = false;
            }
            if (res.data.message === "No records found") {
              $scope.noConsumerFound = true;
            }
          } else if (res.status === "FAILURE") {
            $scope.noConsumerFound = true;
          } else {
            $scope.noConsumerFound = true;
          }
        });
      } else {
        $state.go($state.current, {}, { reload: true });
      }

    };

    $scope.downloadData = function () {
      consumer.getConsumersData().then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data.fileURL != null) {
            $scope.fileHref = res.data.fileURL;
            var anchor = angular.element(document.querySelector('#fileDownloadId'));
            anchor.attr({ href: $scope.fileHref })[0].click();
          }
        }
      });
    };

    $scope.pieoptions = {
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          fontSize: 11,
        }
      }
    };


    $scope.baroptions = {
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          fontSize: 11,
        }
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            mirror: true,
            padding: 0,
            fontSize: 6,
            length: 15,
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false
          }
        }],
        gridLines: {
          display: false
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItems, data) {
            return Math.round(tooltipItems.yLabel * 100) / 100;
          }
        }
      }
    };

    $scope.colordictionary = {
      "Assigned To Agent": "#FFFF00",
      'Accepted By Agent': "#0000FF",
      'Request Completed': "#008000",
      'Cancelled By Consumer': "#000000",
      "Declined By Agent": "#FF0000",
      "Request Initiated": "#FFA500",
      "No Appointments": "#EEEEEE",
    };

    $scope.barcolors = [];

    (function (ChartJsProvider) {
      ChartJsProvider.setOptions({ colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
    });


    $scope.openGraph = function (type) {
      let data = {
        type: type,
        name: $scope.profileEdit.name,
        uniqueitems: $scope.uniqueitems,
        uniqueitemcounts: $scope.uniqueitemcounts,
        uniquestatus: $scope.uniquestatus,
        statuscounts: $scope.statuscounts,
        barcolors: $scope.barcolors,
      }
      var viewGraphModalInstance = $uibModal.open({
        templateUrl: 'views/view-graph.html',
        controller: 'ViewGraphController',
        size: "lg",
        backdrop: 'static',
        resolve: {
          data: function () {
            return data;
          }
        }
      });

      viewGraphModalInstance.result.then(function () {
      }, function () {
      });
    }

    //Get user details
    function getUserDetails(val) {
      var data = {
        mobileNo: val ? val.mobile : null
      };
      consumer.consumerDetails(data).then(function (res) {
        if (res && res.status === "Success") {
          $scope.noConsumerInfo = false;
          $scope.consumerInfo = res.data;
        } else {
          $scope.noConsumerInfo = true;
          $scope.consumerInfo = false;
        }
      }, function (err) {
        $scope.noConsumerInfo = true;
        $scope.consumerInfo = false;
      });
    }

    $scope.consumerDetails = function (agGridDataObject) {

      console.log(agGridDataObject);

      if (!agGridDataObject) {
        return;
      }

      if (agGridDataObject && agGridDataObject.addressType == "RWA") {
        $state.go("root.communities", { consumerId: agGridDataObject.userId })
      } else {
        $scope.length0 = true;
        var details = agGridDataObject;

        getUserDetails(details); //Get user details
        if (details.userId) {
          $scope.consumerId = details.userId;
          var params = {
            "consumerid": $scope.consumerId,
            "size": 30,
            "page": $scope.pages
          };

          getConsumerAppointments(params);

          // console.log(details.mobile)
          consumer.getMessages(details.mobile).then(function (res) {
            // console.log(res)
            if (res.status === "SUCCESS") {
              $scope.noMessages = true;
              $scope.messages = res.data.messagesVMS;
              if ($scope.messages.length === 0) {
                $scope.noMessages = false;
              } else {
                $scope.noMessages = true;
              }
            } else {
              $scope.noMessages = false;
            }
          });

          getConsumerProfile($scope.consumerId);
          $scope.consumer_list = false;
          consumer.refferalCode($scope.consumerId).then(function (res) {
            $scope.refferedCodes = res.data.users;
          });
          consumer.consumerSellsDetails($scope.consumerId).then(function (res) {
            if (res.status === "SUCCESS") {
              if (res.data) {
                $scope.cancelledCount = res.data.cancelledCount;
                $scope.completedRequestCount = res.data.completedRequestCount;
                $scope.transactionAmnt = res.data.transactionAmnt;
                $scope.underProcess = res.data.underProcess;
              }
            }
          });

          var complaintParams = {
            "consumerid": $scope.consumerId,
            "size": 10,
            "page": 1,

          };
          $scope.getComplaints(complaintParams);
        } else {
          $scope.consumer_list = false;
          $scope.noConsumerFound = true;
        }
      }
    };
    $scope.communityAppointments = [];
    $scope.retriveCommunityAppointments = function (communityId) {
      communityId = "HYDCHA5929";
      consumer.getCommunityAppointments(communityId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.communityAppointments = res.data.communityAppointments;
          console.log($scope.communityAppointments);
          if ($scope.GroupAppointmentsGridOptions && $scope.GroupAppointmentsGridOptions.api) {
            $scope.GroupAppointmentsGridOptions.api.setRowData($scope.communityAppointments);
          }
        }
      });
    }
    $scope.showComplaintsDetails = function (view) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/complaints-view-modal.html',
        controller: 'ComplaintViewCtrl',
        size: "md",
        backdrop: 'static',
        resolve: {
          items: function () {
            return view;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
    $scope.transactionChange = function (status) {
      var params = {
        "consumerid": $scope.consumerId,
        "size": 60,
        "page": $scope.pages,
        "status": status
      };
      getConsumerAppointments(params);
    };

    $scope.addConsumers = function () {
      consumer.setConsumerDetails();
      $state.go("root.add-consumer", { type: $scope.consumerbuckettype });
    };

    $scope.editProfile = function () {
      consumer.setConsumerDetails($scope.profileEdit);
      $state.go("root.add-consumer");
    };

    $scope.getComplaints = function (params) {
      dashboard.complaints(params).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.todayAppointment = res.data.complaints;
        }
      })
    };
    $scope.addCustomPricing = function () {
      consumer.setUserId($scope.consumerId ? $scope.consumerId : $scope.userId);
      $state.go("root.customPricing");
    };

    function getConsumerProfile(consumerId) {
      return new Promise((resolve, reject) => {
        consumer.getProfile(consumerId).then(function (res) {
          resolve(res.status);
          if (res.status === "SUCCESS") {
            $scope.profileEdit = res.data.consumer;
            $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
            // $scope.profileEdit.address.formattedAddress = $scope.profileEdit.address.formattedAddress.split(/\,+/g, "--");
            try {
              var addrsplit = $scope.profileEdit.address.formattedAddress.split(/\,+/);
              $scope.profileEdit['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
              var replaced = $scope.profileEdit.address.formattedAddress.replace(/\,+/g, "--").split("--");
              replaced.forEach(function (elem, idx) {
                if (idx % 2 === 0) {
                  replaced[idx] = replaced[idx] + "<br>";
                }
                replaced[idx] = replaced[idx].trim();
              });
              $scope.profileEdit.address.formattedAddress = replaced.join().replace(/\,/g, " ");
            }
            catch (e) {

            }
          } else if (res.status === "FAILURE") {
            $scope.noConsumerFound = "No consumer record found";
          }

        }, function (err) {
          //console.log(err);
        })
      })
    }

    $scope.pickupsApp = [];
    $scope.pickupsEdit = [];

    function parseDate(s) {
      var months = {
        jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
        jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
      };
      var p = s.split('-');
      return new Date(p[2], months[p[1].toLowerCase()], p[0]);
    }

    function getConsumerAppointments(params) {
      if (!params.page) {
        params.page = -1; // to fetch all appointment when we are in customer details page, need to fetch all because there's no pagination over there
      }
      if (!params.size) {
        params.size = 30;
      }
      if (params.status) {
        delete params.status;
      }

      $scope.uniquestatus = [];
      $scope.statuscounts = [];

      $scope.uniqueitems = [];
      $scope.uniqueitemcounts = [];
      $scope.barcolors = [];
      
      consumer.getSellRquests(params).then(function (res) {
        if (res.status === 'SUCCESS') {

          $scope.pickupsEdit = res.data.sells.reverse();

          // console.log($scope.pickupsEdit)


          if ($scope.pickupsEdit.length === 0) {
            $scope.uniquestatus.push("No Appointments");
            $scope.barcolors.push($scope.colordictionary["No Appointments"]);
            $scope.statuscounts.push(1);
          }

          $scope.pickupsEdit.forEach(function (elem, idx) {
            $scope.pickupsEdit[idx]['preferredSlotEnd'] = $scope.pickupsEdit[idx]['preferredSlot'].replace(/.*\-/, '');
            $scope.pickupsEdit[idx]['preferredSlotEndDate'] = $scope.pickupsEdit[idx]['preferredDate'] + " " + $scope.pickupsEdit[idx]['preferredSlotEnd'];
            if (moment() > moment($scope.pickupsEdit[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm') &&
              $scope.pickupsEdit[idx].status.match(/assigned|initiated|accepted|declined/i)) {
              $scope.pickupsEdit[idx]['preferredSlotExpired'] = true;
            }
            if (moment($scope.pickupsEdit[idx]['completedDate'] + " " + $scope.pickupsEdit[idx]['completedTime'], 'DD-MMM-YYYY HH:mm') > moment($scope.pickupsEdit[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm')) {
              $scope.pickupsEdit[idx]['completedAfterPreferredSlotExpired'] = true;
            }

            if ($scope.uniquestatus.indexOf($scope.pickupsEdit[idx].status) > -1) {
              var tempidx = $scope.uniquestatus.indexOf($scope.pickupsEdit[idx].status);
              $scope.statuscounts[tempidx] = $scope.statuscounts[tempidx] + 1;
            }
            else {
              $scope.uniquestatus.push($scope.pickupsEdit[idx].status);
              $scope.barcolors.push($scope.colordictionary[$scope.pickupsEdit[idx].status]);
              $scope.statuscounts.push(1);
            }

            if ($scope.pickupsEdit[idx].statusCode === "REQUEST_COMPLETED") {
              $scope.pickupsEdit[idx].items.forEach(function (elem2, idx2) {
                var tempitem = elem2.categoryName;
                var tempqty = parseFloat(elem2.quantity);
                if (!tempqty) {
                  // console.log("Invalid quantity skipping current loop");
                  return;
                }
                if ($scope.uniqueitems.indexOf(tempitem) > -1) {
                  var tempidx2 = $scope.uniqueitems.indexOf(tempitem);
                  $scope.uniqueitemcounts[tempidx2] = $scope.uniqueitemcounts[tempidx2] + tempqty;
                }
                else if (tempqty !== "0") {
                  $scope.uniqueitems.push(tempitem);
                  $scope.uniqueitemcounts.push(tempqty);
                }
                // console.log($scope.uniqueitems, $scope.uniqueitemcounts)
              });
            }
          });

          if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
            $scope.appointmentsGridOptions.api.setRowData($scope.pickupsEdit);
          }

        } else {
          $scope.uniquestatus.push("No Appointments");
          $scope.barcolors.push($scope.colordictionary["No Appointments"]);
          $scope.statuscounts.push(1);
        }
      }, function (err) {
        //$ionicLoading.hide();
      })
    }

    $scope.dtOptions = DTOptionsBuilder.newOptions().withOption('lengthMenu', [100, 150, 200]);
    // .withDisplayLength($scope.length);

    $scope.viewMore = function () {
      $scope.pages += 1;
      var params = {
        "consumerid": $scope.consumerId,
        "size": 10,
        "page": $scope.pages
      };
      getConsumerAppointments(params);

    };

    $scope.OpenRequest = function (pickup) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/consumer-pickups-modal.html',
        controller: 'ConsumerPickupsModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return pickup;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
        //$scope.allRequests($scope.vm.params);
      });
    };

    $scope.openLocation = function () {
      var address = $scope.profileEdit.address;
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/openlocation.html',
        controller: 'OpenLocationModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {

            return address;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
        //$scope.allRequests($scope.vm.params);
      });
    };

    function getConsumerBids(params) {
      BidService.getBids(params).then(function (res) {
        if (res.status === 'SUCCESS') {

          $scope.BidsDetails = res.data.bids;


        }
      }, function (err) {
        //$ionicLoading.hide();
      })
    }

    $scope.getConsumer = function () {
      $scope.consumer = {};
      consumer.getConsumerByText($scope.searchText).then(function (res) {
        if (res.status === "success")
          $scope.consumer = res.data;
        else
          growl.error(res.error.message);
      })
    };

    $scope.dataset = false;
    $scope.openconsumersCategories = function () {
      var modalInstance = "";

      var data = {
        consumerId: $scope.profileEdit.consumerId,
        apartmentId: $scope.profileEdit.consumerId,
        address: $scope.profileEdit.address,
        confirmationId: ""
      };

      modalInstance = $uibModal.open({
        templateUrl: 'views/sells-consumers.html',
        controller: 'SellConsumerCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          ids: function () {
            return data;
          }
        }

      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function (res) {
        $scope.consumerDetails($scope.consumerData);
        var params = {
          "consumerid": $scope.consumerId,
          "size": 40,
          "page": $scope.pages,
          "status": "pending"
        };
        getConsumerAppointments(params);
        $log.info('Modal dismissed at: ' + new Date());
      });


    };

    $scope.addAppointmentCategories = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/addconsumers.html',
        controller: 'AddConsumerCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          ids: function () {
            return $scope.consumerId;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function (res) {
        $scope.consumerDetails($scope.consumerData);
        var params = {
          "consumerid": $scope.consumerId,
          "size": 60,
          "page": $scope.pages,
          "status": "pending"
        };
        getConsumerAppointments(params);
        $log.info('Modal dismissed at: ' + new Date());

      });

    };
    $scope.addbidCategories = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/addbids.html',
        controller: 'AddBidCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          ids: function () {
            return $scope.consumerId;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function (res) {
        $scope.consumerDetails($scope.consumerData);
        if (res === 'ok') {
          var params = {
            "consumerid": $scope.consumerId,
            "size": 30,
            "page": $scope.pages,
            "status": "pending"
          };
          getConsumerBids(params);
          $log.info('Modal dismissed at: ' + new Date());
        }
      });
    };

    $scope.sendData = function (pickup) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/consumer-pickups-modal.html',
        controller: 'ConsumerPickupsModalCtrl',
        size: "lg",
        resolve: {
          items: function () {

            return pickup;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
        var params = {
          "consumerid": $scope.consumerId,
          "size": 30,
          "page": $scope.pages,
          "status": "pending"
        };
        getConsumerAppointments(params);
      });
      pickupInstance.closed.then(function (selectedItem) {
        $scope.selected = selectedItem;

      }, function () {

        $log.info('Modal dismissed at: ' + new Date());
        var params = {
          "consumerid": $scope.consumerId,
          "size": 30,
          "page": $scope.pages,
          "status": "pending"
        };
        getConsumerAppointments(params);
      });

    };
    $scope.detailBid = function (bid) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/consumer-bids-modal.html',
        controller: 'ConsumerBidsModalCtrl',
        size: "md",
        resolve: {
          items: function () {

            return bid;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };

    $scope.editCustomPrice = function (price) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/edit-customPrice-modal.html',
        controller: 'EditCustomPriceCtrl',
        size: "lg",
        resolve: {
          items: function () {

            return price;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };

    $scope.deleteCustomPrice = function (price) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/delete-customPrice-modal.html',
        controller: 'DeleteCustomPriceCtrl',
        size: "lg",
        resolve: {
          items: function () {

            return price;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.consumerDetails($scope.consumerData);
      }, function () {
        $scope.consumerDetails($scope.consumerData);
        $scope.retrieveAllCustomPrices($scope.consumerId);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };

    $scope.disableUpdateButton = true;
    $scope.keyUpFlag = false;

    $scope.changeKeyUpFlag = function (edit) {
      if ($scope.keyUpFlag === false) {
        $scope.keyUpFlag = true;
        // console.log("Changing keyUp flag to " + $scope.keyUpFlag);
      }
      // if keyUp is true and edit is true,
      // then disableUpdateButton should be false, so that we can call the API
      // however, when we return from the api call we disable the update button by default
      // else false
      if ($scope.keyUpFlag === true && edit === true && $scope.disableUpdateButton === true) {
        $scope.disableUpdateButton = false;
        // console.log("changing disableUpdateButton to " + $scope.disableUpdateButton);
        // console.log("Now update all should be enabled");
      }
    };

    $scope.changeDisableUpdateButton = function (edit) {
      // console.log("edit value : " + edit);
      if (edit === true) {
        $scope.disableUpdateButton = true;
        console.log("changing disableUpdateButton to " + $scope.disableUpdateButton);
      }
    };

    $scope.editAllCustomPrice = function () {
      for (var i = 0; i < $scope.customPrice_list.length; i++) {

        var price = $scope.customPrice_list[i];
        // console.log(price);

        if (price.edited === true) {
          products.editCustomPrice(price).then(function (response) {
            // console.log(response);
            if (response.status === "SUCCESS") {
              growl.success("Successfully updated prices");
              //for redirection
              // console.log("$scope.consumerId ", $scope.consumerId);
              $scope.retrieveAllCustomPrices($scope.consumerId);
            } else if (response.status === "FAILURE") {
              growl.error(response.error.code);
            }
          });
        }
      }

      //after sending out the API calls disable the update all button
      $scope.disableUpdateButton = true;
    };

    $scope.retrieveAllCustomPrices = function (consumerId) {
      pricingService.retrieveCustomPriceForConsumer(consumerId).then(function (res) {
        if (res.status === "SUCCESS") {
          // console.log('res', res.status);
          // console.log("Retrieving all custom prices :", res);
          if (res.data.customPrice) {
            $scope.customPrice_list = res.data.customPrice;
            // console.log("$scope.customPrice_list: ", $scope.customPrice_list);
          }
        }
      });
    }

  });
