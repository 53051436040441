'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:DeleteAreaCtrl
 * @description
 * # DeleteAreaCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('DeleteAreaCtrl', function($scope, $rootScope, $moment,$uibModalInstance, area, items, $global, $uibModal, $window, growl, $log , $stateParams) {
    	
        $scope.cancel = function(res) {
            $uibModalInstance.dismiss(res);
            console.log(res)
        };
   
        $scope.areaDetails = items;   
        $scope.deleteArea = function(){
            area.deleteArea($scope.areaDetails.id).then(function(res){
                console.log(res)
                if (res.status=="SUCCESS") {
                    growl.success("Area deleted successfully!");
                    $uibModalInstance.dismiss(res);
                }else{
                    growl.error("Area could not be deleted!");
                }
            })
        };
        
    });