'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.paymentService
 * @description
 * # paymentService
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('paymentService', function(httpService, $global, $http, $rootScope) {
        return {
           loadPayments: function(type, page, startDate, endDate, nodecrement) {
                var url;
                if (page) {
                    if (!nodecrement) {
                        page = page - 1;
                    }
                    url = $global.getApiUrl() + $global.getApiObject().getAllPayments + "?startDate=" + startDate + "&endDate=" + endDate;
                } else {
                    url = $global.getApiUrl() + $global.getApiObject().getAllPayments + "?startDate=" + startDate + "&endDate=" + endDate;
                }
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },getPayment: function(confirmationId) {
                var confirmationId = confirmationId;
                var url = $global.getApiUrl() + $global.getApiObject().getPayment.replace(":id", confirmationId);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },addWallet: function(data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addWallet;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },getWallets: function(type, page, nodecrement) {
                var url;
                if (page) {
                    if (!nodecrement) {
                        page = page - 1;
                    }
                    url = $global.getApiUrl() + $global.getApiObject().getWallets;
                } else {
                    url = $global.getApiUrl() + $global.getApiObject().getWallets;
                }
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },twalletOtp: function(confirmationId) {
                var confirmationId = confirmationId;
                var url = $global.getApiUrl() + $global.getApiObject().twalletOtp.replace(":id", confirmationId);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },twalletOtpPayment: function(confirmationId,otp) {
                var confirmationId = confirmationId;
                var url = $global.getApiUrl() + $global.getApiObject().twalletOtpPayment.replace(":id", confirmationId).replace(":otp",otp);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            }
        };
    });
