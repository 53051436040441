'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:CompleteCustomerRegistrationCtrl
 * @description
 * # AddUserCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('CompleteCustomerRegistrationCtrl', function($scope, area, authentication, wholesalerService, warehouseService, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope, selectedConsumer) {
    $scope.data = {};
    $scope.vm = {};
    $scope.location = {
      latitude: 0,
      longitude: 0
    };
  
    // default init
    $scope.isSearchDisabled = false;
    $scope.isAddressTypeDisabled = false;
    $scope.isAddressSearchDisabled = false;
    
    $scope.category = null;
  
    (function(){
      $scope.categories = [];
      consumer.userCategories().then(function(res) {
        if (res.status === "SUCCESS") {
          $scope.categoriesList = res.data.categories;
        } else if (res.status === "FAILURE") {
          growl.error(res.error.message)
        }
    
      })
    })();
    
    (function(){
      if (selectedConsumer) {
        $scope.data.name = selectedConsumer.name;
        $scope.data.mobileNo = selectedConsumer.mobile;
        console.log("selectedConsumer ", selectedConsumer);
        console.log("selectedConsumer.latitude = ", selectedConsumer.latitude);
        console.log("selectedConsumer.longitude = ", selectedConsumer.longitude);
        if (selectedConsumer.latitude && selectedConsumer.longitude) { // to render existing address for "invalid city" use case
          $scope.defaultCoordinatesString = selectedConsumer.latitude + ", " + selectedConsumer.longitude;
          $scope.vm.formattedAddress = selectedConsumer.formattedAddress;
        }
      } else {
        console.error("Failed to retrieve selectedConsumer: ", selectedConsumer)
      }
    })();
  
    // populate existing addresses
    (function() {
      if (selectedConsumer && selectedConsumer.userId) {
        consumer.getAddress(selectedConsumer.userId)
          .then(function(res) {
            $scope.existingAddresses = [];
            res.data.addresses.forEach(function(address, idx) {
              try {
                if (res.data.addresses[idx]["defaultAddress"] === "YES") {
                  $scope.defaultLocation = address['addressId'];
                  $scope.category = address['categories'][0]['key'];
                }
              }
              catch (e) {
                console.error(e);
              }
            });
            $scope.existingAddresses = res.data.addresses;
            console.log("$scope.existingAddresses = ", $scope.existingAddresses);
          })
          .catch(function(err) {
            console.error("Failed to retrieve address", err);
          })
      } else {
        console.error("Failed to retrieve address");
      }
    })();
  
    // Watch for defaultLocation changes
    $scope.$watch('defaultLocation', function(newValue, oldValue) {
      if (!$scope.defaultLocation) {
        $scope.vm.formattedAddress = null;
        $scope.defaultCoordinatesString = null;
        $scope.category = null;
        $scope.isSearchDisabled = false;
        $scope.isAddressTypeDisabled = false;
        $scope.isAddressSearchDisabled = false;
        $scope.submitBtnDisabled = false;
      } else if ($scope.existingAddresses) {
        $scope.existingAddresses.forEach(function(eachAddress) {
          if (eachAddress.addressId === newValue) {
            console.log("eachAddress = ", eachAddress);
            $scope.vm.formattedAddress = eachAddress.formattedAddress;
            $scope.isSearchDisabled = true;
            $scope.isAddressSearchDisabled = true;
            $scope.isAddressTypeDisabled = true;
            $scope.submitBtnDisabled = true;
            $scope.defaultCoordinatesString = null;
            $scope.category = eachAddress['categories'][0]['key'];
            $timeout(function() {
              let obj = {};
              obj.lat = eachAddress.latitude;
              obj.lng = eachAddress.longitude;
              $scope.setLocation(obj);
            }, 250);
          }
        })
      }
    
    }, false);
    

    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    
    var citymatcher = "";
    if ($rootScope.allCityData && $rootScope.allCityData.length > 0) {
      $rootScope.allCityData.forEach(function(elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
    }


    $scope.checkradio = function() {
      setTimeout(function() {
        var category = $scope.categories[$scope.categories.length - 1];
        $scope.categories = [];
        $scope.categories.push(category);
      }, 300);
    };

    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng).then(function(res) {
        console.log("$global.getLocationByLatLng",res);
        $scope.place = res;
        $scope.vm.formattedAddress = res.formatted_address;
        $scope.chosenPlace = res.formatted_address;
      })
    }

    function reRenderMap() {
      $timeout(function() {
        angular.forEach($scope.maps, function(map) {
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }

    $scope.setCurrentLocation = function() {
      $global.getCurrentLocation().then(function(res) {
        $scope.center = $scope.getLatLng(res);
        var rem = {};
        rem.lat = res.latitude;
        rem.lng = res.longitude;
        reRenderMap();
      });
    };

    $scope.getLatLng = function(obj) {

      if (obj && obj.latitude && obj.longitude) {
        var latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      }

    };
    //$scope.setCurrentLocation();

    $scope.placeChanged = function() {
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        var obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";
      }
    };

    $scope.setLocation = function(obj) {
      var center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
    };

    var map;
    $scope.maps = [];
    $scope.$on('mapInitialized', function(evt, evtMap) {
      $scope.maps.push(evtMap);
    });


    NgMap.getMap().then(function(evtMap) {
      map = evtMap;
    });
    $scope.setLocations = function() {
      if ($scope.locationadd) {

      }
    };
    $scope.setCurrentLocation();
    $scope.markerDragEnd = function(event) {
      $timeout(function() {
        var latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);

      })
    };

    $scope.cancel = function() {
      $uibModalInstance.close('Close');
    };
  
    // Watch for category changes
    $scope.$watch('category', function(newValue) {
      console.log("Inside category watch");
      console.log("$scope.data.categories (before) = ", $scope.data.categories);
      $scope.categories = [];
      $scope.categories.push($scope.category);
      $scope.data.categories = [];
      angular.forEach($scope.categories, function(value, key) {
        angular.forEach($scope.categoriesList, function(catvalue, key) {
          if (value === catvalue.consumerCategoryId)
            $scope.data.categories.push({
              key: catvalue.consumerCategoryId,
              value: catvalue.name
            })
        });
      });
      console.log("$scope.data.categories (after) = ", $scope.data.categories);
    }, false);
  
    $scope.addUsers = function() {
      $scope.submitBtnDisabled = true;
      $scope.data.city = $rootScope.loginData.city;
      $scope.data.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
      console.log("$scope.data (request body) = ", $scope.data);
      consumer.completeInvalidRegistration($scope.data).then(function(res) {
        if (res.status === "SUCCESS") {
          growl.success("Registration Successful");
          $uibModalInstance.dismiss('Ok');
        } else {
          console.error(res);
          growl.error("Registration Failed");
        }
      });
    };
  
    $scope.searchForLocation = function(){
      let data=$scope.vm.formattedAddress;
      console.log("$scope.vm.formattedAddress",$scope.vm.formattedAddress);
      authentication.getLocations(data).then(function(res){
        console.log("response",res);
        $scope.predictions=res.predictions;
        $scope.showPredictions=true;
      }).catch(function(err){
        console.log("err",err);
      })
    };
  
    $scope.locationSelected=function(obj){
      console.log("clicked item",obj);
      $scope.defaultCoordinatesString = null;
      $scope.vm.formattedAddress=obj.description;
      $scope.showPredictions=false;
      authentication.getLatLng(obj.place_id).then(function(res){
        console.log("response",res);
        if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
          let place=res.result;
          let obj = {};
          obj.lat = place.geometry.location.lat;
          obj.lng = place.geometry.location.lng;
          $scope.setLocation(obj);
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({'address': $scope.vm.formattedAddress}, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              var addresses = $global.getAddressObj(results[0]);
              if (addresses) {
                $scope.data.latitude = addresses.latitude;
                $scope.data.longitude = addresses.longitude;
                $scope.data.postalCode = addresses.postalCode;
                $scope.data.country = addresses.country;
                $scope.data.state = addresses.state;
                $scope.data.locality = addresses.locality;
              }
              $scope.data.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
            }
          })
        } else {
          growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
          $scope.vm.formattedAddress = "";
          $scope.chosenPlace = "";
        }
      }).catch(function(err){
        console.log("err",err);
      });
    }
    
    // TODO we need this for invalid city use case
    // $scope.addLocation = function(address, latitude, longitude) {
    //
    //   var addresses = $global.getAddressObj(address);
    //
    //   //override geocoded lat, long
    //   if (latitude) {
    //     addresses.latitude = latitude;
    //     addresses.longitude = longitude
    //   }
    //
    //   addresses.userId = $scope.userid;
    //   addresses.userType = $scope.data.userType;
    //   addresses.landmark = $scope.data.landmark;
    //   addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
    //   //addresses.formattedAddress = address.formatted_address;
    //   authentication.saveCosumerAddress(addresses).then(function(res) {
    //   })
    //
    // };
  });
