'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.WarehouseInventoryReports
 * @description
 * # warehouseInventoryService
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('warehouseInventoryService', function(httpService, $global, $http, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      getWarehouseInventories: function(obj) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getWarehouseInventoryReports.replace(":city", city);
        if (obj.page || obj.page>=0) {
            url = url + "&page=" + obj.page;
        }

        if(obj.size){
            url = url + "&size="+ obj.size;
        }

        if(obj.keyword){
            url = url + "&keyword="+ obj.keyword;
        }
        if(obj.sort){
            url = url + "&sort="+ obj.sort;
        }

        if(obj.id) {
          url = url + "&warehouseId=" + obj.id;
        }
  
        if ($rootScope.loginData.role === "WAREHOUSEUSER") {
          url = url + "&warehouseId="+ $rootScope.loginData.warehouseId;
        }
        
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getBalanceSheetForWarehouse: function(warehouseId, itemName, city, page) {
        console.log("in service layer",city);
        
        if (page) {
          page -= 1; //because pagination buttons are 1-based in the CRM and 0-based in the API
        } else {
          page = 0;
        }
        let size = 10;
        let url = $global.getApiUrl() + $global.getApiObject().getBalanceInventoryForWarehouse;
        url += "?city=" + city + "&warehouseId=" + warehouseId + "&itemName=" + itemName ;
        url += "&page=" + page + "&size=" + size;
        let $request = httpService.httpRequest(url, "G", "");
        return $request;
      },getProductInventories: function(obj) {
          var city = $rootScope.loginData.city;
          var url = $global.getApiUrl() + $global.getApiObject().getProductInventoryReports.replace(":city", city);
          if (obj.page || obj.page>=0) {
              url = url + "&page=" + obj.page;
          }

          if(obj.size){
              url = url + "&size="+ obj.size;
          }

          if(obj.keyword){
              url = url + "&keyword="+ obj.keyword;
          }
          if(obj.sort){
              url = url + "&sort="+ obj.sort;
          }
          if(obj.productCategory){
              url=url+"&productCategory="+obj.productCategory;
          }
          var $request = httpService.httpRequest(url, "G", "");
          return $request;
      },getCategories: function () {
          var city = $rootScope.loginData.city;
          var url = $global.getApiUrl() + $global.getApiObject().getProductCategories.replace(":city", city);
          var $request = httpService.httpRequest(url, "G", "");
          return $request;
      },getProductCategoriesWithIds: function () {
          var city = $rootScope.loginData.city;
          var url = $global.getApiUrl() + $global.getApiObject().getProductCategoriesWithIds.replace(":city", city);
          var $request = httpService.httpRequest(url, "G", "");
          return $request;
      },retrieveValidatedWarehouses: function(data) {
        data.city = $rootScope.loginData.city;
				var url = $global.getApiUrl() + $global.getApiObject().validateClearanceWarehouses;
				var $request = httpService.httpRequest(url, "P", data); 
				return $request;
      },generateSaleOrderWarehouses: function(data) {
        data.city = $rootScope.loginData.city;
				var url = $global.getApiUrl() + $global.getApiObject().createClearanceSaleOrderWarehouses;
				var $request = httpService.httpRequest(url, "P", data); 
				return $request;
      }
    };
  });
