'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.wholesalerPickups
 * @description
 * # wholesalerPickups
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('pricingService', function(httpService, $global, $http, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      getPricing: function(role) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getPricing.replace(":role", role).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getPricingCapsule: function(role) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getPricingCapsule.replace(":role", role).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      deletePricingCapsule: function(id){
        var url = $global.getApiUrl() + $global.getApiObject().deleteProductPricing.replace(":aid", id);
        var $request = httpService.httpRequest(url, "G","");
        return $request;
      },
      markDefaultPriceCapsule: function(id, userType){
        let url = $global.getApiUrl() + $global.getApiObject().markDefaultPriceCapsule.replace(":aid", id).replace(':userType', userType);
        let $request = httpService.httpRequest(url, "P", null);
        return $request;
      },
      addPricing: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().addPricing;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }, retrieveCustomPriceForConsumer: function(userid) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveCustomPriceForConsumer.replace(":id", userid);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, retrieveCustomPrice: function(page) {
        if (page) {
          var url = $global.getApiUrl() + $global.getApiObject().retrieveCustomPrice + "&page=" + page;
        } else {
          page = -1;
          var url = $global.getApiUrl() + $global.getApiObject().retrieveCustomPrice + "?page=" + page + "&city=" + $rootScope.loginData.city;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      addAgentPricingCapsule: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().addUserPriceCapsule.replace(":userType", "agent");
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      addWholesalerPricingCapsule: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().addUserPriceCapsule.replace(":userType", "wholesaler");
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }
    };
  });
