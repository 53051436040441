'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:ProductsCtrl
 * @description
 * # ProductsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('ProductsCtrl', function ($scope, products, ngTableParams, $state, $stateParams, $global, $uibModal, consumer, envService, editableOptions, growl, pricingService) {

    /*============================================global values===============================================*/
    // TODO get the results from the API call
    const addresstypes = ["Residence", "Shop", "Office", "Hotel", "Automobile", "Hospital", "Shopper", "RWA"];
    $scope.addresstypes = [];
    /*============================================default values===============================================*/
    (function () {
      $scope.edit = $global.getApiUrl() + '/fileManager/getImageFileById';
      editableOptions.theme = 'bs3';
      $scope.data = null;
      $scope.dataset = {};
      $scope.pricing_list_wholesaler_consolidated = {};
      $scope.showType = $stateParams.type;
    })();

    /*============================================default service API calls===============================================*/

    // get consumer user categories
    (function () {
      consumer.userCategories().then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.consumercategoriesList = res.data.categories;
          $scope.consumercategoriesList.forEach(elem => {
            $scope.addresstypes.push(elem.name);
          });
        } else if (res.status === "FAILURE") {
          // growl.error(res.error.message)
        }
      });
    })();

    // product pricing
    (function () {
      products.getProducts()
        .then(function (res) {
          if (res.data.categories) {
            //need api change - retieve all categories
            $scope.data = res.data.categories; // arrays of information about category products
            const dataset = {};
            res.data.categories.forEach(function (catdata, idx1) {
              if (!dataset[catdata.name]) { // each unique category name
                dataset[catdata.name] = {};
                dataset[catdata.name]['uom'] = '(' + catdata.uom + ')';
                dataset[catdata.name]['gstPercentage'] = catdata.gstPercentage;
              }
              dataset[catdata.name]['productCategory'] = catdata.code ? catdata.code : null;
              $scope.addresstypes.forEach(function (type, idx) {
                if (type === catdata.consumercategoryname) { // loop against address types
                  if (!dataset[catdata.name][type]) { //unique type
                    dataset[catdata.name][type] = {}
                  }
                  //assign price and categoryId nested under type
                  if (catdata.price > 0.0) {
                    dataset[catdata.name][type].price = catdata.price; // assign price
                  }
                  dataset[catdata.name][type].categoryid = catdata.categoryId; //assign categoryid
                }
              });
            });
            $scope.dataset = dataset; // data contains {categoryName: {type: {price : xxx, categoryId : yyy}}}
            console.log("$scope.dataset", $scope.dataset)
          }
        })
        .catch(function (err) {
          console.error('Error in products.getProducts() API call ', err);
        })
    })();


    // Wholesaler Pricing
    (function () {
      if ($scope.showType === 'Partner') {
        //===================================
        // ag-grid init
        (function () {
          var columnDefs = [
            {
              headerName: "Name",
              field: "name",
              cellStyle: { 'text-align': 'left', "padding-left": '100px' }
            },
            {
              headerName: "Description",
              field: "description",
              resizable: true,
              minWidth: 150,
              cellStyle: { 'text-align': 'left', "padding-left": '90px' }
            },
            {
              headerName: "Number of Partners",
              field: "wholesalersAssigned",
              cellStyle: { 'text-align': 'center','color': "#449c45",'text-decoration': 'underline' }
            },
            {
              headerName: "Date of Creation",
              field: "createdOn",
              cellStyle: { 'text-align': 'center' }
              // hide:true
            },
            {
              headerName: "",
              field: "0",
              cellRenderer: ActionCellRenderer,
              reresizable: false,
              maxWidth: 30,
              skipHeader: true,
              // pinned:'right',
              // outerWidth: '60px',
              cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
            },
            {
              headerName: "",
              field: "1",
              cellRenderer: DeleteCellRenderer,
              resizable: false,
              maxWidth: 30,
              skipHeader: true,
              // pinned:'right',
              // outerWidth: '60px',
              cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
            },
            {
              headerName: "",
              field: "2",
              cellRenderer: defaultPriceCellRenderer,
              resizable: false,
              maxWidth: 50,
              skipHeader: true,
              // pinned:'right',
              // outerWidth: '60px',
              cellStyle: { 'height': '100%', 'display': 'flex', 'padding': '0', 'color': "#449c45" }
            },
          ];

          let rowData = [];

          $scope.gridOptions = {
            columnDefs: columnDefs,
            defaultColDef: {
              flex: 1,
              // minWidth: 150,
              filter: true,
              sortable: true,
              resizable: true
            },
            rowStyle: { background: '#f4f4f4' },
            pagination: true,
            paginationPageSize: 1000,
            // paginationAutoPageSize:true,
            rowData: rowData,
            suppressCsvExport: true,
            suppressExcelExport: true,
            getRowStyle: function (params) {
              if (params.node.rowIndex % 2 === 0) {
                return { background: 'white' };
              }
            },
            onGridReady: function (params) {
              // params.api.sizeColumnsToFit();
              // params.columnApi.autoSizeAllColumns();
              $scope.gridOptions.api.setRowData($scope.pricing_list_wholesaler);
            },
            onCellClicked: function (event) {
              console.log("check what are the things", event)
              if (event && event.data && event.column.colId == "0") {
                $scope.editPricingCapsule('WHOLESALER', event.rowIndex, event.data.id);
              };
              if (event && event.data && event.column.colId == "wholesalersAssigned") {
                $scope.redirectToPartners(event.data.id);
              }
              if (event && event.data && event.column.colId == "1") {
                $scope.deletePriceCapsule(event.rowIndex, event.data);
              }
              if (event && event.data && event.column.colId == "2" && !event.data.default) {
                $scope.markDefaultPriceCapsule(event.rowIndex, event.data, 'WHOLESALER');
              }
            },
            onFirstDataRendered: function (params) {
              params.api.sizeColumnsToFit();
            },
          };
        })();

        function ActionCellRenderer() {
        }

        ActionCellRenderer.prototype.init = function (params) {
          this.eGui = document.createElement('div');
          this.eGui.style.textAlign = 'center';
          console.log("what are the params", params);
          if (params.value !== "" || params.value !== undefined || params.value !== null) {
            $scope.actionButtonText = "Pay";
            $scope.actionButtonClassList = "";
            // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

            let iconsDiv = `<i class="md md-mode-edit")></i>`;
            this.eGui.innerHTML = iconsDiv;
          }
        };
        ActionCellRenderer.prototype.getGui = function () {
          return this.eGui;
        };
        function DeleteCellRenderer() {
        }

        DeleteCellRenderer.prototype.init = function (params) {
          this.eGui = document.createElement('div');
          this.eGui.style.textAlign = 'center';
          console.log("what are the params", params);
          if (params.value !== "" || params.value !== undefined || params.value !== null) {
            $scope.actionButtonText = "Pay";
            $scope.actionButtonClassList = "";
            // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

            let iconsDiv = `<i class="md md-delete"></i>`;
            this.eGui.innerHTML = iconsDiv;
          }
        };
        DeleteCellRenderer.prototype.getGui = function () {
          return this.eGui;
        };

        function defaultPriceCellRenderer() {
        }

        defaultPriceCellRenderer.prototype.init = function (params) {
          this.eGui = document.createElement('div');
          this.eGui.style.textAlign = 'center';
          this.eGui.setAttribute('data-toggle','tooltip');
          this.eGui.setAttribute('tooltip-popup-delay', '0');
          this.eGui.setAttribute('data-placement', 'top');
          this.eGui.style.width = '100%';
          if (params.value !== "" || params.value !== undefined || params.value !== null) {
            $scope.actionButtonText = "Pay";
            $scope.actionButtonClassList = "";
            let iconsDiv;
            if (params.data.default) {
              iconsDiv = `<i class="md md-check" style="margin: 0 auto"></i>`;
              this.eGui.setAttribute('title','The default Price Capsule!')
            } else {
              iconsDiv = `<i class="md md-beenhere" style="margin: 0 auto"></i>`;
              this.eGui.setAttribute('title','Set as default Price Capsule?')
            }
            this.eGui.innerHTML = iconsDiv;
          }
        };
        defaultPriceCellRenderer.prototype.getGui = function () {
          return this.eGui;
        };

        //===================================
        const type = 'WHOLESALER';
        pricingService.getPricingCapsule(type)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              console.log(res, "wholesaler table data")
              if (res && res.data && !res.data.results) {
                $scope.pricing_list_wholesaler = [];
                return;
              }
              $scope.pricing_list_wholesaler = res.data.results;
              $scope.gridOptions.api.setRowData($scope.pricing_list_wholesaler);
            }
          })
          .catch(function (err) {
            console.error('Error in getting agent pricing details ', err);
          })
      }
    })();

    // agent pricing IIFE
    (function () {
      if ($scope.showType === 'REO') {

        //====================================
        //====================================
        const type = 'AGENT';
        pricingService.getPricingCapsule(type)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              if (res && res.data && !res.data.results) {
                $scope.pricing_list_agent = [];
                return;
              }
              $scope.pricing_list_agent = res.data.results;
              $scope.gridOptions.api.setRowData($scope.pricing_list_agent);
              console.log(res.data.results, "agent table data")
            }
          })
          .catch(function (err) {
            console.error('Error in getting agent pricing details ', err);
          })
      }
    })();

    /*============================================core functions===============================================*/

    $scope.editPricingCapsule = function (pricingcategory, index, id) {
      const modalInstance = $uibModal.open({
        templateUrl: 'views/product-pricing-modal.html',
        controller: 'ProductPricingModalCtrl',
        size: "lg",
        resolve: {
          items: function () {
            if ($scope.showType === 'REO') {
              return 'EDIT+' + JSON.stringify($scope.pricing_list_agent[index]);
            } else {
              return 'EDIT+' + JSON.stringify($scope.pricing_list_wholesaler[index]);
            }
          },
          role: function () {
            return pricingcategory;
          }
        }
      });

      modalInstance.result.then(function () {
        reloadPage();
      }, function () {
        reloadPage();
      });

    };

    $scope.deletePriceCapsule = function (index, row) {
      console.log(index, row, "in delete");
      if (confirm("Are you sure you want to delete " + row.name + "?")) {
        pricingService.deletePricingCapsule(row.id)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success("Deleted Successfully");
              reloadPage();
            } else {
              if (res && res.error && res.error.errors) {
                let errors = res.error.errors;
                for (let i = errors.length - 1; i >= 0; i--) {
                  $scope[errors[i].code] = errors[i].message;
                  growl.error(errors[i].message)
                }
                if (res.error.errors.length === 0) {
                  growl.error(res.error.message);
                }
              }
            }
          })
          .catch(function (err) {
            console.error('Error in deleting pricing capsule', err);
          });
      }
    };

    // mark default pricing capsule
    $scope.markDefaultPriceCapsule = function (index, row, userType) {
      if (confirm("Are you sure you want to set " + row.name + " as default?")) {
        $global.displayLog('marking default price capsule ', 'row', row);
        pricingService.markDefaultPriceCapsule(row.id, userType)
          .then(function (res) {
            if (res && res.status === "SUCCESS" && res.data && res.data.message) {
              growl.success(row.name + " set as default");
              reloadPage();
            } else {
              if (res && res.error && res.error.errors) {
                let errors = res.error.errors;
                for (let i = errors.length - 1; i >= 0; i--) {
                  $scope[errors[i].code] = errors[i].message;
                  growl.error(errors[i].message)
                }
                if (res.error.errors.length === 0) {
                  growl.error(res.error.message);
                }
              }
            }
          })
          .catch(function (err) {
            console.error("Error in marking default ", err);
          })
      }
    };

    // agent pricing edit
    $scope.editAgentPricing = function (key, value) {
    };

    // wholesaler pricing edit
    $scope.editWholesalerPricing = function (key, value, priceId, newPrice) {
      if (!priceId && newPrice > 0) {
        console.log("key = ", key);
        console.log("value = ", value);
        console.log("priceId = ", priceId);
        console.log("newPrice = ", newPrice);

        // TODO get the entire row of the table containing all the empty cells
      }


    };

    // product pricing edit: when cell with a empty category id is updated
    $scope.editProduct = function (key, valueArray, value, addressType, id) {

      if (!id && value > 0) {
        // get category id of nearest sibling
        let nearestSiblingCategoryId;
        for (let eachAddressCategory of addresstypes) {
          if (valueArray[eachAddressCategory] && valueArray[eachAddressCategory]['categoryid']) {
            nearestSiblingCategoryId = valueArray[eachAddressCategory]['categoryid'];
            break;
          }
        }

        let dataObjToSave = {};

        for (let eachConsumerCategoryObject of $scope.consumercategoriesList) {
          if (eachConsumerCategoryObject.name === addressType) {
            dataObjToSave.consumercategoryid = eachConsumerCategoryObject.consumerCategoryId;
            break;
          }
        }


        // get the full detail information
        for (let elem of $scope.data) {
          if (elem.categoryId === nearestSiblingCategoryId) {
            dataObjToSave.name = elem.name;
            dataObjToSave.code = elem.code;
            dataObjToSave.uom = elem.uom;
            dataObjToSave.price = value;
            dataObjToSave.description = elem.description;
            dataObjToSave.image = elem.image;
            dataObjToSave.city = elem.city;
            break;
          }
        }

        products.addProduct(dataObjToSave).then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success("Successfully added records!");
            reloadPage();
          } else if (res.status === "FAILURE") {
            growl.error("Failed");
          }
        });
      }

    };

    // save agent pricing
    $scope.saveAgentsPricingTable = function () {

    };

    // save wholesaler pricing
    $scope.saveWholesalerPricingTable = function () {
      // function to get all the changed price values
      Object.keys($scope.pricing_list_wholesaler_consolidated).forEach((categoryName, index, array) => {
        Object.keys($scope.pricing_list_wholesaler_consolidated[categoryName]).forEach((wholesalerName, index2, array2) => {
          let newPriceVM = $scope.pricing_list_wholesaler_consolidated[categoryName][wholesalerName]['price'];
          let oldPriceVM = $scope.initial_pricing_list_wholesaler_consolidated[categoryName][wholesalerName]['price'];
          let priceIdVM = $scope.pricing_list_wholesaler_consolidated[categoryName][wholesalerName]['priceid'];

          if (priceIdVM && oldPriceVM && newPriceVM && oldPriceVM !== newPriceVM) {
            // TODO use newPriceVM and priceIdVM to call update wholesaler price API
          }

        });
      });

      //TODO reload the page once the save button is clicked
    };

    // save product pricing
    $scope.saveProductsTable = function () {
      const results = [];

      let counter = 0;
      // $global.displayLog('inside save table', '$scope.dataset', $scope.dataset);
      // $global.displayLog('inside save table', '$scope.data', $scope.data);

      let status = false;

      Object.keys($scope.dataset).forEach(function (productcategory, idx) {
        Object.keys($scope.dataset[productcategory]).forEach(function (addresstype, idx) {
          if (typeof $scope.dataset[productcategory][addresstype] === 'object' && $scope.dataset[productcategory][addresstype] !== null) {
            var categoryid = $scope.dataset[productcategory][addresstype]['categoryid'];
            var price = $scope.dataset[productcategory][addresstype]['price'];
            var gstPercentage = $scope.dataset[productcategory]['gstPercentage'];
            $scope.data.forEach(function (elem) {
              if (elem.categoryId === categoryid && (elem.price !== price || elem.gstPercentage !== gstPercentage)) {
                counter += 1;
                status = $scope.priceUpdate(categoryid, price, gstPercentage);
              }
            })
          }
        })
      });
      if (status) { //FIXME not working
        growl.success("Successfully updated " + counter + " records!");
      }
    };

    // product pricing update API call
    $scope.priceUpdate = function (categoryId, price, gstPercentage) {
      const pricedata = {
        "categoryId": categoryId,
        "price": price,
        "gstPercentage": gstPercentage
      };
      products.priceUpdate(pricedata)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            return true;
          } else if (res.status === "FAILURE") {
            growl.error("Failure");
            return false;
          }
        })
        .catch(function (err) {
          console.error('Error in price update API call ', err);
          return false;
        })
    };

    // helper function to transform text
    const textToCapitalize = function (inputString) {
      let outputString = inputString.toLowerCase();
      outputString = outputString.charAt(0).toUpperCase() + outputString.slice(1);
      return outputString;
    };

    // add pricing open modal for agent, wholesalers
    $scope.addPricingCapsule = function (pricingcategory) {
      const modalInstance = $uibModal.open({
        templateUrl: 'views/product-pricing-modal.html',
        controller: 'ProductPricingModalCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return 'ADD';
          },
          role: function () {
            return pricingcategory;
          }
        }
      });

      modalInstance.result.then(function () {
        reloadPage();
      }, function () {
        reloadPage();
      });
    };


    $scope.addPricing = function (pricingcategory) {
      const modalInstance = $uibModal.open({
        templateUrl: 'views/add-pricing.html',
        controller: 'AddPricingCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return 'PRICING';
          },
          role: function () {
            return pricingcategory;
          }
        }
      });

      modalInstance.result.then(function () {
        reloadPage();
      }, function () {
        reloadPage();
      });

    };

    $scope.redirectToReos = function (data) {
      console.log("price capsule's data", data);
      $state.go("root.agents", { priceCapsuleId: data });
    }

    $scope.redirectToPartners = function (data) {
      console.log("wholesaler's data", data);
      $state.go("root.wholesalers", { priceCapsuleId: data });
    }

    // helper function to refresh the current page
    function reloadPage() {
      setTimeout(function () {
        $state.go('root.products', { type: $scope.showType }, { reload: true });
      }, 1500);
    }

    // helper function to return differences in two arrays
    function differenceInWholesalerArray(originalWholesalerArray, currentRowWholesalerNames) {
      for (let wholesalerName of currentRowWholesalerNames) {
        if (originalWholesalerArray.includes(wholesalerName)) {
          let indexOf = originalWholesalerArray.indexOf(wholesalerName);
          originalWholesalerArray.splice(indexOf, 1);
        }
      }
      return originalWholesalerArray;
    }

    // helper function to sort an object by keys
    function sortWholesalerRow(unorderedWholesalerRowObject) {
      const orderedWholesalerRowObject = {};
      Object.keys(unorderedWholesalerRowObject).sort().forEach(function (key) {
        orderedWholesalerRowObject[key] = unorderedWholesalerRowObject[key];
      });

      return orderedWholesalerRowObject;
    }

  });
