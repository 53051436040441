'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddCustomPricingCtrl
 * @description
 * # AddCustomPricingCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AddCustomPricingCtrl', function ($scope, $filter, products, consumer, $rootScope, $state, $global, $uibModal, envService, editableOptions, growl, pricingService, $log) {

        $scope.userId = consumer.getUserId();
        if (!$scope.userId) {
            $state.go("root.consumer");
        }
        $scope.inrFilter = $filter('INR');
        $scope.isEditButtonOn = false;
        $scope.hidethis = false;

        $scope.usersProducts = [];
        $scope.usersConsumerCategories = [];

        (function () {
            let columnDefs = [
                {
                    headerName: "Category",
                    field: "productCategoryName",
                    flex: 2,
                    maxWidth: 230,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Product",
                    field: "categoryName",
                    flex: 2,
                    maxWidth: 230,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "UOM",
                    field: "uom",
                    flex: 2,
                    maxWidth: 110,
                    cellStyle: { 'text-align': 'left' },
                }
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                suppressCsvExport: true,
                suppressExcelExport: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                // isExternalFilterPresent: isExternalFilterPresent,
                // doesExternalFilterPass: doesExternalFilterPass,
                getRowStyle: function (params) {
                    if (params.data.modified) {
                        return { color: 'red', fontStyle: 'italic' };
                    }
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.dataset);
                },
                onCellValueChanged: function (params) {
                    $scope.validatePricing(params);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                },
                stopEditingWhenGridLosesFocus: true
            }; //End of gridOptions//

            $scope.AmountCellRenderer = function () {
            }

            $scope.AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    let val;
                    if (params.value == 0) {
                        val = 0;
                    } else {
                        val = params.value ? $scope.inrFilter(params.value) : '-';
                    }
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            $scope.AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            $scope.startEditing = function () {
                $scope.isEditButtonOn = true;
                $scope.refreshGrid();
            };

            $scope.onClose = function () {
                if ($scope.gridOptions.api.getEditingCells().length !== 0) {
                    growl.error("Cell not saved")
                }
                $scope.isEditButtonOn = false;
                $scope.refreshGrid();
                $scope.onSubmit();
            };

            $scope.onCancel = function () {
                $global.refresh($state);
            };

            $scope.getRowData = () => {
                var rowData = [];
                $scope.gridOptions.api.forEachNode(function (node) {
                    rowData.push(node.data);
                });
                return rowData;
            };

            $scope.refreshGrid = () => {
                let rowData = $scope.getRowData();
                if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData(rowData);
                };
            }

            $scope.validatePricing = function (params) {

                let price = Number(params.value);
                if (price!== 0 && !price || typeof price !== "number") {
                    growl.error("Please enter a valid number!");
                    params.data.modified = params.data.modified ? true : false;
                    params.data[`${params.column.colId}`] = params.oldValue;
                } else {
                    params.data.modified = true;
                    params.data[`${params.column.colId}Modified`] = true;
                }
                $scope.refreshGrid();
            };
        })();

        $scope.onSubmit = function () {
            // set focus on the first grid cell
            const firstCol = $scope.gridOptions.columnApi.getAllDisplayedColumns()[0];
            $scope.gridOptions.api.setFocusedCell(0, firstCol);

            const promises = [];
            const rowData = $scope.getRowData();
            console.log('rowData onSubmit is : ', rowData);
            rowData.forEach(row => {

                // Segregate the modified rows
                if (row.modified) {

                    // Segregate the modified consumerCategory
                    $scope.usersConsumerCategories.forEach(consumerCategory => {
                        if (row[`${consumerCategory.toLowerCase()}Modified`]) {

                            // Segregate Add/Edit Custom Pricing
                            if (row[`${consumerCategory.toLowerCase()}CustomPriceId`]) {
                                let data = {
                                    "customPriceId": row[`${consumerCategory.toLowerCase()}CustomPriceId`],
                                    "customPrice": row[`${consumerCategory.toLowerCase()}`]
                                };
                                // console.log('data: ',data);
                                promises.push($scope.updatePricing(data));
                            } else {
                                let categoryId = $scope.getCategoryIdFromConsumerCategoryNameAndCategoryName(row.categoryName, consumerCategory);
                                if (categoryId === null) {
                                    growl.error(`Product ${row.categoryName} does not exist for the address type ${consumerCategory}`);
                                    return;
                                }
                                let data = {
                                    "userId": $scope.userId,
                                    "categoryId": categoryId,
                                    "consumerCategoryId": $scope.getConsumerCategoryIdFromName(consumerCategory),
                                    "customPrice": row[`${consumerCategory.toLowerCase()}`]
                                };
                                // console.log(' API data: ', data);
                                promises.push($scope.addPricing(data));
                            }
                        }
                    });
                }
            });
            
            Promise.all(promises).then((values) => {
                $state.go($state.current, {}, { reload: true });
                $scope.retrieveCustomPricing();
                console.log('Done : ', values);
            })
            .catch((errs => {
                $state.go($state.current, {}, { reload: true });
                $scope.loadData();
            }));
        }

        $scope.getConsumerCategoryIdFromName = function (value) {

            let filteredArray = $scope.consumerCategories.filter(elem => elem.name === value);
            if (filteredArray && filteredArray[0]) {
                return filteredArray[0].consumerCategoryId;
            }
            return null;
        }

        $scope.getConsumerCategoryNameFromId = function (value) {

            let filteredArray = $scope.consumerCategories.filter(elem => elem.consumerCategoryId === value);
            if (filteredArray && filteredArray[0]) {
                return filteredArray[0].name;
            }
            return null;
        }

        $scope.getBeforeClickConsumerCategoryImageFromName = function (value) {
            if (!$scope.categories || !value) {
                return null;
            }
            let filteredArray = $scope.consumerCategories.filter(elem => elem.name === value);
            if (filteredArray && filteredArray[0]) {
                return filteredArray[0].beforeClickURL;
            }
            return null;
        }

        $scope.getAfterClickConsumerCategoryImageFromName = function (value) {
            if (!$scope.categories || !value) {
                return null;
            }
            let filteredArray = $scope.consumerCategories.filter(elem => elem.name === value);
            if (filteredArray && filteredArray[0]) {
                return filteredArray[0].afterClickURL;
            }
            return null;
        }

        $scope.getCategoryNameFromId = function (value) {

            let filteredArray = $scope.categories.filter(elem => elem.categoryId === value);
            if (filteredArray && filteredArray[0]) {
                return filteredArray[0].name;
            }
            return null;
        }

        $scope.getCategoryIdFromConsumerCategoryNameAndCategoryName = function (categoryName, consumerCategoryName) {

            let filteredCategory = $scope.categories.filter(category => category.name === categoryName && category.consumercategoryname === consumerCategoryName);
            if (filteredCategory && filteredCategory[0]) {
                return filteredCategory[0].categoryId;
            }
            return null;
        }

        $scope.setUsersConsumerCategoryKeysInGrid = function () {

            let rowData = $scope.getRowData();
            $scope.usersConsumerCategories.forEach(cc => {

                rowData.forEach(row => {
                    row[cc.toLowerCase()] = null;
                });
            });
        }

        $scope.addPricing = function (data) {
            return new Promise((resolve, reject) => {
                pricingService.addPricing(data).then(function (res) {
                    resolve(res);
                    if (res.status === "SUCCESS") {
                        growl.success("Successfully Added Pricing!")
                    } else if (res.status === "FAILURE") {
                        growl.error(res.error.message ? res.error.message : "Cannot Add/Update Pricing")
                    }
                });
            });
        }

        $scope.updatePricing = function (data) {
            return new Promise((resolve, reject) => {
                products.editCustomPrice(data).then(function (res) {
                    resolve(res);
                    if (res.status === "SUCCESS") {
                        growl.success("Successfully Added Pricing!");
                    } else if (res.status === "FAILURE") {
                        growl.error(res.error.message ? res.error.message : "Cannot Add/Update Pricing");
                    }
                });
            });
        }

        $scope.disableCustomPricing = function (consumerCategory) {
            let confirmationMessage = `Are you sure you want to disable pricing for ${consumerCategory}?`
            if (confirm(confirmationMessage)) {
                let data = {
                    "consumerId": $scope.userId,
                    "consumerCategoryId": $scope.getConsumerCategoryIdFromName(consumerCategory)
                };
                products.deleteCustomPrice(data).then(function (res) {
                    if (res.status === "SUCCESS") {
                        growl.success("Successfully Disabled Pricing!");
                        $state.go($state.current, {}, { reload: true });
                    } else if (res.status === "FAILURE") {
                        growl.error(res.error.message ? res.error.message : "Cannot Disable Pricing");
                    }
                });
            }
        }

        // Retrieve Consumer Categories
        $scope.addresstypes = [];
        consumer.userCategories().then(function (res) {
            if (res.status == "SUCCESS") {
                $scope.consumerCategories = res.data.categories;

                $scope.totalConsumerCategoriesCount = res.data.categories.length;

                $scope.consumerCategories.forEach(elem => {
                    $scope.addresstypes.push(elem.name);
                })
                // console.log("$scope.consumerCategories", $scope.consumerCategories, $scope.addresstypes);
            } else if (res.status == "FAILURE") {
                growl.error(res.error.message)
            }

        });

        // Retrieve user specific Consumer Categories
        consumer.retrieveConsumerCategoriesForAUser($scope.userId).then(function (res) {
            if (res.status == "SUCCESS") {
                let consumerCategories = res.data.consumerCategories;

                $scope.userName = res.data.name;
                $scope.totalConsumerCategoriesOfTheUserBadge = consumerCategories.length;

                let columnDefs = $scope.gridOptions.columnDefs;
                for (let i = 0; i < consumerCategories.length; i++) {
                    let col = {
                        headerName: consumerCategories[i].name,
                        field: consumerCategories[i].name.toLowerCase(),
                        flex: 1,
                        maxWidth: 130,
                        cellRenderer: $scope.AmountCellRenderer,
                        cellStyle: params => {
                            if ($scope.isEditButtonOn) {
                                return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                            }
                            return { 'text-align': 'right' }
                        },
                        editable: params => $scope.isEditButtonOn,
                        singleClickEdit: true
                    }
                    $scope.usersConsumerCategories.push(consumerCategories[i].name);
                    columnDefs.push(col);
                    // $scope.refreshGrid();
                }
                $scope.gridOptions.api.setColumnDefs(columnDefs);

            } else if (res.status == "FAILURE") {
                growl.error(res.error.message)
            }
        });

        // Retrieve Categories
        $scope.dataset = {};
        products.getProducts().then(function (res) {
            if (res.data.categories) {
                $scope.categories = res.data.categories;

                var dataset = [];
                let uniqueCategories = [];
                let uniqueProductCategories = [];
                $scope.categories.forEach(function (catdata, idx1) {

                    // TODO - solution for non existing categories (for specific consumerCategories)
                    if ($scope.usersConsumerCategories.includes(catdata.consumercategoryname)) {
                        if (!uniqueCategories.includes(catdata.name)) {
                            let categoryObject = {};
                            categoryObject.categoryName = catdata.name;
                            categoryObject.productCategoryName = catdata.code;
                            categoryObject.uom = catdata.uom;
                            dataset.unshift(categoryObject);
                            uniqueCategories.push(catdata.name);
                            if (!uniqueProductCategories.includes(catdata.code)) {
                                uniqueProductCategories.push(catdata.code);
                            }
                        }
                    }
                    // TODO - new API for unique categories and productCategories?
                    $scope.totalCategoriesCount = uniqueCategories.length;
                    $scope.totalProductCategoriesCount = uniqueProductCategories.length;

                });

                $scope.dataset = dataset.reverse();
                if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData(dataset);
                }
                $scope.retrieveCustomPricing();
                console.log('data set is : ', $scope.dataset);
            }
        });

        // Retrieve Custom Pricing       
        $scope.retrieveCustomPricing = function () {
           pricingService.retrieveCustomPriceForConsumer($scope.userId).then(function (res) {
                if (res.status === "SUCCESS") {
                    // console.log('res', res.status);

                    // Badges
                    $scope.totalActiveConsumerCategoriesCount = res.data.totalActiveConsumerCategoriesCount;
                    $scope.totalActiveCategoriesCount = res.data.totalActiveCategoriesCount;
                    $scope.totalActiveProductCategoriesCount = res.data.totalActiveProductCategoriesCount;

                    $scope.totalActiveConsumerCategoriesOfTheUserBadge = `${$scope.totalActiveConsumerCategoriesCount}`;
                    $scope.productsAndCategoriesBadge = `${$scope.totalActiveCategoriesCount} | ${$scope.totalActiveProductCategoriesCount}`;

                    $scope.activeConsumerCategories = res.data.activeConsumerCategories;
                    $scope.setUsersConsumerCategoryKeysInGrid();
                    console.log("Retrieved all custom prices :", res);
                    if (res.data.customPrice) {
                        $scope.customPrice_list = res.data.customPrice;

                        let rowData = $scope.getRowData()
                        rowData.forEach(row => {

                            $scope.customPrice_list.forEach(customPrice => {

                                // $scope.usersConsumerCategories.forEach()
                                let consumerCategoryName = $scope.getConsumerCategoryNameFromId(customPrice.consumerCategoryId).toLowerCase();

                                if (row.categoryName === $scope.getCategoryNameFromId(customPrice.categoryId) && consumerCategoryName in row) {
                                    row[`${consumerCategoryName}`] = customPrice.customPrice;
                                    row[`${consumerCategoryName}CustomPriceId`] = customPrice.customPriceId;
                                    // console.log('Custom price is set : ', customPrice);
                                }

                            });

                        });
                        if ($scope.gridOptions && $scope.gridOptions.api) {
                            $scope.gridOptions.api.setRowData(rowData);
                        }
                        // console.log("$scope.customPrice_list: ", $scope.customPrice_list);
                    }
                }
            });
        }

        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

    });
