'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AquisitionStatsCtrl
 * @description
 * # AquisitionStatsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AquisitionStatsCtrl', function ($scope, $http, $state,$global,authentication , $filter, $moment, $stateParams, $rootScope, salesAgentService, dashboard, $uibModal, $log, consumer, prospect, growl, BidService) {

        $scope.inrFilter = $filter('INR');

        $scope.date = {
            startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
            endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
        };

        $scope.vm = {
            params: { "city": $rootScope.loginData.city }
        };


        $rootScope.loginData = $global.getLocalItem("loginData", true);

        (function () {

            var data = {
                "city": $rootScope.loginData.city,
                "userType": $rootScope.loginData.role,
                "module": "Reports",
                "subModule": "SalesTeam",
                "type": "Other"
            };

            authentication.getUserRoleMetrics(data).then(function (res) {
                if (res.status == "SUCCESS") {
                    $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
                    $scope.gridOptions.columnDefs.forEach(function (col, index) {
                        if ($scope.disabledFeilds.includes(col.field)) {
                            $scope.gridOptions.columnDefs.splice(index, 1);
                        }
                    });
                    $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs);
                } else {
                    $scope.disabledFeilds = [];
                }
            }).catch(function (err) {
                $scope.disabledFeilds = [];
            })
        })();

        (function () {
            let columnDefs = [
                {
                    headerName: "Sales Agent Name",
                    field: "salesAgentName",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Interested",
                    field: "interested",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    headerName: "notInterested",
                    field: "notInterested",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    headerName: "approved",
                    field: "approved",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    headerName: "Number Of Calls",
                    field: "totalNumberOfCalls",
                    cellStyle: { 'text-align': 'right' }
                },
                {
                    headerName: "Total GTV",
                    field: "totalGTV",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer
                },
                {
                    headerName: "Total Transactions",
                    field: "totalTransactionsCount",
                    cellStyle: { 'text-align': 'right' }
                },
            ];
            columnDefs.forEach(function (colObj) {
                colObj.headerTooltip = colObj.headerName;
            })
            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                tooltipShowDelay: 0,
                suppressCsvExport: true,
                suppressExcelExport: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.salesAgentsData);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            }; //End of gridOptions//


            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '0.00';
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

        })();

        (function () {

            $scope.loadData = function () {

                let data = {
                    "startDate": $scope.vm.params.fromdate,
                    "endDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                }
                salesAgentService.retrieveSalesReports(data).then(function (res) {
                    $scope.labelCounts = {
                        totalGtv: res.data && res.data.totalGTV ? $scope.inrFilter(res.data.totalGTV) : "0.00",
                        totalSalesAgents: res.data && res.data.totalSalesAgents ? res.data.totalSalesAgents : 0
                    }
                    if (res.status == "Success") {
                        $scope.salesAgentsData = res.data.salesAgentsData;
                        $scope.gridOptions.api.setRowData($scope.salesAgentsData);
                    }
                })
            }
            $scope.loadData();

        })();




        console.log('4stateparam    : ', $stateParams.fromdate);
        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        //Watch for date changes
        $scope.$watch('date', function (newDate) {
            $scope.transactionChange();
        }, false);

        $scope.transactionChange = function () {

            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;

            if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
                $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
                $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

                if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
                    $scope.vm.params.singledayquery = true;
                }
                $scope.loadData();
            } else {
                console.error("Invalid date, please select valid date range");
                console.error("startDate = ", $scope.date.startDate);
                console.error("endDate = ", $scope.date.endDate);
                growl.error("Invalid date, please select valid date range");
            }
        };

        $scope.nextDate = function () {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.endDate).add(1, "days"),
                endDate: moment($scope.date.endDate).add(1, "days")
            };
            $scope.transactionChange();
        }
        $scope.previousDate = function () {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.startDate).subtract(1, "days"),
                endDate: moment($scope.date.startDate).subtract(1, "days")
            };
            $scope.transactionChange();
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });
