'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddIncomingItemsCtrl
 * @description
 * # AddIncomingItemsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AddIncomingItemsCtrl', function($scope, $filter, agentService, products, warehouseService, $moment, $global, growl, $uibModalInstance, $state, $timeout, $rootScope) {
        $scope.data = {}
        $scope.vm = {};
        $scope.location = {
            latitude: 0,
            longitude: 0
        }

        $scope.errorDiable = true;
        console.log($rootScope.loginData.userid)
        $scope.incomingInventoryDetails = {};

        $scope.categoryNames =[];
        var a = []
        products.getProducts().then(function(res) {
            console.log("CATEGORIES  :::",res);
            if (res.status == 'SUCCESS') {
                $scope.categories = res.data.categories;
                $scope.categories.forEach(function(element, index){
                    a.push(element.name)
                }); 
                $scope.categoryNames = a.filter( onlyUnique );
                $scope.statuses = $scope.categoryNames;
                console.log($scope.statuses)
                }
            }
        );

        function onlyUnique(value, index, self) { 
            return self.indexOf(value) === index;
        }
        $scope.users = []; 


        $scope.groups = [];
        $scope.loadGroups = function() {
            return $scope.groups.length ? null : $http.get('/groups').success(function(data) {
              $scope.groups = data;
            });
        };

        $scope.showGroup = function(user) {
            if(user.group && $scope.groups.length) {
              var selected = $filter('filter')($scope.groups, {id: user.group});
              return selected.length ? selected[0].text : 'Not set';
            } else {
              return user.groupName || 'Not set';
            }
        };

        $scope.showStatus = function(user) {
            
            var selected = [];
            if (user) {
                if(user.categoryName) {
                    // selected = $filter('filter')($scope.statuses, {value: user.items});
                    selected = user.categoryName
                }
            }
            return user.categoryName;
        };

        $scope.checkName = function(data, id) {
            // if (id === 2 && data !== 'awesome') {
            //   return "Username 2 should be `awesome`";
            // }
        };

        $scope.saveUser = function(data, id) {
            console.log(data)
            console.log(id)
            if (data.categoryName == null) {
                console.log("select item");
                growl.error("Select an Item")
                $scope.errorDiable = true;
                return false;
            }else if(data.quantity == null ) {
                console.log("selct quantity")
                growl.error("Select an Quantity")
                $scope.errorDiable = true;
                return false;
            }else if(data.quantity <= 0){
                growl.error("Select an Quantity")
                $scope.errorDiable = true;
                return false;
            }else{
                console.log($scope.users)
                $scope.errorDiable = false;
                return true;
            }

            return true;
        };

          // remove user
        $scope.removeUser = function(index) {
            $scope.users.splice(index, 1);
        };

          // add user
        $scope.addUser = function() {
            $scope.inserted = { 
                categoryName : null,
                quantity : null 
            };
            $scope.users.push($scope.inserted);
            $scope.errorDiable = true;

        };

        $scope.searchAgent = function(key) {
            key = key.trim();
            if(key){
                if (key.length > 3) {
                    var type = 'AGENT';
                    $scope.transactionStatus = "pending";
                    $scope.noAgentFound = false;

                    $scope.agentlistloading = true;
                    agentService.getAgent(type, key).then(function(res) {
                        $scope.agentlistloading = false;
                        if (res.status == "SUCCESS") { //&& res.data.usersuserType == "AGENT"
                            $scope.agents = res.data;
                            console.log(res)
                            if(res.data.users.length == 1){
                                $scope.length0 = true;
                                $scope.searchText = $scope.agents.users[0].name;
                                console.log($scope.users)
                                if($scope.users.length == 0){
                                    $scope.errorDiable = true;
                                }else{
                                    $scope.errorDiable = false;
                                }
                            } else {
                                $scope.agent_list = res.data.users;
                                $scope.length0 = false;
                            }
                            if(res.data.message == "No records found"){
                                $scope.noAgentFound = true;
                            }
                        } else if (res.status == "FAILURE") {
                            $scope.noAgentFound = true;
                        } else {
                            $scope.noAgentFound = true;
                        }

                    }, function(err) {
                        //console.log(err);
                    })
                }
            }else{
                $scope.errorDiable = true;
            }
        };

        if($scope.users == null){
            $scope.errorDiable = true;
        }
        
        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };

        $scope.addUsers = function() {
            console.log($scope.agents)
            $scope.incomingInventoryDetails = {
                agentId : $scope.agents.users[0].userId,
                items : $scope.users
            };
            console.log($scope.incomingInventoryDetails)
            warehouseService.addInventory($rootScope.loginData.userid,$scope.incomingInventoryDetails).then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    $uibModalInstance.close('Close');
                }
            })

        };

    });