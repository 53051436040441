angular.module('scrapQcrmApp')
	.controller('trackLocationCtrl', function ($state, $scope, $filter, $rootScope, $global, wholesalerService, agentService, Crossfilter, $moment, $stateParams, NgMap) {
		var filterCoordinates = [];
		$scope.isShowAll = true;
		$scope.isHideOld = null;

		var todayDate = $moment(new Date()).format("DD-MMM-YYYY")
		console.log(todayDate)
		function init() {
			$scope.search = '';
			$scope.data = {};

			$scope.types = [
				{
					"value": "WHOLESALER",
					"name": "Partner"
				},
				{
					"value": "AGENT",
					"name": "Agent"
				}
			];

			$scope.user = {
				"type": "WHOLESALER"
			};

			$scope.userSearchPlaceholder = "Please Enter Partner Name";
			loadUser($scope.user.type);

			$scope.date = {
				"selectedDate": "today",
				"selectedSlot": defaultSelectedSlot()
			};

			$scope.slot = [
				{
					"name": "06AM - 08AM",
					"value": "6-8"
				},
				{
					"name": "08AM - 10AM",
					"value": "8-10"
				},
				{
					"name": "10AM - 12AM",
					"value": "10-12"
				},
				{
					"name": "12AM - 02PM",
					"value": "12-14"
				},
				{
					"name": "02PM - 04PM",
					"value": "14-16"
				},
				{
					"name": "4PM Onwards",
					"value": "16-22"
				}
			];

			$scope.day = [
				{
					"name": "Today",
					"value": "today"
				},
				{
					"name": "Yesterday",
					"value": "yesterday"
				}
			];

			$scope.selectedDayTime();
		}

		//Check show all
		$scope.checkShowAll = function () {
			if ($scope.isShowAll) {
				$scope.clearSearch(true);
				$scope.data.wholesalerId = "ALL";
				getLocation($scope.user.type);
			} else {
				initMap();
			}
		}

		$scope.checkHideOld = function () {
			console.log("IN checkHideOld")
			if ($scope.isHideOld) {
				initMap();
				$scope.clearSearch(true);
				$scope.data.wholesalerId = "ALL";
				getLocation($scope.user.type);
			} else {
				initMap();
				$scope.clearSearch(true);
				$scope.data.wholesalerId = "ALL";
				getLocation($scope.user.type);
			}
		}

		function loadUser(type) {
			if (type == "AGENT") {
				agentService.usersLoadAll(type).then(function (res) {
					if (res.status == "SUCCESS") {
						$scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
					}
				}, function (err) { });
			} else {
				wholesalerService.usersLoadAll(type).then(function (res) {
					if (res.status == "SUCCESS") {
						$scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
					}
				}, function (err) { });
			}
		}

		//Change user
		$scope.changeUser = function () {
			$scope.clearSearch();
			if ($scope.user.type == "AGENT") {
				$scope.isShowAll = null;
				$scope.isHideOld = null;
				$scope.userSearchPlaceholder = "Please Enter Agent Name";
			} else {
				$scope.userSearchPlaceholder = "Please Enter Partner Name";
			}
			loadUser($scope.user.type);
		}

		$scope.selectAgent = function (val) {
			if (val.userType == "WHOLESALER") {
				$scope.data.wholesalerId = val.userId;
			} else if (val.userType == "AGENT") {
				$scope.data.agentId = val.userId;
			}
			$scope.selectedUser = val.name;
			$scope.search = $scope.selectedUser;
			// getLocation($scope.user.type);
		}

		$scope.clearSearch = function (notReset) {
			$scope.search = '';
			$scope.selectedUser = false;
			$scope.data.agentId = null;
			$scope.data.wholesalerId = null;
			if (!notReset) {
				initMap();
			}
		}

		function defaultSelectedSlot() {
			var date = new Date();
			var hours = date.getHours();
			var nextTwoHours;
			if (hours >= 6 && hours <= 18) {
				if (hours % 2 != 0) {
					hours = hours - 1;
				} else {
					if (hours == 18) {
						hours = 16;
					}
				}
				nextTwoHours = hours + 2;
				return hours + "-" + nextTwoHours;
			} else {
				return "6-8";
			}

		}

		$scope.selectedDayTime = function () {
			var date, time, fromDate, toDate;
			time = $scope.date.selectedSlot.split("-");
			if ($scope.date.selectedDate == "today") {
				date = new Date();
			} else {
				date = new Date();
				date.setDate(date.getDate() - 1);
			}
			date.setMinutes(0);
			date.setSeconds(0);
			fromDate = $moment(new Date(date.setHours(Number(time[0])))).format("DD-MMM-YYYY HH:mm:ss");
			toDate = $moment(new Date(date.setHours(Number(time[1])))).format("DD-MMM-YYYY HH:mm:ss");
			// $scope.data.fromDate = "24-Feb-2018 20:00:00";
			// $scope.data.toDate = "24-Feb-2018 23:00:00";
			$scope.data.fromDate = fromDate;
			$scope.data.toDate = toDate;
			getLocation($scope.user.type);
		}

		function slidingwindow(a, n) {
			var r = []
			var count = 1;
			var first = 0;
			var last = n;
			for (var i = 0; i < Math.floor(a.length / n) + 1; i++) {
				var data = a.slice(first, last + 1);
				r.push(data);
				first = parseInt(last);
				last = parseInt(last + n);
			}
			return r;
		}
		function latLngBound() {
			var bounds = new google.maps.LatLngBounds();
			for (var i = 0; i < filterCoordinates.length; i++) {
				var position = new google.maps.LatLng(filterCoordinates[i][0], filterCoordinates[i][1]);
				bounds.extend(position);
			}
			$scope.map.fitBounds(bounds);
		}
		//Render map with filter data
		function renderWithFilterData(data) {
			var filterData = new google.maps.Polyline({
				path: data,
				geodesic: true,
				strokeColor: '#33D1FF',
				strokeOpacity: 1.0,
				strokeWeight: 4
			});
			console.log(filterCoordinates);
			if (filterCoordinates.length > 0) {
				console.log("filterCoordinates len > 0")
				filterData.setMap($scope.map);
			} else {
				initMap();
			}
			if (globalLocationData.length > 0) {
				var icon = {
					url: "/images/pulse.gif",
					scaledSize: new google.maps.Size(30, 30)
				};
				$scope.map.setCenter({
					lat: globalLocationData[globalLocationData.length - 1].latitude,
					lng: globalLocationData[globalLocationData.length - 1].longitude
				});
				var position = new google.maps.LatLng(globalLocationData[globalLocationData.length - 1].latitude,
					globalLocationData[globalLocationData.length - 1].longitude);
				var marker = new google.maps.Marker({
					position: position,
					map: $scope.map,
					icon: icon
				});
			}
		}

		function renderAgentPosition(markers, hideold) {
			console.log(markers)
			var bounds = new google.maps.LatLngBounds();
			$scope.map.setTilt(45);
			// Multiple Markers
			var markers = markers;
			var icon = {
				url: "/images/pulse.gif",
				scaledSize: new google.maps.Size(30, 30),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(15, 15)
			};
			var greyIcon = {
				url: "/images/pulse-red.gif",
				scaledSize: new google.maps.Size(30, 30),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(15, 15)
			};

			// Loop through our array of markers & place each one on the map
			for (var i = 0; i < markers.length; i++) {
				var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
				bounds.extend(position);
				if (todayDate == markers[i][5]) {
					var marker = new MarkerWithLabel({
						animation: google.maps.Animation.DROP,
						position: position,
						map: $scope.map,
						icon: icon,
						labelContent: markers[i][0],
						labelClass: "custom-class-for-label",
						labelAnchor: new google.maps.Point(-30, 20),
						labelInBackground: true

					});
				}
				else if (!hideold) {
					console.log("hideold :::", hideold)
					var marker = new MarkerWithLabel({
						animation: google.maps.Animation.DROP,
						position: position,
						map: $scope.map,
						icon: greyIcon,
						labelContent: markers[i][0],
						labelClass: "custom-class-for-old-label",
						labelAnchor: new google.maps.Point(-30, 20),
						labelInBackground: true

					});
				}
				// if(markers[i][3]) {
				// 	var circle = new google.maps.Circle({
				// 		radius: markers[i][3],
				// 		map: $scope.map,
				// 		fillColor: "#a2f9c8",
				// 		fillOpacity: 0.4,
				// 		strokeColor: "#a2f9c8",
				// 		strokeOpacity: 0.8
				// 	});
				// 	circle.bindTo('center', marker, 'position');
				// }
			}

			// Automatically center the map fitting all markers on the screen
			$scope.map.fitBounds(bounds);
			// Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
			var boundsListener = google.maps.event.addListener(($scope.map), 'bounds_changed', function (event) {
				this.setZoom(14);
				google.maps.event.removeListener(boundsListener);
			});
		}

		function locationOprationForWholesaler(data, hideold) {
			var result = [];
			if (data && data.data && data.data.locations) {
				result = data.data.locations;
			}
			var marker = [];

			async.forEachOf(result, function (val, key, cb) {
				if (val.agentName) {
					var markerArr = [], infoWindowContentArr = [], label = "";
					label = "<p class='m-0'>" + val.agentName + "</p><p class='m-0'><i class='fa fa-eye'></i> &nbsp;" + $moment(new Date(val.timestamp)).format("DD-MMM-YYYY HH:mm") + "</p>";

					// Add battery info
					if (val.batterylevel) {
						label += "<p class='m-0'><i class='fa fa-battery-three-quarters'></i> &nbsp;" + Math.round(val.batterylevel * 100) + "% </p>";
					}

					// Add battery info
					if (val.is_charging) {
						label += "<p class='m-0'><i class='fa fa-bolt'></i> &nbsp;Charger connected" + " </p>";
					}
					// Add Device Model
					if (val.deviceModel) {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp;" + val.deviceModel + " </p>";
					} else {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp; </p>";
					}

					// Add Platform and Version
					if (val.devicePlatform && val.osVersion) {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp;" + val.devicePlatform + "-" + val.osVersion + " </p>";
					} else {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp; </p>";
					}


					markerArr.push(label);
					markerArr.push(val.latitude);
					markerArr.push(val.longitude);
					markerArr.push(val.accuracy);
					markerArr.push(val);
					markerArr.push($moment(new Date(val.timestamp)).format("DD-MMM-YYYY"));
					marker.push(markerArr);
				}

				cb();
			}, function (err) {
				if (hideold) {
					renderAgentPosition(marker, true);
				} else {
					renderAgentPosition(marker, false)
				}
			})
		}
		var globalLocationData = [];
		function locationOprationForAgent(data) {
			globalLocationData = data.data.locations;
			console.log("Emptying filterCoordinates")
			filterCoordinates = [];
			var coordinates = "", timestamp = "", latLng = "";
			var arr = slidingwindow(data.data.locations, 1999);
			var marker = [];
			async.forEachOf(arr, function (val1, key1, cb1) {
				coordinates = "";
				timestamp = "";
				var filterLatLng = "https://devapi.scrapq.com/match/v1/driving/:coordinates?timestamps=:timestamp&tidy=true";
				async.forEachOf(val1, function (val2, key2, cb2) {
					var markerArr = [], infoWindowContentArr = [], label = "";
					label = "<p class='m-0'>" + val2.agentName + "</p><p class='m-0'><i class='fa fa-eye'></i> &nbsp;" + $moment(new Date(val2.timestamp)).format("DD-MMM-YYYY HH:mm") + "</p>";

					// Add battery info
					if (val2.batterylevel) {
						label += "<p class='m-0'><i class='fa fa-battery-three-quarters'></i> &nbsp;" + Math.round(val2.batterylevel * 100) + "% </p>";
					}

					// Add battery info
					if (val2.is_charging) {
						label += "<p class='m-0'><i class='fa fa-bolt'></i> &nbsp;Charger connected" + " </p>";
					}


					// Add Device Model
					if (val2.deviceModel) {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp;" + val2.deviceModel + " </p>";
					} else {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp; </p>";
					}
					// Add Device Model

					// Add Platform and Version
					if (val2.devicePlatform && val2.osVersion) {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp;" + val2.devicePlatform + "-" + val2.osVersion + " </p>";
					} else {
						label += "<p class='m-0'><i class='fa fa-mobile'></i> &nbsp; </p>";
					}
					markerArr.push(label);
					markerArr.push(val2.latitude);
					markerArr.push(val2.longitude);
					markerArr.push(val2.accuracy);
					markerArr.push(val2);
					markerArr.push($moment(new Date(val2.timestamp)).format("DD-MMM-YYYY"));
					marker.push(markerArr);
					// coordinates += val2.longitude + "," + val2.latitude;
					// timestamp += parseInt(new Date(val2.timestamp).getTime()/1000);
					// if(val1.length - 1 != key2){
					// 	coordinates += ";";
					// 	timestamp += ";";
					// }
					cb2();
					// renderAgentPosition(marker,true);
				}, function (err) {
					filterLatLng = filterLatLng.replace(":coordinates", coordinates);
					filterLatLng = filterLatLng.replace(":timestamp", timestamp);
					$.getJSON(filterLatLng).done(function (res) {
						if (res) {
							var sampleFilterPolyline = [];
							async.forEachOf(res.tracepoints, function (val3, key3, cb3) {
								if (val3 && val3.location) {
									var obj = {
										lat: val3.location[1],
										lng: val3.location[0]
									};
									sampleFilterPolyline.push(obj);
									var latLngArray = [];
									latLngArray.push(val3.location[1]);
									latLngArray.push(val3.location[0]);
									filterCoordinates.push(latLngArray);
								}
								cb3();
							}, function (err) {
								// renderWithFilterData(sampleFilterPolyline);
								cb1();
							})
						} else {
							cb1();
						}
					}).fail(function (jqxhr, textStatus, error) {
						cb1();
					})
					// renderAgentPosition(marker, true)
				})
			}, function (err) {
				if (filterCoordinates.length == 0) {
					// renderWithFilterData(filterCoordinates);
				}
				latLngBound();
				renderAgentPosition(marker)

			})
		}

		function getLocation(type) {
			if (type == "WHOLESALER") {
				wholesalerService.getWholesalerLocation($scope.data).then(function (res) {
					if (res.status == "SUCCESS") {
						if (res.data && res.data.locations && res.data.locations.length > 0) {
							initMapWithoutCenter();
						} else {
							initMap();
						}
						if ($scope.isHideOld) {
							locationOprationForWholesaler(res, true);
						} else {
							locationOprationForWholesaler(res, false);
						}
					} else {
						initMap();
					}
				}, function (err) {
					//console.log("Finally done");
				})
			}
			if (type == "AGENT") {
				agentService.getAgentLocation($scope.data).then(function (res) {
					if (res.status == "SUCCESS") {
						if (res.data && res.data.locations && res.data.locations.length > 0) {
							initMapWithoutCenter();
						} else {
							initMap();
						}
						locationOprationForAgent(res);
					} else {
						initMap();
					}
				}, function (err) {
					//console.log("Finally done");
				})
			}
		}
		var mapOptions = {
			zoom: 17,
			mapTypeId: 'roadmap',
			styles: [
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e9e9e9"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f5f5f5"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 29
						},
						{
							"weight": 0.2
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 18
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f5f5f5"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#dedede"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"saturation": 36
						},
						{
							"color": "#333333"
						},
						{
							"lightness": 40
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f2f2f2"
						},
						{
							"lightness": 19
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 17
						},
						{
							"weight": 1.2
						}
					]
				}
			]
		}
		//Inialize map without center
		function initMapWithoutCenter() {
			$scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
			getCurrentLocation();

		}
		// Map code
		function initMap() {
			$scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
			getCurrentLocation();
		}

		function getCurrentLocation() {
			var geocoder = new google.maps.Geocoder();

			geocoder.geocode({ 'address': $rootScope.loginData.city }, function (results, status) {
				if (status === 'OK') {
					$scope.map.setCenter(results[0].geometry.location);

				} else {
					alert('Geocode was not successful for the following reason: ' + status);
				}
			});
			// $global.getCurrentLocation().then(function (res) {
			// 	if (res) {
			// 		$scope.map.setCenter({ lat: res.latitude, lng: res.longitude });
			// 	}
			// }, function (err) {
			// 	console.log(err);
			// })
		}

		//Initial function call
		init();
		$scope.checkShowAll();
	});