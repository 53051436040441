'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddUserCtrl
 * @description
 * # AddUserCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddDocumentCtrl', function ($scope, authentication, $rootScope, vendorService, wholesalerService, agentService, documents, Upload, pricingService, products, consumer, $moment, reqId, from, $global, growl, $uibModalInstance, $state, $timeout, NgMap) {
    // $scope.setHeader = reqId;
    $scope.document_reqId = reqId;
    $scope.vm = {};
    $scope.agents = {
      agentid: null,
      commissiontypes: ["BACKGROUND CHECK DOCUMENT", "AADHAR", "PAN CARD", "REGISTRATION", "DRIVING LICENSE", "PASSPORT", "RATION CARD", "VOTER ID", "ST REGISTRATION", "OTHER"]
    };

    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    //$scope.url = $global.getApiUrl() + '/agentsData/import';
    $scope.userObj = { userId: reqId };

    $scope.documentTypesList = [];
    (function () {
      console.log($rootScope.loginData)
      let req = {
        city: $rootScope.loginData.city,
        userType: from
      };

      documents.retrieveDocumentsList(req).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.documentTypesList = res.data.documents;
          console.log($scope.documentTypesList);
        }
      })

    })();

    $scope.uploadImage = function ($event) {

      var file = $event.srcElement.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = function () {
        $scope.userObj.documentFormat = file.name.split(".")[1];
        $scope.userObj.document = "data:" + file.type + ":base64," + btoa(reader.result);
        console.log($scope.documentData);
      };
      reader.onerror = function () {
        growl.error("unable to add file please select again");
        $scope.userObj = { userObj: reqId };
      };

    }
    $scope.uploadUserDocs = function () {
      console.log($scope.userObj)
      if (from == "VENDOR") {
        $scope.userObj.userType = "VENDOR";
      }
      documents.uploadUserDocuments($scope.userObj).then(function (res) {
        growl.success("Successfully  Document Uploaded");
        $uibModalInstance.dismiss('cancel');
      })
    };
    $scope.submitDocument = function () {
      $scope.uploadUserDocs();
    }

    $scope.submitDocumentx = function (file) {
      $scope.submitted = true;
      var url = $scope.url;
      $scope.fileHash = false;
      $scope.progress = 0;
      $scope.hash = false;
      Upload.upload({
        url: url,
        file: file
      }).then(function (response) {
        $scope.updateData = {};
        if (from === "AGENT") {
          $scope.updateData.agentId = $scope.document_reqId;
        } else if (from == "WHOLESALER") {
          $scope.updateData.wholesalerId = $scope.document_reqId;
        } else if (from == "VENDOR") {
          $scope.updateData.vendorId = $scope.document_reqId;
        }
        $scope.updateData.documents = [];

        var tempdoc = {
          key: "",
          value: ""
        }

        tempdoc.key = $scope.data.agentComissionType;
        tempdoc.value = $scope.data.image;

        if (response.data.status == "SUCCESS") {
          tempdoc.value = response.data.data.fileId;

          $scope.updateData.documents.push(tempdoc);
          if (from === "AGENT") {
            agentService.addDocument($scope.updateData).then(function (res) {
              if (res.status == "SUCCESS") {
                growl.success("Successfully added");
                $uibModalInstance.dismiss('cancel');
              } else if (res.status == "FAILURE") {

              }
            });
          } else if (from === "WHOLESALER") {
            wholesalerService.addDocument($scope.updateData).then(function (res) {
              if (res.status == "SUCCESS") {
                growl.success("Successfully added");
                $uibModalInstance.dismiss('cancel');
              } else if (res.status == "FAILURE") {

              }
            });
          } else if (from === "VENDOR") {
            vendorService.addDocument($scope.updateData).then(function (res) {
              if (res.status == "SUCCESS") {
                growl.success("Successfully added");
                $uibModalInstance.dismiss('cancel');
              } else if (res.status == "FAILURE") {

              }
            });
          }
        }
        $scope.progress = 0;
      }, function (resp) {

      }, function (evt) {
        var progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
      });

    };

    $scope.cancel = function () {
      $uibModalInstance.close('Close');
    };
  });
