'use strict';

//$dialogue
angular.module('scrapQcrmApp')
		.controller('SlotOptimisationCtrl', function($scope, $rootScope, $uibModal, $log, $window, ngToast, agentService, SellNow, $moment, BoardService, BoardDataFactory, graphhopperService, agentAppointments, $timeout, $global, $q, Geocoder, Directions, Crossfilter) {

		$scope.Math = window.Math;

		$scope.data = {};
		var tomorrow  = $moment(new Date()).add(1,'days');

		$scope.offset = 1;
		
		$scope.toggleMap = true;

		function toast(message) {
	    ngToast.create({
			    content: '<a href="#" class="">' + message + '</a>',
			    dismissButton: true,
			    timeout: 10000
		    });
		 }

		$scope.vm = {
				params: {"city": $rootScope.loginData.city,"singledayquery": true,"todate":$moment(tomorrow).format('DD-MMM-YYYY'),"fromdate":$moment(tomorrow).format('DD-MMM-YYYY'),"page":-1}
		};

		$scope.slotTime = ['2 Hours','4 Hours','6 Hours', '12 Hours'];
		$scope.slotSelectedArr = [[],[],[],[]];
		
		$scope.agentArr = [];
		$scope.tempAgentArr = [];

		var agentData = null;

		$scope.combinedSlotHeading = [['06:00-08:00', '08:00-10:00', '10:00-12:00', '12:00-14:00', '14:00-16:00', '16:00-18:00'],['06:00-10:00','10:00-14:00','14:00-18:00'],['06:00-12:00','12:00-18:00'],['06:00-18:00']];
		var distTimeTemplate = {
			'distance' : '0 KM',
			'time':'0 MINUTES'
		}
		$scope.distAndTime = [[distTimeTemplate, distTimeTemplate, distTimeTemplate, distTimeTemplate, distTimeTemplate, distTimeTemplate],[distTimeTemplate,distTimeTemplate,distTimeTemplate],[distTimeTemplate,distTimeTemplate],[distTimeTemplate]];
		$scope.displayDistTime = $scope.distAndTime[0];
		$scope.mapIdArr = ['map1', 'map2','map3','map4','map5','map6'];
		$scope.mapName = ['$scope.map1','$scope.map2','$scope.map3','$scope.map4','$scope.map5','$scope.map6'];
		$scope.waypointsName = ['$scope.waypoints1','$scope.waypoints2','$scope.waypoints3','$scope.waypoints4','$scope.waypoints5','$scope.waypoints6'];

		SellNow.allRequests($scope.vm.params).then(function(res){
			console.log(res)
			res.data.sells.forEach(function(element, index){
				var template = {
						"name":"",
						"userId":"",
						"address":{}
					};
				if ($scope.tempAgentArr.indexOf(element.agentName) === -1 && element.agentName != "") {
						$scope.tempAgentArr.push(element.agentName)
						template.name = element.agentName;
						template.userId = element.agentObjId;
						template.address = element.agentAddress;
						$scope.agentArr.push(template);
					}
				
			});
		});

		function initMapWithoutCenter(){
			$scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
		}

		var directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
		var directionDisplay = [];
		var directionService = [];
		var directionsService = new google.maps.DirectionsService();
		var map;

		for(var i=0; i<6 ; i++){
			directionDisplay[i] = new google.maps.DirectionsRenderer({suppressMarkers: true});
			directionService[i] = new google.maps.DirectionsService();
			$scope.waypointsName[i] = [];
		}

		var myOptions;

		$scope.allOptimised = true;

		function initialize() {

				myOptions = {
						zoom: 17,
						mapTypeId: google.maps.MapTypeId.ROADMAP,
						mapTypeControl: false,
						streetViewControl: false,
						scaleControl: false,
						fullscreenControl : false,
						center: new google.maps.LatLng(17.3944809,78.4417806)
				}
				$scope.map = new google.maps.Map(document.getElementById("map"), myOptions);

				directionsDisplay.setMap($scope.map);
		}

		initialize();

		function getCurrentLocation(){
			$global.getCurrentLocation().then(function(res){
				if(res){
					$scope.map.setCenter({lat: res.latitude, lng: res.longitude});
				}
			}, function(err){
				console.log(err);
			})
		}

		$scope.mapOptions = {
			center: new google.maps.LatLng(50, 0),
			zoom: 9,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};

		getCurrentLocation();

		var slotAppntArr = [[],[],[],[],[],[]];

		var agentLat, agentLong;

		$scope.selectSlotTime = function(){
			$scope.slotApntArr = [];
			var indexofSelectedSlot = $scope.slotTime.indexOf($scope.dropdownSlot);
			$scope.getWaypointsAndInitMap($scope.slotSelectedArr[indexofSelectedSlot],indexofSelectedSlot);
			$scope.slotHeading = $scope.combinedSlotHeading[indexofSelectedSlot];
			$scope.slotApntArr = $scope.slotSelectedArr[indexofSelectedSlot];
		}

		$scope.selectAgent = function(val, timepadding, isOrderChange){
			slotAppntArr = [[],[],[],[],[],[]];
			$scope.slotTwoHours = [[],[],[],[],[],[]];
			$scope.slotApntArr = [];
			val = $scope.dropdownvalue;
			if (!isOrderChange) {
				isOrderChange = false;
			}
			agentData = val;
			$scope.data.agentId = val.userId;
			$scope.selectedUser = val.name;
			$scope.search = $scope.selectedUser;

			agentLat = parseFloat(val.address.latitude);
			agentLong =  parseFloat(val.address.longitude);

			agentAppointments.getTomorrowAgentAppointments($scope.data.agentId, tomorrow.format('DD-MMM-YYYY')).then(function (appointmentdata){		
				var data = appointmentdata.data.sells;
				var optimisedCount = 0;

				data.forEach(function(element,index){
					var indx = element.preferredSlotId;
					if(element.order > 0){
						element['optimisedBoolen'] = "True";
						optimisedCount += 1;
					}else{
						element['optimisedBoolen'] = "False";
					}
					slotAppntArr[indx].push(element);
				});
				$scope.setSlotData(slotAppntArr);
				$scope.slotTwoHours = slotAppntArr;

				$scope.slotApntArr = angular.copy($scope.slotTwoHours);
				$scope.slotHeading = angular.copy($scope.combinedSlotHeading[0]);

				if (optimisedCount == data.length) {
					$scope.allOptimised = false;
					setwaypoints($scope.slotTwoHours);
				}else{	
					$scope.allOptimised = true;
					$scope.getWaypointsAndInitMap($scope.slotTwoHours,0);
				}

			});
		}

		$scope.setSlotData = function(slotAppntArr){
			$scope.slotSelectedArr = [[],[],[],[]];
			$scope.slotTwoHours = [[],[],[],[],[],[]];
			$scope.slotFourHours = [[],[],[]];
			$scope.slotSixHours = [[],[]];
			$scope.slotTwelveHours = [[]];
			$scope.slotTwoHours = angular.copy(slotAppntArr);

			var count = 0;
			slotAppntArr.forEach(function(element,index){
				if (index == 0 || index == 1) {
					if (element.length > 1) {
						element.forEach(function(elem,indx){
							$scope.slotFourHours[0].push(elem);
							$scope.slotSixHours[0].push(elem);
							$scope.slotTwelveHours[0].push(elem);
						});
					}if (element.length == 1) {
						$scope.slotFourHours[0].push(element[0]);
						$scope.slotSixHours[0].push(element[0]);
						$scope.slotTwelveHours[0].push(element[0]);
					}
				}
				if (index == 2 || index == 3) {
					if (element.length > 1) {
						element.forEach(function(elem,indx){
							$scope.slotFourHours[1].push(elem);
							if (index == 2) {
								$scope.slotSixHours[0].push(elem);
							}else{
								$scope.slotSixHours[1].push(elem);
							}
							$scope.slotTwelveHours[0].push(elem);
						});
					}if (element.length == 1) {
						$scope.slotFourHours[1].push(element[0]);
						if (index == 2) {
								$scope.slotSixHours[0].push(element[0]);
							}else{
								$scope.slotSixHours[1].push(element[0]);
							}
						$scope.slotTwelveHours[0].push(element[0]);
					}
				}
				if (index == 4 || index == 5) {
					if (element.length > 1) {
						element.forEach(function(elem,indx){
							$scope.slotFourHours[2].push(elem);
							$scope.slotSixHours[1].push(elem);
							$scope.slotTwelveHours[0].push(elem);
						});
					}if (element.length == 1) {
						$scope.slotFourHours[2].push(element[0]);
						$scope.slotSixHours[1].push(element[0]);
						$scope.slotTwelveHours[0].push(element[0]);
					}
				}
			});

			$scope.slotSelectedArr[0] = angular.copy($scope.slotTwoHours);
			$scope.slotSelectedArr[1] = angular.copy($scope.slotFourHours);
			$scope.slotSelectedArr[2] = angular.copy($scope.slotSixHours);
			$scope.slotSelectedArr[3] = angular.copy($scope.slotTwelveHours);
		}


		$scope.getWaypointsAndInitMap = function(slotArr,indexofSelectedSlot){
			setTimeout(function(){
				$scope.toggleMap = false;

				//calculating distance:
				slotArr.forEach(function(element,index){
					if (element.length > 1) {
						element.forEach(function(elem,indx){
								var lat = parseFloat(elem.consumerAddress.latitude);
								var long = parseFloat(elem.consumerAddress.longitude);
								element[indx]["distance"] = calculateDistance(agentLat, agentLong, lat, long);
						});
					}
				});

				//sorting slots based on distance:
				if (slotArr.length > 1) {
					slotArr.forEach(function(element,index){
						if (element.length > 1) {
							element.sort(function(a, b) { 
								return a.distance - b.distance;
							});
						}
					});
				}
				if (slotArr.length == 1) {
					slotArr[0].sort(function(a, b) {
					  return a.distance - b.distance;
					});
				}

				//waypoints for main map
				setwaypoints(slotArr);
				
				//waypoints
				var count = 0;
				var end,nextStart;
				var destinationLoc, lat, long, marker;
				slotArr.forEach(function(element,index){
					$scope.waypointsName[index] = [];
					if (element.length > 0 ) {
						count += 1;
					}
					if (element.length == 1) {
						$scope.mapName[index] = new google.maps.Map(document.getElementById($scope.mapIdArr[index]), myOptions);
						directionDisplay[index].setMap($scope.mapName[index]);
						lat = parseFloat(element[0].consumerAddress.latitude);
						long = parseFloat(element[0].consumerAddress.longitude);
						end = new google.maps.LatLng(lat,long);

						//marker for end
						setMarker(end,$scope.mapName[index],element[0].confirmationId,"labels-confirmation");

						// marker for start
						if (count == 1) {
							setMarker(new google.maps.LatLng(agentLat, agentLong), $scope.mapName[index],"Agent Location","labels-agent");
							nextStart = new google.maps.LatLng(agentLat, agentLong);
						}else{
							setMarker(nextStart, $scope.mapName[index],"Start","labels-start");
						}

						directionRequest(nextStart, new google.maps.LatLng(lat,long), null, false, directionDisplay[index],indexofSelectedSlot,index);

						nextStart = new google.maps.LatLng(lat,long);
					}
					// Contains more than one Appointment
					if (element.length > 1) {
						$scope.mapName[index] = new google.maps.Map(document.getElementById($scope.mapIdArr[index]), myOptions);
						directionDisplay[index].setMap($scope.mapName[index]);
						element.forEach(function(elem,indx){
							lat = parseFloat(elem.consumerAddress.latitude);
							long = parseFloat(elem.consumerAddress.longitude);
							if (indx != element.length-1) {
								//setting marker for waypoint
								setMarker(new google.maps.LatLng(lat, long),$scope.mapName[index],elem.confirmationId,"labels-confirmation");

								$scope.waypointsName[index].push({
										location: new google.maps.LatLng(lat, long),
										stopover:true
									});
							}if (indx == element.length-1){
								end = new google.maps.LatLng(lat,long);
								destinationLoc = new google.maps.LatLng(lat,long); 
								setMarker(new google.maps.LatLng(lat,long),$scope.mapName[index],elem.confirmationId,"labels-confirmation");
							}
							if (count == 1) {
								setMarker(new google.maps.LatLng(agentLat, agentLong), $scope.mapName[index],"Agent Location","labels-agent");
								nextStart = new google.maps.LatLng(agentLat, agentLong);
							}else{
								setMarker(nextStart, $scope.mapName[index],"Start","labels-start");
							}
						});	
					
					directionRequest(nextStart, destinationLoc,  $scope.waypointsName[index], true, directionDisplay[index],indexofSelectedSlot,index);
					nextStart = destinationLoc;
					}
				});
			}, 1000);
		};

		function directionRequest(start,stop, waypoints, waypointsBoolean, directionsRequest,indexofSelectedSlot,indexinSlot){
			var request = {};
			var request = {
				origin: start,
				destination: stop,
				waypoints: waypoints,
				optimizeWaypoints: waypointsBoolean,
				travelMode: google.maps.DirectionsTravelMode.DRIVING
			};
			var totalDist = 0 ;
			var totalTime = 0 ;
			directionsService.route(request, function(response, status) {
					console.log("RESPONSE :::::::",response)
				if (status == google.maps.DirectionsStatus.OK) {
					directionsRequest.setDirections(response);
					console.log(indexofSelectedSlot,indexinSlot)
					console.log(isNaN(indexofSelectedSlot) , isNaN(indexinSlot != null))
					console.log(!(isNaN(indexofSelectedSlot))&& !(isNaN(indexinSlot)))
					if (!(isNaN(indexofSelectedSlot))&& !(isNaN(indexinSlot))) {	
						for (i = 0; i < response.routes[0].legs.length; i++) {
						    totalDist += response.routes[0].legs[i].distance.value;
						    totalTime += response.routes[0].legs[i].duration.value;
						}
						totalDist = totalDist / 1000;
						var h = Math.floor(totalTime / 3600);
					    var m = Math.floor(totalTime % 3600 / 60);

					    var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
					    var mDisplay = m > 0 ? m + (m == 1 ? " Minute " : " Minutes ") : "";
					    var distTimeTemplate = {};
						distTimeTemplate.distance = totalDist+" Km";
						distTimeTemplate.time = hDisplay + mDisplay ;
						console.log(distTimeTemplate)
						console.log($scope.distAndTime[indexofSelectedSlot])
						$scope.distAndTime[indexofSelectedSlot][indexinSlot] = distTimeTemplate;
					}else{	
						for (i = 0; i < response.routes[0].legs.length; i++) {
						    totalDist += response.routes[0].legs[i].distance.value;
						    totalTime += response.routes[0].legs[i].duration.value;
						}
						totalDist = totalDist / 1000;
						var h = Math.floor(totalTime / 3600);
					    var m = Math.floor(totalTime % 3600 / 60);

					    var hDisplay = h > 0 ? h + (h == 1 ? " Hour " : " Hours ") : "";
					    var mDisplay = m > 0 ? m + (m == 1 ? " Minute " : " Minutes ") : "";
					    var distTimeTemplate = {};
						$scope.totalDistance = totalDist+" Km";
						$scope.totalTime =hDisplay + mDisplay ;
					}
				}
			});
			$scope.displayDistTime = $scope.distAndTime[indexofSelectedSlot];
			console.log($scope.displayDistTime)
		}

		function setwaypoints(slotApntArr){
			initialize();
			var end = []	;
			var finalWaypoints = [];
			var lat ,long,latitude,longitude
			slotApntArr.forEach(function(element,index){
				if (element.length == 1) {
					lat = parseFloat(element[0].consumerAddress.latitude);
					long = parseFloat(element[0].consumerAddress.longitude);
					setMarker(new google.maps.LatLng(lat,long),$scope.map,element[0].confirmationId,"labels-confirmation");
					finalWaypoints.push({
						location: new google.maps.LatLng(lat, long),
						stopover:true
					});
					end[0] = {'location':new google.maps.LatLng(lat,long)};
				}if (element.length > 1) {
					element.forEach(function(elem , indx){
						latitude = parseFloat(elem.consumerAddress.latitude);
						longitude = parseFloat(elem.consumerAddress.longitude);
						setMarker(new google.maps.LatLng(latitude, longitude),$scope.map,elem.confirmationId,"labels-confirmation");
						finalWaypoints.push({
							location: new google.maps.LatLng(latitude, longitude),
							stopover:true
						});
					});
					// end = finalWaypoints.splice(-1,1)
				}
				
			});
			setMarker(new google.maps.LatLng(agentLat, agentLong), $scope.map,"Agent Location","labels-agent");
			if (finalWaypoints.length == 1) {
				end = finalWaypoints[0];
				console.log(end)
				directionRequest(new google.maps.LatLng(agentLat, agentLong), end.location,  null, false, directionsDisplay,"abc","abc");
			}else if(finalWaypoints.length == 0){
				directionRequest(new google.maps.LatLng(agentLat, agentLong), end.location,  null, false, directionsDisplay,"abc","abc");
			}
			else{
				end = finalWaypoints.splice(-1,1)
				directionRequest(new google.maps.LatLng(agentLat, agentLong), end[0].location,  finalWaypoints, false, directionsDisplay,"abc","abc");
			}
		}


		function setMarker(location,map,text,labClass){
			var marker = new MarkerWithLabel({
				position: location,
				map:map,
				draggable: false,
				labelContent:text,
				labelClass:labClass,
				icon: {
					url:"https://1x1px.me/FF4D00-0.8.png",
					scale: 0
				},
				size: new google.maps.Size(0, 0),
				optimized: false,
				visible: true
			});
			marker.setMap(map);
		}

		var sellOrderData = [];
		var orderData = {
      "confirmationId":"",
      "order":0,
      "preferredDate":"",
      "preferredSlot":0
    };

		$scope.setSellOrderData = function() {
			if (confirm("Are you sure you want to set order for following Appointments?")) {
		    var order = 0 ;

		    $scope.slotApntArr.forEach(function (elemnt, indx) {
		    	console.log(elemnt)
		      if(elemnt.length > 0 ){
		      	if (elemnt.length == 1) {
		      		order += 1;
		      		var orderData = {
					      "confirmationId":elemnt[0].confirmationId,
					      "order":order,
					      "preferredDate":tomorrow.format(),
					      "preferredSlot":indx
					    };
		          sellOrderData.push(orderData);
		          console.log(orderData)
		      	}
		      	if (elemnt.length > 1) {
		      		elemnt.forEach(function(elm,idx){
		      			order += 1;
		      			var orderData = {
						      "confirmationId":elm.confirmationId,
						      "order":order,
						      "preferredDate": tomorrow.format(),
						      "preferredSlot":indx
						    };
			          sellOrderData.push(orderData);
			          console.log(orderData)
		      		});
		      	}
		      }
		    });
		    console.log(sellOrderData)
		    var requestData = {};
		    requestData['sellOrderVMS'] = sellOrderData;
		    requestData['agentId'] = $scope.data.agentId;
		    console.log(requestData)
		    SellNow.sellOrder(requestData).then(function(res){
		      if (res.status == "SUCCESS") {
		        $scope.itemsMoved = true;
		        toast("Order Saved Scucessfully");
		      }else{
		        toast("Failure in saving order");
		        $scope.itemsMoved = false;
		      }
		    });
		  }
		};

		function calculateDistance(lat1, lon1, lat2, lon2) {
			var radlat1 = Math.PI * lat1/180
			var radlat2 = Math.PI * lat2/180
			var radlon1 = Math.PI * lon1/180
			var radlon2 = Math.PI * lon2/180
			var theta = lon1-lon2
			var radtheta = Math.PI * theta/180
			var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			dist = Math.acos(dist)
			dist = dist * 180/Math.PI
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			return Math.round(dist * 100) / 100;
		}

		$scope.OpenRequest = function(pickup) {
			var request =  {'confirmationId':pickup}
      var pickupInstance = $uibModal.open({
          templateUrl: 'views/consumer-pickups-modal.html',
          controller: 'ConsumerPickupsModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
              items: function() {

                  return request;
              }
          }
      });

      pickupInstance.result.then(function(selectedItem) {
          $scope.selected = selectedItem;
      }, function() {
          $log.info('Modal dismissed at: ' + new Date());
      });
  }

		// function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
		//   var R = 6878; // Radius of the earth in km
		//   var dLat = deg2rad(lat2-lat1);  // deg2rad below
		//   var dLon = deg2rad(lon2-lon1); 
		//   var a = 
		//     Math.sin(dLat/2) * Math.sin(dLat/2) +
		//     Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
		//     Math.sin(dLon/2) * Math.sin(dLon/2)
		//     ; 
		//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		//   var d = R * c; // Distance in km
		//   return Math.round(d * 100) / 100;
		// };
		// function deg2rad(deg) {
		//   return deg * (Math.PI/180)
		// };

		// Center the map based on the user's current location
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function(position) {
				$scope.map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
				return $scope.$apply();
			}
			, function(error) {
				$scope.alerts.push("We weren't able to determine your current location.");
				return $scope.$apply();
			});
		}

	});
