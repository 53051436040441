'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddUserCtrl
 * @description
 * # AddUserCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('ProfileImageCtrl', function($scope, authentication, wholesalerService, agentService, Upload, pricingService, products, $moment, items, invokedby, corpimage, $global, growl, $uibModalInstance) {
    $scope.documentagentid = items;
    $scope.vm = {};
    $scope.agents = {
      agentid: null,
    };
    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    $scope.myImage = '';
    $scope.myCroppedImage = '';
    $scope.corpimage = corpimage;
    $scope.usertype = invokedby;
    
    
    $scope.submitDocument = function(dataUrl, name) {
      $scope.submitted = true;
      var url = $scope.url;
      $scope.fileHash = false;
      $scope.progress = 0;
      $scope.hash = false;
      
      Upload.upload({
        url: url,
        file: Upload.dataUrltoBlob(dataUrl, name)
      })
      .then(function(response) {
        $scope.updateData = {};
        if ($scope.usertype === "agent") {
          $scope.updateData.agentId = $scope.documentagentid;
        }
        if ($scope.usertype === "consumer") {
          $scope.updateData.consumerId = $scope.documentagentid;
        }
        $scope.updateData.profileImage = null;
        
        
        if (response.data.status === "SUCCESS") {
          if (!corpimage) {
            $scope.updateData.profileImage = response.data.data.fileId;
          } else {
            $scope.updateData.orgImage = response.data.data.fileId;
          }
          
          if ($scope.usertype === "consumer") {
            $scope.updateData.dataType = "consumer";
          }
          if ($scope.usertype === "agent") {
            $scope.updateData.dataType = "agent";
          }
          agentService.updateProfile($scope.updateData)
            .then(function(res) {
              if (res.status === "SUCCESS") {
                growl.success("Successfully added");
                $uibModalInstance.dismiss('cancel');
              } else {
                $global.displayLog("Failed to upload image", 'res', res);
                growl.error('Failed to add image');
              }
            })
            .catch(function(err) {
              console.error("Error in updating agent with profile image ", err);
            });
        }
        $scope.progress = 0;
      }, function(resp) {
        $scope.errorMsg = resp.status + ': ' + resp.data;
        console.error('Error status: ' + resp.status);
      }, function(evt) {
        var progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
      });
    };
  
    $scope.cancel = function() {
      $uibModalInstance.close('Close');
    };
  });
