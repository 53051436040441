'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.holiday
 * @description
 * # holiday
 * Service in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .service('platformSetting', function(httpService, $global, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            addSetting: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addSetting;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getAllSettings: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getAllSettings.replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            updateSetting: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().updateSetting;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getEnabledSettings: function(isVendorPayments) {
                var city = $rootScope.loginData.city;
                isVendorPayments = (isVendorPayments===true) ? isVendorPayments : false;
                var url = $global.getApiUrl() + $global.getApiObject().getEnabledSettings.replace(":city",city) + "?isVendorPayments=" + isVendorPayments;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }
        };
    });
