'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:IncomingInventoryCtrl
 * @description
 * # IncomingInventoryCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp').controller('IncomingInventoryCtrl', function($scope, $rootScope, $global, $uibModal, $window, SellNow, growl, $log, warehouseService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {
       
        $scope.data = {};
        $scope.pages = -1;
        $scope.vm = { params: {} };
        $scope.currentPage = 1;

        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        console.log($rootScope.loginData)

        $scope.selectedWarehouse = null;

        $scope.date = {
            startDate: $moment().format("DD-MMM-YYYY"),
            endDate: $moment().format("DD-MMM-YYYY")
        };

        warehouseService.getWarehouse().then(function(res) {
            console.log("Warehouse Query sent");
            console.log(res);
            if (res.status == "SUCCESS") {
                if(res.data.warehouses.length > 1){
                    $scope.warehouses = res.data.warehouses;
                    $scope.warehouses = {
                            warehouseid: null,
                            availableOptions: res.data.warehouses
                        };
                    $scope.warehouse_list = res.data.warehouses;;
                    if(res.data.message == "No Warehouse found"){
                        $scope.noWarehouseFound = true;
                    }
                }else {
                    $scope.warehouse_list = res.data.warehouses;
                }

            } else if (res.status == "FAILURE") {
                $scope.noWarehouseFound = true;
            } else {
                $scope.noWarehouseFound = true;
            }
        }, function(err) {

        });

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.noInventoryFound = false;

        $scope.vm = {
            params: {"city": $rootScope.loginData.city}
        };

        $scope.transactionChange = function() {
            $scope.vm.params.page = -1;
            $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');

            console.log($scope.vm.params.todate,$scope.vm.params.fromdate);

            if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
                $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate,$scope.selectedWarehouse);
            } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
                $scope.warehouseid = $rootScope.loginData.userid;
                $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate,$scope.warehouseid);
                $scope.wholesaler_list = false;
            }
        };


        $scope.addItems = function() {
            if ($rootScope.loginData.role == 'WAREHOUSEUSER') {
                var modalInstance = $uibModal.open({
                    templateUrl: 'views/add-incoming-inventory.html',
                    controller: 'AddIncomingItemsCtrl',
                    size: "lg",
                    backdrop: 'static',
                    resolve: {
                        items: function() {
                            return $rootScope.loginData.userid;
                        }
                    }
                });

                modalInstance.result.then(function(selectedItem) {
                    $scope.selected = selectedItem;
                    console.log("Modal closed")
                    if ($rootScope.loginData.role == "WAREHOUSEUSER") {
                        $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate, $rootScope.loginData.userid);
                    }else if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
                        $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate, $scope.selectedWarehouse);
                    }
                }, function() {
                    $log.info('Modal dismissed at: ' + new Date());
                    if ($rootScope.loginData.role == "WAREHOUSEUSER") {
                        $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate, $rootScope.loginData.userid);
                    }else if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
                        $scope.loadInventory($scope.vm.params.fromdate,$scope.vm.params.todate, $scope.selectedWarehouse);
                    }
                });
            }

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDisplayLength($scope.length)
            .withOption('order', [1, 'desc']);

        $scope.$watch('date', function(newDate) {

            $scope.transactionChange();

        }, false);

        $scope.nextDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.endDate).add(1, "days").format('DD-MMM-YYYY'),
                endDate: moment($scope.date.endDate).add(1, "days").format('DD-MMM-YYYY')
            };

            if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
                $scope.loadInventory($scope.date.startDate, $scope.date.endDate,$scope.selectedWarehouse);
            } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
                $scope.warehouseid = $rootScope.loginData.userid;
                $scope.loadInventory($scope.date.startDate, $scope.date.endDate, $scope.warehouseid);
                $scope.wholesaler_list = false;
            }
        }
        $scope.previousDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.startDate).subtract(1, "days").format('DD-MMM-YYYY'),
                endDate: moment($scope.date.startDate).subtract(1, "days").format('DD-MMM-YYYY')
            };
            if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
                $scope.loadInventory($scope.date.startDate, $scope.date.endDate,$scope.selectedWarehouse);
            } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
                $scope.warehouseid = $rootScope.loginData.userid;
                $scope.loadInventory($scope.date.startDate, $scope.date.endDate, $scope.warehouseid);
                $scope.wholesaler_list = false;
            }
        }

        $scope.selectWarehouse = function(){
        	console.log("SELCCTED WAREHOUSE :",$scope.dropdownvalue);
        	console.log("DATA :: ",$scope.data)

        	warehouseService.getProfile($scope.data.warehouseId).then(function(res){
                console.log("IN warehouse service")
                if (res.status == "SUCCESS") {
                    console.log($scope.date.startDate, $scope.date.endDate)
                    $scope.selectedWarehouse = res.data.warehouse.warehouseId;
                    $scope.loadInventory($scope.date.startDate, $scope.date.endDate, res.data.warehouse.warehouseId)
                    if(res.data.message == "No records found"){
                        $scope.noWarehouseFound = true;
                    }
                }
            });
        };

        $scope.loadInventory = function(fromDate,toDate,warehouseId,page,size){
            console.log(warehouseId)
            console.log(fromDate)
            console.log(toDate)
            if (warehouseId) {
                warehouseService.getWarehouseInventory(fromDate,toDate,warehouseId,null,null).then(function(res){
                    console.log(res)
                    if (res.status == "SUCCESS") {
                        $scope.inventory_list = res.data.incomingInventoryVMList;
                        console.log($scope.inventory_list)
                        if (res.data.totalRecords > 0) {
                            $scope.noInventoryFound = false;
                        }else{
                            $scope.noInventoryFound = true;
                        }
                    }
                });
            }else{
                warehouseService.getWarehouseInventory(fromDate,toDate,null,null,null).then(function(res){
                    console.log(res)
                    if (res.status == "SUCCESS") {
                        $scope.inventory_list = res.data.incomingInventoryVMList;
                        console.log($scope.inventory_list)
                        if (res.data.totalRecords > 0) {
                            $scope.noInventoryFound = false;
                        }else{
                            $scope.noInventoryFound = true;
                        }
                    }
                });
            }
        };

        

        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
            var today = $moment(new Date()).format('DD-MMM-YYYY');
            console.log(today)
            $scope.loadInventory(today,today);
        } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
            $scope.warehouseid = $rootScope.loginData.userid;
            $scope.loadInventory(today,today,$scope.warehouseid);
            $scope.wholesaler_list = false;
        }

    });
