'use strict';

angular.module('scrapQcrmApp')
    .controller('CelektProductWiseReportsController', function ($scope, $http, authentication, $state, $global, $filter, $moment, $stateParams, $rootScope, agentAppointments, dashboard, $uibModal, $log, consumer, prospect, growl, BidService) {

        $scope.inrFilter = $filter('INR');

        $scope.date = {
            startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
            endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
        };

        $scope.vm = {
            params: { "city": $rootScope.loginData.city }
        };

        $rootScope.loginData = $global.getLocalItem("loginData", true);

        (function () {
            var data = {
                "city": $rootScope.loginData.city,
                "userType": $rootScope.loginData.role,
                "module": "Reports",
                "subModule": "ProductWise",
                "type": "Celekt"
            };
            authentication.getUserRoleMetrics(data).then(function (res) {
                if (res.status == "SUCCESS") {
                    $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
                    $scope.gridOptions.columnDefs.forEach(function (col, index) {
                        if ($scope.disabledFeilds.includes(col.field)) {
                            $scope.gridOptions.columnDefs.splice(index, 1);
                        }
                    });
                    $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs);
                } else {
                    $scope.disabledFeilds = [];
                }
            }).catch(function (err) {
                $scope.disabledFeilds = [];
            })
        })();

        (function () {
            let columnDefs = [
                {
                    headerName: "",
                    field: "uom",
                    maxWidth: 40,
                    cellRenderer: 'agGroupCellRenderer'
                },
                {
                    headerName: "Item",
                    field: "categoryName",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "UOM",
                    field: "uom",
                    maxWidth: 200,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Total RRRs",
                    field: "totalAgents",
                    maxWidth: 200,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Total Appointments",
                    field: "totalAppointments",
                    maxWidth: 200,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Quantity",
                    field: "totalQuantity",
                    maxWidth: 200,
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer
                }
            ];
            console.log($scope.disabledFeilds)
            columnDefs.forEach(function (colObj) {
                colObj.headerTooltip = colObj.headerName;
            })
            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                tooltipShowDelay: 0,
                suppressCsvExport: false,
                suppressExcelExport: false,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                masterDetail: true,
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                field: 'appointmentId',
                                cellStyle: { 'textAlign': 'center' }
                            },
                            {
                                headerName: 'Consumer',
                                field: 'consumerName'
                            },
                            {
                                headerName: 'RRR',
                                field: 'agentName'
                            },
                            {
                                headerName: 'Date',
                                field: 'preferredDate',
                                cellStyle: { 'textAlign': 'center' },
                                valueFormatter: params => params.value ? $moment(params.value).format('DD-MM-YYYY HH:mm') : ""
                            },
                            {
                                field: 'item',
                            },
                            {
                                field: 'quantity',
                                maxWidth: 120,
                                valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00',
                                cellStyle: { 'textAlign': 'right' }
                            },
                            {
                                field: 'couponCode',
                                cellStyle: { 'textAlign': 'center' }
                            }
                        ],
                        defaultColDef: {
                            flex: 1,
                            minWidth: 150,
                            filter: true,
                            sortable: true,
                            resizable: true
                        },
                        rowHeight: 39,
                    },
                    getDetailRowData: function (params) {
                        let data = {
                            "fromDate": $scope.vm.params.fromdate,
                            "toDate": $scope.vm.params.todate,
                            "city": $rootScope.loginData.city,
                            "productName": params.data.categoryName
                        };
                        console.log('params: ', params);
                        agentAppointments.getCelektProductWiseSubGridReport(data).then((res) => {
                            if (res.status !== "SUCCESS") {
                                console.error(res);
                                return;
                            } else {
                                $scope.subReports = res.data.reports;
                                params.successCallback($scope.subReports);
                            }
                        });
                    },
                },
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.salesAgentsData);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            }; //End of gridOptions//


            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '0.00';
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };
            function DateCellRenderer() {
            }
      
            DateCellRenderer.prototype.init = function (params) {
              this.eGui = document.createElement('div');
              if (params.data != "" || params.data != undefined || params.data != null) {
                var iconsDiv = "<div>" + params.data.date + "</div>";
                this.eGui.innerHTML = iconsDiv;
              }
            };
      
            DateCellRenderer.prototype.getGui = function () {
              return this.eGui;
            };
        })();

        (function () {

            $scope.loadData = function () {

                let data = {
                    "fromDate": $scope.vm.params.fromdate,
                    "toDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                };


                agentAppointments.getCelektProductWiseReport(data).then(function (res) {
                    $scope.labelCounts = {
                        totalUniqueProducts: res.data && res.data.totalUniqueProducts ? res.data.totalUniqueProducts : 0,
                        totalQty: res.data && res.data.totalQty ? $scope.inrFilter(res.data.totalQty) : "0.00"
                    }
                    if (res.status.toUpperCase() == "SUCCESS") {
                        $scope.reports = res.data.reports;
                        console.log($scope.reports);
                        $scope.gridOptions.api.setRowData($scope.reports);
                    } else {
                        if (res.status.toUpperCase() == "FAILURE" && res.error && res.error.code == "EC_MAX_DATE_RANGE_EXCEEDED") {
                            growl.error(res.error.message);
                        } else {
                            $scope.reports = [];
                            $scope.gridOptions.api.setRowData($scope.reports);
                        }
                    }
                })
            }
            $scope.loadData();

        })();

        console.log('4stateparam    : ', $stateParams.fromdate);
        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        //Watch for date changes
        $scope.$watch('date', function (newDate) {

            $scope.selectedRange = $('#daterange3').data('daterangepicker').chosenLabel;

            let months = monthDiff(new Date($scope.date.startDate), new Date($scope.date.endDate));
            if (months <= 6) {
                $scope.transactionChange();
            } else {
                growl.error("Invalid date range, max allowed date range is 6 months only");
            }
        }, false);

        $scope.transactionChange = function () {

            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;

            if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
                $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
                $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

                if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
                    $scope.vm.params.singledayquery = true;
                }
                $scope.loadData();
            } else {
                console.error("Invalid date, please select valid date range");
                console.error("startDate = ", $scope.date.startDate);
                console.error("endDate = ", $scope.date.endDate);
                growl.error("Invalid date, please select valid date range");
            }
        };


        $scope.nextDate = function () {
            $scope.paging = false;
            $scope.date = $global.getNextDateRanges($scope.selectedRange, $scope.date.endDate);
            // $scope.date = {
            //     startDate: moment($scope.date.endDate).add(1, "days"),
            //     endDate: moment($scope.date.endDate).add(1, "days")
            // };
            $scope.transactionChange();
        }
        $scope.previousDate = function () {
            $scope.paging = false;
            $scope.date = $global.getPrevDateRanges($scope.selectedRange, $scope.date.startDate);
            // $scope.date = {
            //     startDate: moment($scope.date.startDate).subtract(1, "days"),
            //     endDate: moment($scope.date.startDate).subtract(1, "days")
            // };
            $scope.transactionChange();
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });
