'use strict';

angular.module('scrapQcrmApp')
  .controller('activitiesCtrl', function($timeout, $state, SellNow, $uibModal, $scope, $filter, $rootScope, $global, $moment, $stateParams, dashboard) {
    var stop;
    $scope.REFRESH_TIME = 90000;
    $scope.completion = 0;
    $scope.acceptance = 0;
    $scope.declined = 0;
    $scope.reschedule = 0;
    $scope.arrivals = 0;
    var startDate = new Date();
    var getDate = startDate.getDate() - (1);
    startDate.setDate(getDate);
    var endDate = new Date();
    $scope.date = {
      startDate: $moment(startDate, 'DD-MMM-YYYY').toDate(),
      endDate: $moment(endDate, 'DD-MMM-YYYY').toDate()
    };
    
    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()]
      }
    };
    var data = {
      "fromDate": null,
      "toDate": null
    }
    
    function tConvert(time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
    
    function ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    }
    
    $scope.init = function() {
      $scope.noLogHistory = false;
      console.log("Here 1");
      dashboard.activitiesLog(data).then(function(res) {
        console.log("Here 2");
        if (res.status == "SUCCESS") {
          
          if (res.data && res.data.activity && res.data.activity.length > 0) {
            res.data.activity = res.data.activity.sort(function(a, b) {
              return parseInt(b.performedOn) - parseInt(a.performedOn);
            });
            
            for (var i in res.data.activity) {
              res.data.activity[i].performedOn = $filter('date')(new Date(res.data.activity[i].performedOn), 'MMM d, y h:mm a');
            }
            $scope.activity = res.data.activity;
            // $scope.newActivity = [];
            // var confirmationid, object = {
            // 	confirmationid: null,
            // 	data: []
            // };
            // function createObject(confirmationid, data){
            // 	this.confirmationid = confirmationid;
            // 	this.data = data;
            // 	$scope.newActivity.push(this);
            // }
            for (var k = 0; k < $scope.activity.length; k++) {
              if ($scope.activity[k].description.match(/Request Completed/i)) {
                $scope.completion += 1;
              }
              if ($scope.activity[k].description.match(/Request Accepted/i)) {
                $scope.acceptance += 1;
              }
              if ($scope.activity[k].description.match(/Declined/i)) {
                $scope.declined += 1;
              }
              if ($scope.activity[k].description.match(/Date Changed|Slot Changed/i)) {
                $scope.reschedule += 1;
              }
              if ($scope.activity[k].description.match(/Arrived/i)) {
                $scope.arrivals += 1;
              }
              
              // if(($scope.activity[k].confirmationid != confirmationid) || !$scope.activity[k+1]){
              // 	if(confirmationid){
              // 		object.confirmationid = confirmationid;
              // 		var newObject = new createObject(confirmationid, object.data);
              // 		object.confirmationid = null;
              // 		object.data = [];
              // 	}
              // 	confirmationid = $scope.activity[k].confirmationid;
              // 	delete $scope.activity[k].confirmationid;
              // 	object.data.push($scope.activity[k]);
              // }else{
              // 	delete $scope.activity[k].confirmationid;
              // 	object.data.push($scope.activity[k]);
              // }
            }
          } else {
            $scope.noLogHistory = true;
          }
        } else {
          $scope.noLogHistory = true;
        }
      }, function(err) {
        //console.log(err);
        $scope.noLogHistory = true;
      })
      $scope.refresh();
    }
    
    //Watch for date changes
    $scope.$watch('date', function() {
      data.fromDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
      data.toDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
      $scope.init();
    }, false);
    
    $scope.removeCustomRang = function() {
      $(".daterangepicker .ranges > ul li").each(function(index, item) {
        if ($(item).attr("data-range-key") == "Custom range") {
          $(item).css("display", "none");
        }
      })
    }
    
    $scope.openAppointment = function(val) {
      SellNow.getSellById(val).then(function(res) {
        if (res.status == "SUCCESS") {
          var pickupInstance = $uibModal.open({
            templateUrl: 'views/consumer-pickups-modal.html',
            controller: 'ConsumerPickupsModalCtrl',
            size: "lg",
            backdrop: 'static',
            resolve: {
              items: function() {
                return res.data.sell;
              }
            }
          });
          pickupInstance.result.then(function(selectedItem) {
            $scope.selected = selectedItem;
          }, function() {
          });
        }
      }, function(err) {
      })
    }
    
    $scope.refresh = function() {
      stop = $timeout(function() {
        $scope.init();
      }, $scope.REFRESH_TIME);
    }
    
    
    $scope.$on('$destroy', function() {
      // Make sure that the interval is destroyed too
      $timeout.cancel(stop);
    });
  });
