'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.area
 * @description
 * # area
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('area', function(httpService, $global, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            addArea: function(data) {
                var city = $rootScope.loginData.city;
                data.city = city;
                var url = $global.getApiUrl() + $global.getApiObject().addArea;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getArea: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getArea.replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            editArea: function(data) {
                var city = $rootScope.loginData.city;
                data.city = city;
                var url = $global.getApiUrl() + $global.getApiObject().editArea;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            deleteArea: function(id) {
                var url = $global.getApiUrl() + $global.getApiObject().deleteArea.replace(":areaId",id);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }
        };
    });
