'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.dashboard
 * @description
 * # dashboard
 * Service in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .service('dashboard', function(httpService, $global, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      statistics: function() {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().dashboard.replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      complaints: function(param) {
        param.page -= 1;
        var params = "?" + $global.objToQueryString(param);
        var url = $global.getApiUrl() + $global.getApiObject().complaints + params;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      complaintRemark: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().complaintRemark;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      pendingRequests: function(param) {
        param.page -= 1;
        var params = "?" + $global.objToQueryString(param);
        var url = $global.getApiUrl() + $global.getApiObject().pendingRequests + params;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      unavailable: function(status) {
        
        var url = $global.getApiUrl() + $global.getApiObject().unavilable.replace(":status", status);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      sellCount: function(date) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().sellCount.replace(":date", date).replace(":city", city);
        if ($rootScope.loginData.role == "WHOLESALER") {
          url += "&wholesalerid=" + $rootScope.loginData.userid;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getCalendar: function(from, to) {
        var agentId = $global.agentId;
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getCalendar.replace(":from", from).replace(":to", to).replace(":city", city);
        if ($rootScope.loginData.role == "WHOLESALER") {
          url += "&wholesalerid=" + $rootScope.loginData.userid;
        }
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getAllCity: function() {
        var url = $global.getApiUrl() + $global.getApiObject().getAllCity;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      activitiesLog: function(data) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().activitiesLog.replace(":fromDate", data.fromDate).replace(":toDate", data.toDate).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }
    };
  });
