'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.logistics-service
 * @description
 * #logistics-service
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('logisticsService', function(httpService, $global, $http, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            addLynkBooking: function(data){
                var url = $global.getApiUrl() + $global.getApiObject().addLynkBooking;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getLogisticsBooking: function(type,key) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getLogisticsBooking.replace(":userType", type).replace(":text", key).replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },getBooking: function(logisticsId) {
                var logisticsId = logisticsId;
                var url = $global.getApiUrl() + $global.getApiObject().getBooking.replace(":id", logisticsId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getTrackingData: function(logisticsId) {
                var logisticsId = logisticsId;
                var url = $global.getApiUrl() + $global.getApiObject().getTrackingData.replace(":id", logisticsId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },getProfile: function(logisticsId) {
                var logisticsId = logisticsId;
                var url = $global.getApiUrl() + $global.getApiObject().getLogisticsBookingDetails.replace(":id", logisticsId);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },loadBooking: function(type, page, startDate, endDate, nodecrement) {
                var url;
                var city = $rootScope.loginData.city;
                if (page) {
                    if (!nodecrement) {
                        page = page - 1;
                    }
                    url = $global.getApiUrl() + $global.getApiObject().loadBooking + "?startDate=" + startDate + "&endDate=" + endDate;
                } else {
                    url = $global.getApiUrl() + $global.getApiObject().loadBooking + "?startDate=" + startDate + "&endDate=" + endDate;
                }
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },cancelBooking: function(orderNo, reasonCode){
                var url = $global.getApiUrl() + $global.getApiObject().cancelBooking.replace(":orderNo", orderNo).replace(":reasonCode", reasonCode);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            }
        };
    });
