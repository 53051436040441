'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.consumer
 * @description
 * # consumer
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('apartments', function(httpService, $global, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            getAllApartments: function(page) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getAllApartments;

                 // if page == -1, the api fetches all the records
                if (page && page != '-1') {
                    page = page - 1;
                    url = $global.getApiUrl() + $global.getApiObject().getAllApartments+"?page=" + page+"&city="+city;
                } else if(page == '-1') {
                    url = $global.getApiUrl() + $global.getApiObject().getAllApartments+"?page=" + page+"&city="+city;
                  }else {
                    url = $global.getApiUrl() + $global.getApiObject().getAllApartments+"&city="+city;
                }
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getApartmentById: function(apartmentId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getApartmentById.replace(":id",apartmentId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, 
            retrieveAppointmentById: function(appointmentId) {
                console.log(appointmentId)
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveCommunityAppointmentById.replace(":id",appointmentId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            retrieveSellsForAppointment: function(appointmentId) {
                var url = $global.getApiUrl() + $global.getApiObject().retrieveSellsForAppointment.replace(":id",appointmentId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            retrieveItemsForAppointment: function(appointmentId) {
                var url = $global.getApiUrl() + $global.getApiObject().retrieveItemsForAppointment.replace(":id",appointmentId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            retrieveApartmentAppointments: function(apartmentId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveApartmentAppointments.replace(":id",apartmentId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getAddressesByUserType: function(data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getAddressesByUserType;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            editApartment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().editApartment;
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            updateApartment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().updateApartment;
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            deleteApartment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().deleteApartment;
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            deleteBlock: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().deleteBlock;
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            addApartment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().addApartment
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            createCommunityAppointment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().createCommunityAppointment
                var $request = httpService.httpRequest(url, "P",data);
                return $request;
            },
            searchApartments: function(text) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().searchApartments.replace(":text",text).replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            cancelAppointment: function(appointmentId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().cancelAppointment.replace(":id",appointmentId);
                var $request = httpService.httpRequest(url, "P", "");
                return $request;
            },
            completeAppointment: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().completeAppointment;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            assignApointmentToAgent: function(data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().assignApointmentToAgent;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            updateAppointment: function(appointmentId,data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().updateAppointment.replace(":id",appointmentId);
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            }
        };
    });