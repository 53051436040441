'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:WholesalersCtrl
 * @description
 * # WholesalersCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('PricingCtrl', function($scope, $global, $uibModal, $window, SellNow, growl, $log, pricingService, $stateParams) {
        console.log("loaded pricing ctrl")
         $scope.loadWholesalers = function() {
            var type = 'WHOLESALER';
            $scope.vm = {};
            $scope.noPricingFound = false;
                pricingService.getPricing().then(function(res) {
                    if (res.status == "SUCCESS") { //&& res.data.usersuserType == "AGENT"
                        console.log(res.data.priceVM)

                        $scope.pricing_list = res.data.priceVM;
                        $scope.length0 = false;
                        $scope.agentId = false;
                        if(res.data.message == "No records found"){
                            $scope.noPricingFound = true;
                        }
                    } else if (res.status == "FAILURE") {
                        $scope.noPricingFound = true;
                    } else {
                        $scope.noPricingFound = true;
                    }
                }, function(err) {

                })
        };
        $scope.loadWholesalers();

        $scope.wholesalerDetails = function(val) {
            $scope.length0 = true;
            var details = $scope.wholesalers.users[val];
            $scope.wholesalerId = details.userId;
            getWholesalerProfile($scope.wholesalerId);
            $scope.wholesaler_list = false;
            $scope.vm.params = {
                "wholesaler": details.userId,
                "size": 10,
                "page": 0,
                "status": "pending"
            };
            getWholesalerAppointments($scope.vm.params);
        }

        $scope.showPricerate = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/wholesaler-prices.html',
                controller: 'PriceCtrl',
                size: "md",
                resolve: {
                    items: function() {
                        return;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                console.log('Modal dismissed at: ' + new Date());
            });
        };

        $scope.editWholesalerDetails = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/wholesaler-edit-profile.html',
                controller: 'WholesalerEditProfileCtrl',
                size: "md",
                resolve: {
                    items: function() {
                        return $scope.profileEdit.agentId;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                console.log('Modal dismissed at: ' + new Date());
                getWholesalerProfile($scope.wholesalerId);
            });
        };

        $scope.addPricing = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-pricing.html',
                controller: 'AddPricingCtrl',
                size: "lg",
                resolve: {
                    items: function() {
                        return 'PRICING';
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                 $scope.loadWholesalers();
            });

        };

        $scope.submit = function() {
            if ($scope.data.pricingDetails) {
                pricingService.addPricing($scope.data).then(function(res) {
                    $uibModalInstance.dismiss(res);
                })

            } else {
                $scope.objName = "Please Add Pricing";
            }

        };

    });
