'use strict';

/**
 * @ngdoc directive
 * @name scrapQApp.directive:orgChart
 * @description
 * # orgChart
 */
angular.module('scrapQcrmApp')
    .directive('orgChart', function($log) {

    function drawChart($scope, element, attrs) {
        var chart = new google.visualization.OrgChart(element[0]);
        $scope.$watch('chartData', function (value, oldvalue) {
            if (!value) {
                return;
            }
            var data = google.visualization.arrayToDataTable(value);
            var options = {
                'title': '',
                'allowHtml': true,
                'allowCollapse': true
            }
            chart.draw(data, options);
            // chart.collapse(0,true);
            value.forEach((element,index) => {
                let type="" + element[2].split(",")[1];
                // $log.info("element",element,type, type==="PARTNER",index)
                if(type==="PARTNER"){
                    chart.collapse(index-1,true);
                }
            });
        })
    }

    return {
        link: drawChart
    };
});