'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddLeadCtrl
 * @description
 * # AddLeadCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddLeadCtrl', function ($scope, area, authentication, wholesalerService, warehouseService, consumer, salesAgentService, $moment, items, action, userData, mode, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope) {
    $scope.data = {};
    $scope.userData = {};
    $scope.vm = {};
    $scope.city = $rootScope.loginData.city;
    $scope.showSubStatus = false;
    // $scope.assignSalesButtonDisable = true;
    $scope.location = {
      latitude: 0,
      longitude: 0
    };
    $scope.latitude = 0.00;
    $scope.longitude = 0.00;
    $scope.addressDetails = {};
    $scope.todayDate = new Date(); // appointments cannot be created for previous dates
    $scope.salesPersonDetails = {}; //salesPersonDetails needed while assigning Leads, creating appointments
    $scope.userTypesList = [
      "Customer",
      "Vendor",
      "Partner",
      "REO",
    ];

    // setLocation is written top of edit, in order to get the address in prepopulated state.

    $scope.setLocation = function (obj) {

      console.log("setLocation obj", obj)
      var center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
      console.log("what is the chosen place", $scope.chosenPlace);
      $scope.latitude = obj.lat;
      $scope.longitude = obj.lng;
      // setPlaceObject(obj);

      //address to fetch all address details
      $global.getLocationByLatLng(obj).then(function (res) {
        var checkAddress = $global.getAddressObj(res);
        $scope.addressDetails = checkAddress;
        console.log("What is addressDetails setPlaceObject", $scope.addressDetails);
        $scope.place = res;
        // $scope.vm.formattedAddress = res.formatted_address;
        console.log("setPlaceObject formattedAddress", $scope.vm.formattedAddress);
        $scope.chosenPlace = res.formatted_address;
        $scope.latitude = obj.lat;
        $scope.longitude = obj.lng;
      })
    };


    // display the content of the modal based upon add, edit or delete action
    $scope.addLeadAction = false;
    $scope.editLeadAction = false;
    $scope.assignLeadActionSingle = false;
    $scope.assignLeadActionMultiple = false;
    // $scope.action = action;
    if (action == "add") {
      $scope.addLeadAction = true;
      $scope.editLeadAction = false;
      $scope.assignLeadActionSingle = false;
      $scope.assignLeadActionMultiple = false;
    }
    if (action == "edit") {
      $scope.editLeadAction = true;
      $scope.addLeadAction = false;
      $scope.assignLeadActionSingle = false;
      $scope.assignLeadActionMultiple = false;

    }
    if (action == "assign" && mode == 'single') {
      $scope.assignLeadActionSingle = true;
      $scope.assignLeadActionMultiple = false;
      $scope.addLeadAction = false;
      $scope.editLeadAction = false;
    }
    if (action == "assign" && mode == 'multiple') {
      $scope.assignLeadActionSingle = false;
      $scope.assignLeadActionMultiple = true;
      $scope.addLeadAction = false;
      $scope.editLeadAction = false;
    }


    //retrieve userData to auto populate for edit action
    if (action == "edit" && userData) {
      $scope.userData = userData;
      console.log("What is userData",userData);
      if (userData.comments.length > 0) {
        $scope.comment = userData.comments[userData.comments.length - 1].comment;
      } else {
        $scope.comment = "";
      }

      //auto populate lat and lng on edit page
      if ($scope.userData.address.latitude && $scope.userData.address.longitude) {
        $scope.addressDetails.latitude = $scope.userData.address.latitude;
        $scope.addressDetails.longitude = $scope.userData.address.longitude;
      };
      $scope.editCategory = $scope.userData.categories;
      let obj = {}
      obj.lat = $scope.addressDetails.latitude;
      obj.lng = $scope.addressDetails.longitude;
      $scope.setLocation(obj);

      console.log("Can I add $scope.setLocation(obj) here??",$scope.addressDetails);

    }


    if (action == "assign" && userData) {

      if (mode == "single") { //userData is an object with Lead details in single mode
        $scope.salesLeadDetails = userData;
        
        //get salesPerson details in salesPersonDetails Obj, to editLead
        console.log("what is the userData", userData);
        if (userData.personIncharge) {
          $scope.salesPersonDetail = userData.personIncharge.mobileNo;
          $scope.salesPersonDetails.name = userData.personIncharge.name;
          $scope.salesPersonDetails.mobileNo = userData.personIncharge.mobileNo;
          $scope.salesPersonDetails.userId = userData.personIncharge.inchargeUserId;
        }

        if (userData.appointmentDuration) {
          $scope.appointmentDuration = userData.appointmentDuration;
        }
        if (userData.appointmentType) {
          $scope.appointmentType = userData.appointmentType;
        }
        if (userData.appointmentTime) {
          $scope.appointmentTime = new Date(userData.appointmentTime);
        }
        if (userData.appointmentDate) {
          $scope.appointmentDate = new Date(userData.appointmentDate);
        }
        // let idArray = [];
        // idArray.push($scope.salesLeadDetails._id);
      }
      if (mode == "multiple") { //userData is an array of ids in multiple mode
        $scope.salesPersonIdsArray = userData;
      }
    }



    //code to verify mobile
    $scope.disableAddLeadButton = false; // disable Add Lead if user Already Exists

    //$scope.notANumber
    $scope.notANumber = false;
    $scope.verifyUserPreExist = function (val, mode) {

      //check if the val is valid mobile number format
      // let numbers = /^[0-9]+$/;
      console.log("What is val", val, mode == "addMode", mode == "editMode");
      // let chkNum = val.value.match("/^[0-9]+$/")
      let chkNum = /^\d+$/.test(val);
      console.log("What is the status", chkNum);
      if (!chkNum) {
        $scope.notANumber = true;
        return;
      } else {
        $scope.notANumber = false;
      }

      let type = "CONSUMER"
      consumer.getConsumer(type, val).then(function (res) {
        if (res.status == "SUCCESS" && res.data.users.length > 0) {
          let userDetails = res.data.users[0];
          $scope.data.name = userDetails.name;
          $scope.disableAddLeadButton = true;
          if (mode == "addMode") {
            alert(`${userDetails.name} is already a registered customer.`);
            $scope.cancel()
          } else if (mode == "editMode") {
            alert(`${userDetails.name} is already a registered customer.`);
          }
        } else {
          let data = { "mobileNo": val };
          consumer.loadLead(data).then(function (res) {
            if (res && res.status == "Success" && res.data.length > 0) {
              let userDetails = res.data[0];
              $scope.data.name = userDetails.name;
              $scope.disableAddLeadButton = true;
              if (mode == "addMode") {
                alert(`${userDetails.name} is already a registered customer.`);
                $scope.cancel()
              } else if (mode == "editMode") {
                alert(`${userDetails.name} is already a registered customer.`);
              }
            }
          })
        }
      })
    }

    //code to capture subStatus if user is NotInterested
    $scope.showSubStatusColumn = function (value) {
      if (value) {
        $scope.showSubStatus = true;
      } else {
        $scope.showSubStatus = false;
      }
    }

    $scope.agentTypeArr = ["Residence", "Corporate"];

    $scope.category = null;
    if ((action == "add" || action == "edit") && userData) {
      $scope.setHeader = items;
      $scope.data.name = userData.name;
      $scope.data.mobileNo = userData.mobileNo;
      $scope.data.passCode = userData.password;
      $scope.data.emailId = userData.email;
      $scope.data.userType = userData.userType;
      $scope.vm.customadd = userData.address.landmark;
      $scope.vm.formattedAddress = userData.address.address;
      console.log("showSubStatusColumn formattedAddress", $scope.vm.formattedAddress);
      var center = [];
      center.push(userData.address.latitude);
      center.push(userData.address.longitude);
      $scope.center = center.join();
      $scope.category = userData.categories ? userData.categories.key : null;
      $scope.location = {
        latitude: userData.address.latitude,
        longitude: userData.address.longitude
      };
      $scope.chosenPlace = userData.address.address;
      console.log('$scope.data : ',$scope.data);
    } else {
      $scope.setHeader = items;
    }
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    var citymatcher = "";
    if ($rootScope.allCityData && $rootScope.allCityData.length > 0) {
      $rootScope.allCityData.forEach(function (elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
    }


    $scope.checkradio = function () {

      setTimeout(function () {
        var category = $scope.categories[$scope.categories.length - 1];
        $scope.categories = [];
        $scope.categories.push(category);
      }, 300);
    };

    if ($scope.setHeader = true) {

      //below code to get list of categories
      $scope.categories = [];
      consumer.userCategories().then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.categoriesList = res.data.categories;
        } else if (res.status === "FAILURE") {
          growl.error(res.error.message)
        }
      });

      //below code to get list of sales person from sales db
      consumer.getSalesPerson().then(function (res) {
        if (res.data.length > 0) {
          $scope.salesList = res.data;
          // console.log("What is salesList",$scope.salesPersonDetail);

          //code to autopopulate edit data if edit action is cliked.
          if (action == "edit") {
            for (let sales of $scope.salesList) {
              if (userData.personIncharge && userData.personIncharge.mobileNo == sales.mobileNo) {
                $scope.salesPersonDetails = sales;
              }
            }
          }

        } else {
          console.log("Error in fetching Sales Person List")
        }

      })
    }

    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng).then(function (res) {
        var checkAddress = $global.getAddressObj(res);
        $scope.addressDetails = checkAddress;
        console.log("What is addressDetails setPlaceObject", $scope.addressDetails);
        $scope.place = res;
        $scope.vm.formattedAddress = res.formatted_address;
        console.log("setPlaceObject formattedAddress", $scope.vm.formattedAddress);
        $scope.chosenPlace = res.formatted_address;
        $scope.latitude = latlng.lat;
        $scope.longitude = latlng.lng;
      })
    }

    function reRenderMap() {
      $timeout(function () {
        angular.forEach($scope.maps, function (map) {
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }

    $scope.setCurrentLocation = function () {
      $global.getCurrentLocation().then(function (res) {
        $scope.center = $scope.getLatLng(res);
        var rem = {};
        rem.lat = res.latitude;
        rem.lng = res.longitude;
        reRenderMap();
      });
    };

    $scope.getLatLng = function (obj) {

      if (obj && obj.latitude && obj.longitude) {
        var latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      }

    };
    //$scope.setCurrentLocation();

    $scope.placeChanged = function () {
      console.log("inside placeChanged")
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        var obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";

      }


    };

    

    var map;
    $scope.maps = [];
    $scope.$on('mapInitialized', function (evt, evtMap) {
      $scope.maps.push(evtMap);

    });


    NgMap.getMap().then(function (evtMap) {
      map = evtMap;
    });
    $scope.setLocations = function () {
      if ($scope.locationadd) {

      }
    };
    $scope.setCurrentLocation();
    $scope.markerDragEnd = function (event) {

      $timeout(function () {
        var latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);

      })
      // $scope.chosenPlace = $scope.vm.formattedAddress;
    };
    $scope.cancel = function () {
      $uibModalInstance.close('Close');
    };

    //code to add Lead to SalesScrapQ db
    $scope.addLead = function (address, latitude, longitude) {
      // let userId = $rootScope.loginData.username; //CHANGE THE NAME WITH userId against name string 
      let addressObj = {};
      // $scope.data.userId = userId;


      //add categories of the lead
      if ($scope.category) {
        console.log('$scope.category: ',$scope.category);
        let category = JSON.parse($scope.category);
        let categories = {};
        categories.key = category.consumerCategoryId;
        categories.value = category.name;
        $scope.data.categories = categories;
      } else {
        $scope.data.categories = { "key": "", "value": "" }; //api needs both key and value to save the data
      }

      //capture the address of the user
      addressObj.address = address;
      addressObj.city = $scope.city;
      addressObj.formattedAddress = address;
      addressObj.latitude = $scope.addressDetails.latitude;
      addressObj.longitude = $scope.addressDetails.longitude;
      addressObj.landmark = $scope.vm.customadd;
      addressObj.locality = $scope.addressDetails.locality;
      addressObj.state = $scope.addressDetails.state;
      addressObj.country = $scope.addressDetails.country;
      addressObj.postalcode = $scope.addressDetails.postalCode;

      $scope.data.address = addressObj;

      if ($scope.data.inchargeDetails) {
        let inchargeDetails = JSON.parse($scope.data.inchargeDetails);
        $scope.data.inchargeName = inchargeDetails.name;
        $scope.data.inchargeMobileNumber = inchargeDetails.mobileNo;
        $scope.data.inchargeUserId = inchargeDetails.userId;
        $scope.data.userId = inchargeDetails.userId; //SINCE NO USERID OF CRM IS PASSED ON TO SALES DB, ATTACHING THE SALES PERSON USERID FOR WORKAROUND 
      }
      if (!$scope.data.comment) {
        $scope.data.comment = "";
      }

      if ($scope.data.inchargeDetails) {  //If the sales person is assigned then the leadStatus shall automatically be in ASSIGNED state
        $scope.data.leadStatus = "ASSIGNED";
      } else {
        $scope.data.leadStatus = "LEAD";
      }

      console.log("what is add user data??", $scope.data);
      consumer.addLead($scope.data).then(function (res) {
        if (res && res.status == "Success") {
          growl.success("Lead added successfully")
          $scope.cancel(); //close the modal once Lead added successfully
          if (res.data.leadStatus == "LEAD") {
            $state.go("root.unassigned-lead");
          } else if (res.data.leadStatus == "ASSIGNED") {
            $state.go("root.assigned-lead");
          }
        } else {
          growl.error("Failed to add lead");
          $scope.cancel();
        }
      })

    };

    $scope.editLead = function (address, latitude, longitude) {

      // let userId = $rootScope.loginData.username; //CHANGE THE NAME WITH userId against name string 
      let addressObj = {};
      // $scope.userData.userId = userId;
      $scope.userData.id = $scope.userData._id;


      // get complete Address details while editing the lead

      //add categories of the lead
      console.log('$scope.category: ',$scope.editCategory);
      if ($scope.editCategory) {
        let category = JSON.parse($scope.editCategory);
        let categories = {};
        categories.key = category.consumerCategoryId;
        categories.value = category.name;
        $scope.userData.categories = categories;
      }

      //capture the address of the user
      if (address) {
        //capture the address of the user
        addressObj.address = address;
        addressObj.city = $scope.city;
        addressObj.formattedAddress = address;
        addressObj.latitude = $scope.addressDetails.latitude;
        addressObj.longitude = $scope.addressDetails.longitude;
        addressObj.landmark = $scope.vm.customadd;
        addressObj.locality = $scope.addressDetails.locality;
        addressObj.state = $scope.addressDetails.state;
        addressObj.country = $scope.addressDetails.country;
        addressObj.postalcode = $scope.addressDetails.postalCode;
        $scope.userData.address = addressObj;
      } else {
        $scope.userData.address = $scope.data.address
      }

      if ($scope.userData.personIncharge || $scope.userData.inchargeDetails) {
        if ($scope.userData.inchargeDetails || $scope.userData.personIncharge.name) {  // ADDING THE INTERESTED TEMPORARILY. NEED TO APPLY CONDITION AT UI
          $scope.userData.leadStatus = "ASSIGNED";
        } else {
          $scope.userData.leadStatus = "LEAD";
        }
      }

      if ($scope.userData.appointmentType) {
        $scope.userData.leadStatus = "APPOINTMENT";
      }

      if ($scope.userData.personIncharge || $scope.userData.inchargeDetails) {
        if ($scope.userData.inchargeDetails) {
          let inchargeDetails = JSON.parse($scope.userData.inchargeDetails);
          $scope.userData.inchargeName = inchargeDetails.name;
          $scope.userData.inchargeMobileNumber = inchargeDetails.mobileNo;
          $scope.userData.inchargeUserId = inchargeDetails.userId;
        } else if ($scope.userData.personIncharge.name) {
          $scope.userData.inchargeName = $scope.userData.personIncharge.name;
          $scope.userData.inchargeMobileNumber = $scope.userData.personIncharge.mobileNo;
          $scope.userData.inchargeUserId = $scope.userData.personIncharge.inchargeUserId;
        }
      }

      if ($scope.comment && $scope.comment != "") {
        $scope.userData.comment = $scope.comment;
      }

      console.log("what is userData updated",$scope.userData);

      consumer.updateLead($scope.userData).then(function (res) {
        if (res && res.status == "Success") {
          growl.success("Lead edited successfully");
          $scope.cancel(); //close the modal once Lead added successfully
        } else {
          growl.error("Failed to edit lead");
          $scope.cancel();
        }
      })
    };

    $scope.getSalesPerson = function (details) {
      // console.log("$scope.event",event);
      console.log("What is salesList", details);
      // console.log("ng change details", details);
      $scope.salesPersonDetails = details;
      // $scope.classApply = {};

    }

    $scope.assignLead = function (data) {
      let assignData = {};
      assignData.id = $scope.salesPersonIdsArray;
      assignData.inchargeName = $scope.salesPersonDetails.name;
      assignData.inchargeMobileNumber = $scope.salesPersonDetails.mobileNo;
      assignData.inchargeUserId = $scope.salesPersonDetails.userId;
      assignData.leadStatus = "ASSIGNED";
      consumer.assignSalesToLeads(assignData).then(function (res) {
        if (res && res.status == "Success") {
          growl.success("Leads assigned to sales person")
          $scope.cancel();
        } else {
          growl.error("Failed to assign leads to sales person")
          $scope.cancel();
        }
      })
    }

    $scope.assignLeadSingle = function () {

      // console.log("What is userData",$scope.userData,$scope.salesPersonDetails,$scope.salesLeadDetails);

      let assignData = {};
      let idArray = [];
      idArray.push($scope.salesLeadDetails._id);
      assignData.id = idArray;
      assignData.inchargeName = $scope.salesPersonDetails.name;
      assignData.inchargeMobileNumber = $scope.salesPersonDetails.mobileNo;
      assignData.inchargeUserId = $scope.salesPersonDetails.userId;
      assignData.leadStatus = "APPOINTMENT";
      assignData.appointmentDate = $scope.appointmentDate;
      assignData.appointmentTime = $scope.appointmentTime;
      assignData.appointmentDuration = $scope.appointmentDuration;
      assignData.appointmentType = $scope.appointmentType;


      consumer.assignSalesToLeads(assignData).then(function (res) {
        if (res && res.status == "Success") {
          growl.success("Lead assigned to sales person")
          $scope.cancel();
        } else {
          growl.error("Failed to assign lead to sales person")
          $scope.cancel();
        }
      })
    }

    $scope.searchForLocation = function () {
      let data = $scope.vm.formattedAddress;
      authentication.getLocations(data).then(function (res) {
        $scope.predictions = res.predictions;
        $scope.showPredictions = true;
      }).catch(function (err) {
        console.log("err", err);
      })
    };

    $scope.locationSelected = function (obj) {
      console.log("What is the obj", obj);
      $scope.vm.formattedAddress = obj.description;
      console.log("locationSelected formattedAddress", $scope.vm.formattedAddress);
      $scope.showPredictions = false;
      authentication.getLatLng(obj.place_id).then(function (res) {
        if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
          let place = res.result;
          let obj = {};
          obj.lat = place.geometry.location.lat;
          obj.lng = place.geometry.location.lng;

          //trying to get locality, state and country from res.result.adr_address
          let adr = res.result.adr_address
          $scope.setLocation(obj);

        } else {
          growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
          $scope.vm.formattedAddress = "";
          $scope.chosenPlace = "";
        }
      }).catch(function (err) {
        console.log("err", err);
      });
    }
  });
