'use strict';

angular.module('scrapQcrmApp')
	.controller('AddAreaCtrl', function ($scope, area, $rootScope, NgMap, $moment, $uibModalInstance, items, $global, $uibModal, $window, growl, $log, $stateParams) {
		console.log(items)
		$scope.data = {};
		$scope.polygonArray = [];
		$scope.daysChanged = false;
		$scope.title = items.title;
		$scope.data.serviceableDaysOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
		if (items.id) {
			$scope.data.areaName = items.area;
			$scope.data.appointmentsLimit = items.appointmentsLimit;
			$scope.data.serviceability = items.serviceability;
			$scope.data.serviceableDays = items.serviceableDays;
			$scope.data.pincode = items.pincode;
		}
		$scope.cancel = function (res) {
			$uibModalInstance.dismiss(res);
		};

		function getCurrentLocation() {
			$global.getCurrentLocation().then(function (res) {
				if (res) {
					if (!items && !items.id) {
						$scope.map.setCenter({ lat: res.latitude, lng: res.longitude });
					}
				}
			}, function (err) {
				console.log(err);
			});
		}

		var letters = '0123456789ABCDEF';
		function getRandomColor() {
			var color = '#'; // <-----------
			for (var i = 0; i < 6; i++) {
				color += letters[Math.floor(Math.random() * 16)];
			}
			return color;
		}

		function polygonCenter(poly) {
			var lowx,
				highx,
				lowy,
				highy,
				lats = [],
				lngs = [],
				vertices = poly;

			for (var i = 0; i < vertices.length; i++) {
				lngs.push(vertices[i].longitude);
				lats.push(vertices[i].latitude);
			}

			lats.sort();
			lngs.sort();
			lowx = lats[0];
			highx = lats[vertices.length - 1];
			lowy = lngs[0];
			highy = lngs[vertices.length - 1];
			var center_x = lowx + ((highx - lowx) / 2);
			var center_y = lowy + ((highy - lowy) / 2);
			return (new google.maps.LatLng(center_x, center_y));
		}

		function initMap() {
			var mapOptions = {
				center: new google.maps.LatLng(39.305, -76.617),
				zoom: 12
			}
			console.log("MAP OPTIONS", mapOptions)
			$scope.map = new google.maps.Map(document.getElementById('map1'), mapOptions);
			if (items.id) {
				$scope.LatLngObj = [];
				items.areaCoordinates.forEach(function (elem, idx) {
					if (elem.latitude != null && elem.longitude != null) {
						$scope.LatLngObj.push(new google.maps.LatLng(elem.latitude, elem.longitude));
					}
				});
				$scope.polygonObj = new google.maps.Polygon({
					paths: $scope.LatLngObj,
					strokeColor: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 3,
					fillColor: '#FF0000',
					editable: true,
					fillOpacity: 0.2

				});
				$scope.polygonObj.setMap($scope.map);

				area.getArea().then(function (res) {
					if (res.status == "SUCCESS") {
						if (res.data && res.data.area) {
							if (res.data.area.length > 0) {
								$scope.area = res.data.area;
								console.log($scope.area)
								$scope.area.forEach(function (element, index) {
									if (element.id != items.id) {
										var center = polygonCenter(element.areaCoordinates);
										var arry = [];
										element.areaCoordinates.forEach(function (elem, idx) {
											if (elem.latitude != null && elem.longitude != null) {
												arry.push(new google.maps.LatLng(elem.latitude, elem.longitude));
											}
										});
										if (arry != null) {
											element.LatLngObj = arry;
										}
										var color = getRandomColor();
										var polygonObj = new google.maps.Polygon({
											paths: element.LatLngObj,
											strokeColor: color,
											strokeOpacity: 0.8,
											strokeWeight: 3,
											fillColor: color,
											fillOpacity: 0.2
										});
										polygonObj.setMap($scope.map);
										var marker = new google.maps.Marker({
											map: $scope.map,
											position: center,
											label: { text: element.area, color: color, fontSize: "18px", fontWeight: "600" },
											icon: {
												path: google.maps.SymbolPath.CIRCLE,
												scale: 0
											}
										});
									} else {
										var center = polygonCenter(element.areaCoordinates);
										$scope.map.setCenter(center);
									}
								});
							}
						}
					}
				});
			} else {
				var drawingManager = new google.maps.drawing.DrawingManager({
					drawingMode: google.maps.drawing.OverlayType.POLYGON,
					drawingControl: true,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_CENTER,
						drawingModes: [google.maps.drawing.OverlayType.POLYGON]
					},
					polygonOptions: {
						fillColor: '#BCDCF9',
						fillOpacity: 0.5,
						strokeWeight: 3,
						strokeColor: '#57ACF9',
						clickable: false,
						editable: true,
						zIndex: 1
					}
				});
				drawingManager.setMap($scope.map);
				google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
					$scope.polygonObj = polygon;
					var polygonArr = [];
					console.log("in else")
					for (var i = 0; i < polygon.getPath().getLength(); i++) {
						var pointPair = polygon.getPath().getAt(i).toUrlValue(6);
						polygonArr.push(pointPair);
					}
					// polygonArray.push(polygon);
					$scope.polygonArray = polygonArr;
				});
				area.getArea().then(function (res) {
					if (res.status == "SUCCESS") {
						if (res.data && res.data.area) {
							if (res.data.area.length > 0) {
								$scope.area = res.data.area;
								console.log($scope.area)
								$scope.area.forEach(function (element, index) {
									var center = polygonCenter(element.areaCoordinates);
									var arry = [];
									element.areaCoordinates.forEach(function (elem, idx) {
										if (elem.latitude != null && elem.longitude != null) {
											arry.push(new google.maps.LatLng(elem.latitude, elem.longitude));
										}
									});
									if (arry != null) {
										element.LatLngObj = arry;
									}
									var color = getRandomColor();
									var polygonObj = new google.maps.Polygon({
										paths: element.LatLngObj,
										strokeColor: color,
										strokeOpacity: 0.8,
										strokeWeight: 3,
										fillColor: color,
										fillOpacity: 0.2
									});
									polygonObj.setMap($scope.map);
									var marker = new google.maps.Marker({
										map: $scope.map,
										position: center,
										label: { text: element.area, color: color, fontSize: "18px", fontWeight: "600" },
										icon: {
											path: google.maps.SymbolPath.CIRCLE,
											scale: 0
										}
									});
									if (index == 0) {
										var center = polygonCenter(element.areaCoordinates);
										$scope.map.setCenter(center);
									}
								});
							}
						}
					}
				});
			}
			getCurrentLocation();

		}

		$scope.clearCurrentPolygon = function () {
			if (items.id) {
				$scope.polygonObj.setMap(null);
				$scope.polygonObj = null;
				console.log("DrawingManager", drawingManager);
				if (drawingManager) {
					drawingManager.setMap(null);
					console.log("In IF");
				}
				var drawingManager = new google.maps.drawing.DrawingManager({
					drawingMode: google.maps.drawing.OverlayType.POLYGON,
					drawingControl: true,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_CENTER,
						drawingModes: [google.maps.drawing.OverlayType.POLYGON]
					},
					polygonOptions: {
						fillColor: '#BCDCF9',
						fillOpacity: 0.5,
						strokeWeight: 3,
						strokeColor: '#57ACF9',
						clickable: false,
						editable: true,
						zIndex: 1
					}
				});
				drawingManager.setMap($scope.map);
				google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
					drawingManager.setMap(null);
					$scope.polygonObj = polygon;
					$scope.polygonArray = [];
					var polygonArr = [];
					console.log("in else")
					for (var i = 0; i < polygon.getPath().getLength(); i++) {
						var pointPair = polygon.getPath().getAt(i).toUrlValue(6);
						polygonArr.push(pointPair);
					}
					// polygonArray.push(polygon);
					$scope.polygonArray = polygonArr;
					console.log($scope.polygonArray)
				});
			} else {
				$scope.polygonObj.setMap(null);
				$scope.polygonObj = null;
			}

		}

		$scope.addArea = function () {
			if (items.id) {
				$scope.data.id = items.id;
				console.log('polygonObje: ', $scope.polygonObj);
				if ($scope.polygonObj != null) {
					for (var i = 0; i < $scope.polygonObj.getPath().getLength(); i++) {
						var pointPair = $scope.polygonObj.getPath().getAt(i).toUrlValue(6);
						$scope.polygonArray.push(pointPair);
					}
				}

			}
			$scope.data.areaCoordinates = $scope.polygonArray;
			if (!items.id) {
				$scope.data.serviceability = true;
				area.addArea($scope.data).then(function (res) {
					if (res.status == "SUCCESS") {
						growl.success("Area Added Successfully!");
						setTimeout(function () { $uibModalInstance.dismiss(res); }, 800);
					} else if (res.error.code == "EC_AREA_NAME_EXISTS") {
						growl.error(res.error.message);
					} else if (res.error.code == "EC_INVALID_AREA_NAME") {
						growl.error("Invalid Area Name");
					}
				});
			}
			if (items.id) {
				console.log($scope.data.areaCoordinates)
				area.editArea($scope.data).then(function (res) {
					if (res.status == "SUCCESS") {
						if ($scope.daysChanged) {
							growl.success("Area modified. Please manually schedule appointments accordingly");
						} else {
							growl.success("Area modified Successfully!");
						}
						setTimeout(function () { $uibModalInstance.dismiss(res); }, 500);
					}
				});
			}
		}

		setTimeout(function () { initMap() }, 800);

	});
