'use strict';


/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:ProductsInventoryCtrl
 * @description
 * # ProductsInventoryCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('ProductsInventoryCtrl', function($scope, products, ngTableParams, localStorageService, $state, $stateParams, $global, $uibModal, $moment, saleOrderService, consumer, envService, editableOptions, growl, warehouseInventoryService) {
    
    /*============================================global values===============================================*/
    
      (function(){
        window.scrollTo(0, 0); // values are x,y-offset
        $scope.fromBalanceSheet=false;
        $scope.selectedProductName=null;
        $scope.clicked=false;
        $scope.clickedIndexes={};
        $scope.selectAll={selected:null};
        $scope.sizeToGet=12;
        $scope.noDataFound=false;
        $scope.pageIndex=0;
        $scope.totalPages=0;
        $scope.products=[];
        $scope.selectedItems=[];
        $scope.selectedSortId={sort_in:null};
        $scope.selectedCategory={category:null};
        $scope.categories=[];
        $scope.sort_options=[{name:"Increasing Quantity",id:"1"},{name:"Decreasing Quantity",id:"2"}];
        if($stateParams.sort!==null){
          $scope.selectedSortId.sort_in=$stateParams.sort;
          $stateParams.sort=null;
        }
        if($stateParams.category!==null){
          $scope.selectedCategory.category=$stateParams.category;
          $stateParams.category=null;
        }
        $scope.singleWarehouseSelected=false;
        $scope.itemNotSelected=true;
        $scope.searchingPhase=false;
        $scope.searchText={characters:null};
        $scope.showTotalQty=null;
        $scope.showTotalCost=null;
      })();
    
    /*============================================default values===============================================*/
    
    
    /*============================================default service API calls===============================================*/
    
    (function() {
      warehouseInventoryService.getCategories()
        .then(function(res) {
          console.log("res cats", res)
          if (res.status === "SUCCESS") {
            if (res && res.data && res.data.uniqueProductCategories) {
              let clist = res.data.uniqueProductCategories;
              $scope.categories.push({value: 0, categoryName: "All"})
              for (let i = 0; i < clist.length; i++) {
                let obj = {
                  value: i + 1,
                  categoryName: clist[i]
                };
                $scope.categories.push(obj);
              }
            } else {
              console.error("No Categories Found", res.data);
            }
          } else {
            console.error("Error in getting all the categories list ", res);
          }
        }).catch(function(error) {
        console.error("Error in getting all the categories list ", error);
      })
    })();
    

    $scope.getInventories=function(obj){
      if(obj && (obj.keyword || obj.sort || obj.productCategory)){
        $scope.pageIndex=0;
        $scope.products=[];
        $scope.clickedIndexes={};
      }
      
      if ($scope.selectedSortId.sort_in !== null && !obj.sort) {
        obj.sort = $scope.selectedSortId.sort_in;
      }

      if($stateParams.size!==null){
        obj.size=$stateParams.size;
        $stateParams.size=null;
      } else {
        obj.size=$scope.sizeToGet;
      }

      if($scope.selectedCategory.category){
        obj.productCategory=$scope.selectedCategory.category
      }
      
      // if($scope.searchingPhase) obj.size=null;
      console.log("getInventories here", obj);
      
      warehouseInventoryService.getProductInventories(obj)
        .then(function(res) {
          // if(obj && obj.keyword){
            $scope.products=[];
            $scope.clickedIndexes={};
          // }
          if (res.status === "SUCCESS") {
            console.log("api response", res.data);
            if (res && res.data && res.data.result && res.data.result.length > 0) {
  
              computeBadgesData(res.data.result);
              
              $scope.totalPages = res.data.totalPages;
              $scope.noDataFound = false;
              for (let i = 0; i < res.data.result.length; i++) {
                if (res.data.result[i]) {
                  $scope.products.push(res.data.result[i]);
                }
              }
              $scope.products.forEach(elem => {
                // elem.currentQuantity = Math.round(((elem.currentQuantity / 1000) + Number.EPSILON) * 1000) / 1000;
                elem.currentQuantity=Number((elem.currentQuantity).toFixed(2));
                let first = (new Date(elem.lastClearedDate)).toLocaleDateString(),
                  second = (new Date()).toLocaleDateString();
                let diff = datediff(parseDate(first), parseDate(second));
                // console.log("diff", diff, (new Date(elem.lastClearedDate)).toLocaleDateString(), (new Date()).toLocaleDateString());
                if (diff >= 30) {
                  elem.indicator = 1;
                } else if (diff >= 15 && diff < 30) {
                  elem.indicator = 2;
                } else {
                  elem.indicator = 3;
                }
              });
              // console.log("$stateParams ",$stateParams)
              // for back button in balance sheet on clicking to get back to the state on which balance sheet was open
              if($stateParams.index!==null){
                  console.log("$stateParams ",$stateParams);
                  $scope.fromBalanceSheet=true;
                  let clickedIndex=$stateParams.index;
                  $stateParams.index=null;
                  $scope.boxClicked($scope.products[clickedIndex],clickedIndex);
                }
            } else if(res && res.data && (!res.data.result || res.data.result.length===0)){
                $scope.noDataFound=true;
                // if($scope.searchingPhase) $scope.searchingPhase=false;
                for(let param in $stateParams){
                  $stateParams[param]=null;
                }
                growl.error("No Products Found");
            } else {
              $scope.noDataFound=true;
              if($scope.searchingPhase) $scope.searchingPhase=false;
              for(let param in $stateParams){
                  $stateParams[param]=null;
                }
              growl.error("Error in getting Products");
            }
          } else {
            console.error("Error in getting all the Products list ", res);
          }
        })
        .catch(function(error) {
          console.error("Error in getting all the Products list ", error);
        })
    };
    
    // triggered on scroll reaching to the end of the page - used to add pagination
    $scope.scrollToEndWindow = function(dir) {
      // alert('Window scrolled to ' + dir);
      if($stateParams.page!==null){
        $scope.pageIndex=$stateParams.page;
        $stateParams.page=null;
      }
      $scope.pageIndex+=1;

      console.log("$scope.pageIndex,$scope.totalPages",$scope.pageIndex,$scope.totalPages)
      if($scope.pageIndex>$scope.totalPages-1 && !$scope.fromBalanceSheet){
        growl.success("No More Products Found!");
        $scope.pageIndex -= 1;
        return;
      }
      let obj = {page: $scope.pageIndex};
      
      if ($scope.selectedSortId.sort_in !== null && !obj.sort) {
        obj.sort = $scope.selectedSortId.sort_in;
        console.log("adding sort", obj.sort)
      }

      if($stateParams.size!==null){
        obj.size=$stateParams.size;
        $stateParams.size=null;
      } else {
        obj.size=$scope.sizeToGet;
      }
      if($scope.selectedCategory.category){
        obj.productCategory=$scope.selectedCategory.category
      }
      
      if ($scope.searchText.characters) obj.keyword = $scope.searchText.characters;
      
      warehouseInventoryService.getProductInventories(obj)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            console.log("api response", res.data);
            
            if (res && res.data && res.data.result && res.data.result.length > 0) {
  
              computeBadgesData(res.data.result,true);
              
              res.data.result.forEach(elem => {
                // elem.currentQuantity = Math.round(((elem.currentQuantity / 1000) + Number.EPSILON) * 1000) / 1000;
                elem.currentQuantity=Number((elem.currentQuantity).toFixed(2));
                let first = (new Date(elem.lastClearedDate)).toLocaleDateString(),
                  second = (new Date()).toLocaleDateString();
                let diff = datediff(parseDate(first), parseDate(second));
                console.log("diff", diff, (new Date(elem.lastClearedDate)).toLocaleDateString(), (new Date()).toLocaleDateString());
                if (diff >= 30) {
                  elem.indicator = 1;
                } else if (diff >= 15 && diff < 30) {
                  elem.indicator = 2;
                } else {
                  elem.indicator = 3;
                }
                console.log(elem.indicator)
              });
              for (let i = 0; i < res.data.result.length; i++) {
                $scope.products.push(res.data.result[i]);
              }
            } else if(res && res.data && (!res.data.result || res.data.result.length===0)){
                $scope.pageIndex-=1;
                $scope.fromBalanceSheet=false;
                growl.success("No More Products Found");
            } else {
              $scope.pageIndex -= 1;
              growl.error("Error in getting Products");
            }
            console.log("$scope.products", $scope.products)
          } else {
            console.error("Error in getting all the products list ", res);
          }
        })
        .catch(function(error) {
          console.error("Error in getting all the products list ", error);
        });
    };
    
    // code to fetch searched Products
    $scope.searchProducts = function(key) {
      if (key) {
        key = key.trim();
        if (key.length > 2) {
          console.log("key", key, "$searchText", $scope.searchText);
          let obj = {keyword: key};
          $scope.searchingPhase = true;
          $scope.getInventories(obj);
        }
      } else if (key.length === 0) {
        $scope.pageIndex = 0;
        $scope.products = [];
        let obj = {};
        $scope.searchingPhase = false;
        console.log("$stateParams ", $stateParams)
        $scope.getInventories(obj);
      }
    };
    
    // code to fetch Products in selected sorting type
    $scope.sortProducts = function() {
      // if($scope.searchText.characters) $scope.searchText.characters=null;
      let obj={page:0,sort:$scope.selectedSortId.sort_in};
      if($scope.selectedCategory.category){
        obj.productCategory=$scope.selectedCategory.category
      }
      if($scope.searchText.characters) {
        obj.keyword=$scope.searchText.characters;
      }
      console.log("$scope.searchText.characters",$scope.searchText.characters)
      if($stateParams.page!==null){
         $stateParams.page=null;
      }
      $scope.getInventories(obj);
    };
    
    $scope.displaySelected = function() {
      // if($scope.searchText.characters) $scope.searchText.characters=null;
      let obj = {page: 0, productCategory: $scope.selectedCategory.category};
      if ($scope.selectedSortId.sort_in) {
        obj.sort = $scope.selectedSortId.sort_in
      }
      if($scope.searchText.characters) {
        obj.keyword=$scope.searchText.characters;
      }
      if($stateParams.page!==null){
         $stateParams.page=null;
      }
      $scope.getInventories(obj);
    };
    
    (function() {
      // when page was navigated to balance sheet after searching for that item and is navigated back
      console.log("$stateParams",$stateParams)
      if($stateParams.key && $stateParams.key!==null){
        $scope.searchText.characters=$stateParams.key;
        $stateParams.key=null;
        console.log("$scope.searchText",$scope.searchText)
        $scope.searchProducts($scope.searchText.characters);
      }
      else {
        let obj = {page: 0};
        $scope.getInventories(obj); //loads data on entering the page
      }
    })();
    /*============================================core functions===============================================*/
    
    // called on product box clicked
    $scope.boxClicked = function(obj,index){
        let flag=true;
        if(!$scope.clickedIndexes[index]){
          $scope.clickedIndexes[index]=true;
        } else {
          flag=false;
        }
        console.log("in boxClicked",obj,index);
        $scope.selectedIndex=index;

        let selectedObj=$scope.products[index];
        // $scope.showTotalQty=selectedObj.currentQuantity?selectedObj.currentQuantity + " tons":"0";
        $scope.showTotalQty=selectedObj.currentQuantity?(selectedObj.currentQuantity).toFixed(2) + " kgs":"0";
        $scope.selectedProductName=selectedObj.productName;
        $scope.warehouses_list=selectedObj.productInventories?selectedObj.productInventories:[];
        $scope.showTotalCost=0;
        if($scope.warehouses_list.length>0){
          $scope.warehouses_list.forEach( elem => {
            $scope.showTotalCost+=elem.totalCost;
          });
        }
        console.log("$scope.warehouses_list",$scope.warehouses_list);
        if(flag){
          $scope.warehouses_list.forEach(elem => {
              elem.select=false;
              elem.warn=false;
              if(elem.currentQuantity){
                  // elem.currentQuantity=Math.round(((elem.currentQuantity/1000) + Number.EPSILON) * 1000) / 1000;
                  elem.currentQuantity=Number((elem.currentQuantity).toFixed(2));
              }
          });
        }
        if($scope.warehouses_list.length>0){
          $scope.getSaleOrderForWarehouse("ALL", "ALL", $moment($global.projectInceptionDate).format("DD-MMM-YYYY"), $moment(new Date()).format("DD-MMM-YYYY"));
        }
        // }
        $scope.selectAll.selected=null;
        $scope.clicked=true;
    };
    
    // back button code
    $scope.goBack=function(){
        $scope.clicked=false;
        $scope.selectedProductName=null;
        $scope.singleWarehouseSelected=false;
        $scope.selectAll.selected=false;
        $scope.itemNotSelected=true
        $scope.warehouses_list.forEach(function(warehouse) {
          warehouse.select = $scope.selectAll.selected;
      });
      $scope.showTotalQty=null;
      $scope.showTotalCost=null;
    };
    
    // checks all of the items rows in each product's table
    $scope.checkAll = function() {
      $scope.selectedItems=[];
      $scope.singleWarehouseSelected=false;
      $scope.itemNotSelected=true;
      $scope.warehouses_list.forEach(function(warehouse) {
        warehouse.select = $scope.selectAll.selected;
      });
      if ($scope.selectAll.selected) {
        $scope.itemNotSelected = false;
        for (let i = 0; i < $scope.warehouses_list.length; i++) {
          $scope.selectedItems.push($scope.warehouses_list[i]);
        }
      }
    };
    
    
    $scope.itemMarked = function(obj, index) {
      // on selecting an item from the list
      if (obj.select) {
        $scope.selectedItems.push(obj);
        if ($scope.selectedItems.length === $scope.warehouses_list.length) {
          $scope.selectAll.selected = true; // marking the outer checkbox to true when all items are selected
        }
        $scope.itemNotSelected = false;
      } else {
        // on checking out of an item from the list
        $scope.selectAll.selected = false;
        console.log("$scope.selectAll", $scope.selectAll.selected);
        let position = $scope.selectedItems.indexOf(obj); // searching for checked out item's object in the selected item's list
        $scope.selectedItems.splice(position, 1); //removing it from the list
        if ($scope.selectedItems.length === 0) {
          $scope.itemNotSelected = true; // disabling Initiate clearance button
        }
      }
      if($scope.selectedItems.length===1){
        $scope.singleWarehouseSelected=true;
      } else {
        $scope.singleWarehouseSelected=false;
      }
    };

    // sort functionality
    $scope.changeSort = function(type) {
      if (type === "currentQuantity") {
        $scope.currentQuantitySort = !$scope.currentQuantitySort;
        $scope.sortType = "currentQuantity";
        $scope.sortValue = $scope.currentQuantitySort;
      }
      // doesn't sort properly in DD-MMM-YYYY format,but does in YYYY-MMM-DD format
      if (type === "lastClearedDate") {
        $scope.lastClearedDateSort = !$scope.lastClearedDateSort;
        $scope.sortType = "lastClearedDate";
        $scope.sortValue = $scope.lastClearedDateSort;
        
      }
      if (type === "totalCost") {
        $scope.totalCostSort = !$scope.totalCostSort;
        $scope.sortType = "totalCost";
        $scope.sortValue = $scope.totalCostSort;
      }
    };

    $scope.initiateTransport = function(){
      $global.displayLog("Inside initiate transport");
      let objToSend = {
                      id:$scope.selectedItems[0].warehouseId,
                      from:"Product",
                      index:$scope.selectedIndex,
                      item:$scope.selectedProductName,
                      sort:$scope.selectedSortId.sort_in,
                      size:$scope.products.length,
                      page:$scope.pageIndex,
                      category: $scope.selectedCategory.category,
                      key:null
      };
      if($scope.searchingPhase) {
        objToSend.key=$scope.searchText.characters; //storing the searched string as a parameter
      }
      objToSend["initiateTransportInventoryItems"] = {};
      objToSend["initiateTransportInventoryItems"]["selectedWarehouseId"] = $scope.selectedItems[0].warehouseId;
  
      let transportInventorySelectedItems = [];
      $scope.warehouses_list.forEach( elem => {
        if(elem.select && elem.currentQuantity>0){
            transportInventorySelectedItems.push(JSON.parse(JSON.stringify(elem))); //creating deep copy of the object
        }
      });
      transportInventorySelectedItems.forEach( elem => {
        if(elem.select && elem.currentQuantity > 0){
          elem.totalQuantity=elem.currentQuantity;
          delete elem.currentQuantity;
          elem.itemName=$scope.selectedProductName;
        }
      });
      
      objToSend["initiateTransportInventoryItems"]["transportInventoryItems"] = transportInventorySelectedItems;
      
      console.log("$scope.transportInventorySelectedItems = ", transportInventorySelectedItems);
  
      if (transportInventorySelectedItems && transportInventorySelectedItems.length > 0) {
        // $state.go('root.transportInventory', objToSend);
        var modalInstance=$uibModal.open({
          templateUrl: 'views/transport-inventory-modal.html',
          controller: 'TransportInventoryModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
            transferRequestData: function() {
              return objToSend;
            }
          }
        });

        modalInstance.result.then(function() {
          $scope.warehouses_list.forEach(elem => {
            elem.select = false;
          });
          $scope.selectAll.selected=false;
          $scope.singleWarehouseSelected=false;
          $scope.itemNotSelected=true;
          $scope.selectedItems=[];
          objToSend = [];
        }, function() {
          $log.info('Modal dismissed at: ' + new Date());
        });
      } else {
        growl.error("Empty transport inventory items");
      }
    };

    $scope.initiateClearance=function(){
      let paramsObj = {
                      id:$scope.selectedItems[0].warehouseId,
                      from:"Product",
                      index:$scope.selectedIndex,
                      item:$scope.selectedProductName,
                      sort:$scope.selectedSortId.sort_in,
                      size:$scope.products.length,
                      page:$scope.pageIndex,
                      category: $scope.selectedCategory.category,
                      key:null
      };
      if($scope.searchingPhase) {
        paramsObj.key=$scope.searchText.characters; //storing the searched string as a parameter
      }
      $global.setLocalItem("productInvParams",paramsObj,true);

      let listToSend=[];
      let flag=false;
      console.log("$scope.selectedItems",$scope.selectedItems);

      $scope.selectedItems.forEach(elem => {
        if(elem.select && elem.currentQuantity===0){
          growl.error("Quantity cannot be 0");
          flag=true;
        }
        if(elem.select && elem.currentQuantity<0){
          growl.error("Quantity cannot be Negative");
          flag=true;
        }
      });
      if(flag){
        return false;
      }
      $scope.selectedItems.forEach(elem => {
        let obj={
          warehouseId: elem.warehouseId,
          warehouseName:elem.warehouseName,
          currentQuantity:elem.currentQuantity,
          totalPrice:elem.totalCost
        };
        listToSend.push(obj);
      });
      console.log("$scope.selectedItems",$scope.selectedItems,"listToSend",listToSend);
      var modalInstance = $uibModal.open({
          templateUrl: 'views/inventory-clearance-modal.html',
          controller: 'InventoryClearanceModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
            productName: function(){
              return $scope.selectedProductName;
            },
            warehouseList: function() {
                return listToSend;
            }
          }
      });

      modalInstance.result.then(function(res) {
        $scope.warehouses_list.forEach(elem => {
          elem.select=false;
        });
        $scope.selectAll.selected=false;
        $scope.singleWarehouseSelected=false;
        $scope.itemNotSelected=true;
        $scope.selectedItems=[];
        listToSend=[];
        let params=$global.getLocalItem("productInvParams", true);
        localStorageService.remove("productInvParams");
        if(res==="Reload"){
          $state.go($state.current,params,{reload:true});
        }
      }, function() {
          $log.info('Modal dismissed at: ' + new Date());
      });
    };

    // go to balance sheet of the item/warehouse clicked
    $scope.openBalanceSheet= function (warehouse,index) {
      console.log("itemdetails",warehouse,index,$scope.searchText.characters,$scope.searchingPhase);
      let objToSend={ id:warehouse.warehouseId,
                      from:"Product",
                      index:$scope.selectedIndex,
                      item:$scope.selectedProductName,
                      sort:$scope.selectedSortId.sort_in,
                      size:$scope.products.length,
                      page:$scope.pageIndex,
                      category: $scope.selectedCategory.category,
                      key:null};
      if($scope.searchingPhase) {
        objToSend.key=$scope.searchText.characters; //storing the searched string as a parameter
      }
      console.log("objToSend Products",objToSend)
      $state.go('root.balanceSheet',objToSend)
    };
    
    function parseDate(str) {
      let mdy = str.split('/');
      let date = new Date(mdy[2], mdy[1] - 1, mdy[0]);
      // console.log("date = ", date);
      return date;
    }
    
    function datediff(first, second) {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    
    function parseDateString(str) {
      return $moment(str,'DD-MMM-YYYY').toDate();
    }
    
    // compute last cleared date and total quantity
    function computeBadgesData(resultArray,fromScroll) {
      
      if (!resultArray) {
        return;
      }
      
      let lastClearedDate;
      let totalQuantity = 0;
      
      for (let resultObj of resultArray) {
        if (resultObj) {
          let responseLastClearedDate = resultObj.lastClearedDate;
          if (responseLastClearedDate) {
            if (lastClearedDate) {// at this point lastClearedDate is a date object
              if (parseDateString(responseLastClearedDate) > lastClearedDate) {
                lastClearedDate = parseDateString(responseLastClearedDate);
              }
            } else {
              lastClearedDate = parseDateString(responseLastClearedDate);
            }
          }
  
          let responseCurrentQuantity = resultObj.currentQuantity;
          if (responseCurrentQuantity) {
            totalQuantity += responseCurrentQuantity;
          }
        }
      }
  
      if (lastClearedDate) {
              // $scope.lastClearanceDate=(new Date(res.data.lastClearedDate) > new Date($scope.lastClearanceDate))?res.data.lastClearedDate:$scope.lastClearanceDate;
        if(fromScroll){
          if($scope.lastClearanceDate==="N/A"){
            $scope.lastClearanceDate=$moment(lastClearedDate).format("DD-MMM-YYYY");
          } else if (new Date(lastClearedDate) > new Date($scope.lastClearanceDate)){
            $scope.lastClearanceDate=$moment(lastClearedDate).format("DD-MMM-YYYY");
          } else {
            $scope.lastClearanceDate=$scope.lastClearanceDate;
          }
        } else {
          $scope.lastClearanceDate = $moment(lastClearedDate).format("DD-MMM-YYYY");
        }
      }
      
      if (totalQuantity) {
        // $scope.totalQty = Math.round(((totalQuantity / 1000) + Number.EPSILON) * 1000) / 1000;
        // $scope.totalQty = totalQuantity.toFixed(2) + " Kgs";
        let totalQty=fromScroll?Number($scope.totalQty.split(" ")[0]):0;

        let newtotalQty=totalQuantity;

        $scope.totalQty=(totalQty + newtotalQty).toFixed(2)+ " Kgs";
      }
    
    }

    $scope.getSaleOrderForWarehouse = function(warehouseId, vendorId, fromdate, todate) {
      $scope.pendingCount = 0;
      $scope.approvedCount = 0;
      $scope.rejectedCount = 0;
      $scope.dispatchedCount = 0;
      $scope.pendingItemsMap={};
      saleOrderService.getSaleOrderForWarehouse(warehouseId, vendorId, fromdate, todate).then(function(res) {
        console.log(res)
        if (res.status == "SUCCESS") {
          if (res.data.saleOrder) {
            $scope.saleOrder_list = res.data.saleOrder;
            if ($scope.saleOrder_list.length == 0) {
              $scope.noSaleOrderFound = true;
            } else {
              $scope.saleOrder_list.forEach(function(element, index) {            
                if (element.status == "PENDING") {
                  $scope.pendingCount = $scope.pendingCount + 1;
                  if(element.saleOrderItems && element.saleOrderItems.length>0){
                    element.saleOrderItems.forEach( item => {
                      if(item && item.items && item.items.items){
                        let innerItem=item.items.items;
                        // console.log("element",element);
                        // console.log("innerItem",innerItem,innerItem[0].categoryName);
                        if(innerItem[0].categoryName && innerItem[0].categoryName===$scope.selectedProductName){
                            $scope.pendingItemsMap[element.warehouse.warehouseName]=true;
                        }
                      }
                    });
                  }
                } else if (element.status == "APPROVED") {
                  $scope.approvedCount = $scope.approvedCount + 1;
                } else if (element.status == "REJECTED") {
                  $scope.rejectedCount = $scope.rejectedCount + 1;
                } else if (element.status == "DISPATCHED") {
                  $scope.dispatchedCount = $scope.dispatchedCount + 1;
                }
              });
              if(!(Object.keys($scope.pendingItemsMap).length === 0 && $scope.pendingItemsMap.constructor === Object)){
                $scope.warehouses_list.forEach(elem => {
                  if($scope.pendingItemsMap[elem.warehouseName]){
                    elem.warn=true;
                  }
                });
              }
              console.log($scope.saleOrder_list)
              console.log("$scope.pendingItemsMap",$scope.pendingItemsMap);
              $scope.noSaleOrderFound = false;
              
              if ($scope.dispatchedCount > 0 || $scope.rejectedCount > 0
                || $scope.approvedCount > 0 || $scope.pendingCount > 0) {
                $scope.noSaleOrderFound = false;
              } else {
                $scope.noSaleOrderFound = true;
              }
            }
          } else {
            $scope.noSaleOrderFound = true;
          }
        } else {
          $scope.noSaleOrderFound = true;
        }
      }).catch(function(err){
        console.log("Err:",err);
      });
    };
  
    
  });
