    'use strict';

    /**
     * @ngdoc function
     * @name scrapQApp.controller:SellReportCtrl
     * @description
     * # SellReportCtrl
     * Controller of the scrapQApp
     */
    angular.module('scrapQcrmApp')
        .controller('SellReportCtrl', function($scope, $moment, $timeout, SellNow, $global, growl, $rootScope) {
            $rootScope.loginData = {};
            $rootScope.loginData = $global.getLocalItem("loginData", true);

            $scope.vm = {params: {"city": $rootScope.loginData.city}};

            $scope.dateInput = $moment().format('DD-MMM-YYYY');
            $scope.date = {
                startDate: $scope.dateInput,
                endDate: $scope.dateInput
            };
            var startdate = $moment();
            $scope.opts = {
                format: "DD-MMM-YYYY",
                singleDatePicker: true
            };

            $scope.vm.params.singledayquery = true;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;
            $scope.vm.params.todate = $moment($scope.dateInput).format('DD-MMM-YYYY');
            $scope.vm.params.fromdate = $moment($scope.dateInput).format('DD-MMM-YYYY');

            var letters = '0123456789ABCDEF';
            function getRandomColor() {
                var color = '#'; // <-----------
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }

            $scope.mapOptions = {
                center: new google.maps.LatLng(50, 0),
                zoom: 9,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            function getCurrentLocation(){
                $global.getCurrentLocation().then(function(res){
                    if(res){
                        $scope.map.setCenter({lat: res.latitude, lng: res.longitude});
                    }
                }, function(err){
                    console.log(err);
                });
            }
            getCurrentLocation();

            function initialize() {
                var myOptions = {
                    zoom: 14,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    center: new google.maps.LatLng(17.3944809,78.4417806)
                }
                $scope.map = new google.maps.Map(document.getElementById("map1"), myOptions);
            }
            initialize();
            $scope.transactionChange = function() {
                $scope.vm.params.page = -1;
                $scope.vm.params.todate = $moment($scope.dateInput).format('DD-MMM-YYYY');
                $scope.vm.params.fromdate = $moment($scope.dateInput).format('DD-MMM-YYYY');
                $scope.fetchSells($scope.vm.params);
                initialize();
            };

            $scope.$watch('dateInput', function(newDate) {
                $scope.transactionChange();
            }, false);

            $scope.nextDate = function() {
               $scope.dateInput = moment($scope.dateInput).add(1, "days").format('DD-MMM-YYYY');
                // $scope.getAppointmentsForAgents($scope.dateInput);
            }
            $scope.previousDate = function() {
                $scope.dateInput = moment($scope.dateInput).subtract(1, "days").format('DD-MMM-YYYY');
                // $scope.getAppointmentsForAgents($scope.dateInput);
            }

            function calcRoute() {
                $scope.agentAppntCount.forEach(function(element, index) {
                    if (element != "") {
                        var directionsOptions = {
                            polylineOptions: {
                                strokeColor: element[0].color
                            },
                            suppressMarkers: true
                        }
                        var directionsDisplay = new google.maps.DirectionsRenderer(directionsOptions);
                        directionsDisplay.setMap($scope.map);
                        var directionsService = new google.maps.DirectionsService();
                        var waypts = [];
                        var completedArr = [];
                        directionsDisplay.set('directions', null);
                        element.forEach(function(elem, indx){
                            if (elem.isCompleted) {
                                completedArr.push(elem)
                            }
                        });
                        if (completedArr.length > 0) {
                            for (var i =  1; i < completedArr.length - 1; i++) {
                                var temp = {location: new google.maps.LatLng(completedArr[i].closedLat, completedArr[i].closedLong), stopover: true}
                                waypts.push(temp)
                            }
                            var end = completedArr.length-1;
                            var request = {
                                origin: new google.maps.LatLng(completedArr[0].closedLat,completedArr[0].closedLong),
                                destination: new google.maps.LatLng(completedArr[end].closedLat,completedArr[end].closedLong),
                                waypoints: waypts,
                                optimizeWaypoints: false,
                                travelMode: google.maps.DirectionsTravelMode.DRIVING
                            };
                            directionsService.route(request, function(response, status) {
                                if (status == google.maps.DirectionsStatus.OK) {
                                    directionsDisplay.setDirections(response);
                                    var route = response.routes[0];
                                }
                            });
                        }
                    }
                    
                    
                });
            }

            $scope.addMarkers = function(){
                initialize();
                $scope.agentAppntCount.forEach(function(element, index){
                    if (element != "") {
                        element.forEach(function(elem, indx){
                            var color = elem.color
                            var styles = {
                                "background-color": color
                            };
                            if (elem.isCompleted) {
                                var text = (indx+1)+'-'+elem.confirmationId+'-'+elem.preferredSlot.split(":")[0];
                            } else {
                                var text = elem.confirmationId+'-'+elem.preferredSlot.split(":")[0];
                            }
                            var marker = new MarkerWithLabel({
                                position: new google.maps.LatLng(elem.closedLat, elem.closedLong),
                                map:$scope.map,
                                draggable: false,
                                labelContent: text,
                                labelClass: "sell-report-labels",
                                labelStyle : styles,
                                icon: {
                                  url:"https://1x1px.me/FF4D00-0.8.png",
                                  scale: 0
                                },
                                size: new google.maps.Size(0, 0),
                                optimized: false,
                                visible: true
                            });
                            marker.setMap($scope.map);
                            $(".labels").css(styles);
                            var iw1 = new google.maps.InfoWindow({
                                content: elem.agentName
                            });
                            google.maps.event.addListener(marker, "click", function(e) {
                                iw1.close();
                                iw1.open($scope.map, this);
                            });
                        });
                    }
                });
                calcRoute();
            }

            function getSort () {
                var nullPosition = 1
                return function (a, b) {
                    if (a.timeObj == null) return nullPosition
                    if (b.timeObj == null) return -nullPosition
                    if (a.timeObj < b.timeObj) return -nullPosition
                    if (a.timeObj > b.timeObj) return nullPosition
                    return 0
                }
            }

            $scope.sortAgentData = function(){
                if ($scope.allSells) {
                    $scope.agentNames = [];
                    $scope.agentEnabled = [];
                    $scope.agentAppntCount = [];
                    $scope.colors = [];
                    $scope.allSells.forEach(function(element, index){
                        if (element.statusCode == "REQUEST_COMPLETED" || element.statusCode == "ASSIGNED_TO_AGENT"
                            || element.statusCode == "ACCEPTED_BY_AGENT" || element.statusCode == "REQUEST_INITIATED") {
                            var pieces,t;
                            if (element.completedTime) {
                                pieces = element.completedTime.split(':');
                                t = new Date(element.completedDate);
                                if(pieces.length === 3) {
                                    t.setHours(parseInt(pieces[0], 10));
                                    t.setMinutes(parseInt(pieces[1], 10));
                                    t.setSeconds(parseInt(pieces[2], 10));
                                }
                            }
                            
                            if (element.agentName || element.statusCode == "REQUEST_INITIATED") {
                                var obj = {
                                    "completedTime" : element.completedTime,
                                    "confirmationId" : element.confirmationId,
                                    "closedLat" : element.closedLat,
                                    "closedLong" : element.closedLong,
                                    "preferredSlot" : element.preferredSlot
                                }
                                if (element.statusCode == "REQUEST_INITIATED") {
                                    obj.agentName = "Initiated Appointments";
                                    element.agentName = "Initiated Appointments";
                                } else {
                                    obj.agentName = element.agentName;
                                }
                                if (element.completedTime) {
                                    obj.timeObj = t.getTime();
                                }
                                if (element.statusCode == "REQUEST_COMPLETED") {
                                    obj.isCompleted = true;
                                } else {
                                    obj.isCompleted = false;
                                }
                                if (obj.closedLat == null || obj.closedLong == null) {
                                    obj.closedLat = parseFloat(element.consumerAddress.latitude);
                                    obj.closedLong = parseFloat(element.consumerAddress.longitude);
                                }

                                if ($scope.agentNames.indexOf(element.agentName) > -1) {
                                    if (element.statusCode == "REQUEST_INITIATED") {
                                        obj.color = "#000000";
                                    } else {
                                        obj.color = $scope.colors[$scope.agentNames.indexOf(element.agentName)]
                                    }
                                    $scope.agentAppntCount[$scope.agentNames.indexOf(element.agentName)].push(obj);
                                    $scope.agentAppntCount[$scope.agentNames.indexOf(element.agentName)].sort(getSort());
                                } else{
                                    $scope.agentNames.push(element.agentName);
                                    $scope.agentEnabled.push(true);
                                    if (element.statusCode == "REQUEST_INITIATED") {
                                        obj.color = "#000000";
                                        $scope.colors.push("#000000");
                                    } else {
                                        var color = getRandomColor();
                                        $scope.colors.push(color);
                                        obj.color = color;
                                    }
                                    var tempArr = [];
                                    tempArr.push(obj);
                                    $scope.agentAppntCount.push(tempArr);
                                }
                            }   
                        }
                    });
                    var index = $scope.agentNames.indexOf("Initiated Appointments");
                    if (index > -1) {
                        var length = $scope.agentNames.length -1;
                        var temp = $scope.agentAppntCount[index];
                        $scope.agentAppntCount[index] = $scope.agentAppntCount[length];
                        $scope.agentAppntCount[length] = temp;
                        temp = $scope.agentNames[index];
                        $scope.agentNames[index] = $scope.agentNames[length];
                        $scope.agentNames[length] = temp;
                        temp = $scope.colors[index];
                        $scope.colors[index] = $scope.colors[length];
                        $scope.colors[length] = temp;
                    }
                    $scope.sortedAgentData = angular.copy($scope.agentAppntCount);
                    setTimeout(function() { $scope.addMarkers();}, 100);
                }
            }           

            $scope.fetchSells = function(params) {
                SellNow.allRequests(params).then(function(res){
                    if (res.status == "SUCCESS") {
                        if (res.data && res.data.sells) {
                            if (res.data.sells.length > 0) {
                                $scope.allSells = res.data.sells;
                                $scope.sortAgentData();
                            }
                        }
                    }
                })
            }

            $scope.agentsEnable = function(index){
                $scope.agentAppntCount = angular.copy($scope.sortedAgentData);
                $scope.agentEnabled.forEach(function(element, index){
                    if (!element) {
                        $scope.agentAppntCount.splice(index, 1);
                        $scope.agentAppntCount.splice(index,0 ,"");
                    }
                });
                
                $scope.addMarkers();
            }
            $scope.clearAll = function(index){
                $scope.agentEnabled.forEach(function(element, index){
                    $scope.agentEnabled[index] = false;
                });
                $scope.agentAppntCount = angular.copy($scope.sortedAgentData);
                $scope.agentEnabled.forEach(function(element, index){
                    if (!element) {
                        $scope.agentAppntCount.splice(index, 1);
                        $scope.agentAppntCount.splice(index,0 ,"");
                    }
                });
                
                $scope.addMarkers();
            }
            $scope.selectAll = function(index){
                $scope.agentEnabled.forEach(function(element, index){
                    $scope.agentEnabled[index] = true;
                });
                $scope.agentAppntCount = angular.copy($scope.sortedAgentData);
                $scope.agentEnabled.forEach(function(element, index){
                    if (!element) {
                        $scope.agentAppntCount.splice(index, 1);
                        $scope.agentAppntCount.splice(index,0 ,"");
                    }
                });
                
                $scope.addMarkers();
            }
        });
