'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:CommunityAppointmentsSellCtrl
 * @description
 * # CommunityAppointmentsSellCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('CommunityAppointmentsSellCtrl', function ($scope, consumerData, apartments, products, platformSetting, consumer, $filter, $uibModalInstance, $uibModal, $moment, $timeout, SellNow, $global, growl, $rootScope, NgMap) {


    // Scale marker icon for map
    $scope.icon = {
      url: "./images/consumer.svg",
      scaledSize: [55, 55]
    }

    $scope.data = {};
    $scope.category = "Residence";

    $scope.addressSelected = function (address) {
      console.log('address: ', address);
      $scope.address = address.formattedAddress;
      $scope.addressId = address.addressId;

      $scope.center = address.latitude + "," + address.longitude;

      $scope.data.flat = address.flat;
      if (!address.flat) {
        $scope.flatNotFound = true;
      } else {
        $scope.flatNotFound = false;
      }

      // Change default
      if (address.defaultAddress === "NO") {
        consumer.setDefaultAdd($scope.addressId).then(function (res) {
          if (res.status == "FAILURE") {
            growl.error(res.error.message || "Unable to select address");
          }
        });
      }
    }

    consumer.getProfile(consumerData.communityAppointment.community.communityId).then(function (res) {
      if (res.status === "FAILURE") {
        growl.error("Unable to retrieve community");
      }

      console.log('community profile: ',res);
      if (res.data.consumer.communityCategory && res.data.consumer.communityCategory.communityCategoryName) {
        $scope.communityCategory = res.data.consumer.communityCategory.communityCategoryName;
        if ($scope.communityCategory === "Commercial Complex") {
          $scope.category = "Shop";
        }
        if ($scope.communityCategory === "MRF") {
          $scope.category = "Segregator";
        }
      }
    });

    if (consumerData.consumer) {

      $scope.consumer = true;
      $scope.data = consumerData.consumer;
      $scope.consumerId = consumerData.consumer.userId;
      let communityAppointment = consumerData.communityAppointment;
      let communityAddress = communityAppointment.community.address;
      $scope.preferredSlot = communityAppointment.preferredStartTime + " - " + communityAppointment.preferredEndTime;
      $scope.data.preferredDate = communityAppointment.preferredDate;
      $scope.data.communityAppointmentId = communityAppointment.communityAppointmentId;

      consumer.getAddress($scope.consumerId).then(function (res) {
        $scope.addresses = res.data.addresses;
        if ($scope.addresses.length > 0) {
          $scope.addresses.forEach(address => {
            if (address.communityId === communityAppointment.community.communityId && $scope.communityCategory !== "Commercial Complex") {
              console.log('Community mapped address: ',address);
              $scope.addressSelected(address);
              return;
            } else if (!$scope.address) {
              $scope.addressSelected(communityAddress);
              $scope.data.addAddress = true;
            }
          });
        } else {
          $scope.addressSelected(communityAddress);
          $scope.data.addAddress = true;
        }
      });

    } else {
      $scope.data.mobile = consumerData.consumerMobile;
      let communityAppointment = consumerData.communityAppointment;
      let communityAddress = communityAppointment.community.address;
      $scope.preferredSlot = communityAppointment.preferredStartTime + " - " + communityAppointment.preferredEndTime;
      $scope.data.preferredDate = communityAppointment.preferredDate;
      $scope.data.communityAppointmentId = communityAppointment.communityAppointmentId;

      $scope.addressSelected(communityAddress);
      $scope.data.addAddress = true;
    }


    $scope.toggleDropdown = function () {
      const addressDropdown = document.querySelector("#addr-dropdown");
      if (addressDropdown) {
        addressDropdown.classList.toggle("show");
      }
    }

    $scope.createAppointment = function () {

      if ($scope.createSellForm.$invalid) {
        growl.error("Please enter valid input");
        return;
      }

      SellNow.sellRequestForCommunityAppointment($scope.data).then(function (res) {

        if (res.status === "FAILURE") {
          growl.error(res.error.message || "Unable to raise appointment");
          return;
        }

        growl.success(res.data.message || "Successfully raised appointment for the consumer");
        $scope.cancel();
        $scope.openAppointment(res.data.confirmationId);

      });
    }

    $scope.openAppointment = function (confirmationId) {

      SellNow.getSellById(confirmationId).then(function (res) {
        if (res.status == "FAILURE") {
          growl.log("Unable to retrieve sell");
        }

        var pickupInstance = $uibModal.open({
          templateUrl: 'views/consumer-pickups-modal.html',
          controller: 'ConsumerPickupsModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
            items: function () {
              return res.data.sell;
            }
          }
        });
        pickupInstance.result.then(function (selectedItem) {
          $scope.selected = selectedItem;
        }, function () {
        });

      }, function (err) { });

    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });