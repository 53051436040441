'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:InvalidCustomersCtrl
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('InvalidCustomersCtrl', function($scope, $rootScope, $global, pricingService, $stateParams, $http, $state, dashboard, $uibModal, $log, consumer, growl, BidService, DTOptionsBuilder, DTColumnDefBuilder, products) {
    
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    
    $scope.pageTitle = "Invalid Customers";
    
    // address type logic
    if ($stateParams.type) {
      $scope.consumerbuckettype = $stateParams.type;
    } else {
      $scope.consumerbuckettype = null;
    }
    
    let columnDefs = [
      { headerName: "Name", field: "name", pinned: "left"},
      { headerName: "Mobile", field: "mobile", pinned: "left"},
      { headerName: "Source", field: "registerType", pinned: "left", cellStyle: {'text-align': 'center'} },
      { headerName: "OTP Pending", field: "isOTPPending", cellStyle: {'text-align': 'center'} },
      { headerName: "Invalid Address", field: "isAddressInvalid", cellStyle: {'text-align': 'center'} },
      { headerName: "Invalid City", field: "isCityInvalid", cellStyle: {'text-align': 'center'} },
      { headerName: "City", field: "city"},
      { headerName: "Created On", field: "createdOn"},
      { headerName: "Created By", field: "createdBy"},
      { headerName: "Updated On", field: "updatedOn"},
      { headerName: "Updated By", field: "updatedBy"},
      { headerName: "OTP Status", field: "status", cellStyle: {'text-align': 'center'}},
      { headerName: "Total Completed Appointments", field: "totalCompletedAppointmentsCount" },
      { headerName: "Active", field: "enabled", cellStyle: {'text-align': 'center'}, hide: true},
      { headerName: "User Type", field: "userType", hide: true},
      { headerName: "userId", field: "userId", hide: true}
    ];

    let rowData = [];

    $scope.gridOptions = {
      columnDefs: columnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      rowStyle: { background: '#f4f4f4', cursor: 'pointer' },
      pagination: true,
      paginationPageSize: 1000,
      rowData: rowData,
      suppressCsvExport: true,
      suppressExcelExport: true,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },
      onRowClicked: function (event) {
        console.log("event.data", event.data);
        if (event && event.data) {
          $scope.completeCustomerRegistration(event.data);
        }
      }
    };

    // GET API to populate the page
    (function(){
      consumer.getIncompleteCustomers()
        .then(function(res) {
          if (res.status === "SUCCESS" && res.data.customerDetails) {
            $scope.invalidCustomerDetails = res.data.customerDetails;
            $scope.gridOptions.api.setRowData($scope.invalidCustomerDetails);
        
            // badges
            $scope.totalInvalidCustomersCount = res.data.totalInvalidCustomersCount;
            $scope.totalPendingOTPCount = res.data.totalPendingOTPCount;
            $scope.totalInvalidAddressCount = res.data.totalInvalidAddressCount;
            $scope.totalInvalidCityCount = res.data.totalInvalidCityCount;
          }
          else {
            $scope.noInvalidCustomersFound = true;
            console.error(res);
            growl.error("No results found");
          }
        })
        .catch(function(err) {
          $scope.noInvalidCustomersFound = true;
          console.error(err);
          growl.error("No results found");
        });
    })();
  
  
    $scope.completeCustomerRegistration = function(selectedConsumer) {
      let modalInstance = $uibModal.open({
        templateUrl: 'views/complete-customer-registration.html',
        controller: 'CompleteCustomerRegistrationCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function() {
            return 'CONSUMER';
          },
          selectedConsumer : function() {
            return selectedConsumer;
          }
        }
      });
    
      modalInstance.result.then(function(selectedItem) {
        $scope.selected = selectedItem;
      }, function() {
        $state.go($state.current, {}, { reload: true });
      });
    
    };
    
  });
