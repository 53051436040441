'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:InventoryClearanceModalCtrl
 * @description
 * # InventoryClearanceModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('InventoryClearanceModalCtrl', function($scope, $filter, products, warehouseInventoryService, $moment, $global, growl, $uibModalInstance, $state, $timeout, $rootScope,warehouseList,productName) {
        
        const addresstypes = ["Residence", "Shop", "Office", "Hotel", "Automobile", "Hospital", "Shopper"];

        $scope.dateInput = $moment().format("DD-MMM-YYYY");
        $scope.date = {
          startDate: $scope.dateInput,
          endDate: $scope.dateInput
        };
        $scope.opts = {
          format: "DD-MMM-YYYY",
          singleDatePicker: true
        };

        (function(){
            $scope.itemsValidated=false;
            $scope.warehousesSelected=[];
            $scope.disableSubmit=true;
            $scope.productToVendor=[];
            $scope.unitPrices={};
            console.log("warehouseList",warehouseList,"productName",productName);
            if(warehouseList && warehouseList.length>0 && productName){
                $scope.productName=productName;
                $scope.warehousesSelected=warehouseList;
            }
            console.log($rootScope.loginData.userid);
        })();

        (function(){
            $scope.itemsValidated=false;
            let data = {
                productName:$scope.productName,
                productInventoryClearanceItems: []
            };
            angular.copy($scope.warehousesSelected,data.productInventoryClearanceItems);
            for(let i=0;i<data.productInventoryClearanceItems.length;i++){
                delete data.productInventoryClearanceItems[i].warehouseName;
                delete data.productInventoryClearanceItems[i].totalPrice;
                $scope.warehousesSelected[i].selectedVendor=null;
                $scope.warehousesSelected[i].selectedVendorId=null;
                $scope.warehousesSelected[i].selectedVendorItem=null;
                $scope.warehousesSelected[i].selectedVendorItemId=null;
                $scope.warehousesSelected[i].selectedVendorItemPrice=null;
                $scope.warehousesSelected[i].selectedVendorItemPriceId=null;
                $scope.warehousesSelected[i].selectedVendorTotalPrice=null;
                $scope.warehousesSelected[i].matchingVendorItems=null;
            }
            warehouseInventoryService.retrieveValidatedWarehouses(data)
            .then(function(res){
                if(res.status=='SUCCESS'){
                    if(res && res.data){
                        console.log("validation response",res);
                        if(res.data.validatedVendors && res.data.validatedVendors.length>0){
                            $scope.validatedVendors=res.data.validatedVendors;
                        } else {
                            $scope.validatedVendors=[];
                        }
                        $scope.itemsValidated=true;
                    } else {
                        console.log("Error: ",res)
                    }
                }
            }).catch(function(err){
                console.log("Error: ",err);
            })
        })();

        (function(){
            products.getProducts().then(function(res) {
            console.log("CATEGORIES  :::",res);
            if (res.status == 'SUCCESS') {
                $scope.categories = res.data.categories;
                $scope.categories.forEach(function(element, index){
                    if(element.consumercategoryname==='Residence'){
                        $scope.unitPrices[element.name]=element.price;
                    } else {
                        let cont=true;
                        for(let i=0;cont && i<addresstypes.length;i++){
                            if(element.consumercategoryname===addresstypes[i]){
                                $scope.unitPrices[element.name]=element.price;
                                cont=false;
                            }
                        }
                    }
                });
                console.log(Object.keys($scope.unitPrices).length,$scope.unitPrices,"unitPrices");
                }
                }
            );
        })();

        //Watch for date changes
        $scope.$watch('dateInput', function(newDate) {
            console.log("$scope.dateInput", $scope.dateInput)
            $scope.date.startDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
            $scope.date.endDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
            console.log("$scope.date", $scope.date.startDate, $scope.date.endDate);
            
            // when date changes
        }, false);
        
        $scope.nextDate = function() {
            $scope.dateInput = $moment($scope.dateInput).add(1, "days").format("DD-MMM-YYYY");
            $scope.date = {
            startDate: $scope.dateInput,
            endDate: $scope.dateInput,
            };
        };
        
        $scope.previousDate = function() {
            $scope.dateInput = $moment($scope.dateInput).subtract(1, "days").format("DD-MMM-YYYY");
            $scope.date = {
            startDate: $scope.dateInput,
            endDate: $scope.dateInput,
            };
        };
        
        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };

        $scope.vendorChanged=function (obj,data,index){
            console.log("vendorChanged",obj,data,index);
            obj.selectedVendorId=data;
            obj.selectedVendorItem=null;
            obj.selectedVendorItemId=null;
            obj.selectedVendorItemPrice=null;
            obj.selectedVendorItemPriceId=null;
            obj.selectedVendorTotalPrice=null;
            if(data===null){
                $scope.warehousesSelected[index].matchingVendorItems=null;
                obj.selectedVendor=null;
            } else {
                for(let i=0;i<$scope.validatedVendors.length;i++){
                    if($scope.validatedVendors[i].vendorId===data){
                        $scope.warehousesSelected[index].selectedVendor=$scope.validatedVendors[i].vendorName;
                        $scope.warehousesSelected[index].matchingVendorItems=$scope.validatedVendors[i].matchingVendorItems;
                        break;
                    }
                }
            }
        };

        // when delete icon is clicked to delete a row
        $scope.deleteItem=function(index,obj){
            if($scope.allowDelete){
                $scope.warehousesSelected.splice(index,1);
            }
        };

        // when Edit button is clicked to edit the table
        $scope.allowEdit=function(tableform){
            $scope.disableSubmit=true;
            $scope.itemsSetCopy=[];
            angular.copy($scope.warehousesSelected,$scope.itemsSetCopy);
            $scope.allowDelete=true;
            tableform.$show();
        };

        // when Cancel button is clicked to cancel changes done in the table
        $scope.cancelChanges=function(tableform){
            $scope.warehousesSelected=$scope.itemsSetCopy;
            $scope.allowDelete=false;
            $scope.disableSubmit=false;
            for(let i=0;i<$scope.warehousesSelected.length;i++){
                if(!$scope.warehousesSelected[i].selectedVendor || !$scope.warehousesSelected[i].selectedVendorItem || !$scope.warehousesSelected[i].currentQuantity){
                    $scope.disableSubmit=true;
                }
            }
            if($scope.warehousesSelected.length===0){
                $scope.disableSubmit=true;
            }
            tableform.$cancel();
        };

        // validation after changes in table are done and Save button is clicked
        $scope.Validate=function(tableform){
            console.log("$scope.warehousesSelected",$scope.warehousesSelected);
            $scope.disableSubmit=false;
            if($scope.warehousesSelected.length===0){
                $scope.disableSubmit=true;
            }
            for(let i=0;i<$scope.warehousesSelected.length;i++){
                let obj=$scope.warehousesSelected[i];
                if(obj.currentQuantity===0){
                    growl.error("Quantity cannot be 0");
                    $scope.disableSubmit=true;
                }
                if(!obj.selectedVendorItem || !obj.selectedVendor){
                    growl.error("Table contains invalid items");
                    $scope.disableSubmit=true;
                }
                if(obj.currentQuantity<0.001){
                    if(obj.currentQuantity>0 && obj.currentQuantity<0.001){
                        growl.error("Quantity cannot be less than 1kg(0.001 tons)")
                    }
                    growl.error("Table contains invalid items");
                    $scope.disableSubmit=true;
                }
            }
            console.log("$scope.warehousesSelected",$scope.warehousesSelected);
            $scope.allowDelete=false;
            tableform.$hide();
            if(!$scope.disableSubmit){
                growl.success("Items Updated");
            }
            return true;
        };

        // called when the totalQuantity in a row is entered
        $scope.valueChanged=function(input,obj){
            if(input===0){
                growl.error("Quantity cannot be 0");
                obj.totalPrice=0;
                obj.selectedVendorTotalPrice=0;
            } else {
                if(!obj.totalPrice) obj.totalPrice=0;
                if(!obj.selectedVendorTotalPrice) obj.selectedVendorTotalPrice=0;
                obj.totalPrice=$scope.unitPrices[$scope.productName]*input;  //*1000; //amount in 1000s only for kgs
                obj.selectedVendorTotalPrice=input*obj.selectedVendorItemPrice;  //*1000;
            }
        };

        // called when a Vendor item is changed from Vendor Item dropdown
        $scope.vendorItemChanged=function(obj,data,index){
            let oldValue=$scope.warehousesSelected[index].selectedVendorItemId;
            
            $scope.warehousesSelected[index].selectedVendorItemId=data;
            if (data!==null) {
                let cont=true,unitPrice=0,id=null,units=null,n=1;
                for(let i=0;cont && i<obj["matchingVendorItems"].length;i++){
                    if(obj["matchingVendorItems"][i].vendorItemId===data){
                        name=obj["matchingVendorItems"][i].itemName;
                        id=obj["matchingVendorItems"][i].vendorPriceId;
                        unitPrice=obj["matchingVendorItems"][i].price;
                        units=obj["matchingVendorItems"][i].uom;
                        cont=false;
                    }
                }
                
                obj.selectedVendorItem=name;
                obj.selectedVendorItemPrice=unitPrice;
                obj.selectedVendorItemPriceId=id;
                obj.selectedVendorTotalPrice=unitPrice*(obj.currentQuantity);  //*1000); //check units
                console.log("obj",obj)
            } else {
                obj.selectedVendorItem=null;
                obj.selectedVendorItemPriceId=null;
                obj.selectedVendorItemPrice=0;
                obj.selectedVendorTotalPrice=0;
            }
        };

        
        $scope.submitValidatedItems = function(){
            console.log("$scope.warehousesSelected",$scope.warehousesSelected)
            let objToSend={
                productName:$scope.productName,
                productInventoryClearanceItems:[]
            }
            $scope.warehousesSelected.forEach(elem => {
                let obj={};
                obj.warehouseId=elem.warehouseId;
                obj.vendorId=elem.selectedVendorId;
                obj.vendorSaleOrderItems=[];
                let innerObj={};
                innerObj.itemName=elem.selectedVendorItem;
                innerObj.quantity=elem.currentQuantity;
                innerObj.unitPrice=elem.selectedVendorItemPrice;
                innerObj.totalPrice=elem.selectedVendorTotalPrice;
                innerObj.vendorPriceId=elem.selectedVendorItemPriceId;
                innerObj.vendorItemsId=elem.selectedVendorItemId;
                obj.vendorSaleOrderItems.push(innerObj);
                objToSend.productInventoryClearanceItems.push(obj);
            });
            console.log("objToSend",objToSend);
            warehouseInventoryService.generateSaleOrderWarehouses(objToSend).then(function(res){
                console.log("submitted",res)
                    if (res.status=="SUCCESS") {
                        growl.success("Sale Order created successfully");
                        $uibModalInstance.close('Reload');
                    } else {
                        console.log("Error generateOrder:",res);
                    }
                }).catch(function(err){
                    console.log("Error generateOrder:",err);
                })
        }
    });