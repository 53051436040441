(function (angular) {
  'use strict';

  /**
   * @ngdoc function
   * @name scrapQcrmApp.controller:PayoutsCtrl
   * @description
   * # PayoutsCtrl
   * Controller of the scrapQcrmApp
   */
  angular.module('scrapQcrmApp')
    .controller('AccountsPayableCtrl', function ($scope, $state, growl, $timeout, $filter, $window, $http, $moment, payoutService, $uibModal, $interval, $global, dashboard, SellNow, $log, $stateParams, agentAppointments, DTOptionsBuilder, DTColumnDefBuilder, $rootScope) {

      (function () {
        $scope.dateInput = $moment().format("DD-MMM-YYYY");
        $scope.date = {
          startDate: $scope.dateInput,
          endDate: $scope.dateInput
        };
        $scope.opts = {
          format: "DD-MMM-YYYY",
          singleDatePicker: true
        };
        // default values init
        $scope.totalPayouts = 0;
        $scope.totalRecords = 0;
        $scope.noPayoutsData = false;



        // indian format amount
        $scope.inrFilter = $filter('INR');
      })();


      //Filtering options in the User Type Dropdown.
      (function () {

        $scope.types_list = [];
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        if ($rootScope.loginData.role === "ADMIN") {
          $scope.types_list.push(...[{ typeName: "ScrapQ" }, { typeName: "Master Franchise - MFA" }, { typeName: "Partner - MR" }, { typeName: "REO" }]);
          $scope.selectedType = "ScrapQ";
        }
        else if ($rootScope.loginData.role === "MASTERFRANCHISE") {
          $scope.types_list.push(...[{ typeName: "Master Franchise - MFA" }, { typeName: "Partner - MR" }, { typeName: "REO" }]);
          $scope.selectedType = "Master Franchise - MFA";
        }
        else if ($rootScope.loginData.role === "WHOLESALER") {
          $scope.types_list.push(...[{ typeName: "Partner - MR" }, { typeName: "REO" }]);
          $scope.selectedType = "Partner - MR";
        }
        else {
          console.error("Invalid Login Role");
        }
      })();

      // ag-grid init
      (function () {
        var columnDefs = [
          {
            headerName: "",
            cellRenderer: ActionCellRenderer,
            resizable: false,
            maxWidth: 65,
            // skipHeader : false,
            pinned: 'left',
            outerWidth: '60px',
            cellStyle: { 'height': '100%', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'max-width': '80px', 'padding': '0px' }
          },
          {
            headerName: "",
            cellRenderer: ViewCellRenderer,
            resizable: false,
            maxWidth: 65,
            pinned: 'left',
            // skipHeader : false,

            cellStyle: { 'height': '100%', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'max-width': '40px', 'padding': '0px' }
          },
          {
            headerName: "Invoice ID",
            field: "invoiceNumber",
            pinned: 'left',
          },
          {
            headerName: "Invoice Date",
            field: "invoiceDate",
            cellStyle: { 'text-align': 'center' }
          },
          {
            headerName: "SO/TO Id",
            field: "parentOrderId",
            cellStyle: { 'text-align': 'center' }
          },
          {
            headerName: "User Type",
            field: "userType",
            cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
          },
          {
            headerName: "User Name",
            field: "userName",
            cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
          },
          {
            headerName: "Payment Status",
            field: "paymentStatus",
            cellStyle: { 'text-align': 'left' }
          },
          {
            headerName: "Payment Mode",
            field: "paymentMode"
          },
          {
            headerName: "Payment Date",
            field: "paymentDate"
          },
          {
            headerName: "Pending Amount",
            field: "pendingAmount",
            cellRenderer: AmountCellRenderer,
            cellStyle: { 'text-align': 'right' }
          },
          {
            headerName: "Invoice Amount",
            field: "invoiceAmount",
            cellRenderer: AmountCellRenderer,
            cellStyle: { 'text-align': 'right', 'margin-right': '30px' }
          },
          {
            headerName: "Payment Mode",
            field: "paymentMode",
            hide: true,
            cellStyle: { 'text-align': 'left' }
          },
          {
            headerName: "Payment Amount",
            field: "pendingAmount",
            hide: true,
            cellStyle: { 'text-align': 'left' }
          },
          {
            headerName: "Payment Date",
            field: "paymentDate",
            hide: true,
            cellStyle: { 'text-align': 'left' }
          },
        ];

        let rowData = [];

        $scope.gridOptions = {
          columnDefs: columnDefs,
          defaultColDef: {
            flex: 1,
            // minWidth: 150,
            filter: true,
            sortable: true,
            resizable: true
          },
          rowStyle: { background: '#f4f4f4' },
          pagination: true,
          paginationAutoPageSize: true,
          paginationPageSize: 1000,
          rowData: rowData,
          suppressCsvExport: true,
          suppressExcelExport: true,
          getRowStyle: function (params) {
            if (params.node.rowIndex % 2 === 0) {
              return { background: 'white' };
            }
          },
          onGridReady: function (params) {
            // params.api.sizeColumnsToFit();
            // params.columnApi.autoSizeAllColumns();
            if (!($scope.noPayoutsData) && $scope.agents_list && $scope.agents_list.length > 0) {
              $scope.gridOptions.api.setRowData($scope.agents_list);
            }
          },
          onCellClicked: function (event) {
            if (event && event.data && event.column.colId == "0") {
              $scope.openViewAccountsPayableModal(event.data.invoiceNumber);
            } else if (event && event.data && event.column.colId == "1") {
              $scope.openSaleOrder(event.data.invoiceURL);
            }
          },
          onFirstDataRendered: function (params) {
            params.api.sizeColumnsToFit();
          }
        };
      })();

      function AmountCellRenderer() {
      }

      AmountCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          // let val= params.value?(params.value).toFixed(2):'-';
          let val = params.value ? $scope.inrFilter(params.value) : '-';
          var iconsDiv = "<div>&#x20b9; " + val + "</div>";
          this.eGui.innerHTML = iconsDiv;
        }
      };

      AmountCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };

      function ActionCellRenderer() {
      }

      ActionCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        this.eGui.style.textAlign = 'center';
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          $scope.actionButtonText = "Pay";
          $scope.actionButtonClassList = "";
          // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

          let iconsDiv = `<button ng-click="openViewAccountsPayableModal(invoiceId)" style="padding: 2px 4px;width: 32px !important; height: 26px !important; border-rardius: 2px; background-color: ${modifyPayButtonColor(params.data.invoiceDate, params.data.paymentStatus)}; color: white;  margin: 0 auto !important;" ng-disabled="agentDetails.disablePay" class="${$scope.actionButtonClassList}btn  waves-effect waves-light pull-right changecursor" data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a">${$scope.actionButtonText}</button>`;
          this.eGui.innerHTML = iconsDiv;
        }
      };
      //Modifying Pay button based on invoiceDate and paymentStatus.
      let modifyPayButtonColor = function (date, status) {

        //When the paymentStatus is paid - return default color, change text and append icon.
        if (status === "PAID") {
          $scope.actionButtonText = "";
          $scope.actionButtonClassList = "fa fa-info-circle btn-sm";
          return "#5fbeaa";
        }

        //Check for Late Invoices and return red if late.
        try {
          let todaysDate = new Date();
          let dateSplits = date.split(' ')[0].split('-');
          date = new Date(dateSplits[1] + '/' + dateSplits[0] + '/' + dateSplits[2]);

          let diffInTime = todaysDate.getTime() - date.getTime();
          let diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
          if (diffInDays > 7) {
            return "red";
          }
        }
        catch (e) {
          return "#5fbeaa";
        }
        return "#5fbeaa";
      }
      ActionCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };

      function ViewCellRenderer() {
      }

      ViewCellRenderer.prototype.init = function (params) {
        this.eGu = document.createElement('div');
        this.eGu.style.textAlign = 'center';
        if (params.data !== "" || params.data !== undefined || params.data !== null) {
          let iconsDiv = `<button ng-click="openSaleOrder()" style="padding: 5px 7px;" ng-disabled="agentDetails.disablePay" class="btn btn-default waves-effect waves-light pull-right changecursor fa fa-eye btn-md btn-green" data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a"></button>`
          this.eGu.innerHTML = iconsDiv;
        }
      };

      ViewCellRenderer.prototype.getGui = function () {
        return this.eGu;
      };

      $scope.loadPayoutDetails = function (userType) {
        payoutService.getAccountsPayableDetails(userType)
          .then(function (res) {
            $stateParams.selectedDate = null;
            if (res && res.status === "SUCCESS") {
              $scope.totalRecords = res.data.totalRecords ? res.data.totalRecords : 0;
              $scope.totalInvoicesCount = res.data.totalInvoicesCount;
              $scope.totalPendingInvoicesCount = res.data.totalPendingInvoicesCount;
              $scope.totalPendingInvoicesAmount = res.data.totalPendingInvoicesAmount;
              $scope.totalLatePendingInvoicesCount = res.data.totalLatePendingInvoicesCount;
              if (res.data.accountsPayableDetails && res.data.accountsPayableDetails.length > 0) {
                $scope.agents_list = res.data.accountsPayableDetails;
                $scope.noPayoutsData = false;
                if ($scope.gridOptions && $scope.gridOptions.api) {
                  $scope.gridOptions.api.setRowData($scope.agents_list);
                }
              } else {
                $scope.agents_list = [];
                $scope.noPayoutsData = true;
              }
            } else {
              $scope.noPayoutsData = true;
              $scope.agents_list = null;
            }
          })
          .catch(function (err) {
            console.error("Error in fetch Payout Details:" + err);
          })


      };

      $scope.openViewAccountsPayableModal = function (invoiceId) {
        let viewAccountsPayableModalData = {};
        viewAccountsPayableModalData.invoiceId = invoiceId;

        var modalInstance = $uibModal.open({
          templateUrl: 'views/view-accounts-payable-modal.html',
          controller: 'ViewAccountsPayableModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
            viewAccountsPayableModalData: function () {
              return viewAccountsPayableModalData;
            }
          }
        });

        modalInstance.result.then(function () {
        }, function (res) {
          if (res === "submitted") {
            $state.go($state.current, { selectedDate: $scope.date.endDate }, { reload: true });
          }
        });
      };

      //open invoice in new Window
      $scope.openSaleOrder = function (URL) {
        window.open(URL);
      }

      // View based filtering
      $scope.selectType = function () {

        /* TODO Redundant Code. showAgentType has no usecase as of now.
        if ($scope.selectedType.type === "Agent") {
          console.log($scope.selectedType.type);
          $scope.showAgentType = true;
        } else if ($scope.selectedType.type === "MasterFranchise") {
          $scope.showAgentType = false;
        }*/
        if ($scope.selectedType === "Partner - MR") {
          $scope.loadPayoutDetails("WHOLESALER");
        }
        else if ($scope.selectedType === "REO") {
          $scope.loadPayoutDetails("AGENT");
        }
        else if ($scope.selectedType === "Master Franchise - MFA") {
          $scope.loadPayoutDetails("MASTERFRANCHISE");
        }
        else if ($scope.selectedType === "ScrapQ") {
          $scope.loadPayoutDetails("ADMIN");
        }
      };

      // TODO IIFE here
      $scope.loadPayoutDetails();
    });
})(window.angular);
