'use strict';

/**
 * Controller for adding payment info by CRM
 *
 * This is mainly for CRM allowing them to add payment info saying that they have received payment from wholesaler
 */
angular.module('scrapQcrmApp')
  .controller('AddPaymentCtrl', function($scope, authentication, wholesalerService, agentService, Upload, pricingService, products, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap) {
    
    /*=============================================Default values==========================================================*/
    /**
     * Default value init
     */
    (function() {
      $scope.data = {};
      $scope.data.wholesalerId = items;
      $scope.paymentOptions = ["Cheque", "Draft", "Netbanking", "Debit Card", "Credit Card", "Cash"];
    })();
    
    /*============================================Core functions=======================================================*/
    
    $scope.addPayment = function() {
      wholesalerService.addPaymentInfo($scope.data)
        .then(function(res) {
          if (res.status === 'SUCCESS') {
            growl.success(res.data.message);
            $timeout(function() {
              $scope.cancel();
            }, 2500)
          } else {
            if (res.error && res.error.message) {
              growl.error(res.error.message);
            } else {
              growl.error('Failed to add payment');
            }
            console.error("Error inserting payment ", res);
          }
        })
        .catch(function(err) {
          growl.error('Failed to add payment');
          console.error("Error inserting payment ", err);
        })
    };
    
    /*=========================================Helper functions======================================================*/
    /**
     * when X icon or close button is clicked
     */
    $scope.cancel = function(res) {
      $uibModalInstance.dismiss(res);
    };
  });
