'use strict';
/**
 * # WarehouseEditProfileCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('WarehouseEditProfileCtrl', function ($scope, warehouseService, wholesalerService, agentService, $stateParams, $window, growl, $global) {

    var items = $stateParams.id;
    // all regex goes here
    (function () {
      $scope.nameRegex = /^[a-zA-Z\s.]*$/;
      $scope.mobileRegex = /^([0-9]{12})+$/; // warehouse once created will have 91+mobile as a end result mobile number, hence the 12 digits
      $scope.maximumCapacityQuantityRegex = /^([0-9]{1,6})(\.\d{1,2})?$/; //max up to 6 digits, and/or with 1,2 decimals
      $scope.maxCapacityUoMRegex = $scope.nameRegex;
      $scope.vm = { isPrimary: false };
    })();

    (function () {
      // populate warehouse type values
      //assuming the ids are string
      $scope.warehouseTypeOptions = [
        { id: "0", name: 'Wholesalers' },
        { id: "1", name: 'MasterFranchise' },
        { id: "2", name: 'ScrapQ' },
        { id: "3", name: 'Agent' },
      ];
      $scope.disablePrimaryCheck = false;
    })();

    (function () {
      warehouseService.getProfile(items)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            console.log("get profile res = ", res.data);
            $scope.data = res.data.warehouse;
            $scope.data.mobileNo = $scope.data.mobile;
            $scope.vm.isPrimary = $scope.data.isPrimaryWarehouse ? $scope.data.isPrimaryWarehouse : false;

            // set maximum capacity - uom and quantity
            if (res.data && res.data.warehouse && res.data.warehouse.maximumCapacity) {
              let maximumCapacity = res.data.warehouse.maximumCapacity;
              if (maximumCapacity.uom && maximumCapacity.quantity) {
                $scope.data.maximumCapacityUoM = maximumCapacity.uom;
                $scope.data.maximumCapacityQuantity = maximumCapacity.quantity;
              }
            }

            // warehouseType: "Wholesalers"
            // warehouseTypeValue: "SQ Dilshuknagar"
            if (res.data && res.data.warehouse.warehouseType) {
              for (let obj of $scope.warehouseTypeOptions) {
                if (obj && obj.name === res.data.warehouse.warehouseType) {
                  $scope.data.warehouseType = obj.id;
                  let warehouseTypeValue = res.data.warehouse.warehouseTypeValue;
                  $scope.change(warehouseTypeValue);
                  break;
                }
              }
            }
          } else {
            console.error('Error in get warehouse profile ', res);
          }
        })
        .catch(function (err) {
          console.error('Error in get warehouse profile ', err);
        });
    })();

    $scope.cancel = function () {
      // $uibModalInstance.close('cancel');
      $window.history.back();

    };

    $scope.save = function () {
      $scope.data.dataType = "warehouse";
      $scope.data.warehouseType = $scope.warehouseTypeOptions[$scope.data.warehouseType].name;
      $scope.data.isPrimaryWarehouse = $scope.vm.isPrimary;
      warehouseService.updateProfile($scope.data)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success(res.data.message);
            // $uibModalInstance.dismiss($scope.data);
            $window.history.back();
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message)
          } else {
            console.error('Error in update profile ', res);
            growl.error('Failed to update warehouse profile');
          }
        })
        .catch(function (err) {
          console.error('Error in update profile ', err);
          growl.error('Failed to update warehouse profile');
        })
    };

    $scope.change = function (warehouseTypeValue) {
      $scope.disablePrimaryCheck = false;
      $scope.data.warehouseTypeValue = null;
      console.clear();
      let selectedWarehouseTypeId = $scope.data.warehouseType;

      if (selectedWarehouseTypeId === "0") { //get all wholesalers, create a new field called warehouseType value, populate it with dropdown of values
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 0', $scope.warehouseTypeOptions[$scope.data.warehouseType]);
        wholesalerService.usersLoadAll('WHOLESALER')
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = true;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.wholesalerSelectedOptions = res.data.users;

              if (warehouseTypeValue) {
                for (let userObj of res.data.users) {
                  if (userObj && userObj.name && userObj.name === warehouseTypeValue) {
                    console.log(userObj);
                    $scope.data.warehouseTypeValue = userObj.registrationId;
                    break;
                  }
                }
              }

            } else {
              console.error('Failed to get a list of all wholesalers ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "1") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 1', $scope.warehouseTypeOptions[$scope.data.warehouseType]);
        warehouseService.retrieveMasterFranchise()
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.masterFranchiseOptions = res.data.users;

              if (warehouseTypeValue) {
                for (let userObj of res.data.users) {
                  if (userObj && userObj.name && userObj.name === warehouseTypeValue) {
                    console.log(userObj);
                    $scope.data.warehouseTypeValue = userObj.registrationId;
                    break;
                  }
                }
              }

            } else {
              console.error('Failed to get mf user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });
      } else if (selectedWarehouseTypeId === "2") {
        warehouseService.retrieveSuperAdmin()
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.isScrapQOptionSelected = true;
              $scope.scrapQOptions = res.data.users;

              if (warehouseTypeValue) {
                for (let userObj of res.data.users) {
                  if (userObj && userObj.name && userObj.name === warehouseTypeValue) {
                    console.log(userObj);
                    $scope.data.warehouseTypeValue = userObj.registrationId;
                    break;
                  }
                }
              }

            } else {
              console.error('Failed to get a list of all admins ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "3") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 3', $scope.warehouseTypeOptions[$scope.data.warehouseType]);

        agentService.usersLoadAll('AGENT')
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.agentOptions = res.data.users;

              if (warehouseTypeValue) {
                for (let userObj of res.data.users) {
                  if (userObj && userObj.name && userObj.name === warehouseTypeValue) {
                    console.log(userObj);
                    $scope.data.warehouseTypeValue = userObj.registrationId;
                    break;
                  }
                }
              }

            } else {
              console.error('Failed to get agent user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });

      } else {
        console.error('Invalid warehouse selected ', selectedWarehouseTypeId, $scope.data.warehouseType);
        $scope.isWholesalerOptionSelected = false;
        $scope.isMasterFranchiseOptionSelected = false;
        $scope.isScrapQOptionSelected = false;
        $scope.isAgentOptionSelected = false;
      }
    };

  });
