'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:SaleOrderCtrl
 * @description
 * #SaleOrderCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('SaleOrderCtrl', function ($scope, $filter, $moment, $interval, $global, saleOrderService, $stateParams, $rootScope, growl, $uibModal, $log) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    $scope.inrFilter = $filter('INR');
    $scope.TotalAmountCellRenderer =  function() {
    } 
    $scope.TotalAmountCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        // let val= params.value?(params.value).toFixed(2):'-';
        let val = params.value ? $scope.inrFilter(params.value) : '-';
        var iconsDiv = "<div>&#x20b9; " + val + "</div>";
        this.eGui.innerHTML = iconsDiv;
      }
    }; 
    $scope.TotalAmountCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    $scope.date = {
      startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
      endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
    };
    $scope.payMethod = false;

    $scope.resetCountLabels = function () {
      $scope.countLabels = {
        saleOrder: 0,
        approved: 0,
        pending: 0,
        cancelled: 0,
        rejected:0
      }
    }

    // TODO - Make these dynamic
    $scope.uniqueStatuses = [
      "DISPACHED",
      "APPROVED",
      "REJECTED",
      "PENDING"      
    ]
  
    function CellRender() {
    }
    CellRender.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";
        let iconsDiv = "";
       if (params.column.colId == "confirmationId" && params.data) {
          iconsDiv = `<p style="line-height:3" class="` + params.data.latestatus + `">` + params.data.confirmationId + `</p>`;
        }
        this.eGui.innerHTML = iconsDiv;
       
    };
  }
    CellRender.prototype.getGui = function () {
      return this.eGui;
    };

    var SaleOrderColumnDefs = [
     
      {
        headerName: "Warehouse",
        field: "warehouseName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Vendor",
        field: "vendorName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "confirmationId",
        field: "confirmationId",
        cellRenderer: CellRender,
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },    
      {
        headerName: "Amount",
        field: "totalPrice",
        cellStyle: { 'textAlign': 'right' },
        cellRenderer: $scope.TotalAmountCellRenderer,
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00'
      },
      {
        headerName: "GST Price",
        field: "gstPrice",
        cellStyle: { 'textAlign': 'right' },
        cellRenderer: $scope.TotalAmountCellRenderer,
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00'
      },
      {
        headerName: "Total Price With GST",
        field: "totalPriceWithGST",
        cellStyle: { 'textAlign': 'right' },
        cellRenderer: $scope.TotalAmountCellRenderer,
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00'
      },
      {
        headerName: "Status",
        field: "status",
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueStatuses; // fetch values from server
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },       
        
      },
      {
        headerName: "Date",
        field: "inceptionDate",
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'],
          closeOnApply: true,
        },
        valueFormatter: params => params.data.inceptionDate ? $moment(params.data.inceptionDate).format('DD-MM-YYYY HH:mm.ss') : ""
      },
    ];
    // implements ag-grid dataSource interface
    $scope.saleOrderDataSource = {
      getRows(params) {    
      

        let city = $rootScope.loginData.city;

        let requestBody = {};
      //  requestBody.fromDate = fromdate;
      //  requestBody.toDate = todate;

        if (city) {
          requestBody.city = city;
        }

        let startRow = params.request.startRow;
        let endRow = params.request.endRow;
        let sortModel = params.request.sortModel;
        let filterModel = params.request.filterModel;
        
       let agGridRequestParams = {};
        // pagination is present by default, no validation needed here
        agGridRequestParams.startRow = startRow;
        agGridRequestParams.endRow = endRow;

        if (sortModel && sortModel.length > 0) {
          agGridRequestParams.sortModel = sortModel;
        }
        agGridRequestParams.filterModel = filterModel;

        requestBody.agGridRequestParams = agGridRequestParams;

        console.log("requestBody = ", JSON.stringify(requestBody, null, 2));

        saleOrderService.getSaleOrderGrid(requestBody)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.saleOrderDetails && res.data.saleOrderDetails.results.length > 0) {
              $scope.results = res.data.saleOrderDetails.results;

              $scope.countLabels.saleOrder = res.data.saleOrderDetails.totalSaleorderCount;
             // if ($scope.showSellRequests || !$scope.showGroupRequests) {
                $scope.countLabels.pending = res.data.saleOrderDetails.totalPendingSOCount;
                $scope.countLabels.approved = res.data.saleOrderDetails.totalApprovedSOCount;
                $scope.countLabels.rejected = res.data.saleOrderDetails.totalRejectedSOCount;
                $scope.countLabels.dispatched = res.data.saleOrderDetails.totalDispatchedSOCount;
             // };
              params.successCallback($scope.results, res.data.saleOrderDetails.totalSaleorderCount);
            } else if (res.status === "SUCCESS") {
              $scope.countLabels.saleOrder = res.data.totalSaleorderCount;
              if ($scope.showSaleRequests || !$scope.showGroupRequests) {
                $scope.countLabels.pending = res.data.totalPendingSOCount;
                $scope.countLabels.approved = res.data.totalApprovedSOCount;
                $scope.countLabels.rejected = res.data.totalRejectedSOCount;
                $scope.countLabels.dispatched = res.data.totalDispatchedSOCount;

                console.error(res);
                growl.error("No results found");
              };
              $scope.saleOrderGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();

              params.failCallback()
            } else {
              $scope.countLabels.approved = 0;
              $scope.countLabels.pending = 0;
              $scope.countLabels.rejected = 0;
              $scope.countLabels.dispatched = 0;

              console.error(res);
              if ($scope.showSaleRequests || !$scope.showGroupRequests) {
                growl.error("No results found");
              }
              $scope.saleOrderGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();

              params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
            }
          })
          .catch(function (err) {
            if ($scope.showSaleRequests || !$scope.showGroupRequests) {
              $scope.countLabels.approved = 0;
              $scope.countLabels.pending = 0;
              $scope.countLabels.rejected = 0;
              $scope.countLabels.dispatched = 0;

            }
            console.error(err);
            growl.error("No results found");
            $scope.saleOrderGridOptions.api.showNoRowsOverlay();
            $rootScope.hideAgGridLoadingRow();

            params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
          })
      }
    };

    $scope.saleOrderGridOptions = {
      columnDefs:SaleOrderColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      rowHeight: 39,
      // suppressCsvExport: true,
      // suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      rowModelType: 'serverSide', // use the server-side row model instead of the default 'client-side'
      pagination: true,
      cacheBlockSize: 25,
      blockLoadDebounceMillis: 100,
      paginationPageSize: 1000,

      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
       $scope.saleOrderGridOptions.api.setServerSideDatasource($scope.saleOrderDataSource);

      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data.confirmationId);
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    $scope.getSaleRequests = function () {
      $scope.resetCountLabels();
      if ($scope.saleOrderGridOptions && $scope.saleOrderGridOptions.api) {
        $scope.saleOrderGridOptions.api.setServerSideDatasource($scope.saleOrderDataSource);
      }
      $scope.showSaleRequests = true;
      $scope.showGroupRequests = false;
    }
    $scope.getSaleRequests();

    $scope.getGroupRequests = function () {
      $scope.resetCountLabels();
      $scope.showSaleRequests = false;
      $scope.showGroupRequests = true;
      if ($scope.saleOrderGridOptions && $scope.saleOrderGridOptions.api) {
        $scope.saleOrderGridOptions.api.setServerSideDatasource($scope.saleOrderDataSource);
      }
           
    }
    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };

    $scope.setStartDate = function () {
      $scope.date.startDate = moment().subtract(4, "days").toDate();
    };

    $scope.setRange = function () {
      $scope.date = {
        startDate: moment().subtract(5, "days"),
        endDate: moment()
      };
    };       

    $scope.currentPage = 1;
    $scope.allRequests = function (params) {
    
      $scope.pending = 0;
      $scope.approved = 0;
      $scope.rejected = 0;
      $scope.pending = 0;
      $scope.dispatched = 0;    

      $scope.resetCountLabels();
      if ($scope.saleOrderGridOptions && $scope.saleOrderGridOptions.api) {
        $scope.saleOrderGridOptions.api.setServerSideDatasource($scope.saleOrderDataSource);
      }            
    }
    //// Modification
    $scope.OpenRequest = function (saleOrderid) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/sale-order-modal.html',
        controller: 'SaleOrderModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          saleOrderid: function () {
            return saleOrderid;
          }
        }
      });      
    };
   });