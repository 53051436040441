'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:LogisticsCtrl
 * @description
 * # LogisticsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('LogisticsCtrl', function($scope, $rootScope, $global, $uibModal, $window, SellNow, growl, $log, logisticsService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {
        $scope.pages = -1;
        $scope.vm = { params: {} };
        $scope.currentPage = 1;

        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        $scope.date = {
            startDate: $moment().toDate(),
            endDate: $moment().toDate()
        };

        $scope.reasonCodeList = [{
                "reason": "I DONT NEED A VEHICLE ANYMORE",
                "code": "APPCR01"
            },{
                "reason":"MY DRIVER WAS LATE",
                "code": "APPCR02"
            },{
                "reason":"MY DRIVER REFUSED/ASKED ME TO CANCEL",
                "code":"APPCR03"
            },{
                "reason":"VEHICLE INCOMPATIBLE WITH MY GOODS",
                "code":"APPCR04"
            },{
                "reason":"I BOOKED BY MISTAKE",
                "code":"APPCR05"
            },{
                "reason":"I AM CHECKING OUT THE APP",
                "code":"APPCR06"
            },{
                "reason":"MY GOODS ARE NOT READY",
                "code":"APPCR07"
            },{
                "reason":"I AM UNABLE TO CONTACT MY DRIVER",
                "code":"APPCR08"
            },{
                "reason":"MY DRIVER SAID HE WILL BE LATE",
                "code":"APPCR09"
            },{
                "reason":"I EXPECTED A SHORTER ARRIVAL TIME",
                "code":"APPCR10"
            }
            ];

        console.log($scope.date)

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        // console.log($scope.opts)

        $scope.setStartDate = function() {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function() {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.length = 1000;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDisplayLength($scope.length)
                    .withOption('order', [1, 'desc']);


        //Watch for date changes
        $scope.$watch('date', function(newDate) {

            $scope.transactionChange();

        }, false);

        $scope.nextDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.endDate).add(1, "days"),
                endDate: moment($scope.date.endDate).add(1, "days")
            };
            $scope.transactionChange();
        }
        $scope.previousDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.startDate).subtract(1, "days"),
                endDate: moment($scope.date.startDate).subtract(1, "days")
            };
            $scope.transactionChange();
        }

        $scope.currentPage = 1;
        $scope.transactionChange = function() {

            $scope.totalBookingRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;
            $scope.vm.params.endDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            $scope.vm.params.startDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');

            if ($scope.vm.params.startDate == $scope.vm.params.endDate) {
                $scope.vm.params.singledayquery = true;
            }
            // $scope.vm.params.status =  'ASSIGNED_TO_AGENT';// 'PENDING';
            // console.log($scope.vm.params.todate)
            $scope.loadLynkBooking($scope.vm.params);
    
        };

        //console.log($rootScope.loginData.role)

        var sourceAddress,destinationAddress;
        var sourceLat;
        var sourceLong;
        var sourceLatLong, destinationLatLong;


        $scope.loadLynkBooking = function(params) {
            var type = 'LOGISTICS';
            $scope.submitted = true;
            $scope.paging = false;
            $scope.noBookingFound = false;

            if (!params || !params.page) {
                params = {};
                params.page = 1;
            }

            var sourceAddress, destinationAddress;
            // console.log($moment($scope.date.startDate).format('DD-MMM-YYYY'))
            logisticsService.loadBooking(type, params.page, $moment($scope.date.startDate).format('DD-MMM-YYYY'), $moment($scope.date.endDate).format('DD-MMM-YYYY')).then(function(res) {
                if (res.status == "SUCCESS") {
                    $scope.booking = res.data;
                    // console.log($scope.booking)
                    $scope.totalBookingRecords = res.data.totalRecords;
                    // if(res.data.logisticsVMS.length == 1){
                    //     $scope.bookingDetails(0);
                    // }else {
                        // console.log(res.data.logisticsVMS)
                        res.data.logisticsVMS.forEach(function(elem, idx) {
                            try {
                                var date = elem.pickUpTime;
                                //console.log(date)
                                //var formattedDate = date.format("dd-mm-yyyy hh:MM:ss");
                                //console.log(formattedDate)
                                var addrsplit = elem.sourceAddress.split(/\,+/);
                                //console.log(addrsplit)
                                res.data.logisticsVMS[idx]['area'] = addrsplit[addrsplit.length-4].trim() + ", " + addrsplit[addrsplit.length-3].trim();
                            } catch(e) {
                                console.log(e)
                            }


                        });
                        // var addrsplit = res.data.logisticsVMS[0].sourceAddress.split(/\,+/);
                        // console.log(addrsplit)
                        // res.data.logisticsVMS[0]['area'] = addrsplit[addrsplit.length-4].trim() + ", " + addrsplit[addrsplit.length-3].trim();
                        // console.log(res.data)
                        $scope.booking_list = res.data.logisticsVMS;
                        // console.log($scope.booking_list)
                        $scope.bookingid = false;
                    // }
                    if(res.data.message == "No records found"){
                        $scope.noBookingFound = true;
                    }
                } else if (res.status == "FAILURE") {
                    $scope.noBookingFound = true;
                } else {
                    $scope.noBookingFound = true;
                }
            }, function(err) {

            })
        };


        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
            $scope.loadLynkBooking($scope.vm.params);
        } else if ($rootScope.loginData.role == "LOGISTICS") {
            //console.log($rootScope.loginData)
        
            $scope.bookingid = $rootScope.loginData.userid;
            // console.log($scope.bookingid)
            //console.log($scope.wholesalerId)
            // getWholesalerProfile($scope.wholesalerId);
            $scope.booking_list = false;
        }

        $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDisplayLength($scope.length);

        $scope.pageChanged = function(currentPage) {
            //console.log(currentPage)
            $scope.vm.params.page = currentPage;
            $scope.loadLynkBooking($scope.vm.params);
        }

        // Location track map opration start
        var mapOptions = {
            zoom: 17,
            mapTypeId: 'roadmap'
        };

        function initMapWithoutCenter(){
            $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
        }

    
        // Location track map opration stop

        $scope.bookingDetails = function(val) {
            var details = $scope.booking.logisticsVMS[val];
            console.log(details)
            $scope.bookingid = details.orderNo;
            getBookingProfile($scope.bookingid);
            $scope.booking_list = false;
            $scope.vm.params = {
                "booking": details.logisticsId,
                "size": 10,
                "page": 0,
                "status": "pending"
            };
        }
        $scope.addLynkBooking = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-lynk-booking.html',
                controller: 'AddLynkBookingCtrl',
                size: "lg",
                resolve: {
                    items: function() {
                        return 'LOGISTICS';
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                 $scope.loadLynkBooking($scope.vm.params);
            });

        };

        $scope.OpenRequest = function(booking) {
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/logistics-booking-modal.html',
                controller: 'LogisticsBookingModalCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    booking: function() {

                        return booking;
                    }
                }
            });

            pickupInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                // $scope.allRequests($scope.vm.params);
            });
        }

        
        function getBookingProfile(logisticsId) {
            logisticsService.getProfile(logisticsId).then(function(res) {
                if (res.status == "SUCCESS") {
                    $scope.profileEdit = res.data;
                    // console.log($scope.profileEdit)
                    // $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
                    var latlongArr = $scope.profileEdit.sourceLatLong.split(',');
                    // console.log(latlongArr[0]);
                    // console.log(latlongArr[1].trim());
                    $scope.centerdata = {"latitude" : parseFloat(latlongArr[0]),
                                "longitude" : parseFloat(latlongArr[1].trim())};
                    // console.log($scope.centerdata)
                    $scope.center = new google.maps.LatLng(parseFloat(latlongArr[0]), parseFloat(latlongArr[1].trim()));
                    // console.log($scope.center)
                    // $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
                    // initMapWithoutCenter();
                    var mapOptions = {
                        zoom: 17,
                        mapTypeId: 'roadmap',
                        center: $scope.center
                    };
                    // $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
                    var icon = {
                        url: "/images/pulse.gif",
                        scaledSize: new google.maps.Size(30, 30),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(15, 15)
                    };
                    // var marker = new google.maps.Marker({
                    //     position: $scope.center,
                    //     map: $scope.map,
                    //     icon: icon
                    // });

                    var destLatLongArr = $scope.profileEdit.destinationLatLong.split(',');
                    var loc = [[latlongArr[0],latlongArr[1].trim()],[destLatLongArr[0],destLatLongArr[1].trim()]];

                    var marker, i;

                    for (i = 0; i < loc.length; i++) {
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(parseFloat(loc[i][0]), parseFloat(loc[i][1])),
                            map: $scope.map,
                            icon: icon
                        });
                    }
                    

                } else if (res.status == "FAILURE") {
                    $scope.noBookingFound = "No booking record found";
                }
                //$scope.profileEdit = res.data.agent;
            }, function(err) {
                //console.log(err);
            })
        }
        $scope.currentPage = 1;
        $scope.selectPage = function() {

        }
       
    });
