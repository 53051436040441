'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:WalletsCtrl
 * @description
 * # WalletsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('WalletsCtrl', function($scope, $rootScope, $moment, $global, $uibModal, DTOptionsBuilder, $window, paymentService, growl, $log , $stateParams) {
    	$scope.pages = -1;
        $scope.vm = { params: {} };
        $scope.currentPage = 1;

        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        $scope.addWallet = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-wallet.html',
                controller: 'AddWalletCtrl',
                size: "lg",
                resolve: {
                    items: function() {
                        return 'WALLET';
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                 $scope.loadWallets($scope.vm.params);
            });

        };


    	$scope.loadWallets = function(params) {
            var type = 'PAYMETNS';
            $scope.submitted = true;
            $scope.paging = false;
            $scope.noWalletFound = false;

            if (!params || !params.page) {
                params = {};
                params.page = 1;
            }

            paymentService.getWallets(type, params.page).then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    $scope.wallets = res.data;
                    $scope.wallet_Details = res.data.walletVMS;
                    $scope.totalPaymentRecords = res.data.totalRecords;
                    $scope.length0 = false;
                    $scope.merchantOrderId = false;
                    if(res.data.message == "No records found"){
                        $scope.noPaymentFound = true;
                    }
                }else {
                    $scope.noWalletFound = true;
                }
            },function(err) {

            });

            
        };


        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
            $scope.loadWallets($scope.vm.params);
        }


        

        $scope.pageChanged = function(currentPage) {
            //console.log(currentPage)
            $scope.vm.params.page = currentPage;
            $scope.loadWallets($scope.vm.params);
        }

        
    });
