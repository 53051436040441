'use strict';

angular.module('scrapQcrmApp').controller('inventoryCtrl', function($state, $scope, $filter, $rootScope, $global, wholesalerService, agentService, Crossfilter, $moment, $stateParams) {
  var data = {};
  
  /**
   * Initially on page load - wholesalers will be selected by default, hence the below ground work
   * 1. load all the wholesalers as default
   * 2. radio button will have wholesaler as default and it's associated placeholder will be assigned
   * 3. Date - start and end will be current date and 30 before the current date respectively
   */
  (function() {
    console.log("inside self calling function");
    $scope.search = '';
    $scope.placeholderText = 'Please Enter Wholesaler Name';
    $scope.userType = 'WHOLESALER';
    
    var type = $scope.userType;
    let page = -1;
    wholesalerService.usersLoad(type, page, true).then(function(res) {
      if (res.status === "SUCCESS") {
        $scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
      }
    }, function(err) {
    });
    
    $scope.date = {
      startDate: moment().subtract(30, 'days').toDate(),
      endDate: $moment(new Date(), 'DD-MMM-YYYY').toDate()
    };
    
    //default - assigning sort types
    $scope.sortType = "totalamount";
    $scope.sortValue = true;
    $scope.totalSort = true;
  })();
  
  /**
   * When the radio button is changed between wholesaler and agent, the following function will be executed based on userType
   * @param userType - AGENT or WHOLESALER
   */
  $scope.changeUserType = function(userType) {
    $scope.clearSearch();
    if (userType === 'WHOLESALER') {
      $scope.userType = userType;
      $scope.placeholderText = 'Please Enter Wholesaler Name';
      let page = -1;
      wholesalerService.usersLoad($scope.userType, page, true).then(function(res) {
        if (res.status === "SUCCESS") {
          $scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
        }
      }, function(err) {
      });
    } else if (userType === 'AGENT') {
      $scope.userType = userType;
      $scope.placeholderText = 'Please Enter Agent Name';
      let page = -1;
      agentService.usersLoad($scope.userType, page).then(function(res) {
        if (res.status === "SUCCESS") {
          $scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
        }
      }, function(err) {
      });
    }
  };
  
  /**
   * Clicking on any of the object in the search result, will execute the following the function
   * @param val - entire user object
   */
  $scope.selectUserType = function(val) {
    $scope.selectedUserType = val.name;
    $scope.search = val.name;
    if ($scope.userType === 'WHOLESALER') {
      data.wholesalerId = val.userId;
    } else if ($scope.userType === 'AGENT') {
      data.agentId = val.userId;
    }
    init();
  };
  
  /**
   * Clicking on the cross or changing the radio buttons will execute the following function
   */
  $scope.clearSearch = function() {
    $scope.search = '';
    $scope.selectedUserType = false;
    $scope.noInventory = true;
    $scope.totalAmount = 0.00;
    $scope.totalQuantity = 0.00;
  };
  
  /**
   * Date filtering and assigning ranges
   */
  $scope.opts = {
    locale: {
      applyClass: 'btn-green',
      applyLabel: "Apply",
      fromLabel: "From",
      format: "DD-MMM-YYYY",
      toLabel: "To",
      cancelLabel: 'Cancel',
      customRangeLabel: 'Custom range'
    },
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')]
    }
  };
  
  /**
   * Changes in date will trigger the init function
   */
  $scope.$watch('date', function() {
    data.fromDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
    data.toDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
    console.log("watch date");
    console.log("data = ", data);
    if (data.agentId || data.wholesalerId) {
      init();
    }
  }, false);
  
  /**
   * Executed firstly on page load, then either
   * 1. on selecting user object
   * OR
   * 2. changes in date
   */
  function init() {
    if ($scope.userType === 'WHOLESALER') {
      wholesalerService.getInventory(data).then(function(res) {
        if (res.status === "SUCCESS") {
          $scope.inventory = res.data.inventory;
          $scope.totalAmount = 0;
          $scope.totalQuantity = 0;
          for (var item in $scope.inventory) {
            $scope.totalAmount = $scope.totalAmount + $scope.inventory[item].totalamount;
            $scope.totalQuantity = $scope.totalQuantity + $scope.inventory[item].totalquantity;
          }
          $scope.noInventory = $scope.inventory.length === 0;
        } else {
          $scope.noInventory = true;
        }
      }, function(err) {
        console.log(err);
      })
    } else if ($scope.userType === 'AGENT') {
      agentService.getInventory(data).then(function(res) {
        if (res.status === "SUCCESS") {
          $scope.inventory = res.data.inventory;
          $scope.totalAmount = 0;
          $scope.totalQuantity = 0;
          for (var item in $scope.inventory) {
            $scope.totalAmount = $scope.totalAmount + $scope.inventory[item].totalamount;
            $scope.totalQuantity = $scope.totalQuantity + $scope.inventory[item].totalquantity;
          }
          $scope.noInventory = $scope.inventory.length === 0;
        } else {
          $scope.noInventory = true;
        }
      }, function(err) {
        console.log(err);
      })
    }
  }
  
  $scope.changeSort = function(type) {
    if (type === "Quantity") {
      $scope.quantitySort = !$scope.quantitySort;
      $scope.sortType = "totalquantity";
      $scope.sortValue = $scope.quantitySort;
    }
    if (type === "Total") {
      $scope.totalSort = !$scope.totalSort;
      $scope.sortType = "totalamount";
      $scope.sortValue = $scope.totalSort;
    }
  }
})
