'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.agentAppointments
 * @description
 * # agentAppointments
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('agentAppointments', function (httpService, $global, $rootScope) {
        // Service logic
        // ...



        // Public API here
        return {
            getAgentAppointments: function () {
                var url = $global.getApiUrl() + $global.getApiObject().agentsAppointments;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, todayAppointments: function () {
                var url = $global.getApiUrl() + $global.getApiObject().todayAppointments;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getTomorrowAgentAppointments: function (agentId, date) {
                var url = $global.getApiUrl() + $global.getApiObject().tomorrowAgentAppointments.replace(":agentId", agentId)
                    .replace(":fromDate", date).replace(":toDate", date);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            agentAppointmentsPerSlot: function (date) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().agentAppointmentsPerSlot.replace(":date", date).replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getAppointmentWiseReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getAppointmentWiseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getGroupWiseReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getGroupWiseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getAgentWiseReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getAgentWiseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getAgentsAttendance: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getAgentsAttendance;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getInventoryReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getInventoryReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getSaleOrderReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getSaleOrderReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getPaymentStatusReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getPaymentStatusReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getPaymentFraudCheckReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getPaymentFraudCheckReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getCelektAppointmentWiseReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getCelektAppointmentWiseReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getCelektRRRWiseReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getCelektRRRWiseReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getCelektProductWiseReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getCelektProductWiseReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getCelektRRRWiseSubGridReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getCelektRRRWiseSubGridReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getCelektProductWiseSubGridReport: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getCelektProductWiseSubGridReport;
                var $request = httpService.httpRequest(url, "P", data);
                return $request
            },
            getAgentId: function () {
                return $rootScope.agentId;
            },
            setAgentId: function (details) {
                $rootScope.agentId = details;
            },
            getAgentName: function () {
                return $rootScope.agentName;
            },
            setAgentName: function (details) {
                $rootScope.agentName = details;
            },
        };
    });
