'use strict';

angular.module('scrapQcrmApp').filter('INR', function () {
  return function (input) {
    input = input ? input.toString().replaceAll(',', '') : input;
    if (!isNaN(input)) {
      if (!input) {
        input = "0";
      }
      //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!           
      var result = input.toString().split('.');

      var lastThree = result[0].substring(result[0].length - 3);
      var otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != '')
        lastThree = ',' + lastThree;
      var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (result.length > 1) {
        if (result[1].length > 2) {
          output += "." + result[1].substring(0, 2);
        } else if (result[1].length == 1) {
          output += "." + result[1] + "0";
        } else {
          output += "." + result[1];
        }
      } else {
        output += ".00";
      }

      return output === "0.00" ? "0.00" : output;
    }
  }
})
  .filter('COMMA_NUMBER', function () {
    return function (input) {
      input = input ? input.toString().replaceAll(',', '') : input;
      if (!isNaN(input)) {
        if (!input) {
          input = "0";
        }
        //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!           
        var result = input.toString().split('.');

        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        // if (result.length > 1) {
        //   if (result[1].length > 2) {
        //     output += "." + result[1].substring(0, 2);
        //   } else if (result[1].length == 1) {
        //     output += "." + result[1] + "0";
        //   } else {
        //     output += "." + result[1];
        //   }
        // } else {
        //   output += ".00";
        // }

        return output;
      }
    }
  });