'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:SalesAgentsCtrl
 * @description
 * # SalesAgentsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('SalesAgentsCtrl', function ($scope, $rootScope, $global, $uibModal, $window, SellNow, growl, $log, $state, salesAgentService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment, agentService) {


        // ag-grid init
        (function () {
            var columnDefs = [
                {
                    headerName: "Name",
                    field: "name",
                    cellStyle: { 'padding-left':'50px' }
                },
                {
                    headerName: "Mobile Number",
                    // cellRenderer: HideMobileNumber,
                    field : "mobileNo",
                    cellStyle: { 'text-align': 'center' }
                },
                {
                    headerName: "Inception Date",
                    field: "createdAt",
                    cellStyle: { 'text-align': 'center','padding-right':'30px' },
                    cellRenderer: DateCellRenderer
                },
                {
                    headerName: "Area",
                    field: "address",
                    cellStyle: { 'text-align': 'left','padding-left':'50px' }
                },
                {
                    headerName: "",
                    field: "action",
                    maxWidth: 65,
                    cellRenderer: ActionCellRenderer
                }
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true
                },
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                paginationPageSize: 1000,
                // paginationAutoPageSize: true,
                rowData: rowData,
                suppressCsvExport: true,
                suppressExcelExport: true,
                // suppressPaginationPanel:true,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    params.api.sizeColumnsToFit();
                    params.columnApi.autoSizeAllColumns();
                    $scope.gridOptions.api.setRowData($scope.salesAgent_list);
                },
                onCellClicked: function (event) {
                    // console.log('event: ',event);
                    if (event.column.colId === "action") {
                        // pass data from here!
                        getSalesAgentProfile(event.data.userId).then((val)=>{
                            $scope.addOrEditSalesAgents(true);
                        })
                        .catch( val => console.log("Unable to retrieve user details"));
                    } else {
                        if (!$scope.isEnabled) {
                            $scope.salesAgentDetails(event.rowIndex);
                        }
                    }
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            };

        })();

        // BELOW CODE UNCOMMENT TO HIDE MOBILE NUMBER OF CUSTOMERS. [headerName: "Mobile Number"]
        //Hide mobile number of the Customers to all except for Super Admin and CRM User
        // function HideMobileNumber() {
        // }
        // HideMobileNumber.prototype.init = function (params) {
        //     // console.log("where is mobile no.",params.data.mobile);
        //     this.mobileNumberDiv = document.createElement('div');
        //     if (params.data != "" || params.data != "undefined") {
        //         if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
        //             let mobileNo = params.data.mobile;
        //             this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        //         } else {
        //             let mobileNo = params.data.mobile;
        //             let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
        //             this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        //         }
        //     } else {
        //         this.mobileNumberDiv.innerHTML = "";
        //     }
        // }

        // HideMobileNumber.prototype.getGui = function () {
        //     return this.mobileNumberDiv;
        // }
        function ActionCellRenderer() {
        }
    
        ActionCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                $scope.actionButtonClassList = "";
    
                let iconsDiv = `<i class="md md-mode-edit" style="color: green"></i>`;
                this.eGui.innerHTML = iconsDiv;
            }
        };
    
        ActionCellRenderer.prototype.getGui = function () {
            return this.eGui;

        }

        function DateCellRenderer() {
        }
    
        DateCellRenderer.prototype.init = function (params) {
          this.eGui = document.createElement('div');
          if (params.data != "" || params.data != undefined || params.data != null) {
            // console.log("params.data.createdAt = ", params.data.createdAt);
            let dateModified = $moment(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss');
            params.data.createdAt = dateModified;
            // console.log("dateModified = ", dateModified);
            var iconsDiv = "<div>" + dateModified + "</div>";
            this.eGui.innerHTML = iconsDiv;
          }
        };
    
        DateCellRenderer.prototype.getGui = function () {
          return this.eGui;
        };

        //Get sales agent profile
        function getSalesAgentProfile(salesAgentId) {
            //To get opeartor details from sales server
            $scope.noStatistics = false;
            return new Promise((resolve,reject) => {

                agentService.operatorDetails(salesAgentId).then(function (res) {
                    if (res && res.status == "Success") {
                        $scope.profileEdit = $scope.operatorDetails = res.data;
                        $scope.noWholesalerFound = false;
                        console.log("Is this fetching all the records???",$scope.profileEdit);
                        resolve(res);
                    } else {
                        reject(res);
                        $scope.noSalesAgentFound = true;
                        $scope.noStatistics = true;
                    }
                }, function (err) {
                    $scope.noStatistics = true;
                });

            })
            // Sales agent details
            // salesAgentService.getProfile(salesAgentId).then(function (res) {
            //     if (res.status == "SUCCESS") {
            //         $scope.profileEdit = res.data.wholesaler;
            //         $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
            //         $scope.noWholesalerFound = false;
            //     } else if (res.status == "FAILURE") {
            //         $scope.noSalesAgentFound = true;
            //     }
            // }, function (err) { })
        }

        //Get sales agent profile
        $scope.salesAgentDetails = function (val) {
            var details = $scope.salesAgent_list[val];
            $scope.salesAgentId = details.userId;
            $scope.showSalesAgentList = false;
            $scope.showSalesAgentDetails = true;
            getSalesAgentProfile($scope.salesAgentId);
        }

        //Load sales agent
        $scope.loadSalesAgents = function (params) {
            $scope.showSalesAgentList = true;
            $scope.showSalesAgentDetails = false;
            $scope.noSalesAgentFound = false;
            if (!params || !params.page) {
                params = {};
                params.page = 1;
            }

            params.page = -1;
            let enabled = !$scope.isEnabled;
            salesAgentService.getAllSalesAgents(enabled).then(function (res) {
                console.log("What is the resssss of sales agents",res);
                if (res.status == "Success") {
                    // $scope.totalAgentRecords = res.data.length;
                    $scope.salesAgent_list = res.data;
                    if (res.data.length == 1) {
                        $scope.salesAgentDetails(0);
                    } else {
                        res.data.forEach(function (elem, idx) {
                            // TODO - Might have to modify
                            try {
                                var addrsplit = elem.address.formattedAddress.split(/\,+/);
                                res.data[idx]['address'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                            } catch (e) { }

                            try {
                                let date = elem.address;
                                res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                                res.data.users[idx]['createdAt'] = $moment(res.data.users[idx]['createdAt']).format('YYYY-MM-DD HH:mm:ss');
                            } catch (e) { }
                        });
                        $scope.salesAgent_list = res.data;

                        //ag-grid displays $scope.salesAgent_list data in the table
                        $scope.gridOptions.api.setRowData($scope.salesAgent_list);

                        if ($scope.salesAgent_list.length == 0) {
                            $scope.noSalesAgentFound = true;
                        }
                    }
                    if (res.data.message == "No records found") {
                        $scope.noSalesAgentFound = true;
                    }
                } else if (res.status == "FAILURE") {
                    $scope.noSalesAgentFound = true;
                } else {
                    $scope.noSalesAgentFound = true;
                }
            }, function (err) {
                $scope.noSalesAgentFound = true;
            })
        };

        $scope.statusChanged = function(event) {
            $scope.isEnabled = !$scope.isEnabled;
            $scope.loadSalesAgents($scope.vm.params);
        }

        //Page changed
        $scope.pageChanged = function (currentPage) {
            $scope.vm.params.page = currentPage;
            $scope.loadSalesAgents($scope.vm.params);
        }

        //Add/Edit sales agent
        $scope.addOrEditSalesAgents = function (data) {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-salesagent.html',
                controller: 'AddSalesAgentCtrl',
                size: "lg",
                resolve: {
                    salesAgentData: function () {
                        return data ? $scope.profileEdit : null;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
            }, function () {
                $scope.loadSalesAgents($scope.vm.params);
                $state.go($state.current, {}, { reload: true });
            });
        };

        $scope.disableSalesAgent = function () {

            if (confirm(`Are you sure you want to disable ${$scope.profileEdit.name}?`)) {

            console.log('data profileEdits: ',$scope.profileEdit);
            let data = {};

            const disableInNodeAPI = new Promise((resolve,reject) => {
                const id = $scope.profileEdit.userId;
                salesAgentService.disableSalesAgent(id).then((res) => {
                    console.log('res: ',res);
                    if (res.status === "Success") {
                        resolve(res);
                        $state.go($state.current, {}, { reload: true });
                    }
                    else {
                        reject(res);
                    }
                });
            });

            disableInNodeAPI.then((value) => {
                console.log(value)
                growl.success("Successfully disabled the sales agent");
                $state.go('root.salesagents',{},{reload:true});
            })
            .catch((err) => {
                console.error(err);
                growl.error("Unable to disable sales agent");
            });

            }
        };

        //Search sales agent
        $scope.searchSalesAgent = function (key) {
            key = key.trim();
            if (key) {
                if (key.length > 3) {
                    salesAgentService.getSalesAgent($scope.type, key).then(function (res) {
                        console.log('getsalesagent response : ',res);
                        if (res.status == "SUCCESS") {
                            $scope.salesAgent_list = res.data.users;
                            if (res.data.users.length == 1) {
                                $scope.salesAgentDetails(0);
                            } else {
                                res.data.users.forEach(function (elem, idx) {
                                    try {
                                        var addrsplit = elem.address.formattedAddress.split(/\,+/);
                                        res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                                    } catch (e) { }
                                });
                                $scope.salesAgent_list = res.data.users;
                                if ($scope.salesAgent_list.length == 0) {
                                    $scope.noSalesAgentFound = true;
                                }
                            }
                            if (res.data.message == "No records found") {
                                $scope.noSalesAgentFound = true;
                            }
                        } else if (res.status == "FAILURE") {
                            $scope.noSalesAgentFound = true;
                        } else {
                            $scope.noSalesAgentFound = true;
                        }
                    }, function (err) {
                        $scope.noSalesAgentFound = true;
                    })
                }
            } else {
                $scope.loadSalesAgents($scope.vm.params);
            }
        };

        //Inialize function
        function init() {
            $scope.vm = { params: {} };
            $scope.type = 'SALESAGENT';
            $scope.loadSalesAgents($scope.vm.params);
        }

        //Will call first
        init();
        $scope.isEnabled = false;
    });
