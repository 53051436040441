'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:WholesalerEditProfileCtrl
 * @description
 * # WholesalerEditProfileCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('WholesalerEditProfileCtrl', function ($scope, wholesalerService,  $rootScope, $stateParams, growl, consumer, $global, $timeout,$window) {

    var items = $stateParams.id;
    /*==============================================default promises========================================================*/

    const getAllWholesalerPriceCapsulesPromises = () => {
      return new Promise((resolve, reject) => {
        // get wholesaler pricing capsule details
        wholesalerService.getWholesalerPriceCapsules()
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data && res.data.results) {
              resolve(res);
            } else {
              reject('Unable to retrieve wholesaler price capsule details');
            }
          })
          .catch(function (err) {
            reject(err);
          })
      })
    }

    /*==============================================default service calls========================================================*/
    (function () {
      wholesalerService.getProfile(items)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.data = res.data.wholesaler;
            consumer.getAddress($scope.data.wholesalerId)
              .then(function (res) {

                if (res && res.data && res.data.addresses && res.data.addresses.length > 0) {
                  if (res.data.addresses[0].formattedAddress) { //get formattedAddress
                    $scope.data.formattedAddress = res.data.addresses[0].formattedAddress;
                  } else {
                    console.error('Missing data for formattedAddress');
                  }

                  if (res.data.addresses[0].latitude && res.data.addresses[0].longitude) {// set the map pointer based on the below geo-cords
                    let center = [];
                    center.push(res.data.addresses[0].latitude);
                    center.push(res.data.addresses[0].longitude);
                    $scope.center = center.join();
                  } else {
                    console.error('Missing data for lat and long')
                  }

                } else {
                  console.error('Missing data in res');
                }

              })
              .catch(function (err) {
                console.error('Error in consumer.getAddress($scope.data.wholesalerId)', err);
              });

            // get wholesaler pricing capsule details
            getAllWholesalerPriceCapsulesPromises()
              .then(function (res) {
                // logger
                $global.displayLog('getAllWholesalerPriceCapsulesPromises', 'res', res);

                // set view model
                $scope.priceCapsuleDetails = res.data.results;

                // pre-populate price capsule field
                if ($scope.data && $scope.data.priceCapsule && $scope.data.priceCapsule.id) {
                  for (let priceCapsule of $scope.priceCapsuleDetails) {
                    if (priceCapsule.id === $scope.data.priceCapsule.id) {
                      $scope.priceCapsule = priceCapsule;
                      //setting characters to cut in price capsule's description within the dropdown
                      setCharactersToCut();
                      break;
                    }
                  }
                }
              })
              .catch(function (err) {
                console.error(err);
              })

          } else {
            console.error('Empty results in getting the wholesalerService.getProfile(items)');
          }
        })
        .catch(function (err) {
          console.error('Error in wholesalerService.getProfile(items) ', err);
        });
    })();

    /*==============================================core functions===============================================*/
    $scope.save = function () {
      $scope.data.dataType = "wholesaler";
      delete $scope.data.address; // we don't have to send the entire address, as we can userId to get the address obj in backend

      if ($scope.priceCapsule) {
        $scope.data.priceCapsuleId = $scope.priceCapsule.id;
        $scope.data.priceCapsuleName = $scope.priceCapsule.name;
      }

      consumer.updateProfile($scope.data)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success(res.data.message);
            $window.history.back();
            // $uibModalInstance.dismiss($scope.data);
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message)
          } else {
            growl.error('Failed to update the wholesaler profile');
          }
        })
        .catch(function (err) {
          console.error('Error in updating wholesaler profile ', err);
        });
    };

    $scope.markerDragEnd = function (event) {
      $timeout(function () {
        let latitudeLongitudeObject = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

        $global.getLocationByLatLng(latitudeLongitudeObject)
          .then(function (res) {
            if (res) {
              if (res.formatted_address) { // formatted address
                $scope.data.formattedAddress = res.formatted_address;
              } else {
                console.error("Empty formattedAddress being sent");
              }

              // fields for the address object needed for updating the address collection
              let addressObj = $global.getAddressObj(res);
              if (addressObj) {
                if (addressObj.locality) {
                  $scope.data.locality = addressObj.locality;
                } else {
                  console.error('Missing locality');
                }
                if (addressObj.state) {
                  $scope.data.state = addressObj.state;
                } else {
                  console.error('Missing state');
                }
                if (addressObj.country) {
                  $scope.data.country = addressObj.country;
                } else {
                  console.error('Missing country');
                }
                if (addressObj.postalCode) {
                  $scope.data.postalCode = addressObj.postalCode;
                } else {
                  console.error('Missing postal code');
                }
                if (addressObj.longitude && addressObj.latitude) {
                  $scope.data.longitude = addressObj.longitude;
                  $scope.data.latitude = addressObj.latitude;
                } else {
                  console.error('Missing latitude and/or longitude');
                }
                $scope.data.city = $rootScope.loginData.city;
              } else {
                console.error('Error/empty results for the addressObj');
              }

              // center the map location of this new address
              let latLng = [];
              latLng.push(latitudeLongitudeObject.lat);
              latLng.push(latitudeLongitudeObject.lng);
              $scope.center = latLng.join();
            } else {
              console.error('Error/empty results in getting location by latitude and longitude');
            }
          })
          .catch(function (err) {
            console.error("failed to fetch formatted address ", err);
          });
      })
    };

    /*==============================================helper functions=====================================================*/
    $scope.cancel = function () {
      // $uibModalInstance.close('Close');
      $window.history.back();

    };

    function setCharactersToCut() {
      // calculating number of characters to cut in description display of Price Capsule dropdown based on dropdown's width wrt screen size
      $scope.charactersToCut = Math.round(document.getElementById("priceCapsule").offsetWidth / 7);
      console.log($scope.charactersToCut, document.getElementById("priceCapsule").offsetWidth);
    }

  });
