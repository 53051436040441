'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:SelectWarehouseCtrl
 * @description
 * # SelectWarehouseCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('SelectWarehouseCtrl', function ($scope, $rootScope, SellNow, consumer, $moment, apartments, $uibModalInstance, warehouseService, items, $global, $uibModal, DTOptionsBuilder, $window, growl) {

    (function () {
      $global.displayLog('Inside select warehouse ctrl ', 'DI value of items', items);
      $scope.data = {};
      $scope.data.warehouseId = items.warehouseId;
      $scope.disableSubmitButton = true;
    })();


    (function () {
      let page = -1; //we need all the warehouses results instead of paginated results
      warehouseService.getWarehouse(page)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.warehouse_list = res.data.warehouses;
          } else {
            console.error("Error in getting all the warehouses list ", res);
          }
        })
        .catch(function (error) {
          console.error("Error in getting all the warehouses list ", error);
        })
    })();

    $scope.warehouseValueChanged = function () {
      if (items.warehouseId === $scope.data.warehouseId) {
        growl.error('Changed warehouse matches with the existing warehouse name : ' + items.warehouseName);
        $scope.disableSubmitButton = true;
        return;
      }

      $scope.disableSubmitButton = false;
    };


    $scope.cancel = function (res) {
      $uibModalInstance.dismiss(res);
    };

    $scope.changeWarehouse = function () {
      $scope.data.sellId = items.sellObjId;
      $scope.data.consumerAddressId = items.consumerAddress ? items.consumerAddress.addressId : "";
      $scope.data.preferredDate = items.preferredDate;
      $scope.data.preferredSlot = items.preferredSlotId;
      $scope.data.city = items.city;
      if ($scope.data.warehouseId != null) {

        if (items.communityAppointmentId) {
          apartments.updateAppointment(items.communityAppointmentId, { warehouseId: $scope.data.warehouseId }).then(function (res) {
            if (res.status == "SUCCESS") {
              growl.success(res.data.message);
              $uibModalInstance.dismiss();
            } else if (res.status == "FAILURE") {
              growl.error(res.error.message);
              $uibModalInstance.dismiss();
            }
          })
        } else {
          SellNow.updatePickup($scope.data)
            .then(function (res) {
              if (res.status === "SUCCESS") {
                growl.success(res.data.message);
                $uibModalInstance.dismiss($scope.data);
              } else if (res.status === "FAILURE") {
                if (res.error && res.error.errors && res.error.errors.length > 0) {
                  let errorMessage = "";
                  for (let errorObj of res.error.errors) {
                    errorMessage += errorObj.message;
                    errorMessage += " ";
                  }
                  growl.error(errorMessage);
                } else {
                  growl.error(res.error.message)
                }
              } else {
                growl.error("Failed to update the warehouse");
              }
            })
            .catch(function (err) {
              growl.error("Failed to update the warehouse");
              console.error("Failed to update the warehouse ", err);
            })
        }


      } else {
        growl.error('Missing warehouse Id, unable to update the warehouse');
      }
    };
  });


