'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:VendorItemsModalCtrl
 * @description
 * # VendorItemsModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('VendorItemsModalCtrl', function ($scope, $filter, authentication, vendorService, vendorId, Upload, pricingService, products, consumer, $moment, status, $global, growl, $uibModalInstance, $state, $timeout, NgMap) {
    
        $scope.inrFilter = $filter('INR');
        $scope.isAddButtonOn = false;
        $scope.isEditButtonOn = false;
        $scope.hidethis = false;
        $scope.vendorStatus = status;

        (function () {
            var columnDefs = [
                {
                    headerName: "Category",
                    field: "productCategoryName",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Product",
                    field: "productName",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Vendor Product",
                    field: "vendorItemName",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                    editable:  params => $scope.isEditButtonOn,
                    singleClickEdit: true,
                    cellStyle: params => {
                        if ($scope.isEditButtonOn) {
                            return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                        }
                        return { 'text-align': 'right' }
                    },
                },
                {
                    headerName: "UOM",
                    field: "uom",
                    flex: 1,
                    maxWidth: 100,
                    cellStyle: { 'text-align': 'left'},
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorParams: {
                        values: ['Kg', 'Per Unit'],
                        cellRenderer: '',
                        cellHeight: 30,
                      },
                    cellStyle: params => {
                        if ($scope.isEditButtonOn) {
                            return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                        }
                        return { 'text-align': 'right' }
                    },
                    editable: params => $scope.isEditButtonOn,
                    singleClickEdit: true

                },
                {
                    headerName: "Status",
                    field: "status",
                    flex: 1,
                    maxWidth: 110,
                    editable: true,
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorParams: {
                        values: ['ACTIVE', 'DISABLED'],
                        cellHeight: 30,
                      },
                    cellStyle: params => {
                        if ($scope.isEditButtonOn) {
                            return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                        }
                        return { 'text-align': 'right' }
                    },
                    editable: params => $scope.isEditButtonOn,
                    singleClickEdit: true,
                    valueFormatter: (params) => { 
                        if (params.value === "INACTIVE") {
                            return "NEW"
                        } else {
                            return params.value
                        }
                    }
                },
                {
                    headerName: "Pricing",
                    field: "price",
                    flex: 1,
                    cellRenderer: AmountCellRenderer,
                    maxWidth: 130,
                    cellStyle: params => {
                        if ($scope.isEditButtonOn && params.data.status === "ACTIVE" || $scope.isAddButtonOn) {
                            return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                        }
                        return { 'text-align': 'right' }
                    },
                    editable: params => params.data.status === "ACTIVE" && $scope.isEditButtonOn || $scope.isAddButtonOn,
                    singleClickEdit: true

                },
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                suppressCsvExport: true,
                suppressExcelExport: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                isExternalFilterPresent: isExternalFilterPresent,
                doesExternalFilterPass: doesExternalFilterPass,
                getRowStyle: function (params) {
                    if(params.data.modified) {
                        return {color: 'red', fontStyle: 'italic'};
                    }
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.vendorItemsList);
                },
                onCellValueChanged: function (params) {
                    if (params.column.colId === "price") {
                        $scope.validatePricing(params);
                    } else if (params.column.colId === "vendorItemName") {
                        $scope.validateVendorItemNaming(params);                        
                    } else {
                        params.data.modified = true;
                        $scope.refreshGrid();
                    }
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                },
                stopEditingWhenGridLosesFocus: true
                }; //End of gridOptions//
                
                
                function AmountCellRenderer() {
                }
                
                AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '-';
                    var iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            function isExternalFilterPresent() {
                return $scope.isAddButtonOn !== null;
              };
              
              function doesExternalFilterPass(node) {
                // hide inactive (unmapped) vendor items until Add Vendor Items button is clicked
                switch ($scope.isAddButtonOn) {
                  case true:
                    return node.data.status === "INACTIVE";
                  case false:
                    return node.data.status !== "INACTIVE";
                  default:
                    return node.data.status !== "INACTIVE";
                }
              };

              $scope.validatePricing = function(params) {

                let price = Number(params.value);
                  if(!price || typeof price !== "number") {
                    growl.error("Please enter a valid number!");
                    params.data.modified = params.data.modified ? true : false;
                    params.data.price = params.oldValue;
                } else {
                    params.data.modified = true;
                }
                $scope.refreshGrid();
              };

              $scope.validateVendorItemNaming = function(params) {

                let vendorItemName = params.value.trim();
                let vendorItems = $scope.vendorItemsList.filter(row =>  row.vendorItemName === params.value);

                if (!vendorItemName) {
                    growl.error("Please enter a valid vendor product name!");
                    params.data.modified = params.data.modified ? true : false;
                    params.data.vendorItemName = params.oldValue;
                } else if (vendorItems.length>1) {
                    growl.error("Vendor Product Name already exists!");
                    params.data.modified = params.data.modified ? true : false;
                    params.data.vendorItemName = params.oldValue;
                } else {
                    params.data.modified = true;
                }

                $scope.refreshGrid();
              };

              $scope.startEditing = function() {
                $scope.isEditButtonOn = true;
                $scope.refreshGrid();
              };

              $scope.onClose = function () {
                  if($scope.gridOptions.api.getEditingCells().length !== 0) {
                      growl.error("Cell not saved")
                  }
                $scope.isEditButtonOn = false;
                $scope.refreshGrid();
                $scope.submitItems();
            };

            $scope.submitItems = () => {
                // set focus on the first grid cell
                const firstCol = $scope.gridOptions.columnApi.getAllDisplayedColumns()[0];
                $scope.gridOptions.api.setFocusedCell(0, firstCol);

                const promises = [];
                const rowData = $scope.getRowData();
                rowData.forEach(row => {
    
                    if(row.modified) {
                        $scope.data = {
                            "vendorId": vendorId,
                            "vendorItemName": row.vendorItemName, 
                            "vendorItemsId": row.vendorItemsId,
                            "vendorPrice": row.price,
                            "status": row.status,
                            "uom": row.uom
                        };
    
                        promises.push($scope.updateProductDetailsForAVendor($scope.data));
                    }
 
                });
                Promise.all(promises).then((values)=> {
                $state.go($state.current, {}, { reload: true });
                $scope.loadData();
                })
                .catch((errs => {
                    $state.go($state.current, {}, { reload: true });
                    $scope.loadData();
                }));
            };
              
        })();


        $scope.addProducts = function () {

            $scope.isAddButtonOn = true;
            $scope.gridOptions.api.onFilterChanged();
        };

        $scope.saveProducts = function () {
            // set focus on the first grid cell
           const firstCol = $scope.gridOptions.columnApi.getAllDisplayedColumns()[0];        
           $scope.gridOptions.api.setFocusedCell(0, firstCol);

           $scope.isAddButtonOn = false;
           const rowData = $scope.getRowData();
           const promises = [];

           rowData.forEach(row => {

               if(row.modified) {
                   if(!row.price || typeof Number(row.price) !== "number") {
                       growl.error("Invalid Vendor Pricing!");
                       return;
                   }
                   $scope.data = {
                       "vendorId": vendorId, 
                       "vendorItemsId": row.vendorItemsId,
                       "vendorPrice": row.price,
                   };

                  promises.push(($scope.addProductsForAVendor($scope.data)));
               }

           })
           // $scope.gridOptions.api.onFilterChanged();
           Promise.all(promises).then((values)=> {
            $state.go($state.current, {}, { reload: true });
            $scope.loadData();           
           })

        };
        
        $scope.getRowData = () => {
            var rowData = [];
            $scope.gridOptions.api.forEachNode(function (node) {
                rowData.push(node.data);
            });
            return rowData;
        };

        $scope.refreshGrid = () => {
            let rowData = $scope.getRowData();
            $scope.gridOptions.api.setRowData(rowData);
        };
    
        $scope.updateProductDetailsForAVendor = function (data) {
            return new Promise((resolve,reject) => {
            vendorService.updateDetailsForVendorItemsModal(data).then(function (res) {
                resolve(res);
                if (res.status === "SUCCESS") {
                    growl.success("Successfully Updated Products!")
                } else if(res.status === "FAILURE") {
                    growl.error(res.error.message ? res.error.message : "Cannot update product")
                }
            });
        });
        };

        $scope.addProductsForAVendor = function (data) {
            return new Promise((resolve,reject) => {
            vendorService.addVendorPricing(data).then(function(res) {
                resolve(res);
                if (res.status === "SUCCESS") {
                    growl.success("Successfully Added Products!")           
                } else if(res.status === "FAILURE") {
                    growl.error(res.error.message ? res.error.message : "Cannot Add Products")
                }
            });

        });
        };
        
        (function () {

            $scope.loadData = function() {

                vendorService.retrieveItemsForVendor(vendorId).then(function (res) {
                if (res.status === "SUCCESS") {

                    $scope.vendorItemsList = res.data.vendorModalItemsList;

                    $scope.vendorName = res.data.vendorName;
                    $scope.totalCategories = res.data.productCategoriesCount;
                    $scope.totalActiveCategories = res.data.activeProductCategoriesCount;
                    $scope.totalProducts = res.data.productsCount;
                    $scope.totalActiveProducts = res.data.activeProductsCount;
                    $scope.totalVendorProducts = res.data.vendorProductsCount;
                    $scope.totalActiveVendorProducts = res.data.activeVendorProductsCount;

                    $scope.categoriesBadge = $scope.totalActiveCategories+ " | " + $scope.totalCategories;
                    $scope.productsBadge = $scope.totalActiveProducts + " | " + $scope.totalProducts;
                    $scope.vendorProductsBadge = $scope.totalActiveVendorProducts + " | " + $scope.totalVendorProducts;

                    if ($scope.vendorItemsList.length > 0) {
                         $scope.gridOptions.api.setRowData($scope.vendorItemsList)
                    }
                }
                $scope.refreshGrid();
            })
        }
        $scope.loadData();

        })();

        /*=========================================Helper functions======================================================*/
        /**
         * when X icon or close button is clicked
         */
        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });