(function(angular) {
'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:ScrapPhotosCtrl
 * @description
 * # ScrapPhotosCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('ScrapPhotosCtrl', function($scope, growl, $timeout, $filter, $window, $http, $moment, warehouseService, agentService, $uibModal, $interval, $global, dashboard, SellNow, $log, $stateParams, agentAppointments, DTOptionsBuilder, DTColumnDefBuilder, $rootScope) {
        
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        $scope.date = {
            startDate: $moment().format('DD-MMM-YYYY'),
            endDate: $moment().format('DD-MMM-YYYY')
        };
        /*$('.grid').masonry({
            columnWidth: 160,
            itemSelector: '.grid-item'
        });*/

        var $grid = $('.grid').masonry({
            columnWidth: 160,
            itemSelector: '.grid-item'
        });

        $('.append-button').on( 'click', function() {
            var elems = [ getItemElement(), getItemElement(), getItemElement() ];
            // make jQuery object
            var $elems = $( elems );
            $grid.append( $elems ).masonry( 'appended', $elems );
        });

        // create <div class="grid-item"></div>
        function getItemElement() {
            var elem = document.createElement('div');
            var wRand = Math.random();
            var hRand = Math.random();
            var widthClass = wRand > 0.8 ? 'grid-item--width3' : wRand > 0.6 ? 'grid-item--width2' : '';
            var heightClass = hRand > 0.85 ? 'grid-item--height4' : hRand > 0.6 ? 'grid-item--height3' : hRand > 0.35 ? 'grid-item--height2' : '';
            elem.className = 'grid-item ' + widthClass + ' ' + heightClass;
            return elem;
        }


        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.setStartDate = function() {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function() {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.vm = {
            params: {"city": $rootScope.loginData.city},
            hidecompleted: true
        };

        $scope.currentPage = 1;
        $scope.transactionChange = function() {
            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            $scope.vm.params.page = -1;
            $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');

            if ($scope.vm.params.todate == $scope.vm.params.fromdate) {
                $scope.vm.params.singledayquery = true;
            }

            if ($scope.searchText != null) {
                $scope.selectedWarehouseId = "";
                $scope.getPhotos($scope.searchText.userId);
            }else if($scope.selectedWarehouseId != null){
                $scope.searchText = "";
                $scope.getPhotos(null,$scope.selectedWarehouseId);
            }else if ($scope.searchText == null && $scope.selectedWarehouseId == null) {
                $scope.getPhotos();
                console.log("getPhotos")
            }
        };

        $scope.length = 1000;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDisplayLength($scope.length)
                    .withOption('order', [1, 'desc']);


        //Watch for date changes
        $scope.$watch('date', function(newDate) {

            $scope.transactionChange();

        }, false);

        var searchTextWatch = $scope.$watch('searchText', function(searchText) {
            if (searchText != null) { 
                console.log(searchText.userId);
                if (searchText.userId) {
                    if (searchText.userId.length == '58731278769d7d057e6c8386'.length) {
                        $scope.selectedWarehouseId = "";
                        $scope.getPhotos(searchText.userId);
                    } 
                }
            }

        }, false);

        console.log($scope);

        $scope.$on('$destroy',function(){
            console.log("Route changed, cancelling setInterval")
            console.log($scope.searchText);
            searchTextWatch();
            console.log("watch stopped")
            console.log($scope.searchText)
            
        });

        $scope.nextDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.endDate).add(1, "days"),
                endDate: moment($scope.date.endDate).add(1, "days")
            };
        }
        $scope.previousDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.startDate).subtract(1, "days"),
                endDate: moment($scope.date.startDate).subtract(1, "days")
            };
        }

        // $scope.items = [];

        // var counter = 0;
        // $scope.loadMore = function() {
        //     for (var i = 0; i < 5; i++) {
        //         $scope.items.push({
        //             id: counter
        //         });
        //         counter += 10;
        //     }
        // };

        // $scope.loadMore();

        $scope.photosExist = true;

        $scope.getPhotos = function(agentId,warehouseId){
            console.log("AGENT",agentId)
            if (agentId != null) {
                var params = {
                    "agentId"  :agentId,
                    "fromdate" :$scope.vm.params.fromdate,
                    "todate":$scope.vm.params.todate,
                    "page":0,
                    "status":"completed"
                }
            }else if(warehouseId != null){
                var params = {
                    "warehouseId"  :warehouseId,
                    "fromdate" :$scope.vm.params.fromdate,
                    "todate":$scope.vm.params.todate,
                    "page":0,
                    "status":"completed"
                }
            }else if (agentId== null && warehouseId == null) {
                var params = {
                    "fromdate" :$scope.vm.params.fromdate,
                    "todate":$scope.vm.params.todate,
                    "page":0,
                    "status":"completed"
                }
            }
            SellNow.getSells(params).then(function(res){
                if (res.status === 'SUCCESS') {
                    if (res.data.sells.length > 0) {
                        $scope.photosExist = true;
						let photoArray = [];
						for (let sellIndex in res.data.sells) {
							let photoObject = {};
							
							//scrapPhotograph and imageType
							if (res.data.sells[sellIndex] && res.data.sells[sellIndex].scrapPhotograph && res.data.sells[sellIndex].scrapPhotograph !== '' && res.data.sells[sellIndex].scrapPhotograph.includes('https://')) {
								photoObject["scrapPhotograph"] = res.data.sells[sellIndex].scrapPhotograph.replace('https://', '');
								photoObject["imageType"] = "URL";
							} else if (res.data.sells[sellIndex] && res.data.sells[sellIndex].scrapPhotograph && res.data.sells[sellIndex].scrapPhotograph !== '' && res.data.sells[sellIndex].scrapPhotograph.includes('base64')) {
								photoObject["scrapPhotograph"] = res.data.sells[sellIndex].scrapPhotograph;
								photoObject["imageType"] = "base64";
							} else {
							    continue;
                            }
		
							//confirmation id
							if (res.data.sells[sellIndex] && res.data.sells[sellIndex].confirmationId && res.data.sells[sellIndex].confirmationId !== '') {
								photoObject["confirmationId"] = res.data.sells[sellIndex].confirmationId;
							}
		
							//agentName
							if (res.data.sells[sellIndex] && res.data.sells[sellIndex].agentName && res.data.sells[sellIndex].agentName !== '') {
								photoObject["agentName"] = res.data.sells[sellIndex].agentName;
							}
							
							//final check before pushing into the array
							if(photoObject["scrapPhotograph"] && photoObject["imageType"] && photoObject["confirmationId"] && photoObject["agentName"]){
								photoArray.push(photoObject);
							}
						}
						console.log("===========================================================================================================");
	                    console.log("photoArray = ", photoArray);
						$scope.scrap_list = photoArray;
	
						$scope.open = false;
                        $('.grid').masonry({
                            columnWidth: 160,
                            itemSelector: '.grid-item'
                        });
                    }else{
                        $scope.photosExist = false;
                    }
                }
            });
        }
       
        $scope.OpenRequest = function(pickup) {
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/consumer-pickups-modal.html',
                controller: 'ConsumerPickupsModalCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    items: function() {
                        return pickup;
                    }
                }
            });

            pickupInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
            });
        }

        warehouseService.warehousesLoad("WAREHOUSE").then(function(res){
            if (res.status == 'SUCCESS') {
                if(res.data.warehouses){
                    $scope.warehouse_list = res.data.warehouses;
                }
            }
        });

        $scope.selectWarehouseAgent = function(agentId){
            
            if(agentId != null){
                $scope.selectedWarehouseId = "";
                $scope.getPhotos(agentId);
            }else if ($scope.selectedWarehouseId) {
                $scope.searchText="";
                $scope.getPhotos(null,$scope.selectedWarehouseId);
            }   
        }

        $scope.dropDownEnable = false;

       $scope.getLocation = function(val) {
          console.log(val)
          return [{"address": "abc"}, {"address": "def"}]
        };
        $scope.searchAgent = function(key) {
            console.log("KEY:",key)
            key = key.trim();
            return agentService.getAgent("AGENT", key).then(function(response) {
                console.log(response.data.users)
                return response.data.users;
            });;
            // if(key){
            //     if (key.length > 1) {
            //         var type = 'AGENT';
            //         $scope.noAgentFound = false;
            //         $scope.agentlistloading = true;
            //         agentService.getAgent(type, key).then(function(res) {
            //             $scope.agentlistloading = false;
            //             if (res.status == "SUCCESS") { 
            //                 console.log(res.data.users)
            //                 return res.data.users;
            //             } else if (res.status == "FAILURE") {
            //                 $scope.noAgentFound = true;
            //             } else {
            //                 $scope.noAgentFound = true;
            //             }

            //         }, function(err) {
            //         })
            //     }
            // }else{
            //     $scope.errorDiable = true;
            // }
        };

        // $scope.selectAgent = function(name){
        //     $timeout(function() {
        //         console.log(":::::::::::",$scope.searchText,"###########",name)
        //     }, 1000);
        // }
    });

// angular.module('scrapQcrmApp').directive('whenScrolled', function() {
//     return function(scope, elm, attr) {
//         var raw = elm[0];
        
//         elm.bind('scroll', function() {
//             if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
//                 scope.$apply(attr.whenScrolled);
//             }
//         });
//     };
// });
})(window.angular);

