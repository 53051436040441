'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:SellConsumerCtrl
 * @description
 * # SellConsumerCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('ApartmentAppointmentCtrl', function ($scope, apartments, products, platformSetting, consumer, $filter, params, $uibModalInstance, $uibModal, $moment, $timeout, SellNow, $global, growl, $rootScope, NgMap) {
        $scope.frequencyList = [{ name: "One time", value: "ONETIME" }, { name: "Daily", value: "DAILY" }, { name: "Weekly", value: "WEEKLY" }, { name: "Fortnightly", value: "FORTNIGHTLY" }, { name: "Monthly", value: "MONTHLY" }];
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        console.log(params);
        $scope.params = params;
        $scope.communityId = params.apartmentId;

        $scope.loadCustomersOptions = [
            "YES",
            "NO",
        ]

        $scope.data = {
            "apartmentId": params.apartmentId,
            "address": params.address,
            "items": [],
            "city": $rootScope.loginData.city,
            "loadAllCustomers": "YES"
        };
        var disabledDates = [];
        $scope.popup1 = {};
        $scope.datepickerObject = {};
        $scope.datepickerObjectPopup = {}
        $scope.isOpen = false;
        $scope.chosenPlace = {};
        $scope.showSellRequestSection = true;
        $scope.showConfirmSection = false;
        $scope.data = {};
        $scope.blocks = [];
        $scope.data.blockId = [];

        // Scale marker icon for map
        $scope.icon = {
            url: "./images/consumer.svg",
            scaledSize: [55, 55]
        }

        if (params.communityAppointmentId) {
            $scope.consumerTitle = "APARTMENT APPOINTMENT DETAILS";
            apartments.retrieveAppointmentById(params.communityAppointmentId).then(function (res) {
                if (res.status == "SUCCESS") {
                    if (res.data && res.data.communityAppointment) {
                        $scope.appointmentDetails = res.data.communityAppointment;
                        $scope.preferredStartTime = res.data.communityAppointment.preferredStartTime;
                        $scope.preferredEndTime = res.data.communityAppointment.preferredEndTime;
                        $scope.data.frequencyName = $scope.frequencyList.filter(freq => freq.value === res.data.communityAppointment.frequency)[0].name;
                        $scope.blocks = [];
                        res.data.communityAppointment.blocks.forEach(block => {
                            $scope.blocks.push(block.blockName);
                        });
                        $scope.datepickerObjectPopup.inputDate = new Date($scope.appointmentDetails.preferredDate);
                    }
                }
            })
        } else {
            $scope.consumerTitle = "Book Group Appointment";
        }

        $scope.getBlocks = function (communityId) {
            consumer.getBlocks(communityId).then(function (res) {
                if (res.status === "SUCCESS") {
                    $scope.blockList = res.data.blocks;
                    $scope.blockNames = []

                    $scope.blockList.forEach(function (elem, indx) {
                        $scope.blockNames.push(elem.blockName);
                    });
                    console.log($scope.blockNames)
                }
            }, function (err) {
            })
        }
        consumer.getProfile(params.apartmentId).then(function (res) {
            if (res.status === "SUCCESS") {
                $scope.community = res.data.consumer;
                $scope.center = res.data.consumer.address.latitude + "," + res.data.consumer.address.longitude;
                $scope.getBlocks($scope.communityId);
            } else if (res.status === "FAILURE") {
                $scope.noConsumerFound = "No consumer record found";
            }
        }, function (err) {
            //console.log(err);
        })

        console.log($scope.blocks)

        $scope.status = {
            opened: false
        };
        var maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() + 1);
        $scope.dateOptions = {
            maxDate: maxDate,
            minDate: new Date(),
            startingDay: 1
        };
        $scope.dateOptionsPick = {
            maxDate: maxDate,
            minDate: new Date().setHours(18),
            startingDay: 1
        };
        $scope.open = function ($event) {
            $scope.status.opened = true;
        };
        $scope.today = function () {
            $scope.dt = new Date();
        };



        setTimeout(function () {
            let ele = document.getElementsByClassName("multiSelect");
            if (ele)
                ele[0].style.width = "100%"
        }, 1500)



        $scope.today();


        $scope.sellNow = function () {
            console.log($scope.blocks)
            $scope.error = false;
            if ($scope.blocks != null && $scope.blocks.length > 0) {
                $scope.blocks.forEach(function (element, index) {
                    $scope.blockList.forEach(function (elem, indx) {
                        if (element == elem.blockName) {
                            $scope.data.blockId.push(elem.blockId);
                        }
                    })
                })
            }
            if (params.communityAppointmentId) {
                if ($moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY') != $scope.appointmentDetails.preferredDate) {
                    $scope.data.preferredDate = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                }
                if ($scope.preferredStartTime) {
                    $scope.data.preferredStartTime = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                    $scope.data.preferredStartTime = String($scope.data.preferredStartTime) + " " + String($scope.preferredStartTime)
                }
                if ($scope.preferredEndTime) {
                    $scope.data.preferredEndTime = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                    $scope.data.preferredEndTime = String($scope.data.preferredEndTime) + " " + String($scope.preferredEndTime);
                }
                $scope.data.communityAppointmentId = params.communityAppointmentId;
                if (!$scope.preferredStartTime && $scope.preferredEndTime) {
                    $scope.error = true;
                    $scope.showConfirmSection = false;
                    $scope.showSellRequestSection = true;
                    growl.error("Select Start Time for Appointment")
                }
                else if ($scope.preferredStartTime && !$scope.preferredEndTime) {
                    $scope.error = true;
                    $scope.showConfirmSection = false;
                    $scope.showSellRequestSection = true;
                    growl.error("Select End Time for Appointment")
                }
                if ($scope.data.preferredStartTime && $scope.data.preferredEndTime) {
                    if (Date.parse($scope.data.preferredEndTime) < Date.parse($scope.data.preferredStartTime)) {
                        $scope.error = true;
                        $scope.showConfirmSection = false;
                        $scope.showSellRequestSection = true;
                        growl.error("End Time cannot be less than Start Time");
                    }
                }
                if (!$scope.error) {
                    $scope.data.city = $rootScope.loginData.city;
                    $scope.data.frequency = $scope.frequencyList.filter(freq => freq.name === $scope.data.frequencyName)[0].value;
                    $scope.data["agentId"] = $scope.params.appointmentData.agentObjId;
                    $scope.data["agentAddressId"] = $scope.params.appointmentData.agentAddress.addressId;
                    console.log($scope.data)


                    apartments.updateAppointment($scope.params.appointmentData.communityAppointmentId, $scope.data).then(function (res) {
                        if (res.status == "SUCCESS") {
                            growl.success(res.data.message);
                            $scope.consumerTitle = "Confirm";
                            apartments.retrieveAppointmentById($scope.params.appointmentData.communityAppointmentId).then(function (res) {
                                if (res.status == "SUCCESS") {
                                    $scope.items = res.data.communityAppointment;
                                    $scope.showSellRequestSection = false;
                                    $scope.showConfirmSection = true;
                                    $uibModalInstance.dismiss();
                                }
                            });

                        } else if (res.status == "FAILURE") {
                            growl.error(res.error.message);
                            $uibModalInstance.dismiss();
                        }
                    })
                }

            } else {
                var message = "Are you sure you dont want to create appointments for all customers in group?";
                if ($scope.data.loadAllCustomers && $scope.data.loadAllCustomers === "YES") {
                    var message = "Are you sure you want to create appointments for all customers in group?";
                }
                $global.confirm(message).then(accept => {
                    var today = $moment().format('DD-MMM-YYYY')
                    var current = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY')

                    $scope.data.sellSubType = 'APPOINTMENT';

                    if (!$scope.preferredStartTime) {
                        $scope.error = true;
                        $scope.showConfirmSection = false;
                        $scope.showSellRequestSection = true;
                        growl.error("Select Start Time for Appointment")
                    }
                    if (!$scope.preferredEndTime) {
                        $scope.error = true;
                        $scope.showConfirmSection = false;
                        $scope.showSellRequestSection = true;
                        growl.error("Select End Time for Appointment")
                    }

                    $scope.data.preferredDate = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                    $scope.data.preferredStartTime = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                    $scope.data.preferredStartTime = String($scope.data.preferredStartTime) + " " + String($scope.preferredStartTime)
                    $scope.data.preferredEndTime = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
                    $scope.data.preferredEndTime = String($scope.data.preferredEndTime) + " " + String($scope.preferredEndTime);
                    $scope.data.communityId = $scope.communityId;

                    $scope.data.frequency = $scope.frequencyList.filter(freq => freq.name === $scope.data.frequencyName)[0].value;
                    if ($scope.data.frequency == "DAILY") {
                        $scope.data.count = $scope.data.count ? $scope.data.count : 7;
                    }
                    
                    if ($scope.data.preferredStartTime && $scope.data.preferredEndTime) {
                        if (Date.parse($scope.data.preferredEndTime) < Date.parse($scope.data.preferredStartTime)) {
                            $scope.error = true;
                            $scope.showConfirmSection = false;
                            $scope.showSellRequestSection = true;
                            growl.error("End Time cannot be less than Start Time");
                        }
                    }

                    if (!$scope.data.loadAllCustomers) {
                        $scope.data.loadAllCustomers = "YES";
                    }

                    if (!$scope.error) {
                        apartments.createCommunityAppointment($scope.data).then(function (res) {
                            if (res.status == "SUCCESS") {
                                growl.success(res.data.message);
                                $scope.consumerTitle = "Confirm";
                                apartments.retrieveAppointmentById(res.data.communityAppointment.communityAppointmentId).then(function (res) {
                                    if (res.status == "SUCCESS") {
                                        $scope.items = res.data.appointment;
                                        $scope.showSellRequestSection = false;
                                        $scope.showConfirmSection = true;
                                        $uibModalInstance.dismiss();
                                    }
                                });
                            } else if (res.status == "FAILURE") {
                                growl.error(res.error.message);
                                $scope.showSellRequestSection = true;
                                $scope.showConfirmSection = false;
                                $uibModalInstance.dismiss();
                            }
                        });
                        $scope.ok();
                    }
                }, reject => {
                    $scope.ok();
                }).catch(err => {
                    $scope.ok();
                });
            }

        }
        $scope.ok = function () {
            $uibModalInstance.dismiss('ok');
        };
        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

    })
