'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:skuPricingCtrl
 * @description
 * # skuPricingCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('skuPricingCtrl', function ($scope, $filter, products, ngTableParams, $state, $stateParams, $global, $uibModal, consumer, envService, editableOptions, growl, pricingService) {

    $scope.addresstypes = [];
    $scope.isEditButtonOn = false;
    $scope.inrFilter = $filter('INR');

    (function () {
      $scope.columnDefs = [
        {
          headerName: "Category",
          field: "productCategoryName",
          flex: 2,
          pinned: 'left',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: "Product",
          field: "categoryName",
          flex: 2,
          pinned: 'left',          
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: "UOM",
          field: "uom",
          flex: 2,
          maxWidth: 90,
          pinned: 'left',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: "GST",
          field: "gst",
          flex: 2,
          maxWidth: 110,
          cellStyle: params => {
            if ($scope.isEditButtonOn) {
              return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
            }
            return { 'text-align': 'right' }
          },
          editable: params => $scope.isEditButtonOn,
          cellRenderer: $scope.AmountCellRenderer,
          singleClickEdit: true
        }
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: $scope.columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true,
          columnsMenuParams: {
            suppressColumnFilter: true,
            contractColumnSelection: true,
          }
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: rowData,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.data.modified) {
            return { color: 'red', fontStyle: 'italic' };
          }
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          $scope.gridOptions.api.setRowData($scope.dataset);
        },
        onCellValueChanged: function (params) {
          if (params.column.colId === "gst") {
            $scope.validateGST(params);
            return;
          }
          $scope.validatePricing(params);
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        },
        stopEditingWhenGridLosesFocus: true
      }; //End of gridOptions//

      $scope.AmountCellRenderer = function () {
      }

      $scope.AmountCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          let val;
          if (params.value == 0) {
            val = 0;
          } else {
            val = params.value ? $scope.inrFilter(params.value) : "";
          }
          let iconsDiv = "<div> " + val + "</div>";
          this.eGui.innerHTML = iconsDiv;
        } //inr symbol - &#x20b9;
      };

      $scope.AmountCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };

      $scope.onClose = function () {
        if ($scope.gridOptions.api.getEditingCells().length !== 0) {
          growl.error("Cell not saved")
        }
        $scope.isEditButtonOn = false;
        $scope.refreshGrid();
      };

      $scope.cancel = function () {
        $state.go($state.current, {}, { reload: true });
      };

      $scope.getRowData = () => {
        var rowData = [];
        $scope.gridOptions.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        return rowData;
      };

      $scope.refreshGrid = () => {
        let rowData = $scope.getRowData();
        if ($scope.gridOptions && $scope.gridOptions.api) {
          $scope.gridOptions.api.setRowData(rowData);
        };
      }

      $scope.validatePricing = function (params) {

        let price = Number(params.value);
        if (price !== 0 && !price || typeof price !== "number") {
          growl.error("Please enter a valid number!");
          params.data.modified = params.data.modified ? true : false;
          params.data[`${params.column.colId}`] = params.oldValue;
        } else {
          params.data.modified = true;
          params.data[`${params.column.colId}Modified`] = true;
        }
        let row = $scope.gridOptions.api.getDisplayedRowAtIndex(params.rowIndex);
        $scope.gridOptions.api.redrawRows({ rowNodes: [row] });
      };

      $scope.validateGST = function (params) {

        let price = Number(params.value);
        if (price !== 0 && !price || typeof price !== "number") {
          growl.error("Please enter a valid number!");
          params.data.modified = params.data.modified ? true : false;
          params.data[`${params.column.colId}`] = params.oldValue;
        } else {
          params.data.modified = true;
          $scope.consumercategoriesList.forEach(consumerCategory => {
            if (params.data[`${consumerCategory.name}Id`]) {
              params.data[`${consumerCategory.name}Modified`] = true;
            }
          })
        }
        let row = $scope.gridOptions.api.getDisplayedRowAtIndex(params.rowIndex);
        $scope.gridOptions.api.redrawRows({ rowNodes: [row] });
      };
    })();

    // get consumer user categories
    (function () {
      consumer.userCategories().then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.consumercategoriesList = res.data.categories;
          $scope.consumercategoriesList.forEach(elem => {
            $scope.addresstypes.push(elem.name);
            let col = {
              headerName: elem.name,
              field: elem.name,
              flex: 1,
              maxWidth: 110,
              cellRenderer: $scope.AmountCellRenderer,
              cellStyle: params => {
                if ($scope.isEditButtonOn) {
                  return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
                }
                return { 'text-align': 'right' }
              },
              editable: params => $scope.isEditButtonOn,
              singleClickEdit: true
            }
            $scope.columnDefs.push(col);
          });
          $scope.gridOptions.api.setColumnDefs($scope.columnDefs);

        } else if (res.status === "FAILURE") {
          // growl.error(res.error.message)
        }
      });
    })();

    // product pricing
    (function () {
      products.getProducts()
        .then(function (res) {
          if (res.data.categories) {
            $scope.data = res.data.categories; // arrays of information about category products
            const dataset = [];
            $scope.categories = [];
            res.data.categories.forEach(function (catdata, idx1) {

              if (!$scope.categories.includes(catdata.name)) {

                let obj = {};
                obj.productCategoryName = catdata.code;
                obj.categoryName = catdata.name;
                obj.uom = catdata.uom;
                obj.image = catdata.image;
                obj.description = catdata.description;
                obj.city = catdata.city;
                obj.gst = catdata.gstPercentage;
                obj.weightedPriceAverage = catdata.weightedPriceAverage;
                obj[catdata.consumercategoryname] = catdata.price;
                obj[`${catdata.consumercategoryname}Id`] = catdata.categoryId;

                dataset.push(obj);
                $scope.categories.push(catdata.name);

              } else {
                dataset.forEach(data => {
                  if (data.categoryName === catdata.name) {
                    data[catdata.consumercategoryname] = catdata.price;
                    data[`${catdata.consumercategoryname}Id`] = catdata.categoryId;
                  }
                })
              }

            });
            $scope.dataset = dataset;
            if ($scope.gridOptions && $scope.gridOptions.api) {
              $scope.gridOptions.api.setRowData(dataset);
              $scope.gridOptions.columnApi.moveColumnByIndex(3, $scope.columnDefs.length-1);
            }
          }
        })
        .catch(function (err) {
          console.error('Error in products.getProducts() API call ', err);
        })
    })();

    $scope.editPricing = function () {

      $scope.isEditButtonOn = true;
      $scope.refreshGrid();

    }

    // save product pricing
    $scope.savePricing = function () {

      // set focus on the first grid cell
      const firstCol = $scope.gridOptions.columnApi.getAllDisplayedColumns()[0];
      $scope.gridOptions.api.setFocusedCell(0, firstCol);

      let rowData = $scope.getRowData();
      rowData = rowData.filter(row => row.modified);
      console.log('Data to be submitted is: ', rowData);

      rowData.forEach(row => {

        $scope.consumercategoriesList.forEach(consumerCategory => {

          // Segregate modified consumer categories
          if (row[`${consumerCategory.name}Modified`]) {

            // Segregate Add/Edit
            if (row[`${consumerCategory.name}Id`]) {

              let categoryId = row[`${consumerCategory.name}Id`];
              let price = row[consumerCategory.name];
              let gstPercentage = row.gst;

              $scope.priceUpdate(categoryId, price, gstPercentage);

            } else {

              let addProductData = {};
              addProductData.code = row.productCategoryName;
              addProductData.name = row.categoryName;
              addProductData.image = row.image;
              addProductData.price = row[consumerCategory.name];
              addProductData.uom = row.uom;
              addProductData.description = row.description;
              addProductData.city = row.city;
              addProductData.gstPercentage = row.gst;
              addProductData.consumercategoryid = consumerCategory.consumerCategoryId;

              $scope.priceAdd(addProductData);

            }

          }
        });
      });

      $state.go($state.current, {}, { reload: true });

    };

    // product pricing update API call
    $scope.priceUpdate = function (categoryId, price, gstPercentage) {
      const pricedata = {
        "categoryId": categoryId,
        "price": price,
        "gstPercentage": gstPercentage
      };
      products.priceUpdate(pricedata)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.status = true;
            growl.success("Price updated successfully")
          } else if (res.status === "FAILURE") {
            growl.error("Failure");
            return false;
          }
        })
        .catch(function (err) {
          console.error('Error in price update API call ', err);
          return false;
        })
    };

    $scope.priceAdd = function (addProductData) {
      products.addProduct(addProductData).then(function (res) {
        if (res.status === "SUCCESS") {
          growl.success("Price updated successfully");
          counter += 1;
        } else if (res.status === "FAILURE") {
          growl.error(res.error.message);
        }
      })
        .catch(function (err) {
          console.error('Error in price add API call ', err);
          return false;
        })
    }

  });