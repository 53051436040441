'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:PaymentsModalCtrl
 * @description
 * # PaymentsModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('PaymentModalCtrl', function ($scope, $rootScope, $global, items, $uibModalInstance, platformSetting, growl, $filter, consumer, $state, SellNow, NgMap, $moment) {

    $scope.cancel = function (res) {
      if ($scope.stopEvents) {
        return;
      }
      $uibModalInstance.dismiss(res);
    };

    $scope.otp = "";

    $scope.transferModes = [
      "IMPS",
      "NEFT"
    ];

    $scope.verificationTypes = [
      "PASSWORD",
      "OTP"
    ];

    console.log('itms: ', items);
    $scope.items = items;
    function getConsumerProfile(consumerId) {
      return new Promise((resolve, reject) => {
        consumer.getProfile(consumerId).then(function (res) {
          resolve(res.status);
          if (res.status === "SUCCESS") {
            $scope.profileEdit = res.data.consumer;
            $scope.upiId = $scope.profileEdit.upiId;
            $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
            try {
              var addrsplit = $scope.profileEdit.address.formattedAddress.split(/\,+/);
              $scope.profileEdit['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
              var replaced = $scope.profileEdit.address.formattedAddress.replace(/\,+/g, "--").split("--");
              replaced.forEach(function (elem, idx) {
                if (idx % 2 === 0) {
                  replaced[idx] = replaced[idx] + "<br>";
                }
                replaced[idx] = replaced[idx].trim();
              });
              $scope.profileEdit.address.formattedAddress = replaced.join().replace(/\,/g, " ");
            }
            catch (e) {

            }
          } else if (res.status === "FAILURE") {
            $scope.noConsumerFound = "No consumer record found";
          }

        }, function (err) {
          //console.log(err);
        })
      })
    }
    getConsumerProfile(items.consumerObjId)


    $scope.requestPayment = function () {
      $scope.verifyOTP().then((val) => {
        if (val == "SUCCESS") {
          $scope.stopEvents = true;
          let data = {
            "sellId": items.sellObjId,
            "upiId": $scope.upiId,
            "bankAccountNumber": $scope.bankAccountNumber,
            "ifsc": $scope.ifsc,
            "transferMode": $scope.transferMode ? $scope.transferMode.toLowerCase() : null,
            "paymentMethod": $scope.paymentMethod,
          };
          $scope.intiateTransaction(data).then(transactionResponse => {

            if (transactionResponse.success) {
              if ($scope.paymentMethod.toUpperCase() == "CASH" || $scope.paymentMethod.toUpperCase() == "DONATION") {
                $scope.stopEvents = false;                
                growl.success("Payment done successfully");
                $scope.cancel();
                return;
              }
              let input = {
                sellId: items.sellObjId,
                transactionId: transactionResponse.data.transactionId
              };
              $scope.pollTransactionStatus(input);

            } else {
              $scope.stopEvents = false;
              growl.error("Unable to raise payment");
            }
          })
        }
      });

    }


    $scope.intiateTransaction = function (data) {
      return new Promise((resolve, reject) => {
        (SellNow.requestSellPayment(data)).then((response) => {
          if (response.status == "SUCCESS") {
            resolve({ success: true, data: response.data });
          } else {
            resolve({ success: false, data: response });
          }
        })
      })
    }

    $scope.sendOTP = function () {
      (SellNow.sendTransactionCode(items.sellObjId)).then((response) => {
        if (response.status == "SUCCESS") {
          $scope.transactionCodeSent = true;
          growl.success("Transaction code sent successfully");
        } else {
          growl.error("Transaction code is not sent");
        }
      })
    }

    $scope.verifyOTP = function () {
      return new Promise((resolve, reject) => {
        console.log('otp: ', $scope.otpCode);
        let data = {
          "sellId": items.sellObjId,
          "transactionCode": $scope.otpCode,
          "password": $scope.password
        }
        SellNow.verifyTransactionCode(data).then((response) => {
          resolve(response.status);
          if (response.status == "SUCCESS") {
            growl.success("Transaction code verified successfully");
          } else {
            growl.error("Unable to verify passcode");
          }
        })
      });
    }


    $scope.pollTransactionStatus = function (data) {
      SellNow.retrieveSellPayment(data).then((response) => {
        if (response.status == "SUCCESS" && response.data.transactionStatus == "SUCCESS") {
          growl.success("Payment done successfully")
          $scope.stopEvents = false;
          $scope.cancel();
        } else {
          $scope.stopEvents = false;
          console.log(response)

          if (response.data && response.data.transactionStatus == "FAILED") {

            if (response.data.error && response.data.error.message) {
              growl.error(response.data.error.message);
            } else {
              growl.error("Transaction failed, Please try again");
            }
            // this.hideLoader();
            return;
          }
          else if (response.data && (response.data.transactionStatus == "PENDING" || response.data.transactionStatus == "RECEIVED")) {
            $scope.pollTransactionStatus(data).then(val=>{return});
          } else {
            growl.error("Transaction failed, Please try again");
            $scope.stopEvents = false;
          }
        }
      });
    }

    platformSetting.getEnabledSettings("false").then(function (res) {
      if (res.status === "SUCCESS" && res.data && res.data.platformSettings && res.data.platformSettings.paymentSettings && res.data.platformSettings.paymentSettings.length > 0) {
        $scope.paymentSettingsOptions = res.data.platformSettings.paymentSettings;
        $scope.paymentSettingsArr = [];
        $scope.paymentSettingsOptions.forEach(function (element) {
          if (element.toggle && element.paymentType.toLowerCase().replaceAll(" ", "").trim() != "paylater") {
            $scope.paymentSettingsArr.push(element.paymentType);
          }
        })
        $scope.paymentModes = $scope.paymentSettingsArr;
      }
    });

  });