'use strict';

angular.module('scrapQcrmApp')
    .filter('prettyTime', () =>
  function(seconds) {
    let pretty;
    return pretty = (() => { switch (false) {
      case !(seconds >= 108000): return Math.round(seconds / 86400, 1) + ' days';
      case !(seconds >= 3600): return Math.round(seconds / 3600, 1) + ' hours';
      case !(seconds >= 60): return Math.round(seconds / 60) + ' minutes';
      default: return seconds + ' seconds';
    } })();
  }
);
