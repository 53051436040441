'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:PaymentsCtrl
 * @description
 * # PaymentsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('PaymentsCtrl', function($scope, $rootScope, $moment, $global, $uibModal, DTOptionsBuilder, $window, paymentService, growl, $log , $stateParams) {
    	$scope.pages = -1;
        $scope.vm = { params: {} };
        $scope.currentPage = 1;

        console.log($scope)

        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        $scope.date = {
            startDate: $moment().toDate(),
            endDate: $moment().toDate()
        };

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        // console.log($scope.opts)

        $scope.setStartDate = function() {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function() {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.length = 1000;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDisplayLength($scope.length)
                    .withOption('order', [1, 'desc']);


        //Watch for date changes
        $scope.$watch('date', function(newDate) {

            $scope.transactionChange();

        }, false);

        $scope.nextDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.endDate).add(1, "days"),
                endDate: moment($scope.date.endDate).add(1, "days")
            };
            $scope.transactionChange();
        }
        $scope.previousDate = function() {
            $scope.paging = false;
            $scope.date = {
                startDate: moment($scope.date.startDate).subtract(1, "days"),
                endDate: moment($scope.date.startDate).subtract(1, "days")
            };
            $scope.transactionChange();
        }

        $scope.currentPage = 1;
        $scope.transactionChange = function() {

            $scope.totalPaymentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;
            $scope.vm.params.endDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            $scope.vm.params.startDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');

            if ($scope.vm.params.startDate == $scope.vm.params.endDate) {
                $scope.vm.params.singledayquery = true;
            }
            // $scope.vm.params.status =  'ASSIGNED_TO_AGENT';// 'PENDING';
            // console.log($scope.vm.params.todate)
            $scope.loadPayments($scope.vm.params);
    
        };

    	$scope.loadPayments = function(params) {
            var type = 'PAYMETNS';
            $scope.submitted = true;
            $scope.paging = false;
            $scope.noPaymentFound = false;

            if (!params || !params.page) {
                params = {};
                params.page = 1;
            }

            paymentService.loadPayments(type, params.page, $moment($scope.date.startDate).format('DD-MMM-YYYY'), $moment($scope.date.endDate).format('DD-MMM-YYYY')).then(function(res) {
                if (res.status == "SUCCESS") {
                    $scope.payments = res.data;
                    $scope.totalPaymentRecords = res.data.totalRecords;
                    console.log($scope.payments)
                    // if(res.data.warehouses.length == 1){
                    //     $scope.length0 = true;
                    //     $scope.warehouseDetails(0);
                    // }else {
	                        $scope.payments_list = res.data.payment;
	                        $scope.length0 = false;
	                        $scope.merchantOrderId = false;
                    // }
                    if(res.data.message == "No records found"){
                        $scope.noPaymentFound = true;
                    }
                } else if (res.status == "FAILURE") {
                    $scope.noPaymentFound = true;
                } else {
                    $scope.noPaymentFound = true;
                }
            }, function(err) {

            })
        };


        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
            $scope.loadPayments($scope.vm.params);
        }


        $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDisplayLength($scope.length);

        $scope.pageChanged = function(currentPage) {
            //console.log(currentPage)
            $scope.vm.params.page = currentPage;
            $scope.loadPayments($scope.vm.params);
        }

        $scope.OpenRequest = function(payment) {
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/payments-modal.html',
                controller: 'PaymentsModalCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    payment: function() {

                        return payment;
                    }
                }
            });

            pickupInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                $scope.loadPayments($scope.vm.params);
            });
        }
    });
