'use strict';

angular.module('scrapQcrmApp').controller('BalanceInventoryCtrl', function ($state, $scope, $filter, $rootScope, $global, warehouseService, agentService, Crossfilter, $moment, growl, $uibModal, saleOrderService) {
  const data = {};
  
  /**
   * Initially on page load
   * 1. load all the wholesalers as default
   * 2. radio button will have wholesaler as default and it's associated placeholder will be assigned
   * 3. Date - start and end will be current date and 30 before the current date respectively
   */
  (function () {
    $scope.search = '';
    $scope.placeholderText = 'Please Enter Warehouse Name';
    $scope.userType = 'WHOLESALER';
  
    $scope.date = {
      startDate: $moment().subtract(7, 'days').toDate(),
      endDate: $moment(new Date(), 'DD-MMM-YYYY').toDate()
    };
    
    //default - assigning sort types
    $scope.sortType = "totalamount";
    $scope.sortValue = true;
    $scope.totalSort = true;
    
    // default pagination page value
    $scope.currentPage = 1;
    
    //default values and also working as a false condition
    $scope.totalTransactionsCount = 0;
    $scope.totalQuantitySum = 0;
  })();
  
  /**
   * Date range filter options
   */
  (function(){
    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };
  })();
  
  /**
   * Default service call
   */
  (function(){
    if ($rootScope.loginData.role === "WAREHOUSEUSER") {
      $scope.selectedWarehouseId = $rootScope.loginData.warehouseId;
      warehouseService.getProfile($rootScope.loginData.warehouseId)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            $scope.warehouseName = res.data.warehouse.warehouseName;
            let fromDate = $moment($scope.date.startDate).format("DD-MMM-YYYY");
            let endDate = $moment($scope.date.endDate).format("DD-MMM-YYYY");
            if ($scope.selectedWarehouseId && fromDate && endDate) {
              getBalanceInventoryForWarehouse(
                $scope.selectedWarehouseId, fromDate, endDate, $rootScope.loginData.city, $scope.currentPage
              );
            }
          } else {
            console.error("Failed to get warehouse ", res);
          }
        })
        .catch(function(err) {
          console.error("Failed to get warehouse ", err)
        })
    } else {
      let page = -1; //we need all the warehouses results instead of paginated results
      warehouseService.getWarehouse(page)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            $scope.$ngc = new Crossfilter(res.data.warehouses, 'warehouseName', 'persistent');
          } else {
            console.error("Error in getting all the warehouses list ", res);
          }
        })
        .catch(function(error) {
          console.error("Error in getting all the warehouses list ", error);
        })
    }
    
  })();
  
  /**
   * Executed firstly on page load, then either
   * 1. on selecting user object
   * OR
   * 2. changes in date
   * //TODO point to the balance inventory API
   * //TODO Update comments
   */
  const getBalanceInventoryForWarehouse = function(warehouseId, fromDate, toDate, city, page) {
    warehouseService.getBalanceInventoryForWarehouse(warehouseId, fromDate, toDate, city, page)
      .then(function (res) {
        if (res.status === "SUCCESS") {
          $global.displayLog('inside getBalanceInventoryForWarehouse() API response', 'res', res);
          $scope.balanceSheetInventories = res.data.balanceSheetInventories;
          $scope.noInventory = res.data.balanceSheetInventories.length === 0;
          $scope.totalBalanceSheetInventoriesRecords = res.data.totalRecords;
          $scope.totalTransactionsCount = res.data.totalTransactionsCount;
          $scope.totalQuantitySum = res.data.totalQuantitySum.toFixed(2);
        } else {
          $scope.noInventory = true;
          if(res && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl('Failed to get balance inventory sheet information ');
            console.log('Failed to get balance inventory sheet information ', res);
          }
        }
      })
      .catch(function(err){
        console.log('Failed to get balance inventory sheet information ', err);
      })
  };
  
  $scope.pageChanged = function(currentPage) {
    let fromDate = $moment($scope.date.startDate).format("DD-MMM-YYYY");
    let endDate = $moment($scope.date.endDate).format("DD-MMM-YYYY");
    $scope.currentPage = currentPage;
    if ($scope.selectedWarehouseId && fromDate && endDate) {
      getBalanceInventoryForWarehouse(
        $scope.selectedWarehouseId, fromDate, endDate, $rootScope.loginData.city, $scope.currentPage
      );
    }
  };
  
  /**
   * Clicking on any of the object in the search result, will execute the following the function
   * @param val - entire user object
   */
  $scope.selectUserType = function (val) {
    $global.displayLog('selected val in auto-dropdown ', 'val', val);
    $scope.selectedUserType = val.warehouseName;
    $scope.search = val.warehouseName;
    $scope.selectedWarehouseId = val.warehouseId;
    let fromDate = $moment($scope.date.startDate).format("DD-MMM-YYYY");
    let endDate = $moment($scope.date.endDate).format("DD-MMM-YYYY");
    getBalanceInventoryForWarehouse(
      $scope.selectedWarehouseId, fromDate, endDate, $rootScope.loginData.city, $scope.currentPage
    );
  };
  
  /**
   * Clicking on the cross or changing the radio buttons will execute the following function
   */
  $scope.clearSearch = function () {
    $scope.search = '';
    $scope.selectedUserType = false;
    $scope.noInventory = true;
    $scope.totalTransactionsCount = 0;
    $scope.totalQuantitySum = 0;
  };
  
  /**
   * Changes in date will trigger the init function
   */
  $scope.$watch('date', function () {
    let fromDate = $moment($scope.date.startDate).format("DD-MMM-YYYY");
    let endDate = $moment($scope.date.endDate).format("DD-MMM-YYYY");
  
    let fromDateObj = new Date(fromDate);
    let endDateObj = new Date(endDate);
    let fullYear = fromDateObj.getFullYear();
  
    if (fullYear < 2000) {
      growl.error('Min year should be > 2000');
      return;
    }
    
    // fromYear min year is 2000 and also this logic helps with repeated API calls
    if(fromDateObj < endDateObj && fullYear > 2000 && $scope.selectedWarehouseId) {
      getBalanceInventoryForWarehouse(
        $scope.selectedWarehouseId, fromDate, endDate, $rootScope.loginData.city, $scope.currentPage
      );
    }
  }, false);
  
  // when transaction id for sells request is clicked
  $scope.OpenSellRequest = function(confirmationId) {
    
    let pickup = {};
    pickup.confirmationId = confirmationId;
  
    $global.displayLog("Inside open request balance inventory ", "pickup", pickup);
  
    var pickupInstance = $uibModal.open({
      templateUrl: 'views/appointments-modal.html',
      controller: 'AppointmentsModalCtrl',
      size: "lg",
      backdrop: 'static',
      resolve: {
        items: function() {
          return pickup;
        },
        allRequests: function() {
          return null;
        },
        vmParams: function() {
          return null;
        }
      }
    });
    
    pickupInstance.result.then(function(selectedItem) {
    
    }, function() {
    
    });
  };
  
  // when transaction id for vendor sale order request is clicked
  $scope.OpenSaleOrderRequest = function(confirmationId) {
  
    saleOrderService.getVendorSaleOrder(confirmationId)
      .then(function(res){
        if(res.status === "SUCCESS" && res.data && res.data.saleOrder) {
          
          let saleOrder = res.data.saleOrder;
          console.log("saleOrder = ", saleOrder);
          
          var pickupInstance = $uibModal.open({
            templateUrl: 'views/sale-order-modal.html',
            controller: 'SaleOrderModalCtrl',
            size: "lg",
            backdrop: 'static',
            resolve: {
              saleOrder: function() {
                return saleOrder;
              }
            }
          });
          pickupInstance.result.then(function(selectedItem) {
          }, function() {
          });
          
          
        } else {
          console.error("Failed to open vendor sale order ", res);
        }
      })
      .catch(function(err){
        console.error("Failed to open vendor sale order ", err);
      });
    
  };
  
  // when transaction id for transport inventory request is clicked
  $scope.OpenTransportInventoryReceipt = function(confirmationId) {
  
    warehouseService.getTransportInventoryByConfirmationId(confirmationId)
      .then(function(res){
        if(res.status === "SUCCESS" && res.data && res.data.transportInventories && res.data.transportInventories.length > 0) {
            
            let url = res.data.transportInventories[0].gatePassURL;
            
            let pickupInstance = $uibModal.open({
              templateUrl: 'views/view-receipt-modal.html',
              controller: 'ViewReceiptModalController',
              size: "lg",
              resolve: {
                items: function() {
                  return url;
                },
                pageTitle: function() {
                  return "View Transport Inventory Invoice";
                }
              }
            });
            pickupInstance.result.then(function(selectedItem) {
            }, function() {
              //FIXME no close functionality here
            });
          
        } else {
          console.error("Failed to open transport inventory receipt ", res);
        }
      })
      .catch(function(err){
        console.error("Failed to open transport inventory receipt ", err);
      });
    
  };
  
});
