'use strict';

angular.module('scrapQcrmApp')
  .controller('DeleteHolidayCtrl', function ($scope, $rootScope, $moment, $uibModalInstance, holiday, items, $global, $uibModal, DTOptionsBuilder, $window, paymentService, growl) {

    (function () {
      $global.displayLog('Inside delete holiday controller');
      $global.displayLog('DI value for items ', 'items', items);
      $scope.date = items.date;
      $scope.areaId = items.areaId;
    })();

    $scope.deleteHoliday = function () {
      if (!$scope.areaId) {
        holiday.deleteHoliday($scope.date, $rootScope.loginData.city)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success("Holiday deleted successfully!");
              $uibModalInstance.dismiss(res);
            } else {
              growl.error("Holiday could not be deleted!");
            }
          })
          .catch(function (err) {
            console.error('Error in calling the deleteHoliday() API ', err);
          });
      } else {
        holiday.deleteAreaHoliday($scope.date, $scope.areaId)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success("Holiday deleted successfully!");
              $uibModalInstance.dismiss(res);
            } else {
              growl.error("Holiday could not be deleted!");
            }
          })
          .catch(function (err) {
            console.error('Error in calling the deleteAreaHoliday() API ', err);
          });
      }

    };

    $scope.cancel = function (res) {
      $uibModalInstance.dismiss(res);
    };

  });
