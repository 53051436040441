'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:InventoryListCtrl
 * @description
 * # InventoryListCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('InventoryListCtrl', function ($scope, products, ngTableParams, localStorageService, $filter, $state, $stateParams, $global, $uibModal, $moment, consumer, envService, editableOptions, growl, warehouseInventoryService, saleOrderService, warehouseService, $rootScope) {

    /*============================================global values===============================================*/
    var inrFilter = $filter('INR');
    (function () {
      window.scrollTo(0, 0); // values are x,y-offset
      $scope.imagePath = $global.getApiUrl() + $global.getApiObject().getImageFileById + '/';
      $scope.viewOption = { toggle: false };
      $scope.fromBalanceSheet = false;
      $scope.clicked = false;
      $scope.clickedIndexes = {};
      $scope.selectAll = { selected: null };
      $scope.sizeToGet = 12;
      $scope.noDataFound = false;      
      $scope.totalWarehousesQty = 0;
      $scope.pageIndex = 0;
      $scope.totalPages = 0;
      $scope.warehouses = [];
      $scope.selectedItems = [];
      $scope.selectedSortId = { sort_in: null };
      $scope.sort_options = [{ name: "Increasing Quantity", id: "1" }, {
        name: "Decreasing Quantity",
        id: "2"
      }, { name: "Warehouse", id: "3" }, { name: "Date", id: "4" }, { name: "Clearance Date", id: "5" }];
      if ($stateParams.sort !== null) {
        $scope.selectedSortId.sort_in = $stateParams.sort;
        $stateParams.sort = null;
      }
      $scope.itemNotSelected = true;
      $scope.searchingPhase = false;
      $scope.searchText = { characters: null };
    })();

    var iconsValueGetter = function (params) {
      let path = $scope.imagePath;
      if (params.data && params.data.sales) {
        let sales = params.data.sales;
        let img1, img2, img3;
        img1 = (sales[0] ? path + sales[0].image : null);
        img2 = (sales[1] ? path + sales[1].image : null);
        img3 = (sales[2] ? path + sales[2].image : null);
        // console.log(img1+" "+img2+" "+img3);
        return [img1, img2, img3];
      }
      return null;
    };

    var cellIconValueGetter = function (params) {
      let path = $scope.imagePath;
      if (params.data && params.data.image) {
        let imgUrl = path + "" + params.data.image + ".jpg";
        return imgUrl;
      }
      return null;
    };

    (function () {
      var columnDefs = [
        { headerName: "Warehouse", field: "warehouseName" },
        { headerName: "Type", field: "warehouseType" },
        {
          headerName: "Quantity (kgs)",
          field: "currentQuantity",
          cellStyle: { 'text-align': 'right' },
          cellRenderer: QtyCellRenderer
        },
        { headerName: "Total Cost", field: "totalCost", cellStyle: { 'text-align': 'right' }, cellRenderer: AmountCellRenderer },
        { headerName: "Max Quantity (kgs)", field: "totalQuantity.quantity", cellStyle: { 'text-align': 'right' } },
        { headerName: "Last Cleared Date", field: "lastClearedDate", cellStyle: { 'text-align': 'center' } },
        { headerName: "Top 3 Products", valueGetter: iconsValueGetter, cellRenderer: IconsCellRenderer }
      ];
      // "<img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache ng-src=\"{{agGrid.getCellValue(row, col)}}[0]\" class=\"cat-imgs\" lazy-src><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache ng-src=\"{{imagePath}}/{{warehouse.sales[1].image}}.jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache ng-src=\"{{imagePath}}/{{warehouse.sales[2].image}}.jpg\" class=\"cat-imgs\">"
      //{/* <div style=\"float: right;\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache ng-src=\"{{agGrid.getCellValue(row, col)}}[0]\" class=\"cat-imgs\" lazy-src><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache ng-src=\"{{imagePath}}/{{warehouse.sales[1].image}}.jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache ng-src=\"{{imagePath}}/{{warehouse.sales[2].image}}.jpg\" class=\"cat-imgs\"></div></div>" */}
      var rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        paginationAutoPageSize: true,
        rowData: rowData,
        suppressCsvExport: true,
        suppressExcelExport: true,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          console.log("params", params);
          params.api.sizeColumnsToFit();
          if (!($scope.noDataFound) && $scope.warehouses && $scope.warehouses.length > 0) {
            $scope.gridOptions.api.setRowData($scope.warehouses);
          }
        },
        onRowClicked: function (event) {
          console.log("event.data", event, "d", event.data, event.rowIndex);
          if (event && event.data) {
            $scope.boxClicked(event.data, event.rowIndex);
          }
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };
    })();

    (function () {
      var salesColumnDefs = [
        { headerName: "Category", field: "productCategory" },
        {
          headerName: "",
          field: "image",
          cellRenderer: iconCellRenderer,
          valueGetter: cellIconValueGetter,
          maxWidth: 65
        },
        { headerName: "Product Name", field: "itemName" },
        {
          headerName: "Quantity (kgs)",
          field: "totalQuantity",
          type: 'rightAligned',
          cellStyle: { 'direction': 'rtl' },
          cellRenderer: QtyCellRenderer
        },
        {
          headerName: "Last Cleared Quantity",
          field: "lastClearedQuantity",
          type: 'rightAligned',
          cellStyle: { 'direction': 'rtl' },
          cellRenderer: QtyCellRenderer
        },
        { headerName: "Last Cleared Date", field: "lastClearedDate", cellStyle: { 'text-align': 'center' } },
        {
          headerName: "Cost of Scrap",
          field: "totalCost",
          type: 'rightAligned',
          cellStyle: { 'direction': 'rtl' },
          cellRenderer: AmountCellRenderer
        },
        { headerName: "Active SO", field: "warnSO", cellStyle: { 'padding-left': '65px' }, cellRenderer: tickCellRenderer },
        { headerName: "Active TO", field: "warnTR", cellStyle: { 'padding-left': '65px' }, cellRenderer: tickCellRenderer },
        { headerName: "View Split Info", field: "", cellStyle: { 'padding-left': '65px' }, cellRenderer: vsiCellRenderer },
      ];

      var salesData = [];

      $scope.gridOptionsSales = {
        columnDefs: salesColumnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true,
          headerCheckboxSelection: isFirstColumn,
          checkboxSelection: isFirstColumn,
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        rowStyle: { background: '#f4f4f4' },
        rowData: salesData,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          console.log("params", params);
          params.api.sizeColumnsToFit();
          if ($scope.sales_list && $scope.sales_list.length > 0) {
            $scope.gridOptionsSales.api.setRowData($scope.sales_list);
          }
        },
        onCellClicked: function (event) {
          console.log("event.data", event.colDef.headerName, "d", event.data, event.rowIndex);
          if (event && event.data) {
            if (event.colDef.headerName == "View Split Info") {

            } else {
              $scope.itemDetails(event.data, event.rowIndex);
            }
          }
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        },
        onRowSelected: function (event) {
          console.log("event", event, event.node.isSelected());
          if (event.node.isSelected()) {
            $scope.selectedItems.push(event.data);
            $scope.sales_list[event.rowIndex].select = true;
            if ($scope.selectedItems.length === $scope.sales_list.length) {
              $scope.selectAll.selected = true; // marking the outer checkbox to true when all items are selected
            }
            $scope.itemNotSelected = false;
          } else {
            // on checking out of an item from the list
            $scope.selectAll.selected = false;
            console.log("$scope.selectAll", $scope.selectAll.selected);
            let position = $scope.selectedItems.indexOf(event.data); // searching for checked out item's object in the selected item's list
            $scope.selectedItems.splice(position, 1); //removing it from the list
            $scope.sales_list[event.rowIndex].select = false;
            if ($scope.selectedItems.length === 0) {
              $scope.itemNotSelected = true; // disabling Initiate clearance button
            }
          }
          console.log("$scope.selectedItems", $scope.selectedItems);
        }
      };
    })();

    $scope.countLabels = {
      totalCost: 0,
      totalWarehouses: 0,
      totalQuantity: 0,
    }


    $scope.resetCountLabels = function () {
      $scope.countLabels = {
        totalCost: 0,
        totalWarehouses: 0,
        totalQuantity: 0,
      }
    }
    /*============================================default values===============================================*/

    function vsiCellRenderer() {
    }

    vsiCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      this.eGui.style.fontSize = '18px';
      var cellVal = "<i class=\"fa fa-info-circle btn-sm waves-effect waves-light\" aria-hidden=\"true\"></i>";
      this.eGui.innerHTML = cellVal;
    };

    vsiCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function tickCellRenderer() {
    }

    tickCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      var cellVal;
      if (params.value == true) {
        cellVal = "<span><i uib-tooltip=\"Pending Order Exists\" class=\"fa fa-check\" aria-hidden=\"true\"></i></span>";
      } else {
        cellVal = "<span><i uib-tooltip=\"No Pending Order\" class=\"fa fa-times\" aria-hidden=\"true\"></i></span>"
      }
      this.eGui.innerHTML = cellVal;
    };

    tickCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function iconCellRenderer() {
    }

    iconCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        var iconCell = "<div class=\"baa-cat valign\"><img src=" + params.value + " class=\"m-a cat-imgs\"></div>";
        this.eGui.innerHTML = iconCell;
      }
    };

    iconCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function IconsCellRenderer() {
    }

    IconsCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        var iconsDiv = "<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0] + ".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src=" + params.value[1] + ".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src=" + params.value[2] + ".jpg\" class=\"cat-imgs\"></div></div>";

        // var gender = '<img border="0" width="15" height="10" src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/' + params.value.toLowerCase() + '.png">';
        // this.eGui.innerHTML = gender + ' ' + params.value;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    IconsCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function AmountCellRenderer() {
    }

    AmountCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        // let val= params.value?(params.value).toFixed(2):'-';
        let val = params.value ? inrFilter(params.value) : '-';
        var iconsDiv = "<div>" + val + "</div>";
        this.eGui.innerHTML = iconsDiv;
      }
    };

    AmountCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function QtyCellRenderer() {
    }

    QtyCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        // let val= params.value?(params.value).toFixed(2):'-';
        let val = params.value ? inrFilter(params.value) : '-';
        // console.log(typeof (params.value),params.value,"val",val);
        var iconsDiv = "<div>" + val + "</div>";
        this.eGui.innerHTML = iconsDiv;
      }
    };

    QtyCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function isFirstColumn(params) {
      var displayedColumns = params.columnApi.getAllDisplayedColumns();
      var thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }

    /*============================================default service API calls===============================================*/

    $scope.changeViewToggle = function (val) {
      console.log(val);
      if (val.toggle) {
        growl.success("Switched to Grid View");
      } else {
        growl.success("Switched to List View");
      }
      $scope.viewOption.toggle = !($scope.viewOption.toggle);
    };

    $scope.getInventories = function (obj) {
      if (obj && (obj.keyword || obj.sort)) {
        $scope.totalWarehousesQty = 0;
        $scope.pageIndex = 0;
        $scope.warehouses = [];
        $scope.clickedIndexes = {};
      }

      if ($scope.selectedSortId.sort_in !== null && !obj.sort) {
        obj.sort = $scope.selectedSortId.sort_in;
      }

      if ($stateParams.size !== null) {
        obj.size = $stateParams.size;
        $stateParams.size = null;
      } else {
        obj.size = $scope.sizeToGet;
      }

      if ($scope.searchingPhase) {
        obj.size = null;
      }

      warehouseInventoryService.getWarehouseInventories(obj)
        .then(function (res) {
          // if(obj && obj.keyword){
          $scope.warehouses = [];
          $scope.clickedIndexes = {};
          // }
          if (res.status === "SUCCESS") {
            console.log("api response", res.data);
            if (res && res.data) {
              $scope.totalPages = (res.data.totalPages) ? res.data.totalPages : 0;
              $scope.lastClearanceDate = (res.data.lastClearedDate) ? res.data.lastClearedDate : "N/A";

              // $scope.totalQty=(res.data.totalQuantity)?Math.round(((res.data.totalQuantity/1000) + Number.EPSILON) * 1000) / 1000 + " tons":0;
              $scope.totalQty = (res.data.totalQuantity) ? (res.data.totalQuantity) : 0;
              console.log("$scope.", $scope.totalQty)
              if (res.data.warehouseInventories && res.data.warehouseInventories.length > 0) {

                $scope.countLabels = {
                  totalCost: res.data.totalCost,
                  totalWarehouses: res.data.totalWarehouseCount,
                  totalQuantity: res.data.totalQuantity,
                }
                $scope.noDataFound = false;
                for (let i = 0; i < res.data.warehouseInventories.length; i++) {
                  if (res.data.warehouseInventories[i]) {
                    $scope.warehouses.push(res.data.warehouseInventories[i]);
                  }
                }
                $scope.warehouses.forEach(elem => {
                  if (!elem.totalQuantity) {
                    elem.totalQuantity = { quantity: 0, uom: '' };
                    elem.percentage = "-"; //"Total Capacity not configured"
                  } else {
                    let n = 1;
                    if (elem.totalQuantity.uom === 'tons') {
                      n = 1000;
                    }
                    // "Inventory: "+
                    elem.percentage = Math.round((elem.currentQuantity / (elem.totalQuantity.quantity * n)) * 100) + "%";
                  }
                  // elem.currentQuantity = Math.round(((elem.currentQuantity/1000) + Number.EPSILON) * 1000) / 1000;
                  elem.currentQuantity = Number((elem.currentQuantity).toFixed(2));
                  let first = (new Date(elem.lastClearedDate)).toLocaleDateString(),
                    second = (new Date()).toLocaleDateString();
                  let diff = datediff(parseDate(first), parseDate(second));
                  console.log("diff", diff, (new Date(elem.lastClearedDate)).toLocaleDateString(), (new Date()).toLocaleDateString());
                  if (diff >= 30) {
                    elem.indicator = 1;
                  } else if (diff >= 15 && diff < 30) {
                    elem.indicator = 2;
                  } else {
                    elem.indicator = 3;
                  }
                });
                if ($scope.gridOptions && $scope.gridOptions.api) {
                  $scope.gridOptions.api.setRowData($scope.warehouses);
                }
                console.log("$stateParams 87", $stateParams)
                // for back button in balance sheet on clicking to get back to the state on which balance sheet was open
                if ($stateParams.index !== null) {
                  console.log("$stateParams 82", $stateParams);
                  $scope.fromBalanceSheet = true;
                  let clickedIndex = $stateParams.index;
                  $stateParams.index = null;
                  let fromTransportInv = null;
                  fromTransportInv = $global.getLocalItem("fromWarehouse", true);
                  localStorageService.remove("fromWarehouse");
                  if (fromTransportInv && $scope.warehouses.length > 0) {
                    let warehouse = $scope.warehouses[clickedIndex];
                    if (warehouse.warehouseName !== fromTransportInv) {
                      for (let i = 0; i < $scope.warehouses.length; i++) {
                        let val = $scope.warehouses[i];
                        if (val.warehouseName === fromTransportInv) {
                          clickedIndex = i;
                          break;
                        }
                      }
                    }
                  }
                  $scope.boxClicked($scope.warehouses[clickedIndex], clickedIndex);
                }
              } else if (res && res.data && (!res.data.warehouseInventories || res.data.warehouseInventories.length === 0)) {
                $scope.resetCountLabels();
                $scope.noDataFound = true;
                if ($scope.searchingPhase) {
                  $scope.searchingPhase = false;
                }
                for (let param in $stateParams) {
                  $stateParams[param] = null;
                }
                growl.error("No Warehouses Found");
              } else {
                $scope.noDataFound = true;
                if ($scope.searchingPhase) {
                  $scope.searchingPhase = false;
                }
                for (let param in $stateParams) {
                  $stateParams[param] = null;
                }
                growl.error("Error in getting Warehouses");
              }
            }
          } else {
            $scope.resetCountLabels();
            $scope.noDataFound = true;
            console.error("Error in getting all the warehouses list ", res);
          }
        })
        .catch(function (error) {
          $scope.resetCountLabels();

          console.error("Error in getting all the warehouses list ", error);
        })
    };

    // triggered on scroll reaching to the end of the page - used to add pagination
    $scope.scrollToEndWindow = function (dir) {
      // alert('Window scrolled to ' + dir);
      if ($stateParams.page !== null) {
        $scope.pageIndex = $stateParams.page;
        $stateParams.page = null;
      }
      $scope.pageIndex += 1;
      if ($scope.pageIndex > $scope.totalPages - 1 && !$scope.fromBalanceSheet) {
        //growl.success("No More Warehouses Found!");
        $scope.pageIndex -= 1;
        return;
      }
      let obj = { page: $scope.pageIndex };

      if ($scope.selectedSortId.sort_in !== null && !obj.sort) {
        obj.sort = $scope.selectedSortId.sort_in;
        console.log("adding sort", obj.sort)
      }

      if ($stateParams.size !== null) {
        obj.size = $stateParams.size;
        $stateParams.size = null;
      } else {
        obj.size = $scope.sizeToGet;
      }

      if ($scope.searchText.characters) obj.keyword = $scope.searchText.characters;
      warehouseInventoryService.getWarehouseInventories(obj)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            console.log("api response", res.data);
            if (res && res.data) {
              if (res.data.lastClearedDate) {
                // $scope.lastClearanceDate=(new Date(res.data.lastClearedDate) > new Date($scope.lastClearanceDate))?res.data.lastClearedDate:$scope.lastClearanceDate;
                if ($scope.lastClearanceDate === "N/A") {
                  $scope.lastClearanceDate = res.data.lastClearedDate;
                } else if (new Date(res.data.lastClearedDate) > new Date($scope.lastClearanceDate)) {
                  $scope.lastClearanceDate = res.data.lastClearedDate;
                } else {
                  $scope.lastClearanceDate = $scope.lastClearanceDate;
                }
              }

              if (res.data.totalQuantity) {
                // .split(" ")[0]
                let totalQty = Number($scope.totalQty);
                // let newtotalQty=Math.round(((res.data.totalQuantity/1000) + Number.EPSILON) * 1000) / 1000 ;
                let newtotalQty = res.data.totalQuantity;
                $scope.totalQty = (totalQty + newtotalQty);
              }

              if (res.data.warehouseInventories && res.data.warehouseInventories.length > 0) {
                res.data.warehouseInventories.forEach(elem => {
                  if (!elem.totalQuantity) {
                    elem.totalQuantity = { quantity: 0, uom: '' };
                    elem.percentage = "-"; //"Total Capacity not configured"
                  } else {
                    let n = 1;
                    if (elem.totalQuantity.uom === 'tons') {
                      n = 1000;
                    }
                    // "Inventory: "+
                    elem.percentage = Math.round((elem.currentQuantity / (elem.totalQuantity.quantity * n)) * 100) + "%";
                  }
                  // elem.currentQuantity = Math.round(((elem.currentQuantity/1000) + Number.EPSILON) * 1000) / 1000;
                  elem.currentQuantity = Number((elem.currentQuantity).toFixed(2));
                  let first = (new Date(elem.lastClearedDate)).toLocaleDateString(),
                    second = (new Date()).toLocaleDateString();
                  let diff = datediff(parseDate(first), parseDate(second));
                  console.log("diff", diff, (new Date(elem.lastClearedDate)).toLocaleDateString(), (new Date()).toLocaleDateString());
                  if (diff >= 30) {
                    elem.indicator = 1;
                  } else if (diff >= 15 && diff < 30) {
                    elem.indicator = 2;
                  } else {
                    elem.indicator = 3;
                  }
                });
                for (let i = 0; i < res.data.warehouseInventories.length; i++) {
                  $scope.warehouses.push(res.data.warehouseInventories[i]);
                }
              }
              // if(res && res.data && (!res.data.warehouseInventories || res.data.warehouseInventories.length===0))
              else {
                $scope.pageIndex -= 1;
                $scope.fromBalanceSheet = false;
                console.log("No More Warehouses Found")
                growl.success("No More Warehouses Found");
              }
            } else {
              $scope.pageIndex -= 1;
              growl.error("Error in getting Warehouses");
            }
            console.log("$scope.warehouses", $scope.warehouses)
          } else {
            console.error("Error in getting all the warehouses list ", res);
          }
        })
        .catch(function (error) {
          console.error("Error in getting all the warehouses list ", error);
        });
    };

    // code to fetch searched warehouses
    $scope.searchWarehouse = function (key) {
      if (key) {
        key = key.trim();
        if (key.length > 2) {
          console.log("key", key, "$searchText", $scope.searchText);
          let obj = { keyword: key };
          $scope.searchingPhase = true;
          $scope.getInventories(obj);
        }
      } else if (key.length === 0) {
        $scope.totalWarehousesQty = 0;
        $scope.pageIndex = 0;
        $scope.warehouses = [];
        let obj = {};
        $scope.searchingPhase = false;
        console.log("$stateParams 205", $stateParams)
        $scope.getInventories(obj);
      }
    };

    // code to fetch warehouses in selected sorting type
    $scope.sortWarehouses = function () {
      // if($scope.searchText.characters) {
      //   $scope.searchText.characters=null;
      //   if($scope.searchingPhase) $scope.searchingPhase=false;
      // }
      let obj = { page: 0, sort: $scope.selectedSortId.sort_in };
      if ($scope.searchText.characters) {
        obj.keyword = $scope.searchText.characters;
      } else {
        if ($scope.searchingPhase) {
          $scope.searchingPhase = false;
        }
      }
      if ($stateParams.page !== null) {
        $stateParams.page = null;
      }
      $scope.getInventories(obj);
    };

    (function () {
      // when page was navigated to balance sheet after searching for that item and is navigated back
      if ($stateParams.key !== null) {
        $scope.searchText.characters = $stateParams.key;
        $stateParams.key = null;
        console.log("$scope.searchText", $scope.searchText)
        $scope.searchWarehouse($scope.searchText.characters);
      }
      else {
        let obj = { page: 0 };
        $scope.getInventories(obj); //loads data on entering the page
      }
    })();

    /*============================================core functions===============================================*/

    function parseDate(str) {
      let mdy = str.split('/');
      return new Date(mdy[2], mdy[1] - 1, mdy[0]);
    }

    function datediff(first, second) {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    // called on warehouse box clicked
    $scope.boxClicked = function (obj, index) {
      let flag = true;
      if (!$scope.clickedIndexes[index]) {
        $scope.clickedIndexes[index] = true;
      } else {
        flag = false;
      }
      console.log("in boxClicked", obj, index);
      $scope.selectedIndex = index;

      let selectedObj = $scope.warehouses[index];
      $scope.selectedWarehouse = selectedObj;

      $scope.selectedWarehouseId = selectedObj.warehouseId;

      $scope.phoneNumber = selectedObj.phoneNumber ? selectedObj.phoneNumber : "-";

      $scope.ownerName = selectedObj.ownerName ? selectedObj.ownerName : "-";

      $scope.warehouseName = selectedObj.warehouseName;
      $scope.totalCost = selectedObj.totalCost;


      $scope.warehouseTotal = (selectedObj.totalQuantity && selectedObj.totalQuantity.quantity) ? selectedObj.totalQuantity.quantity : "-";
      if (selectedObj.totalQuantity && selectedObj.totalQuantity.uom) {
        if (selectedObj.totalQuantity.uom === 'tons') {
          $scope.warehouseTotal *= 1000;
        }
      }
      $scope.lastClearedDate = selectedObj.lastClearedDate ? selectedObj.lastClearedDate : "-";
      $scope.sales_list = selectedObj.sales ? selectedObj.sales : [];
      console.log("$scope.sales_list", $scope.sales_list);
      if (flag) {
        $scope.sales_list.forEach(elem => {
          elem.warnSO = false;
          elem.warnTR = false;
          if (elem.lastClearedDate) {
            elem.lastClearedDate = $moment(elem.lastClearedDate).format("YYYY-MMM-DD");
          } else {
            elem.lastClearedDate = '-';
          }
          if (elem.totalQuantity) {
            // elem.totalQuantity = Math.round(((elem.totalQuantity / 1000) + Number.EPSILON) * 1000) / 1000;
            elem.totalQuantity = Number((elem.totalQuantity).toFixed(2));
          }
          if (elem.lastClearedQuantity) {
            // elem.lastClearedQuantity = Math.round(((elem.lastClearedQuantity / 1000) + Number.EPSILON) * 1000) / 1000;
            elem.lastClearedQuantity = Number((elem.lastClearedQuantity).toFixed(2));
          }
        });
      }
      $scope.Activities = 0;
      $scope.PendingRequests = 0;
      if ($scope.sales_list.length > 0) {
        $scope.getSaleOrderForWarehouse($scope.selectedWarehouseId, "ALL", $moment($global.projectInceptionDate).format("DD-MMM-YYYY"), $moment(new Date()).format("DD-MMM-YYYY"));
        getTransportInventoriesForAWarehouse();
      }
      // }
      $scope.selectAll.selected = null;
      $scope.clicked = true;
      if ($scope.gridOptionsSales && $scope.gridOptionsSales.api) {
        $scope.gridOptionsSales.api.setRowData($scope.sales_list);
        $scope.gridOptionsSales.api.deselectAll();
      }
    };

    // back button code
    $scope.goBack = function () {
      $scope.clicked = false;
      $scope.selectAll.selected = false;
      $scope.gridOptionsSales.api.deselectAll();
      $scope.itemNotSelected = true;
      $scope.sales_list.forEach(function (sale) {
        sale.select = $scope.selectAll.selected;
      });
      $scope.selectedWarehouse = null;
    }

    // checks all of the items rows in each warehouse's table
    $scope.checkAll = function () {
      $scope.selectedItems = [];
      $scope.itemNotSelected = true;
      $scope.sales_list.forEach(function (sale) {
        sale.select = $scope.selectAll.selected;
      });
      if ($scope.selectAll.selected) {
        $scope.itemNotSelected = false;
        for (let i = 0; i < $scope.sales_list.length; i++) {
          $scope.selectedItems.push($scope.sales_list[i]);
        }
      }
    };


    $scope.itemMarked = function (obj, index) {
      // on selecting an item from the list
      if (obj.select) {
        $scope.selectedItems.push(obj);
        if ($scope.selectedItems.length === $scope.sales_list.length) {
          $scope.selectAll.selected = true; // marking the outer checkbox to true when all items are selected
        }
        $scope.itemNotSelected = false;
      } else {
        // on checking out of an item from the list
        $scope.selectAll.selected = false;
        console.log("$scope.selectAll", $scope.selectAll.selected);
        let position = $scope.selectedItems.indexOf(obj); // searching for checked out item's object in the selected item's list
        $scope.selectedItems.splice(position, 1); //removing it from the list
        if ($scope.selectedItems.length === 0) {
          $scope.itemNotSelected = true; // disabling Initiate clearance button
        }
      }
    }

    // go to balance sheet of the item clicked
    $scope.itemDetails = function (item, index) {
      console.log("itemdetails", item, index);
      let objToSend = {
        id: $scope.selectedWarehouseId,
        from: "Warehouse",
        index: $scope.selectedIndex,
        item: item.itemName,
        sort: $scope.selectedSortId.sort_in,
        size: $scope.warehouses.length,
        page: $scope.pageIndex,
        key: null
      };
      if ($scope.searchingPhase) {
        objToSend.key = $scope.searchText.characters; //storing the searched string as a parameter
      }
      $state.go('root.balanceSheet', objToSend)
    };

    $scope.initiateTransport = function () {
      $global.displayLog("Inside initiate transport");
      let objToSend = {
        id: $scope.selectedWarehouseId,
        from: "Warehouse",
        index: $scope.selectedIndex,
        sort: $scope.selectedSortId.sort_in,
        size: $scope.warehouses.length,
        page: $scope.pageIndex,
        key: null
      };
      if ($scope.searchingPhase) {
        objToSend.key = $scope.searchText.characters; //storing the searched string as a parameter
      }

      objToSend["initiateTransportInventoryItems"] = {};

      objToSend["initiateTransportInventoryItems"]["selectedWarehouseId"] = $scope.selectedWarehouseId;

      let transportInventorySelectedItems = [];
      $scope.sales_list.forEach(elem => {
        if (elem.select && elem.totalQuantity > 0) {
          transportInventorySelectedItems.push(JSON.parse(JSON.stringify(elem))); //creating deep copy of the object
        }
      });

      objToSend["initiateTransportInventoryItems"]["transportInventoryItems"] = transportInventorySelectedItems;

      console.log("$scope.transportInventorySelectedItems = ", transportInventorySelectedItems);

      if (transportInventorySelectedItems && transportInventorySelectedItems.length > 0) {
        // $state.go('root.transportInventory', objToSend);
        var modalInstance = $uibModal.open({
          templateUrl: 'views/transport-inventory-modal.html',
          controller: 'TransportInventoryModalCtrl',
          size: "md",
          backdrop: 'static',
          resolve: {
            transferRequestData: function () {
              return objToSend;
            }
          }
        });

        modalInstance.result.then(function () {
          $scope.sales_list.forEach(elem => {
            elem.select = false;
          });
          $scope.selectAll.selected = false;
          if ($scope.gridOptionsSales && $scope.gridOptionsSales.api) {
            $scope.gridOptionsSales.api.deselectAll();
          }
          $scope.itemNotSelected = true;
          $scope.selectedItems = [];
          objToSend = [];
        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
      } else {
        growl.error("Empty transport inventory items");
      }

    };
    $scope.initiateClearance = function () {
      let paramsObj = {
        id: $scope.selectedWarehouseId,
        from: "Warehouse",
        index: $scope.selectedIndex,
        sort: $scope.selectedSortId.sort_in,
        size: $scope.warehouses.length,
        page: $scope.pageIndex,
        key: null
      };
      if ($scope.searchingPhase) {
        paramsObj.key = $scope.searchText.characters; //storing the searched string as a parameter
      }
      $global.setLocalItem("warehouseInvParams", paramsObj, true);
      let objToSend = [];
      $scope.sales_list.forEach(elem => {
        if (!elem.select) {
          $scope.selectedItems.push(JSON.parse(JSON.stringify(elem))); //creating deep copy of the object
        }
      });
      $scope.selectedItems.forEach(elem => {
        if (!elem.select) {
          elem.originalTotalQuantity = elem.totalQuantity;
          elem.totalQuantity = 0;
          elem.totalCost = 0;
        }
      });
      angular.copy($scope.selectedItems, objToSend);
      console.log("$scope.selectedItems", $scope.selectedItems, "$scope.sales_list", $scope.sales_list);
      var modalInstance = $uibModal.open({
        templateUrl: 'views/create-sale-order-modal.html',
        controller: 'CreateSaleOrderModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          warehouseId: function () {
            return $scope.selectedWarehouseId;
          },
          vendorId: function () {
            return null;
          },
          itemsList: function () {
            // return $scope.sales_list;
            return objToSend;
          }
        }
      });

      modalInstance.result.then(function (res) {
        // console.log("Res", res);
        $scope.sales_list.forEach(elem => {
          elem.select = false;
        });
        $scope.selectAll.selected = false;
        if ($scope.gridOptionsSales && $scope.gridOptionsSales.api) {
          $scope.gridOptionsSales.api.deselectAll();
        }
        $scope.itemNotSelected = true;
        $scope.selectedItems = [];
        objToSend = [];
        let params = $global.getLocalItem("warehouseInvParams", true);
        localStorageService.remove("warehouseInvParams");
        if (res === "Reload") {
          $state.go($state.current, params, { reload: true });
        }
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
      });
    }
    $scope.redirectToSaleOrders = function (warehouse, index) {
      console.log("warehouse", warehouse, index);
      if (warehouse && warehouse.warehouseId) {
        $state.go("root.saleOrder", { fromWarehouseId: warehouse.warehouseId });
      } else {
        growl.error("Invalid Data in Warehouse");
      }
    };

    $scope.redirectToTrReqs = function (warehouse, index) {
      console.log("warehouse", warehouse, index);
      if (warehouse && warehouse.warehouseId) {
        $state.go("root.transportInventory", { fromWarehouseId: warehouse.warehouseId });
      } else {
        growl.error("Invalid Data in Warehouse");
      }
    };

    // sort functionality
    $scope.changeSort = function (type) {
      if (type === "itemName") {
        $scope.itemNameSort = !$scope.itemNameSort;
        $scope.sortType = "itemName";
        $scope.sortValue = $scope.itemNameSort;
      }
      if (type === "productCategory") {
        $scope.productCategorySort = !$scope.productCategorySort;
        $scope.sortType = "productCategory";
        $scope.sortValue = $scope.productCategorySort;
      }
      if (type === "totalQuantity") {
        $scope.totalQuantitySort = !$scope.totalQuantitySort;
        $scope.sortType = "totalQuantity";
        $scope.sortValue = $scope.totalQuantitySort;
      }
      if (type === "lastClearedQuantity") {
        $scope.lastClearedQuantitySort = !$scope.lastClearedQuantitySort;
        $scope.sortType = "lastClearedQuantity";
        $scope.sortValue = $scope.lastClearedQuantitySort;
      }
      if (type === "lastClearedDate") {
        $scope.lastClearedDateSort = !$scope.lastClearedDateSort;
        $scope.sortType = "lastClearedDate";
        $scope.sortValue = $scope.lastClearedDateSort;
      }
      if (type === "totalCost") {
        $scope.totalCostSort = !$scope.totalCostSort;
        $scope.sortType = "totalCost";
        $scope.sortValue = $scope.totalCostSort;
      }
    };

    $scope.toggleSort = function (type) {
      if (type === "warehouseName") {
        $scope.warehouseNameSort = !$scope.warehouseNameSort;
        $scope.sortTypeLV = "warehouseName";
        $scope.sortValueLV = $scope.warehouseNameSort;
      }
      if (type === "warehouseType") {
        $scope.warehouseTypeSort = !$scope.warehouseTypeSort;
        $scope.sortTypeLV = "warehouseType";
        $scope.sortValueLV = $scope.warehouseTypeSort;
      }
      if (type === "currentQuantity") {
        $scope.currentQuantitySort = !$scope.currentQuantitySort;
        $scope.sortTypeLV = "currentQuantity";
        $scope.sortValueLV = $scope.currentQuantitySort;
      }
      if (type === "totalQuantity.quantity") {
        $scope.maxQuantitySort = !$scope.maxQuantitySort;
        $scope.sortTypeLV = "totalQuantity.quantity";
        $scope.sortValueLV = $scope.maxQuantitySort;
      }
      if (type === "lastClearedDate") {
        $scope.lastClearedDateSort = !$scope.lastClearedDateSort;
        $scope.sortTypeLV = "lastClearedDate";
        $scope.sortValueLV = $scope.lastClearedDateSort;
      }
    }

    $scope.getSaleOrderForWarehouse = function (warehouseId, vendorId, fromdate, todate) {
      var pendingCount = 0;
      var approvedCount = 0;
      var rejectedCount = 0;
      var dispatchedCount = 0;
      $scope.pendingItemsMap = {};
      saleOrderService.getSaleOrderForWarehouse(warehouseId, vendorId, fromdate, todate).then(function (res) {
        console.log(res)
        if (res.status == "SUCCESS") {
          if (res.data.saleOrder) {
            var saleOrder_list = res.data.saleOrder;
            if (saleOrder_list.length == 0) {
              // $scope.noSaleOrderFound = true;
            } else {
              saleOrder_list.forEach(function (element, index) {
                if (element.status == "PENDING") {
                  pendingCount = pendingCount + 1;
                  if (element.saleOrderItems && element.saleOrderItems.length > 0) {
                    element.saleOrderItems.forEach(item => {
                      if (item && item.items && item.items.items) {
                        let innerItem = item.items.items;
                        // console.log("innerItem",innerItem,innerItem[0].categoryName);
                        if (innerItem[0].categoryName) {
                          $scope.pendingItemsMap[innerItem[0].categoryName] = true;
                        }
                      }
                    });
                  }
                } else if (element.status == "APPROVED") {
                  approvedCount = approvedCount + 1;
                } else if (element.status == "REJECTED") {
                  rejectedCount = rejectedCount + 1;
                } else if (element.status == "DISPATCHED") {
                  dispatchedCount = dispatchedCount + 1;
                }
              });
              if (!(Object.keys($scope.pendingItemsMap).length === 0 && $scope.pendingItemsMap.constructor === Object)) {
                $scope.Activities = Object.keys($scope.pendingItemsMap).length;
                $scope.sales_list.forEach(elem => {
                  if ($scope.pendingItemsMap[elem.itemName]) {
                    elem.warnSO = true;
                  }
                });
              }
              console.log("$scope.pendingItemsMap", $scope.pendingItemsMap);
              // $scope.noSaleOrderFound = false;

              if (dispatchedCount > 0 || rejectedCount > 0
                || approvedCount > 0 || pendingCount > 0) {
                // $scope.noSaleOrderFound = false;
              } else {
                // $scope.noSaleOrderFound = true;
              }
            }
          } else {
            // $scope.noSaleOrderFound = true;
          }
        } else {
          // $scope.noSaleOrderFound = true;
        }
        if ($scope.gridOptionsSales && $scope.gridOptionsSales.api) {
          $scope.gridOptionsSales.api.setRowData($scope.sales_list);
        }
      }).catch(function (err) {
        console.log("Err:", err);
      });
    };

    const getTransportInventoriesForAWarehouse = function () {
      $scope.pendingTRMap = {};
      var pendingCount = 0;
      var approvedCount = 0;
      var rejectedCount = 0;
      var dispatchedCount = 0;
      let obj = { fromWarehouseId: null, toWarehouseId: null, fromDate: null, toDate: null };
      obj.fromWarehouseId = $scope.selectedWarehouseId ? $scope.selectedWarehouseId : "ALL";
      obj.toWarehouseId = "ALL";
      obj.fromDate = $moment($global.projectInceptionDate).format("DD-MMM-YYYY");
      obj.toDate = $moment(new Date()).format("DD-MMM-YYYY");

      warehouseService.getTransportInventories(obj).then(function (res) {
        if (res && res.status === "SUCCESS") {
          if (res && res.data && res.data.transportInventories) {
            var transportInventories = res.data.transportInventories;
            console.log("transportInventories", transportInventories);
            // $scope.totalTransportInventoriesRecords = res.data.totalRecords;
            if (transportInventories.length === 0) {
              // $scope.noInventoriesFound = true;
            } else {
              transportInventories.forEach(function (element, index) {
                // var totalAmount = 0.0;
                // element.items.forEach(function(elem, indx) {
                //   elem.amount = parseFloat(elem.amount.replace(',', ''));
                //   totalAmount = totalAmount + parseFloat(elem.amount);
                // });
                // element['costOfScrap'] = totalAmount;

                if (element.status === "PENDING") {
                  element['statusNo'] = 1;
                  if (element.items && element.items.length > 0) {
                    element.items.forEach((elem, idx) => {
                      $scope.pendingTRMap[elem.categoryName] = true;
                    }
                    );
                    pendingCount = pendingCount + 1;
                  }
                } else if (element.status === "APPROVED") {
                  element['statusNo'] = 2;
                  approvedCount = approvedCount + 1;
                } else if (element.status === "REJECTED") {
                  element['statusNo'] = 3;
                  rejectedCount = rejectedCount + 1;
                } else if (element.status === "DISPATCHED") {
                  element['statusNo'] = 4;
                  dispatchedCount = dispatchedCount + 1;
                }
              });
              // console.log("$scope.noContent",$scope.noContent);
              // $scope.noInventoriesFound = false;
              console.log("$scope.pendingTRMap", $scope.pendingTRMap);
              if (!(Object.keys($scope.pendingTRMap).length === 0 && $scope.pendingTRMap.constructor === Object)) {
                $scope.PendingRequests = Object.keys($scope.pendingTRMap).length;
                $scope.sales_list.forEach(elem => {
                  if ($scope.pendingTRMap[elem.itemName]) {
                    elem.warnTR = true;
                  }
                });
              }
              if (dispatchedCount > 0 || rejectedCount > 0
                || approvedCount > 0 || pendingCount > 0) {
                // if($scope.noContent){
                // $scope.noContent=false;
                // }
                // $scope.noInventoriesFound = false;
                if (($scope.selectedStatusId === 1 && $scope.pendingCount === 0) || ($scope.selectedStatusId === 2 && $scope.approvedCount === 0)) {
                  // $scope.noContent=true;
                }
              } else {
                // $scope.noInventoriesFound = true;
              }
            }
          }
        } else {

        }
      }).catch(function (err) {
        console.log("Err", err);
      })
    };


  });
