'use strict';
angular.module('scrapQcrmApp')
  .factory('SellNow', function($global, httpService, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      sellNow: function(data, type) {
        if (type == "PICKUP")
          var url = $global.getApiUrl() + $global.getApiObject().sellNowPickup;
        if (type == "DROP")
          var url = $global.getApiUrl() + $global.getApiObject().sellNowDrop;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      sellRequestForCommunityAppointment: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().sellRequestForCommunityAppointment;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getSellById: function(id) {
        var url = $global.getApiUrl() + $global.getApiObject().getSellById.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSlots: function() {
        var url = $global.getApiUrl() + $global.getApiObject().getSlots;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellRquests: function(params) {
        var city = $rootScope.loginData.city;
        
        var consumerId = $global.consumerId;
        params.consumerid = consumerId;
        params.page -= 1;
        var params = "?" + $global.objToQueryString(params);
        params = params + "&city=" + city
        var url = $global.getApiUrl() + $global.getApiObject().getSellRquests + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      }, getSells: function(params) {
        var city = $rootScope.loginData.city;
        params.page -= 1;
        var params = "?" + $global.objToQueryString(params);
        params = params + "&city=" + city
        var url = $global.getApiUrl() + $global.getApiObject().searchSellPhotosRequest + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getAgentsByLatLongAndSellId: function(lat, lng, sellId) {
        var url = $global.getApiUrl() + $global.getApiObject().getAgentsByLatLngAndSellId.replace(":lng", lng).replace(":lat", lat).replace(":sellId", sellId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getAgentsByLatAndLong: function(lat, lng) {
        var url = $global.getApiUrl() + $global.getApiObject().getAgentsByLatAndLng.replace(":lng", lng).replace(":lat", lat);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      cancelSellRquests: function(confirmationId) {
        var url = $global.getApiUrl() + $global.getApiObject().cancelSellRquests.replace(":cid", confirmationId);
        var $request = httpService.httpRequest(url, "P");
        return $request;
      },
      cancelSellRquestsRecurring: function(confirmationId) {
        var url = $global.getApiUrl() + $global.getApiObject().cancelSellRquestsRecurring.replace(":cid", confirmationId);
        var $request = httpService.httpRequest(url, "P");
        return $request;
      },
      getAddress: function(id) {
        var consumerId = $global.consumerId;
        var url = $global.getApiUrl() + $global.getApiObject().getAddress + "?userid=" + id;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      saveCosumerAddress: function(data) {
        var consumerId = $global.consumerId;
        var url = $global.getApiUrl() + $global.getApiObject().saveAddress;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      updatePickup: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().updatePickup.replace(":sellId", data.sellId);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      updateDrop: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateDrop.replace(":sellId", data.sellId);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      updateSellItems: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateSellItems;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      completeSellItems: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().completeSellItems;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      declineRequest: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().declineRequest;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      reschedule: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().reschedule;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      pendingRequests: function(param) {
        var params = "?" + $global.objToQueryString(param);
        var url = $global.getApiUrl() + $global.getApiObject().pendingRequests + params;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      payToConfirmationId: function(id, paymentType) {
        var url = $global.getApiUrl() + $global.getApiObject().payToConfirmationId.replace(":id", id).replace(":paymentType", paymentType);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      retrievePayment: function(id) {
        var url = $global.getApiUrl() + $global.getApiObject().retrievePayment.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      allRequests: function(param) {
        
        var params = "?" + $global.objToQueryString(param);
        var url = $global.getApiUrl() + $global.getApiObject().pendingRequests + params;
        if ($rootScope.loginData.role == "WHOLESALER") {
          url += "&wholesalerid=" + $rootScope.loginData.userid;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      sellOrder: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().sellOrder;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      checkAgentSlotAvailability: function(agentId, sellObjId) {
        var url = $global.getApiUrl() + $global.getApiObject().checkAgentSlotAvailability.replace(":agentId", agentId).replace(":sellObjId", sellObjId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      slotsCheck: function(data, sellId) {
        var url = $global.getApiUrl() + $global.getApiObject().slotsCheck.replace(":sellId", sellId);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      slotsCheckCreateAppointment: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().slotsCheckCreateAppointment;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      //service method to call /sell/agentComment api to save agent comment
      addAgentComment: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().addAgentComment;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getAppointmentsGrid: function (requestBody) {
        let url = $global.getApiUrl() + $global.getApiObject().getAppointmentsGrid;
        return httpService.httpRequest(url, "P", requestBody);
      },
      getCommunityAppointmentsGrid: function (requestBody) {
        let url = $global.getApiUrl() + $global.getApiObject().getCommunityAppointmentsGrid;
        return httpService.httpRequest(url, "P", requestBody);
      },
      requestSellPayment: function (requestBody) {
        let url = $global.getApiUrl() + $global.getApiObject().requestSellPayment;
        return httpService.httpRequest(url, "P", requestBody);
      },
      retrieveSellPayment: function (requestBody) {
        let sellId = requestBody.sellId;
        let transactionId = requestBody.transactionId;
        let url = $global.getApiUrl() + $global.getApiObject().retrieveSellPayment.replace(":sellId", sellId).replace(":transactionId", transactionId);
        return httpService.httpRequest(url, "G", "");
      },
      sendTransactionCode: function (requestBody) {
        let sellId = requestBody;
        let url = $global.getApiUrl() + $global.getApiObject().sendTransactionCode.replace(":sellId", sellId);
        return httpService.httpRequest(url, "G", "");
      },
      verifyTransactionCode: function (requestBody) {
        let sellId = requestBody.sellId;
        let transactionCode = requestBody.transactionCode;
        let password = requestBody.password;
        let url = $global.getApiUrl() + $global.getApiObject().verifyTransactionCode.replace(":sellId", sellId).replace(":transactionCode", transactionCode).replace(":password", password);
        return httpService.httpRequest(url, "P", "");
      },
    };
  });
