'use strict';
/**
 * @ngdoc function
 * @name scrapQApp.controller:AgentSlotCtrl
 * @description
 * # AgentSlotCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AgentSlotCtrl', function($scope, $rootScope, $global, ngToast, agentAppointments, $uibModal, $window, growl, $log, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {
        
        $scope.transactionChange = function() {
            $scope.vm.params.page = -1;
            $scope.vm.params.date = $moment($scope.dateInput).format('DD-MMM-YYYY');

            var params = {}
            params.page = -1;
            params.date = $scope.dateInput;
            params.city= "Hyderabad";
        };

        $scope.$watch('dateInput', function(newDate) {
            $scope.transactionChange();
        }, false);

        $scope.nextDate = function() {
            $scope.dateInput = moment($scope.dateInput).add(1, "days").format('DD-MMM-YYYY');
            $scope.getAppointmentsForAgents($scope.dateInput);
        }
        $scope.previousDate = function() {
            $scope.dateInput = moment($scope.dateInput).subtract(1, "days").format('DD-MMM-YYYY');
            $scope.getAppointmentsForAgents($scope.dateInput);
        }

        $scope.dateInput = $moment().format('DD-MMM-YYYY');
        $scope.date = {
            startDate: $scope.dateInput,
            endDate: $scope.dateInput
        };
        $scope.opts = {
            format: "DD-MMM-YYYY",
            singleDatePicker: true
        };

        $scope.vm = {
            params :{}
        };

        $scope.isCountAvailable = true;
        $scope.getAppointmentsForAgents = function() {
            agentAppointments.agentAppointmentsPerSlot($scope.dateInput).then(function(res){
                if (res.status == "SUCCESS") {
                    if (res.data && res.data.appointments.length > 0) {
                        $scope.isCountAvailable = true;
                        $scope.resArr = res.data.appointments;
                        $scope.slotCount = [];
                        $scope.agents = [];
                        $scope.resArr.forEach(function(element,index){
                            if (element != null) {
                                if (($scope.agents).includes(element.name)) {
                                    var index = ($scope.agents).indexOf(element.name);
                                    $scope.slotCount[index].slots[element.preferredslot] = element.agentSlotCount;
                                } else {
                                    var slotsArr = [0,0,0,0,0,0]
                                    slotsArr[element.preferredslot] = element.agentSlotCount;
                                    var temp = {"agentName": element.name,"slots":slotsArr};
                                    ($scope.slotCount).push(temp);
                                    ($scope.agents).push(element.name);
                                }
                            }
                        });
                    } else {
                        $scope.isCountAvailable = false;
                    }
                } else {
                    $scope.isCountAvailable = false;
                }
            })
        }

        $scope.getAppointmentsForAgents($scope.dateInput);
    });
