'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.wholesalerPickups
 * @description
 * # wholesalerPickups
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('wholesalerService', function(httpService, $global, $http, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      getWholesalerpickups: function() {
        var url = $global.getApiUrl() + $global.getApiObject().wholesalersPickups;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getWholesaler: function(type, key) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getWholesaler.replace(":userType", type).replace(":text", key).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      usersLoad: function(type, page, nodecrement,priceCapsuleId) {
        var url;
        var city = $rootScope.loginData.city;
         // if page == -1, the api fetches all the records
        if (page && page != '-1') {
          if (!nodecrement) {
            page = page - 1;
          }
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
        }else if(page == '-1') {
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
        } else {
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city);
        }
        if(priceCapsuleId){
          url += "&priceCapsuleId=" + priceCapsuleId;
        }
        
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      usersLoadAll: function(type) {
        var url;
        var city = $rootScope.loginData.city;
        url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + "-1";
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getProfile: function(wholesalerId) {
        var wholesalerId = wholesalerId;
        var url = $global.getApiUrl() + $global.getApiObject().getWholesalerProfile.replace(":aid", wholesalerId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellRquests: function(wholesalerId) {
        var params = "?" + $global.objToQueryString(wholesalerId);
        var url = $global.getApiUrl() + $global.getApiObject().getSellRquests + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getAgentsForWholesaler: function(wholesalerId, page) {
        let params = {};
        if (page) {
          page = page - 1;
          params.page = page;
        }
        params.city = $rootScope.loginData.city;
        params = "?" + $global.objToQueryString(params);
        let url = $global.getApiUrl() + $global.getApiObject().agentsLoadForWholesaler.replace(":wholesalerId", wholesalerId) + params;
        return httpService.httpRequest(url, "G");
      }, getWholesalerCompletedAppointments: function(wholesalerId, page) {
        let params = {};
        params.status = "COMPLETED";
        params.wholesalerid = wholesalerId;
        if (page) {
          page = page - 1;
          params.page = page;
        }
        params = "?" + $global.objToQueryString(params);
        let url = $global.getApiUrl() + $global.getApiObject().completedAppointmentsForWholesaler + params;
        return httpService.httpRequest(url, "G");
      },
      getSellById: function(id) {
        var url = $global.getApiUrl() + $global.getApiObject().getSellById.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      addPaymentInfo: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().addPaymentInfo;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getInventory: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().wholesalerInventory.replace(":wholesalerId", data.wholesalerId).replace(":fromDate", data.fromDate).replace(":toDate", data.toDate);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getWholesalerLocation: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getWholesalerLocation.replace(":wholesalerId", data.wholesalerId).replace(":city", data.city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getWholesalerPayments: function(wholesalerId) {
        var url = $global.getApiUrl() + $global.getApiObject().getWholesalerPayments.replace(":wholesalerId", wholesalerId);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getWholesalerPriceCapsules: function() {
      var url = $global.getApiUrl() + $global.getApiObject().getUserPriceCapsules.replace(":userType", "WHOLESALER").replace(":city", $rootScope.loginData.city);
      var $request = httpService.httpRequest(url, "G");
      return $request;
    },
    getOrgChartData: function(id){
      var city = $rootScope.loginData.city;
      var url=$global.getApiUrl()+$global.getApiObject().getOrgChart.replace(":city",city).replace(":bool",true);
      if(id){
        url=url.replace(":id",id);
      } else {
        url=url.replace(":id",'SCRAPQ');
      }
      var $request = httpService.httpRequest(url, "G");
      return $request;
    },
    addDocument: function(data) {
      var url = $global.getApiUrl() + $global.getApiObject().addWholesalerDocument;
      var $request = httpService.httpRequest(url, "P", data);
      return $request;
    },
    downloadDocument: function(id) {
      var url = $global.getApiUrl() + $global.getApiObject().getFileById.replace(":id", id);
      window.open(url, "_blank");
    },
    getSalesDetails: function () {
      return $rootScope.vendorDetails;
    },
    setSalesDetails: function (details) {
      console.log('details: ',details);
      $rootScope.vendorDetails = details;
    },
    };
  });
