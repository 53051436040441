'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ConsumersCtrl
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('ReportingCtrl', function ($scope, $rootScope, $global, $stateParams, $http, $state, dashboard, $uibModal, $log, consumer, growl, BidService, DTOptionsBuilder, DTColumnDefBuilder, NgMap, $moment, agentService, fCsv) {
        //Global scope
        var mapOptions = {
            zoom: 12,
            center: new google.maps.LatLng(17.4091101, 78.4987697),
            mapTypeId: 'roadmap',
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        }, searchData = {}, consumers = [], acquisitionConsumers = [], appStats = [];
        $scope.category = null;
        $scope.heatmapSelected = true;
        $scope.markerClusterSelected = false;

        var interestedGradient = [
            'rgba(255, 165, 0, 0)',
            'rgba(255, 165, 0, 1)',
            'rgba(255, 165, 0, 0.95)',
            'rgba(255, 165, 0, 0.92)',
            'rgba(255, 165, 0, 0.90)',
            'rgba(255, 165, 0, 0.85)',
            'rgba(255, 165, 0, 0.75)',
            'rgba(255, 165, 0, 0.50)',
            'rgba(255, 255, 255, 1)',
            'rgba(255, 255, 255, 0.90)',
            'rgba(255, 255, 255, 0.60)',
            'rgba(255, 193, 7, 0.50)',
            'rgba(255, 193, 7, 1)'
        ];

        var notInterestedGradient = [
            'rgba(2, 172, 249, 0)',
            'rgba(31, 31, 241, 1)',
            'rgba(55, 55, 243, 1)',
            'rgba(73, 73, 239, 1)',
            'rgba(107, 107, 249, 1)',
            'rgba(122, 122, 247, 1)',
            'rgba(144, 144, 251, 1)',
            'rgba(161, 161, 245, 1)',
            'rgba(113, 113, 183, 1)',
            'rgba(86, 86, 154, 1)',
            'rgba(62, 62, 121, 1)',
            'rgba(66, 66, 107, 1)',
            'rgba(74, 74, 101, 1)'
        ];

        //Load app stats
        var loadAppStats = function () {
            var dateString = "";
            $scope.noAppStats = false;
            if (Number($scope.selectedMonth) < 10) {
                dateString += new Date().getFullYear().toString() + "0" + $scope.selectedMonth;
            } else {
                dateString += new Date().getFullYear().toString() + $scope.selectedMonth;
            }
            consumer.installStatistic(dateString).then(function (res) {
                if (res) {
                    appStats = JSON.parse(fCsv.toJson(res));
                    loadBarGraph(appStats);
                    loadLineGraph(appStats);
                } else {
                    $scope.noAppStats = true;
                }
            }, function (err) {
                $scope.noAppStats = true;
            });
        }

        //Bar graph
        function loadBarGraph(appStats) {
            $scope.totalUninstalls = 0;
            $scope.chartBar = {
                "labels": [],
                "data": [],
                "series": ['Daily Device Installs', 'Daily Device Uninstalls'],
                "colors": ['#A0B421', '#FF0000'],
                "options": {
                    "legend": {
                        "display": true
                    }
                },
                "datasetOverride": [
                    {
                        fill: true,
                        backgroundColor: []
                    },
                    {
                        fill: true,
                        backgroundColor: []
                    }
                ]
            };
            var data_One = [], data_Two = [];
            for (var i in appStats) {
                $scope.chartBar.labels.push(appStats[i]["Date"]);
                data_One.push(appStats[i]["Daily Device Installs"]);
                data_Two.push(appStats[i]["Daily Device Uninstalls"]);
                $scope.chartBar.datasetOverride[0].backgroundColor.push("#A0B421");
                $scope.chartBar.datasetOverride[1].backgroundColor.push("#FF0000");
                $scope.totalUninstalls += Number(appStats[i]["Daily Device Uninstalls"]);
            }
            $scope.chartBar.data.push(data_One);
            $scope.chartBar.data.push(data_Two);
        }

        //Selected month rule
        $scope.selectedMonth = new Date().getMonth().toString();

        //Month list
        $scope.monthList = [
            {
                "name": "January",
                "value": 1
            },
            {
                "name": "February",
                "value": 2
            },
            {
                "name": "March",
                "value": 3
            },
            {
                "name": "April",
                "value": 4
            },
            {
                "name": "May",
                "value": 5
            },
            {
                "name": "June",
                "value": 6
            },
            {
                "name": "July",
                "value": 7
            },
            {
                "name": "August",
                "value": 8
            },
            {
                "name": "September",
                "value": 9
            },
            {
                "name": "October",
                "value": 10
            },
            {
                "name": "November",
                "value": 11
            },
            {
                "name": "December",
                "value": 12
            }
        ];

        //Month change
        $scope.monthChange = function () {
            loadAppStats();
        }

        //Line graph
        function loadLineGraph(appStats) {
            $scope.chartLine = {
                "labels": [],
                "data": [],
                "series": ['Total User Installs', 'Active Device Installs'],
                "colors": ['#A0B421', '#F0AB05'],
                "options": {
                    "legend": {
                        "display": true
                    }
                },
                "datasetOverride": [
                    {
                        fill: false,
                        backgroundColor: []
                    },
                    {
                        fill: false,
                        backgroundColor: []
                    }
                ]
            };
            var data_One = [], data_Two = [];
            for (var i in appStats) {
                $scope.chartLine.labels.push(appStats[i]["Date"]);
                data_One.push(appStats[i]["Total User Installs"]);
                data_Two.push(appStats[i]["Active Device Installs"]);
            }
            $scope.totalUserInstalls = appStats[appStats.length - 1]["Total User Installs"];
            $scope.activeDeviceInstalls = appStats[appStats.length - 1]["Active Device Installs"];
            $scope.chartLine.data.push(data_One);
            $scope.chartLine.data.push(data_Two);
        }

        //Load statistic
        var loadStatistic = function () {
            agentService.loadStatistic(searchData).then(function (res) {
                $scope.noStatisticFound = false;
                $scope.statisticFound = true;
                if (res.status == "Success") {
                    if (res.data.length > 0) {
                        $scope.statistic = res.data;
                    } else {
                        $scope.noStatisticFound = true;
                        $scope.statisticFound = false;
                    }
                } else {
                    $scope.noStatisticFound = true;
                    $scope.statisticFound = false;
                }
            }, function (err) {
                $scope.noStatisticFound = true;
                $scope.statisticFound = false;
            });
        }

        $scope.date = {
            startDate: null,
            endDate: null
        };

        //Date picker configaration
        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        //Watch for date changes
        $scope.$watch('date', function () {
            var startDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
            var endDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            if (!isNaN(new Date(startDate).getTime())) {
                searchData.fromDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
            }
            if (!isNaN(new Date(endDate).getTime())) {
                searchData.toDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
            }
            if (searchData.fromDate && searchData.toDate) {
                loadStatistic();
            }
        }, false);

        $scope.callDate = function () {
            if (!$scope.date.startDate && !$scope.date.endDate) {
                $scope.date = {
                    startDate: $moment(new Date(), 'DD-MMM-YYYY').toDate(),
                    endDate: $moment(new Date(), 'DD-MMM-YYYY').toDate()
                }
                angular.forEach(angular.element(".ranges > ul > li"), function (value, key) {
                    var a = angular.element(value);
                    var dateRangKey = value.getAttribute("data-range-key");
                    if (dateRangKey == "Today") {
                        a.addClass("active");
                    } else {
                        a.removeClass("active");
                    }
                });
            }
        }

        // Map code
        function initMapForHeatmap(data) {
            let sumLat = 0, sumLng = 0, n = 0;
            if (data && data.consumers && data.consumers.length > 0) {
                let arr = data["consumers"] ? data["consumers"] : null;
                if (arr && arr.length > 0) {
                    for (let j = 0; j < arr.length; j++) {
                        sumLat += arr[j].lat();
                        sumLng += arr[j].lng();
                        n++;
                    }
                }
            }
            if (n > 0) {
                mapOptions.center = new google.maps.LatLng(sumLat / n, sumLng / n);
            }

            var map = new google.maps.Map(document.getElementById('reportmap'), mapOptions);

            var heatmapForRegisterUser = new google.maps.visualization.HeatmapLayer({
                data: data.consumers
            });
            var heatmapForAcquisitionInterested = new google.maps.visualization.HeatmapLayer({
                data: data.acquisitionInterested
            });
            var heatmapForAcquisitionNotInterested = new google.maps.visualization.HeatmapLayer({
                data: data.acquisitionNotInterested
            });
            //For register user
            heatmapForRegisterUser.setMap(map);
            heatmapForRegisterUser.set('radius', heatmapForRegisterUser.get('radius') ? null : 20);
            //For acquisition interested
            heatmapForAcquisitionInterested.setMap(map);
            heatmapForAcquisitionInterested.set('radius', heatmapForRegisterUser.get('radius') ? null : 20);
            heatmapForAcquisitionInterested.set('gradient', heatmapForAcquisitionInterested.get('gradient') ? null : interestedGradient);
            //For acquisition not interested
            heatmapForAcquisitionNotInterested.setMap(map);
            heatmapForAcquisitionNotInterested.set('radius', heatmapForRegisterUser.get('radius') ? null : 20);
            heatmapForAcquisitionNotInterested.set('gradient', heatmapForAcquisitionNotInterested.get('gradient') ? null : notInterestedGradient);
        }

        //Map for marker cluster
        function initMapForMarkerCluster(locations) {
            let sumLat = 0, sumLng = 0, n = 0;
            if (locations && locations.length > 0) {
                for (let j = 0; j < locations.length; j++) {
                    sumLat += locations[j].lat;
                    sumLng += locations[j].lng;
                    n++;
                }
            }
            if (n > 0) {
                mapOptions.center = new google.maps.LatLng(sumLat / n, sumLng / n);
            }
            var map = new google.maps.Map(document.getElementById('reportmap'), mapOptions);
            var markers = locations.map(function (location, i) {
                return new google.maps.Marker({
                    position: location
                });
            });
            // Add a marker clusterer to manage the markers.
            var markerCluster = new MarkerClusterer(map, markers,
                {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                }
            );
        }

        //Get consumers
        function getConsumers() {
            async.waterfall([
                function (cb) {
                    consumer.allUsersLoad("CONSUMER").then(function (res) {
                        if (res.status == "SUCCESS") {
                            consumers = res.data.users;
                        }
                        cb();
                    }, function (err) {
                        cb();
                    });
                },
                function (cb) {
                    var data = {
                        "status": "ALL",
                        "isApproved": false
                    };
                    consumer.loadConsumer(data).then(function (res) {
                        if (res.status == "Success") {
                            acquisitionConsumers = res.data;
                        }
                        cb();
                    }, function (err) {
                        cb();
                    });
                }
            ], function (err, res) {
                $scope.category = $scope.categoriesList[0];
            });
        }

        //Init with
        function initWith(type) {
            if (type && type == "Acquisition Statistics") {
                $scope.isAcquisitionStatistic = true;
                $scope.pageTitle = type;
                loadStatistic();
            } else if (type && type == "App Stats") {
                $scope.pageTitle = type;
                $scope.appStats = true;
                loadAppStats();
            } else {
                $scope.isConsumers = true;
                $scope.pageTitle = "Consumers";
                $rootScope.loginData = $global.getLocalItem("loginData", true);
                $scope.categories = [];
                consumer.userCategories().then(function (res) {
                    if (res.status == "SUCCESS") {
                        $scope.categoriesList = res.data.categories;
                        getConsumers();
                    } else if (res.status == "FAILURE") {
                        growl.error(res.error.message)
                    }
                });
            }
        }

        //Render map
        function renderMap() {
            var tempConsumers = [], locations = [], tempAcquisitionConsumers = [],
                data = {
                    "consumers": [],
                    "acquisitionInterested": [],
                    "acquisitionNotInterested": []
                };

            $scope.addressType = $scope.category.name;
            async.waterfall([
                function (cb) {
                    async.forEachOf(consumers, function (val, key, Cb) {
                        if (val.address && (val.address.addressType == $scope.addressType)) {
                            tempConsumers.push(val);
                        }
                        Cb();
                    }, function (err) {
                        cb();
                    });
                },
                function (cb) {
                    async.forEachOf(acquisitionConsumers, function (val, key, Cb) {
                        if (val.address && (val.categories.value == $scope.addressType)) {
                            tempAcquisitionConsumers.push(val);
                        }
                        Cb();
                    }, function (err) {
                        cb();
                    });
                },
                function (cb) {
                    async.series([
                        function (CB) {
                            async.forEachOf(tempConsumers, function (val, key, Cb) {
                                if (val && val.address && val.address.latitude && val.address.longitude) {
                                    data.consumers.push(new google.maps.LatLng(val.address.latitude, val.address.longitude));
                                    locations.push({ "lat": Number(val.address.latitude), "lng": Number(val.address.longitude) });
                                }
                                Cb();
                            }, function (err) {
                                CB();
                            })
                        },
                        function (CB) {
                            async.forEachOf(tempAcquisitionConsumers, function (val, key, Cb) {
                                if (val && val.address && val.address.latitude && val.address.longitude) {
                                    if (val.status == "INTERESTED") {
                                        data.acquisitionInterested.push(new google.maps.LatLng(val.address.latitude, val.address.longitude));
                                    } else if (val.status == "NOTINTERESTED") {
                                        data.acquisitionNotInterested.push(new google.maps.LatLng(val.address.latitude, val.address.longitude));
                                    }
                                }
                                Cb();
                            }, function (err) {
                                CB();
                            });
                        }
                    ], function (err, res) {
                        cb();
                    })
                }
            ], function (err, res) {
                if ($scope.heatmapSelected) {
                    $scope.totalNumberOfUser = data.consumers.length;
                    initMapForHeatmap(data);
                }
                if ($scope.markerClusterSelected) {
                    $scope.totalNumberOfUser = locations.length;
                    initMapForMarkerCluster(locations);
                }
            });
        }

        //Category watch
        $scope.$watch('category', function () {
            if ($scope.category) {
                renderMap();
            }
        }, false);

        //Change map type
        $scope.selectedMapType = function (type) {
            if (type == "HEATMAP") {
                $scope.heatmapSelected = true;
                $scope.markerClusterSelected = false;
                renderMap();
            }
            if (type == "MARKERCLUSTER") {
                $scope.markerClusterSelected = true;
                $scope.heatmapSelected = false;
                renderMap();
            }
        }

        //First checker
        function init() {
            $scope.params = $stateParams;
            if ($stateParams && ($stateParams.type == "Acquisition Statistics" || $stateParams.type == "App Stats")) {
                initWith($stateParams.type);
            } else {
                initWith();
            }
        }
        init();
    });
