'use strict';

/**
 */
angular.module('scrapQcrmApp')
    .controller('ViewGraphController', function ($scope, $uibModalInstance, $sce, data) {

        $scope.pieoptions = {
            legend: {
              display: false,
              labels: {
                boxWidth: 15,
                fontSize: 11,
              }
            }
          };
      
          $scope.baroptions = {
            legend: {
              display: false,
              labels: {
                boxWidth: 15,
                fontSize: 11,
              }
            },
            scales: {
              xAxes: [{
                display: true,
                ticks: {
                  mirror: true,
                  padding: 0,
                  fontSize: 12,
                  length: 15,
                },
                gridLines: {
                  display: false
                }
              }],
              yAxes: [{
                display: true,
                gridLines: {
                  display: false
                }
              }],
              gridLines: {
                display: false
              }
            },
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItems, data) {
                  return Math.round(tooltipItems.yLabel * 100) / 100;
                }
              }
            }
          };
      
          $scope.colordictionary = {
            "ASSIGNED TO AGENT": "#FFFF00",
            'ACCEPTED BY AGENT': "#0000FF",
            'REQUEST COMPLETED': "#008000",
            'CANCELLED BY CONSUMER': "#000000",
            "DECLINED BY AGENT": "#FF0000",
            "REQUEST INITIATED": "#FFA500",
            "NO APPOINTMENTS": "#EEEEEE",
          }
      
          $scope.barcolors = [];
      
          (function (ChartJsProvider) {
            ChartJsProvider.setOptions({ colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
          });
          
        $scope.data = data;

        if ($scope.data.type=="pie") {
          $scope.title = "Appointments Graph - " + $scope.data.name;
        } else {
          $scope.title = "Products Graph - " + $scope.data.name;
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        }
    });
