'use strict';

angular.module('scrapQcrmApp')
  .controller('WholesalersCtrl', function ($scope, $rootScope, area, $global, $timeout, $uibModal, $window, documents, $state, SellNow, growl, $log, wholesalerService, agentService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {

    $global.displayLog('Inside wholesaler.js');

    /*==============================================Global variables=====================================================*/
    var mapOptions;
    $scope.labelCounts = {
      totalPartners: 0,
      activePartners: 0,
      inActivePartners: 0
    };
    /*==============================================Default init variables=====================================================*/
    $scope.resetLabelCounts = function () {
      $scope.labelCounts = {
        totalPartners: 0,
        activePartners: 0,
        inActivePartners: 0
      };
    };
    (function () {
      $scope.pages = -1;
      $scope.vm = { params: {} };
      $scope.currentPage = 1;

      console.log("How many times is this loading???");

      $rootScope.loginData = {};
      $rootScope.loginData = $global.getLocalItem("loginData", true);

      $scope.customIcon = {
        "scaledSize": [32, 32],
        "url": "http://www.cliparthut.com/clip-arts/823/arrowhead-clip-art-823528.png"
      };

      // Location track map operation start
      mapOptions = {
        zoom: 17,
        mapTypeId: 'roadmap'
      };

      if ($stateParams.priceCapsuleId) {
        $scope.priceCapsuleId = $stateParams.priceCapsuleId;
        // $scope.loadWholesalers({});
      }

      // ag-grid init
      var columnDefs = [
        {
          headerName: "Name",
          field: "name",
        },
        {
          headerName: "Mobile Number",
          cellRenderer: HideMobileNumber,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Inception Date",
          field: "createdOn",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Price Capsule",
          field: "priceCapsule.name",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Area",
          field: "area",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Latitude",
          field: "address.latitude",
          hide: true
        },
        {
          headerName: "Longitude",
          field: "address.longitude",
          hide: true
        }
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        // paginationAutoPageSize: true,
        rowData: rowData,
        // suppressPaginationPanel:true,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          params.api.sizeColumnsToFit();
          params.columnApi.autoSizeAllColumns();
          $scope.gridOptions.api.setRowData($scope.wholesaler_list);
        },
        onCellClicked: function (event) {
          $scope.wholesalerDetails(event.data.userId);
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };

    })();

    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    function HideMobileNumber() {
    }
    HideMobileNumber.prototype.init = function (params) {
      // console.log("where is mobile no.",params.data);
      this.mobileNumberDiv = document.createElement('div');
      if (params.data != "" || params.data != "undefined") {
        if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
          let mobileNo = params.data.mobile;
          this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        } else {
          let mobileNo = params.data.mobile;
          let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
          this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        }
      } else {
        this.mobileNumberDiv.innerHTML = "";
      }
    }

    HideMobileNumber.prototype.getGui = function () {
      return this.mobileNumberDiv;
    }

    var paymentsColumnDefs = [
      {
        headerName: "Date",
        field: "date",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Invoice #",
        field: "invoiceid",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Payment Type",
        field: "paymentType",
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: "Amount",
        field: "amount",
        cellStyle: { textAlign: 'right' },
      }
    ];
    $scope.paymentsGridOptions = {
      columnDefs: paymentsColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.paymentsGridOptions && $scope.paymentsGridOptions.api) {
            $scope.paymentsGridOptions.api.setRowData($scope.wholesalerPaymentsVM);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    // Billed Transactions Ag-Grid
    var billedTransactionColDefs = [
      {
        headerName: "Conf. Id",
        field: "confirmationId",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Date",
        field: "preferredDate",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Slot",
        field: "preferredSlot",
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: "Consumer Name",
        field: "consumerName",
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: "Partner Price",
        field: "totalAmount",
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: "REO Commission",
        field: "amount",
        cellStyle: { textAlign: 'right' },
      }, {
        headerName: "Type",
        field: "amount",
        cellStyle: { textAlign: 'left' },
        valueFormatter: params => {
          if (params.value === '') {
            return 'DEBIT'
          }
        }
      }
    ];

    $scope.billedTransactionsGridOptions = {
      columnDefs: billedTransactionColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.billedTransactionsGridOptions && $scope.billedTransactionsGridOptions.api) {
            $scope.billedTransactionsGridOptions.api.setRowData($scope.todayAppointment);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.viewAppointment(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    //Payout History Ag-Grid
    var payoutHistoryColDefs = [
      {
        headerName: "Date",
        field: "confirmationId",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Amount",
        field: "preferredDate",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Status",
        field: "preferredSlot",
        cellStyle: { textAlign: 'left' },
      }

    ];

    $scope.payoutHistoryGridOptions = {
      columnDefs: payoutHistoryColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.payoutHistoryGridOptions && $scope.payoutHistoryGridOptions.api) {
            $scope.payoutHistoryGridOptions.api.setRowData($scope.todayAppointment);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    //Agents Ag-Grid
    var partnerAgentsColDefs = [
      {
        headerName: "Name",
        field: "name",
        cellStyle: { textAlign: 'left' }
      },
      {
        headerName: "Mobile",
        field: "mobile",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Inception Date",
        field: "createdOn",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "Area",
        field: "area",
        cellStyle: { textAlign: 'left' },
      }
    ];

    $scope.partnerAgentsGridOptions = {
      columnDefs: partnerAgentsColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.partnerAgentsGridOptions && $scope.partnerAgentsGridOptions.api) {
            $scope.partnerAgentsGridOptions.api.setRowData($scope.agentListUnderThisWholesaler);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    //Documents Ag-Grid
    var documentsColDefs = [
      {
        headerName: "Document Type",
        field: "name",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "",
        field: "download",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionCellRenderer,
        maxWidth: 70,
      },
      {
        headerName: "",
        field: "delete",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionDeleteCellRenderer,
        maxWidth: 70,
      }
    ];

    $scope.userDocuments = [];

    $scope.documentsGridOptions = {
      columnDefs: documentsColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.documentsGridOptions && $scope.documentsGridOptions.api) {
            $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        console.log(event)
        if (event.column.colId == "delete") {
          $scope.deleteDoc(event.data);
        }
        if (event.column.colId == "download") {
          $scope.downloadDoc(event.data.url);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    //Price Capsule Ag-Grid
    var priceCapsuleColDef = [
      {
        headerName: "Items",
        field: "productName",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Type",
        field: "priceType",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 1",
        field: "tier1",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 2",
        field: "tier2",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 3",
        field: "tier3",
        cellStyle: { textAlign: 'center' },

      }
    ];

    $scope.priceCapsuleGridOptions = {
      columnDefs: priceCapsuleColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.priceCapsuleGridOptions && $scope.priceCapsuleGridOptions.api) {
            $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        // $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    function ActionCellRenderer() {
    }

    ActionCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-download-alt"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function ActionDeleteCellRenderer() {
    }

    ActionDeleteCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-trash"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionDeleteCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    /*===============================================Core functions================================================*/
    $scope.searchWholesaler = function (searchString) {
      searchString = searchString.trim();
      if (searchString) {
        var userType = 'WHOLESALER';
        $scope.submitted = true;
        $scope.paging = true;
        $scope.transactionStatus = "pending";
        $scope.noWholesalerFound = false;

        if (searchString.length > 3) {
          wholesalerService.getWholesaler(userType, searchString)
            .then(function (res) {
              if (res.status === "SUCCESS") {
                $scope.wholesalers = res.data;
                if (res.data.users.length === 1) {
                  $scope.length0 = true;
                  $scope.wholesalerDetails(res.data.users[0].userId);
                } else {
                  res.data.users.forEach(function (elem, idx) {
                    try {
                      var addrsplit = elem.address.formattedAddress.split(/,+/);
                      res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                    } catch (e) {
                      console.error("Error in converting formatting address into area ", e);
                    }
                  });
                  $scope.length0 = false;
                  $scope.wholesalerId = false;
                  $scope.wholesaler_list = res.data.users;
                }
                if (res.data.message === "No records found") {
                  $scope.noWholesalerFound = true;
                }
              } else if (res.status === "FAILURE") {
                $scope.noWholesalerFound = true;
              } else {
                $scope.noWholesalerFound = true;
              }
            })
            .catch(function (err) {
              console.error("Error in search wholesaler API ", err);
            });
        }
      } else { //load all wholesalers, this gets triggered when we clear the search field
        $scope.loadWholesalers($scope.vm.params);
      }
    };

    $scope.loadWholesalers = function (params) {

      var userType = 'WHOLESALER';
      $scope.submitted = true;
      $scope.paging = false;
      $scope.transactionStatus = "pending";
      $scope.noWholesalerFound = false;

      if (!params || !params.page) {
        params = {};
        params.page = 1; // this is decremented to zero to load all wholesalers in the service layer
      }

      // ag-grid pagination to work we need all records. -1 fetches all records
      params.page = -1;
      wholesalerService.usersLoad(userType, params.page, undefined, $scope.priceCapsuleId)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.wholesalers = res.data;
            $scope.totalAgentRecords = res.data.totalRecords;
            res.data.users.forEach(function (elem, idx) {
              try {
                var addrsplit = elem.address.formattedAddress.split(/,+/);
                res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
              } catch (e) {
                console.error("Error in converting formatting address into area ", e);
              }
            });
            $scope.wholesaler_list = res.data.users;

            $scope.labelCounts = {
              totalPartners: res.data.totalUsers,
              activePartners: res.data.totalActiveUsers,
              inActivePartners: res.data.totalInactiveUsers
            };

            $scope.length0 = false;
            $scope.wholesalerId = false;
            if (res.data.message === "No records found") {
              $scope.noWholesalerFound = true;
            }
            //ag-grid table displays the $scope.wholesaler_list 
            if ($scope.gridOptions && $scope.gridOptions.api) {
              $scope.gridOptions.api.setRowData($scope.wholesaler_list);
            }
          } else if (res.status === "FAILURE") {
            $scope.noWholesalerFound = true;
          } else {
            $scope.noWholesalerFound = true;
          }
        })
        .catch(function (err) {
          $scope.resetLabelCounts();
          console.error("Error in loading the entire wholesaler list ", err);
        })
    };
    (function () {
      if (!($rootScope.loginData.role === "WHOLESALER")) {
        // get wholesaler pricing capsule details
        getAllWholesalerPriceCapsulesPromises()
          .then(function (res) {
            // logger
            $global.displayLog('getAllWholesalerPriceCapsulesPromises', 'res', res);
            // set view model
            $scope.allPriceCapsuleDetails = res.data.results;
            if ($stateParams.wholesalerName) {
              $scope.searchWholesaler($stateParams.wholesalerName);
            }
          })
          .catch(function (err) {
            console.error(err);
            if ($stateParams.wholesalerName) {
              $scope.searchWholesaler($stateParams.wholesalerName);
            }
          })
      }
    })();

    (function () {
      if ($rootScope.loginData.role === "CRMUSER" || $rootScope.loginData.role === "ADMIN" || $rootScope.loginData.role === "MASTERFRANCHISE") {
        $scope.loadWholesalers($scope.vm.params);
      } else if ($rootScope.loginData.role === "WHOLESALER") {
        // manipulating sections of wholesalers.html
        $scope.length0 = true;
        $scope.wholesaler_list = false;
        // get wholesaler pricing capsule details
        getAllWholesalerPriceCapsulesPromises()
          .then(function (res) {
            // logger
            $global.displayLog('getAllWholesalerPriceCapsulesPromises', 'res', res);
            // set view model
            $scope.allPriceCapsuleDetails = res.data.results;
            if ($stateParams.wholesalerName) {
              $scope.searchWholesaler($stateParams.wholesalerName);
            }
            $scope.wholesalerId = $rootScope.loginData.userid;

            initDataForWholesalerProfileFunctions();
            initMapRenderingFunctions();
          })
          .catch(function (err) {
            console.error(err);
            if ($stateParams.wholesalerName) {
              $scope.searchWholesaler($stateParams.wholesalerName);
            }
          })
      } else {
        console.error("Login role is neither of CRMUSER, ADMIN nor WHOLESALER ");
      }
    })();

    $scope.wholesalerDetails = function (id) {
      $scope.length0 = true;
      $scope.registrationId = id;
      $scope.wholesalerId = id;
      $scope.wholesaler_list = false;
      $scope.vm.params = {
        "wholesaler": id,
        "size": 10,
        "page": 0,
        "status": "pending"
      };

      initDataForWholesalerProfileFunctions();
      initMapRenderingFunctions();
      buildChart();
    };

    function initDataForWholesalerProfileFunctions() {
      getWholesalerProfile($scope.wholesalerId);
      getWholesalerCompletedAppointments($scope.wholesalerId);
    }

    function initMapRenderingFunctions() {
      getWholesalerAgents($scope.wholesalerId)
        .then(function (res) {
          return initMapWithoutCenter(); //map init function
        })
        .then(function (res) {
          return renderWithAgentMarkerData($scope.markerData); //map function
        })
        .then(function (res) {
          return getWholesalerCurrentLocation($scope.wholesalerId); //map function
        })
        .then(function (res) {
          return locationOperationForWholesaler(res); //map function
        })
        .then(function (res) {
          return renderAgentPosition(res); //map function
        })
        .catch(function (err) {
          console.error("Error ", err);
        });
    }

    $scope.generateInvoice = function (wholesalerId) {
      alert("Start PDF invoice download")
    };

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withDisplayLength($scope.length);

    $scope.pageChanged = function (currentPage) {
      let params = {};
      params.page = currentPage;
      $scope.loadWholesalers(params);
    };

    $scope.transactionChange = function (status) {
      $scope.currentPage = 1;
      $scope.vm.params.page = 0;
      $scope.vm.params.status = status;
      getAgentAppointments($scope.vm.params); //TODO no function defined to call this
    };

    /*===============================================Open modal functions================================================*/
    $scope.addWholesalers = function () {

      $state.go("root.add-reo", { type: "WHOLESALER", fromPage: "root.wholesalers" });

      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/add-user.html',
      //   controller: 'AddUserCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return 'WHOLESALER';
      //     }
      //   }
      // });

      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   $scope.loadWholesalers($scope.vm.params);
      // });
    };

    $scope.disableWholesaler = function () {
      if (confirm(`Are you sure you want to disable ${$scope.profileEdit.name}?`)) {
        var mobile = $scope.profileEdit.mobileNo;
        var updatedata = {
          "id": mobile
        };
        agentService.disableAgent(updatedata).then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success('Partner disabled successfully');
            $timeout(function () {
              $scope.loadWholesalers($scope.vm.params);
            }, 1500);
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message);
          } else {
            $scope.loadWholesalers($scope.vm.params);
          }
        }, function (err) {
          console.error('Error in disabling partner API ', err);
          growl.error('Failed to disable partner');
        })
      }
    };

    $scope.addPayment = function (wholesalerId) {
      console.log(wholesalerId)
      $scope.wholesalerId = wholesalerId;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-payment.html',
        controller: 'AddPaymentCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return wholesalerId;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        getWholesalerProfile($scope.wholesalerId);
      });
    };
    $scope.showPricerate = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/wholesaler-prices.html',
        controller: 'PriceCtrl',
        size: "md",
        resolve: {
          items: function () {
            return;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
      });
    };

    $scope.editWholesalerDetails = function () {
      $state.go("root.edit-partner", { id: $scope.profileEdit.wholesalerId });
      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/wholesaler-edit-profile.html',
      //   controller: 'WholesalerEditProfileCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return $scope.profileEdit.wholesalerId;
      //     }
      //   }
      // });

      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   getWholesalerProfile($scope.wholesalerId);
      // });
    };

    $scope.viewAppointment = function (view) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/wholesaler-today-appointments.html',
        controller: 'WholesalerTodayAppointmentsCtrl',
        size: "lg",
        resolve: {
          items: function () {

            return view;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
      });
    };

    $scope.upcomingAppointments = function (upview) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/wholesaler-upcoming-appointments.html',
        controller: 'WholesalerUpcomingAppointmentsCtrl',
        size: "md",
        resolve: {
          items: function () {
            return upview;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
      });
    };

    $scope.wholesalerBid = function (bid) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/wholesaler-bids.html',
        controller: 'WholesalerBidsCtrl',
        size: "md",
        resolve: {
          items: function () {
            return bid;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
      });
    };

    //FIXME we don't even use this function for the wholesaler module
    $scope.updateDetails = function (id) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-items.html',
        controller: 'AddItemsCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return id;
          }
        }
      });

      modalInstance.result.then(function (data) {
        getWholesalerAgents($scope.vm.params)
          .then(function (res) {
            return renderWithAgentMarkerData(res);
          })
          .catch(function (err) {
            console.error("Error ", err);
          });
      }, function () {
      });
    };

    //FIXME this is not being called anywhere in wholesalers.html
    $scope.rescheduleConfirmation = function (sell, index) {
      var reschedule = {
        'sellObjId': sell.sellObjId,
        'wholesalerObjId': sell.wholesalerObjId,
      }
      // $scope.showRescheduleModal(sell.preferredSlot);
      var reschedule = $uibModal.open({
        templateUrl: 'views/reschedule-modal.html',
        controller: "ReschduleCtrl",
        size: "md",
        resolve: {
          items: function () {
            return reschedule;
          }
        }
      });
      reschedule.result.then(function (selectedItem) {
        getWholesalerAgents($scope.vm.params)
          .then(function (res) {
            return renderWithAgentMarkerData(res);
          })
          .catch(function (err) {
            console.error("Error ", err);
          });
      }, function () {

      });

    };

    //FIXME this is not being called anywhere in wholesalers.html
    $scope.declineSellItem = function (sell, index) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/deleteConfirmation.html',
        controller: "DeleteCtrl",
        size: "md",
        resolve: {
          items: function () {

            return sell;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        var obj = {
          'sellObjId': sell.sellObjId,
          'wholesalerObjId': sell.wholesalerObjId,
        }
        SellNow.declineRequest(obj).then(function (res) {
          if (res.status == "SUCCESS") {
            $scope.todayAppointment.splice(index, 1);


          }
        })
      }, function () {


      });
    };

    $scope.addAgents = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-user.html',
        controller: 'AddUserCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return 'AGENT';
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $scope.loadAgents();
      });

    };

    /*===============================================Helper functions================================================*/
    function initMapWithoutCenter() {
      return new Promise((resolve, reject) => {
        try {
          $scope.map = new google.maps.Map(document.getElementById('map1'), mapOptions);
          //below 2 funcs - form areas
          getCurrentLocation();
          init();
          resolve($global.status.SUCCESS);
        } catch (err) {
          console.error('Failed to init map ', err);
          reject($global.status.FAILURE);
        }
      });
    }

    function renderAgentPosition(markers) {
      // console.log("Markers", markers);
      return new Promise((resolve, reject) => {
        try {

          if (!markers || markers.length === 0) {
            reject($global.status.FAILURE);
          }

          var bounds = new google.maps.LatLngBounds();
          $scope.map.setTilt(45);
          // Multiple Markers
          // var markers = markers;
          var icon = {
            url: "/images/pulse.gif",
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          };

          // Loop through our array of markers & place each one on the map
          for (var i = 0; i < markers.length; i++) {
            var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
            bounds.extend(position);
            var marker = new MarkerWithLabel({
              animation: google.maps.Animation.DROP,
              position: position,
              map: $scope.map,
              icon: icon,
              labelContent: markers[i][0],
              labelClass: "custom-class-for-label",
              labelAnchor: new google.maps.Point(-30, 20),
              labelInBackground: true

            });
            if (markers[i][3]) {
              var circle = new google.maps.Circle({
                radius: markers[i][3],
                map: $scope.map,
                fillColor: "#a2f9c8",
                fillOpacity: 0.4,
                strokeColor: "#a2f9c8",
                strokeOpacity: 0.8
              });
              circle.bindTo('center', marker, 'position');
            }
          }

          // Automatically center the map fitting all markers on the screen
          $scope.map.fitBounds(bounds);
          // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
          var boundsListener = google.maps.event.addListener(($scope.map), 'bounds_changed', function (event) {
            this.setZoom(14);
            google.maps.event.removeListener(boundsListener);
          });
          resolve($global.status.SUCCESS);
        } catch (err) {
          console.error(err);
          reject($global.status.FAILURE);
        }
      });
    }

    function renderWithAgentMarkerData(markers) {
      console.log("Markers", markers);
      return new Promise((resolve, reject) => {
        try {
          var bounds = new google.maps.LatLngBounds();
          var icon = {
            url: "/images/pulse-red.gif",
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          };
          for (var i = 0; i < markers.length; i++) {
            var position = new google.maps.LatLng(markers[i].latitude, markers[i].longitude);
            bounds.extend(position);
            var label = "<p class='m-0'>" + markers[i].title + "</p>";
            // <p class='m-0'><i class='fa fa-eye'></i> &nbsp;" + "-" + "</p>";
            // label += "<p class='m-0'><i class='fa fa-battery-three-quarters'></i> &nbsp;" + "-" + "% </p>";
            // label += "<p class='m-0'><i class='fa fa-bolt'></i> &nbsp;Charger disconnected" + " </p>";

            var marker = new MarkerWithLabel({
              animation: google.maps.Animation.DROP,
              position: position,
              icon: icon,
              map: $scope.map,
              labelContent: label,
              labelClass: "custom-class-for-old-label",
              labelAnchor: new google.maps.Point(-30, 20),
              labelInBackground: true
            });
          }
          $scope.map.fitBounds(bounds);
          var boundsListener = google.maps.event.addListener(($scope.map), 'bounds_changed', function (event) {
            this.setZoom(14);
            google.maps.event.removeListener(boundsListener);
          });
          resolve($global.status.SUCCESS);
        } catch (err) {
          console.error('Error in renderWithAgentMarkerData() ', err);
          reject($global.status.FAILURE);
        }
      });

    }

    function locationOperationForWholesaler(data) {
      return new Promise((resolve, reject) => {
        try {
          var result = [];
          if (data && data.data && data.data.locations) {
            result = data.data.locations;
          } else {
            reject($global.status.FAILURE);
          }
          var marker = [];

          async.forEachOf(result, function (val, key, cb) {
            var markerArr = [], infoWindowContentArr = [], label = "";
            label = "<p class='m-0'>" + val.agentName + "</p><p class='m-0'><i class='fa fa-eye'></i> &nbsp;" + $moment(new Date(val.timestamp)).format("DD-MMM-YYYY HH:mm") + "</p>";

            // Add battery info
            if (val.batterylevel) {
              label += "<p class='m-0'><i class='fa fa-battery-three-quarters'></i> &nbsp;" + Math.round(val.batterylevel * 100) + "% </p>";
            }

            // Add battery info
            if (val.is_charging) {
              label += "<p class='m-0'><i class='fa fa-bolt'></i> &nbsp;Charger connected" + " </p>";
            }

            markerArr.push(label);
            markerArr.push(val.latitude);
            markerArr.push(val.longitude);
            markerArr.push(val.accuracy);
            markerArr.push(val);
            marker.push(markerArr);
            cb();
          }, function (err) {
            if (err) {
              console.error('Error in looping through result of locationOperationForWholesaler() ', err);
              reject($global.status.FAILURE);
            } else {
              resolve(marker);
            }
          })
        } catch (err) {
          console.error(err);
          reject($global.status.FAILURE);
        }
      })
    }

    function getWholesalerCurrentLocation(wholesalerId) {
      return new Promise((resolve, reject) => {
        try {
          var data = {};
          data.wholesalerId = wholesalerId;
          wholesalerService.getWholesalerLocation(data)
            .then(function (res) {
              if (res.status === "SUCCESS") {
                if (res && res.data && res.data.locations && res.data.locations.length > 0) {
                  resolve(res);
                } else {
                  reject($global.status.FAILURE);
                }
              } else {
                console.warn("Error/Empty in getWholesalerCurrentLocation ");
                reject($global.status.FAILURE);
              }
            })
            .catch(function (err) {
              console.error("Error in getWholesalerCurrentLocation ", err);
              reject($global.status.FAILURE);
            });
        } catch (err) {
          console.error(err);
          reject($global.status.FAILURE);
        }
      })
    };

    $scope.getUserDocuments = function (userId) {
      $scope.userDocuments = [];
      documents.retrieveUserDocuments({ userId: userId }).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.userDocuments = res.data.documents;
          $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
        }
      })
    }

    function getWholesalerProfile(wholesalerId) {
      $scope.getUserDocuments(wholesalerId)
      wholesalerService.getProfile(wholesalerId)
        .then(function (res) {
          // console.log("res", res)
          if (res.status === "SUCCESS") {
            $scope.profileEdit = res.data.wholesaler;
            $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
            // $scope.documentsGridOptions.api.setRowData($scope.profileEdit.documents);

            $scope.registrationId = $scope.profileEdit.registrationId;
            // console.log("$scope.profileEdit", $scope.profileEdit)
            // document parsing logic
            try {
              if (res && res.data && res.data.wholesaler && res.data.wholesaler.documents && res.data.wholesaler.documents.length > 0) {
                $scope.profileEdit.documentcount = res.data.wholesaler.documents.length;
              }
            }
            catch (e) {
              console.error('Error in getting document length ', e);
              $scope.profileEdit.documentcount = 0;
            }
            if ($scope.profileEdit.priceCapsule && $scope.profileEdit.priceCapsule.id) {
              let keepGoing = true;
              if ($scope.allPriceCapsuleDetails && $scope.allPriceCapsuleDetails.length > 0) {
                $scope.allPriceCapsuleDetails.forEach(elem => {
                  if (keepGoing && $scope.profileEdit.priceCapsule.id === elem.id) {
                    $scope.priceCapsuleProducts = elem.productsPricing;
                    $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);

                    // console.log("elem", elem, $scope.priceCapsuleProducts);
                    keepGoing = false;
                  }
                });
              }
            } else {
              $scope.priceCapsuleProducts = [];
              $scope.defaultPriceCapsule = '';
              let keepGoing = true;
              if ($scope.allPriceCapsuleDetails) {
                $scope.allPriceCapsuleDetails.forEach(elem => {
                  if (keepGoing && elem.default) {
                    $scope.defaultPriceCapsule = elem.name;
                    $scope.priceCapsuleProducts = elem.productsPricing;
                    $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);

                    // console.log("elem", elem, $scope.priceCapsuleProducts);
                    keepGoing = false;
                  }
                });
              }
            }
            //condition check
            if ($rootScope.loginData.role === "WHOLESALER") {
              buildChart();
            }
            getWholesalerPayments(wholesalerId);
          } else if (res.status === "FAILURE") {
            $scope.noWholesalerFound = true;
            console.error('No wholesaler record found');
          } else {
            console.error("Error in getting wholesaler profile ");
          }
        })
        .catch(function (err) {
          console.error("Error in getting wholesaler profile ", err);
        });


    }

    function getWholesalerPayments(wholesalerId) {
      wholesalerService.getWholesalerPayments(wholesalerId)
        .then(function (res) {
          if (res.status === $global.status.SUCCESS) {
            $scope.wholesalerPaymentsVM = res.data.results;
            $scope.paymentsGridOptions.api.setRowData($scope.wholesalerPaymentsVM);

          } else {
            console.error('Failed to fetch wholesaler payment details');
            $scope.paymentsGridOptions.api.setRowData([]);
          }
        })
        .catch(function (err) {
          console.error("err = ", err);
        })
    }

    function getWholesalerAgents(params, page) {
      return new Promise((resolve, reject) => {
        try {
          $scope.agentListUnderThisWholesaler = [];
          wholesalerService.getAgentsForWholesaler(params, page)
            .then(function (res) {
              $scope.totalAgentsCountByWholesaler = res.data.totalRecords;
              $scope.markerData = [];
              //$scope.center = [parseFloat($scope.profileEdit.address.latitude), parseFloat($scope.profileEdit.address.longitude)];
              if (res.status === 'SUCCESS') {
                res.data.users.forEach(function (elem, idx) {
                  try {
                    $scope.markerData.push({
                      "latitude": parseFloat(elem.address.latitude),
                      "longitude": parseFloat(elem.address.longitude),
                      "title": elem.name
                    });
                    var addrsplit = elem.address.formattedAddress.split(/,+/);
                    res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                  } catch (e) {
                    console.error("Error in converting formatting address into area ", e);
                  }
                });
                $scope.upcomingAppointment = [];
                $scope.agentListUnderThisWholesaler = res.data.users;
                $scope.partnerAgentsGridOptions.api.setRowData($scope.agentListUnderThisWholesaler);

                if (page) {
                  resolve($scope.agentListUnderThisWholesaler)
                } else {
                  resolve($scope.markerData);
                }
              } else {
                reject($global.status.FAILURE);
              }
            })
            .catch(function (err) {
              console.error("Error in getWholesalerAgents() ", err);
              reject($global.status.FAILURE);
            })
        } catch (err) {
          console.error("Error in getWholesalerAgents() ", err);
          reject($global.status.FAILURE);
        }
      });
    }
    // 08044292200

    function getWholesalerCompletedAppointments(wholesalerid, page) {
      wholesalerService.getWholesalerCompletedAppointments(wholesalerid, page)
        .then(function (res) {
          $scope.totalAgentRecords = res.data.totalRecords;
          if (res.status === 'SUCCESS') {
            $scope.todayAppointment = [];
            $scope.upcomingAppointment = [];
            $scope.todayAppointment = res.data.sells;
            $scope.billedTransactionsGridOptions.api.setRowData($scope.todayAppointment);

          } else {
            console.error("Error in getWholesalerCompletedAppointments() ");
          }
        })
        .catch(function (err) {
          console.error("Error in getWholesalerCompletedAppointments() ", err);
        })
    }
    function getAllWholesalerPriceCapsulesPromises() {
      return new Promise((resolve, reject) => {
        // get wholesaler pricing capsule details
        wholesalerService.getWholesalerPriceCapsules()
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data && res.data.results) {
              resolve(res);
            } else {
              reject('Unable to retrieve wholesaler price capsule details');
            }
          })
          .catch(function (err) {
            reject(err);
          })
      })
    }

    function createNewNode(arr) {
      if (Array.isArray(arr.children)) {
        arr.children.forEach(child => {
          let obj = {};
          obj.userId = child.userId;
          obj.userType = child.userType === "WHOLESALER" ? "PARTNER" : child.userType;
          obj.userName = child.userName;
          obj.userMobile = child.userMobile
          obj.ownerId = child.ownerId;
          $scope.data.push(obj);
          createNewNode(child);
        })
      }
    }

    function addNode() {
      angular.forEach($scope.data, function (val) {
        $scope.newobject.push(
          [
            {
              v: val.userId.toString(),
              f: '<div class="parent ' + val.userType + '">'
                + ' <div class="text">'
                + '<h4><b>' + val.userName + '</b></h4>'
                + '<div >' + val.userType + '</div>'
                + '<div >' + val.userMobile + '</div>'
                + '</div>'
                + '</div>'
            },
            (val.ownerId.toString() === "0" ? "" : val.ownerId.toString()),
            (val.userName + "," + val.userType)
          ]
        );
      })

      $scope.chartData = $scope.newobject;
    }

    function buildChart() {
      $scope.newobject = [['User', 'Owner Id', 'Username']];
      $scope.data = [];
      $scope.apiData = null;
      $scope.chartData = null;
      wholesalerService.getOrgChartData($scope.registrationId).then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data && res.data.payoutUsers && res.data.payoutUsers.length > 0) {
            $scope.apiData = res.data.payoutUsers;
            $scope.data.push({
              ownerId: 0,
              userId: $scope.apiData[0].userId,
              userType: $scope.apiData[0].userType === "WHOLESALER" ? "PARTNER" : $scope.apiData[0].userType,
              userName: $scope.apiData[0].userName,
              userMobile: $scope.apiData[0].userMobile
            });
            createNewNode($scope.apiData[0]);
            addNode();
          } else {
            growl.success("No heirarchy present for Chart")
          }
        } else {
          growl.error("Invalid Id to get Chart")
        }
      }).catch(function (err) {
        console.log("err", err)
      })
    }

    (function () {
      $('.container').on('click', '.chart-row #chart tbody .parent', function (e) {
        let s = new String(e.currentTarget.innerText).split("\n");
        console.log("agent node", s)
        if (s[1] === 'AGENT') {
          $state.go('root.agents', { agentName: s[0] })
        }
      });
    })();

    $scope.pageChangedCompletedTransactions = function (currentPage) {
      getWholesalerCompletedAppointments($scope.wholesalerId, currentPage);
    };

    $scope.pageChangedAgentsListByWholesaler = function (currentPage) {
      getWholesalerAgents($scope.wholesalerId, currentPage)
        .then(function (res) {
        })
        .catch(function (err) {
          console.error('Error in getting agents for the current wholesaler ', err);
        });
    };

    $scope.downloadDoc = function (url) {
      window.open(url, "_blank");
    };

    $scope.deleteDoc = function (data) {

      documents.disableUserDocuments({ documentId: data.id }).then(function (res) {
        if (res.status == "SUCCESS") {
          growl.success("Document Deleted Successfully");
          $scope.getUserDocuments($scope.wholesalerId);
        } else {
          growl.error("Unable to delete document please try again")
        }
      }).catch(function () {
        growl.error("Unable to delete document please try again")
      })

    }
    $scope.addDocument = function (wholesalerId) {

      $scope.document_wholesalerId = wholesalerId;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-document.html',
        controller: 'AddDocumentCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          reqId: function () {
            return wholesalerId;
          },
          from: function () {
            return "WHOLESALER";
          }
        }
      });
      modalInstance.result.then(function (res) {
        console.log("Modal closed :", res);
      }, function () {
        $scope.getUserDocuments($scope.wholesalerId);

        getWholesalerProfile($scope.wholesalerId);
      });
    };
    // Mapping Areas

    var letters = '0123456789ABCDEF';
    function getRandomColor() {
      var color = '#'; // <-----------
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    function polygonCenter(poly) {
      var lowx,
        highx,
        lowy,
        highy,
        lats = [],
        lngs = [],
        vertices = poly;

      for (var i = 0; i < vertices.length; i++) {
        lngs.push(vertices[i].longitude);
        lats.push(vertices[i].latitude);
      }

      lats.sort();
      lngs.sort();
      lowx = lats[0];
      highx = lats[vertices.length - 1];
      lowy = lngs[0];
      highy = lngs[vertices.length - 1];
      var center_x = lowx + ((highx - lowx) / 2);
      var center_y = lowy + ((highy - lowy) / 2);
      return (new google.maps.LatLng(center_x, center_y));
    }

    function getCurrentLocation() {
      $global.getCurrentLocation().then(function (res) {
        if (res) {
          // $scope.map.setCenter({lat: res.latitude, lng: res.longitude});
        }
      }, function (err) {
        console.log(err);
      })
    }

    function init() {

      var mapOptions = {
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        fullscreenControl: false,
        center: new google.maps.LatLng(17.3944809, 78.4417806)
      }
      // console.log("MAP OPTIONS", mapOptions)
      // $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
      // console.log("MAP", $scope.map)

      area.getArea().then(function (res) {
        if (res.status == "SUCCESS") {
          if (res.data && res.data.area) {
            if (res.data.area.length > 0) {
              $scope.area = res.data.area;
              console.log($scope.area)
              // var avgCenter={lat:0,lng:0}
              $scope.area.forEach(function (element, index) {
                var center = polygonCenter(element.areaCoordinates);
                // avgCenter.lat+=center.lat();
                // avgCenter.lng+=center.lng();
                var arry = [];
                element.areaCoordinates.forEach(function (elem, idx) {
                  if (elem.latitude != null && elem.longitude != null) {
                    arry.push(new google.maps.LatLng(elem.latitude, elem.longitude));
                  }
                });
                if (arry != null) {
                  element.LatLngObj = arry;
                }
                var color = getRandomColor();
                var polygonObj = new google.maps.Polygon({
                  paths: element.LatLngObj,
                  strokeColor: color,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: color,
                  fillOpacity: 0.2
                });
                polygonObj.setMap($scope.map);
                var marker = new google.maps.Marker({
                  map: $scope.map,
                  position: center,
                  label: { text: element.area, color: color, fontSize: "18px", fontWeight: "600" },
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 0
                  }
                });
              });
              // $scope.center={lat:avgCenter.lat/$scope.area.length,lng:avgCenter.lng/$scope.area.length}
              // $scope.map.setCenter($scope.center);
            }
          }
        }
      });
    };
  });
