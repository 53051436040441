'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:InventoryConfirmCtrl
 * @description
 * # InventoryConfirmCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('InventoryConfirmCtrl', function($scope, $rootScope, $global, ngToast, $uibModal, $window, SellNow, growl, $log, warehouseService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {
    
    console.log($rootScope.loginData)
    // $scope.showAgentItems = false;
    $scope.loadInventory = function(warehouseId, fromDate, toDate) {
      console.log($rootScope.loginData)
      console.log(warehouseId);
      console.log($scope.selectedWarehouse)
      $scope.isActualQuantityDisable = false;
      $scope.warehouseItemsListLoading = true;
      $scope.inventory_List.items = [];
      if (warehouseId) {
        warehouseService.retrieveTotalInventory(warehouseId, fromDate, toDate, $rootScope.loginData.city).then(function(res) {
          if (res.status == "SUCCESS") {
            $scope.appointmentCompleted = res.data.appointmentCompleted;
            if (res.data.inventory) {
              $scope.expectedQuantityList = res.data.inventory;
              if (res.data.inventory == []) {
                $scope.noInventoryFound = true;
              }
              if (res.data.inventory.length > 0) {
                $scope.noInventoryFound = false;
              } else {
                $scope.noInventoryFound = true;
              }
              if (res.data.inventoryUpdatedForSameDay) {
                //call expected quantity method.
                $scope.inventory_List = {
                  items: []
                };
                actualQuantity(warehouseId, fromDate, toDate, $rootScope.loginData.city);
              } else {
                $scope.inventory_List = {items: []};
                $scope.expectedQuantityList.forEach(function(element, index) {
                  var itemName = $scope.expectedQuantityList._id;
                  var temp_details = {
                    "categoryName": "",
                    "expectedQuantity": "",
                    "actualQuantity": ""
                  }
                  temp_details.categoryName = element._id;
                  temp_details.expectedQuantity = element.totalquantity;
                  $scope.inventory_List.items.push(temp_details);
                });
              }
              $scope.warehouseItemsListLoading = false;
            } else {
              $scope.noInventoryFound = true;
            }
          }
        });
      }
      // else{
      //     warehouseService.retrieveTotalInventory(fromDate,toDate,null,null,null).then(function(res){
      //         if (res.status == "SUCCESS") {
      //             $scope.appointmentCompleted = res.data.appointmentCompleted;
      //             $scope.expectedQuantityList = res.data.inventory;
      //             console.log($scope.expectedQuantityList)
      //             if (res.data.inventory.length > 0) {
      //                 $scope.noInventoryFound = false;
      //             }else{
      //                 $scope.noInventoryFound = true;
      //             }
      //             $scope.warehouseItemsListLoading = false;
      //         }
      //     });
      // }
    };
    
    function init() {
      $scope.warehouseItemsListLoading = true;
      $scope.showWarehouseItems = false;
      $scope.noInventoryFound = false;
      $scope.data = {};
      $scope.vm = {
        params: {}
      };
      $rootScope.loginData = {};
      $rootScope.loginData = $global.getLocalItem("loginData", true);
      $scope.inventory_List = {
        items: []
      };
      $scope.isActualQuantityDisable = false;
      $scope.dateInput = $moment().format('DD-MMM-YYYY');
      $scope.selectedWarehouse = null;
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput
      };
      $scope.opts = {
        format: "DD-MMM-YYYY",
        singleDatePicker: true
      };
      $scope.warehouseDetailsStatus = {warehouse: []};
      warehouseService.getWarehouse().then(function(res) {
        console.log("Warehouse Query sent");
        console.log(res);
        if (res.status == "SUCCESS") {
          if (res.data.warehouses.length > 1) {
            $scope.warehouses = res.data.warehouses;
            $scope.warehouses = {
              warehouseid: null,
              availableOptions: res.data.warehouses
            };
            $scope.warehouse_list = res.data.warehouses;
            console.log($scope.warehouse_list)
            if (res.data.message == "No Warehouse found") {
              $scope.noWarehouseFound = true;
            }
          } else {
            $scope.warehouse_list = res.data.warehouses;
            console.log($scope.warehouse_list)
          }
          
        } else if (res.status == "FAILURE") {
          $scope.noWarehouseFound = true;
        } else {
          $scope.noWarehouseFound = true;
        }
        
        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
          // var warehouseid = "ALL"
          // $scope.loadInventory(warehouseid,$scope.dateInput,$scope.dateInput);
          $scope.warehouseItemsListLoading = false;
          $scope.noInventoryFound = false;
          var count = 0;
          warehouseService.getWarehouseInventory($scope.dateInput, $scope.dateInput).then(function(res) {
            if (res.status == "SUCCESS") {
              if (res.data.incomingInventory) {
                $scope.savedInvetoryForToday = res.data.incomingInventory;
              }
            } else {
              $scope.warehouseDetailsStatus.warehouse.push($scope.warehouse_list)
              console.log("WAREHOUSE DETAILS::", $scope.warehouseDetailsStatus)
            }
            console.log("warehouse_list", $scope.warehouse_list)
            console.log("savedInvetoryForToday", $scope.savedInvetoryForToday)
            if (($scope.savedInvetoryForToday != null) && ($scope.warehouse_list != null)) {
              
              $scope.warehouse_list.forEach(function(element, index) {
                // count = 0;
                var temp_elem;
                if (element.warehouseId != null) {
                  count = 0;
                  console.log("ELEMENT", element)
                  $scope.savedInvetoryForToday.forEach(function(elem, indx) {
                    if (element.warehouseId == elem.warehouseId) {
                      count = 1;
                      elem['warehouseName'] = elem.warehouse.warehouseName;
                      console.log("ELEM", elem)
                      $scope.warehouseDetailsStatus.warehouse.push(elem);
                    }
                    temp_elem = elem;
                  });
                  if (count == 0) {
                    console.log("WAREHOUSE DETAILS::", $scope.warehouseDetailsStatus)
                    $scope.warehouseDetailsStatus.warehouse.push(element);
                  }
                }
              });
            }
          });
        } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
          $scope.warehouseid = $rootScope.loginData.warehouseId;
          $scope.loadInventory($scope.warehouseid, $scope.dateInput, $scope.dateInput);
        }
        
      }, function(err) {
      });
    }
    
    init();
    
    function toast(message) {
      ngToast.create({
        content: '<a href="#" class="">' + message + '</a>',
        dismissButton: true,
        timeout: 10000
      });
    }
    
    $scope.transactionChange = function() {
      $scope.inventory_List = {
        items: []
      };
      $scope.noInventoryFound = true;
      console.log($scope.dateInput)
      $scope.vm.params.page = -1;
      $scope.vm.params.todate = $moment($scope.dateInput).format('DD-MMM-YYYY');
      $scope.vm.params.fromdate = $moment($scope.dateInput).format('DD-MMM-YYYY');
      $scope.date.startDate = $scope.vm.params.fromdate;
      $scope.date.endDate = $scope.vm.params.todate;
      
      var params = {}
      params.page = -1;
      params.todate = $scope.date.endDate;
      params.fromdate = $scope.date.startDate;
      params.city = "Hyderabad";
      
      if ($scope.showWarehouseItems) {
        if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
          if ($scope.selectedWarehouse.warehouseId) {
            $scope.loadInventory($scope.selectedWarehouse.warehouseId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          } else {
            $scope.loadInventory("ALL", $scope.vm.params.fromdate, $scope.vm.params.todate);
          }
        }
      }
      if ($rootScope.loginData.role == "WAREHOUSEUSER") {
        $scope.warehouseid = $rootScope.loginData.warehouseId;
        $scope.loadInventory($scope.warehouseid, $scope.vm.params.fromdate, $scope.vm.params.todate);
      }
    };
    
    // $scope.dtOptions = DTOptionsBuilder.newOptions()
    //     .withDisplayLength($scope.length)
    //     .withOption('order', [1, 'desc']);
    
    $scope.$watch('dateInput', function(newDate) {
      
      $scope.transactionChange();
      
    }, false);
    
    $scope.backClick = function() {
      $scope.showWarehouseItems = false;
      $scope.noInventoryFound = false;
      $scope.noAgentInventoryFound = false;
      $scope.showAgentItems = false;
    };
    
    $scope.nextDate = function() {
      $scope.noInventoryFound = true;
      $scope.paging = false;
      $scope.dateInput = moment($scope.dateInput).add(1, "days").format('DD-MMM-YYYY');
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput
      };
      $scope.inventory_List.items = [];
      // if ($scope.showWarehouseItems) {
      //     if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN") {
      //         if ($scope.selectedWarehouse.warehouseId) {
      //             $scope.loadInventory($scope.selectedWarehouse.warehouseId,$scope.date.startDate, $scope.date.endDate);
      //         }else{
      //             $scope.loadInventory("ALL",$scope.date.startDate, $scope.date.endDate);
      //         }
      
      //     } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
      //         $scope.warehouseid = $rootScope.loginData.warehouseId;
      //         $scope.loadInventory( $scope.warehouseid, $scope.date.startDate, $scope.date.endDate);
      //     }
      // }
    }
    $scope.previousDate = function() {
      $scope.noInventoryFound = true;
      $scope.paging = false;
      $scope.dateInput = moment($scope.dateInput).subtract(1, "days").format('DD-MMM-YYYY');
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput
      };
      $scope.inventory_List.items = [];
      // if ($scope.showWarehouseItems) {
      //     if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN") {
      //        if ($scope.selectedWarehouse.warehouseId) {
      //             $scope.loadInventory($scope.selectedWarehouse.warehouseId,$scope.date.startDate, $scope.date.endDate);
      //         }else{
      //             $scope.loadInventory("ALL",$scope.date.startDate, $scope.date.endDate);
      //         }
      //     } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
      //         $scope.warehouseid = $rootScope.loginData.warehouseId;
      //         $scope.loadInventory($scope.warehouseid, $scope.date.startDate, $scope.date.endDate);
      //     }
      // }
    }
    
    $scope.selectWarehouse = function(warehouseSelected) {
      console.log("SELECTED WAREHOUSE :", warehouseSelected);
      $scope.selectedWarehouse = warehouseSelected;
      $scope.loadInventory(warehouseSelected.warehouseId, $scope.dateInput, $scope.dateInput);
      $scope.showWarehouseItems = true;
      $scope.noInventoryFound = false;
      console.log($scope.showWarehouseItems);
    };
    
    $scope.getItemsForAgent = function(warehouseSelected) {
      console.log("warehouseSelected ", warehouseSelected);
      if (warehouseSelected.warehouseId) {
        warehouseService.retrieveAgentItems(warehouseSelected.warehouseId, $scope.dateInput, $scope.dateInput, $rootScope.loginData.city).then(function(response) {
          console.log("warehouseService.retrieveAgentItems", response);
          $scope.inventoryItems = response.data.agentInventory;
          if ($scope.inventoryItems.length !== 0) {
            console.log("Inventory items present");
            $scope.getCategories();
            
          } else {
            $scope.showAgentItems = false;
            $scope.noAgentInventoryFound = true;
            $scope.noInventoryFound = false;
            $scope.showWarehouseItems = false;
            $scope.warehouseItemsListLoading = false;
          }
        });
      }
    };
    
    $scope.getCategories = function() {
      warehouseService.distinctCategories().then(function(response) {
        
        //headers for our table
        console.log("Calling distinct categories", response);
        $scope.categoryNameHeaders = response.data.uniqueCategories;
        
        //below 3 var are used for building scoreCardItems array of JSON objects
        var scoreCardItems = [];
        var scoreCardBody = {};
        var totalQuantity = [];
        
        // console.log("$scope.inventoryItems", $scope.inventoryItems);
        
        $scope.inventoryItems.forEach(function(inventoryItem, inventoryIndex) {
          
          $scope.categoryNameHeaders.forEach(function(header, headerIndex) {
            //for debug
            // if(inventoryItem.categoryname === header){
            //     console.log("comparing inventoryItem.categoryname and header --> ", inventoryItem.categoryname, " <=> ", header, inventoryItem.categoryname === header, "at index ", headerIndex);
            // }
            
            //for each inventoryItem, loop through totalQuantity and check for equal categoryName
            //if found then push it into the array and set the flag true (to break the scan)
            //else push 0
            var checkFlag = false;
            inventoryItem.totalQuantity.forEach(function(item, itemIndex) {
              if (item.categoryName === header && !checkFlag) {
                totalQuantity.push(item.totalQuantity);
                checkFlag = true;
                return true; //to break for loop once the match is found
              }
            });
            if (checkFlag === false) {
              totalQuantity.push(0);
            }
          });
          
          scoreCardBody = {
            agentName: inventoryItem.agentName,
            inventory: totalQuantity
          };
          
          
          console.log("scoreCardBody", scoreCardBody);
          totalQuantity = [];
          scoreCardItems.push(scoreCardBody);
        });
        
        /*
                inventory: Array(9)
                0: {name: "Test Agent for Agent Type", categoryname: "AIR COOLER", totalquantity: 1}
                1: {name: "SCRAPQ GANDHINAGAR", categoryname: "GLASS/BOTTLES", totalquantity: 1}
                2: {name: "SCRAPQ GANDHINAGAR", categoryname: "WHITE PAPER", totalquantity: 1}
                3: {name: "Test Agent for Agent Type", categoryname: "REFRIGERATOR", totalquantity: 1}
                4: {name: "Test Agent for Agent Type", categoryname: "BB CARTON", totalquantity: 1}
                5: {name: "SCRAPQ GANDHINAGAR", categoryname: "BOOKS", totalquantity: 1}
                6: {name: "Test Agent for Agent Type", categoryname: "IRON", totalquantity: 1}
                7: {name: "Test Agent for Agent Type", categoryname: "ALUMINIUM", totalquantity: 1}
                8: {name: "Test Agent for Agent Type", categoryname: "REGULAR CARTON", totalquantity: 1}
             */
        
        //build the below object only if the scoreCardItems.agentName == inventoryItem.name doesn't exist
        /*if(scoreCardItems.forEach(function(scoreCardItem, scoreCardItemIndex){
            if(scoreCardItem.agentName === inventoryItem.name){
                console.log("scoreCardItem.agentName", scoreCardItem.agentName, "inventoryItem.name", inventoryItem.name, "scoreCardItem.agentName === inventoryItem.name", scoreCardItem.agentName === inventoryItem.name);

            }else{
                console.log("constructing a new scoreCardItem object");

            }
        }));*/
        
        //for testing static values
        /*$scope.scoreCardItems = [
            {
                agentName : "ranjith12",
                categoryTotalValues : [1,2,3,4,5]
            },
            {
                agentName : "ranjith34",
                categoryTotalValues : [1,2,3,4,5]
            }
        ];*/
        
        //consider this as an array of JSON objects that needs to be displayed in table after the headers
        $scope.scoreCardItems = scoreCardItems;
        console.log("$scope.scoreCardItems", $scope.scoreCardItems);
        
        $scope.showAgentItems = true;
        $scope.noAgentInventoryFound = false;
      })
    };
    
    function actualQuantity(warehouseId, fromDate, toDate, city) {
      $scope.inventory_List = {
        items: []
      };
      $scope.warehouseItemsListLoading = true;
      warehouseService.retrieveActualInventory(warehouseId, fromDate, toDate, city).then(function(res) {
        console.log(res)
        if (res.status == "SUCCESS") {
          if (res.data.incomingInventory.length != 0) {
            if (res.data.incomingInventory[0].items) {
              $scope.actualQuantityList = res.data.incomingInventory[0].items;
              console.log($scope.actualQuantityList)
              $scope.noActualQuantity = false;
            }
          } else {
            $scope.actualQuantityList = {};
            $scope.noActualQuantity = true;
          }
        } else {
          $scope.actualQuantityList = {};
          $scope.noActualQuantity = true;
        }
        console.log($scope.expectedQuantityList);
        console.log($scope.actualQuantityList)
        console.log(($scope.actualQuantityList === {}))
        if (!($scope.noActualQuantity)) {
          $scope.appointmentCompleted = false;
          $scope.isActualQuantityDisable = true;
          $scope.expectedQuantityList.forEach(function(element, index) {
            
            var itemName = $scope.expectedQuantityList._id;
            var temp_details = {
              "categoryName": "",
              "expectedQuantity": "",
              "actualQuantity": ""
            }
            $scope.actualQuantityList.forEach(function(elem, indx) {
              if (element._id == elem.categoryName) {
                temp_details.categoryName = elem.categoryName;
                temp_details.expectedQuantity = element.totalquantity;
                temp_details.actualQuantity = elem.quantity;
                $scope.inventory_List.items.push(temp_details);
              }
            })
          });
        } else {
          $scope.isActualQuantityDisable = false;
          $scope.appointmentCompleted = true;
          $scope.expectedQuantityList.forEach(function(element, index) {
            var itemName = $scope.expectedQuantityList._id;
            var temp_details = {
              "categoryName": "",
              "expectedQuantity": "",
              "actualQuantity": ""
            }
            temp_details.categoryName = element._id;
            temp_details.expectedQuantity = element.totalquantity;
            $scope.inventory_List.items.push(temp_details);
          });
        }
        $scope.warehouseItemsListLoading = false;
      });
    }
    
    $scope.saveQuantity = function() {
      var jsonData = angular.copy($scope.inventory_List.items);
      console.log(jsonData)
      jsonData.forEach(function(element, index) {
        element['quantity'] = element["actualQuantity"];
        delete element["actualQuantity"];
        delete element["expectedQuantity"];
      });
      var data = {
        warehouseId: "",
        items: jsonData
      };
      if ($rootScope.loginData.role == "CRMUSER" || $rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "MASTERFRANCHISE") {
        data.warehouseId = $scope.selectedWarehouse.warehouseId;
      } else if ($rootScope.loginData.role == "WAREHOUSEUSER") {
        data.warehouseId = $rootScope.loginData.warehouseId;
      }
      console.log(data)
      warehouseService.addInventory(data.warehouseId, data).then(function(res) {
        if (res.status == "SUCCESS") {
          toast("Items Approved Successfully");
          $scope.loadInventory(data.warehouseId, $scope.date.startDate, $scope.date.endDate);
        } else {
          toast("Error in Approving Items");
        }
        
      });
    }
    
  });
