'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:CommunityCtrl
 * @description
 * # CommunityCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('CommunityCtrl', function ($scope, $rootScope, $global, $state, $stateParams, consumer, $uibModal, $log, apartments, growl, BidService, DTOptionsBuilder, DTColumnDefBuilder) {
    $scope.pages = -1;
    $scope.vm = { params: {} };
    $scope.currentPage = 1;
    var apartmentIndex;

    $scope.apartmentId = $stateParams.consumerId;
    if (!$scope.apartmentId) {
      $state.go("root.consumer");
    }
    $scope.consumerId = $stateParams.consumerId;
    $scope.communityId = $scope.consumerId;

    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    $scope.apartmentListLoading = true;
    $scope.blocksList = [];

    $scope.nameRegex = new RegExp(/^[a-zA-Z\s.]*$/);
    $scope.mobileRegex = new RegExp(/^([0-9]{10})+$/);
    $scope.numberRegex = new RegExp(/^\d+$/g);

    $scope.getConsumerProfile = function () {
      if (!$scope.consumerId) {
        console.error('consumerId is null');
        return;
      }
      consumer.getProfile($scope.consumerId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.community = res.data.consumer;
          $scope.getBlocks($scope.communityId);
          $scope.getCommunityCustomers($scope.communityId);
          $scope.getCommunityStatusGraphs($scope.communityId);
          $scope.getCommunityProductsGraphs($scope.communityId);
          // For appointments pie
          $scope.getConsumerAppointments().then(val => {
            $scope.retriveCommunityAppointments($scope.communityId);
          });
        } else if (res.status === "FAILURE") {
          $scope.noConsumerFound = "No consumer record found";
        }

      }, function (err) {
      });
    }

    $scope.getCommunityCustomers = function (communityId) {
      if (!communityId) {
        console.error('communityId is null');
        return;
      }
      consumer.getCommunityCustomers(communityId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.communityCustomers = res.data.communityCustomers;
          $scope.communityCustomers.forEach(customer => {
            $scope.trainsCustomers = customer.trainNumber ? true : $scope.trainsCustomers;
            let days = [];
            if (customer.dayOfArrivals) {
              customer.dayOfArrivals.forEach(day => { day = days.push(day.slice(0, 3).toUpperCase()); });
              customer.dayOfArrivals = days;
            }
          });
        } else if (res.status === "FAILURE") {
        }
      }, function (err) {
      });
    }

    $scope.getCommunityStatusGraphs = function (communityId) {
      if (!communityId) {
        console.error('communityId is null');
        return;
      }
      consumer.getCommunityStatusGraphs(communityId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.appointmentStatuses = res.data.communityStatusCounts;
          $scope.appointmentStatuses.forEach(status => {
            $scope.uniquestatus.push(status.status.replaceAll("_", " "));
            $scope.barcolors.push($scope.colordictionary[status.status.replaceAll("_", " ")])
            $scope.statuscounts.push(status.count);
          })
        } else if (res.status === "FAILURE") {
        }
      }, function (err) {
      });
    }

    $scope.getCommunityProductsGraphs = function (communityId) {
      if (!communityId) {
        console.error('communityId is null');
        return;
      }
      consumer.getCommunityProductsGraphs(communityId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.communityProductsCounts = res.data.communityProductsCounts;
          $scope.communityProductsCounts.forEach(status => {
            $scope.uniqueitems.push(status.itemName);
            $scope.uniqueitemcounts.push(status.count);
          })
        } else if (res.status === "FAILURE") {
        }
      }, function (err) {
      });
    }
    $scope.getBlocks = function (communityId) {
      consumer.getBlocks(communityId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.blocksList = res.data.blocks;
          if ($scope.blocksGridOptions && $scope.blocksGridOptions.api) {
            $scope.blocksGridOptions.api.setRowData($scope.blocksList)
          }
        }
      }, function (err) {
      })
    }
    $scope.retriveCommunityAppointments = function (communityId) {
      consumer.getCommunityAppointments(communityId).then(function (res) {

        if (res.status === "SUCCESS") {
          $scope.communityAppointments = res.data.communityAppointments.reverse();
          console.log($scope.communityAppointments)
          $scope.appointmentsGridOptions.api.setRowData($scope.communityAppointments);


          $scope.communityAppointments.forEach(function (elem, idx) {
            $scope.communityAppointments[idx]['preferredSlotEndDate'] = $scope.communityAppointments[idx]['preferredDate'] + " " + $scope.communityAppointments[idx]['preferredEndTime'];

            if (moment() > moment($scope.communityAppointments[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm') &&
              $scope.communityAppointments[idx].status.match(/assigned|initiated|accepted|declined/i)) {
              $scope.communityAppointments[idx]['preferredSlotExpired'] = true;
            }
            if (moment($scope.communityAppointments[idx]['completedDate'] + " " + $scope.communityAppointments[idx]['completedTime'], 'DD-MMM-YYYY HH:mm') > moment($scope.communityAppointments[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm')) {
              $scope.communityAppointments[idx]['completedAfterPreferredSlotExpired'] = true;
            }
          });
        }
      });
    }
    $scope.getConsumerAppointments = function () {
      return new Promise((resolve, reject) => {

        $scope.uniquestatus = [];
        $scope.uniqueitems = [];
        $scope.uniqueitemcounts = [];
        $scope.statuscounts = [];
        $scope.barcolors = [];

        let params = {
          "consumerid": $scope.consumerId,
          "size": 40,
          "page": -1,
        };
        consumer.getSellRquests(params).then(function (res) {
          $scope.pickupsEdit = $scope.consumerAppointments = res.data.sells.reverse();
          resolve();
          if ($scope.consumerAppointmentsGridOptions && $scope.consumerAppointmentsGridOptions.api) {
            $scope.consumerAppointmentsGridOptions.api.setRowData($scope.consumerAppointments);
          }
        })
      });
    }
    //Intit Data
    $scope.getConsumerProfile();



    // ag-grid init
    (function () {
      var columnDefs = [
        {
          headerName: "Name",
          field: "apartmentName",
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: "Secretary Name",
          field: "secretaryName",
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: "Secretary Mobile Number",
          cellRenderer: HideMobileNumber,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: "Number of Aparmtments",
          field: "numberOfApartments",
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: "Edit",
          field: "edit-community",
          cellRenderer: ActionCellRenderer,
          // cellStyle: { 'text-align': 'left' }
        },
        {
          headerName: "Area",
          field: "area",
          hide: true
        },
        {
          headerName: "Number of Blocks",
          field: "numberOfBlocks",
          hide: true
        },
        {
          headerName: "Latitude",
          field: "address.latitude",
          hide: true
        },
        {
          headerName: "Longitude",
          field: "address.longitude",
          hide: true
        },
      ];
      function ActionCellRenderer() {
      }
      function ActionDeleteCellRenderer() {
      }

      ActionDeleteCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        this.eGui.style.textAlign = 'center';
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          $scope.actionButtonClassList = "";

          let iconsDiv = `<i class="fa fa-trash" style="color:red"></i>`;
          if (params.data.editBlock) {
            iconsDiv = `<i class="fa fa-times" style="color:#000"></i>`;
          }
          this.eGui.innerHTML = iconsDiv;
        }
      };
      ActionDeleteCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };
      ActionCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        this.eGui.style.textAlign = 'center';
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          $scope.actionButtonClassList = "";

          let iconsDiv = `<i class="md md-mode-edit" style="color:#9acd32"></i>`;
          if (params.data.editBlock) {
            iconsDiv = `<i class="fa fa-save" style="color:#9acd32"></i>`;
          }
          this.eGui.innerHTML = iconsDiv;
        }
      };

      ActionCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };


      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true,

        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: rowData,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          $scope.gridOptions.api.setRowData($scope.apartment_list);
        },
        onCellClicked: function (event) {
          if (event.column.colId == "edit-community") {
            console.log(event);
            $scope.editApartment(event.data);
          } else {
            $scope.apartment_list.forEach((apartment, index) => {
              if (apartment.apartmentName === event.data.apartmentName) {
                $scope.apartmentDetails(index);
              }
            })
          }
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };


      // Customers Ag grid Init
      var communityCustomersColumnDefs = [
        {
          headerName: "name",
          field: "name",
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: "mobile",
          field: "mobile",
          maxWidth: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: "created On",
          field: "createdOn",
          cellStyle: { textAlign: 'center' }
        },
      ];

      $scope.communityCustomersGridOptions = {
        columnDefs: communityCustomersColumnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: rowData,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          setTimeout(function () {
            $scope.communityCustomersGridOptions.api.setRowData($scope.communityCustomers);
          }, 1500)
        },
        onCellClicked: function (event) {

        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
          if ($scope.trainsCustomers) {
            let trainColumns = [
              {
                headerName: "train Number",
                field: "trainNumber",
                maxWidth: 110
              },
              {
                headerName: "Slot",
                field: "appointmentSlot",
                maxWidth: 120
              },
              {
                headerName: "day Of Arrivals",
                field: "dayOfArrivals",
              }
            ];
            communityCustomersColumnDefs = communityCustomersColumnDefs.concat(trainColumns);
            $scope.communityCustomersGridOptions.api.setColumnDefs(communityCustomersColumnDefs);
          }
        }
      };

      // Blocks Ag grid Init
      var blocksColumnDefs = [
        {
          headerName: "EDIT",
          field: "editBlock",
          maxWidth: 40,
          cellStyle: { textAlign: 'center' },
          cellRenderer: ActionCellRenderer,
        },
        {
          headerName: "REMOVE",
          field: "removeBlock",
          maxWidth: 40,
          cellStyle: { textAlign: 'center' },
          cellRenderer: ActionDeleteCellRenderer,
        },
        {
          headerName: "BLOCK NAME",
          field: "blockName",
          editable: params => params.data.editBlock,
          cellStyle: params => {
            if (params.data.editBlock) {
              return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
            }
            return { 'text-align': 'left' }
          },
          singleClickEdit: true
        },
        {
          headerName: "INCHARGE NAME",
          field: "inchargeName",
          editable: params => params.data.editBlock,
          cellStyle: params => {
            if (params.data.editBlock) {
              return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
            }
            return { 'text-align': 'left' }
          },
          singleClickEdit: true
        },
        {
          headerName: "INCHARGE MOBILE",
          field: "inchargeMobile",
          editable: params => params.data.editBlock,
          cellStyle: params => {
            if (params.data.editBlock) {
              return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
            }
            return { 'text-align': 'center' }
          },
          singleClickEdit: true
        },
        {
          headerName: "NO. OF FLATS",
          field: "numberOfFlats",
          editable: params => params.data.editBlock,
          cellStyle: params => {
            if (params.data.editBlock) {
              return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 1px 1px rgb(236 236 236)' };
            }
            return { 'text-align': 'right' }
          },
          singleClickEdit: true
        },
        {
          headerName: "CREATED ON",
          field: "createdOn",
          cellStyle: { textAlign: 'center' }
        },
      ];

      $scope.blocksGridOptions = {
        columnDefs: blocksColumnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: rowData,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          setTimeout(function () {
            $scope.blocksGridOptions.api.setRowData($scope.blocksList);
          }, 1500)
        },
        onCellClicked: function (event) {
          if (event.column.colId == "removeBlock") {
            if (!event.data.editBlock) {
              $scope.deleteBlock(event.data);
            }

            event.data.editBlock = false;
            $scope.getBlocks($scope.communityId);
            $scope.refreshBlocksGrid();
            event.api.refreshCells({
              columns: ['editBlock', 'removeBlock'],
              rowNodes: [event.node],
              force: true,
            });
          }
          if (event.column.colId == "editBlock") {

            if (event.data.editBlock) {
              $scope.updateBlock(event.data);
              $scope.getBlocks($scope.communityId);
            }

            event.data.editBlock = event.data.editBlock ? false : true;
            $scope.refreshBlocksGrid();
            event.api.refreshCells({
              columns: ['editBlock', 'removeBlock'],
              rowNodes: [event.node],
              force: true,
            });
          }
        },
        onCellValueChanged: function (params) {
          if (params.column.colId === "inchargeMobile") {
            $scope.validateCells(params, $scope.mobileRegex, "inchargeMobile");
          } else if (params.column.colId === "inchargeName") {
            $scope.validateCells(params, $scope.nameRegex, "inchargeName");
          } else if (params.column.colId === "numberOfFlats") {
            $scope.validateCells(params, $scope.numberRegex, "numberOfFlats");
          }
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };
      // Blocks Ag Grid End

      $scope.updateBlock = function (data) {
        consumer.updateBlocks(data).then(function (res) {
          if (res.status === "FAILURE") {
            growl.error("Unable to update block");
            return;
          }

          growl.success("Community block has been updated successfully");
          $global.refresh($state);
        });
      }

      $scope.validateCells = function (params, regex, colId) {
        if (!regex.test(params.value)) {
          growl.error("Please enter a valid input!");
          params.data.value = params.oldValue;
          params.data[colId] = params.oldValue;
        }
        let row = $scope.blocksGridOptions.api.getDisplayedRowAtIndex(params.rowIndex);
        $scope.blocksGridOptions.api.redrawRows({ rowNodes: [row] });
      }

      $scope.getRowData = () => {
        var rowData = [];
        $scope.blocksGridOptions.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        return rowData;
      };

      $scope.refreshBlocksGrid = () => {
        let rowData = $scope.getRowData();
        $scope.blocksGridOptions.api.setRowData(rowData);
      };

      function stringStyleFormatter(params) {
        if (params.column.colId == "communityAppointmentId" || params.column.colId === "confirmationId") {
          if (params.data.completedAfterPreferredSlotExpired) {
            return { color: 'red', textAlign: 'center' };
          } else {
            return { textAlign: 'center' }
          }
        }
        if (params.column.colId == "status") {
          return $scope.getStatusColor(params.data);
        }
      }

      // Appointments Grid Init
      var appointmentsColumnDefs = [
        {
          headerName: "CONF. ID",
          field: "communityAppointmentId",
          cellStyle: stringStyleFormatter,
        },
        {
          headerName: "INITIATED",
          field: "requestedDate",
          cellStyle: { textAlign: 'center' },
          valueFormatter: stringFormatter,
        },
        {
          headerName: "APPOINTMENT SLOT",
          field: "preferredDate",
          cellStyle: { textAlign: 'center' },
          valueFormatter: stringFormatter,
        },
        {
          headerName: "REO",
          field: "agentName",
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: "STATUS",
          field: "status",
          cellStyle: stringStyleFormatter,
        },
        {
          headerName: "FULFILLED ON",
          field: "completedTime",
          valueFormatter: stringFormatter,
          cellStyle: { textAlign: 'center' },
        }
      ];
      function stringFormatter(params) {
        if (params.column.colId == "requestedDate") {
          return params.data.requestedDate + " " + params.data.requestedTime
        }
        if (params.column.colId == "preferredDate") {
          if (params.data.preferredSlot) {
            return params.data.preferredDate + " " + params.data.preferredSlot;
          } else {
            return params.data.preferredDate + " " + params.data.preferredStartTime + "-" + params.data.preferredEndTime;
          }
        }
        if (params.column.colId == "completedTime") {
          return (params.data.completedDate ? params.data.completedDate : "") + " " + (params.data.completedTime ? params.data.completedTime : "");
        }
      }

      $scope.appointmentsGridOptions = {
        columnDefs: appointmentsColumnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: rowData,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          setTimeout(function () {
            $scope.appointmentsGridOptions.api.setRowData($scope.communityAppointments);
          }, 1500)
        },
        onCellClicked: function (event) {
          $scope.OpenRequest(event.data)
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };

      //////////////////////
      var consumerAppointmentsColumns = [
        {
          headerName: "CONF. ID",
          field: "confirmationId",
          cellStyle: stringStyleFormatter,
        },
        {
          headerName: "INITIATED",
          field: "requestedDate",
          cellStyle: { textAlign: 'center' },
          valueFormatter: stringFormatter,
        },
        {
          headerName: "APPOINTMENT SLOT",
          field: "preferredDate",
          cellStyle: { textAlign: 'center' },
          valueFormatter: stringFormatter,
        },
        {
          headerName: "REO",
          field: "agentName",
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: "STATUS",
          field: "status",
          cellStyle: stringStyleFormatter,
        },
        {
          headerName: "FULFILLED ON",
          field: "completedTime",
          valueFormatter: stringFormatter,
          cellStyle: { textAlign: 'center' },
        }
      ];
      $scope.consumerAppointmentsGridOptions = {
        columnDefs: consumerAppointmentsColumns,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: [],
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          setTimeout(function () {
            $scope.consumerAppointmentsGridOptions.api.setRowData($scope.consumerAppointments);
          }, 1500)
        },
        onCellClicked: function (event) {
          $scope.OpenSellRequest(event.data)
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };
    })();


    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    function HideMobileNumber() {
    }
    HideMobileNumber.prototype.init = function (params) {
      // console.log("where is mobile no.",params.data);
      this.mobileNumberDiv = document.createElement('div');
      if (params.data != "" || params.data != "undefined") {
        if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
          let mobileNo = params.data.secretaryMobile;
          this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        } else {
          let mobileNo = params.data.secretaryMobile;
          let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
          this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        }
      } else {
        this.mobileNumberDiv.innerHTML = "";
      }
    }

    HideMobileNumber.prototype.getGui = function () {
      return this.mobileNumberDiv;
    }

    $scope.getRowStyle = function (index) {
      if (index % 2 !== 0) {
        return {
          "background-color": "rgb(247 247 247)"
        }
      } else {
        return {
          "background-color": "white"
        }
      }
    }
    $scope.back = function () {
      $state.go("root.consumer", { type: 'RWA', pageTitle:"community-customers" })
    }
    $scope.getStatusColor = function (item) {
      let obj = {
        "color": "black",
        textAlign: 'center'
      };
      switch (item.status) {
        case "Assigned To REO":
          obj.color = "yellow";
          break;
        case "Accepted By Agent":
          obj.color = "blue";
          break;
        case "Request Completed":
          obj.color = "green";
          break;
        case "Cancelled By Consumer":
          obj.color = "black";
          break;
        case "Declined By Agent":
          obj.color = "red";
          break;
        case "Request Initiated":
          obj.color = "orange";
          break;
      }
      return obj;
    }

    $scope.searchConsumer = function (key) {
      key = key.trim();
      if (key) {
        $scope.submitted = true;
        $scope.paging = true;
        $scope.noApartmentFound = false;
        if (key.length > 3) {
          $scope.apartmentListLoading = true;
          apartments.searchApartments(key).then(function (res) {
            $scope.apartmentListLoading = false;
            console.log("REEEESSSSSS", res)
            if (res.status == "SUCCESS") {
              // if (res.data.userId) {
              $scope.apartments = res.data;
              res.data.apartments.forEach(function (elem, idx) {
                try {
                  var addrsplit = elem.address.formattedAddress.split(/\,+/);
                  res.data.apartments[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                } catch (e) {

                }

              });
              if (res.data.apartments.length == 1) {
                $scope.length0 = true;
                $scope.apartmentDetails(0);
              } else {
                $scope.apartment_list = res.data.apartments;

                $scope.length0 = false;
                $scope.apartmentId = false;
              }
              if (res.data.message == "No records found") {
                $scope.noApartmentFound = true;
              }
            } else if (res.status == "FAILURE") {
              $scope.noApartmentFound = true;
            } else {
              $scope.noApartmentFound = true;
            }

          }, function (err) {
            //console.log(err);
          })
        }
      } else {
        $scope.loadApartments($scope.vm.params);
      }
    };
    $scope.oneApartment = false;


    $scope.loadApartments = function (params) {
      $scope.apartmentEdit = {};
      $scope.paging = false;
      $scope.submitted = true;
      $scope.noApartmentFound = false;
      if (!params || !params.page) {
        params = {};
        params.page = 1;
      }

      // ag-grid pagination to work we need all records. -1 fetches all records
      params.page = -1;
      $scope.apartmentListLoading = true;
      apartments.getAllApartments(params.page).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.totalApartmentRecords = res.data.totalRecords;
          $scope.apartments = res.data;
          $scope.apartmentListLoading = false;
          if (res.data.apartments.length > 1) {
            $scope.oneApartment = false;
          } else {
            $scope.oneApartment = true;
          }
          if (res.data.apartments.length == 1) {
            $scope.length0 = true;
            $scope.apartmentDetails(0);
          } else {
            res.data.apartments.forEach(function (elem, idx) {
              try {
                var addrsplit = elem.address.formattedAddress.split(/\,+/);
                res.data.apartments[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
              } catch (e) {

              }
            });
            $scope.apartmentListLoading = false;
            $scope.length0 = false;
            $scope.apartmentId = false;
            $scope.apartment_list = res.data.apartments;
            // the ag-grid displays the following data $scope.apartment_list
            $scope.gridOptions.api.setRowData($scope.apartment_list);

          }
          if (res.data.message == "No records found") {
            $scope.noApartmentFound = true;
          }
        } else if (res.status == "FAILURE") {
          $scope.noApartmentFound = true;
        } else {
          $scope.noApartmentFound = true;
        }

      }, function (err) {

      })
    }
    $scope.OpenSellRequest = function (pickup) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/consumer-pickups-modal.html',
        controller: 'ConsumerPickupsModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return pickup;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.getConsumerProfile();
      }, function () {
        $scope.getConsumerProfile();
      });
    };
    $scope.editProfile = function () {
      consumer.setConsumerDetails($scope.community);
      $state.go("root.add-consumer");
    };
    $scope.loadConsumer = function () {
      $state.go('root.consumer', { type: 'RWA' });
    }
    $scope.pageChanged = function (currentPage) {
      $scope.vm.params.page = currentPage;
      $scope.loadApartments($scope.vm.params);
    }

    // $scope.loadApartments($scope.vm.params);

    $scope.apartmentDetails = function (val) {
      apartmentIndex = val;
      // console.log(val);
      console.log($scope.apartments)
      $scope.length0 = true;
      var details = $scope.apartments.apartments[val];
      if (details.apartmentId) {
        $scope.apartmentId = details.apartmentId;
        getApartment($scope.apartmentId);
        $scope.apartment_list = false;
        // getConsumerAppointments(params);
      } else {
        $scope.apartment_list = false;
        $scope.noApartmentFound = true;
      }
    }

    $scope.addApartments = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-apartment.html',
        controller: 'AddApartmentCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return 'Apartment';
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        $scope.loadApartments();
      });

    };

    function getApartment(apartmentId) {
      apartments.getApartmentById(apartmentId).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.apartmentEdit = res.data.apartment;
          if (res.data.apartment.blocks) {
            $scope.blocksList = res.data.apartment.blocks;
            getAppointments($scope.apartmentEdit.apartmentId);
          }
          // $scope.apartmentEdit.address.formattedAddress = $scope.apartmentEdit.address.formattedAddress.split(/\,+/g, "--");
          try {
            var addrsplit = $scope.apartmentEdit.address.formattedAddress.split(/\,+/);
            $scope.apartmentEdit['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
            var replaced = $scope.apartmentEdit.address.formattedAddress.replace(/\,+/g, "--").split("--");
            replaced.forEach(function (elem, idx) {
              if (idx % 2 == 0) {
                replaced[idx] = replaced[idx] + "<br>";
              }
              replaced[idx] = replaced[idx].trim();
            });
            $scope.apartmentEdit.address.formattedAddress = replaced.join().replace(/\,/g, " ");
          }
          catch (e) {

          }
        } else if (res.status == "FAILURE") {
          $scope.noApartmentFound = "No consumer record found";
        }

      }, function (err) {
        //console.log(err);
      })
    }

    $scope.pickupsApp = [];
    $scope.pickupsEdit = [];

    function parseDate(s) {
      var months = {
        jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
        jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
      };
      var p = s.split('-');
      return new Date(p[2], months[p[1].toLowerCase()], p[0]);
    }

    $scope.dtOptions = DTOptionsBuilder.newOptions().withOption('lengthMenu', [100, 150, 200]);

    $scope.pieoptions = {
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          fontSize: 11,
        }
      }
    };

    $scope.baroptions = {
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          fontSize: 11,
        }
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            mirror: true,
            padding: 0,
            fontSize: 6,
            length: 15,
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false
          }
        }],
        gridLines: {
          display: false
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItems, data) {
            return Math.round(tooltipItems.yLabel * 100) / 100;
          }
        }
      }
    };

    $scope.colordictionary = {
      "ASSIGNED TO AGENT": "#FFFF00",
      'ACCEPTED BY AGENT': "#0000FF",
      'REQUEST COMPLETED': "#008000",
      'CANCELLED BY CONSUMER': "#000000",
      "DECLINED BY AGENT": "#FF0000",
      "REQUEST INITIATED": "#FFA500",
      "NO APPOINTMENTS": "#EEEEEE",
    }

    $scope.barcolors = [];

    (function (ChartJsProvider) {
      ChartJsProvider.setOptions({ colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
    });

    $scope.openGraph = function (type) {
      let data = {
        type: type,
        name: $scope.community.name,
        uniqueitems: $scope.uniqueitems,
        uniqueitemcounts: $scope.uniqueitemcounts,
        uniquestatus: $scope.uniquestatus,
        statuscounts: $scope.statuscounts,
        barcolors: $scope.barcolors,
      }
      var viewGraphModalInstance = $uibModal.open({
        templateUrl: 'views/view-graph.html',
        controller: 'ViewGraphController',
        size: "lg",
        backdrop: 'static',
        resolve: {
          data: function () {
            return data;
          }
        }
      });

      viewGraphModalInstance.result.then(function () {
      }, function () {
      });
    }

    $scope.viewMore = function () {
      $scope.pages += 1;
      var params = {
        "apartmentId": $scope.apartmentId,
        "size": 10,
        "page": $scope.pages
      };
      // getConsumerAppointments(params);

    };

    $scope.getConsumer = function () {
      $scope.consumer = {};
      consumer.getConsumerByText($scope.searchText).then(function (res) {
        if (res.status == "success")
          $scope.consumer = res.data;
        else
          growl.error(res.error.message);
      })
    }

    $scope.editApartment = function (data) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/apartment-edit.html',
        controller: 'ApartmentEditCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return data ? data.apartmentId : $scope.apartmentEdit.apartmentId;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getApartment($scope.apartmentId);
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        getApartment($scope.apartmentId);
      });
    };

    // "61b06feb33049f1c86c31b56"
    $scope.updateApartment = function (type, blockData) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/apartment-update.html',
        controller: 'ApartmentUpdateCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return {
              communityId: $scope.communityId,
              actionType: type,
              blockData: blockData
            };
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.getConsumerProfile();
      }, function () {
        $log.info('Modal fsdfsddismissed at: ' + new Date());
        $scope.getConsumerProfile();
      });
    };
    $scope.openconsumersCategories = function () {
      var modalInstance = "";

      var data = {
        consumerId: $scope.consumerId,
        apartmentId: $scope.consumerId,
        address: $scope.community.address,
        confirmationId: ""
      };

      modalInstance = $uibModal.open({
        templateUrl: 'views/sells-consumers.html',
        controller: 'SellConsumerCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          ids: function () {
            return data;
          }
        }

      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.getConsumerProfile();
      }, function (res) {
        $scope.getConsumerProfile();
        $log.info('Modal dismissed at: ' + new Date());
      });


    };


    $scope.deleteBlock = function (row) {
      console.log(row)
      var modalInstance = $uibModal.open({
        templateUrl: 'views/deleteBlock.html',
        controller: 'DeleteBlockCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return row;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getApartment($scope.apartmentId);
        $global.refresh($state);
      }, function () {
      });
    }

    $scope.dataset = false;
    $scope.bookAppointment = function () {

      var data = {
        consumerId: $scope.consumerId,
        apartmentId: $scope.consumerId,
        address: $scope.community.address,
        confirmationId: ""
      };
      var modalInstance = $uibModal.open({
        templateUrl: 'views/apartment-appointment-create.html',
        controller: 'ApartmentAppointmentCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          params: function () {
            return data;
          }
        }

      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.getConsumerProfile();
      }, function (res) {
        $scope.getConsumerProfile();
        $log.info('Modal dismissed at: ' + new Date());
      });


    };

    function getAppointments(aptId) {
      apartments.retrieveApartmentAppointments(aptId).then(function (res) {

        if (res.status == 'SUCCESS') {
          console.log(res)
          // $scope.pickupsEdit = res.data.sells.reverse();
          $scope.pickupsEdit = res.data.appointments;
          $scope.uniqueitems = [];
          $scope.uniqueitemcounts = [];

          $scope.pickupsEdit.forEach(function (elem, idx) {
            $scope.pickupsEdit[idx]['preferredSlotEndDate'] = $scope.pickupsEdit[idx]['preferredDate'] + " " + $scope.pickupsEdit[idx]['preferredEndTime'];

            if (moment() > moment($scope.pickupsEdit[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm') &&
              $scope.pickupsEdit[idx].status.match(/assigned|initiated|accepted|declined/i)) {
              $scope.pickupsEdit[idx]['preferredSlotExpired'] = true;
            }
            if (moment($scope.pickupsEdit[idx]['completedDate'] + " " + $scope.pickupsEdit[idx]['completedTime'], 'DD-MMM-YYYY HH:mm') > moment($scope.pickupsEdit[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm')) {
              $scope.pickupsEdit[idx]['completedAfterPreferredSlotExpired'] = true;
            }
          });
        }
      }, function (err) {
        //$ionicLoading.hide();
      })
    }

    $scope.OpenRequest = function (pickup) {
      pickup["consumerId"] = $scope.consumerId;
      console.log(pickup)

      var pickupInstance = $uibModal.open({
        templateUrl: 'views/apartment-appointment-details.html',
        controller: 'AppointmentDetailsCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return pickup;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.getConsumerProfile();
      }, function () {
        $scope.getConsumerProfile();
      });
    }

    $scope.openLocation = function () {
      var address = $scope.apartmentEdit.address;
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/openlocation.html',
        controller: 'OpenLocationModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {

            return address;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        //$scope.allRequests($scope.vm.params);
        $scope.apartmentDetails(apartmentIndex);
      });
    }
  });
