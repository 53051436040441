'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.saleOrderService
 * @description
 * # saleOrderService
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('saleOrderService', function (httpService, $global, $http, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            createSaleOrder: function (data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().createSaleOrder;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            }, getVendorSaleOrder: function (saleOrderConfirmationId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getVendorSaleOrder.replace(":id", saleOrderConfirmationId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, getSaleOrderForWarehouse: function (warehouseId, vendorId, fromDate, toDate) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getSaleOrderForWarehouse.replace(":id", warehouseId).replace(":vendorId", vendorId).replace(":fromDate", fromDate).replace(":toDate", toDate).replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, getPendingSaleOrdersForAVendor: function (vendorId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getPendingSaleOrdersForAVendor.replace(":vendorId", vendorId).replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, acceptVendorSaleOrder: function (saleOrderConfirmationId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().acceptVendorSaleOrder.replace(":id", saleOrderConfirmationId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, rejectVendorSaleOrder: function (saleOrderConfirmationId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().rejectVendorSaleOrder.replace(":id", saleOrderConfirmationId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, dispatchVendorSaleOrder: function (saleOrderConfirmationId, billNo, truckNo) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().dispatchVendorSaleOrder.replace(":id", saleOrderConfirmationId).replace(":billNo", billNo).replace(":truckNo", truckNo);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getSalesAccountingReports: function (data) {
                var url = $global.getApiUrl() + $global.getApiObject().getSalesAccountingReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getSaleOrderGrid: function (requestBody) {
                let url = $global.getApiUrl() + $global.getApiObject().getSaleOrderGrid;
                return httpService.httpRequest(url, "P", requestBody);                 
            },
            getSaleOrderDetails: function (saleOrderConfirmationId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getSaleOrderDetails.replace(":id", saleOrderConfirmationId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }            
        };
    });
