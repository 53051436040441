'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AgentEditProfileCtrl
 * @description
 * # AgentEditProfileCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AgentEditProfileCtrl', function ($scope, authentication, agentService, $stateParams, area, warehouseService,$window, growl, $global, $timeout, wholesalerService) {
    var items = $stateParams.id;
    /*===============================================global variables==================================================================*/

    (function () {
      $scope.mobileRegex = /^([0-9]{10})+$/;
      $scope.agentType = ["Residence", "Corporate"];
      $scope.serviceTypeArr = ["PICKUP", "DROPOFF", "RRR"];
      $scope.trackingTypeArr = ["ONETIME", "LIVE"];
    })();


    /*================================================Promises of API calls=====================================================*/

    const agentGetProfilePromise = (items) => {
      return new Promise((resolve, reject) => {
        agentService.getProfile(items)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              resolve(res);
            } else {
              reject('Error in calling the agent get profile API ');
            }
          })
          .catch(function (err) {
            reject('Error in calling the agent get profile API ', err);
          });
      });
    };

    const agentGetWarehousePromise = () => {
      return new Promise((resolve, reject) => {
        warehouseService.getWarehouse(-1)
          .then(function (res) {
            if (res.status === "SUCCESS") {
              resolve(res);
            } else {
              reject('Failed to get warehouse data');
            }
          })
          .catch(function (err) {
            reject('Error in calling the agent get warehouse API ', err);
          });
      })
    };

    const getAllAreasPromise = () => {
      return new Promise((resolve, reject) => {
        area.getArea()
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data && res.data.area) {
              resolve(res);
            } else {
              reject('Unable to retrieve area details');
            }
          })
          .catch(function (err) {
            reject(err);
          });
      });
    };

    const getAllAgentPriceCapsulesPromises = () => {
      return new Promise((resolve, reject) => {
        agentService.getAgentPriceCapsules()
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data && res.data.results) {
              resolve(res);
            } else {
              reject('Unable to retrieve agent price capsule details');
            }
          })
          .catch(function (err) {
            reject(err);
          })
      })
    };

    const getAllWholesalersPromise = () => {
      return new Promise((resolve, reject) => {
        wholesalerService.usersLoad("WHOLESALER", -1, true)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.users.length > 0) {
              resolve(res);
            } else {
              reject('Unable to retrieve wholesalers list details');
            }
          })
          .catch(function (err) {
            reject(err);
          })
      })
    };

    /*================================================Init service calls=====================================================*/
    (function () {
      agentGetProfilePromise(items)
        .then(function (res) {

          // logger
          $global.displayLog('agentGetProfilePromise', 'res', res);

          // set map center
          let latitude = res.data.agent.address.latitude;
          let longitude = res.data.agent.address.longitude;
          $scope.center = setMapCenterFromLatAndLong(latitude, longitude);

          //set view model
          $scope.data = res.data.agent;
          if ($scope.data && $scope.data.warehouse && $scope.data.warehouse.warehouseId) {
            $scope.data.warehouseId = $scope.data.warehouse.warehouseId;
          }

          //preselect area name
          if ($scope.data.area && $scope.data.area.length > 0) {
            $scope.areaSelectedArr = [];
            for (let area of $scope.data.area) {
              $scope.areaSelectedArr.push(area.id);
            }
          }

          getAllWholesalersPromise()
            .then(function (res) {
              $scope.wholesalersAvailableOptions = res.data.users;
              console.log("$scope.wholesalersAvailableOptions = ", $scope.wholesalersAvailableOptions);
              console.log("$scope.data.owner = ", $scope.data.owner);
              if ($scope.data.owner && $scope.data.owner.ownerId) {  // preselect partner drop down value
                console.log("$scope.data.owner.ownerId = ", $scope.data.owner.ownerId);
                $scope.data.wholesalerid = $scope.data.owner.ownerId;
              }
            })
            .catch(function (err) {
              console.error(err);
            });

          getAllAgentPriceCapsulesPromises()
            .then(function (res) {
              // logger
              $global.displayLog('getAllAgentPriceCapsulesPromises', 'res', res);

              // set view model
              $scope.priceCapsuleDetails = res.data.results;

              // pre-populate price capsule field
              if ($scope.data && $scope.data.priceCapsule && $scope.data.priceCapsule.id) {
                for (let priceCapsule of $scope.priceCapsuleDetails) {
                  if (priceCapsule.id === $scope.data.priceCapsule.id) {
                    $scope.priceCapsule = priceCapsule;
                    //setting characters to cut in price capsule's description within the dropdown
                    setCharactersToCut();
                    console.log("priceCapsule", $scope.priceCapsule)
                    break;
                  }
                }
              }
            })
            .catch(function (err) {
              console.error(err);
            })

        })
        .catch(function (err) {
          console.error(err);
        });

      agentGetWarehousePromise()
        .then(function (res) {
          // logger
          $global.displayLog('agentGetWarehousePromise', 'res', res);

          // set view models
          $scope.warehouse_list = res.data.warehouses;
        })
        .catch(function (err) {
          console.error(err);
        });

      getAllAreasPromise()
        .then(function (res) {
          // logger
          $global.displayLog('getAllAreasPromise', 'res', res);

          // set view model
          $scope.area_list = res.data.area;
        })
        .catch(function (err) {
          console.error(err);
        });
    })();


    /*================================================Core functions=====================================================*/
    $scope.save = function () {
      $scope.data.dataType = "agent";
      $scope.data.area = $scope.areaSelectedArr;

      if ($scope.priceCapsule) {
        $scope.data.priceCapsuleId = $scope.priceCapsule.id;
        $scope.data.priceCapsuleName = $scope.priceCapsule.name;
      }

      agentService.updateProfile($scope.data)
        .then(function (res) {
          if (res.status === "SUCCESS" && res.data && res.data.message) {
            growl.success(res.data.message);
            // $uibModalInstance.dismiss($scope.data);//TODO timeout
            $window.history.back();
          } else if (res.status === "FAILURE" && res.error && res.error.message) {
            growl.error(res.error.message)
          } else {
            growl.error("Failed to update agent details");
          }
        })
        .catch(function (err) {
          console.error('Error in calling the consumer.updateProfile API ', err);
        })
    };

    /*================================================Helper functions=====================================================*/
    function setMapCenterFromLatAndLong(latitude, longitude) {
      let center = [];
      center.push(latitude);
      center.push(longitude);
      return center.join();
    }

    $scope.markerDragEnd = function (event) {
      $timeout(function () {

        // get current lat and long object
        let latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

        // get address from the lat and long
        $global.getLocationByLatLng(latlng)
          .then(function (res) {
            if (res && res.formatted_address) {
              $scope.data.address.formattedAddress = res.formatted_address;
            } else {
              let errorMessage = 'Google map failed to fetch the address';
              growl.error(errorMessage);
              console.error(errorMessage);

              //TODO disable the submit here
            }
          })
          .catch(function (err) {
            console.error("Error in converting lat long to address ", err);
          });

        // position the map to the lat and lng
        $scope.center = setMapCenterFromLatAndLong(latlng.lat, latlng.lng);

        // changing form state
        $scope.EditAgentForm.$setDirty();

      })
    }

    $scope.cancel = function () {
      // $uibModalInstance.close('Close');
      $window.history.back();

    };

    $scope.searchForLocation = function () {
      let data = $scope.data.address.formattedAddress;
      console.log("$scope.data.address.formattedAddress", $scope.data.address.formattedAddress)
      authentication.getLocations(data).then(function (res) {
        console.log("response", res);
        $scope.predictions = res.predictions;
        $scope.showPredictions = true;
      }).catch(function (err) {
        console.log("err", err);
      })
    };

    $scope.locationSelected = function (obj) {
      console.log("clicked item", obj);
      $scope.data.address.formattedAddress = obj.description;
      $scope.showPredictions = false;
      authentication.getLatLng(obj.place_id).then(function (res) {
        console.log("response", res);
        let place = res.result;
        let obj = {};
        obj.lat = place.geometry.location.lat;
        obj.lng = place.geometry.location.lng;

        $scope.data.address.latitude = obj.lat;
        $scope.data.address.longitude = obj.lng;

        $scope.center = setMapCenterFromLatAndLong(obj.lat, obj.lng);
      }).catch(function (err) {
        console.log("err", err);
      });
    }

    function setCharactersToCut() {
      // calculating number of characters to cut in description display of Price Capsule dropdown based on dropdown's width wrt screen size
      $scope.charactersToCut = Math.round(document.getElementById("priceCapsule").offsetWidth / 7);
      console.log($scope.charactersToCut, document.getElementById("priceCapsule").offsetWidth);
    }

  });
