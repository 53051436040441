'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AppointmentDetailsCtrl
 * @description
 * # AppointmentDetailsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AppointmentDetailsCtrl', function ($scope, $rootScope, $log, $global, $uibModalInstance, consumer, growl, $filter, items, apartments, $uibModal, SellNow, NgMap, agentService, $moment) {
        $scope.feedItem = {};
        $scope.data = {};
        var latLngBound = [];

        $scope.inrFilter = $filter('INR');

        $scope.finalStatus = true;
        console.log(items);
        $scope.params = items;
        (function () {
            let columnDefs = [
              {
                headerName: "SellId",
                field: "confirmationId",
                maxWidth: 120,
                cellStyle: { "text-align": "left" },
              },
              {
                headerName: "Consumer",
                field: "consumerName",
                cellStyle: { "text-align": "left" },
              },
              {
                headerName: "Mobile",
                field: "consumerMobileNo",
                cellStyle: { "text-align": "left" },
              },
              {
                headerName: "Slot",
                field: "preferredSlot",
                maxWidth: 130,
                cellStyle: { "text-align": "left" },
              },
              {
                headerName: "Status",
                field: "status",
                maxWidth: 160,
                cellStyle: stringStyleFormatter,
                valueFormatter: valueFormatter,
              },
              {
                headerName: "Payment",
                field: "actualPaymentMethod",
                maxWidth: 110,
                cellStyle: { "text-align": "left" },
              },
              {
                headerName: "Paid",
                field: "totalAmount",
                cellStyle: { "text-align": "right" },
              },
            ];

            function valueFormatter(status) {
                let st = "";
                if (status.value == "Assigned To Agent") {
                  st = "ASSIGNED";
                }
                if (status.value == "Accepted By Agent") {
                  st = "ACCEPTED";
                }
                if (status.value == "Cancelled By Consumer") {
                  st = "CANCELLED";
                }
                if (status.value == "Declined By Agent") {
                  st = "DECLINED";
                }
                if (status.value == "Request Completed") {
                  st = "COMPLETED";
                }
                if (status.value == "Request Initiated") {
                  st = "INITIATED";
                }
                if (status.value == "Agent Arrived") {
                  st = "ARRIVED";
                }
                return st;
            }

            function stringStyleFormatter(params) {
              if (params.column.colId == "status") {
                return $scope.getStatusColor(params.data);
              }
            }

            $scope.getStatusColor = function (item) {
              let obj = {
                color: "white",
                textAlign: "center",
                fontSize: "11px !important",   
                fontWeight: "500",
                backgroundColor: "black",
                animation: 'none',
              };

              switch (item.status) {
                case "Assigned To Agent":
                  obj.backgroundColor = "#b5723b";
                  break;
                case "Accepted By Agent":
                  obj.backgroundColor = "blue";
                  break;
                case "Request Completed":
                  obj.backgroundColor = "green";
                  break;
                case "Cancelled By Consumer":
                  obj.backgroundColor = "black";
                  break;
                case "Declined By Agent":
                  obj.backgroundColor = "red";
                  break;
                case "Request Initiated":
                  obj.backgroundColor = "orange";
                  break;
                case "Agent Arrived":
                  obj.backgroundColor = "bluegit ";
                  obj.animation = "color 2s infinite both";
                  break;
              }
              return obj;
            };

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                suppressCsvExport: true,
                suppressExcelExport: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.data.modified) {
                        return { color: 'red', fontStyle: 'italic' };
                    }
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    // $scope.params.totalQuantity = _.sumBy($scope.sells, "totalQuantity");
                    // $scope.params.totalAmount = _.sumBy($scope.sells, "totalAmount");
                    $scope.gridOptions.api.setRowData($scope.sells);
                },
                onCellClicked: function (event) {
                    $scope.OpenSellRequest(event.data);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            }; //End of gridOptions//

            apartments.retrieveSellsForAppointment(items.communityAppointmentId).then((res) => {
                if (res.status !== "SUCCESS") {
                    console.error(res);
                    return;
                }
                $scope.sells = res.data.sells;

                if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData($scope.sells);
                }
            });

        })();

        function resetRatingTags(rating) {
            if (rating && $scope.ratings) {
                $scope.rating = $filter('filter')($scope.ratings, {
                    rating: rating
                })[0];
                $scope.ratingTags = $scope.rating.tags;
            }
        }
        $scope.$watch('feedItem.rating', function (newValue, oldValue) {
            resetRatingTags(newValue);
        });
        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
        $scope.ok = function (res) {
            if ($scope.feedItem && $scope.comments) {
                $scope.save();
            }
            if ($scope.comments) {
                $scope.saveComment();
            }
            if (!($scope.feedItem && $scope.comments)) {
                $uibModalInstance.dismiss(res);
            }
        };

        $scope.getSells = function () {
            console.log(items)
            apartments.retrieveAppointmentById(items.communityAppointmentId).then(function (res) {
                $scope.items = res.data.communityAppointment;
                console.log($scope.items);
                if ($scope.items && $scope.items.community) {
                    $scope.consumerLocation = $scope.items.community.address.latitude + "," + $scope.items.community.address.longitude;
                    var consumerLocation = [$scope.items.community.address.latitude, $scope.items.community.address.longitude];
                    latLngBound.push(consumerLocation);
                    console.log(latLngBound)
                }
                if ($scope.items && $scope.items.agentarrived) {
                    $scope.arrivedLocation = $scope.items.arrivedlat + "," + $scope.items.arrivedlong;
                    var arrivedLocation = [$scope.items.arrivedlat, $scope.items.arrivedlong];
                    latLngBound.push(arrivedLocation);
                }
                if ($scope.items && $scope.items.closedLat && $scope.items.closedLong) {
                    $scope.closedLocation = $scope.items.closedLat + "," + $scope.items.closedLong;
                    var closedLocation = [$scope.items.closedLat, $scope.items.closedLong];
                    latLngBound.push(closedLocation);
                }
                if ($scope.items.agentObjId) {
                    $scope.data.agentId = $scope.items.agentObjId;
                    if ($scope.items.preferredDate && $scope.items.preferredEndTime && $scope.items.preferredStartTime) {
                        var makeDate = moment($scope.items.preferredDate, "DD-mmm-YYYY").toDate();
                        var fromTime = parseInt($scope.items.preferredStartTime.split(":")[0]);
                        if (fromTime) {
                            fromTime = fromTime - 1;
                        }
                        var toTime = parseInt($scope.items.preferredStartTime.split(":")[0]);
                        var fromDate = new Date(makeDate);
                        fromDate = fromDate.setHours(fromTime);
                        fromDate = new Date(fromDate);
                        fromDate = fromDate.setMinutes(30);
                        var toDate;
                        if ($scope.items.statusCode == "REQUEST_COMPLETED") {
                            toDate = new Date(makeDate);
                            toDate = toDate.setHours(toTime);
                        } else {
                            toDate = new Date();
                        }
                        $scope.data.fromDate = $moment(new Date(fromDate)).format("DD-MMM-YYYY HH:mm:ss");
                        $scope.data.toDate = $moment(new Date(toDate)).format("DD-MMM-YYYY HH:mm:ss");
                        // $scope.data.fromDate = "28-Feb-2018 12:00:00";
                        // $scope.data.toDate = "28-Feb-2018 14:00:00";
                    }
                    getLocation();
                } else {
                    callBound();
                }

                $scope.items.totalAmount = (Math.round(parseFloat($scope.items.totalAmount) * 100) / 100).toString();
                $scope.items.totalQuantity = (Math.round(parseFloat($scope.items.totalQuantity) * 100) / 100).toString();


                if ($scope.items.paymentStatus == "SUCCESS") {
                    $scope.paymentStatus = true;
                }
            });
            apartments.retrieveSellsForAppointment(items.communityAppointmentId).then((res) => {
                if (res.status !== "SUCCESS") {
                    console.error(res);
                    return;
                }
                $scope.sells = res.data.sells;
                if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData($scope.sells);
                }
            });
            apartments.retrieveItemsForAppointment(items.communityAppointmentId).then((res) => {
                if (res.status !== "SUCCESS") {
                    console.error(res);
                    return;
                }
                $scope.items.items = res.data.items;
                if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData($scope.sells);
                }
            });
        }
        $scope.getSells();
        console.log($scope.finalStatus)

        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        //console.log($rootScope.loginData)

        function getfeendinRatings() {
            $scope.feedback = [];
            consumer.getPendingFeedBacks($scope.items.consumerObjId).then(function (res) {
                if (res.status == "SUCCESS") {
                    var feedbacks = res.data.feedbacks;
                    angular.forEach(feedbacks, function (feed) {

                        if ($scope.items.sellObjId == feed.sellId) // google.maps.setCenter($scope.center);
                        {
                            $scope.feedback.push(feed);
                            $scope.feedItem.feedbackId = feed.feedbackId;

                        }

                    });

                }
            });
        }

        $scope.save = function () {
            $scope.submit = true;
            if ($scope.feedItem.rating > 0) {
                consumer.submitFeedBack($scope.feedItem).then(function (res) {
                    if (res.status == "SUCCESS") {
                        growl.success(res.data.message || "Thank you for your feedback");
                        //$global.feedBackChecked = true;
                        //$uibModalInstance.dismiss('cancel');
                        getfeendinRatings();
                        $scope.saveComment();
                    } else {
                        growl.success(res.data.message);
                    }
                })
            }
        }

        $scope.editSellNow = function () {
            $scope.commentStatus = true;
            console.log($scope.items)
            var data = {
                "apartmentId": $scope.params.consumerId ? $scope.params.consumerId : $scope.items.community.communityId,
                "id": $scope.items.id,
                "communityAppointmentId": $scope.items.communityAppointmentId,
                "appointmentData": $scope.items
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'views/apartment-appointment-create.html',
                controller: 'ApartmentAppointmentCtrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    params: function () {
                        return data;
                    }
                }
            });
            modalInstance.result.then(function (selectedItem) {
                $scope.getSells();
            }, function (res) {
                $scope.getSells();
            });

        }
        $scope.reAssignAgent = function () {
            $scope.commentStatus = true;
            console.log($scope.items)
            var data = {
                apartmentId: $scope.items.consumerId,
                appointmentId: $scope.items.communityAppointmentId,
                sellId: $scope.items.communityAppointmentId,
                conLat: $scope.items.community.address.latitude,
                conLong: $scope.items.community.address.longitude,
                appointmentObjId: $scope.items.id,
                sellObjId: $scope.items.id,
                agentName: $scope.items.agentName
            }
            console.log(data)
            var modalInstance = $uibModal.open({
                templateUrl: 'views/reassign-agent-modal.html',
                controller: 'ReAssignAgentCtrl',
                size: 'lg',
                windowClass: 'myModal',
                backdrop: 'static',
                resolve: {
                    items: function () {
                        return data;
                    }
                }

            });
            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells()
            }, function (res) {
                $scope.getSells();
            });
        }

        function getComments() {
            consumer.getComments(items.sellObjId).then(function (res) {
                if (res.status == 'SUCCESS') {
                    if (res.data.comments.length > 0) {
                        $scope.sellComments = res.data.comments;
                    }
                } else {
                    growl.success(res.error.message || ' Unable To retrive comments');
                }
            })
        }
        // getComments();
        $scope.saveComment = function () {
            $scope.commentStatus = true;
            var obj = {
                objId: items.sellObjId,
                comment: $scope.comments.comment
            }
            consumer.addComment(obj).then(function (res) {
                if (res.status == 'SUCCESS') {
                    growl.success(res.data.message || ' Comment added successfully');
                }
                $scope.comments.comment = '';
                getComments();
                $scope.commentStatus = false;
                $uibModalInstance.dismiss(res);
            })
        }

        $scope.updateDetails = function () {

            $scope.commentStatus = true;
            $global.confirm("This will close all the pending appointments in the group with completed status").then(accept=>{
                let data = {};
                data.override = true;
                data.communityAppointmentObjId = $scope.items.id;
                data.agentObjId = $scope.items.agentObjId;
                apartments.completeAppointment(data).then((res) => {
                    if (res.status === "FAILURE") {
                        growl.error(res.error ? res.error.message : "Unable to complete request");
                        return;
                    }
                    growl.success("Successfully completed the appointment request");
                    $scope.cancel();
                });
            }, reject => {

            }).catch(err=>{

            });

        }

        $scope.declineSellItem = function () {
            $scope.commentStatus = true;
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/deleteConfirmation.html',
                controller: "DeleteCtrl",
                size: "md",
                resolve: {
                    items: function () {

                        return items;
                    }
                }
            });
            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells();
            }, function () {
                $scope.getSells()
            });
        }

        $scope.cancelSellItem = function () {
            $scope.commentStatus = true;
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/cancelConfirmation.html',
                controller: "DeleteCtrl",
                size: "md",
                resolve: {
                    items: function () {

                        return items;
                    }
                }
            });
            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells();
            }, function () {
                $scope.getSells()
            });
        }

        $scope.cancelSellRecurring = function () {
            var id = $scope.items.confirmationId;

            if (confirm("Are you sure you want to delete all recurring appointments following " + id + "?")) {
                $scope.items.agentObjId = true;
                SellNow.cancelSellRquestsRecurring(id).then(function (res) {
                    if (res.status = "SUCCESS") {
                        // $scope.todayAppointment.splice(index, 1);
                        growl.success(res.data.message);
                        $scope.ok(res);
                    } else if (res.status == "FAILURE") {
                        growl.error(res.error.errors[0].message);
                    }
                })
            }

        };

        $scope.selectWarehouse = function (warehouseId, type) {
            if (!items.warehouseId) {
                items.warehouseId = warehouseId;
            }
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/select-warehouse-modal.html',
                controller: 'SelectWarehouseCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    items: function () {

                        return items;
                    }
                }
            });

            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells()

            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                // $scope.loadPayments($scope.vm.params);
                $scope.getSells();
            });
        }

        $scope.makePayment = function (payment) {
            console.log($scope.items)
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/make-payment-modal.html',
                controller: 'MakePaymentCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    items: function () {

                        return items;
                    }
                }
            });

            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells()

            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                // $scope.loadPayments($scope.vm.params);
            });

        }

        $scope.OpenSellRequest = function (pickup) {

            let vmParams = {};

            var pickupInstance = $uibModal.open({
                templateUrl: 'views/appointments-modal.html',
                controller: 'AppointmentsModalCtrl',
                size: "lg",
                backdrop: 'static',
                resolve: {
                    items: function () {
                        return pickup;
                    },
                    vmParams: function () {
                        return vmParams;
                    }
                }
            });

            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $scope.getSells()
            }, function () {
                $scope.getSells()
            });
        };


        // Map code
        function initMap(bounds) {
            NgMap.getMap().then(function (map) {
                $scope.map = map;
                if (bounds) {
                    $scope.map.fitBounds(bounds);
                }
            });
        }
        initMap();

        // Polyline for track location
        function slidingwindow(a, n) {
            var r = []
            var count = 1;
            var first = 0;
            var last = n;
            for (var i = 0; i < Math.floor(a.length / n) + 1; i++) {
                var data = a.slice(first, last + 1);
                r.push(data);
                first = parseInt(last);
                last = parseInt(last + n);
            }
            return r;
        }
        var globalLocationData;
        $scope.filterCoordinates = [];
        $scope.renderPolyline = false;
        function callBound() {
            var bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < latLngBound.length; i++) {
                var position = new google.maps.LatLng(latLngBound[i][0], latLngBound[i][1]);
                bounds.extend(position);
            }
            if ($scope.map) {
                $scope.map.fitBounds(bounds);
            } else {
                initMap(bounds);
            }
        }
        function locationOprationForAgent(data) {
            globalLocationData = data.data.locations;
            if (globalLocationData.length > 0) {
                $scope.agentLastLocation = globalLocationData[globalLocationData.length - 1].latitude + "," + globalLocationData[globalLocationData.length - 1].longitude;
                var agentLastLocation = [globalLocationData[globalLocationData.length - 1].latitude, globalLocationData[globalLocationData.length - 1].longitude];
                latLngBound.push(agentLastLocation);
            }
            callBound();
            $scope.filterCoordinates = [];
            var coordinates = "", timestamp = "", latLng = "";
            var arr = slidingwindow(data.data.locations, 1999);
            async.forEachOf(arr, function (val1, key1, cb1) {
                coordinates = "";
                timestamp = "";
                latLng = "";
                var filterLatLng = "https://devapi.scrapq.com/match/v1/driving/:coordinates?timestamps=:timestamp&tidy=true";
                async.forEachOf(val1, function (val2, key2, cb2) {
                    coordinates += val2.longitude + "," + val2.latitude;
                    latLng += val2.latitude + "," + val2.longitude;
                    timestamp += parseInt(new Date(val2.timestamp).getTime() / 1000);
                    if (val1.length - 1 != key2) {
                        coordinates += ";";
                        timestamp += ";"
                        latLng += "|";
                    }
                    cb2();
                }, function (err) {
                    filterLatLng = filterLatLng.replace(":coordinates", coordinates);
                    filterLatLng = filterLatLng.replace(":timestamp", timestamp);
                    $.getJSON(filterLatLng).done(function (res) {
                        if (res) {
                            var sampleFilterPolyline = [];
                            async.forEachOf(res.tracepoints, function (val3, key3, cb3) {
                                if (val3 && val3.location) {
                                    var latLngArray = [];
                                    latLngArray.push(val3.location[1]);
                                    latLngArray.push(val3.location[0]);
                                    sampleFilterPolyline.push(latLngArray);
                                }
                                cb3();
                            }, function (err) {
                                $scope.filterCoordinates.push(sampleFilterPolyline);
                                cb1();
                            })
                        } else {
                            cb1();
                        }
                    }).fail(function (jqxhr, textStatus, error) {
                        cb1();
                    })
                })
            }, function (err) {
                //console.log($scope.filterCoordinates);
                $scope.renderPolyline = true;
            })
        }

        function getLocation() {
            console.log($scope.data)
            agentService.getAgentLocation($scope.data).then(function (res) {
                if (res.status == "SUCCESS") {
                    locationOprationForAgent(res);
                }
            }, function (err) {
                //console.log("Finally done");
            })
        }

        $scope.createSubAppointment = function (data) {

            let modalInstance = $uibModal.open({
                templateUrl: 'views/create-community-appointments-sell.html',
                controller: 'CommunityAppointmentsSellCtrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    consumerData: function () {
                        return data;
                    }
                }
            });
            modalInstance.result.then(function () {
                $scope.getSells()
            }, function (res) {
                $scope.getSells()
            });
        }

        $scope.validate = function () {

            if ($scope.mobileForm.$invalid) {
                growl.error("Please enter a valid mobile number");
                return;
            }

            consumer.searchConsumerRegistration($scope.mobileNo).then(function (res) {

                if (res.status === "SUCCESS") {

                    let totalRecords = res.data.totalRecords;

                    if (totalRecords !== 1) {
                        growl.error("No user found");
                        return;
                    }

                    growl.success("Successfully verified")

                    let data = {};
                    data.consumer = res.data.users[0];
                    data.communityAppointment = $scope.items;
                    $scope.createSubAppointment(data);

                } else {
                    growl.error("No user found");
                    let data = {};
                    data.consumerMobile = $scope.mobileNo;
                    data.communityAppointment = $scope.items;
                    $scope.createSubAppointment(data);
                    return;
                }

            });

        }

    });