'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.agentPickups
 * @description
 * # agentPickups
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('agentService', function(httpService, $global, $http, $rootScope) {
    return {
      getAgentpickups: function() {
        var url = $global.getApiUrl() + $global.getApiObject().agentsPickups;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAgent: function(type, key) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getAgent.replace(":userType", type).replace(":text", key).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      usersLoad: function(type, page, priceCapsuleId) {
        var url;
        var city = $rootScope.loginData.city;
        
        if (page > 0) {
          page = page - 1; // get paginated records, API pages are 0-based, but CRM does 1-based pages
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
        } else if (page < 0) {
          page = -1; // get all records
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
        } else {
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city);
        }
        
        if ($rootScope.loginData.role === 'WHOLESALER') {
          url += "&wholesalerid=" + $rootScope.loginData.userid;
        }

        if(priceCapsuleId){
          url += "&priceCapsuleId=" + priceCapsuleId;
        }
        
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      usersLoadAll: function(type) {
        var url;
        var city = $rootScope.loginData.city;
        url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + "-1";
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getProfile: function(agentId) {
        var agentId = agentId;
        var url = $global.getApiUrl() + $global.getApiObject().getAgentProfile.replace(":aid", agentId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellRquests: function(agentId) {
        var params = "?" + $global.objToQueryString(agentId);
        var url = $global.getApiUrl() + $global.getApiObject().getSellRquests + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellById: function(id) {
        var url = $global.getApiUrl() + $global.getApiObject().getSellById.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      addDocument: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().addAgentDocument;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      downloadDocument: function(id) {
        var url = $global.getApiUrl() + $global.getApiObject().getFileById.replace(":id", id);
        window.open(url, "_blank");
      },
      disableAgent: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().disableUser;
        var $request = httpService.httpRequest(url, "P", data);
        return $request
      },
      getInventory: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().inventory.replace(":agentId", data.agentId).replace(":fromDate", data.fromDate).replace(":toDate", data.toDate);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAgentLocation: function(data) {
        data.city = $rootScope.loginData.city;
        var url;
        if (data.agentId) {
          url = $global.getApiUrl() + $global.getApiObject().getAgentLocation.replace(":agentId", data.agentId).replace(":fromDate", data.fromDate).replace(":toDate", data.toDate).replace(":city", data.city);
        } else {
          url = $global.getApiUrl() + $global.getApiObject().getAgentLocation.replace("agentId=:agentId&", "").replace(":fromDate", data.fromDate).replace(":toDate", data.toDate).replace(":city", data.city);
        }
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      operatorDetails: function(id) {
        var url = $global.salesApiUrl() + $global.getApiObject().operatorDetails.replace(":userId", id);
        var $request = httpService.thirdPartyHttp(url, "G");
        return $request;
      },
      loadStatistic: function(data) {
        var url = $global.salesApiUrl() + $global.getApiObject().loadStatistic;
        if (data.fromDate && data.toDate) {
          url = url + "?fromDate=" + data.fromDate + "&toDate=" + data.toDate;
        }
        var $request = httpService.thirdPartyHttp(url, "G");
        return $request;
      },
      retrieveAgentSellDetails: function(agentId) {
        var agentId = agentId;
        var url = $global.getApiUrl() + $global.getApiObject().retrieveAgentSellDetails.replace(":id", agentId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      updateProfile: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateProfile.replace(":id", data.dataType);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getAgentAttendance: function(data) {
        let url = $global.getApiUrl() + $global.getApiObject().getAgentAttendance.replace(":agentId", data.agentId);
        
        if (data.page) {
          data.page -= 1;
        } else {
          data.page = 0;
        }
        
        url = url + "?page=" + data.page;
        
        if (data.fromDate) {
          url = url + "&fromDate=" + data.fromDate;
        }
        
        if (data.toDate) {
          url = url + "&toDate=" + data.toDate;
        }
        
        let $request = httpService.httpRequest(url, "G", data);
        return $request;
      },
      getAgentPriceCapsules: function() {
        var url = $global.getApiUrl() + $global.getApiObject().getUserPriceCapsules.replace(":userType", "AGENT").replace(":city", $rootScope.loginData.city);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      }
    };
  });
