'use strict';

angular.module('scrapQcrmApp')
	.controller('SellConsumerCtrl', function ($scope, products, authentication, platformSetting, consumer, $filter, ids, $uibModalInstance, $uibModal, holiday, $moment, $timeout, SellNow, $global, growl, $rootScope, NgMap) {
		$rootScope.loginData = {};
		$rootScope.loginData = $global.getLocalItem("loginData", true);
		$scope.data = {
			"consumerId": ids.consumerId,
			"address": ids.address,
			"items": [],
			"city": $rootScope.loginData.city
		};
		$scope.popup1 = {};
		$scope.datepickerObject = {};
		$scope.datepickerObjectPopup = {};
		$scope.isOpen = false;
		$scope.chosenPlace = {};
		$scope.holidays = [];
		let areSlotsAvailable = true;
		$scope.datePickerDisabled = false;
		(function () {
			platformSetting.getEnabledSettings("false").then(function (res) {
				//FIXME multiple redundant jump to access a variable
				if (res.status === "SUCCESS" && res.data && res.data.platformSettings && res.data.platformSettings.paymentSettings && res.data.platformSettings.paymentSettings.length > 0) {
					$scope.paymentSettingsOptions = res.data.platformSettings.paymentSettings;
					$scope.paymentSettingsArr = [];

					$scope.paymentSettingsOptions.forEach(function (element) {
						if (element.toggle) {
							$scope.paymentSettingsArr.push(element.paymentType);
						}
					})
				}
			});
		})();

		$scope.getHolidays = function (addressId) {
			$scope.datePickerDisabled = true;
			$scope.holidays = [];
			holiday.getAreaHolidaysForConsumer(addressId)
				.then(function (res) {
					if (res.status == "SUCCESS") {
						if (res && res.data && res.data.holidays) {
							$scope.holidays = res.data.holidays.map(el => { 
								if (el&&el.date) {
									return new Date(el.date)
								}
							});
						}
						$scope.datePickerDisabled = false;
					} else {
						if (res.error.code=="EC_INVALID_AREA") {
							growl.error("Area not serviceable");
						}
						$scope.datePickerDisabled = false;
					}
				})
				.catch(function (err) {
					$global.displayLog('get holiday for dateClicked', 'err', err);
				});
		};

		$scope.highlightDate = function (calendarDate) {
			const isDateInArray = $scope.isHoliday(calendarDate);
			if (isDateInArray) {
				return 'highlighted-date';
			}
			return '';
		};

		$scope.isHoliday = function(calendarDate) {
			let selectedDate = calendarDate;
			if (selectedDate && selectedDate.date) {
				selectedDate = selectedDate.date;
			}
			console.log('selectedDate : ',selectedDate);
			return $scope.holidays.some(date => {
				try {
					return (
						date &&
						selectedDate &&
						date.getFullYear() === selectedDate.getFullYear() &&
						date.getMonth() === selectedDate.getMonth() &&
						date.getDate() === selectedDate.getDate()
					);
				} catch(e) {
					console.error(e);
				}
			});
		};

		/**
		 * This below function is called when user clicks on Next button in the "Select items screen" to "Sell request screen" OR
		 * From edit button in consumer-pick modal or appointments-modal
		 */
		$scope.setDataForSellRequestModal = function () {
			$scope.data.items = [];
			if (ids.confirmationId) {
				$scope.backButton = true;
				consumer.getSellById(ids.confirmationId).then(
					function (res) {

						let sell = res.data.sell;

						if (sell) {

							if (sell.preferredPaymentMethod) {
								$scope.data.preferredPaymentMethod = sell.preferredPaymentMethod;
							}

							if (sell.items) {
								$scope.data.items = sell.items;
							}

							if (sell.preferredDate) {
								$scope.data.preferredDate = $moment(sell.preferredDate, "DD-MMM-YYYY").toDate();
								$scope.datepickerObjectPopup = {};
								$scope.datepickerObjectPopup.inputDate = $moment(sell.preferredDate, "DD-MMM-YYYY").toDate();
							}

							if (sell.preferredSlotId) {
								$scope.data.preferredSlot = sell.preferredSlotId;
							}

							if (sell.sellObjId) {
								$scope.data.sellId = sell.sellObjId;
							}

							if (sell.type) {
								$scope.data.type = sell.type;
							}

							if (sell.agentObjId) {
								$scope.data.agentId = sell.agentObjId;
							}

							if (sell.consumerAddress && sell.consumerAddress.addressId) {
								$scope.data.consumerAddressId = sell.consumerAddress.addressId;
							}
						}

						// for the uib-tab buttons to be selected by default based on the data.type value
						$scope.currentTab = $scope.data.type === 'DROP' ? 2 : 1;

						if ($scope.data.type === "DROP") {
							$scope.selectDrop = true;
							$scope.pickupat = false;
							$scope.dropat = true;
							$scope.reRenderMap();
						}


						SellNow.getAddress(ids.consumerId).then(
							function (res) {
								if (res && res.status === "SUCCESS" && res.data && res.data.addresses) {
									$scope.locations = res.data.addresses;
									$scope.selectedItem = $filter('filter')(res.data.addresses, { addressId: sell.consumerAddress.addressId })[0];
									let obj = {};
									obj.lat = $scope.selectedItem.latitude;
									obj.lng = $scope.selectedItem.longitude;
									$scope.setLocation(obj);
								} else {
									console.error("Empty or undefined result for SellNow.getAddress() service");
								}
							},
							function (error) {
								console.error("Error for SellNow.getAddress() service ", error);
							});

						SellNow.getSlots().then(
							function (res) {
								if (res && res.data && res.data.presentDaySlots) {
									$scope.allslots = res.data;
									let slots = res.data.presentDaySlots;
									for (let i = slots.length - 1; i >= 0; i--) {
										if (slots[i].slotId === $scope.data.preferredSlot) {
											slots[i].status = 'Enabled';
										}
									}
									$scope.slots = slots;
								} else {
									console.error("Empty or undefined result for SellNow.getSlots() service");
								}
							},
							function (error) {
								console.error("Error for SellNow.getSlots() service ", error);
							})
					},
					function (error) {
						console.error("Error for SellNow.getSellById service ", error);
					});

			} else {
				$scope.backButton = false;
				$scope.data.preferredPaymentMethod = "Cash";
				$scope.datepickerObjectPopup.inputDate = new Date();
				$global.setSellRequest($scope.categories.users);
				let sellRequestObj = $global.getSellRequest();
				for (let i = sellRequestObj.length - 1; i >= 0; i--) {

					let item = {};

					if (sellRequestObj[i].categoryId) {
						item.categoryId = sellRequestObj[i].categoryId;
					}

					if (sellRequestObj[i].name) {
						item.categoryName = sellRequestObj[i].name;
					}

					if (sellRequestObj[i].qty) {
						item.quantity = sellRequestObj[i].qty;
					}

					if (sellRequestObj[i].price) {
						item.pricePerUnit = parseFloat(sellRequestObj[i].price);
					}

					if (sellRequestObj[i].image) {
						item.image = sellRequestObj[i].image;
					}

					$scope.data.items.push(item);
				}

				SellNow.getSlots().then(
					function (res) {
						if (res && res.data && res.data.presentDaySlots) {
							$scope.allslots = res.data;
							$scope.slots = res.data.presentDaySlots;
							for (let i = $scope.slots.length - 1; i >= 0; i--) {
								if ($scope.slots[i].status !== "Disabled") {
									$scope.data.preferredSlot = $scope.slots[i].slotId;
									areSlotsAvailable = false;
								}
							}
							if (areSlotsAvailable) {
								$scope.slots.unshift({
									slotId: 0,
									slotName: "Set Next Slot",
									status: "Enable"
								});
								$scope.data.preferredSlot = 0;
							}
						} else {
							console.error("Empty or undefined result for SellNow.getSlots() service");
						}

					},
					function (error) {
						console.error("Error for SellNow.getSlots() service ", error);
					})
			}

			// get addresses of the consumer for any create or update sell requests
			SellNow.getAddress(ids.consumerId).then(
				function (res) {
					$scope.locations = res.data.addresses;
					if ($scope.locations[0] && $scope.locations[0].defaultAddress === 'YES') {
						$scope.selectedItem = $scope.locations[0];
						$scope.setMap($scope.selectedItem, 'drop');
						$scope.reRenderMap();
					} else {
						$scope.locations = res.data.addresses;
						if ($scope.locations.length > 0) {
							$scope.selectedItem = $scope.locations[0];
							$scope.setMap($scope.selectedItem, 'drop');
							$scope.reRenderMap();
						} else {
							$global.getCurrentLocation().then(function (res) {
								$scope.center = $scope.getLatLng(res);
							});

						}
					}
					$scope.reRenderMap();
					$scope.getHolidays($scope.selectedItem.addressId);
				},
				function (error) {
					console.error("Error for SellNow.getAddress() service ", error);
				});


			// modal title
			$scope.consumerTitle = "Sell Request";
		};

		(function () {
			if (!ids.confirmationId) { //FIXME: when is this block of code called?
				products.getProductsForConsumer(ids.address.addressId).then(
					function (res) {
						if (res.data.categories) {
							$scope.categories = res.data.categories;
							$scope.categories.users = [];
						}
					},
					function (error) {
						console.error("Error for SellNow.getProductsForConsumer() service ", error);
					});

				// modal title
				$scope.consumerTitle = "Select Items";

			} else {
				$scope.showSelectItemsSection = true;
				$scope.showSellRequestSection = true;
				$scope.showConfirmSection = false;
				$scope.setDataForSellRequestModal();
			}

			$scope.status = {
				opened: false
			};

		})();

		$scope.changeSlots = function () {
			let today = $moment().format('DD-MMM-YYYY');
			let current = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
			if (today !== current) {
				$scope.slots = $scope.allslots.allSlots;
				$scope.data.preferredSlot = $scope.slots[0].slotId;
			} else if ($scope.allslots) {
				$scope.slots = $scope.allslots.presentDaySlots;
			}
			else {
				console.error("Error in changing slots");
			}
		};

		(function () {
			let maxDate = new Date();
			maxDate.setFullYear(maxDate.getFullYear() + 1);
			$scope.dateOptions = {
				maxDate: maxDate,
				minDate: new Date(),
				startingDay: 1
			};
			$scope.dateOptionsPick = {
				customClass: $scope.highlightDate,
				maxDate: maxDate,
				minDate: new Date(),
				startingDay: 1
			};
		})();

		$scope.open = function ($event) {
			$scope.status.opened = true;
		};

		$scope.decreaseQty = function (index) {
			if ($scope.categories[index].qty > 1) {
				$scope.categories[index].qty = parseInt($scope.categories[index].qty) - 1;
			}
		};

		$scope.increaseQty = function (index) {
			$scope.categories[index].qty = parseInt($scope.categories[index].qty) + 1;
		};

		let map;
		NgMap.getMap().then(function (evtMap) {
			map = evtMap;
		});
		$scope.maps = [];
		$scope.$on('mapInitialized', function (evt, evtMap) {
			$scope.maps.push(evtMap);
		});
		$scope.reRenderMap = function () {
			$timeout(function () {
				angular.forEach($scope.maps, function (map) {
					let currCenter = map.getCenter();
					google.maps.event.trigger(map, 'resize');
					map.setCenter(currCenter);
				});
			}, 500);
		};

		$scope.vm = {};
		$scope.disableTap = function (drop) {
			let container = document.getElementsByClassName('pac-container');
			angular.element(container).attr('data-tap-disabled', 'true');
			angular.element(container).on("click", function () {
				if (drop) {
					document.getElementById('dropautocomplete').blur();
				} else {
					document.getElementById('autocomplete').blur();
				}
			});
		};
		$timeout(function () {
		}, $global.defaultTimeout);
		$scope.data.preferredDate = $moment().format('DD-MMM-YYYY');
		$scope.drop = {};

		function getAgents(obj) {
			SellNow.getAgentsByLatAndLong(obj.lat, obj.lng).then(
				function (res) {
					if (res && res.status === "SUCCESS" && res.data && res.data.addresses) {
						$scope.agents = res.data.addresses;
					}
				}, function (err) {
					console.error("Error in the service call getAgentsByLatAndLong ", err);
				})
		}

		$scope.placeChanged = function () {
			$scope.place = this.getPlace();
			let obj = {};
			obj.lat = $scope.place.geometry.location.lat();
			obj.lng = $scope.place.geometry.location.lng();
			getAgents(obj);
			$scope.setLocation(obj);
		};

		$scope.getLatLng = function (obj) {
			if (obj && obj.latitude && obj.longitude) {
				let latLng = [];
				latLng.push(obj.latitude);
				latLng.push(obj.longitude);
				return latLng.join();
			}
		};

		/**
		 * When user clicks on agent marker in the map
		 */
		$scope.setAgent = function () {
			$scope.drop.agentId = this.data;
			$scope.data.agentId = this.data.userId;
		};

		$scope.setDropAgent = function (userId) {
			for (let i = $scope.agents.length - 1; i >= 0; i--) {
				if ($scope.agents[i].userId === userId) {
					$scope.drop.agentId = $scope.agents[i];
					break;
				}
			}
		};

		/**
		 * When user clicks on confirm button for the pick up or drop off requests. <br>
		 *     if "ids.confirmationId" is present then it's an update functionality for either pick up OR drop requests
		 */
		$scope.sellNow = function (drop) {
			if (ids.confirmationId) { //update pickup or drop off functionality
				$scope.data.sellSubType = 'NOW';

				if (!$scope.place && !$scope.data.consumerAddressId) {
					return;
				}

				if (drop === 'DROP') {
					if (!$scope.drop.agentId || !$scope.drop.agentId.userId) {
						return;
					}
					$scope.data.agentId = $scope.drop.agentId.userId;
					$scope.data.agentAddressId = $scope.drop.agentId.addressId;
				}

				$scope.data.preferredDate = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');

				if (drop === "PICKUP") {
					if ($scope.place) {
						let SELLTYPE = "PICKUP";
						let address = $global.getAddressObj($scope.place);
						address.userId = ids.consumerId;
						address.userType = "CONSUMER";
						address.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
						SellNow.slotsCheckCreateAppointment($scope.data).then(
							function (slotsChecksResponse) {
								if (slotsChecksResponse.status === "SUCCESS") {
									if (slotsChecksResponse.data && slotsChecksResponse.data.presentDaySlots.length > 0) {
										let slotObj = slotsChecksResponse.data.presentDaySlots[$scope.data.preferredSlot];
										let slotCount = slotObj.count;
										if (slotCount >= 4) {
											SellNow.saveCosumerAddress(address).then(
												function (res) {
													$scope.data.consumerAddressId = res.data.address.addressId;
													$scope.data.city = $rootScope.loginData.city;
													SellNow.updatePickup($scope.data, SELLTYPE).then(function (response) {
														if (response.status = "SUCCESS") {
															growl.success(response.data.message);
															$uibModalInstance.dismiss('cancel');
														} else if (res.status === "FAILURE") {
															growl.error(response.error.errors[0].message);
														}
													})
												},
												function (err) {
													console.error("Error in the service call saveCosumerAddress ", err);
												})
										} else {
											$scope.data.city = $rootScope.loginData.city;
											SellNow.updatePickup($scope.data, SELLTYPE).then(
												function (response) {
													if (response.status = "SUCCESS") {
														growl.success(response.data.message);
														$uibModalInstance.dismiss('cancel');
													} else if (response.status === "FAILURE") {
														growl.error(response.error.errors[0].message);
													}
												},
												function (err) {
													console.error("Error in the service call updatePickup ", err);
												})
										}
									}
								} else if (slotsChecksResponse.error.code === "EC_AGENT_NOT_FOUND") {
									growl.error("No agents found within 5 KM.");
								} else {
									console.error("Empty or undefined results for slotsCheckCreateAppointment()");
								}
							},
							function (err) {
								console.error("Error in the service call slotsCheckCreateAppointment ", err);
							});
					} else {
						let SELLTYPE = "PICKUP";
						SellNow.slotsCheckCreateAppointment($scope.data).then(
							function (ress) {
								if (ress.status === "SUCCESS" && ress.data && ress.data.presentDaySlots.length > 0) {
									let slotObj = ress.data.presentDaySlots[$scope.data.preferredSlot];
									let slotCount = slotObj.count;
									if (slotCount >= 4) {
										let message = "There are ";
										message += slotCount + " appointments for agent on selected Date and slot. Do you want to Continue?";
										if (message && confirm(message)) {
											$scope.data.city = $rootScope.loginData.city;
											SellNow.updatePickup($scope.data, SELLTYPE).then(
												function (res) {
													if (res.status === "SUCCESS") {
														growl.success(res.data.message);
														$uibModalInstance.dismiss('cancel');
													} else if (res.status === "FAILURE") {
														growl.error(res.error.errors[0].message);
													} else {
														console.error("Empty or undefined results for updatePickup()");
													}
												},
												function (err) {
													console.error("Error in the service call updatePickup ", err);
												})
										}
									} else {
										$scope.data.city = $rootScope.loginData.city;
										SellNow.updatePickup($scope.data, SELLTYPE).then(
											function (res) {
												if (res.status === "SUCCESS") {
													growl.success(res.data.message);
													$uibModalInstance.dismiss('cancel');
												} else if (res.status === "FAILURE") {
													growl.error(res.error.errors[0].message);
												} else {
													console.error("Empty or undefined results for updatePickup()");
												}
											},
											function (err) {
												console.error("Error in the service call updatePickup ", err);
											})
									}

								} else if (ress.error.code === "EC_AGENT_NOT_FOUND") {
									growl.error("No agents found within 5 KM.");
								} else {
									console.error("Empty or undefined results for slotsCheckCreateAppointment()");
								}
							},
							function (err) {
								console.error("Error in the service call slotsCheckCreateAppointment ", err);
							})
					}
				} else { // drop-off functionality
					let SELLTYPE = "DROP";
					if ($scope.place) {
						let address = $global.getAddressObj($scope.place);
						address.userId = ids.consumerId;
						address.userType = "CONSUMER";
						address.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
						SellNow.saveCosumerAddress(address).then(
							function (res) {
								$scope.data.consumerAddressId = res.data.address.addressId;
								SellNow.updateDrop($scope.data, SELLTYPE).then(
									function (response) {
										if (res.status === "SUCCESS") {
											growl.success(res.data.message);
											$uibModalInstance.dismiss('cancel');
										} else if (res.status === "FAILURE") {
											growl.error(res.error.message);
										} else {
											console.error("Empty or undefined results for updateDrop() and saveCosumerAddress()");
										}
									},
									function (err) {
										console.error("Error in the service call updateDrop ", err);
									})
							},
							function (err) {
								console.error("Error in the service call saveCosumerAddress ", err);
							})
					} else {
						SellNow.updateDrop($scope.data, SELLTYPE).then(
							function (res) {
								if (res.status === "SUCCESS") {
									growl.success(res.data.message);
									$uibModalInstance.close('cancel');

								} else if (res.status === "FAILURE") {
									growl.error(res.error.message);
								}
							},
							function (err) {
								console.error("Error in the service call updateDrop ", err);
							})
					}
				}
			}
			// create pick up or drop off functionality
			else {
				let message = "Are you sure?";
				if ($scope.isHoliday($scope.datepickerObjectPopup.inputDate)) {
					message = "Selected date is not available. Do you want to continue?"
				}
				let today = $moment().format('DD-MMM-YYYY');
				let current = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
				if (current === today) {
					$scope.data.sellSubType = 'NOW';
				} else {
					$scope.data.sellSubType = 'APPOINTMENT';
				}

				if (!$scope.place && !$scope.data.consumerAddressId) {
					return;
				}
				if (drop === 'DROP') {
					if (!$scope.drop.agentId || !$scope.drop.agentId.userId) {
						return;
					}
					$scope.data.agentId = $scope.drop.agentId.userId;
					$scope.data.agentAddressId = $scope.drop.agentId.addressId;
				}
				$scope.data.preferredDate = $moment($scope.datepickerObjectPopup.inputDate).format('DD-MMM-YYYY');
				if ($scope.place) {
					let address = $global.getAddressObj($scope.place);
					address.userId = ids.consumerId;
					address.userType = "CONSUMER";
					address.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
					SellNow.slotsCheckCreateAppointment($scope.data).then(
						function (ress) {
							if (ress.status === "SUCCESS") {
								if (ress.data && ress.data.presentDaySlots.length > 0) {
									let slotObj = ress.data.presentDaySlots[$scope.data.preferredSlot];
									let slotCount = slotObj.count;
									if (slotCount >= 4) {
										message += "? There are ";
										message += slotCount + " appointments for agent on selected Date and slot. Do you want to Continue?";
										if (message && confirm(message)) {
											SellNow.saveCosumerAddress(address).then(
												function (res) {
													$scope.data.consumerAddressId = res.data.address.addressId;
													SellNow.sellNow($scope.data, drop).then(
														function (response) {
															growl.success(response.data.message);
															$scope.consumerTitle = "Confirmation";
															//TODO debug starts from here
															consumer.getSellById(response.data.confirmationId).then(
																function (res) {
																	if (res.status === "SUCCESS") {
																		$scope.items = res.data.sell;
																		$scope.showSellRequestSection = false;
																		$scope.showConfirmSection = true;
																	}
																}, function (err) {
																	console.error("Error in the service call getSellById ", err);
																});
														}, function (err) {
															console.error("Error in the service call sellNow ", err);
														})
												}, function (err) {
													console.error("Error in the service call saveCosumerAddress ", err);
												})
										}
									} else {
										message += "?";
										if (message && confirm(message)) {
											SellNow.saveCosumerAddress(address).then(
												function (res) {
													$scope.data.consumerAddressId = res.data.address.addressId;
													SellNow.sellNow($scope.data, drop).then(
														function (response) {
															growl.success(response.data.message);
															$scope.consumerTitle = "Confirmation";
															consumer.getSellById(response.data.confirmationId).then(
																function (res) {
																	if (res.status === "SUCCESS") {
																		$scope.items = res.data.sell;
																		$scope.showSellRequestSection = false;
																		$scope.showConfirmSection = true;
																	}
																}, function (err) {
																	console.error("Error in the service call getSellById ", err);
																});
														}, function (err) {
															console.error("Error in the service call sellNow ", err);
														})
												}, function (err) {
													console.error("Error in the service call saveConsumerAddress ", err);
												})
										}
									}
								}
							} else if (ress.error.code === "EC_AGENT_NOT_FOUND") {
								growl.error("No agents found within 5 KM.");
							}

						}, function (err) {
							console.error("Error in the service call slotsCheckCreateAppointment ", err);
						});

				} else {
					SellNow.slotsCheckCreateAppointment($scope.data).then(
						function (ress) {
							if (ress.status === "SUCCESS") {
								if (ress.data && ress.data.presentDaySlots.length > 0) {
									let slotObj = ress.data.presentDaySlots[$scope.data.preferredSlot];
									let slotCount = slotObj.count;
									if ($scope.isHoliday($scope.datepickerObjectPopup.inputDate)) {
										message = "Selected date is not available. Do you want to continue?"
									}
									if (slotCount >= 4) {
										if (message && confirm(message)) {
											SellNow.sellNow($scope.data, drop).then(
												function (res) {
													if (res.status === "SUCCESS") {
														growl.success(res.data.message);
														$scope.consumerTitle = "Confirm";
														consumer.getSellById(res.data.confirmationId).then(
															function (res) {
																if (res.status === "SUCCESS") {
																	$scope.items = res.data.sell;
																	$scope.showSellRequestSection = false;
																	$scope.showConfirmSection = true;
																}
															}, function (err) {
																console.error("Error in the service call getSellById ", err);
															});

													} else if (res.status === "FAILURE") {
														growl.error(res.error.message);
													}

												}, function (err) {
													console.error("Error in the service call sellNow ", err);
												})
										}
									} else {
										if (message && confirm(message)) {
											SellNow.sellNow($scope.data, drop).then(
												function (res) {
													if (res.status === "SUCCESS") {
														growl.success(res.data.message);
														$scope.consumerTitle = "Confirm";
														consumer.getSellById(res.data.confirmationId).then(
															function (res) {
																if (res.status === "SUCCESS") {
																	$scope.items = res.data.sell;
																	$scope.showSellRequestSection = false;
																	$scope.showConfirmSection = true;
																}
															}, function (err) {
																console.error("Error in the service call getSellById ", err);
															});

													} else if (res.status === "FAILURE") {
														growl.error(res.error.message);
													}

												}, function (err) {
													console.error("Error in the service call sellNow ", err);
												})
										}
									}
								}
							} else if (ress.error.code === "EC_AGENT_NOT_FOUND") {
								growl.error("No agents found within 5 KM.");
							}
						}, function (err) {
							console.error("Error in the service call slotsCheckCreateAppointment ", err);
						});
				}
			}
		};


		$scope.setMap = function (location, drop) {
			if (location) {
				let obj = {};
				$scope.data.consumerAddressId = location.addressId;
				obj.lat = location.latitude;
				obj.lng = location.longitude;
				if (drop) {
					getAgents(obj);
				}
				$scope.setLocation(obj);
				$scope.getHolidays(location.addressId);
			} else {
				$scope.data.consumerAddressId = null;
			}
		};

		$scope.setLocation = function (obj) {
			let center = [];
			center.push(obj.lat);
			center.push(obj.lng);
			$scope.center = center.join();
		};

		function setPlaceObject(latlng) {
			$global.getLocationByLatLng(latlng).then(
				function (res) {
					$scope.place = res;
					$scope.vm.formattedAddress = res.formatted_address;
				},
				function (err) {
					console.error("Error in the service call setPlaceObject ", err);
				})
		}

		$scope.setCurrentLocation = function () {
			$global.getCurrentLocation().then(
				function (latlng) {
					$scope.center = latlng.lat + "," + latlng.lng;
					$scope.reRenderMap();
					setPlaceObject(latlng)
				},
				function (err) {
					console.error("Error in the service call getCurrentLocation ", err);
				})
		};

		$scope.markerDragEnd = function (event) {
			$timeout(function () {
				let latlng = {
					lat: event.latLng.lat(),
					lng: event.latLng.lng()
				};
				setPlaceObject(latlng);
				$scope.center = latlng.lat + "," + latlng.lng;
			})
		};

		$scope.ok = function () {
			$uibModalInstance.dismiss('ok');
		};

		$scope.cancel = function () {
			$uibModalInstance.close('cancel');
		};

		$scope.setDataFill = function () {
			$scope.showSelectItemsSection = false;
			$scope.showSellRequestSection = false;
			$scope.showConfirmSection = false;
			$scope.consumerTitle = "Item Categories"
		};

		$scope.clearSearch = function (hide) {
			if (!hide) {
				$scope.vm.formattedAddress = '';
			}
			$scope.showPredictions = false;
		}

		$scope.searchForLocation = function () {
			let data = $scope.vm.formattedAddress;
			console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress)
			authentication.getLocations(data).then(function (res) {
				console.log("response", res);
				$scope.predictions = res.predictions;
				$scope.showPredictions = true;
			}).catch(function (err) {
				console.log("err", err);
			})
		};

		$scope.locationSelected = function (obj) {
			console.log("clicked item", obj);
			$scope.vm.formattedAddress = obj.description;
			$scope.showPredictions = false;
			authentication.getLatLng(obj.place_id).then(function (res) {
				console.log("response", res);
				let place = res.result;
				let obj = {};
				obj.lat = place.geometry.location.lat;
				obj.lng = place.geometry.location.lng;
				getAgents(obj);
				$scope.setLocation(obj);
			}).catch(function (err) {
				console.log("err", err);
			});
		}
	});
