'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:CustomPricingCtrl
 * @description
 * # CustomPricingCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('CustomPricingCtrl', function ($scope, products, consumer, $state, $global, $rootScope, $uibModal, envService, editableOptions, growl, pricingService, $log) {

        $scope.isEditOn = false;

        // ag-grid init
        var columnDefs = [
            {
                headerName: "Mobile Number",
                field: "mobile",
                filter: 'agTextColumnFilter',
                filterParams: {
                    debounceMs: 200,
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    buttons: ['reset', 'apply', 'cancel']
                },
                cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
            },
            {
                headerName: "Name",
                field: "name",
                filter: 'agTextColumnFilter',
                filterParams: {
                    debounceMs: 200,
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    buttons: ['reset', 'apply', 'cancel']
                },
                minWidth: 150,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: "Type",
                field: "addressType",
                filter: 'agSetColumnFilter',
                filterParams: {
                    debounceMs: 200,
                    suppressAndOrCondition: true,
                    buttons: ['reset', 'apply', 'cancel'],
                    values: params => {
                        const values = $scope.uniqueAddressTypeValues; // fetch values from server
                        params.success(values); // supply values to the set filter
                    },
                    closeOnApply: true
                },
                cellStyle: { 'text-align': 'left', 'padding-left': '60px' }
            },
            {
                headerName: "Products",
                field: "totalCustomPricedCategoriesCount",
                maxWidth: 150,
                sortable: false,
                filter: false,
                cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
            },
            {
                headerName: "Inception Date",
                field: "inceptionDate",
                filter: 'agDateColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply', 'cancel'],
                    closeOnApply: true,
                },
                cellStyle: { 'text-align': 'center', 'padding-right': '30px' }
            },
        ];

        let rowData = [];

        $scope.gridOptions = {
            columnDefs: columnDefs,
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowModelType: 'serverSide',
            pagination: true,
            cacheBlockSize: 25,
            paginationPageSize: 1000,
            rowStyle: { background: '#f4f4f4' },
            pagination: true,
            rowData: rowData,
            suppressCsvExport: true,
            suppressExcelExport: true,
            getRowStyle: function (params) {
                if (params.node.rowIndex % 2 === 0) {
                    return { background: 'white' };
                }
            },
            onGridReady: function (params) {
                $scope.gridOptions.api.setServerSideDatasource(dataSource);
            },
            onCellClicked: function (event) {
                // console.log(event)
                
                // Navigate to Consumer's Custom Pricing screen 
                let userId = event.data.userId;
                if (userId) {
                    consumer.setUserId(event.data.userId);
                    $state.go("root.customPricing");
                }
            },
            onFirstDataRendered: function (params) {
                params.api.sizeColumnsToFit();
            },
        };

        // implements ag-grid dataSource interface
        const dataSource = {
            getRows(params) {
                console.log("params.request = ", JSON.stringify(params.request, null, 2));

                let userAddressType = $scope.consumerbuckettype;
                let city = $rootScope.loginData.city;

                let requestBody = {};
                if (userAddressType) {
                    requestBody.userAddressType = userAddressType;
                }
                if (city) {
                    requestBody.city = city;
                }

                let startRow = params.request.startRow;
                let endRow = params.request.endRow;
                let sortModel = params.request.sortModel;
                let filterModel = params.request.filterModel;

                let agGridRequestParams = {};

                // pagination is present by default, no validation needed here
                agGridRequestParams.startRow = startRow;
                agGridRequestParams.endRow = endRow;

                if (sortModel && sortModel.length > 0) {
                    agGridRequestParams.sortModel = sortModel;
                }

                if (filterModel) {
                    agGridRequestParams.filterModel = filterModel;
                }

                requestBody.agGridRequestParams = agGridRequestParams;

                console.log("requestBody = ", JSON.stringify(requestBody, null, 2));

                consumer.getCustomersAgGrid(requestBody)
                    .then(function (res) {
                        if (res.status === "SUCCESS" && res.data.customerDetails && res.data.customerDetails.length > 0) {

                            // badges count
                            $scope.residenceCount = res.data.totalResidenceCustomersCount;
                            $scope.commercialCount = res.data.totalCommercialCustomersCount;
                            $scope.totalAgentRecords = res.data.totalCustomersCount; // TODO rename rename agent to customers

                            // set filtering values
                            $scope.uniqueAddressTypeValues = res.data.uniqueAddressTypeValues;
                            $scope.uniqueSourceValues = res.data.uniqueSourceValues;

                            params.successCallback(res.data.customerDetails, res.data.totalCustomersCount)
                        }
                        else {
                            console.error(res);
                            growl.error("No results found");
                            params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                        growl.error("No results found");
                        params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
                    })
            }
        };

    });