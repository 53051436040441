'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:VendorCtrl
 * @description
 * # VendorCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('VendorCtrl', function ($scope, $rootScope, $global, $uibModal, $timeout, $window, SellNow, growl, $log, $state, vendorService,documents, saleOrderService, warehouseService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment) {
    $scope.vm = { params: {} };
    $scope.isVendorDisabled = false;
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    $scope.date = {
      startDate: $moment().format("DD-MMM-YYYY"),
      endDate: $moment().format("DD-MMM-YYYY")
    };

    $scope.selectedStatus = "PENDING";
    $scope.selectedStatusId = 1;

    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };

    $scope.statusArr = ["ALL", "PENDING", "APPROVED", "REJECTED", "DISPATCHED"];
    $scope.vm = {
      params: { "city": $rootScope.loginData.city },
      hidecompleted: true
    };

    // ag-grid init
    (function () {
      var columnDefs = [
        {
          headerName: "",
          field: "action",
          maxWidth: 40,
          pinned: 'left',
          cellStyle: params => {
            if (!params.data.enabled) {
              return { 'border-left': '4px solid red' };
            }
            return { 'border-left': '4px solid #ffffff00' }
          },
          cellRenderer: ActionCellRenderer
        },
        {
          headerName: "Vendor Name",
          field: "vendorName",
          pinned: 'left',
        },
        {
          headerName: "Contact",
          field: "contact"
        },
        {
          headerName: "Mobile Number",
          field: "mobile",
          pinned: 'left',
          cellRenderer: HideMobileNumber,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "City",
          field: "city",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Products",
          field: "consumerProductsCount",
          cellStyle: { 'text-align': 'right', 'color': "#449c45", 'text-decoration': 'underline', 'cursor': 'pointer' }
        },
        {
          headerName: "Vendor Products",
          field: "vendorProductsCount",
          cellStyle: { 'text-align': 'right', 'color': "#449c45", 'text-decoration': 'underline', 'cursor': 'pointer' }
        },
        {
          headerName: "Pricing",
          field: "pricingExists",
          maxWidth: 115,
          valueFormatter: params => {
            if (params.value) {
              return 'Y'
            } else {
              return 'N'
            }
          },
          cellStyle: { 'text-align': 'center', 'color': "#449c45", 'text-decoration': 'underline', 'cursor': 'pointer' }
        },
        {
          headerName: "Sale Orders",
          field: "saleOrderCount",
          cellStyle: { 'text-align': 'right' }
        },
        {
          headerName: "Last Modified Date",
          field: "lastModifiedVendorProductPriceDate",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Inception Date",
          field: "inceptionDate",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Latitude",
          field: "address.latitude",
          hide: true
        },
        {
          headerName: "Longitude",
          field: "address.longitude",
          hide: true
        }
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        // paginationAutoPageSize: true,
        rowData: rowData,
        // suppressPaginationPanel:true,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          // params.api.sizeColumnsToFit();
          // params.columnApi.autoSizeAllColumns();
          $scope.gridOptions.api.setRowData($scope.vendor_list);
        },
        onCellClicked: function (event) {

          if (event && event.data && event.column.colId == "consumerProductsCount" || event.column.colId == "vendorProductsCount" || event.column.colId == "pricingExists") {
            $scope.openVendorItemsModal(event.data.vendorId, event.data.enabled);
          }
          else if (event && event.data && event.column.colId === "action" && event.data.enabled) {
            (getVendorProfile(event.data.vendorId))
              .then((val) => {
                $scope.editVendorDetails();
              })
          } else {
            $scope.vendorDetails(event.data.vendorId);
          }

        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };
    })();


    //Documents Ag-Grid
    var documentsColDefs = [
      {
        headerName: "Document Type",
        field: "name",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "",
        field: "download",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionDownloadCellRenderer,
        maxWidth: 70,
      },
      {
        headerName: "",
        field: "delete",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionDeleteCellRenderer,
        maxWidth: 70,
      }
    ];

    $scope.userDocuments = [];

    $scope.documentsGridOptions = {
      columnDefs: documentsColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.documentsGridOptions && $scope.documentsGridOptions.api) {
            $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        console.log(event)
        if (event.column.colId == "delete") {
          console.log(event.data)
          $scope.deleteDoc(event.data);
        }
        if (event.column.colId == "download") {
          $scope.downloadDoc(event.data.url);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    function ActionDownloadCellRenderer() {
    }

    ActionDownloadCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-download-alt"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionDownloadCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    function ActionDeleteCellRenderer() {
    }

    ActionDeleteCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-trash"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionDeleteCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    var saleOrdersColDef = [
      {
        headerName: "Warehouse",
        field: "warehouseName",
        cellStyle: { textAlign: 'left' },
        valueFormatter: function (params) {
          return params.data.warehouse.warehouseName;
        }
      },
      {
        headerName: "Vendor",
        field: "vendorName",
        cellStyle: { textAlign: 'left' },
        valueFormatter: function (params) {
          return params.data.vendor.vendorName;
        }
      },
      {
        headerName: "Confirmation Id",
        field: "saleOrderConfirmationId",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Total Price",
        field: "totalAmount",
        cellStyle: { textAlign: 'right' }
      },
      {
        headerName: "Status",
        field: "status",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Inception Date",
        field: "createdOn",
        cellStyle: { textAlign: 'center' }
      }
    ];

    $scope.saleOrdersGridOptions = {
      columnDefs: saleOrdersColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.saleOrdersGridOptions && $scope.saleOrdersGridOptions.api) {
            $scope.saleOrdersGridOptions.api.setRowData($scope.saleOrder_list);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    //

    var pricingColDefs = [
      {
        headerName: "Category",
        field: "productCategory",
        cellStyle: { textAlign: 'left' },

      },
      {
        headerName: "Product Name",
        field: "productName",
        cellStyle: { textAlign: 'left' },

      },
      {
        headerName: "Vendor Product Name",
        field: "itemName",
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: "Price",
        field: "vendorPrice",
        cellStyle: { textAlign: 'right' }
      },
      {
        headerName: "UOM",
        field: "uom",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "",
        field: "update",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionUpdateAndDeleteCellRenderer,
        maxWidth: 70,

      },
      {
        headerName: "",
        field: "delete",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionUpdateAndDeleteCellRenderer,
        maxWidth: 70,

      }
    ];

    $scope.pricingGridOptions = {
      columnDefs: pricingColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.pricingGridOptions && $scope.pricingGridOptions.api) {
            $scope.pricingGridOptions.api.setRowData($scope.vendorPrice_list);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        if (event && event.column.colId === "delete") {
          $scope.deleteVendorPrice(event.data);
        }
        if (event && event.column.colId === "update") {
          $scope.updateVendorPrice(event.data);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    function ActionDownloadCellRenderer() {
    }

    ActionDownloadCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-download-alt"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionDownloadCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };



    function ActionUpdateAndDeleteCellRenderer() {
    }

    ActionUpdateAndDeleteCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        console.log(params)
        $scope.actionButtonClassList = "";
        let iconsDiv = "";
        if (params.colDef.field == "delete") {
          iconsDiv = `<button type="button" class="btn btn-xs btn-danger" >Delete
          </button>`;
        } else {
          iconsDiv = `<button type="button" class="btn btn-xs btn-info" >Update
          </button>`;
        }
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionUpdateAndDeleteCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    function HideMobileNumber() {
    }

    HideMobileNumber.prototype.init = function (params) {
      this.mobileNumberDiv = document.createElement('div');
      if (params.data != "" || params.data != "undefined") {
        if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
          let mobileNo = params.data.mobile;
          this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        } else {
          let mobileNo = params.data.mobile;
          let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
          this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        }
      } else {
        this.mobileNumberDiv.innerHTML = "";
      }
    }

    HideMobileNumber.prototype.getGui = function () {
      return this.mobileNumberDiv;
    }

    function ActionCellRenderer() {
    }

    ActionCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="md md-mode-edit"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    $scope.openVendorPricingModal = function (vendorId) {

      var modalInstance = $uibModal.open({
        templateUrl: 'views/vendor-pricing-modal.html',
        controller: 'VendorPricingCtrl',
        backdrop: 'static',
        size: "lg",
        resolve: {
          items: function () {
            return null;
          },
          vendorId: function () {
            return vendorId;
          },
          header: function () {
            return "Add Vendor";
          }
        }

      });

      modalInstance.result.then(function () {
        console.log("closed");
      });
    }

    $scope.openVendorItemsModal = function (vendorId, enabled) {

      status = enabled ? "ENABLED" : "DISABLED";

      var modalInstance = $uibModal.open({
        templateUrl: 'views/vendor-items-modal.html',
        controller: 'VendorItemsModalCtrl',
        backdrop: 'static',
        size: "lg",
        resolve: {
          status: function () {
            return status;
          },
          vendorId: function () {
            return vendorId;
          },
          header: function () {
            return "Add Vendor";
          }
        }

      });

      modalInstance.result.then(function () {
        console.log("closed")
        //   $scope.selected = selectedItem;
        // }, function () {
        //   $scope.loadVendor();
      });
    }


    $scope.setStartDate = function () {
      $scope.date.startDate = moment().subtract(4, "days").toDate();
    };

    $scope.setRange = function () {
      $scope.date = {
        startDate: moment().subtract(5, "days"),
        endDate: moment()
      };
    };

    $scope.getUserDocuments = function (userId) {
      $scope.userDocuments = [];
      documents.retrieveUserDocuments({ userId: userId }).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.userDocuments = res.data.documents;
          $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
        }else{
          $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
        }
      })
    }

    $scope.loadVendor = function () {

      vendorService.getAllVendorsDetails().then(function (res) {
        if (res.status === "SUCCESS") {

          $scope.vendor_list = res.data.vendorListDetails.vendorDetails;

          //Empty String concatanations to avoid rendering "-" when the count is 0
          $scope.totalVendorRecords = "" + res.data.vendorListDetails.totalVendorsCount;
          $scope.activeVendorsCount = "" + res.data.vendorListDetails.activeVendorsCount;
          $scope.totalConsumerProductsCount = "" + res.data.vendorListDetails.totalConsumerProductsCount;
          $scope.totalVendorProductsCount = "" + res.data.vendorListDetails.totalVendorProductsCount;
          $scope.nonPricedVendorProductsCount = "" + res.data.vendorListDetails.nonPricedVendorProductsCount;

          $scope.length0 = false;
          $scope.vendorId = false;
          if ($scope.gridOptions && $scope.gridOptions.api) {
            $scope.gridOptions.api.setRowData($scope.vendor_list);
          }
          if (res.data.message === "No records found") {
            $scope.noVendorFound = true;
          }
        } else if (res.status === "FAILURE") {
          $scope.noVendorFound = true;
        } else {
          $scope.noVendorFound = true;
        }
      }, function (err) {

      })
    };

    if ($rootScope.loginData.role === "CRMUSER" || $rootScope.loginData.role === "ADMIN") {
      $scope.loadVendor($scope.vm.params);
    } else if ($rootScope.loginData.role === "VENDOR") {
      $scope.length0 = true;
      $scope.vendorId = $rootScope.loginData.userid;
      getVendorProfile($scope.vendorId);
      $scope.vendor_list = false;
    }

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withDisplayLength($scope.length);

    // Location track map operation start
    function initMapWithoutCenter() {
      $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
    }

    $scope.changeSort = function (type) {
      if (type === "category") {
        $scope.categorySort = !$scope.categorySort;
        $scope.sortType = "category";
        $scope.sortValue = $scope.categorySort;
      }
      if (type === "productName") {
        $scope.productNameSort = !$scope.productNameSort;
        $scope.sortType = "productName";
        $scope.sortValue = $scope.productNameSort;
      }
      if (type === "itemName") {
        $scope.itemNameSort = !$scope.itemNameSort;
        $scope.sortType = "itemName";
        $scope.sortValue = $scope.itemNameSort;
      }
    }

    $scope.vendorDetails = function (vendorId) {
      $scope.date = {
        startDate: $moment($global.projectInceptionDate).format("DD-MMM-YYYY"),
        endDate: $moment().format("DD-MMM-YYYY")
      };

      $scope.length0 = true;
      $scope.vendorId = vendorId;
      $scope.vendorId = vendorId;
      getVendorProfile(vendorId);
      $scope.vendor_list = false;
      $scope.vm.params = {
        "vendor": vendorId,
        "size": 10,
        "page": 0,
      };
    };
    $scope.downloadDoc = function (url) {
      window.open(url, "_blank");
    };

    $scope.deleteDoc = function (data) {

      documents.disableUserDocuments({ documentId: data.id }).then(function (res) {
        if (res.status == "SUCCESS") {
          growl.success("Document Deleted Successfully");
          $scope.getUserDocuments($scope.vendorId);
        } else {
          growl.error("Unable to delete document please try again")
        }
      }).catch(function () {
        growl.error("Unable to delete document please try again")
      })

    }
    function getVendorProfile(vendorId) {
      return new Promise((resolve, reject) => {
        vendorService.getProfile(vendorId).then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.profileEdit = res.data.vendor;
            // $scope.documentsGridOptions.api.setRowData($scope.profileEdit.documents);
            $scope.getUserDocuments(vendorId)
            resolve(res.status);
            $scope.isVendorDisabled = res.data.vendor ? false : true;
            // document parsing logic
            try {
              if (res && res.data && res.data.vendor && res.data.vendor.documents && res.data.vendor.documents.length > 0) {
                $scope.profileEdit.documentcount = res.data.vendor.documents.length;
              }
            }
            catch (e) {
              console.error('Error in getting document length ', e);
              $scope.profileEdit.documentcount = 0;
            }
            $scope.vendorPrice_list = [];

            $scope.centerdata = {
              "latitude": parseFloat($scope.profileEdit.address.latitude),
              "longitude": parseFloat($scope.profileEdit.address.longitude)
            };

            $scope.center = new google.maps.LatLng(parseFloat($scope.profileEdit.address.latitude), parseFloat($scope.profileEdit.address.longitude));

            // $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
            // initMapWithoutCenter();
            var mapOptions = {
              zoom: 17,
              mapTypeId: 'roadmap',
              center: $scope.center
            };
            $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            var icon = {
              url: "/images/pulse.gif",
              scaledSize: new google.maps.Size(30, 30),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(15, 15)
            };
            var marker = new google.maps.Marker({
              position: $scope.center,
              map: $scope.map,
              icon: icon
            });
            $scope.length0 = true;
            vendorService.retrieveAllItems().then(function (res) {
              if (res.status === "SUCCESS") {
                if (res.data.vendorItems.length > 0) {
                  $scope.itemsExist = true;
                  $scope.items_list = res.data.vendorItems;
                  vendorService.retrievePricingForVendor($scope.profileEdit.vendorId).then(function (res) {
                    if (res.status === "SUCCESS") {
                      if (res.data.items.length > 0) {
                        $scope.itemsExist = true;
                        $scope.vendorPrice_list = res.data.items;

                      } else {
                        $scope.itemsExist = false;
                      }
                    } else {
                      $scope.itemsExist = false;
                    }

                    if ($scope.vendorPrice_list && $scope.items_list) {
                      $scope.vendorPrice_list.forEach(function (element, index) {
                        $scope.items_list.forEach(function (elem, indx) {
                          if (element.vendorItemsId === elem.vendorItemsId) {
                            element['itemName'] = elem.itemName;
                            element['uom'] = elem.uom;
                          }
                        })
                      });
                    };
                    $scope.pricingGridOptions.api.setRowData($scope.vendorPrice_list);

                  });


                } else {
                  $scope.itemsExist = false;
                }
              } else {
                $scope.itemsExist = false;
              }
            });
          } else if (res.status === "FAILURE") {
            $scope.noVendorFound = "No vendor record found";
            reject(res.status);
          }
          //$scope.profileEdit = res.data.agent;
        }, function (err) {
          //console.log(err);
        });
      });
    }

    $scope.customIcon = {
      "scaledSize": [32, 32],
      "url": "http://www.cliparthut.com/clip-arts/823/arrowhead-clip-art-823528.png"
    };

    $scope.addVendor = function () {

      $state.go("root.add-vendor", { header: "Add Vendor", items: null, type: "ADD" });

      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/add-vendor.html',
      //   controller: 'AddVendorCtrl',
      //   backdrop: 'static',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return null;
      //     },
      //     header: function () {
      //       return "Add Vendor";
      //     }
      //   }
      // });

      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   $scope.loadVendor();
      // });

    };

    $scope.disableVendor = function () {
      // call API to get all pending SO's
      let promise = new Promise((resolve, reject) => {
        saleOrderService.getPendingSaleOrdersForAVendor($scope.profileEdit.vendorId).then(res => {
          if (res.status === "SUCCESS") {
            let saleOrderCount = res.data.saleOrder ? res.data.saleOrder.length : 0;
            resolve(saleOrderCount);
          } else {
            console.error(res);
            growl.error("Unable to retrieve pending sale orders for the vendor, Please try later");
            return;
          }
        })
          .catch(err => {
            console.error("Error in getting Pendig Sale Orders: ", err);
            growl.error("Unable to retrieve pending sale orders for the vendor");
            return;
          });
      });

      promise.then(saleOrderCount => {
        let confirmationMessage = `${$scope.profileEdit.vendorName} has ${saleOrderCount ? saleOrderCount : 'no'} active Sale Orders. All ${saleOrderCount ? '' : 'active'} Sale Orders will be rejected before disabling. Confirm?`;

        if (confirm(confirmationMessage)) {
          let obj = { vendorId: $scope.vendorId };
          vendorService.disableVendor(obj).then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success(res.data.message);
              $timeout(function () {
                $scope.loadVendor();
              }, 1000);
            } else if (res.status === "FAILURE") {
              growl.error(res.error.message);
            } else {
              $scope.loadVendor();
            }
          }).catch(function (err) {
            console.error("Error in disabling Vendor :", err);
            growl.error("Failed to disable Vendor");
          })
        }
      });

    }

    $scope.enableVendor = function () {
      let confirmationMessage = `Are you sure you want to enable this vendor?`
      if (confirm(confirmationMessage)) {
        let obj = { vendorId: $scope.profileEdit.vendorId };
        vendorService.enableVendor(obj).then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success(res.data.message);
            $timeout(function () {
              $scope.loadVendor();
            }, 1000);
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message);
          } else {
            $scope.loadVendor();
          }
        }).catch(function (err) {
          console.error("Error in enableVendor :", err);
          growl.error("Failed to enable Vendor");
        })
      }
    }

    $scope.editVendorDetails = function () {


      $state.go("root.add-vendor", { header: "Edit Vendor", items: $scope.profileEdit, type: "EDIT" });

      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/add-vendor.html',
      //   controller: 'AddVendorCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return $scope.profileEdit;
      //     },
      //     header: function () {
      //       return "Edit Vendor"
      //     }
      //   }
      // });

      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   getVendorProfile($scope.vendorId);
      // });
    };


    $scope.addVendorPrice = function () {
      $scope.openVendorItemsModal($scope.profileEdit.vendorId, $scope.profileEdit.enabled);
    };


    $scope.updateVendorPrice = function (vendorItem) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/vendor-items-price-modal.html',
        controller: 'VendorItemsPriceCtrl',
        size: "md",
        resolve: {
          items: function () {
            return $scope.profileEdit.vendorId;
          },
          vendorUpdateItem: function () {
            return vendorItem;
          },
          modalHeader: function () {
            return "Update Vendor Pricing";
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $log.info('Modal dismissed at: ' + new Date());
        getVendorProfile($scope.profileEdit.vendorId);
      }, function () {
      });
    };

    $scope.deleteVendorPrice = function (vendorItem) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/vendor-items-price-modal.html',
        controller: 'VendorItemsPriceCtrl',
        size: "md",
        resolve: {
          items: function () {
            return $scope.profileEdit.vendorId;
          },
          vendorUpdateItem: function () {
            return vendorItem;
          },
          modalHeader: function () {
            return "Delete Vendor Pricing";
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getVendorProfile($scope.profileEdit.vendorId);
      }, function () {
      });
    };

    //Watch for date changes
    $scope.$watch('date', function (newDate) {
      //   let todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
      //   let fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
      //   if($scope.vendorId){
      //     $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, fromdate, todate);
      //   }
      if ($scope.vendorId) {
        $scope.transactionChange();
      }
    }, false);

    $scope.nextDate = function () {
      $scope.paging = false;
      $scope.date = {
        startDate: moment($scope.date.endDate).add(1, "days"),
        endDate: moment($scope.date.endDate).add(1, "days")
      };
      // $scope.transactionChange();
    }
    $scope.previousDate = function () {
      $scope.paging = false;
      $scope.date = {
        startDate: moment($scope.date.startDate).subtract(1, "days"),
        endDate: moment($scope.date.startDate).subtract(1, "days")
      };
      // $scope.transactionChange();
    }

    $scope.statusSelect = function (option) {
      // console.log("OPTION", $scope.selectedStatus);
      if ($scope.selectedStatus == 'PENDING' && $scope.pendingCount > 0) {
        $scope.selectedStatusId = 1;
        $scope.noContent = false;
      } else if ($scope.selectedStatus == 'APPROVED' && $scope.approvedCount > 0) {
        //   console.log("APPROVED", $scope.approvedCount)
        $scope.noContent = false;
        $scope.selectedStatusId = 2;
      } else if ($scope.selectedStatus == 'REJECTED' && $scope.rejectedCount > 0) {
        $scope.selectedStatusId = 3;
        $scope.noContent = false;
      } else if ($scope.selectedStatus == 'DISPATCHED' && $scope.dispatchedCount > 0) {
        $scope.selectedStatusId = 4;
        $scope.noContent = false;
      } else if ($scope.selectedStatus == 'ALL') {
        $scope.selectedStatus = null;
        $scope.selectedStatusId = null;
      } else {
        $scope.noContent = true;
      }
    }

    $scope.transactionChange = function () {

      $scope.vm.params.singledayquery = false;
      $scope.totalAgentRecords = 0;
      $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
      $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');

      if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
        $scope.vm.params.singledayquery = true;
      }
      // $scope.vm.params.status =  'ASSIGNED_TO_AGENT';// 'PENDING';
      // console.log($scope.selectedVendorId)
      // console.log($scope.selectedWarehouseId)
      if ($rootScope.loginData.role === 'CRMUSER' || $rootScope.loginData.role === 'ADMIN' || $rootScope.loginData.role === 'MASTERFRANCHISE') {
        if ($scope.vendorId != null && $scope.selectedWarehouseId != null) {
          $scope.getSaleOrderForWarehouse($scope.selectedWarehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        } else {
          if ($scope.vendorId != null) {
            $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          } else if ($scope.selectedWarehouseId != null) {
            $scope.getSaleOrderForWarehouse($scope.selectedWarehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          } else {
            $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          }
        }
      } else if ($rootScope.loginData.role === 'WAREHOUSEUSER') {
        if ($scope.vendorId != null) {
          $scope.getSaleOrderForWarehouse($rootScope.loginData.warehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        } else {
          $scope.getSaleOrderForWarehouse($rootScope.loginData.warehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        }
      }
    };


    $scope.getSaleOrderForWarehouse = function (warehouseId, vendorId, fromdate, todate) {
      $scope.pendingCount = 0;
      $scope.approvedCount = 0;
      $scope.rejectedCount = 0;
      $scope.dispatchedCount = 0;
      saleOrderService.getSaleOrderForWarehouse(warehouseId, vendorId, fromdate, todate).then(function (res) {
        // console.log(res)
        if (res.status == "SUCCESS") {
          if (res.data.saleOrder) {
            $scope.saleOrder_list = res.data.saleOrder;
            if ($scope.saleOrder_list.length == 0) {
              $scope.noSaleOrderFound = true;
            } else {
              $scope.saleOrder_list.forEach(function (element, index) {
                var totalAmount = 0.0;
                element.saleOrderItems.forEach(function (elem, indx) {
                  elem.totalPrice = elem.totalPrice.replace(',', '');
                  totalAmount = totalAmount + parseFloat(elem.totalPrice);
                });
                element['totalAmount'] = totalAmount;

                if (element.status == "PENDING") {
                  element['statusNo'] = 1;
                  $scope.pendingCount = $scope.pendingCount + 1;
                } else if (element.status == "APPROVED") {
                  element['statusNo'] = 2;
                  $scope.approvedCount = $scope.approvedCount + 1;
                } else if (element.status == "REJECTED") {
                  element['statusNo'] = 3;
                  $scope.rejectedCount = $scope.rejectedCount + 1;
                } else if (element.status == "DISPATCHED") {
                  element['statusNo'] = 4;
                  $scope.dispatchedCount = $scope.dispatchedCount + 1;
                }
              });
              //   console.log($scope.saleOrder_list)
              $scope.noSaleOrderFound = false;

              if ($scope.dispatchedCount > 0 || $scope.rejectedCount > 0
                || $scope.approvedCount > 0 || $scope.pendingCount > 0) {
                $scope.noSaleOrderFound = false;
              } else {
                $scope.noSaleOrderFound = true;
              }
            }
            $scope.saleOrdersGridOptions.api.setRowData($scope.saleOrder_list);

          } else {
            $scope.noSaleOrderFound = true;
          }
        } else {
          $scope.noSaleOrderFound = true;
        }
      })
    }

    $scope.downloaddoc = function (docid) {
      // alert('To do');
      vendorService.downloadDocument(docid).then(function (res) {
        if (res.status === "SUCCESS") {
        } else if (res.status === "FAILURE") {
        } else {
        }
      }, function (err) {

      });
    };

    $scope.addDocument = function (vendorId) {
      console.log(vendorId)
      $scope.document_vendorId = vendorId;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-document.html',
        controller: 'AddDocumentCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          reqId: function () {
            return vendorId;
          },
          from: function () {
            return "VENDOR";
          }
        }
      });
      modalInstance.result.then(function (res) {
        console.log("Modal closed :", res);
      }, function () {
        $scope.getUserDocuments($scope.vendorId);
        getVendorProfile($scope.vendorId);
      });
    };

    //my changes
    $scope.OpenRequest = function (saleOrder) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/sale-order-modal.html',
        controller: 'SaleOrderModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          saleOrder: function () {

            return saleOrder;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.selectWarehouseVendor();
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        $scope.selectWarehouseVendor();
      });
    }

    $scope.selectWarehouseVendor = function () {
      // console.log($scope.selectedWarehouseId)
      // console.log($scope.warehouse_list)
      // console.log($scope.selectedVendorId)
      if ($rootScope.loginData.role == 'CRMUSER' || $rootScope.loginData.role == 'ADMIN' || $rootScope.loginData.role == 'MASTERFRANCHISE') {
        if ($scope.vendorId != null && $scope.selectedWarehouseId != null) {
          $scope.getSaleOrderForWarehouse($scope.selectedWarehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        } else {
          if ($scope.vendorId != null) {
            $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          } else if ($scope.selectedWarehouseId != null) {
            $scope.getSaleOrderForWarehouse($scope.selectedWarehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          } else {
            $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
          }
        }
      } else if ($rootScope.loginData.role == 'WAREHOUSEUSER') {
        if ($scope.vendorId != null) {
          $scope.getSaleOrderForWarehouse($rootScope.loginData.warehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        } else {
          $scope.getSaleOrderForWarehouse($rootScope.loginData.warehouseId, $scope.vendorId, $scope.vm.params.fromdate, $scope.vm.params.todate);
        }
      }
    }

    warehouseService.warehousesLoad("WAREHOUSE").then(function (res) {
      // console.log(res)
      if (res.status == 'SUCCESS') {
        if (res.data.warehouses) {
          $scope.warehouse_list = res.data.warehouses;
        }
      }
    })

    $scope.refreshSaleOrderPage = function () {
      $scope.date = {
        startDate: $moment($global.projectInceptionDate).format("DD-MMM-YYYY"),
        endDate: $moment().format("DD-MMM-YYYY")
      };
      $scope.getSaleOrderForWarehouse("ALL", $scope.vendorId, $moment($global.projectInceptionDate).format('DD-MMM-YYYY'), $moment().format('DD-MMM-YYYY'));
    }

    /*if ($rootScope.loginData.role == 'CRMUSER' || $rootScope.loginData.role == 'ADMIN' || $rootScope.loginData.role == 'MASTERFRANCHISE') {
        $scope.getSaleOrderForWarehouse("ALL", "ALL", $scope.date.startDate, $scope.date.endDate);
    } else if ($rootScope.loginData.role == 'WAREHOUSEUSER') {
        $scope.getSaleOrderForWarehouse($rootScope.loginData.warehouseId, "ALL", $scope.date.startDate, $scope.date.endDate);
    }*/

  });
