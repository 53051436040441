'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ReportsController
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('ReportsController', function ($scope, $rootScope, $global, authentication, $stateParams, $http, $state, $moment) {
        //Global scope
        $scope.date = $moment().format('DD-MMM-YYYY');
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        $scope.userRoleMetrics = [];
        $scope.reportsAccessObj = {
            appointmentWiseReport: false,
            agentWiseReport: false,
            warehouseWiseReport: false,
            customerAddressTypeWiseReport: false,
            productWisePurchanseReport: false,
            salesAccountingReport: false,
            productWiseSalesReport: false,
            productWiseConsolidatedReport: false,
            vendorWiseSalesReport: false,
            salesTeamReport: false,
            inventoryPurchaseReport: false,
            saleOrderSalesReport: false,
            dailyDashBoardReport: false,
            celektRRRWiseReport: false,
            celektAppointmentWiseReport: false,
            celektProductWiseReport: false
        };


        $scope.setReportsAccessObj = function (metrics) {
            metrics.forEach(function (mObj) {
                if (mObj.subModule == "AppointmentWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.appointmentWiseReport = true;
                }
                if (mObj.subModule == "GroupWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.groupWiseReport = true;
                }
                if (mObj.subModule == "AgentWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.agentWiseReport = true;
                }
                if (mObj.subModule == "WarehouseWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.warehouseWiseReport = true;
                }
                if (mObj.subModule == "CustomerAddressTypeWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.customerAddressTypeWiseReport = true;
                }
                if (mObj.subModule == "Inventory" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.inventoryPurchaseReport = true;
                }
                if (mObj.subModule == "SaleOrder" && mObj.type == "Sales") {
                    $scope.reportsAccessObj.saleOrderSalesReport = true;
                }
                if (mObj.subModule == "ProductWise" && mObj.type == "Purchase") {
                    $scope.reportsAccessObj.productWisePurchanseReport = true;
                }
                if (mObj.subModule == "ProductWise" && mObj.type == "Consolidated") {
                    $scope.reportsAccessObj.productWiseConsolidatedReport = true;
                }
                if (mObj.subModule == "ProductWise" && mObj.type == "Sales") {
                    $scope.reportsAccessObj.productWiseSalesReport = true;
                }
                if (mObj.subModule == "VendorWise" && mObj.type == "Sales") {
                    $scope.reportsAccessObj.vendorWiseSalesReport = true;
                }
                if (mObj.subModule == "Accounting" && mObj.type == "Sales") {
                    $scope.reportsAccessObj.salesAccountingReport = true;
                }
                if (mObj.subModule == "SalesTeam" && mObj.type == "Other") {
                    $scope.reportsAccessObj.salesTeamReport = true;
                }
                if (mObj.subModule == "DailyDashboard" && mObj.type == "Other") {
                    $scope.reportsAccessObj.dailyDashBoardReport = true;
                }
                if (mObj.subModule == "PaymentStatus" && mObj.type == "Payments") {
                    $scope.reportsAccessObj.paymentStatusReport = true;
                }
                if (mObj.subModule == "PaymentFraudCheck" && mObj.type == "Payments") {
                    $scope.reportsAccessObj.paymentFraudCheckReport = true;
                }
                if (mObj.subModule == "AppointmentWise" && mObj.type == "Celekt") {
                    $scope.reportsAccessObj.celektAppointmentWiseReport = true;
                }
                if (mObj.subModule == "RRR" && mObj.type == "Celekt") {
                    $scope.reportsAccessObj.celektRRRWiseReport = true;
                }
                if (mObj.subModule == "ProductWise" && mObj.type == "Celekt") {
                    $scope.reportsAccessObj.celektProductWiseReport = true;
                }
            });
            console.log($scope.reportsAccessObj);
        }

        // Init Data 
        $scope.Init = function () {
            console.log("Init reports");
            $scope.getReportsUserMetrics = function () {
                var data = {
                    "city": $rootScope.loginData.city,
                    "userType": $rootScope.loginData.role,
                    "module": "Reports"
                };
                authentication.getUserRoleMetrics(data).then(function (res) {
                    if (res.status == "SUCCESS") {
                        $scope.userRoleMetrics = res.data.userRoleMatrix;
                        $scope.setReportsAccessObj($scope.userRoleMetrics);
                    } else {
                        $scope.userRoleMetrics = [];
                    }
                }).catch(function (err) {
                    $scope.userRoleMetrics = [];
                })
            };

            $scope.getReportsUserMetrics();
        };

        $scope.Init();

        $scope.findReportDisabledFeilds = function (subModule, type) {
            let metrixObj = $scope.userRoleMetrics.find(function (mObj) {
                return mObj.subModule.toLowerCase() == subModule.toLowerCase() && mObj.type.toLowerCase() == type.toLowerCase();
            });
            if (metrixObj) {
                return metrixObj.disabledFields;
            } else {
                return [];
            }
        }

        $scope.loadAppointmentWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("AppointmentWise", "Purchase");
            $state.go('root.appointment-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }

        $scope.loadGroupWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("GroupWise", "Purchase");
            $state.go('root.group-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }

        $scope.loadAgentWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("AgentWise", "Purchase");
            $state.go('root.agent-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadProductWiseReports = function (type) {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("ProductWise", type);
            $state.go('root.product-wise-reports', { fromdate: currentDate, todate: currentDate, type: type, disabledFeilds: disabledFeilds });
        }
        $scope.wareHouseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("WarehouseWise", "Purchase");
            $state.go('root.warehouse-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadCustomerWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("CustomerAddressTypeWise", "Purchase");
            $state.go('root.customer-address-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadInventoryPurchaseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("Inventory", "Purchase");
            $state.go('root.inventory-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadSaleOrderReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("SaleOrder", "Sales");
            $state.go('root.sale-order-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadAccountingReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("Accounting", "Sales");
            $state.go('root.accounting-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadHeatmap = function () {
            $state.go('root.reporting', { type: 'Heatmap', from: "report" });
        }
        $scope.loadSalesReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("SalesTeam", "Other");
            $state.go('root.sales-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadAppStats = function () {
            $state.go('root.reporting', { type: 'App Stats', from: "report" });
        }
        $scope.loadVendorWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("VendorWise", "Sales");
            $state.go('root.vendor-wise-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadDailySummaryReport = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("DailyDashboard", "Other");
            $state.go('root.daily-summary-report', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadPaymentStatusReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("PaymentStatus", "Payments");
            $state.go('root.payment-status-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadPaymentFraudReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("PaymentStatus", "Payments");
            $state.go('root.payment-fraud-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadCelektAppointmentWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("AppointmentWise", "Celekt");
            $state.go('root.celekt-appointment-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadCelektRRRReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("RRR", "Celekt");
            $state.go('root.celekt-rrr-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }
        $scope.loadCelektProductReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            let disabledFeilds = $scope.findReportDisabledFeilds("ProductWise", "Celekt");
            $state.go('root.celekt-product-reports', { fromdate: currentDate, todate: currentDate, disabledFeilds: disabledFeilds });
        }

    });
