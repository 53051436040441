'use strict';

angular.module('scrapQcrmApp')
  .controller('HolidayCtrl', function ($scope, $state, area, $log, $uibModal, $interval, $global, dashboard, holiday, $moment, uiCalendarConfig) {

    (function () {
      $scope.currentViewFromDate = $moment().format('DD-MMM-YYYY');
      $scope.currentViewToDate = $moment().format('DD-MMM-YYYY');
      $scope.events = [];
      $scope.monthEvents = [];
    })();

    function getCalenderInfo(from, to) {
      if (!from) {
        from = $scope.currentViewFromDate;
      }
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEvents');
      holiday.getHoliday(from, 60)
        .then(function (res) {
          if (res.status === "SUCCESS" && res.data && res.data.holidays && res.data.holidays.length > 0) {
            parseCalenderObj(res.data.holidays, "black");
          } else {
            console.warn('Empty results when getHolidays API is called ');
          }
        })
        .catch(function (err) {
          console.error('Error when getHolidays API is called ', err);
        })
    }
    $scope.getAreaCalenderInfo = function (areaId, from) {
      getCalenderInfo(from, 60);
      if (!areaId) {
        $scope.areaId = null;
        return;
      }
      $scope.areaId = areaId;
      if (!from) {
        from = $scope.currentViewFromDate;
      }
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEvents');
      holiday.getAreaHoliday($scope.currentViewFromDate, areaId)
        .then(function (res) {
          if (res.status === "SUCCESS" && res.data && res.data.holidays && res.data.holidays.length > 0) {
            parseCalenderObj(res.data.holidays, "red");
          } else {
            console.warn('Empty results when getHolidays API is called ');
          }
        })
        .catch(function (err) {
          console.error('Error when getHolidays API is called ', err);
        })
    }

    function heatMapColorforValue(value, date) {
      let h = (1 - value) * 230;
      let l = value * 90;
      return "hsl(" + h + ", 100%, " + l + "%)";
    }

    function parseCalenderObj(reqObj, backgroundColor) {
      for (let i = reqObj.length - 1; i >= 0; i--) {
        let month = {
          title: reqObj[i].name,
          start: moment(reqObj[i].date),
          stick: true,
          backgroundColor: backgroundColor,
          textColor: "#fff",
          borderColor: "#eee"
        };
        $scope.monthEvents.push(month);
      }
    }

    (function () {
      $scope.eventSources = []; //The calendar must be supplied an array of documented event sources to render itself
      $scope.eventSources.push($scope.monthEvents);
    })();
    (function () {
      area.getArea().then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data && res.data.area) {
            $scope.areaList = res.data.area;
          }
        } else {
          growl.error(res.error.message ? res.error.message : "Unable to retrieve areas");
        }
      });

    })();

    $scope.appointmentList = function (date, jsEvent, view) {
      let caseNumber = Math.floor((Math.abs(jsEvent.offsetX + jsEvent.currentTarget.offsetLeft) / $(this).parent().parent().width() * 100) / (100 / 7));
      let table = $(this).parent().parent().parent().parent().children();
      $(table).each(function () {
        if ($(this).is('thead')) {
          let tds = $(this).children().children();
          let dateClicked = $(tds[caseNumber]).attr("data-date");
          dateClicked = moment(dateClicked, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          // when an holiday is clicked, the following modal is opened up
          $scope.addHoliday(dateClicked);
        }
      });
    };

    /* config object */
    $scope.uiConfig = {
      calendar: {
        height: 500,
        displayEventTime: false,
        allDay: true,
        ignoreTimezone: true,
        header: {
          left: 'title',
          center: '',
          right: 'prev,next'
        },
        events: function (start, end) { // poulating data in the calendar
          $scope.currentViewFromDate = start.format("DD-MMM-YYYY");
          $scope.currentViewToDate = end.format("DD-MMM-YYYY");
          $scope.getAreaCalenderInfo($scope.areaId, $scope.currentViewFromDate);
        },
        eventRender: function (event, element, view) { //Render Tooltip
          if (view.name === "month") {
            element.find('.fc-title').html(event.name);
          }
        },
        eventClick: $scope.appointmentList, // when click event is triggered
        loading: function (bool) {
          // alert('events are being rendered' + bool); // Add your script to show loading
        },
        eventAfterAllRender: function (view) {
        }
      }
    };

    $scope.addHoliday = function (dateClicked) {
      let modalInstance = $uibModal.open({
        templateUrl: 'views/add-holiday-modal.html',
        controller: 'AddHolidayCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          dateClicked: function () {
            return dateClicked;
          },
          areaId: function () {
            return $scope.areaId;
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $log.info('Modal dismissed at: ' + new Date());
        $global.refresh($state);
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        $global.refresh($state);
      });
    };

    $scope.editArea = function () {
      area.getArea().then(function(res) {
        if (res.status=="SUCCESS") {
          res.data.area.forEach(area => {
            if (area.id==$scope.areaId) {
              var data = area;
              data.title = "Edit Area"
              var pickupInstance = $uibModal.open({
                  templateUrl: 'views/add-area.html',
                  controller: 'AddAreaCtrl',
                  size: "lg",
                  resolve: {
                      items: function () {
                          return data;
                      }
                  }
              });
              pickupInstance.result.then(function (selectedItem) {
                  $scope.selected = selectedItem;
                  $global.refresh($state);
              }, function () {
                  $global.refresh($state);
              });
            }
          });
        }
      });
  };

  });
