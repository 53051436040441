'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.documents
 * @description
 * # documents
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('documents', function (httpService, $global, $rootScope) {
        return {
            retrieveDocuments: function (data) {
                let city = $rootScope.loginData.city;
                let url = $global.getApiUrl() + $global.getApiObject().retrieveDocuments.replace(":city", city).replace(":userType", data.userType);
                let $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            uploadConsumerDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().uploadConsumerDocuments;
                let $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            disableConsumerDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().disableConsumerDocuments.replace(":id", data.id);
                let $request = httpService.httpRequest(url, "P", "");
                return $request;
            },
            retrieveConsumerDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().retrieveConsumerDocuments.replace(":userId", data.userId);
                let $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            retrieveDocumentsList: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().retrieveDocumentsList.replace(":city", data.city).replace(":userType", data.userType);
                let $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            uploadUserDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().uploadUserDocuments;
                let $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            retrieveUserDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().getUserDocuments.replace(":userId", data.userId);
                let $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            disableUserDocuments: function (data) {
                let url = $global.getApiUrl() + $global.getApiObject().disableUserDocuments.replace(":documentId", data.documentId);
                let $request = httpService.httpRequest(url, "P", {});
                return $request;
            },
        };
    });
