'use strict';

angular.module('scrapQcrmApp').controller('BalanceSheetCtrl', function ($state, $stateParams, $scope, $uibModal, saleOrderService, warehouseService, $filter, $window, $rootScope, $global, warehouseInventoryService, Crossfilter, $moment, growl) {
  

  (function () {
    if($stateParams.id===null){
      // retrieving the state params from loval storage on page reload as the params are lost on reload
      $stateParams = $global.getLocalItem("balanceSheetParams", true);
    } else {
      // storing the stateparams when navigated to the balance sheet page
      $global.setLocalItem("balanceSheetParams",$stateParams,true);
    }

    $scope.itemName=$stateParams.item;
    $scope.selectedWarehouseIndex=$stateParams.index;
    $scope.selectedWarehouseId=$stateParams.id;
    $scope.balanceSheetInventories=[];

    // default pagination page value
    $scope.currentPage = 1;
    
    //default values and also working as a false condition
    $scope.totalTransactionsCount = 0;
    $scope.totalQuantitySum = 0;
  })();


  const getBalanceInventoryForWarehouse = function(warehouseId, itemName,  city, page) {
    warehouseInventoryService.getBalanceSheetForWarehouse(warehouseId, itemName,  city, page)
      .then(function (res) {
        if (res.status === "SUCCESS") {
          $global.displayLog('inside getBalanceInventoryForWarehouse() API response', 'res', res);
          $scope.balanceSheetInventories = res.data.balanceSheetInventories;
          $scope.noInventory = res.data.balanceSheetInventories.length === 0;
          $scope.totalBalanceSheetInventoriesRecords = res.data.totalRecords;
          $scope.totalTransactionsCount = res.data.totalTransactionsCount;
          $scope.totalQuantitySum = res.data.totalQuantitySum.toFixed(2);
        } else {
          $scope.noInventory = true;
          if(res && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl('Failed to get balance inventory sheet information ');
            console.log('Failed to get balance inventory sheet information ', res);
          }
        }
      })
      .catch(function(err){
        console.log('Failed to get balance inventory sheet information ', err);
      })
  };
  
  // calls api for the first time when navigated to this page
  (function(){
    if ($scope.selectedWarehouseId && $scope.itemName) {
      getBalanceInventoryForWarehouse(
        $scope.selectedWarehouseId, $scope.itemName,  $rootScope.loginData.city, $scope.currentPage
      );
    }
  })();


  $scope.pageChanged = function(currentPage) {
    $scope.currentPage = currentPage;
    
    if ($scope.selectedWarehouseId && $scope.itemName) {
      getBalanceInventoryForWarehouse(
        $scope.selectedWarehouseId, $scope.itemName,  $rootScope.loginData.city, $scope.currentPage
      );
    }
  };
  
  
  $scope.goBack = function() {
    let objToSend={ index:$scope.selectedWarehouseIndex,
                    page:$stateParams.page,
                    size:$stateParams.size,
                    sort:$stateParams.sort,
                    key:null};
    if($stateParams.key) {
      objToSend.key=$stateParams.key;
    }
    console.log("bl objToSend",objToSend)
    if($stateParams.from==="Warehouse"){
      $state.go('root.inventoryList',objToSend);
    } else {
      if($stateParams.category){
        objToSend.category=$stateParams.category;
      }
      $state.go('root.productsInventory',objToSend);
    }
};
  
  // when transaction id for sells request is clicked
  $scope.OpenSellRequest = function(confirmationId) {
    
    let pickup = {};
    pickup.confirmationId = confirmationId;
    
    $global.displayLog("Inside open request balance inventory ", "pickup", pickup);
    
    var pickupInstance = $uibModal.open({
      templateUrl: 'views/appointments-modal.html',
      controller: 'AppointmentsModalCtrl',
      size: "lg",
      backdrop: 'static',
      resolve: {
        items: function() {
          return pickup;
        },
        allRequests: function() {
          return null;
        },
        vmParams: function() {
          return null;
        }
      }
    });
    
    pickupInstance.result.then(function(selectedItem) {
    
    }, function() {
    
    });
  };
  
  // when transaction id for vendor sale order request is clicked
  $scope.OpenSaleOrderRequest = function(confirmationId) {
    
    saleOrderService.getVendorSaleOrder(confirmationId)
      .then(function(res){
        if(res.status === "SUCCESS" && res.data && res.data.saleOrder) {
          
          let saleOrder = res.data.saleOrder;
          console.log("saleOrder = ", saleOrder);
          
          var pickupInstance = $uibModal.open({
            templateUrl: 'views/sale-order-modal.html',
            controller: 'SaleOrderModalCtrl',
            size: "lg",
            backdrop: 'static',
            resolve: {
              saleOrder: function() {
                return saleOrder;
              }
            }
          });
          pickupInstance.result.then(function(selectedItem) {
          }, function() {
          });
          
          
        } else {
          console.error("Failed to open vendor sale order ", res);
        }
      })
      .catch(function(err){
        console.error("Failed to open vendor sale order ", err);
      });
    
  };
  
  // when transaction id for transport inventory request is clicked
  $scope.OpenTransportInventoryReceipt = function(confirmationId) {
    
    warehouseService.getTransportInventoryByConfirmationId(confirmationId)
      .then(function(res){
        if(res.status === "SUCCESS" && res.data && res.data.transportInventories && res.data.transportInventories.length > 0) {
          
          let url = res.data.transportInventories[0].gatePassURL;
          
          let pickupInstance = $uibModal.open({
            templateUrl: 'views/view-receipt-modal.html',
            controller: 'ViewReceiptModalController',
            size: "lg",
            resolve: {
              items: function() {
                return url;
              },
              pageTitle: function() {
                return "View Transport Inventory Invoice";
              }
            }
          });
          pickupInstance.result.then(function(selectedItem) {
          }, function() {
            //FIXME no close functionality here
          });
          
        } else {
          console.error("Failed to open transport inventory receipt ", res);
        }
      })
      .catch(function(err){
        console.error("Failed to open transport inventory receipt ", err);
      });
    
  };
  
});
