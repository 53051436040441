'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddProspectCtrl
 * @description
 * # AddProspectCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AddProspectCtrl', function($scope, authentication, wholesalerService, consumer, $moment, items, prevscope, $global, growl, $uibModalInstance, $state, $timeout, NgMap) {
        //$scope.data = {}
        $scope.setHeader = items;
        console.log("Prevscope:", prevscope);

        console.log($scope.setHeader)
        $scope.vm = {};

    	$scope.category = null;

        if ($scope.setHeader == "CONSUMER") {
            $scope.categories = [];
            consumer.userCategories().then(function(res) {
                if (res.status == "SUCCESS") {
                    var categorycheck = [];
                    prevscope.categories.forEach(function(elem, idx) {
                        categorycheck.push(elem.value);
                    });
                    res.data.categories.forEach(function(elem, idx) {
                        if (categorycheck.indexOf(res.data.categories[idx].name) > -1) {
                            res.data.categories[idx]['enabled'] = true;
                            $scope.categories.push(res.data.categories[idx].consumerCategoryId);
                        }
                    });
                    $scope.categoriesList = res.data.categories;
                } else if (res.status == "FAILURE") {
                    growl.error(res.error.message)
                }

            })
        }

        /*   profile.updateProfile($scope.data).then(function(res) {
                  if (res.status == "SUCCESS") {
                      growl.success(res.data.message);
                  } else if (res.status == "failure") {
                      growl.error(res.error.message)
                  }
              });
          }*/



        function setPlaceObject(latlng) {
            $global.getLocationByLatLng(latlng).then(function(res) {
                $scope.place = res;
                $scope.vm.formattedAddress = res.formatted_address;

                $scope.chosenPlace = res.formatted_address;


            })

        }

        function reRenderMap() {
            $timeout(function() {
                angular.forEach($scope.maps, function(map) {
                    var currCenter = map.getCenter();
                    google.maps.event.trigger(map, 'resize');
                    map.setCenter(currCenter);
                });
            }, 500);
        }

        $scope.setCurrentLocation = function() {

            $global.getCurrentLocation().then(function(res) {
                $scope.center = $scope.getLatLng(res);
                var rem = {}
                rem.lat = res.latitude;
                rem.lng = res.longitude;
                //setPlaceObject(rem);
                reRenderMap();
            });

        }

        $scope.getLatLng = function(obj) {

                if (obj && obj.latitude && obj.longitude) {
                    var latLng = [];
                    latLng.push(obj.latitude);
                    latLng.push(obj.longitude);
                    return latLng.join();
                }

            }
            //$scope.setCurrentLocation();

        $scope.placeChanged = function() {
            $scope.place = this.getPlace();
            var obj = {};
            obj.lat = $scope.place.geometry.location.lat();
            obj.lng = $scope.place.geometry.location.lng();
            $scope.setLocation(obj);
            console.log(obj.lat, obj.lng)

        }

        $scope.setLocation = function(obj) {

            var center = [];
            center.push(obj.lat);
            center.push(obj.lng);
            $scope.center = center.join();
            $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
        }

        /*  $scope.addLocation = function() {
              if (!$scope.place || !$scope.chosenPlace) {
                  $scope.errorMessage = true;
                  return;
              }
              var address = $global.getAddressObj($scope.place);
              console.log(address);
              address.userId = $global.consumerId;
              address.userType = "CONSUMER";
              address.formattedAddress = $scope.chosenPlace;
              profile.saveCosumerAddress(address).then(function(res) {

                  $scope.data.addressId = res.data.address.addressId;
                  growl.success(res.data.message)
                  $uibModalInstance.dismiss('ok');
              })

          };*/
        $scope.vm = {};
        var map;
        $scope.maps = [];
        $scope.$on('mapInitialized', function(evt, evtMap) {
            $scope.maps.push(evtMap);

        });


        NgMap.getMap().then(function(evtMap) {
            map = evtMap;
        });
        $scope.setLocations = function() {
            if ($scope.locationadd) {

            }
        };
        //$scope.setCurrentLocation();
        $scope.markerDragEnd = function(event) {

            $timeout(function() {
                    var latlng = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng()
                    };
                    setPlaceObject(latlng);
                    $scope.center = $scope.getLatLng(latlng);

                })
                // $scope.chosenPlace = $scope.vm.formattedAddress;
        }
        $scope.cancel = function() {
            $uibModalInstance.close('cancel');
        };

        $scope.location = {
                latitude: 0,
                longitude: 0
            }

        if (prevscope) {
            $scope.data = prevscope;
            $scope.data.passCode = "707070";
            try {
                $scope.vm.formattedAddress = prevscope.address.formattedAddress;
                $scope.location.longitude = parseFloat(prevscope.address.longitude);
                $scope.location.latitude = parseFloat(prevscope.address.latitude);
                var obj = {};
                obj.lat = parseFloat(prevscope.address.latitude);
                obj.long = parseFloat(prevscope.address.longitude);
                $scope.setLocation(obj);
                // setPlaceObject(obj);
                $scope.location = {
                    latitude: obj.lat,
                    longitude: obj.long
                }
                console.log("Lat, Long: ", obj)
                var latlng = new google.maps.LatLng(obj.lat, obj.long);
                setPlaceObject(latlng);
                $scope.center = $scope.getLatLng($scope.location);
                console.log($scope.center);
                reRenderMap();
            } catch(e) {
                growl.error("Address not recorded. Manual intervention needed!");
            }

        }

        $scope.addUsers = function(address, latitude, longitude) {
            console.log("Overriden: ", latitude, longitude)
            console.log("Add users called with ", address, latitude, longitude)
	    $scope.categories = [];
            $scope.categories.push($scope.category);
            $scope.catalog = "Please select any one categories";
            $scope.data.registerType = "CRM";
            $scope.data.userType = $scope.setHeader, //"SQAGENT"
            $global.setLocalItem("registration", $scope.authorization, true);
            $scope.submitted = true;
            if ($scope.addUserForm.$valid) {
                var geocoder = new google.maps.Geocoder();
                //First Geocode
                geocoder.geocode({ 'address': address }, function(results, status) {
                    console.log("Geocode status: ", status, results)
                    if (status == google.maps.GeocoderStatus.OK) {
                        console.log($scope.data);
                        //Register main user
                        authentication.register($scope.data).then(function(res) {
                            console.log("User update status: ", res.status);
                            if (res.status == "SUCCESS") {
                                // Add address of wholesaler
                				$scope.userid = res.data.userId;
                				console.log(results[0])
                                $scope.addLocation(results[0], latitude, longitude);

                                // Add agent of wholesaler
                                if ($scope.data.userType == "WHOLESALER") {
                                    $scope.tempdata = $scope.data;
                                    $scope.tempdata.userType = "AGENT";
                                    $scope.tempdata.wholesalerid = res.data.userId;
                                    $scope.tempdata.mobileNo = "0" + $scope.tempdata.mobileNo;
                                    $scope.tempdata.name = $scope.tempdata.name + " WS";
                                    authentication.register($scope.tempdata).then(function(resagent) {
                                        console.log(resagent)
                                        if (resagent.status == "SUCCESS") {
                                            $scope.tempdata.userid = resagent.data.userId;
                                            console.log(results[0]);
                                            $scope.dataws = $scope.data;
                                            $scope.dataws.agentid = resagent.data.userId;
                                            $scope.dataws.wholesalerId = res.data.userId;
                                            $scope.dataws.dataType = "wholesaler";
                                            delete $scope.dataws.mobileNo;
                                            delete $scope.dataws.passCode;
                                            delete $scope.dataws.userType;

                                            consumer.updateProfile($scope.dataws).then(function(res2) {
                                                if (res2.status == "SUCCESS") {
                                                // growl.success(res.data.message);
                                                } else if (res2.status == "failure") {
                                                    // growl.error(res.error.message)
                                                }
                                            });
                                            // $scope.addLocation(results[0], latitude, longitude);

                                            // Add address of agent
                                            var addresses_agent = $global.getAddressObj(results[0]);

                                            //override geocoded lat, long
                                            if (latitude) {
                                                addresses_agent.latitude = latitude;
                                                addresses_agent.longitude = longitude;
                                            }

                                            addresses_agent.userId = resagent.data.userId;
                                            addresses_agent.userType = "AGENT";
                                            addresses_agent.landmark= $scope.tempdata.landmark;
                                            addresses_agent.formattedAddress = ($scope.vm.customadd != '' && $scope.vm.customadd != undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
                                            //addresses.formattedAddress = address.formatted_address;

                                            console.log("Saving: " + addresses_agent)
                                            authentication.saveCosumerAddress(addresses_agent).then(function(res) {

                                                // $scope.data.addressId = res.data.address.addressId;
                                                // growl.success(res.data.message)
                                                // $uibModalInstance.dismiss('ok');
                                            })
                                        } else {
                                            var errors = res.error.errors;
                                            for (var i = errors.length - 1; i >= 0; i--) {
                                                $scope[errors[i].code] = errors[i].message;
                                                growl.error(errors[i].message)
                                            };
                                            if(res.error.errors.length==0){
                                                growl.error(res.error.message);
                                            }
                                        }
                                    });
                                }
                                //$scope.addLocation(results[0], latitude, longitude);
                                if ($scope.data.userType == "CONSUMER") {
                                    $scope.data.categories = [];
                                    growl.success(res.data.message);
                                    angular.forEach($scope.categories, function(value, key) {
                                        angular.forEach($scope.categoriesList, function(catvalue, key) {
                                            if (value == catvalue.consumerCategoryId)
                                            $scope.data.categories.push({
                                                key: catvalue.consumerCategoryId,
                                                value: catvalue.name
                                            })
                                        });
                                    });
                                    //$ionicLoading.hide();
                                    delete $scope.data.registerType;
                                    delete $scope.data.userType;
                                    delete $scope.data.landmark;
                                    $scope.data.consumerId = res.data.userId;
                                    $scope.data.preferredPaymentMode = "";
                                    $scope.data.profileImage = "";
                                    $scope.data.walletBalance = "";
                                    $scope.data.dataType = "consumer";
                                    consumer.updateProfile($scope.data).then(function(res) {
                                        if (res.status == "SUCCESS") {
                                        // growl.success(res.data.message);
                                        } else if (res.status == "failure") {
                                            growl.error(res.error.message)
                                        }
                                    });
                                }
                                $uibModalInstance.dismiss('cancel');

                            } else {
                                var errors = res.error.errors;
                                for (var i = errors.length - 1; i >= 0; i--) {
                                    $scope[errors[i].code] = errors[i].message;
                                    growl.error(errors[i].message)
                                };
                                if(res.error.errors.length==0){
                                    growl.error(res.error.message);
                                }
                            }
                        });
                    } else {
                        growl.error("Failed to find lat,long for the address, please enter a better address");
                    }

                });
            }
            // console.log($scope.mytime)
            //$scope.mytime=$moment().format('H:mm:ss');
            //console.log($scope.mytime)
        };

        $scope.addLocation = function(address, latitude, longitude) {

            var addresses = $global.getAddressObj(address);

            //override geocoded lat, long
            if (latitude) {
                addresses.latitude = latitude
                addresses.longitude = longitude
            }

            addresses.userId = $scope.userid;
            addresses.userType = $scope.data.userType;
            addresses.landmark=$scope.data.landmark;
            addresses.formattedAddress = ($scope.vm.customadd != '' && $scope.vm.customadd != undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
            //addresses.formattedAddress = address.formatted_address;
            addresses.categories = [];
            angular.forEach($scope.categories, function(value, key) {
                angular.forEach($scope.categoriesList, function(catvalue, key) {
                    if (value == catvalue.consumerCategoryId)
                    addresses.categories.push({
                        key: catvalue.consumerCategoryId,
                        value: catvalue.name
                    })
                });
            });

            authentication.saveCosumerAddress(addresses).then(function(res) {

                // $scope.data.addressId = res.data.address.addressId;
                // growl.success(res.data.message)
                // $uibModalInstance.dismiss('ok');
            })

        };
    });
