'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:PlatformSettingsCtrl
 * @description
 * # PlatformSettingsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('PlatformSettingsCtrl', function($scope, $log, platformSetting, $uibModal, $global, growl, Upload) {
    
    (function(){
      $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
      $scope.isInputModified = false;
      $scope.isAddButtonHidden = false;
    $scope.addPaymentSettingsData = {};
    $scope.addPaymentSettingsData.settingType = "Payment";
    $scope.addPaymentSettingsData.toggle = false;
    $scope.addPaymentSettingsData.toggleAgentApp = false;
    $scope.addPaymentSettingsData.toggleVendorPayments = false;
    $scope.settingType = ['Payment', 'Appointment Settings'];    
  })();


    $scope.addSettings = function(pricingcategory) {
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-platform-settings.html',
        controller: 'AddPlatformSettingsCtrl',
        size: "lg",
        resolve: {
          items: function() {
            return true;
          }
        }
      });
      
      modalInstance.result.then(function(selectedItem) {
        $scope.selected = selectedItem;
        $scope.loadSettings();
      }, function() {
        $scope.loadSettings();
      })
      .catch(function(err) {
        console.error("Err :", err);
      });
      
    };

    $scope.inputChanged = function() {
      $scope.isInputModified = true;
    }

    $scope.pencilClicked = function(option , element) {
      option.updateSettingName = option.paymentType;
      option.isInputHidden = !option.isInputHidden;
      option.isToggleDisabled = !option.isToggleDisabled;
      element.forEach(mode => {
        if (mode !== option) {
          mode.isOnePencilClicked = true;
        }
      })
    }

    $scope.disableAllPencils = function () {
      $scope.paymentSettingsArr.forEach(option => {
        option.isOnePencilClicked = true;
      })
    }
    $scope.enableAllPencils = function () {
      $scope.paymentSettingsArr.forEach(option => {
        option.isOnePencilClicked = false;
      })
    }
    
    //Called when close button clicked
    $scope.clearInputFields = function () {
      $scope.addPaymentSettingsData.settingName = null;
      angular.element("input[type='file']").val(null);
      $scope.addPaymentSettingsData.toggle = false;
      $scope.addPaymentSettingsData.toggleAgentApp = false;
      $scope.addPaymentSettingsData.toggleVendorPayments = false;
    }

    $scope.saveButtonClicked = function(element, payArr) {
      let url = $scope.url;

      $scope.enableAllPencils();

      if(!$scope.isInputModified){
        element.isInputHidden = !element.isInputHidden;
        element.isToggleDisabled = !element.isToggleDisabled;
        return;
      }
      $scope.isInputModified = false;

      // Check if an image is selected
      if(element.updateImage) {
        if(!element.updateImage.type.includes("image")) {
          growl.error("Only image files are accepted")
          return;
        }
        Upload.upload({url: url, file: element.updateImage}).then(function(response) {
          if (response.data.status === "SUCCESS") {
            $scope.updatePaymentSettings(element, response.data.data.fileId);
          }
          else {
            growl.error("Image cannot be uploaded")
          }
          $scope.progress = 0;
        })
        .catch((error) => {
          growl.error("Image File cannot be uploaded");
        });
        // var progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total); 
      }
      else {
        $scope.updatePaymentSettings(element, null);
      }
    };

    $scope.updatePaymentSettings = function (element, uploadedImage) {
      var data = {
        settingType: "Payment",
        settingName: element.paymentType,
        updateSettingName: element.updateSettingName,
        image: uploadedImage ? uploadedImage : element.image,
        toggle: element.toggle,
        toggleAgentApp: element.toggleAgentApp,
        toggleVendorPayments: element.toggleVendorPayments
      };

      platformSetting.updateSetting(data).then(function(res) {
        if (res.status === "SUCCESS") {
          if (res.data) {
            if (res.data.platformSettings) {
              growl.success("Setting updated");
              $scope.loadSettings();
            } else {
              growl.error("Setting could not be updated");
              $scope.loadSettings();
            }
          } else {
            growl.error("Setting could not be updated");
            $scope.loadSettings();
          }
        } else {
          let errorMessage = res.error.errors[0].message ? res.error.errors[0].message : "Settings could not be updated";
          growl.error(errorMessage);
          $scope.loadSettings();
        }
      })
      .catch(function(err) {
        console.error("Err :", err);
      });
    }

    $scope.addSetting = function(file) {
          let url = $scope.url;
          $scope.fileHash = false;
          $scope.progress = 0;
          $scope.hash = false;
          if(!$scope.addPaymentSettingsData.settingName){
            growl.error("Name is Required!");
            return;
          }
          if(!file){
            growl.error("Image is Required!");
            return;  
          }
          // console.log($scope.isAddButtonHidden)
          // $scope.isAddButtonHidden = !$scope.isAddButtonHidden;
          // console.log($scope.isAddButtonHidden)
          if(!file.type.includes("image")) {
            growl.error("Only image files are accepted")
            return;
          }
          Upload.upload({url: url, file: file}).then(function(response) {
            if (response.data.status === "SUCCESS") {
              $scope.addPaymentSettingsData.image = response.data.data.fileId;
              platformSetting.addSetting($scope.addPaymentSettingsData).then(function(res) {
                if (res.status === "SUCCESS") {
                  $scope.isAddButtonHidden = !$scope.isAddButtonHidden;
                  $scope.addPaymentSettingsData.settingName = null;
                  angular.element("input[type='file']").val(null), 
                  growl.success("Successfully added Setting");
                  $scope.loadSettings();

                } else {
                  $scope.loadSettings();
                  let errorMessage = res.error.errors[0].message ? res.error.errors[0].message : "Settings cannot be added";
                  growl.error(errorMessage);
                }
              });
            }
            else {
              growl.error('Image not Selected')
              $scope.loadSettings();
            }
            $scope.progress = 0;
          }, function(resp) {
          }, function(evt) {
            var progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
          });
    };
    
    // Edit Appointment Settings
    $scope.editSetting = function(option) {
      option.settingType = "Appointment Settings";
      $scope.appointmentSettingType = [
        {
          'value': 'agentDistance',
          'text': 'Agent Distance'
        },
        {
          'value': 'maxAppointmentPerSlot',
          'text': 'Max. Appointments per Slot'
        }
      ];
      $scope.appointmentSettingType.forEach(function(element, index) {
        if (option.settingName === element.text) {
          option.settingName = element.value;
          platformSetting.updateSetting(option).then(function(res) {
            if (res.status === "SUCCESS" && res.data && res.data.platformSettings) {
              growl.success("Setting updated");
              $scope.loadSettings();
            } else {
              growl.error("Setting could not be updated");
              $scope.loadSettings();
            }
          })
          .catch(function(err) {
            console.error("Err :", err);
          });
        }
      })
      
    };
    
    $scope.noSetting = false;
    $scope.loadSettings = function() {
      platformSetting.getAllSettings().then(function(res) {
        if (res.status === "SUCCESS" && res.data && res.data.platformSettings) {
          
          if (res.data.platformSettings.paymentSettings) {
            $scope.paymentSettingsArr = res.data.platformSettings.paymentSettings;
            console.log(res.data)
            $scope.paymentSettingsArr.forEach(mode => {
              mode.toggleAgentApp = mode.toggleAgentApp ? mode.toggleAgentApp : false;
              mode.toggleVendorPayments = mode.toggleVendorPayments ? mode.toggleVendorPayments : false;
              mode.isInputHidden = mode.isToggleDisabled = false;
              mode.updateImage = null;
              mode.isOnePencilClicked = false;
              mode.isImageBroken = (mode.imageBinary) ? true : false;

            });
          }
          
          if (res.data.platformSettings.appointmentSettings) {
            $scope.appointmentSettingsArr = res.data.platformSettings.appointmentSettings;
          }
          
          $scope.edit = false;
          $scope.appointmentSettingType = [
            {
              'value': 'agentDistance',
              'text': 'Agent Distance'
            },
            {
              'value': 'maxAppointmentPerSlot',
              'text': 'Max. Appointments per Slot'
            }
          ];
          
          if ($scope.appointmentSettingsArr && $scope.appointmentSettingType) {
            $scope.appointmentSettingsArr.forEach(function(element, index) {
              $scope.appointmentSettingType.forEach(function(elem, indx) {
                if (element.settingName === elem.value) {
                  element.settingName = elem.text;
                }
              })
            });
          }
        } else {
          $scope.noSetting = true;
        }
      })
      .catch(function(err) {
        console.error("Err :", err);
      });
    };
    
    $scope.loadSettings();
    
  });
