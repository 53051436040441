'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.consumer
 * @description
 * # consumer
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('consumer', function (httpService, $global, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    $rootScope.userIdForCustomPricing;
    return {
      getConsumerByText: function (text) {
        var url = $global.getApiUrl() + $global.getApiObject().consumerSearch;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      consumerPickupRequest: function () {
        var url = $global.getApiUrl() + $global.getApiObject().consumerSearch;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      complaintsList: function () {
        var url = $global.getApiUrl() + $global.getApiObject().compaints;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getConsumer: function (type, key) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getConsumer.replace(":userType", type).replace(":text", key).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      usersLoad: function (type, consumerbuckettype, page) {
        var url;
        var city = $rootScope.loginData.city;

        if (!consumerbuckettype) {
          if (page && page != '-1') {
            page = page - 1;
            url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
          } else if (page == '-1') {
            url = $global.getApiUrl() + $global.getApiObject().usersLoadBucket.replace(":userType", type).replace(":userBucketType", consumerbuckettype).replace(":city", city) + "&page=" + page;
          } else {
            url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city);
          }
        } else {
          // if page == -1, the api fetches all the records
          if (page && page != '-1') {
            page = page - 1;
            url = $global.getApiUrl() + $global.getApiObject().usersLoadBucket.replace(":userType", type).replace(":userBucketType", consumerbuckettype).replace(":city", city) + "&page=" + page;
          } else if (page == '-1') {
            url = $global.getApiUrl() + $global.getApiObject().usersLoadBucket.replace(":userType", type).replace(":userBucketType", consumerbuckettype).replace(":city", city) + "&page=" + page;
          } else {
            url = $global.getApiUrl() + $global.getApiObject().usersLoadBucket.replace(":userType", type).replace(":userBucketType", consumerbuckettype).replace(":city", city);
          }
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      retrieveRegistrationsByCategories: function (data) {
        data.city = $rootScope.loginData.city;
        data.size = 10;
        var url = $global.getApiUrl() + $global.getApiObject().retrieveRegistrationsByCategories;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getConsumersData: function () {
        var url = $global.getApiUrl() + $global.getApiObject().getConsumersData;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      allUsersLoad: function (type) {
        var url;
        var city = $rootScope.loginData.city;
        var page = "-1";

        if (type) {
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
        } else {
          url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":city", city) + "&page=" + page;
        }

        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getCustomersAgGrid: function (requestBody) {
        let url = $global.getApiUrl() + $global.getApiObject().getCustomersAgGrid;
        return httpService.httpRequest(url, "P", requestBody);
      },
      getIncompleteCustomers: function () {
        var url = $global.getApiUrl() + $global.getApiObject().getIncompleteCustomers;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getCommunityCustomers: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getCommunityCustomers.replace(":communityId", data);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getCommunityStatusGraphs: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getCommunityStatusGraphs.replace(":communityId", data);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getCommunityProductsGraphs: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getCommunityProductsGraphs.replace(":communityId", data);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      completeInvalidRegistration: function (requestBody) {
        let url = $global.getApiUrl() + $global.getApiObject().completeInvalidRegistration;
        return httpService.httpRequest(url, "P", requestBody);
      },
      getProfile: function (agentId) {
        var agentId = agentId;
        var url = $global.getApiUrl() + $global.getApiObject().getConsumerProfile.replace(":aid", agentId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellRquests: function (agentId) {
        //var agentId=$global.agentId;
        /*var agentId = agentId;
        var params = {};
        params.consumerid = agentId;*/
        var city = $rootScope.loginData.city;
        var params = "?" + $global.objToQueryString(agentId);
        var url = $global.getApiUrl() + $global.getApiObject().getSellRquests + params + "&city=" + city;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getSellById: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().getSellById.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getBids: function (params) {
        //var consumerId=$global.consumerId;
        /*var params={};
        params.status='pending';*/
        params.page -= 1;
        var params = "?" + $global.objToQueryString(params);
        var url = $global.getApiUrl() + $global.getApiObject().getBids + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },

      getBidById: function (id) {
        //var consumerId=$global.consumerId;
        var params = {};
        params.consumerid = id;
        var params = "?" + $global.objToQueryString(params);
        var url = $global.getApiUrl() + $global.getApiObject().getBidById + params;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      userCategories: function () {
        //var params = "?" + $global.objToQueryString(agentId);
        var url = $global.getApiUrl() + $global.getApiObject().userCategories;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },

      updateProfile: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateProfile.replace(":id", data.dataType);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      setDefaultAdd: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().setDefault.replace(":id", id);
        var $request = httpService.httpRequest(url, "P");
        return $request;
      },
      assignAgent: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().assignAgent
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getAddress: function (id) {
        var consumerId = id;
        var url = $global.getApiUrl() + $global.getApiObject().getAddress + "?userid=" + consumerId;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getPendingFeedBacks: function (consumer) {

        var url = $global.getApiUrl() + $global.getApiObject().getPendingFeedBacks.replace(":cid", consumer);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      pendingAgents: function (consumer) {

        var url = $global.getApiUrl() + $global.getApiObject().pendingAgents;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },

      rejectAgent: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().rejectAgent.replace(":id", data);
        var $request = httpService.httpRequest(url, "P", '');
        return $request;
      },
      refferalCode: function (data) {

        var url = $global.getApiUrl() + $global.getApiObject().refferalCode.replace(":regId", data);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      deleteAddres: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().deleteAddress.replace(":id", id);
        var $request = httpService.httpRequest(url, "P");
        return $request;
      },
      getRatingTags: function (rating) {
        var url = $global.getApiUrl() + $global.getApiObject().getRatingTags.replace(":id", rating);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getRatings: function (rating) {
        var url = $global.getApiUrl() + $global.getApiObject().getRatings;
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      checkPendingFeedBacks: function () {
        var cid = $global.consumerId;
        var url = $global.getApiUrl() + $global.getApiObject().getPendingFeedBacks.replace(":cid", cid);
        var $request = httpService.httpRequest(url, "G").then(function (res) {
          if (res.status == $global.SUCCESS) {
            if (res.data.feedbacks.length > 0) {
              feedBack(res.data.feedbacks[0]);
            } else {
              $global.feedBackChecked = true;
            }
          }
        });

      },
      submitFeedBack: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().submitFeedBack;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      skipFeedBack: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().skipFeedBack.replace(":id", id);
        var $request = httpService.httpRequest(url, "P");
        return $request;
      },
      complaintsConsumer: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().complaintsConsumer.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      addComment: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().addComment;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getComments: function (sellId) {
        var url = $global.getApiUrl() + $global.getApiObject().getComments.replace('{sellId}', sellId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      loadConsumer: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().loadConsumer;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      loadLead: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().loadLead;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      getSalesPerson: function () {
        var city = $rootScope.loginData.city;
        var url = $global.salesApiUrl() + $global.getApiObject().getSalesPerson + "?city=" + city;
        var $request = httpService.thirdPartyHttp(url, "P");
        return $request;
      },
      addLead: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().addLead;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      updateLead: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().updateLead;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      unAssignLead: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().unAssignLead;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      assignSalesToLeads: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().assignSalesToLeads;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      deleteLead: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().deleteLead;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      updateConsumerRegistration: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateConsumerRegistration;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      updateConsumer: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().updateConsumer;
        var $request = httpService.thirdPartyHttp(url, "P", data);
        return $request;
      },
      consumerDetails: function (data) {
        var url = $global.salesApiUrl() + $global.getApiObject().consumerDetails + "?mobileNo=" + data.mobileNo;
        var $request = httpService.thirdPartyHttp(url, "G", data);
        return $request;
      },
      installStatistic: function (dateString) {
        var url = $global.getApiObject().installStatistic.replace("PARAM", dateString);
        var $request = httpService.httpGet(url);
        return $request;
      },
      getMessages: function (senderMobile) {
        var url = $global.getApiUrl() + $global.getApiObject().getMessages.replace(":senderMobile", senderMobile);
        var $request = httpService.httpGet(url);
        return $request;
      },
      consumerSellsDetails: function (id) {
        var url = $global.getApiUrl() + $global.getApiObject().consumerSellsDetails.replace(":id", id);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      retrieveCategory: function (categoryId) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveCategory.replace(":categoryId", categoryId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      retrieveConsumerCategoriesForAUser: function (userId) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveConsumerCategoriesForAUser.replace(":userId", userId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getUserId: function () {
        return $rootScope.userIdForCustomPricing;
      },
      setUserId: function (userId) {
        $rootScope.userIdForCustomPricing = userId;
      },
      getConsumerDetails: function () {
        return $rootScope.consumerDetailsForAddConsumer;
      },
      setConsumerDetails: function (details) {
        $rootScope.consumerDetailsForAddConsumer = details;
      },
      getCommunityCatogories: function (city) {
        var url = $global.getApiUrl() + $global.getApiObject().retriveCommunityCatogories.replace(":city", city);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      addBlocks: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().addBlocks;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      updateBlocks: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateBlocks;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getBlocks: function (communityId) {
        var url = $global.getApiUrl() + $global.getApiObject().getBlocks.replace(":id", communityId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getCommunityAppointments: function (communityId) {
        var url = $global.getApiUrl() + $global.getApiObject().getCommunityAppointments.replace(":id", communityId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      searchConsumerRegistration: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().searchConsumerRegistration.replace(":mobileNo", data);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      },
      getConsumerAddressTypeWiseReports: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getConsumerAddressTypeWiseReports;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getDailySummaryReport: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getDailySummaryReport;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getCustomerGraphData: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getCustomerGraphData;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getSellsGraphData: function (data) {
        var url = $global.getApiUrl() + $global.getApiObject().getSellsGraphData;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }
    };
  });
