'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.wholesalerPickups
 * @description
 * # warehousesServ
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('vendorService', function(httpService, $global, $http, $rootScope) {
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            vendorLoad: function(type, page, nodecrement) {
                var url;
                var city = $rootScope.loginData.city;
                 // if page == -1, the api fetches all the records
                if (page && page != '-1') {
                    if (!nodecrement) {
                        page -= 1;
                    }
                    url = $global.getApiUrl() + $global.getApiObject().vendorLoad.replace(":city", city) + "&page=" + page;
                } else if(page == '-1') {
                    url = $global.getApiUrl() + $global.getApiObject().vendorLoad.replace(":city", city) + "&page=" + page;
                  }else {
                    url = $global.getApiUrl() + $global.getApiObject().vendorLoad.replace(":city", city);
                }
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            addVendor: function(data) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addVendor;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },updateVendor: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().updateVendor.replace(":vendorId", data.vendorId);
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },saveAddress: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().saveAddress;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },updateAddress: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().updateAddress;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },getProfile: function(vendorId) {
                var vendorId = vendorId;
                var url = $global.getApiUrl() + $global.getApiObject().getVendorProfile.replace(":id", vendorId);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },searchVendor: function(type,key) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getVendor.replace(":city", city) + "&keyword=" + key;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },retrieveAllVendorsCity: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveAllVendorsCity.replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },retrieveItemsForVendor: function(vendorId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveItemsForVendor.replace(":id", vendorId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },updateDetailsForVendorItemsModal: function(data) {
                let city = $rootScope.loginData.city;
                let vendorId = data.vendorId;
                data.city = city;
                let url = $global.getApiUrl() + $global.getApiObject().updateDetailsForVendorItemsModal.replace(":id", vendorId);
                let $request = httpService.httpRequest(url, "P", data);
                return $request;
            }, getAllVendorsDetails: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().getAllVendorsDetails.replace(":city", city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            }, addVendorItems: function(data) {
                var city = $rootScope.loginData.city;
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addVendorItems;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },addVendorPricing: function(data) {
                var city = $rootScope.loginData.city;
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addVendorPricing;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },updateVendorPricing: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().updateVendorPricing;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },deleteVendorPricing: function(data) {
				data.city = $rootScope.loginData.city;
				var url = $global.getApiUrl() + $global.getApiObject().deleteVendorPricing;
				var $request = httpService.httpRequest(url, "P", data); //FIXME: unable to call HTTP delete on this, following POST instead
				return $request;
			},retrievePricingForVendor: function(vendorId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrievePricingForVendor.replace(":id", vendorId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
			},retrievePricingForVendorModal: function(vendorId) {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrievePricingForVendorModal.replace(":id", vendorId);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },retrieveAllPricing: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveAllPricing.replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },retrieveAllItems: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().retrieveAllItems.replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },retrieveValidatedItems: function(data) {
                data.city = $rootScope.loginData.city;
				var url = $global.getApiUrl() + $global.getApiObject().validateClearance;
				var $request = httpService.httpRequest(url, "P", data); 
				return $request;
            },generateSaleOrder: function(data) {
                // data.city = $rootScope.loginData.city;
				var url = $global.getApiUrl() + $global.getApiObject().createClearanceSaleOrder;
				var $request = httpService.httpRequest(url, "P", data); 
				return $request;
            },
            addDocument: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().addVendorDocument;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            downloadDocument: function(id) {
                var url = $global.getApiUrl() + $global.getApiObject().getFileById.replace(":id", id);
                window.open(url, "_blank");
            },
            disableVendor: function(data){
                var url=$global.getApiUrl()+$global.getApiObject().disableVendor;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            enableVendor: function(data){
                var url=$global.getApiUrl()+$global.getApiObject().enableVendor;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getVendorWiseReports: function(data){
                var url=$global.getApiUrl()+$global.getApiObject().getVendorWiseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            }
        };
    });
