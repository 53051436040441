'use strict';

/**
 */
angular.module('scrapQcrmApp')
  .controller('ViewReceiptModalController', function($scope, $uibModalInstance, $sce, items, pageTitle, request) {
    
    $scope.cancel = function(res) {
      $uibModalInstance.dismiss(res);
    };
    
    if (pageTitle) {
      $scope.pageTitle = pageTitle;
    } else {
      $scope.pageTitle = 'View Image';
    }

    // console.log("What is request????",request);
    if(request){
      $scope.request=request;
      $scope.showButton=true;
    } else {
      $scope.showButton=false;
    }
  
    $scope.content = $sce.trustAsResourceUrl(items);
  });
