'use strict';

angular.module('scrapQcrmApp')
    .controller('ReAssignAgentCtrl', function (SellNow, $scope, $uibModalInstance, growl, $filter, items, consumer, apartments) {

        $scope.getNearByAgents = function () {
            SellNow.getAgentsByLatLongAndSellId(items.conLat, items.conLong, items.sellObjId).then(
                function (res) {
                    if (res && res.status === 'SUCCESS' && res.data && res.data.addresses) {
                        $scope.nearByAgents = res.data.addresses;
                    } else {
                        console.error("Empty/undefined results for getAgentsByLatLongAndSellId()");
                    }
                }, function (err) {
                    console.error("Failed service getAgentsByLatLongAndSellId() ", err);
                })
        };

        $scope.radioClick = function (val) {
            $scope.index = val;
        }
        $scope.sellobjid = items.sellObjId;
        $scope.agentName = items.agentName;
        $scope.assign = function (sellobjid) {
            if ($scope.index >= 0) {
                var assignNewAgentObj = {};
                assignNewAgentObj.agentObjId = $scope.nearByAgents[$scope.index].userId;
                if (!items.appointmentId) {
                    assignNewAgentObj.sellObjId = sellobjid;
                    SellNow.checkAgentSlotAvailability(assignNewAgentObj.agentObjId, assignNewAgentObj.sellObjId).then(function (res) {
                        if (res.status == "SUCCESS") {
                            consumer.assignAgent(assignNewAgentObj).then(function (res) {
                                if (res.status == 'SUCCESS') {
                                    growl.success(res.data.message);
                                    $scope.ok(res);

                                } else {
                                    growl.error(res.error.message);
                                }
                            })
                        } else {
                            var message = res.error.code;
                            console.log(message)
                            if (message && confirm(message)) {
                                consumer.assignAgent(assignNewAgentObj).then(function (res) {
                                    if (res.status == 'SUCCESS') {
                                        growl.success(res.data.message);
                                        $scope.ok(res);

                                    } else {
                                        growl.error(res.error.message);
                                    }
                                })
                            }
                        }
                    })
                }
                if (items.appointmentId) {
                    assignNewAgentObj.communityAppointmentObjId = items.appointmentObjId;
                    apartments.assignApointmentToAgent(assignNewAgentObj).then(function (res) {
                        console.log(res)
                        if (res.status == 'SUCCESS') {
                            growl.success(res.data.message);
                            $scope.ok(res);

                        } else {
                            growl.error(res.error.message);
                        }
                    })
                }

            } else {
                $scope.selectAgentError = "Please select agent !";
            }

        }

        $scope.systeAllocation = function (sellObjId) {
            var obj = {
                'sellObjId': sellObjId,
                'agentObjId': "CRMUSER",
            }
            SellNow.declineRequest(obj).then(function (res) {
                if (res.status == "SUCCESS") {
                    growl.success(res.data.message);
                    $scope.ok(res);
                } else if (res.status == "FAILURE") {
                    growl.error(res.error.message);
                }
            })
        }

        $scope.hide = function () {
            $scope.sysAllocation = true;
        }

        $scope.show = function () {
            $scope.sysAllocation = false;
        }


        $scope.cancel = function (res) {
            $uibModalInstance.close(res);
        };
        $scope.ok = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });
