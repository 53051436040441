'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.graphopperService
 * @description
 * # graphopperService
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('graphhopperService', function(httpService, $global, $http, $rootScope) {
        return {
            submitProblem: function(data) {
                var url = "https://graphhopper.com/api/1/vrp/optimize?key=5852dba7-7d31-4dc2-b693-bd4a42ee09c9";
                var $request = httpService.httpRequestGraphhopper(url, "P", data);
                return $request;
            },
            getResults: function(id) {
            	var url = "https://graphhopper.com/api/1/vrp/solution/" + id + "?key=5852dba7-7d31-4dc2-b693-bd4a42ee09c9";
                var $request = httpService.httpRequestGraphhopper(url, "G", "");
                return $request;
            }
        };
    });
