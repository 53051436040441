'use strict';
/**
 * @ngdoc function
 * @name scrapQApp.controller:SaleOrderModalCtrl
 * @description
 * # SaleOrderModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
	.controller('SaleOrderModalCtrl', function($scope, $sce, $rootScope, $global, $state, $window, $uibModal, $uibModalInstance, saleOrderService, growl, $filter,  saleOrderid) {
	 
		$scope.billNumber = {value:null};
		$scope.truckNumber = {value:null};
		$scope.saleOrder =  saleOrderid;
    	$scope.disableDispatchBtn = false;
		$scope.disableAcceptBtn = false;
		$scope.disableEditBtn = false;
		$scope.disableRejectBtn = false;
		$scope.blobURLs = [];

		console.log(saleOrderid);
		// 
		// if (saleOrderid) {
			// let totalAmount = 0.0;
			// saleOrderid.saleOrderItems.forEach(function(elem, indx) {
				// elem.totalPrice = elem.totalPrice.replaceAll(',', '');
				// elem.quantity = elem.quantity.replaceAll(',', '');
				// totalAmount += parseFloat(elem.totalPrice);
			// });
			// $scope.saleOrder['totalAmount'] = totalAmount;
		// }		
		$scope.oneAtATime = true;
		$scope.isOpen = false;
		
		$scope.renderDocuments=function() {

		
		// Data
		$scope.groups = $scope.saleOrder && $scope.saleOrder.saleOrderDocuments?$scope.saleOrder.saleOrderDocuments:[];
		$scope.invoiceGroups = $scope.saleOrder && $scope.saleOrder.saleOrderInvoices?$scope.saleOrder.saleOrderInvoices:[];

		// To Generate Downloadable blobs
		$scope.generateBlobURL = function(url, ind, arr) {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					// $apply used to refresh bindings
					$scope.$apply(function() {
						let blobURL = URL.createObjectURL(blob);
						arr[ind].documentDownloadURL = blobURL;
						$scope.blobURLs.unshift(blobURL);
						// Enable download button
						arr[ind].isDocumentDownlaodable = true;
					});
					console.log('group.isDocumentDownlaodable: ',arr[ind].isDocumentDownlaodable);
				})
				.catch((error) => {
					console.error('Error:', error);
					console.error('url: ', url)
				});
		}

		if ($scope.groups && $scope.content) {

			let id = $scope.saleOrder && $scope.saleOrder.saleOrderConfirmationId ? $scope.saleOrder.saleOrderConfirmationId : '-';
			let url = $scope.content;
			const previewOptions = "#&toolbar=0&navpanes=0";
			$scope.groups.unshift({ documentConfirmationId: id, documentURL: url }); // parent document for the SO row
			$scope.invoiceGroups.unshift({ invoiceConfirmationId: id, invoiceURL: url }); // parent document for the Invoices row

			$scope.groups.forEach((group, index, array) => {
				if (group.documentURL) {
					group.documentPreviewURL = $sce.trustAsResourceUrl(group.documentURL + previewOptions); // For PDF preview purpose
					$scope.generateBlobURL(group.documentURL, index, array); // For auto-download purpose

					// To set the pdf name for direct downloads
					console.log(group);
					let parts = group.documentURL.split("/");
					let docName = parts[parts.length - 1];
					group.documentDownloadName = docName;
					
					
				}
			});
			$scope.invoiceGroups.forEach((group, index, array) => {
				if (group.invoiceURL) {
					group.invoicePreviewURL = $sce.trustAsResourceUrl(group.invoiceURL + previewOptions); // For PDF preview purpose
					$scope.generateBlobURL(group.invoiceURL, index, array); // For auto-download purpose
	
					// To set the pdf name for direct downloads
					let parts = group.invoiceURL.split("/");
					let docName = parts[parts.length - 1];
					group.documentDownloadName = docName;
				}
			});
		}
	}
	    
		$scope.updateOpenStatus = function(){
			$scope.isOpen = $scope.groups.some(function(item){
				return item.isOpen;
			});
		}

		$scope.cancel = function(res) {
			$scope.groups.shift();
			$scope.invoiceGroups.shift();
			$uibModalInstance.dismiss('Cancel');
			$scope.blobURLs.forEach(blobURL => URL.revokeObjectURL(blobURL));
		};

		$scope.isDispatched = function(){
			return ($scope.saleOrder.status === 'DISPATCHED');
		};

		$scope.redirect = function(url){
			window.open(url);
		};
		$scope.getSaleOrderDetails = function(saleOrderConfirmationId){
			saleOrderService.getSaleOrderDetails(saleOrderConfirmationId).then(function(res){
				console.log(res)
				if (res.status == "SUCCESS") {
					if(res.data){
						if (res.data.saleOrderDetails) {
							$scope.saleOrder = res.data.saleOrderDetails;
							console.log($scope.saleOrder)
							$scope.content = $scope.saleOrder.gatePassURL;
							$scope.renderDocuments()
						}
					}
				}
			})
		}
		$scope.getSaleOrderDetails(saleOrderid);


		$scope.acceptSaleOrder = function(saleOrderConfirmationId) {
			$scope.disableAcceptBtn = true;
			saleOrderService.acceptVendorSaleOrder(saleOrderConfirmationId).then(function(res){
				console.log(res);
				if (res.status == "SUCCESS") {
					$global.setLocalItem("saleOrderId",saleOrderConfirmationId,true);
					growl.success(res.data && res.data.message?res.data.message:"Approved");
					$state.go('root.saleOrder',{},{reload:true});
					$uibModalInstance.dismiss(res);
					var totalAmount = $scope.saleOrder.totalAmount;
					$scope.saleOrder = res.data.saleOrder;
					$scope.saleOrder['totalAmount'] = totalAmount;
					$scope.content = $sce.trustAsResourceUrl($scope.saleOrder.gatePassURL);
				} else {
					if(res && res.data && res.data.message){
						growl.error(res.data.message);
					} else if(res && res.error && res.error.message){
						growl.error(res.error.message);
					} else {
						growl.error("Failed to accept sale order");
					}
				}
			}).catch(function(err){
				growl.error("Failed to accept sale order");
				console.log("Err:",err)
			})
		};

		$scope.rejectSaleOrder = function(saleOrderConfirmationId) {
			$scope.disableRejectBtn = true;
			saleOrderService.rejectVendorSaleOrder(saleOrderConfirmationId).then(function(res){
				console.log(res);
				if (res.status == "SUCCESS") {
					growl.success(res.data && res.data.message?res.data.message:"Rejected");
					$global.setLocalItem("saleOrderId",saleOrderConfirmationId,true);
					$state.go('root.saleOrder',{},{reload:true});
					$uibModalInstance.dismiss(res);
					var totalAmount = $scope.saleOrder.totalAmount;
					$scope.saleOrder = res.data.saleOrder;
					$scope.saleOrder['totalAmount'] = totalAmount;
				} else {
					if(res && res.data && res.data.message){
						growl.error(res.data.message);
					} else if(res && res.error && res.error.message){
						growl.error(res.error.message);
					} else {
						growl.error("Failed to reject sale order");
					}
				}
			}).catch(function(err){
				growl.error("Failed to reject sale order");
				console.log("Err:",err)
			})
		};

		$scope.dispatchSaleOrder = function(saleOrderConfirmationId) {
			if(!($scope.billNumber.value) || !($scope.truckNumber.value)){
				growl.error("Missing Bill or Truck Number!");
				return;
			}
   
			// to avoid button spamming
      $scope.disableDispatchBtn = true;
			
			saleOrderService.dispatchVendorSaleOrder(saleOrderConfirmationId,$scope.billNumber.value,$scope.truckNumber.value).then(function(res){
				console.log(res);
				if (res.status === "SUCCESS") {
					growl.success(res.data && res.data.message?res.data.message:"Dispatched");
					$global.setLocalItem("saleOrderId",saleOrderConfirmationId,true);
					$state.go('root.saleOrder',{},{reload:true});
					$uibModalInstance.dismiss(res);
					var totalAmount = $scope.saleOrder.totalAmount;
					$scope.saleOrder = res.data.saleOrder;
					$scope.saleOrder['totalAmount'] = totalAmount;
				} else {
					if(res && res.data && res.data.message){
						growl.error(res.data.message);
					} else if(res && res.error && res.error.message){
						growl.error(res.error.message);
					} else {
						growl.error("Failed to dispatch sale order");
					}
				}
			}).catch(function(err){
				growl.error("Failed to dispatch sale order");
				console.log("Err:",err)
			})
		};

		$scope.cancelSaleOrder = function(saleOrderConfirmationId) {
			alert('To Do');
		};

		$scope.editSaleOrder = function(saleOrderid){
			$scope.disableEditBtn = true;
			console.log(" Eddddittt Sale order",saleOrderid);
			let selectedItems=[];
			$scope.saleOrder.saleOrderItems.forEach( elem => {
			// 	if(!elem.select){
				selectedItems.push({ itemName: elem.categoryname, vendorItemName: elem.itemName, totalQuantity: elem.quantity, price: elem.unitPrice, totalPrice: elem.totalPrice, uom: elem.uom, image: elem.image, productCategory: elem.productCategoryName }); //creating deep copy of the object
			// 	}
			});
			console.log("selected items",selectedItems);
			var modalInstance = $uibModal.open({
				templateUrl: 'views/create-sale-order-modal.html',
				controller: 'CreateSaleOrderModalCtrl',
				size: "lg",
				backdrop: 'static',
				resolve: {
					warehouseId: function() {
						return saleOrderid.warehouseId;
					},
					vendorId: function() {
						return saleOrderid.vendorId +'$'+saleOrderid.saleOrderConfirmationId + '$' + saleOrderid.pickUpDate;
					},
					itemsList: function() {
						return selectedItems;
					},
					// saleOrderConfirmationId: function() {
					// 	return saleOrder.saleOrderConfirmationId;
					// }
			}
			});
			
			modalInstance.result.then(function(res) {
				console.log("Res",res);
				if(res==="Reload"){
					$state.go('root.saleOrder',{},{reload:true});
					$uibModalInstance.dismiss(res);
				}
			}, function() {
				$log.info('Modal dismissed at: ' + new Date());
			});
		}

		$scope.viewInvoice = function() {
			$window.open($scope.saleOrder.gatePassURL, '_blank');
		};
		
	});