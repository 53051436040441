'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ConfirmationCtrl
 * @description
 * # ConfirmationCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('ConfirmationCtrl', function($scope, authentication, $uibModalInstance, message, apartments, growl) {
        $scope.message = message;
        $scope.cancel = function() {
            $uibModalInstance.dismiss('');
        };
        $scope.ok = function() {
            $uibModalInstance.close('');
        };
    });
