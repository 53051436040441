'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:PaymentsModalCtrl
 * @description
 * # PaymentsModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('PaymentsModalCtrl', function($scope, $rootScope, $global, $uibModalInstance, paymentService, growl, $filter, payment, consumer, $uibModal, SellNow, NgMap, $moment) {
       

        $scope.cancel = function(res) {
            $uibModalInstance.dismiss(res);
        };

        console.log(payment)
       
        $scope.getPayment = function(){
            $scope.noPaymentFound = false;
            paymentService.getPayment(payment.merchantOrderId).then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    if (res.data.payment) {
                        $scope.paymentDetails = res.data.payment;

                        $scope.statusArr = res.data.payment.status;
                    }
                    if (res.data.payment.walletVM) {
                        $scope.walletDetails = res.data.payment.walletVM;
                    }

                }else if (res.status == "FAILURE") {
                    $scope.noPaymentFound = true;
                }
            });
        };

        $scope.getPayment();
    });