'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:SplitModalCtrl
 * @description
 * # SplitModalCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('SplitModalCtrl', function($scope, $rootScope, $moment, $uibModalInstance, holiday, item, $global, $uibModal, DTOptionsBuilder, $window, paymentService, growl) {
  
    /*================================================init functions=================================================================*/
    (function(){
      console.log('item obj', item);
      $scope.saleItem = item;
      $scope.quantityObj={  currentQty: $scope.saleItem.totalQuantity,
                            newQty: $scope.saleItem.totalQuantity,
                            enteredValue: 0};
    })();
    
    /*================================================core functions=================================================================*/
    $scope.splitted = function() {
      $uibModalInstance.dismiss($scope.quantityObj.enteredValue);
    };
  
    /*================================================helper functions=================================================================*/
    $scope.cancel = function() {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.subtract= function(data){
        console.log("data",data)
        // if(data<$scope.prevValue){
        //     $scope.quantityObj.newQty=$scope.quantityObj.currentQty;
        // }
        $scope.quantityObj.newQty=$scope.quantityObj.currentQty-$scope.quantityObj.enteredValue;
        // $scope.prevValue=data;
    };
    
  });
