'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:OrgChartCtrl
 * @description
 * # OrgChartCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('OrgChartCtrl', function($scope, $global, wholesalerService, $state, growl, dashboard, $rootScope, $stateParams) {
  
    
    $scope.noData=false;
    var newobject = [['User', 'Owner Id', 'Username']];
    var data=[],apiData;

    (function(){
      wholesalerService.getOrgChartData().then(function(res){
        if(res.status==="SUCCESS"){
          // console.log("res",res.data);
          if(res.data && res.data.payoutUsers && res.data.payoutUsers.length>0){
            apiData=res.data.payoutUsers;
            data.push({    ownerId:0,
                    userId:apiData[0].userId,
                    userType:apiData[0].userType === "WHOLESALER"?"PARTNER":apiData[0].userType,
                    userName:apiData[0].userName,
                    userMobile:apiData[0].userMobile
                });
            createNewNode(apiData[0]);
            addNode();
          } else {
            $scope.noData=true;
            growl.success("No records found")
          }
        } else {
            $scope.noData=true;
            growl.error("Invalid Id to get Chart")
        }
      }).catch(function(err){
        $scope.noData=true;
        growl.error("No records found")
        console.log("err",err)
      })
    })();

    function createNewNode(arr){
        if(Array.isArray(arr.children)){
            arr.children.forEach(child => {
                let obj={};
                obj.userId=child.userId;
                obj.userType=child.userType === "WHOLESALER"?"PARTNER":child.userType;
                obj.userName=child.userName;
                obj.userMobile=child.userMobile
                obj.ownerId=child.ownerId;
                data.push(obj);
                createNewNode(child);
            })
        }
    }

    function addNode(){
      angular.forEach(data, function (val) {
        newobject.push(
            [
                {
                    v: val.userId.toString(),
                    f: '<div class="parent '+val.userType+'">'
                        + ' <div class="text">'
                        + '<h4><b>' + val.userName + '</b></h4>'
                        + '<div >' + val.userType + '</div>'
                        + '<div >'+ val.userMobile +'</div>'
                        + '</div>'
                        + '</div>'
                },
                (val.ownerId.toString() === "0" ? "" : val.ownerId.toString()),
                (val.userName +"," +val.userType)
            ]
            );
      })

      $scope.chartData = newobject;
      console.log("chartData",$scope.chartData)
    }

    (function(){
      // $('.org-chart-container').on('click','.chart-row #chart tbody .parent',clicked);
      $('.org-chart-container').on('click','.chart-row #chart tbody .parent',doubleclick);
    })();

    function clicked(e){
        let s= new String(e.currentTarget.innerText).split("\n");
        console.log('s',s)
        if(s[1]==='AGENT'){
          $state.go('root.agents',{agentName:s[2]})
        }
        if(s[1]==='PARTNER'){
          $state.go('root.wholesalers',{wholesalerName:s[2]})
        }
    }

    // function clickedTwice(e){
    //   console.log("e.detail",e.detail);
    // }

    var clickTimeoutID;

    function doubleclick(event) {
      if ( event.originalEvent.detail === 1 ) {
            /** Is this a true single click or it it a single click that's part of a double click?
             * The only way to find out is to wait it for either a specific amount of time or the `dblclick` event.
             **/
            clickTimeoutID = window.setTimeout(function(){
                  clicked(event);
                  },300 // how much time users have to perform the second click in a double click -- see accessibility note below.
                );
        } else if ( event.originalEvent.detail === 2 ) {
            console.log("Double Click",document.getElementById("chart"))
            window.clearTimeout( clickTimeoutID ); // it's a dblclick, so cancel the single click behavior.
        } // triple, quadruple, etc. clicks are ignored.

    }

  });
