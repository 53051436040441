'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.payoutService
 * @description
 * # payoutService
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('payoutService', function(httpService, $global, $http, $rootScope) {
    return {
      getAccountsPayableDetails: function(userType) {
        var url;
        var city = $rootScope.loginData.city;
        var page = -1;
        url = $global.getApiUrl() + $global.getApiObject().getAccountsPayableDetails.replace(":city", city);
        url = url + "&page=" + page;
        if (userType) {
          url = url + "&userType=" + userType;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAccountsReceivableDetails: function(userType) {
        var url;
        var city = $rootScope.loginData.city;
        var page = -1;
        url = $global.getApiUrl() + $global.getApiObject().getAccountsReceivableDetails.replace(":city", city);
        url = url + "&page=" + page;
        if (userType) {
          url = url + "&userType=" + userType;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getViewAccountsPayableModalDetails: function(invoiceId) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getViewAccountsPayableModalDetails.replace(":city", city).replace(":invoiceId", invoiceId);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      payInvoiceAmount: function(data) {
        var url;
        var url = $global.getApiUrl() + $global.getApiObject().payInvoiceAmount;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getPayoutsHistory: function(id, date) {
        var url;
        var city = $rootScope.loginData.city;
        url = $global.getApiUrl() + $global.getApiObject().getPayoutHistory.replace(":city", city);
        url = url.replace(":agentId", id).replace(":date", date);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getPayoutRecordById: function(payoutId) {
        var url;
        var city = $rootScope.loginData.city;
        url = $global.getApiUrl() + $global.getApiObject().getPayoutRecordById
          .replace(":city", city)
          .replace(":payoutId", payoutId);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
    };
  });
