'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.salsesAgentService
 * @description
 * # agentPickups
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
.factory('salesAgentService', function(httpService, $global, $http, $rootScope) {
    return {
        getProfile: function(id) {
            var url = $global.getApiUrl() + $global.getApiObject().getSalesAgentProfile.replace(":id", id);
            var $request = httpService.httpRequest(url, "G", "");
            return $request;
        },
        usersLoad: function(type, page, nodecrement) {
            var url;
            var city = $rootScope.loginData.city;
            console.log("what is page",page);
             // if page == -1, the api fetches all the records
            if (page && page != '-1') {
                if (!nodecrement) {
                    page = page - 1;
                }
                url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
            } else if(page == '-1') {
                url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city) + "&page=" + page;
              }else {
                url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type).replace(":city", city);
            }
            console.log("this is the url",url);
            var $request = httpService.httpRequest(url, "G", "");
            return $request;
        },
        getSalesAgent: function(type, key) {
            var city = $rootScope.loginData.city;
            var url = $global.getApiUrl() + $global.getApiObject().getSalesAgent.replace(":userType", type).replace(":text", key).replace(":city", city);
            var $request = httpService.httpRequest(url, "G", "");
            return $request;
        },
        addSalesAgent: function(data) {
            var url = $global.salesApiUrl() + $global.getApiObject().addSalesAgent;
            var $request = httpService.thirdPartyHttp(url, "P", data);
            return $request;
        },
        editSalesAgent: function(data) {
            var url = $global.salesApiUrl() + $global.getApiObject().editSalesAgent;
            var $request = httpService.thirdPartyHttp(url, "P", data);
            return $request;
        },
        getAllSalesAgents: function(data) {
            const city = $rootScope.loginData.city;
            const enabled = data;
            const url = $global.salesApiUrl() + $global.getApiObject().getAllSalesAgents.replace(":city", city).replace(":enabled", enabled);
            let $request = httpService.thirdPartyHttp(url, "G", "");
            return $request;
        },
        disableSalesAgent: function(id) {
            const data = {}
            data.id = id
            var url = $global.salesApiUrl() + $global.getApiObject().disableSalesAgent;
            var $request = httpService.thirdPartyHttp(url, "P", data);
            return $request;
        },
        retrieveSalesReports: function(data) {
            var url = $global.salesApiUrl() + $global.getApiObject().retrieveSalesReports;
            var $request = httpService.thirdPartyHttp(url, "P", data);
            return $request;
        }
    };
});
