'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddPlatformSettingsCtrl
 * @description
 * # AddPlatformSettingsCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddPlatformSettingsCtrl', function($scope, platformSetting, Upload, $moment, $global, growl, $uibModalInstance, httpService, $http, $state, $timeout, NgMap) {
  
    $scope.data = {};
    $scope.data.toggle = false;
    $scope.data.toggleAgentApp = false;
    $scope.data.toggleVendorPayments = false;
    $scope.settingType = ['Payment', 'Appointment Settings'];
    
    $scope.appointmentSettingType = [
      {'value': 'agentDistance', 'text': 'Agent Distance'},
      {'value': 'maxAppointmentPerSlot', 'text': 'Max. Appointments per Slot'}
    ];
    
    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    
    // $scope.changeToggle = function() {
    //   $scope.data.toggle = !$scope.data.toggle;
    // };

    // $scope.changeToggleCRM = function() {
    //   $scope.data.toggleCRM = !$scope.data.toggleCRM
    // }

    // $scope.changeToggleCRM = function() {
    //   $scope.data.toggleCRM = !$scope.data.toggleCRM
    // }

    // $scope.changeToggleCRM = function() {
    //   $scope.data.toggleCRM = !$scope.data.toggleCRM
    // }
    
    $scope.$watch('data.settingType', function(newValue, oldValue) {
      $scope.data.settingName = "";
      $scope.data.value = "";
    });
    
    $scope.addSetting = function(file) {
      if ($scope.addSettingForm.$valid) {
        if ($scope.data.settingType === "Payment") {
          var url = $scope.url;
          $scope.fileHash = false;
          $scope.progress = 0;
          $scope.hash = false;
          console.log($scope.data);
          Upload.upload({url: url, file: file}).then(function(response) {
            if (response.data.status === "SUCCESS") {
              $scope.data.image = response.data.data.fileId;
              platformSetting.addSetting($scope.data).then(function(res) {
                if (res.status === "SUCCESS") {
                  growl.success("Successfully added Setting");
                  $uibModalInstance.dismiss();
                } else {
                  growl.error("Error in adding Setting");
                }
              });
            }
            $scope.progress = 0;
          }, function(resp) {
          }, function(evt) {
            var progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
          });
        } else {
          platformSetting.addSetting($scope.data).then(function(res) {
            if (res.status === "SUCCESS") {
              growl.success("Successfully addded Setting");
              $uibModalInstance.dismiss();
            } else {
              growl.error("Error in adding Setting");
            }
          });
        }
        
      }
    };
    
    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };
  });
