'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddWalletCtrl
 * @description
 * # AddWalletCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AddWalletCtrl', function($scope, wholesalerService, agentService, authentication, paymentService, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope) {
        $scope.data = {}

        if(items.WalletsData){
            $scope.setHeader = items.setHeader;
            $scope.data.name = items.WalletsData.name;
            $scope.data.mobileNo = items.WalletsData.mobileNo;
            $scope.data.walletGuid = items.WalletsData.walletGuid;            
        }else{
            $scope.setHeader = items;
        }

        $scope.walletType=["Paytm","TWallet"];
        $scope.wholesalerSelected = false;

        var searchTextWatch = $scope.$watch('searchText', function(searchText) {
            console.log("SEARCH TEXT::",searchText);
            if (searchText != null) { 
                console.log(searchText.userId);
                if (searchText.userId) {
                    $scope.selectedWholesalerId = searchText.userId;
                    $scope.data.mobileNo = searchText.mobile;
                    // $scope.addWallet();
                }
            }

        }, false);

        var agentSearchTextWatch = $scope.$watch('searchAgentText', function(searchAgent) {
            if (searchAgent != null) { 
                console.log(searchAgent.userId);
                if (searchAgent.userId) {
                    $scope.selectedAgentId = searchAgent.userId;
                    $scope.data.mobileNo = searchAgent.mobile;
                }
            }

        }, false);

        $scope.$on('$destroy',function(){
            console.log("Route changed, cancelling setInterval")
            console.log($scope.searchText);
            searchTextWatch();
            agentSearchTextWatch();
            console.log("watch stopped")
            console.log($scope.searchText)
            
        });

        

        $scope.searchWholesaler = function(key) {
            console.log("KEY:",key)
            key = key.trim();
            return wholesalerService.getWholesaler("WHOLESALER", key).then(function(response) {
                console.log(response.data.users)
                return response.data.users;
            });
        };

        $scope.searchAgent = function(key) {
            console.log("KEY:",key)
            key = key.trim();
            return agentService.getAgent("AGENT", key).then(function(response) {
                console.log(response.data.users)
                return response.data.users;
            });
        };


        $scope.next = function(){
            $scope.wholesalerSelected = true;
            if ($scope.selectedWholesalerId) {
                $scope.data.wholesalerId = $scope.selectedWholesalerId;
            }
            if ($scope.selectedAgentId) {
                $scope.data.agentId = $scope.selectedAgentId;
            }
        }
        
        $scope.addWallet = function() {
            $scope.wholesalerSelected = true;
            $scope.data.registerType = "CRM";
            $scope.data.userType = $scope.setHeader, 
            $scope.data.city = $rootScope.loginData.city;

            console.log($scope.data)
            $scope.submitted = true;
            if ($scope.addWalletForm.$valid) {

                        paymentService.addWallet($scope.data).then(function(res) {
                            console.log(res)
                            var authres = JSON.parse(JSON.stringify(res));
                            if (res.status == "SUCCESS") {
                                $uibModalInstance.dismiss('Ok');
                                
                            } else {
                                var errors = res.error.errors;
                                for (var i = errors.length - 1; i >= 0; i--) {
                                    $scope[errors[i].code] = errors[i].message;
                                    growl.error(errors[i].message)
                                };
                                if(res.error.errors.length==0){
                                    growl.error(res.error.message);
                                }
                            }
                        });
            }
        };

        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };


    });