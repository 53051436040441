'use strict';

/**
 * Called only in one place in the agents.js
 */
angular.module('scrapQcrmApp')
  .controller('AgentTodayAppointmentsCtrl', function($scope, $uibModalInstance, $uibModal, items, agentService, $global) {
    /*==================================================loggers=========================================================*/
    $global.displayLog('Inside AgentTodayAppointmentsCtrl');
    
    $global.displayLog('DI value for items', 'items', items);
  
    /*==================================================default init=========================================================*/
    (function(){
      agentService.getSellById(items.confirmationId)
        .then(function(res) {
          $scope.items = res.data.sell
        })
        .catch(function(err) {
          console.error('Error in getting sellById information ', err);
        })
    })();
    
    /*==================================================helper functions===================================================*/
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    
    $scope.ok = function() {
      $uibModalInstance.close('ok');
    };
    
  });
