'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ProductPricingModalCtrl
 * @description
 * # ProductPricingModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('ProductPricingModalCtrl', function($scope, authentication, wholesalerService, agentService, pricingService, role, products, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $filter) {
    
    /*========================================global variables============================================================*/
    
    /*==========================================default values init===================================================*/
    (function() {
      $scope.sorted=true;
      $scope.editModal=false;
      $scope.role = role;
      $scope.Categories = [];
      $scope.distinctCategories = null;
      $scope.isTableModified = false; 
      $scope.isTableSaved = false;

      let itemsArr = items.split('+');
      console.log(items, itemsArr, "items");
      $scope.modalType = itemsArr[0];
      if (itemsArr[0] === 'EDIT') {
        $scope.editModal=true;
        $scope.addForm = {};
        let pricingData = JSON.parse(itemsArr[1]); // reading passed data from parent window (agents or wholesalers)
        $scope.addForm["id"] = pricingData["id"];
        $scope.addForm["name"] = pricingData["name"];
        $scope.addForm["description"] = pricingData["description"];
        $scope.distinctCategories = pricingData["productsPricing"];
        products.getProducts()
        .then(function(res) {
          // get distinct categories 
          let productCategoriesArr = [];
          for (let productCategory of res.data.categories) {
            let exists = $scope.distinctCategories.filter(c=>c.productName===productCategory.name)
              if (exists.length > 0) {
                continue
              }
                  let productCategoriesObj = {};
                  productCategoriesObj.productName = productCategory.name;
                  productCategoriesObj.priceType='';
                  productCategoriesObj.tier1 = 0;
                  productCategoriesObj.tier2 = 0;
                  productCategoriesObj.tier3 = 0;
                  $scope.distinctCategories.push(productCategoriesObj)
          }
        })
        .catch(function(err) {
          console.error('Error in products.getProducts() API ', err);
        })
      } else {
        $scope.addForm = {name: null, description: null};
      }
    })();
    
    /*=========================================default service calls==============================================================*/
    // populates the modal with categories when opened as an add modal
    const getProductsCategories = function() {
      products.getProducts()
        .then(function(res) {
          // get distinct categories 
          let distinctCategoryNames = [];
          let productCategoriesArr = [];
          for (let productCategory of res.data.categories) {
              if (!distinctCategoryNames.includes(productCategory.code)) {
                  distinctCategoryNames.push(productCategory.code); //using this space to keep track of distinct code names
                  let productCategoriesObj = {};
                  productCategoriesObj.categoryName = productCategory.code;
                  productCategoriesObj.priceType='';
                  productCategoriesObj.tier1 = 0;
                  productCategoriesObj.tier2 = 0;
                  productCategoriesObj.tier3 = 0;
                  productCategoriesArr.push(productCategoriesObj)
              }
          }
          $scope.distinctCategories = productCategoriesArr;
        })
        .catch(function(err) {
          console.error('Error in products.getProducts() API ', err);
        })
    };
    
   
    $scope.addPriceCapsule = function(){
       if (!$scope.validate()) {
        return;
      }

      if($scope.modalType === "EDIT"){
        if(!$scope.isTableModified) {
          $scope.cancel();
          growl.error("Nothing is changed to Submit!")
          return;
        }
        if($scope.isTableModified && !$scope.isTableSaved) {
          growl.error("Please Save the pricing before Submitting!");
          return;
        }
      }

      let formToCheck = $scope.addForm;
      
      let productPricingArr = [];
      
      $scope.distinctCategories.forEach((elem) => {
        let productPricingObj = {};
        if(!$scope.editModal){
          productPricingObj.categoryName = elem.categoryName;
        } else {
          productPricingObj.productName=elem.productName;
        }
        productPricingObj.priceType = elem.priceType;
        productPricingObj.tier1 = elem.tier1;
        productPricingObj.tier2 = elem.tier2;
        productPricingObj.tier3 = elem.tier3;
        productPricingArr.push(productPricingObj);
      });
      formToCheck.productsPricing = productPricingArr;
      console.log("data to send",formToCheck);
      if ($scope.role === 'AGENT') {
        pricingService.addAgentPricingCapsule(formToCheck)
          .then(function(res) {
              if (res.status === $global.status.SUCCESS) {
                growl.success(res.data.message);
                $scope.cancel();
              } else {
                let errors = res.error.errors;
                for (let i = errors.length - 1; i >= 0; i--) {
                  $scope[errors[i].code] = errors[i].message ? errors[i].message : errors[i].code;
                  growl.error(errors[i].message)
                }
                if (res.error.errors.length === 0) {
                  growl.error(res.error.message);
                }
              }
            })
          .catch(function(err){
            console.error('Error in addAgentPricingCapsule() ', err);
          });
      } else if ($scope.role === 'WHOLESALER') {
        pricingService.addWholesalerPricingCapsule(formToCheck)
          .then(function(res) {
            if (res.status === $global.status.SUCCESS) {
              growl.success(res.data.message);
              $scope.cancel();
            } else {
              let errors = res.error.errors;
              for (let i = errors.length - 1; i >= 0; i--) {
                $scope[errors[i].code] = errors[i].message;
                growl.error(errors[i].message)
              }
              if (res.error.errors.length === 0) {
                growl.error(res.error.message);
              }
            }
          })
          .catch(function(err){
            console.error('Error in addWholesalerPricingCapsule() ', err);
          });
      }
    };

    /*=========================================helper functions==============================================================*/
    $scope.sortTable=function(){
      $scope.sorted=$scope.sorted?false:true;
      if(!$scope.sorted){
        $scope.distinctCategories.sort((a,b) => {
          var textA = a.productName.toUpperCase();
          var textB = b.productName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      } else {
        $scope.distinctCategories.reverse();
      }
    }
    $scope.validate = function() {
      let formToCheck = $scope.addForm;

      for (let property in formToCheck) {
        if (!formToCheck[property]) {
          growl.error("Please fill in all the required fields");
          return false;
        }
      }
      if ($scope.distinctCategories.length === 0) {
        growl.error("Please fill in the table");
        return false;
      } else {
        for (let i = 0; i < $scope.distinctCategories.length; i++) {
          let currentRow = $scope.distinctCategories[i];
          if (!validateEditableTableFormRow(currentRow)) {
            console.error('Invalid values in the table ', currentRow);
            growl.error("Invalid Values in the table");
            return false;
          }
        }
      }
      return true;
    };
    
    function validateEditableTableFormRow(currentRow) {
      let name = $scope.editModal?currentRow.productName:currentRow.categoryName;
      let priceType = currentRow.priceType;
      let tier1 = currentRow.tier1;
      let tier2 = currentRow.tier2;
      let tier3 = currentRow.tier3;
      
      if (!name) {
        return false;
      }
      
      if (!priceType && (priceType !== 'FLAT' || priceType !== 'PERCENTAGE')) {
        return false;
      }
      
      // tier1 = 0
      if ((!tier1 && tier1!==0) || tier1 < 0) {
        return false;
      }
  
      if ((!tier2 && tier2!==0) || tier2 < 0) {
        return false;
      }
  
      if ((!tier3 && tier3!==0) || tier3 < 0) {
        return false;
      }
      
      return true;
    }
    
    $scope.cancel = function(res) {
      $uibModalInstance.dismiss(res);
    };

    // code to update the radio button CSS
    $scope.setActive = function(index,val) {
      $scope.distinctCategories[index].priceType=val;
    };

    $scope.allowEdit=function(tableform){
      $scope.isTableModified = true;
      $scope.datasetCopy=[];
      angular.copy($scope.distinctCategories,$scope.datasetCopy); // making a copy to restore when changes are cancelled
      tableform.$show();
    };
    $scope.cancelChanges=function(tableform){
      $scope.isTableModified = $scope.isTableSaved ? true : false;
      $scope.distinctCategories=$scope.datasetCopy; // restoring data on Cancel
      $scope.distinctCategories.forEach((element,index)=>{
        $scope.setActive(index,element.priceType); // restoring all the button's CSS on Cancel
      });
      tableform.$cancel();
    }

    function reloadPage() {
      let rType = $scope.role === 'AGENT' ? 'REO' : 'Partner';
      setTimeout(function() {
        $state.go('root.products', {type: rType}, {reload: true});
      }, 500);
    }
    
    if(!$scope.editModal){ // calling api to get Categories for Adding a new Price Capsule
      getProductsCategories();
    }
  });
