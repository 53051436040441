'use strict';
// Is Obsolete now - merged into Vendor Items Modal.
/**
 * @ngdoc function
 * @name scrapQApp.controller:VendorPricingCtrl
 * @description
 * # VendorPricingCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('VendorPricingCtrl', function ($scope, $filter, authentication, vendorService, vendorId, Upload, pricingService, products, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap) {


        $scope.inrFilter = $filter('INR');
        $scope.color = 'green';
    
        (function () {
            var columnDefs = [
                {
                    headerName: "Category",
                    field: "productCategoryName",
                    flex: 2,

                    cellStyle: { 'text-align': 'left' },
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorParams: {
                        values: ['Type-1 Recyclables', 'E-Waste', 'Metals', 'Recyclables by Unit'],
                        cellRenderer: '',
                        cellHeight: 30,
                      },
                    editable: function (params) {
                        return params.node.data.productName === 'empty';
                    }

                },
                {
                    headerName: "Product",
                    field: "productName",
                    flex: 2,

                    cellStyle: { 'text-align': 'left' },
                    cellEditor: 'agRichSelectCellEditor',

                    editable: function (params) {
                        return params.node.data.productName === 'empty';
                    }
                },
                {
                    headerName: "Vendor Product",
                    field: "vendorItemName",
                    flex: 2,

                    cellStyle: { 'text-align': 'left' },
                    cellEditor: 'agRichSelectCellEditor',
                    editable: function (params) {
                        return params.node.data.productName === 'empty';
                    }
                },
                {
                    headerName: "Pricing",
                    field: "price",
                    flex: 1,
                    cellRenderer: AmountCellRenderer,
                    maxWidth: 130,
                    cellStyle: params => {
                        if ($scope.editPrices) {
                            return { backgroundColor: 'white', border: '1px solid rgb(228 231 234)', boxShadow: '0 0 2px 2px rgb(236 236 236)' };
                        }
                        return { 'text-align': 'right' }
                    },
                    editable: params => $scope.editPrices,
                    singleClickEdit: true

                },
                // {
                //     headerName: "",
                //     field: "",
                //     maxWidth:35,
                //     cellRenderer: ViewCellRenderer,
                //     cellStyle: { 'text-align': 'right', 'max-width': '20px !important' },
                // }
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,

                },
                suppressCsvExport: true,
                suppressExcelExport: true,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        console.log("on get row style")
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    // params.api.sizeColumnsToFit();
                    // params.columnApi.autoSizeAllColumns();
                    $scope.gridOptions.api.setRowData($scope.vendorPriceList);
                },
                onCellClicked: function (event) {
                    console.log("onCellClicked event: ", event)

                    //To edit using side buttons
                    // if (event && event.data && event.column.colId == "action") {
                    //     $scope.gridOptions.api.startEditingCell({
                    //         rowIndex: event.rowIndex,
                    //         colKey: 'vendorPrice'
                    //     });
                    // }

                    // $scope.vendorDetails(0);
                    // $scope.vendorDetails(event.rowIndex);

                },
                onCellValueChanged: function (params) {
                    console.log("onCellValueChanged: ", params);
                    params.data.modified = true;
                    console.log($scope.vendorPriceList);

                    $scope.color = 'red';
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                },
                postSort: rowNodes => {
                    console.log(
                        "in postsort"
                    )
                }
            }; //End of gridOptions//

            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '-';
                    var iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };


            //Side Edit Buttons
            function ViewCellRenderer() {
            }

            ViewCellRenderer.prototype.init = function (params) {
                console.log(params);
                this.eGu = document.createElement('div');
                this.eGu.style.textAlign = 'center';
                this.eGu.style.cssText = 'display: flex; justify-content: center;';
                if (params.data !== "" || params.data !== undefined || params.data !== null) {
                    let iconsDiv = `<i class="fa fa-circle ${(params.data.modified)?'c-r':'c-g'}" style="margin: 9px auto; color: green"};"></i>`
                    this.eGu.innerHTML = iconsDiv;
                }
            };

            ViewCellRenderer.prototype.getGui = function () {
                return this.eGu;
            };


            $scope.onBtStartEditing = function (key, char, pinned) {
                $scope.editPrices = true;
                console.log("ononBtStartEditing");
                // $scope.gridOptions.api.refreshCells();
                $scope.gridOptions.api.setRowData($scope.getRowData());
                $scope.gridOptions.api.setFocusedCell(0, 'lastName', pinned);

                // for (let i = 0; i < $scope.gridOptions.api.getDisplayedRowCount(); i++) {
                //     console.log(i);

                //     $scope.gridOptions.api.startEditingCell({
                //         rowIndex: i,
                //         colKey: 'vendorPrice',
                //         // set to 'top', 'bottom' or undefined
                //         rowPinned: pinned,
                //         keyPress: key,
                //         charPress: char,
                //     });
                // }

            }

            $scope.onClose = function () {
                $scope.gridOptions.api.stopEditing();
                $scope.editPrices = false;
                let rowData = $scope.getRowData();

                $scope.gridOptions.api.setRowData(rowData);

                console.log($scope.getRowData())

 

            }

            $scope.addRow = function () {

                let rowData = $scope.getRowData();
                const row = {
                    productCategory: "empty",
                    itemName: "empty",
                    productName: "empty",
                    vendorPrice: 0
                }
                console.log("grid options' rowData: ", $scope.gridOptions.rowData)
                rowData.unshift(row)
                $scope.gridOptions.api.setRowData(rowData)
            }
            // $scope.addRow = function () {
            //     var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
            //     var res = $scope.gridOptions.api.applyTransaction({
            //       add: newItems,
            //     });
            //     printResult(res);
            //   }
            //   function createNewRowData() {
            //     var newData = {
            //         productCategory: 'Toyota ',
            //       model: 'Celica ',
            //       price: 35000,

            //     };
            //     return newData;
            //   }


        })();

        $scope.getRowData = () => {
            var rowData = [];
            $scope.gridOptions.api.forEachNode(function (node) {
                rowData.push(node.data);
            });
            return rowData;
        }

        $scope.refreshGrid = () => {
            let rowData = $scope.getRowData();
            $scope.gridOptions.api.setRowData(rowData);
        }

        $scope.submitItems = () => {

            console.log("Submitting Items");
            const rowData = $scope.getRowData();

            console.log(rowData);

            rowData.forEach(row => {

                if(row.modified) {
                    $scope.priceData = {
                        "vendorId": row.vendorId, 
                        "vendorItemsId": row.vendorItemsId,
                        "vendorPrice": row.vendorPrice,
                        "vendorPriceId": row.vendorPriceId
                    };

                    $scope.updateVendorPrice($scope.priceData);
                }



            })
        }




        (function () {
            vendorService.retrievePricingForVendorModal(vendorId).then(function (res) {

                if (res.status === "SUCCESS") {
                    console.log("retrievePricing: ", res)
                    $scope.vendorName = res.data.vendorName;
                    $scope.pricedVendorItemsCount = res.data.pricedVendorItemsCount;
                    $scope.nonPricedVendorItemsCount = res.data.nonPricedVendorItemsCount;
                    $scope.lastUpdatedPriceDate = res.data.lastUpdatedPriceDate.split(" ")[0];

                    if (res.data.vendorPricesModalList.length > 0) {
                        console.log(res.data.vendorPricesModalList)
                        $scope.vendorPriceList = res.data.vendorPricesModalList.filter(item => item.vendorPriceSet)
                        console.log($scope.vendorPriceList);
                        $scope.gridOptions.api.setRowData($scope.vendorPriceList)
                    }
                }

            });
        })();

        (function () {

            // vendorService.retrieveItemsForVendor(vendorId).then(function (res) {
            //     if (res.status === "SUCCESS") {
            //         console.log(res);
            //         if (res.data.items.length > 0) {
            //             $scope.vendorItemsList = res.data.items;
            //             console.log("vendorItemsList", $scope.vendorItemsList);
            //             // $scope.gridOptions.api.setRowData($scope.vendorPriceList)
            //         }
            //     }

            // })
        })();

        $scope.updateVendorPrice = function (priceData) {
            if (!priceData) {
                growl.error('Vendor Pricing data cannot be null');
            }
            vendorService.updateVendorPricing(priceData).then(function (res) {
                console.log(res);
                if (res.status === "SUCCESS") {
                    growl.success("Vendor Price Updated Successfully.");
                    // $uibModalInstance.close('Close');
                } else {
                    growl.error("Failure while updating the price");
                }
            })
        }

        /*=========================================Helper functions======================================================*/
        /**
         * when X icon or close button is clicked
         */
        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });