'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:VendorPriceCtrl
 * @description
 * # VendorPriceCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('VendorPriceCtrl', function($scope, products, ngTableParams, $state, $global, $uibModal, envService, editableOptions, growl, vendorService, $log) {
        

        products.getProducts().then(function(res) {
            if (res.data.categories) {
                $scope.data = res.data.categories;

                var addresstypes = ["Residence", "Shop", "Office", "Hotel", "Automobile", "Hospital"];
                var dataset = {};

                res.data.categories.forEach(function(catdata, idx1) {

                    if (!dataset[catdata.name]) {
                        dataset[catdata.name] = {}
                    }
                    addresstypes.forEach(function(type, idx) {
                        if (type == catdata.consumercategoryname) {

                            if (!dataset[catdata.name][type]) {
                                dataset[catdata.name][type] = {}
                            }
                            dataset[catdata.name][type].price = catdata.price;
                            dataset[catdata.name][type].categoryid = catdata.categoryId;
                        }
                    });
                });

                console.log(dataset)
                $scope.dataset = dataset;
            }
        });


        $scope.addPricing= function(vendorId){
            console.log(vendorId)
            var modalInstance = $uibModal.open({
                templateUrl: 'views/vendor-items-price-modal.html',
                controller: 'VendorItemsPriceCtrl',
                size: "md",
                resolve: {
                    items: function() {
                        return vendorId;
                    },
					vendorUpdateItem: function(){
						return null;;
					},
					modalHeader : function() {
						return null;
					},
                    vendorItemsSelected : function() {
                        return null;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
                console.log("hi")
                $scope.price();
            }, function() {
                console.log('Modal dismissed at: ' + new Date());
                $scope.price();
            });
        }

        $scope.price = function(){

        vendorService.retrieveAllPricing().then(function(res){
            if (res.status == "SUCCESS") {
                if (res.data.vendorItems.length>0) {
                    $scope.vendorPricing_list = res.data.vendorItems;
                    vendorService.retrieveAllItems().then(function(res){
                        console.log(res)
                        if (res.status == "SUCCESS") {
                            if (res.data.vendorItems.length>0) {
                                $scope.vendorItems_list = res.data.vendorItems;
                                console.log("PRICING",$scope.vendorPricing_list);
                                console.log("ITEMS",$scope.vendorItems_list);
                                $scope.vendorPricing_list.forEach(function(element, index){
                                    element.prices.forEach(function(elmnt, indx){
                                        $scope.vendorItems_list.forEach(function(elm,idx){
                                            if (elmnt.vendorItemsId == elm.vendorItemsId) {
                                                elmnt['itemName'] = elm.itemName;
                                            }
                                        });
                                    });
                                });
                                console.log($scope.vendorPricing_list);
                            }
                        }
                    });
                    console.log($scope.vendorPricing_list)
                    vendorService.retrieveAllVendorsCity().then(function(res){
                        console.log(res)
                        if (res.status == 'SUCCESS') {
                            if (res.data.vendors.length > 0) {
                                $scope.vendor_list = res.data.vendors;
                                $scope.vendor_list.forEach(function(element,index){
                                    $scope.vendorPricing_list.forEach(function(elnt,indx){
                                        if (element.vendorId == elnt.vendorId) {
                                            elnt['vendorName'] = element.vendorName;
                                        }
                                    })
                                })
                            }
                        }
                    });
                    console.log($scope.vendorPricing_list);
                }
            }
        });
        }
        $scope.price();
    });
