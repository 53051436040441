'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AppointmentsModalCtrl
 * # AppointmentsModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AppointmentsModalCtrl', function ($scope, $rootScope, $log, $global, $uibModalInstance, $state, growl, $filter, items, consumer, $uibModal, SellNow, NgMap, agentService, $moment) {
    $scope.feedItem = {};
    $scope.data = {};
    var latLngBound = [];

    $scope.finalStatus = true;

    /////////////////////////////////Courousal Start////////////////////////////////////////
    $scope.myInterval = 5000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
    var slides = $scope.slides = [];
    var currIndex = 0;



    $scope.randomize = function () {
      var indexes = generateIndexesArray();
      assignNewIndexesToSlides(indexes);
    };



    // Randomize logic below

    function assignNewIndexesToSlides(indexes) {
      for (var i = 0, l = slides.length; i < l; i++) {
        slides[i].id = indexes.pop();
      }
    }

    function generateIndexesArray() {
      var indexes = [];
      for (var i = 0; i < currIndex; ++i) {
        indexes[i] = i;
      }
      return shuffle(indexes);
    }

    function shuffle(array) {
      var tmp, current, top = array.length;

      if (top) {
        while (--top) {
          current = Math.floor(Math.random() * (top + 1));
          tmp = array[current];
          array[current] = array[top];
          array[top] = tmp;
        }
      }

      return array;
    }

    //////////////////////////////////////// Courousel End ////////////////////////////////////////////

    function resetRatingTags(rating) {

      if (rating && $scope.ratings) {

        $scope.rating = $filter('filter')($scope.ratings, {
          rating: rating
        })[0];

        $scope.ratingTags = $scope.rating.tags;

      }
    }

    $scope.$watch('feedItem.rating', function (newValue, oldValue) {
      resetRatingTags(newValue);

    });
    $scope.cancel = function (res) {
      $uibModalInstance.dismiss(res);
    };
    $scope.ok = function (res) {
      if ($scope.feedItem && $scope.comments) {
        $scope.save();
      }
      if ($scope.comments) {
        $scope.saveComment();
      }
      if ($scope.agentComments) {
        $scope.addAgentComments();
      }
      if (!($scope.feedItem && $scope.comments)) {
        $uibModalInstance.dismiss(res);
      }
    };

    $scope.warehouseFound = false;

    function getSells() {
      consumer.getSellById(items.confirmationId).then(function (res) {
        $scope.items = res.data.sell;
        items = $scope.items;
        // if (items.customerSignature) {
        //   slides.push({
        //     image: items.customerSignature,
        //     text: "Customer Signature",
        //     id: currIndex++,
        //     isSignature: true
        //   });
        // };
        // if (items.scrapPhotograph) {
        //   slides.push({
        //     image: $scope.items.scrapPhotograph,
        //     text: "Scrap Image",
        //     id: currIndex++,
        //     isSignature: true
        //   });
        // };
        $scope.items.items.forEach(function (itemObj) {
          if (itemObj.imageURL) {
            slides.push({
              image: itemObj.imageURL,
              text: itemObj.categoryName,
              id: currIndex++
            });
          }
        })
        $scope.imageTitle = slides.length > 0 ? slides[0].text : "";
        console.log($scope.items)
        if ($scope.items && $scope.items.consumerAddress) {
          $scope.consumerLocation = $scope.items.consumerAddress.latitude + "," + $scope.items.consumerAddress.longitude;
          var consumerLocation = [$scope.items.consumerAddress.latitude, $scope.items.consumerAddress.longitude];
          latLngBound.push(consumerLocation);
        }
        if ($scope.items && $scope.items.warehouseId && $scope.items.warehouseName) {
          $scope.warehouseFound = true;
        } else {
          $scope.warehouseFound = false;
        }
        if ($scope.items && $scope.items.agentarrived) {
          $scope.arrivedLocation = $scope.items.arrivedlat + "," + $scope.items.arrivedlong;
          var arrivedLocation = [$scope.items.arrivedlat, $scope.items.arrivedlong];
          latLngBound.push(arrivedLocation);
        }
        if ($scope.items && $scope.items.closedLat && $scope.items.closedLong) {
          $scope.closedLocation = $scope.items.closedLat + "," + $scope.items.closedLong;
          var closedLocation = [$scope.items.closedLat, $scope.items.closedLong];
          latLngBound.push(closedLocation);
        }
        if ($scope.items.agentObjId) {
          $scope.data.agentId = $scope.items.agentObjId;
          if ($scope.items.preferredDate && $scope.items.preferredSlot) {
            var makeDate = moment($scope.items.preferredDate, "DD-mmm-YYYY").toDate();
            var getTime = $scope.items.preferredSlot.split("-");
            var fromTime = parseInt(getTime[0].split(":00")[0]);
            if (fromTime) {
              fromTime = fromTime - 1;
            }
            var toTime = parseInt(getTime[1].split(":00")[0]);
            var fromDate = new Date(makeDate);
            fromDate = fromDate.setHours(fromTime);
            fromDate = new Date(fromDate);
            fromDate = fromDate.setMinutes(30);
            var toDate;
            if ($scope.items.statusCode == "REQUEST_COMPLETED") {
              toDate = new Date(makeDate);
              toDate = toDate.setHours(toTime);
            } else {
              toDate = new Date();
            }
            $scope.data.fromDate = $moment(new Date(fromDate)).format("DD-MMM-YYYY HH:mm:ss");
            $scope.data.toDate = $moment(new Date(toDate)).format("DD-MMM-YYYY HH:mm:ss");
            // $scope.data.fromDate = "28-Feb-2018 12:00:00";
            // $scope.data.toDate = "28-Feb-2018 14:00:00";
          }
          getLocation();
        } else {
          callBound();
        }
        $scope.items.items.forEach(function (elem, idx) {
          var items = $scope.items.items[idx];
          var amount = $scope.items.items[idx].amount ? $scope.items.items[idx].amount : null;

          if (amount && typeof amount === "string") {
            amount = Number(amount.replace(/,/g, ''));
          }
          console.log("amount", amount);
          $scope.items.items[idx].amount = parseFloat(Math.round(amount * 100) / 100).toString();
        })

        // replace all the , in the string for totalAmount and totalQuantity
        $scope.items.totalAmount = $scope.items.totalAmount.replace(",", "");
        $scope.items.totalQuantity = $scope.items.totalQuantity.replace(",", "");
        // .toLocaleString('en-IN');
        $scope.items.totalAmount = (Math.round(parseFloat($scope.items.totalAmount) * 100) / 100);
        $scope.items.totalQuantity = (Math.round(parseFloat($scope.items.totalQuantity) * 100) / 100);

        getfeendinRatings();

        if ($scope.items.rating != null) {
          if ($scope.items.rating.agentRating != null) {
            $scope.agentRatings = [{
              current: $scope.items.rating.agentRating,
              max: 5
            }];
          }
          if ($scope.items.rating.rating != null) {
            $scope.serviceRatings = [{
              current: $scope.items.rating.rating,
              max: 5
            }];
          }
        }
        console.log("Agent Rating ", $scope.agentRating, " Service Rating ", $scope.serviceRating);

        consumer.getRatings().then(function (res) {
          $scope.ratings = res.data.ratingInfos

        })
        if ($scope.items.paymentStatus == "SUCCESS") {
          $scope.paymentStatus = true;
        }
        SellNow.retrievePayment($scope.items.confirmationId).then(function (res) {
          if (res.status == "SUCCESS") {
            if (res.data.paymentVM != null) {
              $scope.paymentDetails = res.data.paymentVM;
              console.log($scope.paymentDetails);
            }
          }
        });

        if ($scope.items.statusCode == "REQUEST_COMPLETED") {
          $scope.finalStatus = false;
        } else {
          $scope.finalStatus = true;
        }
        if ($scope.items.agentComments.length > 0) {
          $scope.agentCommentsObj = $scope.items.agentComments;
        }
        getComments();
      });
    }

    getSells();

    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    console.log($rootScope.loginData.features)

    function getfeendinRatings() {
      $scope.feedback = [];
      consumer.getPendingFeedBacks($scope.items.consumerObjId).then(function (res) {
        if (res.status == "SUCCESS") {
          var feedbacks = res.data.feedbacks;
          angular.forEach(feedbacks, function (feed) {

            if ($scope.items.sellObjId == feed.sellId) // google.maps.setCenter($scope.center);
            {
              $scope.feedback.push(feed);
              $scope.feedItem.feedbackId = feed.feedbackId;

            }

          });

        }
      });
    }
    $scope.openImage = function (image, imageName) {
      let obj = {
        image: image,
        imageName: imageName
      }
      var viewImageModalInstance = $uibModal.open({
        templateUrl: 'views/view-image.html',
        controller: 'ViewImageController',
        size: "lg",
        backdrop: 'static',
        resolve: {
          imageData: function () {
            return obj;
          }
        }
      });

      viewImageModalInstance.result.then(function () {
        console.log("XXXXXXX")
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
      });

    }
    $scope.save = function () {
      $scope.submit = true;
      if ($scope.feedItem.rating > 0) {
        consumer.submitFeedBack($scope.feedItem).then(function (res) {
          if (res.status == "SUCCESS") {
            growl.success(res.data.message || "Thank you for your feedback");
            //$global.feedBackChecked = true;
            //$uibModalInstance.dismiss('cancel');
            getfeendinRatings();
            $scope.saveComment();
          } else {
            growl.success(res.data.message);
          }
        })
      }
    }

    $scope.editSellNow = function () {
      $scope.commentStatus = true;
      var data = {
        consumerId: $scope.items.consumerObjId,
        confirmationId: $scope.items.confirmationId
      }
      var modalInstance = $uibModal.open({
        templateUrl: 'views/sells-consumers.html',
        controller: 'SellConsumerCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          ids: function () {
            return data;
          }
        }

      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;

      }, function (res) {

        getSells();

      });

    }
    $scope.reAssignAgent = function () {
      $scope.commentStatus = true;
      var data = {
        consumerId: $scope.items.consumerObjId,
        confirmationId: $scope.items.confirmationId,
        conLat: $scope.items.consumerAddress.latitude,
        conLong: $scope.items.consumerAddress.longitude,
        sellObjId: $scope.items.sellObjId,
        agentName: $scope.items.agentName
      }
      var modalInstance = $uibModal.open({
        templateUrl: 'views/reassign-agent-modal.html',
        controller: 'ReAssignAgentCtrl',
        size: 'lg',
        backdrop: 'static',
        windowClass: 'myModal',
        resolve: {
          items: function () {
            return data;
          }
        }

      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;

      }, function (res) {

        getSells();

      });
    }


    function getComments() {
      consumer.getComments($scope.items.sellObjId).then(function (res) {
        if (res.status == 'SUCCESS') {
          if (res.data.comments.length > 0) {
            $scope.sellComments = res.data.comments;
          }
        } else {
          growl.success(res.error.message || ' Unable To retrive comments');
        }
      })
    }

    $scope.saveComment = function () {
      $scope.commentStatus = true;
      var obj = {
        objId: $scope.items.sellObjId,
        comment: $scope.comments.comment
      }
      consumer.addComment(obj).then(function (res) {
        if (res.status == 'SUCCESS') {
          growl.success(res.data.message || ' Comment added successfully');
        }
        $scope.comments.comment = '';
        getComments();
        $scope.commentStatus = false;
        $uibModalInstance.dismiss(res);
      })
    }

    //call service method for our current controller file
    $scope.addAgentComments = function () {
      $scope.agentCommentStatus = true;
      var obj = {
        objId: $scope.items.sellObjId,
        comment: $scope.agentComments
      }
      SellNow.addAgentComment(obj).then(function (res) {
        if (res.status == 'SUCCESS') {
          growl.success(res.data.message || ' Comment added successfully');
          getSells();
        }
        $scope.agentComments = '';
        $scope.agentCommentStatus = false;
        // $uibModalInstance.dismiss(res);
      })
    }

    $scope.updateDetails = function () {
      $scope.commentStatus = true;
      var id = $scope.items.confirmationId;
      var items = $scope.items;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-items.html',
        controller: 'AddItemsCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return id;
          },
          item: function () {
            return items;
          },
          address: function () {
            return $scope.items.consumerAddress;
          }

        }
      });

      $scope.finalStatus = false;

      modalInstance.result.then(function (data) {
        // $scope.selected = selectedItem;
        // getAgentAppointments($scope.vm.params);
        getSells();
      }, function () {

      });
    }

    $scope.declineSellItem = function () {
      $scope.commentStatus = true;
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/deleteConfirmation.html',
        controller: "DeleteCtrl",
        size: "md",
        resolve: {
          items: function () {

            return items;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getSells();
      }, function () {


      });
    }
    $scope.declineByCrm = function () {
      $scope.commentStatus = true;
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/deleteConfirmation.html',
        controller: "DeleteCtrl",
        size: "md",
        resolve: {
          items: function () {

            return items;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        var obj = {
          'sellObjId': items.sellObjId,
          'agentObjId': "CRMUSER",
        }
        SellNow.declineRequest(obj).then(function (res) {
          if (res.status == "SUCCESS") {
            growl.success(res.data.message);
            $scope.ok(res);
          } else if (res.status == "FAILURE") {
            growl.error(res.error.message);
          }
        })
      }, function () {


      });

    }
    $scope.cancelSellItem = function () {
      $scope.commentStatus = true;
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/cancelConfirmation.html',
        controller: "DeleteCtrl",
        size: "md",
        resolve: {
          items: function () {

            return items;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getSells();
      }, function () {

      });
    }

    $scope.cancelSellRecurring = function () {
      var id = $scope.items.confirmationId;

      if (confirm("Are you sure you want to delete all recurring appointments following " + id + "?")) {
        $scope.items.agentObjId = true;
        SellNow.cancelSellRquestsRecurring(id).then(function (res) {
          if (res.status = "SUCCESS") {
            // $scope.todayAppointment.splice(index, 1);
            growl.success(res.data.message);
            $scope.ok(res);
          } else if (res.status == "FAILURE") {
            growl.error(res.error.errors[0].message);
          }
        })
      }

    };

    $scope.selectWarehouse = function (warehouseId) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/select-warehouse-modal.html',
        controller: 'SelectWarehouseCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {

            return items;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        // $scope.loadPayments($scope.vm.params);
        getSells();
      });
    }

    $scope.makePayment = function () {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/payment-modal.html',
        controller: 'PaymentModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return items;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        getSells();
        $state.go($state.current, {}, { reload: true });
      });
    }

    // Map code
    function initMap(bounds) {
      NgMap.getMap().then(function (map) {
        $scope.map = map;
        if (bounds) {
          $scope.map.fitBounds(bounds);
        }
      });
    }

    initMap();

    // Polyline for track location
    function slidingwindow(a, n) {
      var r = []
      var count = 1;
      var first = 0;
      var last = n;
      for (var i = 0; i < Math.floor(a.length / n) + 1; i++) {
        var data = a.slice(first, last + 1);
        r.push(data);
        first = parseInt(last);
        last = parseInt(last + n);
      }
      return r;
    }

    var globalLocationData;
    $scope.filterCoordinates = [];
    $scope.renderPolyline = false;

    function callBound() {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < latLngBound.length; i++) {
        var position = new google.maps.LatLng(latLngBound[i][0], latLngBound[i][1]);
        bounds.extend(position);
      }
      if ($scope.map) {
        $scope.map.fitBounds(bounds);
      } else {
        initMap(bounds);
      }
    }

    function locationOprationForAgent(data) {
      globalLocationData = data.data.locations;
      if (globalLocationData.length > 0) {
        $scope.agentLastLocation = globalLocationData[globalLocationData.length - 1].latitude + "," + globalLocationData[globalLocationData.length - 1].longitude;
        var agentLastLocation = [globalLocationData[globalLocationData.length - 1].latitude, globalLocationData[globalLocationData.length - 1].longitude];
        latLngBound.push(agentLastLocation);
      }
      callBound();
      $scope.filterCoordinates = [];
      var coordinates = "", timestamp = "", latLng = "";
      var arr = slidingwindow(data.data.locations, 1999);
      async.forEachOf(arr, function (val1, key1, cb1) {
        coordinates = "";
        timestamp = "";
        latLng = "";
        var filterLatLng = "https://devapi.scrapq.com/match/v1/driving/:coordinates?timestamps=:timestamp&tidy=true";
        async.forEachOf(val1, function (val2, key2, cb2) {
          coordinates += val2.longitude + "," + val2.latitude;
          latLng += val2.latitude + "," + val2.longitude;
          timestamp += parseInt(new Date(val2.timestamp).getTime() / 1000);
          if (val1.length - 1 != key2) {
            coordinates += ";";
            timestamp += ";"
            latLng += "|";
          }
          cb2();
        }, function (err) {
          filterLatLng = filterLatLng.replace(":coordinates", coordinates);
          filterLatLng = filterLatLng.replace(":timestamp", timestamp);
          $.getJSON(filterLatLng).done(function (res) {
            if (res) {
              var sampleFilterPolyline = [];
              async.forEachOf(res.tracepoints, function (val3, key3, cb3) {
                if (val3 && val3.location) {
                  var latLngArray = [];
                  latLngArray.push(val3.location[1]);
                  latLngArray.push(val3.location[0]);
                  sampleFilterPolyline.push(latLngArray);
                }
                cb3();
              }, function (err) {
                $scope.filterCoordinates.push(sampleFilterPolyline);
                cb1();
              })
            } else {
              cb1();
            }
          }).fail(function (jqxhr, textStatus, error) {
            cb1();
          })
        })
      }, function (err) {
        $scope.renderPolyline = true;
      })
    }

    function getLocation() {
      agentService.getAgentLocation($scope.data).then(function (res) {
        if (res.status == "SUCCESS") {
          locationOprationForAgent(res);
        }
      }, function (err) {
      })
    }



    $scope.$watch('active', function (newIndex, oldIndex) {
      console.log($scope.slides)
      $scope.imageTitle = $scope.slides[newIndex].text;
    });
  });



