'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ViewTansportInvModalCtrl
 * @description
 * # ViewTansportInvModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('ViewTansportInvModalCtrl', function($scope, $sce, $rootScope, $state, $global, $window, $uibModal, $uibModalInstance, warehouseService, growl, $filter, request, $timeout) {
    
    $scope.billNumber = {value: null};
    $scope.truckNumber = {value: null};
    $scope.requestDetails = request;
    $scope.content = $sce.trustAsResourceUrl($scope.requestDetails.gatePassURL);
    $scope.dispatchButtonClicked = false;

    $scope.blobURLs = [];
    
    
    if (request) {
      let totalAmount = 0.0;
      request.items.forEach(function(elem, indx) {
        // elem.amount = elem.amount.replace(',', '');
        elem.quantity = elem.quantity.replace(',', '');
        totalAmount += parseFloat(elem.amount);
      });
      $scope.requestDetails['totalAmount'] = totalAmount;
    }
    
    $scope.oneAtATime = true;
    $scope.isOpen = false;
    
    // Data
    $scope.groups = $scope.requestDetails && $scope.requestDetails.transferOrderDocuments ? $scope.requestDetails.transferOrderDocuments : [];
    $scope.invoicesGroups = $scope.requestDetails && $scope.requestDetails.transferOrderInvoices ? $scope.requestDetails.transferOrderInvoices : [];
    
    // To Generate Downloadable blobs
		$scope.generateBlobURL = function(url, ind, arr) {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					// $apply used to refresh bindings
					$scope.$apply(function() {
						let blobURL = URL.createObjectURL(blob);
						arr[ind].documentDownloadURL = blobURL;
						$scope.blobURLs.unshift(blobURL);
						// Enable download button
						arr[ind].isDocumentDownlaodable = true;
					});
					console.log('group.isDocumentDownlaodable: ',arr[ind].isDocumentDownlaodable);
				})
				.catch((error) => {
					console.error('Error:', error);
					console.error('url: ', url)
				});
    }

    if ($scope.groups && $scope.content) {
      let id = $scope.requestDetails && $scope.requestDetails.confirmationId ? $scope.requestDetails.confirmationId : '-';
      let url = $scope.content;
      const previewOptions = "#&toolbar=0&navpanes=0";
      $scope.groups.unshift({documentConfirmationId: id, documentURL: url}); // for the parent document in TO row
      $scope.invoicesGroups.unshift({documentConfirmationId: id, documentURL: url}); // for the parent document in Invoices row

      $scope.groups.forEach((group, index, array) => {
				if (group.documentURL) {
					group.documentPreviewURL = $sce.trustAsResourceUrl(group.documentURL + previewOptions); // For PDF preview purpose
					$scope.generateBlobURL(group.documentURL, index, array); // For auto-download purpose

					// To set the pdf name for direct downloads
          let str = group.documentURL.toString(); // converting url to string for splitting later 
					let parts = str.split("/");
					let docName = parts[parts.length - 1];
					group.documentDownloadName = docName;
				}
			});

			$scope.invoicesGroups.forEach((group, index, array) => {
				if (group.documentURL) {
					group.invoicePreviewURL = $sce.trustAsResourceUrl(group.documentURL + previewOptions); // For PDF preview purpose
					$scope.generateBlobURL(group.documentURL, index, array); // For auto-download purpose
	
					// To set the pdf name for direct downloads
					let str = group.documentURL.toString(); // converting url to string for splitting later 
					let parts = str.split("/");
					let docName = parts[parts.length - 1];
					group.documentDownloadName = docName;
				}
			});
    }
    
    $scope.updateOpenStatus = function() {
      $scope.isOpen = $scope.groups.some(function(item) {
        return item.isOpen;
      });
    };
    
    
    $scope.cancel = function(res) {
      $scope.groups.shift();
      $scope.invoicesGroups.shift();
      $uibModalInstance.dismiss(res);
      $scope.blobURLs.forEach(blobURL => URL.revokeObjectURL(blobURL));
    };
    
    $scope.isDispatched = function() {
      return ($scope.requestDetails.status === 'DISPATCHED');
    };
    
    $scope.redirect = function(url) {
      window.open(url);
    };
    
    $scope.acceptTransferRequest = function(confirmationId) {
      warehouseService.acceptTransferRequest(confirmationId).then(function(res) {
        if (res && res.status === "SUCCESS") {
          growl.success(res.data && res.data.message ? res.data.message : "Approved");
          // $uibModalInstance.close('Close');
          $global.setLocalItem("transferRequestId", confirmationId, true);
          $state.go('root.transportInventory', {}, {reload: true});
          $uibModalInstance.close('Close');
        } else {
          if (res && res.data && res.data.message) {
            growl.error(res.data.message);
          } else if (res && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl.error("Failed to accept transfer order");
          }
        }
      }).catch(function(err) {
        growl.error("Failed to accept transfer order");
      })
    };
    
    $scope.rejectTransferRequest = function(confirmationId) {
      warehouseService.rejectTransferRequest(confirmationId).then(function(res) {
        if (res && res.status === "SUCCESS") {
          growl.success(res.data && res.data.message ? res.data.message : "Rejected");
          // $uibModalInstance.close('Close');
          $global.setLocalItem("transferRequestId", confirmationId, true);
          $state.go('root.transportInventory', {}, {reload: true});
          $uibModalInstance.close('Close');
        } else {
          if (res && res.data && res.data.message) {
            growl.error(res.data.message);
          } else if (res && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl.error("Failed to reject transfer order");
          }
        }
      }).catch(function(err) {
        growl.error("Failed to reject transfer order");
      })
    };
    
    $scope.dispatchTransportInventory = function(confirmationId) {
      if (!($scope.billNumber.value) || !($scope.truckNumber.value)) {
        growl.error("Missing Bill or Truck Number!");
        return;
      }
  
      // to avoid button spamming
      $scope.dispatchButtonClicked = true;
      
      warehouseService.dispatchTransferRequest(confirmationId, $scope.billNumber.value, $scope.truckNumber.value).then(function(res) {
        if (res && res.status === "SUCCESS") {
          growl.success(res.data && res.data.message ? res.data.message : "Dispatched");
          // $uibModalInstance.close('Close');
          $global.setLocalItem("transferRequestId", confirmationId, true);
          $state.go('root.transportInventory', {}, {reload: true});
          $uibModalInstance.close('Close');
        } else {
          if (res && res.data && res.data.message) {
            growl.error(res.data.message);
          } else if (res && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl.error("Failed to dispatch transfer order");
          }
        }
      }).catch(function(err) {
        growl.error("Failed to dispatch transfer order");
      })
    };
    
    $scope.editTransferRequest = function(request) {
      let objToSend = {
        id: request.fromWarehouse.warehouseId,
        from: "Transport",
        index: null,
        sort: null,
        size: null,
        page: null,
        key: null
      };
      
      objToSend["initiateTransportInventoryItems"] = {};
      
      objToSend["initiateTransportInventoryItems"]["selectedWarehouseId"] = request.fromWarehouse.warehouseId;
      objToSend["initiateTransportInventoryItems"]["toWarehouse"] = request.toWarehouse;
      objToSend["initiateTransportInventoryItems"]["confirmationId"] = request.confirmationId;
      objToSend["initiateTransportInventoryItems"]["pickUpDate"] = request.pickUpDate;
      
      let transportInventorySelectedItems = [];
      request.items.forEach(elem => {
        if (Number(elem.quantity) > 0) {
          let obj = {totalQuantity: Number(elem.quantity), itemName: elem.categoryName};
          transportInventorySelectedItems.push(JSON.parse(JSON.stringify(obj))); //creating deep copy of the object
        }
      });
      
      objToSend["initiateTransportInventoryItems"]["transportInventoryItems"] = transportInventorySelectedItems;
      
      
      if (transportInventorySelectedItems && transportInventorySelectedItems.length > 0) {
        // $state.go('root.transportInventory', objToSend);
        var modalInstance = $uibModal.open({
          templateUrl: 'views/transport-inventory-modal.html',
          controller: 'TransportInventoryModalCtrl',
          size: "lg",
          backdrop: 'static',
          resolve: {
            transferRequestData: function() {
              return objToSend;
            }
          }
        });
        
        modalInstance.result.then(function(res) {
          objToSend = [];
          if (res === 'Updated') {
            $timeout(function() {
              $state.go('root.transportInventory', {}, {reload: true});
              $uibModalInstance.close('Close');
            }, 1500);
          }
        }, function(res) {
          console.info('Modal dismissed at: ' + new Date());
        });
      } else {
        growl.error("Empty transport inventory items");
      }
      
    };
    
  });
