'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:UserRoleMetrixController
 * @description
 * # UserRoleMetrixController
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('UserRoleMetrixController', function ($scope, $rootScope, authentication, growl, $uibModal, $timeout) {

        $scope.userRoleMetrics = [];
        $scope.userRolesOptions = [];


        var colDefs = [
            {
                headerName: "",
                field: "id",
                maxWidth: 40,
                cellRenderer: 'agGroupCellRenderer',
            },
            {
                headerName: "Module",
                field: "module",
            },
            {
                headerName: "Sub Module",
                field: "subModule",
            },
            {
                headerName: "Type",
                field: "type",
            },
            {
                headerName: "Admin",
                field: "admin",
                cellRenderer: ToggleActionCellRenderer,
            },
            {
                headerName: "Master Franchise",
                field: "masterFranchise",
                cellRenderer: ToggleActionCellRenderer,
            },
            {
                headerName: "Wholesaler",
                field: "wholesaler",
                cellRenderer: ToggleActionCellRenderer,
            },
            {
                headerName: "Warehouse User",
                field: "warehouseUser",
                cellRenderer: ToggleActionCellRenderer,
            },
            {
                headerName: "Reporting User",
                field: "reportinguser",
                cellRenderer: ToggleActionCellRenderer,
            },
            {
                headerName: "Celekt User",
                field: "celektUser",
                cellRenderer: ToggleActionCellRenderer,
            }
        ];
        $scope.userRoleMetrixGridOptions = {
            columnDefs: colDefs,
            angularCompileRows: true,
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowHeight: 39,

            rowStyle: { background: '#f4f4f4' },
            rowSelection: 'single',
            pagination: true,
            paginationPageSize: 1000,
            masterDetail: true,
            detailCellRendererParams: {
                // level 2 grid options
                detailGridOptions: {
                    angularCompileRows: true,
                    enableCellChangeFlash: true,
                    columnDefs: [
                        {
                            headerName: "Field",
                            field: "field",
                            maxWidth: 200
                        },
                        {
                            headerName: "Admin",
                            field: "admin",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        },
                        {
                            headerName: "Master Franchise",
                            field: "masterFranchise",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        },
                        {
                            headerName: "Wholesaler",
                            field: "wholesaler",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        },
                        {
                            headerName: "Warehouse User",
                            field: "warehouseUser",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        },
                        {
                            headerName: "Reporting User",
                            field: "reportingUser",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        },
                        {
                            headerName: "Celekt User",
                            field: "celektUser",
                            cellRenderer: ToggleFieldActionCellRenderer,
                        }
                    ],
                    defaultColDef: {
                        flex: 1,
                    },
                    detailRowHeight: 240,
                },
                getDetailRowData: (params) => {
                    console.log(params)
                    $scope.selectedSubTableId = params.node.id;
                    $scope.getModuleFields(params.data, function (moduleData) {
                        $scope.moduleData = moduleData;
                        $scope.moduleData.module = moduleData.name;

                        $scope.moduleFields = [];
                        for (let i = 0; i < moduleData.fields.length; i++) {
                            let field = moduleData.fields[i];
                            $scope.moduleFields.push({
                                index: i,
                                field: field,
                                admin: $scope.getUserFieldStatus(field, "ADMIN", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                                masterFranchise: $scope.getUserFieldStatus(field, "MASTERFRANCHISE", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                                wholesaler: $scope.getUserFieldStatus(field, "WHOLESALER", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                                warehouseUser: $scope.getUserFieldStatus(field, "WAREHOUSEUSER", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                                reportingUser: $scope.getUserFieldStatus(field, "REPORTINGUSER", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                                celektUser: $scope.getUserFieldStatus(field, "CELEKTUSER", angular.copy($scope.moduleData), angular.copy($scope.userRoleMetrics)),
                            });
                        }
                        params.successCallback($scope.moduleFields);
                    });

                },
            },
            getRowStyle: function (params) {
                if (params.node.rowIndex % 2 === 0) {
                    return { background: 'white' };
                }
            },
            onGridReady: function (params) {
                $rootScope.hideAgGridLoadingRow("ag-overlay");

            },
            onCellClicked: function (event) {
                // $scope.openFieldsMetrixModal(event.data)
            },
            onFirstDataRendered: function (params) {
                params.api.sizeColumnsToFit();
            }
        };
        function ToggleActionCellRenderer() {
        }

        ToggleActionCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            if (params.value !== "" || params.value !== undefined || params.value !== null) {

                // let 
                let userMetrixObj = $scope.findUserRoleOption(params.colDef.field.toUpperCase(), params.data);

                $scope.actionButtonClassList = "";
                console.log('userrolematrix: ',userMetrixObj);
                let className = userMetrixObj.enabled ? 'toggle-active' : 'fa-rotate-180 toggle-inactive';
                let iconsDiv = `<i class="fa fa-toggle-on ` + className + `" style="font-size: 23px"
                ng-click="toggleClicked(\'`+ userMetrixObj.id + `\')"></i>`;

                this.eGui.innerHTML = iconsDiv;
            }
        };

        ToggleActionCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function ToggleFieldActionCellRenderer() {
        }

        ToggleFieldActionCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            if (params.value !== "" || params.value !== undefined || params.value !== null) {

                let data = {
                    userType: params.column.colId
                };

                if (params.column.colId == "admin") {
                    data.enabled = params.data.admin
                }
                if (params.column.colId == "masterFranchise") {
                    data.enabled = params.data.masterFranchise
                }
                if (params.column.colId == "wholesaler") {
                    data.enabled = params.data.wholesaler
                }
                if (params.column.colId == "warehouseUser") {
                    data.enabled = params.data.warehouseUser
                }
                if (params.column.colId == "reportingUser") {
                    data.enabled = params.data.reportingUser
                }
                if (params.column.colId == "celektUser") {
                    data.enabled = params.data.celektUser
                }

                let className = data.enabled ? 'toggle-active' : 'fa-rotate-180 toggle-inactive';
                let iconsDiv = `<i class="fa fa-toggle-on ` + className + `" style="font-size: 23px"
                ng-click="fieldToggleClicked(\'`+ params.data.index + `\',\'` + data.userType + `\')"></i>`;

                this.eGui.innerHTML = iconsDiv;
            }
        };

        ToggleFieldActionCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };


        $scope.findUserRoleOption = function (userType, optionData) {
            let userMetrix = $scope.userRoleMetrics.find(function (obj) {
                return obj.userType == userType && obj.module == optionData.module && obj.subModule == optionData.subModule && obj.type == optionData.type;
            });
            return angular.copy(userMetrix);
        }


        $scope.fieldToggleClicked = function (index, userType) {
            $scope.moduleFields[index][userType] = !$scope.moduleFields[index][userType];
            let fields = $scope.moduleFields.map(function (obj) {
                if (!obj[userType]) {
                    return obj.field;
                }
            }).filter(function (field) {
                return field;
            });

            let userMetrix = $scope.findUserRoleOption(userType.toUpperCase(), $scope.moduleData);

            let data = {
                id: userMetrix.id,
                enabled: userMetrix.enabled,
                disabledFields: fields
            };
            $scope.updateFields(data);

        }
        $scope.toggleClicked = false;
        $scope.toggleClicked = function (id) {
            let userMetrix = $scope.userRoleMetrics.find(function (obj) {
                return angular.copy(obj.id == id);
            });
            if (userMetrix) {
                let payload = {
                    "id": id,
                    "enabled": !userMetrix.enabled
                }
                authentication.updateUserRoleMetrix(payload).then(function (res) {
                    if (res.status == "SUCCESS") {
                        growl.success("Data Updated Successfully");
                        $scope.getUserScreensMetrix(true);
                    }
                }).catch(function (err) {

                })
            }
        };
        $scope.Init = function () {

            $scope.getUserScreensMetrix = function (reloadGrid) {
                var data = {
                    "city": $rootScope.loginData.city,
                    "userType": $rootScope.loginData.role,
                    "matrix": true
                };
                authentication.getUserRoleMetrics(data).then(function (res) {
                    if (res.status == "SUCCESS") {
                        $scope.userRoleMetrics = angular.copy(res.data.userRoleMatrix);
                        $scope.userRolesOptions = res.data.userRoleMatrix.filter(function (obj) {
                            return obj.userType == "ADMIN";
                        })
                        if ($scope.userRoleMetrixGridOptions.api && reloadGrid)
                            $scope.userRoleMetrixGridOptions.api.setRowData($scope.userRolesOptions);



                    } else {
                        $scope.userRolesOptions = [];
                        if ($scope.userRoleMetrixGridOptions.api && reloadGrid)
                            $scope.userRoleMetrixGridOptions.api.setRowData($scope.userRolesOptions);

                    }
                }).catch(function (err) {
                    $scope.userRolesOptions = [];
                    if ($scope.userRoleMetrixGridOptions.api && reloadGrid)
                        $scope.userRoleMetrixGridOptions.api.setRowData($scope.userRolesOptions);

                })

            };

            $scope.getUserScreensMetrix(true);
        };
        $scope.Init();

        $scope.selectedRowFields = [];
        $scope.isRowClicked = false;

        $scope.getModuleFields = function (data, callback) {
            data = {
                module: data.module,
                subModule: data.subModule,
                type: data.type
            };


            authentication.getUserRoleModules(data).then(function (response) {
                if (response.status == "SUCCESS") {
                    callback(response.data.module)
                } else {
                    callback();
                }
            })
        }

        $scope.getUserFieldStatus = function (field, userType, data, metricsData) {
            let metrixObj = metricsData.find(function (obj) {
                return obj.module == data.module && obj.subModule == data.subModule && obj.userType == userType && obj.type == data.type
            });
            if (metrixObj) {
                return metrixObj.disabledFields && metrixObj.disabledFields.includes(field) ? false : true;
            } else {
                return true;
            }
        }
        $scope.updateFields = function (data) {

            data.updateFields = true;
            authentication.updateUserRoleMetrix(data).then(function (res) {
                growl.success("Data Updated Successfully....!");
                $scope.userRoleMetrixGridOptions.api.detailGridInfoMap[$scope.selectedSubTableId].api.setRowData($scope.moduleFields);
                $scope.getUserScreensMetrix(false);
            })
        };
    });
