'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:AddProductCtrl
 * @description
 * # AddProductCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('AddProductCtrl', function($scope, products, Upload, httpService, warehouseInventoryService, consumer, $state, $global, $http, growl, $filter) {
    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    //$scope.url = $global.getApiUrl() + '/agentsData/import';
    $scope.categories=[];
    (function() {
      warehouseInventoryService.getCategories()
        .then(function(res) {
          console.log("res cats", res)
          if (res.status === "SUCCESS") {
            if (res && res.data && res.data.uniqueProductCategories) {
              $scope.categories = res.data.uniqueProductCategories;
            } else {
              console.error("No Categories Found", res.data);
            }
          } else {
            console.error("Error in getting all the categories list ", res);
          }
        }).catch(function(error) {
        console.error("Error in getting all the categories list ", error);
      })
    })();

    $scope.consumercategoriesList = [];
    consumer.userCategories().then(function(res) {
      if (res.status === "SUCCESS") {
        $scope.consumercategoriesList = res.data.categories;
      } else if (res.status === "FAILURE") {
        growl.error(res.error.message)
      }
    });
    
    $scope.upload = function(file) {
      $scope.submitted = true;
      if ($scope.addproduct.$valid) {
        const url = $scope.url;
        $scope.fileHash = false;
        $scope.progress = 0;
        $scope.hash = false;
        Upload.upload({
          url: url,
          file: file
        }).then(function(response) {
          if (response.data.status === "SUCCESS") {
            $scope.data.image = response.data.data.fileId;
            products.addProduct($scope.data).then(function(res) {
              if (res.status === "SUCCESS") {
                growl.success("Successfully added");
                $state.go('root.skupricing',{type:'SKU'});
              } else if (res.status === "FAILURE") {
                growl.error(res.error.message);
              }
            })
            .catch(er => {
              console.error(er);
            });
          }
          $scope.progress = 0;
        }, function(resp) {
        
        }, function(evt) {
          const progressPercentage = $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
        });
      }
    }
  });
