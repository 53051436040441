'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddUserCtrl
 * @description
 * # AddUserCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddUserCtrl', function ($scope, area, authentication, wholesalerService, warehouseService, vendorService, documents, consumer, Upload, $window, $global, growl, $stateParams, $state, $timeout, NgMap, $rootScope, $moment) {

    var items = $stateParams.type ? $stateParams.type : "AGENT";

    let salesDetails = wholesalerService.getSalesDetails();
    console.log('salesDetails: ', salesDetails);

    $scope.data = salesDetails ? salesDetails : {};
    $scope.vm = {};
    $scope.location = {
      latitude: 0,
      longitude: 0
    };

    //assuming the ids are string
    $scope.warehouseTypeOptions = [
      { id: "1", name: 'MasterFranchise' },
      { id: "2", name: 'ScrapQ' },
    ];

    $scope.agentTypeArr = ["Residence", "Corporate"];
    $scope.serviceTypeArr = ["PICKUP", "DROPOFF", "RRR"];
    $scope.trackingTypeArr = ["ONETIME", "LIVE"];

    if (salesDetails) {
      $scope.referrer = "Aquisitions"
      $scope.vm.customadd = salesDetails.address.landmark;
      $scope.vm.formattedAddress = salesDetails.address.address;
      var center = [];
      center.push(salesDetails.address.latitude);
      center.push(salesDetails.address.longitude);
      $scope.center = center.join();
      $scope.category = salesDetails.categories.key;
      $scope.location = {
        latitude: salesDetails.address.latitude,
        longitude: salesDetails.address.longitude
      };
      $scope.chosenPlace = salesDetails.address.address;
      $scope.data.comments = salesDetails.comments ? salesDetails.comments : [];
      $scope.data.comments.forEach(comment => {
        comment.actualTime = comment.time;
        comment.time = $moment(comment.time).format("DD-MM-YY");
      });
    }

    $scope.category = null;
    if (items.userData) {
      console.log("Is it coming from items??", items);
      $scope.setHeader = items.setHeader;
      $scope.data.name = items.userData.name;
      $scope.data.mobileNo = items.userData.mobileNo;
      $scope.data.passCode = items.userData.password;
      $scope.data.emailId = items.userData.email;
      $scope.vm.customadd = items.userData.address.landmark;
      $scope.vm.formattedAddress = items.userData.address.address;
      var center = [];
      center.push(items.userData.address.latitude);
      center.push(items.userData.address.longitude);
      $scope.center = center.join();
      $scope.category = items.userData.categories.key;
      $scope.location = {
        latitude: items.userData.address.latitude,
        longitude: items.userData.address.longitude
      };
      $scope.chosenPlace = items.userData.address.address;
    } else {
      $scope.setHeader = items;
    }
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    //console.log($rootScope.loginData)

    var citymatcher = "";
    if ($rootScope.allCityData && $rootScope.allCityData.length > 0) {
      $rootScope.allCityData.forEach(function (elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
    }


    $scope.checkradio = function () {
      console.log("Radio clicked");

      // console.log(newcategories)
      setTimeout(function () {
        // var tempcategories = JSON.parse(JSON.stringify($scope.categories));
        var category = $scope.categories[$scope.categories.length - 1];
        $scope.categories = [];
        $scope.categories.push(category);
        // $scope.categories.push(tempcategories[tempcategories.length-1]);
        //console.log($scope.categories);
      }, 300);
    };

    if ($scope.setHeader === "CONSUMER") {
      $scope.categories = [];
      consumer.userCategories().then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.categoriesList = res.data.categories;
        } else if (res.status === "FAILURE") {
          growl.error(res.error.message)
        }

      })
    }

    if ($scope.setHeader === "AGENT") {
      $scope.warehouseTypeOptions.unshift({ id: "0", name: 'Wholesalers' });
      wholesalerService.usersLoad("WHOLESALER", -1, true).then(function (res) {
        // console.log("Query sent");
        // console.log(res);
        if (res.status === "SUCCESS") { //&& res.data.usersuserType == "AGENT"
          // $scope.agents = res.data;
          if (res.data.users.length > 0) {
            // $scope.length0 = true;
            // $scope.agentDetails(0);
            $scope.wholesalers = {
              wholesalerid: null,
              availableOptions: res.data.users
            };
          } else {
            $scope.wholesaler_list = res.data.users;
            // $scope.length0 = false;
            // $scope.agentId = false;
          }
          if (res.data.message === "No records found") {
            $scope.noWholesalerFound = true;
          }
        } else if (res.status === "FAILURE") {
          $scope.noAgentFound = true;
        } else {
          $scope.noAgentFound = true;
        }
      }, function (err) {

      });

      warehouseService.getWarehouse(-1).then(function (res) {
        console.log("WAREHOUSES:::::", res);
        if (res.status === "SUCCESS") {
          $scope.warehouse_list = res.data.warehouses;
          if (res.data.message === "No records found") {
            $scope.noWholesalerFound = true;
          }
        } else if (res.status === "FAILURE") {
          $scope.noAgentFound = true;
        } else {
          $scope.noAgentFound = true;
        }
      }, function (err) {

      });
      area.getArea().then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data && res.data.area) {
            $scope.area_list = res.data.area;
          }
        }
      });
    }

    /*   profile.updateProfile($scope.data).then(function(res) {
              if (res.status == "SUCCESS") {
                  growl.success(res.data.message);
              } else if (res.status == "failure") {
                  growl.error(res.error.message)
              }
          });
      }*/


    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng).then(function (res) {
        console.log("$global.getLocationByLatLng", res);
        $scope.place = res;
        $scope.vm.formattedAddress = res.formatted_address;
        $scope.chosenPlace = res.formatted_address;
      })
    }

    function reRenderMap() {
      $timeout(function () {
        angular.forEach($scope.maps, function (map) {
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }

    $scope.setCurrentLocation = function () {
      console.log("is setcurrentlocation being called");
      $global.getCurrentLocation().then(function (res) {
        $scope.center = $scope.getLatLng(res);
        var rem = {};
        rem.lat = res.latitude;
        rem.lng = res.longitude;
        //setPlaceObject(rem);
        reRenderMap();
      });
    };

    $scope.getLatLng = function (obj) {

      if (obj && obj.latitude && obj.longitude) {
        var latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      }

    };
    //$scope.setCurrentLocation();

    $scope.placeChanged = function () {
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        var obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";

      }

      // console.log(obj.lat, obj.lng)

    };

    // Fetch Documents List
    (function () {
      console.log($rootScope.loginData)
      let req = {
        city: $rootScope.loginData.city,
        userType: $scope.setHeader
      };

      documents.retrieveDocumentsList(req).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.documentTypesList = res.data.documents;
          console.log($scope.documentTypesList);
        }
      })

    })();



    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    $scope.documentData = [];
    $scope.id = 1;
    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    // $scope.documentTypesList = ["BACKGROUND CHECK DOCUMENT", "AADHAR", "PAN CARD", "REGISTRATION", "DRIVING LICENSE", "PASSPORT", "RATION CARD", "VOTER ID", "ST REGISTRATION", "OTHER", "GST"];

    $scope.uploadImage = function ($event, docRowIndex) {

      var file = $event.srcElement.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = function () {
        $scope.documentData[docRowIndex].documentFormat = file.name.split(".")[1];
        $scope.documentData[docRowIndex].document = "data:" + file.type + ":base64," + btoa(reader.result);
      };
      reader.onerror = function () {
        growl.error("unable to add file please select again");
        $scope.documentData[docRowIndex] = {};
      };

    }

    $scope.uploadUserDocs = function (userId) {
      console.log($scope.documentData);
      $scope.documentData.forEach(function (obj) {
        if (obj.document) {
          obj.userId = userId;
          documents.uploadUserDocuments(obj).then(function (res) {
            $scope.documentData = [];
          })
        }
      });
    }


    $scope.addRow = function () {
      let obj = {};
      $scope.documentData.push(obj);
    };


    $scope.anyEditingDocuments = function () {
      for (let i = 0; i < $scope.documentData.length; i++) {
        // Check for pending rows
        if ($scope.documentData[i].id) {
          return true;
        }
      }
      return false;
    }

    $scope.saveDocuments = function () {
      $scope.documentData.forEach(document => {
        // document.id will only be true for newly adding documents
        if (document.id) {
          if (!document.documentFile) {
            growl.error("Please select Documents to upload")
          } else {
            // Upload document if the file is present
            let file = document.documentFile;
            // Allow only images and pdfs
            if (!file.type.includes("image") && !file.type.includes("pdf")) {
              growl.error("Only image and pdf files are accepted!")
              return;
            }

            Upload.upload({ url: $scope.url, file: file }).then(function (response) {
              if (response.data.status === "SUCCESS") {
                growl.success("Successfully saved documents")
                document.documentId = response.data.data.fileId;
                document.id = 0;
              }
            });
          }
        }
      });
    }

    $scope.downloadDocument = function (docid) {
      // alert('To do');
      vendorService.downloadDocument(docid).then(function (res) {
        if (res.status === "SUCCESS") {
          console.log("Downloading file..", docid);
        } else if (res.status === "FAILURE") {
          growl.error("Unable to download documents!");
        } else {
          growl.error("Unable to download documents!");
        }
      }, function (err) {
        console.error(err);
      });
    };

    $scope.removeRow = function (index) {
      $scope.documentData.splice(index, 1);
    };








    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX


    $scope.setLocation = function (obj) {

      var center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
    };

    var map;
    $scope.maps = [];
    $scope.$on('mapInitialized', function (evt, evtMap) {
      $scope.maps.push(evtMap);

    });


    NgMap.getMap().then(function (evtMap) {
      map = evtMap;
    });
    $scope.setLocations = function () {
      if ($scope.locationadd) {

      }
    };
    $scope.setCurrentLocation();
    $scope.markerDragEnd = function (event) {

      console.log("Is $scope.markerDragEnd getting called when dragged???", event);

      $timeout(function () {
        var latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);

      })
      // $scope.chosenPlace = $scope.vm.formattedAddress;
    };
    $scope.cancel = function () {
      // $uibModalInstance.close('Close');
      $window.history.back();
    };

    $scope.addUsers = function (address, latitude, longitude) {

      console.log("What is userType", $scope.data, $scope.setHeader);

      $scope.categories = [];
      $scope.categories.push($scope.category);

      $scope.catalog = "Please select any one category";
      $scope.data.registerType = "CRM";
      $scope.data.userType = $scope.setHeader; //"SQAGENT"
      $scope.data.city = $rootScope.loginData.city;
      $scope.data.formattedAddress = $scope.vm.formattedAddress;

      if ($scope.data.userType === 'AGENT' || $scope.data.userType === 'WHOLESALER') {
        let options = [];
        if ($scope.data.ownerType === "0") {
          options = $scope.wholesalerSelectedOptions;
        } else if ($scope.data.ownerType === "1") {
          options = $scope.masterFranchiseOptions
        } else if ($scope.data.ownerType === "2") {
          options = $scope.scrapQOptions;
        }

        let obj = {};
        for (let i = 0; i < options.length; i++) {
          if (options[i].registrationId === $scope.data.ownerValue) {
            obj.ownerId = options[i].registrationId;
            obj.type = (options[i].userType === 'WHOLESALER') ? "PARTNER" : options[i].userType;
            obj.name = options[i].name;
            obj.mobile = options[i].mobile;
            $scope.data.wholesalerid = (obj.type === 'PARTNER') ? options[i].userId : null;
            break;
          }
        }
        $scope.data.owner = obj;
      }

      $global.setLocalItem("registration", $scope.authorization, true);
      $scope.submitted = true;
      $scope.agentType = $scope.data.agentType;
      if (!$scope.addUserForm.$valid) {
        growl.error("Please enter valid inputs");
        return;
      }

      var geocoder = new google.maps.Geocoder();
      //First Geocode
      geocoder.geocode({ 'address': address }, function (results, status) {
        //console.log(google.maps.GeocoderStatus.OK);
        if (status === google.maps.GeocoderStatus.OK) {
          //Register main user
          if ($scope.data.userType === "WHOLESALER") {
            $scope.data.mobileNo = "0" + $scope.data.mobileNo;
          }

          authentication.register($scope.data).then(function (res) {
            var authres = JSON.parse(JSON.stringify(res));
            console.log("User update status: ", res);
            if (res.status === "SUCCESS") {
              // Add address of wholesaler
              $scope.userid = res.data.userId;
              $scope.registrationId = res.data.registrationId;
              $scope.name = res.data.name;
              $scope.mobile = res.data.mobileNo;
              $scope.uploadUserDocs($scope.userid);

              var addresses = $global.getAddressObj(results[0]);

              //override geocoded lat, long
              if (latitude) {
                addresses.latitude = latitude;
                addresses.longitude = longitude
              }

              addresses.userId = $scope.userid;
              addresses.userType = $scope.data.userType;
              addresses.landmark = $scope.data.landmark;
              addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
              addresses.categories = [];
              addresses.agentType = $scope.agentType;
              angular.forEach($scope.categories, function (value, key) {
                angular.forEach($scope.categoriesList, function (catvalue, key) {
                  if (value === catvalue.consumerCategoryId)
                    addresses.categories.push({
                      key: catvalue.consumerCategoryId,
                      value: catvalue.name
                    })
                });
              });
              //addresses.formattedAddress = address.formatted_address;
              authentication.saveCosumerAddress(addresses).then(function (res) {
                // Add agent of wholesaler
                console.log("what is the respone of saveconsumerAddress", res);
                if ($scope.data.userType === "WHOLESALER") {
                  $scope.tempdata = $scope.data;
                  $scope.tempdata.userType = "AGENT";
                  $scope.tempdata.wholesalerid = res.data.address.userId;
                  $scope.tempdata.mobileNo = $scope.tempdata.mobileNo.substr($scope.tempdata.mobileNo.length - 10);
                  $scope.tempdata.name = $scope.tempdata.name + " WS";
                  $scope.tempdata.owner = {};
                  $scope.tempdata.owner.type = "PARTNER";
                  $scope.tempdata.owner.ownerId = $scope.registrationId;
                  $scope.tempdata.owner.name = $scope.name;
                  $scope.tempdata.owner.mobile = $scope.mobile;
                  authentication.register($scope.tempdata).then(function (resagent) {
                    // console.log(resagent)
                    if (resagent.status === "SUCCESS") {
                      $scope.tempdata.userid = resagent.data.userId;
                      // console.log(results[0]);
                      $scope.dataws = $scope.data;
                      $scope.dataws.agentid = resagent.data.userId;
                      $scope.dataws.wholesalerId = res.data.userId;
                      $scope.dataws.dataType = "wholesaler";
                      delete $scope.dataws.mobileNo;
                      delete $scope.dataws.passCode;
                      delete $scope.dataws.userType;

                      consumer.updateProfile($scope.dataws).then(function (res2) {
                        if (res2.status === "SUCCESS") {
                          // growl.success(res.data.message);
                        } else if (res2.status === "failure") {
                          // growl.error(res.error.message)
                        }
                      });
                      // $scope.addLocation(results[0], latitude, longitude);

                      // Add address of agent
                      var addresses_agent = $global.getAddressObj(results[0]);

                      //override geocoded lat, long
                      if (latitude) {
                        addresses_agent.latitude = latitude;
                        addresses_agent.longitude = longitude;
                      }

                      addresses_agent.userId = resagent.data.userId;
                      addresses_agent.userType = "AGENT";
                      addresses_agent.landmark = $scope.tempdata.landmark;
                      addresses_agent.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
                      //addresses.formattedAddress = address.formatted_address;
                      authentication.saveCosumerAddress(addresses_agent).then(function (res) {

                        // $scope.data.addressId = res.data.address.addressId;
                        // growl.success(res.data.message)
                        // $uibModalInstance.dismiss('ok');
                      })

                      if (salesDetails) {
                        let obj = {
                          id: salesDetails._id,
                          isApproved: true
                        };
                        consumer.updateConsumer(obj).then(function (res) {
                          if (res.status === "Success") {
                            console.log("User approved successfully");
                          } else {
                            growl.error("Consumer added successfully but unable to approve");
                          }
                        })
                          .catch((err) => {
                            console.log('err in Approving user: ', err);
                            growl.error("Consumer added successfully but unable to approve");
                          });
                      }

                    } else {
                      var errors = res.error.errors;
                      for (var i = errors.length - 1; i >= 0; i--) {
                        $scope[errors[i].code] = errors[i].message;
                        growl.error(errors[i].message)
                      }
                      if (res.error.errors.length === 0) {
                        growl.error(res.error.message);
                      }
                    }
                  });
                }
                //$scope.addLocation(results[0], latitude, longitude);
                if ($scope.data.userType === "CONSUMER") {
                  $scope.data.categories = [];
                  growl.success(res.data.message);
                  angular.forEach($scope.categories, function (value, key) {
                    angular.forEach($scope.categoriesList, function (catvalue, key) {
                      if (value === catvalue.consumerCategoryId)
                        $scope.data.categories.push({
                          key: catvalue.consumerCategoryId,
                          value: catvalue.name
                        })
                    });
                  });
                  //$ionicLoading.hide();
                  delete $scope.data.registerType;
                  delete $scope.data.userType;
                  delete $scope.data.landmark;
                  // console.log(authres)
                  $scope.data.consumerId = authres.data.userId;
                  $scope.data.preferredPaymentMode = "";
                  $scope.data.profileImage = "";
                  $scope.data.walletBalance = "";
                  $scope.data.dataType = "consumer";
                  consumer.updateProfile($scope.data).then(function (res) {
                    if (res.status === "SUCCESS") {
                      // growl.success(res.data.message);
                    } else if (res.status === "failure") {
                      growl.error(res.error.message)
                    }
                  });
                }
                if (items && items.referrer === "Aquisitions") {
                  // $uibModalInstance.dismiss(items.userData);
                  growl.success("User Added Successfully...!");
                  $state.go($scope.getPreviousState());
                } else {
                  // $uibModalInstance.dismiss('Ok');
                  growl.success("User Added Successfully...!");
                  $state.go($scope.getPreviousState());
                }
              })


            } else {
              var errors = res.error.errors;
              for (var i = errors.length - 1; i >= 0; i--) {
                $scope[errors[i].code] = errors[i].message;
                growl.error(errors[i].message)
              }
              if (res.error.errors.length === 0) {
                growl.error(res.error.message);
              }
            }
          });
        } else {
          growl.error("Failed to find lat,long for the address, please enter a better address");
        }
      });
    }

    $scope.change = function () {
      $scope.disablePrimaryCheck = false;
      $scope.data.ownerValue = null;
      console.clear();
      let selectedWarehouseTypeId = $scope.data.ownerType;

      if (selectedWarehouseTypeId === "0") { //get all wholesalers, create a new field called ownerType value, populate it with dropdown of values
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 0', $scope.warehouseTypeOptions[$scope.data.ownerType]);
        wholesalerService.usersLoadAll('WHOLESALER')
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = true;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.wholesalerSelectedOptions = res.data.users;
            } else {
              console.error('Failed to get a list of all wholesalers ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "1") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 1', $scope.warehouseTypeOptions[$scope.data.ownerType]);
        warehouseService.retrieveMasterFranchise()
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.masterFranchiseOptions = res.data.users;
            } else {
              console.error('Failed to get mf user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });
      } else if (selectedWarehouseTypeId === "2") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 2', $scope.warehouseTypeOptions[$scope.data.ownerType]);
        // $scope.scrapQOptions = [
        //   {id: "0", name: 'ScrapQ'}
        // ];
        warehouseService.retrieveSuperAdmin()
          .then(function (res) {
            $global.displayLog('inside change function > wholesalerService.usersLoadAll', 'res', res);
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = false;
              $scope.isScrapQOptionSelected = true;
              $scope.scrapQOptions = res.data.users;
            } else {
              console.error('Failed to get a list of all admins ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error('Failed to get a list of all wholesalers ', err);
          })
      } else if (selectedWarehouseTypeId === "3") {
        $global.displayLog('inside change function', 'selectedWarehouseTypeId === 3', $scope.warehouseTypeOptions[$scope.data.ownerType]);

        agentService.usersLoadAll('AGENT')
          .then(function (res) {
            if (res.status === 'SUCCESS' && res.data && res.data.users && res.data.users.length > 0) {
              $scope.isWholesalerOptionSelected = false;
              $scope.isMasterFranchiseOptionSelected = false;
              $scope.isAgentOptionSelected = true;
              $scope.isScrapQOptionSelected = false;
              $scope.agentOptions = res.data.users;
            } else {
              console.error('Failed to get agent user ', res.data.users);
            }
          })
          .catch(function (err) {
            console.error("err = ", err);
          });

      } else {
        console.error('Invalid warehouse selected ', selectedWarehouseTypeId, $scope.data.ownerType);
        $scope.isWholesalerOptionSelected = false;
        $scope.isMasterFranchiseOptionSelected = false;
        $scope.isScrapQOptionSelected = false;
        $scope.isAgentOptionSelected = false;
      }
    };

    $scope.getPreviousState = function () {
      if ($stateParams.type == "AGENT") {
        return "root.agents"
      }
      if ($stateParams.type == "WHOLESALER") {
        return "root.wholesalers"
      }
    }





    $scope.addLocation = function (address, latitude, longitude) {

      var addresses = $global.getAddressObj(address);

      //override geocoded lat, long
      if (latitude) {
        addresses.latitude = latitude;
        addresses.longitude = longitude
      }

      addresses.userId = $scope.userid;
      addresses.userType = $scope.data.userType;
      addresses.landmark = $scope.data.landmark;
      addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
      //addresses.formattedAddress = address.formatted_address;
      authentication.saveCosumerAddress(addresses).then(function (res) {

        // $scope.data.addressId = res.data.address.addressId;
        // growl.success(res.data.message)
        // $uibModalInstance.dismiss('ok');
      })

    };

    $scope.searchForLocation = function () {
      let data = $scope.vm.formattedAddress;
      console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress);
      authentication.getLocations(data).then(function (res) {
        console.log("response", res);
        $scope.predictions = res.predictions;
        $scope.showPredictions = true;
      }).catch(function (err) {
        console.log("err", err);
      })
    };

    $scope.locationSelected = function (obj) {
      console.log("clicked item", obj);
      $scope.vm.formattedAddress = obj.description;
      $scope.showPredictions = false;
      authentication.getLatLng(obj.place_id).then(function (res) {
        console.log("response", res);
        if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
          let place = res.result;
          let obj = {};
          obj.lat = place.geometry.location.lat;
          obj.lng = place.geometry.location.lng;
          $scope.setLocation(obj);
        } else {
          growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
          $scope.vm.formattedAddress = "";
          $scope.chosenPlace = "";
        }
      }).catch(function (err) {
        console.log("err", err);
      });
    }
  });
