'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AgentsAttendanceController
 * @description
 * # AgentsAttendanceController
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AgentsAttendanceController', function ($scope, $http, $state, $filter, authentication, $moment, $global, $stateParams, $rootScope, agentAppointments, dashboard, $uibModal, $log, consumer, prospect, growl) {

    $scope.inrFilter = $filter('INR');

    $scope.date = {
      startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
      endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
    };

    $scope.vm = {
      params: { "city": $rootScope.loginData.city }
    };

    $scope.agentId = agentAppointments.getAgentId();
    if (!$scope.agentId) {
      $state.go("root.reports");
    }
    $scope.agentName = agentAppointments.getAgentName();

    $rootScope.loginData = $global.getLocalItem("loginData", true);

      (function () {
        var data = {
          "city": $rootScope.loginData.city,
          "userType": $rootScope.loginData.role,
          "module": "Reports",
          "subModule": "AgentsAttendance",
          "type": "Purchase"
        };
        authentication.getUserRoleMetrics(data).then(function (res) {
          if (res.status == "SUCCESS") {
            $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
            $scope.gridOptions.columnDefs.forEach(function (col, index) {
              if ($scope.disabledFeilds.includes(col.field)) {
                $scope.gridOptions.columnDefs.splice(index, 1);
              }
            });
            $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs);
          } else {
            $scope.disabledFeilds = [];
          }
        }).catch(function (err) {
          $scope.disabledFeilds = [];
        })
      })();

    (function () {
      let appointcolumnDefs = [
        {
          headerName: "Date",
          field: "date",
          cellRenderer: DateCellRenderer,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Time of first appointment",
          field: "firstAppointmentClosedAt",
          // cellRenderer: DateCellRenderer,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Time of last appointment closed",
          field: "lastAppointmentClosedAt",
          // cellRenderer: DateCellRenderer,
          cellStyle: { 'text-align': 'center', 'margin-left': '10px' }
        },
        {
          headerName: "Total Hours",
          field: "operatedHours",
          cellRenderer: AmountCellRenderer,
          cellStyle: { 'text-align': 'right', 'margin-left': '10px' }
        },
        {
          headerName: "Appointments",
          field: "totalAppointmentsCount",
          cellStyle: { 'text-align': 'right', 'margin-left': '10px' }
        },
        {
          headerName: "GTV",
          field: "gtv",
          cellRenderer: AmountCellRenderer,
          cellStyle: { 'text-align': 'right', 'margin-left': '10px' }
        }
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: appointcolumnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: false,
        suppressExcelExport: false,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        // paginationAutoPageSize: true,
        rowData: rowData,
        // suppressPaginationPanel:true,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          $scope.gridOptions.api.setRowData($scope.vendorPrice_list);
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };

      function AmountCellRenderer() {
      }

      AmountCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        if (params.value !== "" || params.value !== undefined || params.value !== null) {
          // let val= params.value?(params.value).toFixed(2):'-';
          let val = params.value ? $scope.inrFilter(params.value) : '0.00';
          let iconsDiv = "<div style=\"padding-right: 30px;\"> " + val + "</div>";
          this.eGui.innerHTML = iconsDiv;
        } //inr symbol - &#x0b9;
      };

      AmountCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };

      function DateCellRenderer() {
      }

      DateCellRenderer.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        if (params.data != "" || params.data != undefined || params.data != null) {
          var iconsDiv = "<div>" + params.data.date + "</div>";
          this.eGui.innerHTML = iconsDiv;
        }
      };

      DateCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };
    })();

    (function () {

      $scope.loadData = function () {

        let data = {
          "fromDate": $scope.vm.params.fromdate,
          "toDate": $scope.vm.params.todate,
          "agentId": $scope.agentId,
          "city": $rootScope.loginData.city
        };
        agentAppointments.getAgentsAttendance(data).then(function (res) {
          $scope.labelCounts = {
            totalGtv: res.data && $scope.inrFilter(res.data.totalGTV) ? res.data.totalGTV : "0.00",
            totalAppointments: res.data && res.data.totalAppointmentsCount ? res.data.totalAppointmentsCount : 0,
            averageAppointmentTime: res.data && res.data.averageAppointmentTime ? res.data.averageAppointmentTime : 0,
            presentDays: res.data && res.data.presentDays ? res.data.presentDays : 0,
            absentDays: res.data && res.data.absentDays ? Math.abs(res.data.absentDays) : 0
          }
          if (res.status.toUpperCase() == "SUCCESS") {
            $scope.reports = res.data.reports;
            $scope.gridOptions.api.setRowData($scope.reports);
          } else {
            if (res.status.toUpperCase() == "FAILURE" && res.error && res.error.code == "EC_MAX_DATE_RANGE_EXCEEDED") {
              growl.error(res.error.message);
            } else {
              $scope.reports = [];
              $scope.gridOptions.api.setRowData($scope.reports);
            }
          }
        })
      }

    })();


    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };

    $scope.setStartDate = function () {
      $scope.date.startDate = moment().subtract(4, "days").toDate();
    };

    $scope.setRange = function () {
      $scope.date = {
        startDate: moment().subtract(5, "days"),
        endDate: moment()
      };
    };

    //Watch for date changes
    $scope.$watch('date', function (newDate) {
      $scope.selectedRange = $('#daterange3').data('daterangepicker').chosenLabel;

      $scope.transactionChange();
    }, false);

    $scope.transactionChange = function () {

      $scope.vm.params.singledayquery = false;
      $scope.totalAgentRecords = 0;
      // $scope.currentPage = 1;
      $scope.vm.params.page = -1;

      if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
        $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
        $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

        if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
          $scope.vm.params.singledayquery = true;
        }
        $scope.loadData();
      } else {
        console.error("Invalid date, please select valid date range");
        console.error("startDate = ", $scope.date.startDate);
        console.error("endDate = ", $scope.date.endDate);
        growl.error("Invalid date, please select valid date range");
      }
    };
    $scope.nextDate = function () {
      $scope.paging = false;
      $scope.date = $global.getNextDateRanges($scope.selectedRange, $scope.date.endDate);
      // $scope.date = {
      //     startDate: moment($scope.date.endDate).add(1, "days"),
      //     endDate: moment($scope.date.endDate).add(1, "days")
      // };
      $scope.transactionChange();
    }
    $scope.previousDate = function () {
      $scope.paging = false;
      $scope.date = $global.getPrevDateRanges($scope.selectedRange, $scope.date.startDate);
      // $scope.date = {
      //     startDate: moment($scope.date.startDate).subtract(1, "days"),
      //     endDate: moment($scope.date.startDate).subtract(1, "days")
      // };
      $scope.transactionChange();
    }
    $scope.goBack = function () {
      $state.go('root.agent-wise-reports', { fromdate: $stateParams.fromdate, todate: $stateParams.todate, disabledFeilds: null });
    }
  });