'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddApartmentCtrl
 * @description
 * # AddApartmentCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AddApartmentCtrl', function ($scope, consumer, authentication, apartments, $moment, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope) {
        $scope.data = {
            numberOfBlocks: 0
        }
        $scope.vm = {};
        $scope.location = {
            latitude: 0,
            longitude: 0
        }
        $scope.data.blocks = [];
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        //console.log($rootScope.loginData)

        consumer.userCategories().then(function (res) {
            if (res.status == "SUCCESS") {
                $scope.categoriesList = res.data.categories;
            } else if (res.status == "FAILURE") {
                growl.error(res.error.message)
            }

        })

        var citymatcher = "";
        $rootScope.allCityData.forEach(function (elem, idx) {
            if (elem.name == $rootScope.loginData.city) {
                if (citymatcher) {
                    citymatcher += "|"
                }
                citymatcher += elem.subarea;;
            }
        })

        function setPlaceObject(latlng) {
            $global.getLocationByLatLng(latlng).then(function (res) {
                $scope.place = res;
                $scope.vm.formattedAddress = res.formatted_address;

                $scope.chosenPlace = res.formatted_address;


            })

        }

        function reRenderMap() {
            $timeout(function () {
                angular.forEach($scope.maps, function (map) {
                    var currCenter = map.getCenter();
                    google.maps.event.trigger(map, 'resize');
                    map.setCenter(currCenter);
                });
            }, 500);
        }
        $scope.blocksChanged = function () {
            if ($scope.data.numberOfBlocks > $scope.data.blocks.length) {
                for (let i = $scope.data.blocks.length; i < $scope.data.numberOfBlocks; i++) {
                    $scope.data.blocks.push({});
                }
            } else {
                $scope.data.blocks.length = $scope.data.numberOfBlocks;
            }
        }
        $scope.setCurrentLocation = function () {

            $global.getCurrentLocation().then(function (res) {
                $scope.center = $scope.getLatLng(res);
                var rem = {}
                rem.lat = res.latitude;
                rem.lng = res.longitude;
                //setPlaceObject(rem);
                reRenderMap();
            });

        }

        $scope.getLatLng = function (obj) {
            if (obj && obj.latitude && obj.longitude) {
                var latLng = [];
                latLng.push(obj.latitude);
                latLng.push(obj.longitude);
                return latLng.join();
            }
        }
        //$scope.setCurrentLocation();

        $scope.placeChanged = function () {
            if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
                $scope.place = this.getPlace();
                var obj = {};
                obj.lat = $scope.place.geometry.location.lat();
                obj.lng = $scope.place.geometry.location.lng();
                $scope.setLocation(obj);
            } else {
                growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
                $scope.vm.formattedAddress = "";
                $scope.chosenPlace = "";

            }
        }

        $scope.setLocation = function (obj) {
            var center = [];
            center.push(obj.lat);
            center.push(obj.lng);
            $scope.center = center.join();
            $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
        }

        var map;
        $scope.maps = [];
        $scope.$on('mapInitialized', function (evt, evtMap) {
            $scope.maps.push(evtMap);

        });

        NgMap.getMap().then(function (evtMap) {
            map = evtMap;
        });

        $scope.setCurrentLocation();
        $scope.markerDragEnd = function (event) {

            $timeout(function () {
                var latlng = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                };
                setPlaceObject(latlng);
                $scope.center = $scope.getLatLng(latlng);

            });
        }
        $scope.cancel = function () {
            $uibModalInstance.close('Close');
        };

        $scope.addUsers = function (address, latitude, longitude) {
            console.log($scope.data)
            console.log($scope.addApartmentForm.$valid)

            return
            $scope.data.registerType = "CRM";
            $scope.data.city = $rootScope.loginData.city;

            $global.setLocalItem("registration", $scope.authorization, true);
            $scope.submitted = true;
            //console.log(address);
            //console.log($scope.addApartmentForm.$valid);
            if ($scope.addApartmentForm.$valid) {
                var geocoder = new google.maps.Geocoder();
                //First Geocode
                geocoder.geocode({ 'address': address }, function (results, status) {
                    //console.log(google.maps.GeocoderStatus.OK);
                    if (status == google.maps.GeocoderStatus.OK) {
                        //Register main user
                        $scope.data.numberOfBlocks = $scope.data.blocks.length;
                        apartments.addApartment($scope.data).then(function (res) {
                            var authres = JSON.parse(JSON.stringify(res));
                            console.log("Apartment: ", res);
                            if (res.status == "SUCCESS") {
                                $scope.apartmentId = res.data.apartment.apartmentId;
                                var addresses = $global.getAddressObj(results[0]);
                                //override geocoded lat, long
                                if (latitude) {
                                    addresses.latitude = latitude
                                    addresses.longitude = longitude
                                }

                                addresses.apartmentId = $scope.apartmentId;
                                addresses.userType = "APARTMENT";
                                addresses.landmark = $scope.data.landmark;
                                addresses.formattedAddress = ($scope.vm.customadd != '' && $scope.vm.customadd != undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
                                addresses.categories = [];
                                $scope.categories = ["56f004299950b5754387ff6f"];
                                angular.forEach($scope.categories, function (value, key) {
                                    angular.forEach($scope.categoriesList, function (catvalue, key) {
                                        if (value == catvalue.consumerCategoryId)
                                            addresses.categories.push({
                                                key: catvalue.consumerCategoryId,
                                                value: catvalue.name
                                            })
                                    });
                                });
                                authentication.saveCosumerAddress(addresses).then(function (res) {
                                    // Add agent of wholesaler
                                    //$scope.addLocation(results[0], latitude, longitude);
                                    if (res.status === "SUCCESS") {
                                        growl.success("Apartment added Successfully");
                                        $uibModalInstance.dismiss('Ok');
                                    } else {
                                        if (res.error && res.error.code && res.error.code === "EC_UNAUTHORIZED_ADMIN_ADD_ADDRESS_TRANSACTION") {
                                            growl.error(res.error.message);
                                        } else {
                                            growl.error("Failed to save address");
                                        }
                                    }
                                });
                            } else {
                                var errors = res.error.errors;
                                for (var i = errors.length - 1; i >= 0; i--) {
                                    $scope[errors[i].code] = errors[i].message;
                                    growl.error(errors[i].message)
                                };
                                if (res.error.errors.length == 0) {
                                    growl.error(res.error.message);
                                }
                            }
                        });
                    } else {
                        growl.error("Failed to find lat,long for the address, please enter a better address");
                    }

                });
            }
            // console.log($scope.mytime)
            //$scope.mytime=$moment().format('H:mm:ss');
            //console.log($scope.mytime)
        };

        $scope.addLocation = function (address, latitude, longitude) {

            var addresses = $global.getAddressObj(address);

            //override geocoded lat, long
            if (latitude) {
                addresses.latitude = latitude
                addresses.longitude = longitude
            }

            addresses.userId = $scope.userid;
            addresses.userType = $scope.data.userType;
            addresses.landmark = $scope.data.landmark;
            addresses.formattedAddress = ($scope.vm.customadd != '' && $scope.vm.customadd != undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
            //addresses.formattedAddress = address.formatted_address;
            authentication.saveCosumerAddress(addresses).then(function (res) {

                // $scope.data.addressId = res.data.address.addressId;
                // growl.success(res.data.message)
                // $uibModalInstance.dismiss('ok');
            })

        };


        $scope.searchForLocation = function () {
            let data = $scope.vm.formattedAddress;
            console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress)
            authentication.getLocations(data).then(function (res) {
                console.log("response", res);
                $scope.predictions = res.predictions;
                $scope.showPredictions = true;
            }).catch(function (err) {
                console.log("err", err);
            })
        };

        $scope.locationSelected = function (obj) {
            console.log("clicked item", obj);
            $scope.vm.formattedAddress = obj.description;
            $scope.showPredictions = false;
            authentication.getLatLng(obj.place_id).then(function (res) {
                console.log("response", res);
                if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
                    let place = res.result;
                    let obj = {};
                    obj.lat = place.geometry.location.lat;
                    obj.lng = place.geometry.location.lng;
                    $scope.setLocation(obj);
                } else {
                    growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
                    $scope.vm.formattedAddress = "";
                    $scope.chosenPlace = "";
                }
            }).catch(function (err) {
                console.log("err", err);
            });
        }
    });
