'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AboutCtrl
 * @description
 * # AboutCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('DeleteBlockCtrl', function($scope, authentication, $uibModalInstance, items, apartments, growl) {
        $scope.items = items;
        console.log($scope.items)
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.ok = function(res) {
            $uibModalInstance.close(res);
        };
        $scope.delete = function() {
            var obj = {
                'blockId': $scope.items.blockId,
            }
            console.log(obj)
            apartments.deleteBlock(obj).then(function(res) {
                if (res.status = "SUCCESS") {
                    // $scope.todayAppointment.splice(index, 1);
                    growl.success("Block Deleted Successfully");
                    $scope.ok(res);
                } else if (res.status == "FAILURE") {
                    growl.error(res.error.errors[0].message);
                }

            })


        };
    });
