'use strict';

/**
 * Warehouse controller
 */
angular.module('scrapQcrmApp')
  .controller('WarehousesCtrl', function ($scope, $rootScope, $global, $uibModal, $window, $state, growl, $log, warehouseService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $moment, $timeout) {

    /*===============================================init values===================================================*/
    $scope.labelCounts = {
      totalWarehouses: 0,
      activeWarehouses: 0,
      inActiveWarehouses: 0
    };
    $scope.resetLabelCounts = function () {
      $scope.labelCounts = {
        totalWarehouses: 0,
        activeWarehouses: 0,
        inActiveWarehouses: 0
      };
    };
    (function () {
      $scope.pages = -1;
      $scope.vm = { params: { "city": $rootScope.loginData.city } };
      $scope.currentPage = 1;

      $scope.dateInput = $moment().format("DD-MMM-YYYY");
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput
      };

      $rootScope.loginData = {};
      $rootScope.loginData = $global.getLocalItem("loginData", true);

      $scope.opts = {
        format: "DD-MMM-YYYY",
        singleDatePicker: true
      };

      $scope.customIcon = {
        "scaledSize": [32, 32],
        "url": "http://www.cliparthut.com/clip-arts/823/arrowhead-clip-art-823528.png"
      };

      $scope.currentPage = 1;

      $scope.date = {
        startDate: $moment().subtract(730, 'd').format("DD-MMM-YYYY"),
        endDate: $moment().format("DD-MMM-YYYY")
      };

      var columnDefs = [
        {
          headerName: "Warehouse Name",
          field: "warehouseName",
        },
        {
          headerName: "Mobile Number",
          cellRenderer: HideMobileNumber,
        },
        {
          headerName: "Warehouse Type",
          field: "warehouseType",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Owner",
          field: "warehouseTypeValue",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "City",
          field: "city",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Inception Date",
          field: "createdOn",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Area",
          field: "area",
          hide: true
        },
        {
          headerName: "Latitude",
          field: "address.latitude",
          hide: true
        },
        {
          headerName: "Longitude",
          field: "address.longitude",
          hide: true
        }
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        paginationPageSize: 1000,
        // rowModelType: 'serverSide',
        // serverSideStoreType: 'partial',
        // cacheBlockSize: 50,
        rowData: rowData,
        // suppressPaginationPanel:true,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          // params.api.sizeColumnsToFit();
          // params.columnApi.autoSizeAllColumns();
          $scope.gridOptions.api.setRowData($scope.warehouse_list);

        },
        onCellClicked: function (event) {
          $scope.warehouseDetails(event.data.warehouseId);
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };



      //Inventory Grid
      var inventoryColDef = [
        {
          headerName: "Items Name",
          field: "categoryName",
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: "+ Incoming ScrapQ Inventory (kgs)",
          field: "vendorName",
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return params.data.incomingInventory.toFixed(2);
          }
        },
        {
          headerName: "+ Incoming Warehouse Inventory (kgs)",
          field: "saleOrderConfirmationId",
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return params.data.toWarehouseTransportInventory.toFixed(2);
          }

        },
        {
          headerName: "- Outgoing ScrapQ Inventory (kgs)",
          field: "totalAmount",
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return params.data.incomingInventory.toFixed(2);
          }
        },
        {
          headerName: "- Outgoing Warehouse Inventory (kgs)",
          field: "status",
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return params.data.outgoingInventory.toFixed(2);
          }
        },
        {
          headerName: "= Current Inventory (kgs)",
          field: "createdOn",
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return params.data.currentInventory.toFixed(2);
          }
        }
      ];

      $scope.inventoryGridOptions = {
        columnDefs: inventoryColDef,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        rowData: [],
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          setTimeout(function () {
            if ($scope.inventoryGridOptions && $scope.inventoryGridOptions.api) {
              $scope.inventoryGridOptions.api.setRowData($scope.currentInventory_list);
            }
          }, 1500);
        },
        onCellClicked: function (event) {
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };
    })();


    (function () {
      if ($rootScope.loginData.role === "CRMUSER" || $rootScope.loginData.role === "ADMIN" || $rootScope.loginData.role === "MASTERFRANCHISE" || $rootScope.loginData.role === "WHOLESALER") {
        loadWarehouses($scope.vm.params);
      } else if ($rootScope.loginData.role === "WAREHOUSEUSER") {
        $scope.length0 = true;
        $scope.warehouseId = $rootScope.loginData.warehouseId;
        getWarehouseProfile($scope.warehouseId);
        $scope.warehouse_list = false;
      } else {
        console.error('Invalid authorized role details ', $rootScope.loginData);
      }
    })();

    /*===============================================core function===================================================*/


    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    function HideMobileNumber() {
    }
    HideMobileNumber.prototype.init = function (params) {
      // console.log("where is mobile no.",params.data);
      this.mobileNumberDiv = document.createElement('div');
      if (params.data != "" || params.data != "undefined") {
        if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
          let mobileNo = params.data.mobile;
          this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        } else {
          let mobileNo = params.data.mobile;
          let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
          this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        }
      } else {
        this.mobileNumberDiv.innerHTML = "";
      }
    }

    HideMobileNumber.prototype.getGui = function () {
      return this.mobileNumberDiv;
    }

    $scope.$watch('dateInput', function (newDate) {
      $scope.transactionChange();
    }, false);

    $scope.transactionChange = function () {
      $scope.date.startDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
      $scope.date.endDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
      $scope.noCurrentInventory = true;
      if ($scope.warehouseId) {
        $scope.currentInventory($scope.warehouseId, $scope.date.startDate, $scope.date.endDate);
      }
    };

    $scope.nextDate = function () {
      $scope.paging = false;
      $scope.dateInput = $moment($scope.dateInput).add(1, "days").format("DD-MMM-YYYY");
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput,
      };
    };

    $scope.previousDate = function () {
      $scope.paging = false;
      $scope.dateInput = $moment($scope.dateInput).subtract(1, "days").format("DD-MMM-YYYY");
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput,
      };
    };

    $scope.currentInventory = function (warehouseId, fromDate, toDate) {
      $scope.noCurrentInventory = true;
      $scope.currentInventory_list = [];
      warehouseService.retrieveAllInventories(warehouseId, toDate, $rootScope.loginData.city, $scope.currentPage)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            if (res.data.allInventories.length > 0) {
              $scope.noCurrentInventory = false;
              $scope.currentInventory_list = res.data.allInventories;
              $scope.inventoryGridOptions.api.setRowData($scope.currentInventory_list);

              $scope.totalInventoriesRecords = res.data.totalRecords;
            } else {
              $scope.noCurrentInventory = true;
            }
          }
        })
        .catch(function (err) {
          console.error('Error in warehouseService.retrieveAllInventories() ', err);
        })
    };

    $scope.pageChangedCompletedTransactions = function (currentPage) {
      $scope.currentPage = currentPage;
      $scope.currentInventory($scope.warehouseId, $scope.date.startDate, $scope.date.endDate);
    };

    $scope.searchWarehouse = function (key) {
      key = key.trim();
      if (key) {
        const type = 'WAREHOUSE';
        $scope.submitted = true;
        $scope.paging = true;
        $scope.noWarehouseFound = false;

        if (key.length > 3) {
          warehouseService.searchWarehouse(type, key)
            .then(function (res) {
              if (res.status === "SUCCESS") {
                $scope.warehouses = res.data;
                if (res.data.warehouses.length === 1) {
                  $scope.length0 = true;
                  $scope.warehouseDetails(res.data.warehouses[0].warehouseId);
                } else {
                  res.data.warehouses.forEach(function (elem, idx) {
                    try {
                      const addrsplit = elem.address.formattedAddress.split(/,+/);
                      res.data.warehouses[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                    } catch (e) {
                      console.error('Error in formatting address ', e);
                    }
                  });
                  $scope.warehouse_list = res.data.warehouses;
                  $scope.length0 = false;
                  $scope.warehouseId = false;
                }
                if (res.data.message === "No records found") {
                  $scope.noWarehouseFound = true;
                }
              } else if (res.status === "FAILURE") {
                $scope.noWarehouseFound = true;
              } else {
                $scope.noWarehouseFound = true;
              }
            })
            .catch(function (err) {
              console.error('Error in warehouseService.searchWarehouse() ', err);

            })
        }
      } else {
        loadWarehouses($scope.vm.params);
      }
    };

    $scope.disableWarehouse = function () {
      if (confirm(`Are you sure you want to disable ${$scope.profileEdit.warehouseName}?`)) {
        var mobile = $scope.profileEdit.mobile;
        var updatedata = {
          "id": mobile
        };
        warehouseService.disableWarehouse(updatedata).then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success('Warehouse disabled successfully');
            $timeout(function () {
              loadWarehouses($scope.vm.params);
            }, 1500);
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message);
          } else {
            loadWarehouses($scope.vm.params);
          }
        }, function (err) {
          console.error('Error in disabling warehouse API ', err);
          growl.error('Failed to disable warehouse');
        })
      }
    };

    /*===============================================helper function===================================================*/

    $scope.pageChanged = function (currentPage) {
      $scope.vm.params.page = currentPage;
      loadWarehouses($scope.vm.params);
    };

    $scope.warehouseDetails = function (id) {
      $scope.length0 = true;
      $scope.warehouseId = id;
      getWarehouseProfile(id);
      $scope.warehouse_list = false;
      $scope.date = {
        startDate: $moment().subtract(730, 'd').format("DD-MMM-YYYY"),
        endDate: $moment().format("DD-MMM-YYYY")
      };
      $scope.currentInventory($scope.warehouseId, $scope.date.startDate, $scope.date.endDate);
      $scope.vm.params = {
        "warehouse": id,
        "size": 10,
        "page": 0,
      };
    };

    function getWarehouseProfile(warehouseId) {
      warehouseService.getProfile(warehouseId)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.profileEdit = res.data.warehouse;
            $scope.centerdata = {
              "latitude": parseFloat($scope.profileEdit.address.latitude),
              "longitude": parseFloat($scope.profileEdit.address.longitude)
            };
            $scope.center = new google.maps.LatLng(parseFloat($scope.profileEdit.address.latitude), parseFloat($scope.profileEdit.address.longitude));
            const mapOptions = {
              zoom: 17,
              mapTypeId: 'roadmap',
              center: $scope.center
            };
            $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            const icon = {
              url: "/images/pulse.gif",
              scaledSize: new google.maps.Size(30, 30),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(15, 15)
            };
            const marker = new google.maps.Marker({
              position: $scope.center,
              map: $scope.map,
              icon: icon
            });
            $scope.length0 = true;
          } else if (res.status === "FAILURE") {
            $scope.noWarehouseFound = "No warehouse record found";
          }
        })
        .catch(function (err) {
          console.error('Error in getWarehouseProfile() ', err);
        })
    }

    function loadWarehouses(params) {
      $global.displayLog("loadWarehouses");
      const type = 'WAREHOUSE';
      $scope.submitted = true;
      $scope.paging = false;
      $scope.noWarehouseFound = false;

      if (!params || !params.page) {
        params = {};
        params.page = 1;
      }
      let noDecrement = false;
      if (params.page === -1) {
        noDecrement = true;
      }
      // ag-grid pagination to work we need all records. -1 fetches all records
      params.page = -1;

      warehouseService.warehousesLoad(type, params.page, noDecrement)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            $scope.warehouses = res.data;
            $scope.totalWarehouseRecords = res.data.totalRecords;
            res.data.warehouses.forEach(function (elem, idx) {
              try {
                if (elem.address) {
                  const addrsplit = elem.address.formattedAddress.split(/,+/);
                  res.data.warehouses[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                }
              } catch (e) {
                console.error('Error in formatting address ', e);
              }
            });
            $scope.length0 = false;
            $scope.warehouseId = false;
            $scope.warehouse_list = res.data.warehouses;

            $scope.labelCounts = {
              totalWarehouses: res.data.totalWarehouses,
              activeWarehouses: res.data.totalActiveWarehouses,
              inActiveWarehouses: res.data.totalInactiveWarehouses
            };

            // the below code displays the data in ag-grid table
            if ($scope.gridOptions && $scope.gridOptions.api) {
              $scope.gridOptions.api.setRowData($scope.warehouse_list);
            }
            // register the datasource with the grid
            // $scope.gridOptions.api.setServerSideDatasource($scope.warehouse_list);

            if (res.data.message === "No records found") {
              $scope.noWarehouseFound = true;
            }
          } else if (res.status === "FAILURE") {
            $scope.noWarehouseFound = true;
          } else {
            $scope.noWarehouseFound = true;
          }
        })
        .catch(function (err) {
          $scope.resetLabelCounts();
          console.error('Error in loadWarehouses() ', err);
        })
    }

    $scope.backClick = function () {
      loadWarehouses();
    };

    $scope.refreshClick = function () {
      loadWarehouses();
    };

    /*===============================================modal functions===================================================*/

    $scope.selectPage = function () {

    };

    $scope.addWarehouses = function () {

      $state.go("root.add-warehouse");


      // const modalInstance = $uibModal.open({
      //   templateUrl: 'views/add-warehouse.html',
      //   controller: 'AddWarehouseCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return 'WAREHOUSE';
      //     }
      //   }
      // });
      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   loadWarehouses($scope.vm.params);
      // });
    };

    $scope.editWarehouseDetails = function () {

      $state.go("root.edit-warehouse", { id: $scope.profileEdit.warehouseId })



      // const modalInstance = $uibModal.open({
      //   templateUrl: 'views/warehouse-edit-profile.html',
      //   controller: 'WarehouseEditProfileCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return $scope.profileEdit.warehouseId;
      //     }
      //   }
      // });
      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   getWarehouseProfile($scope.warehouseId);
      // });
    };

  });

