'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddWarehouseCtrl
 * @description
 * # AddWarehouseCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
	.controller('AddVendorCtrl', function ($scope, $uibModal, Upload, authentication, vendorService, $window, documents, consumer, $stateParams, $moment, $global, growl, $state, $timeout, NgMap, $rootScope, wholesalerService) {

		$scope.vm = {};
		$scope.location = {
			latitude: 0,
			longitude: 0
		};
		$scope.category = null;
		$scope.url = $global.getApiUrl() + '/fileManager/uploadFile';

		var header = $stateParams.type == "ADD" ? "Add Vendor" : "Edit Vendor";
		$scope.pageType = $stateParams.type == "ADD" ? "ADD" : "EDIT";;
		if ($stateParams.type == "EDIT" && !$stateParams.items) {
			$state.go("root.vendor");
		}
		
		let salesDetails = wholesalerService.getSalesDetails();
		$scope.data = salesDetails ? salesDetails : {}; 
		console.log('salesDetails: ', salesDetails);
		if (salesDetails) {
			$scope.data.vendorName = salesDetails.name;
			$scope.referrer = "Aquisitions"
			$scope.vm.customadd = salesDetails.address.landmark;
			$scope.vm.formattedAddress = salesDetails.address.address;
			var center = [];
			center.push(salesDetails.address.latitude);
			center.push(salesDetails.address.longitude);
			$scope.center = center.join();
			$scope.category = salesDetails.categories.key;
			$scope.location = {
			  latitude: salesDetails.address.latitude,
			  longitude: salesDetails.address.longitude
			};
			$scope.chosenPlace = salesDetails.address.address;
			$scope.data.comments = salesDetails.comments ? salesDetails.comments : [];
			$scope.data.comments.forEach(comment => {
			  comment.actualTime = comment.time;
			  comment.time = $moment(comment.time).format("DD-MM-YY");
			});
		  }

		var items = $stateParams.items;
		$scope.headerTitle = header; //coming from vendor.js add-vendor or edit-vendor modal resolve
		/**
		 * Populate the following fields
		 * 1. Vendor name
		 * 2. Mobile
		 * 3. City
		 * 4. chosenPlace (hidden)
		 * 5. Address field that holds formatted address
		 * 6. Flat No
		 * 7. Center for zooming into the map location
		 */
		$scope.documentData = [];
		$scope.id = 1;
		$scope.isAddRowOn = false;
		$scope.selectedVendorType = { value: null };
		$scope.vendor_types = ["Wholesaler", "Industry", "Recycler", "Mega Wholesaler"];
		// $scope.documentTypesList = ["BACKGROUND CHECK DOCUMENT", "AADHAR", "PAN CARD", "REGISTRATION", "DRIVING LICENSE", "PASSPORT", "RATION CARD", "VOTER ID", "ST REGISTRATION", "OTHER", "GST"];

		init();
		function init() {
			if (items) {
				if (items.vendorName) {
					$scope.data.vendorName = items.vendorName;
				}
				if (items.mobile) {
					$scope.data.mobileNo = items.mobile;
				}

				if (items.contact) {
					$scope.data.contact = items.contact;
				}

				// vendor type
				if (items.vendorType) {
					$scope.selectedVendorType.value = items.vendorType;
				}

				if (items.city) {
					$scope.data.city = items.city;
				}
				if (items.gstNumber) {
					$scope.data.gstNumber = items.gstNumber;
				}
				if (items.panNumber) {
					$scope.data.panNumber = items.panNumber;
				}
				if (items.upiId) {
					$scope.data.upiId = items.upiId;
				}
				if (items.address) {
					$scope.chosenPlace = items.address;
				}

				if (items.documents) {
					items.documents.forEach(document => {
						let obj = {};
						obj.documentType = document.key;
						obj.documentId = document.value;
						$scope.documentData.unshift(obj);
					});
				}

				if (items.address.formattedAddress && items.address.formattedAddress.length > 0) {
					$scope.vm.formattedAddress = items.address.formattedAddress;
					$scope.vm.customadd = items.address.formattedAddress.split(",")[0];
				}

				let center = [];
				if (items.address.latitude && items.address.longitude) {
					center.push(parseFloat(items.address.latitude));
					center.push(parseFloat(items.address.longitude));
				}
				if (center && center.length === 2) {
					$scope.editCenter = center.join();
				}
			}
		};

		$rootScope.loginData = {};
		$rootScope.loginData = $global.getLocalItem("loginData", true);

		var citymatcher = "";
		$rootScope.allCityData ? $rootScope.allCityData : [].forEach(function (elem, idx) {
			if (elem.name === $rootScope.loginData.city) {
				if (citymatcher) {
					citymatcher += "|"
				}
				citymatcher += elem.subarea;
			}
		});

		function setPlaceObject(latlng) {
			$global.getLocationByLatLng(latlng).then(function (res) {
				$scope.place = res;
				$scope.vm.formattedAddress = res.formatted_address;
				$scope.chosenPlace = res.formatted_address;
			})
		}

		function reRenderMap() {
			$timeout(function () {
				angular.forEach($scope.maps, function (map) {
					var currCenter = map.getCenter();
					google.maps.event.trigger(map, 'resize');
					map.setCenter(currCenter);
				});
			}, 500);
		}

		$scope.setCurrentLocation = function () {
			$global.getCurrentLocation().then(function (res) {
				$scope.center = $scope.getLatLng(res);
				var rem = {}
				rem.lat = res.latitude;
				rem.lng = res.longitude;
				reRenderMap();
			});
		};

		$scope.getLatLng = function (obj) {
			if (obj && obj.latitude && obj.longitude) {
				var latLng = [];
				latLng.push(obj.latitude);
				latLng.push(obj.longitude);
				return latLng.join();
			}

		};

		$scope.placeChanged = function () {
			if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
				$scope.place = this.getPlace();
				var obj = {};
				obj.lat = $scope.place.geometry.location.lat();
				obj.lng = $scope.place.geometry.location.lng();
				$scope.setLocation(obj);
			} else {
				growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
				$scope.vm.formattedAddress = "";
				$scope.chosenPlace = "";

			}

		};

		$scope.setLocation = function (obj) {
			var center = [];
			center.push(obj.lat);
			center.push(obj.lng);
			$scope.center = center.join();
			$scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
		}

		var map;
		$scope.maps = [];
		$scope.$on('mapInitialized', function (evt, evtMap) {
			$scope.maps.push(evtMap);

		});

		NgMap.getMap().then(function (evtMap) {
			map = evtMap;
		});

		//FIXME empty function
		$scope.setLocations = function () {
			if ($scope.locationadd) {

			}
		};

		$scope.setCurrentLocation();

		$scope.markerDragEnd = function (event) {
			$timeout(function () {
				var latlng = {
					lat: event.latLng.lat(),
					lng: event.latLng.lng()
				};
				setPlaceObject(latlng);
				$scope.center = $scope.getLatLng(latlng);

			})
		};

		$scope.cancel = function () {
			$state.go("root.vendor");
		};
		$scope.keyUp = function (event) {
			if (event.key === "Enter") {
				console.log($scope.vm.formattedAddress)
				$scope.searchForLocation();
			}
		}

		$scope.addVendor = function (address, latitude, longitude, saveType) {
			$scope.data.registerType = "CRM";
			$scope.data.city = $rootScope.loginData.city;
			$scope.data.formattedAddress = $scope.vm.formattedAddress;
			$scope.data.vendorType = $scope.selectedVendorType.value;

			$global.setLocalItem("vendor", $scope.authorization, true);
			$scope.submitted = true;

			if ($scope.addVendorForm.$valid) {
				let geocoder = new google.maps.Geocoder();
				//First Geocode
				geocoder.geocode({ 'address': address }, function (results, status) {
					if (status === google.maps.GeocoderStatus.OK) {
						if (saveType === 'Add Vendor') {
							vendorService.addVendor($scope.data).then(function (res) {
								let authres = JSON.parse(JSON.stringify(res));
								if (res.status === "SUCCESS") {
									// Add address of vendor
									$scope.vendorid = res.data.vendorId;
									$scope.uploadUserDocs($scope.vendorid);
									growl.success("Successfully updated vendor")
									let addresses = $global.getAddressObj(results[0]);

									//override geocoded lat, long
									if (latitude) {
										addresses.latitude = latitude;
										addresses.longitude = longitude;
									}

									addresses.vendorId = $scope.vendorid;
									addresses.userType = $scope.data.userType;
									addresses.landmark = $scope.data.landmark;
									addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;

									vendorService.saveAddress(addresses).then(function (res) {

										if ($scope.data.userType === "VENDOR") {
											delete $scope.data.registerType;
											delete $scope.data.userType;
											delete $scope.data.landmark;
											$scope.data.consumerId = authres.data.userId;
										}
										$scope.openVendorItemsModal();

										if (salesDetails) {
											let obj = {
											  id: salesDetails._id,
											  isApproved: true
											};
											consumer.updateConsumer(obj).then(function (res) {
											  if (res.status === "Success") {
												console.log("User approved successfully");
											  } else {
												growl.error("Consumer added successfully but unable to approve");
											  }
											})
											  .catch((err) => {
												console.log('err in Approving user: ', err);
												growl.error("Consumer added successfully but unable to approve");
											  });
										  }
										  
										$window.history.back();

									})
								} else {
									let errors = res.error.errors;
									for (let i = errors.length - 1; i >= 0; i--) {
										$scope[errors[i].code] = errors[i].message;
										growl.error(errors[i].message)
									}
									if (res.error.errors.length === 0) {
										growl.error(res.error.message);
									}
								}
							});
						} else if (saveType === 'Edit Vendor') {
							$scope.data.vendorId = items.vendorId;
							vendorService.updateVendor($scope.data).then(function (res) {
								if (res.status === "SUCCESS") {
									$scope.vendorid = res.data.vendorId;
									growl.success("Successfully updated vendor");
									let addresses = $global.getAddressObj(results[0]);

									//override geocoded lat, long
									if (latitude) {
										addresses.latitude = latitude;
										addresses.longitude = longitude;
									}
									addresses.addressId = items.address.addressId;
									addresses.vendorId = items.vendorId;
									addresses.userType = $scope.data.userType;
									if ($scope.vm && $scope.vm.customadd && $scope.vm.formattedAddress && $scope.vm.formattedAddress.length > 0 && $scope.vm.customadd !== $scope.vm.formattedAddress.split(",")[0]) {
										addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
									}

									vendorService.updateAddress(addresses).then(function (res) {
										if ($scope.data.userType === "VENDOR") {
											delete $scope.data.registerType;
											delete $scope.data.userType;
											delete $scope.data.landmark;
											$scope.data.consumerId = authres.data.userId;
										}
										// $uibModalInstance.dismiss('Ok');
										// growl.success("Vendor Updated Successfully....!");
										$window.history.back();

									})
								} else {
									let errors = res.error.errors;
									for (let i = errors.length - 1; i >= 0; i--) {
										$scope[errors[i].code] = errors[i].message;
										growl.error(errors[i].message)
									}
									if (res.error.errors.length === 0) {
										growl.error(res.error.message);
									}
								}
							});
						}
					} else {
						growl.error("Failed to find lat,long for the address, please enter a better address");
					}

				});
			} else {
				growl.error("Invalid form. Please fill in all the required fields");
			}
		};
		// Fetch Documents List
		(function () {
			console.log($rootScope.loginData)
			let req = {
				city: $rootScope.loginData.city,
				userType: "VENDOR"
			};

			documents.retrieveDocumentsList(req).then(function (res) {
				if (res.status == "SUCCESS") {
					$scope.documentTypesList = res.data.documents;
					console.log($scope.documentTypesList);
				}
			})

		})();

		$scope.documentData = [];

		$scope.uploadImage = function ($event, docRowIndex) {

			var file = $event.srcElement.files[0];
			console.log(file);
			var reader = new FileReader();
			reader.readAsBinaryString(file);

			reader.onload = function () {
				$scope.documentData[docRowIndex].documentFormat = file.name.split(".")[1];
				$scope.documentData[docRowIndex].document = "data:" + file.type + ":base64," + btoa(reader.result);
				console.log($scope.documentData);
			};
			reader.onerror = function () {
				growl.error("unable to add file please select again");
				$scope.documentData[docRowIndex] = {};
			};

		}
		$scope.uploadUserDocs = function (userId) {
			console.log($scope.documentData);
			$scope.documentData.forEach(function (obj) {
				if (obj.document) {
					obj.userId = userId;
					obj.userType = "VENDOR"
					documents.uploadUserDocuments(obj).then(function (res) {
						$scope.documentData = [];
					})
				}
			});
		};

		$scope.saveDocuments = function () {
			$scope.documentData.forEach(document => {
				// document.id will only be true for newly adding documents
				if (document.id) {
					if (!document.documentFile) {
						growl.error("Please select Documents to upload")
					} else {
						// Upload document if the file is present
						let file = document.documentFile;
						// Allow only images and pdfs
						if (!file.type.includes("image") && !file.type.includes("pdf")) {
							growl.error("Only image and pdf files are accepted!")
							return;
						}

						Upload.upload({ url: $scope.url, file: file }).then(function (response) {
							if (response.data.status === "SUCCESS") {
								growl.success("Successfully saved documents")
								document.documentId = response.data.data.fileId;
								document.id = 0;
							}
						});
					}
				}
			});
		}

		$scope.downloadDocument = function (docid) {
			// alert('To do');
			vendorService.downloadDocument(docid).then(function (res) {
				if (res.status === "SUCCESS") {
					console.log("Downloading file..", docid);
				} else if (res.status === "FAILURE") {
					growl.error("Unable to download documents!");
				} else {
					growl.error("Unable to download documents!");
				}
			}, function (err) {
				console.error(err);
			});
		};

		$scope.addLocation = function (address, latitude, longitude) {

			var addresses = $global.getAddressObj(address);

			//override geocoded lat, long
			if (latitude) {
				addresses.latitude = latitude
				addresses.longitude = longitude
			}

			addresses.userId = $scope.userid;
			addresses.userType = $scope.data.userType;
			addresses.landmark = $scope.data.landmark;
			addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
			authentication.saveCosumerAddress(addresses).then(function (res) {

			})

		};


		$scope.searchForLocation = function () {
			let data = $scope.vm.formattedAddress.replaceAll("\n", " ");
			console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress)
			authentication.getLocations(data).then(function (res) {
				console.log("response", res);
				$scope.predictions = res.predictions;
				$scope.showPredictions = true;
			}).catch(function (err) {
				console.log("err", err);
			})
		};

		$scope.locationSelected = function (obj) {
			console.log("clicked item", obj);
			$scope.vm.formattedAddress = obj.description;
			$scope.showPredictions = false;
			authentication.getLatLng(obj.place_id).then(function (res) {
				console.log("response", res);
				if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
					let place = res.result;
					let obj = {};
					obj.lat = place.geometry.location.lat;
					obj.lng = place.geometry.location.lng;
					$scope.setLocation(obj);
				} else {
					growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
					$scope.vm.formattedAddress = "";
					$scope.chosenPlace = "";
				}
			}).catch(function (err) {
				console.error("err", err);
			});
		};

		$scope.addRow = function () {
			let obj = {};
			$scope.documentData.push(obj);
		};

		$scope.removeRow = function (index) {
			$scope.documentData.splice(index, 1);
		};

		$scope.anyEditingDocuments = function () {
			for (let i = 0; i < $scope.documentData.length; i++) {
				// Check for pending rows
				if ($scope.documentData[i].id) {
					return true;
				}
			}
			return false;
		}

		$scope.openVendorItemsModal = function () {
			// console.log('In open: ',$scope.vendorid);
			let id = items ? items.vendorId : $scope.vendorid;
			var modalInstance = $uibModal.open({
				templateUrl: 'views/vendor-items-modal.html',
				controller: 'VendorItemsModalCtrl',
				backdrop: 'static',
				size: "lg",
				resolve: {
					items: function () {
						return null;
					},
					vendorId: function () {
						return id;
					},
					header: function () {
						return "Add Vendor";
					}
				}

			});

			modalInstance.result.then(function () {
				console.log("closed")
			});
		}
	});
