'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:VendorItemsPriceCtrl
 * @description
 * # VendorItemsPriceCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('VendorItemsPriceCtrl', function($scope, $filter, items, vendorUpdateItem, modalHeader, vendorService, products, saleOrderService, $moment, $global, growl, $uibModalInstance, $state, $timeout, $rootScope) {
	
		// vendorItemsSelected
        $scope.categoryNames = [];
        $scope.modalHeader = modalHeader;
        $scope.vendorAddItem = {};
        
        if(vendorUpdateItem){
			$scope.vendorUpdateItem = vendorUpdateItem;
		}

		function swap(json){
			var ret = {};
			for(var key in json){
				ret[json[key]] = key;
			}
			return ret;
		}

		// old functionality 
		
		(function(){
			let flag=false;
			// console.log(modalHeader,vendorItemsSelected,modalHeader == "Add Vendor Pricing" && vendorItemsSelected)
			// if(modalHeader == "Add Vendor Pricing" && vendorItemsSelected){
			// 	$scope.vendor_items_table={};
			// 	for(let i=0;i<vendorItemsSelected.length;i++){
			// 		let name=vendorItemsSelected[i].label;
			// 		console.log(name)
			// 		$scope.vendor_items_table[name]=true;
			// 	}
			// 	if(Object.keys($scope.vendor_items_table).length>0){
			// 		flag=true;
			// 	}
			// 	console.log("$scope.vendor_items_table",$scope.vendor_items_table)
			// }
			vendorService.retrieveAllItems().then(function (res) {
			if (res.status === 'SUCCESS' && res.data && res.data.vendorItems) {
				$scope.vendorItems_list = res.data.vendorItems;
				console.log("$scope.vendorItems_list",$scope.vendorItems_list);
				if(!flag){
					vendorService.retrievePricingForVendor(items).then(function (res) {
						// console.log("res",res)
						if (res.status === 'SUCCESS' && res.data && res.data.items) {
							$scope.vendor_price_list = res.data.items;
							console.log("$scope.vendor_price_list",$scope.vendor_price_list);
							$scope.vendor_price_list.forEach(function (element, index) {
								$scope.vendorItems_list.forEach(function (elem, indx) {
										if (element.vendorItemsId === elem.vendorItemsId) {
											$scope.vendorItems_list.splice(indx, 1);
										}
									});
								});
							}
						});
					} else {
						let new_list=[];
						$scope.vendorItems_list.forEach( (val,indx) => {
							console.log("val",val,!(val.itemName in $scope.vendor_items_table))
							if(val.itemName in $scope.vendor_items_table){
								new_list.push(val);
							}
						})
						$scope.vendorItems_list=new_list;
					}
				}
			});
		})();

		// new functionality - to implement	

        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };
	
		$scope.addVendorItems = function (saveType) {
			if (saveType === 'saveVendorItem') {
				let priceData = {
					"vendorId": items,
					"vendorItemsId": $scope.vendorAddItem.selectedItem,
					"vendorPrice": $scope.vendorAddItem.price
				};
				vendorService.addVendorPricing(priceData).then(function (res) {
					if (res.status === "SUCCESS") {
						growl.success("Vendor Price Added Successfully.");
						$uibModalInstance.close('Close');
					} else {
						growl.error("Failure while adding Price");
					}
				})
			} else if (saveType === 'updateVendorItem') {
				let priceString = $scope.vendorUpdateItem.vendorPrice;
				let priceFloat = Number(priceString);
				let priceFixed = priceFloat.toFixed(2);
				if (!isNaN(priceFixed)) {
					priceString = priceFixed.toString();
					let priceData = {
						"vendorId": items,
						"vendorItemsId": $scope.vendorUpdateItem.vendorItemsId,
						"vendorPrice": priceString,
						"vendorPriceId": $scope.vendorUpdateItem.vendorPriceId
					};
					vendorService.updateVendorPricing(priceData).then(function (res) {
						console.log(res);
						if (res.status === "SUCCESS") {
							growl.success("Vendor Price Updated Successfully.");
							$uibModalInstance.close('Close');
						} else {
							growl.error("Failure while updating the price");
						}
					})
				}else{
					growl.error("Invalid price format");
					$uibModalInstance.close('Close');
				}
				
				
			} else if (saveType === 'deleteVendorItem') {
				let priceData = {
					"vendorId": items,
					"vendorItemsId": $scope.vendorUpdateItem.vendorItemsId,
					"vendorPrice": $scope.vendorUpdateItem.vendorPrice,
					"vendorPriceId": $scope.vendorUpdateItem.vendorPriceId
				};
				vendorService.deleteVendorPricing(priceData).then(function (res) {
					console.log(res);
					if (res.status === "SUCCESS") {
						growl.success("Vendor Price Deleted Successfully.");
						$uibModalInstance.close('Close');
					} else {
						growl.error("Failure while deleting the price");
					}
				})
			}
		};

    });