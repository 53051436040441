"use strict";

angular
  .module("scrapQcrmApp")
  .controller("TransportInventoryCtrl", function ($scope, $filter, $http, $state, $sce, $rootScope, $global, ngToast, products, $uibModal, $window, SellNow, growl, $log, warehouseService, wholesalerService, $stateParams, localStorageService, DTOptionsBuilder, DTColumnDefBuilder, $moment) {

    // var initializing = true;
    var inrFilter = $filter('INR');
    var columnDefs = [
      { headerName: "From Warehouse", field: "fromWarehouse.warehouseName" },
      { headerName: "To Warehouse", field: "toWarehouse.warehouseName" },
      { headerName: "Confirmation Id", field: "confirmationId" },
      { headerName: "Cost Of Scrap", field: "costOfScrap", cellRenderer: TotalAmountCellRenderer, cellStyle: { 'text-align': 'right' } },
      { headerName: "Status", field: "status" },
      { headerName: "Inception Date", field: "timestamp" }
    ];

    var rowData = [];

    $scope.gridOptions = {
      columnDefs: columnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      rowStyle: { background: '#f4f4f4', cursor: 'pointer' },
      pagination: true,
      // paginationAutoPageSize: true,
      paginationPageSize: 1000,
      rowData: rowData,
      suppressCsvExport: true,
      suppressExcelExport: true,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        // console.log("params",params);
        params.api.sizeColumnsToFit();
        if (!($scope.noInventoriesFound) && $scope.transportInventories && $scope.transportInventories.length > 0) {
          $scope.gridOptions.api.setRowData($scope.transportInventories);
        }
      },
      onRowClicked: function (event) {
        // console.log("event.data",event,"d",event.data);
        if (event && event.data) {
          // $scope.OpenRequest(event.data);
          $scope.viewTransportInventory(event.data.gatePassURL, event.data)
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      },
      onFilterChanged: function (e) {
        // console.log('onFilterChanged', e);
        // console.log('gridApi.getFilterModel() =>', e.api.getFilterModel());
      },
      onFilterModified: function (e) {
        // console.log('onFilterModified', e);
        // console.log('filterInstance.getModel() =>', e.filterInstance.getModel());
        // console.log(
        //   'filterInstance.getModelFromUi() =>',
        //   e.filterInstance.getModelFromUi()
        // );
      },
    };

    function TotalAmountCellRenderer() {
    }

    TotalAmountCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        // let val= params.value?(params.value).toFixed(2):'-';
        let val = params.value ? inrFilter(params.value) : '-';
        var iconsDiv = "<div>&#x20b9; " + val + "</div>";
        this.eGui.innerHTML = iconsDiv;
      }
    };

    TotalAmountCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    (function () {
      $scope.isRedirected = $global.getLocalItem("transferRequestId", true);
      $scope.statusArr = ["ALL", "PENDING", "APPROVED", "REJECTED", "DISPATCHED"];

      $scope.selectedStatus = "PENDING";
      $scope.selectedStatusId = 1;

      if ($rootScope.loginData.role === "WAREHOUSEUSER") {
        $scope.selectedStatus = "APPROVED";
        $scope.selectedStatusId = 2;
      }

      // console.log("$stateParams",$stateParams);

      $scope.totalTransportInventoriesRecords = 0;
      var data = [], apiData, list = [];
      $scope.warehouse_list_copy = [];
      $scope.to_warehouse_list = [];
      $scope.toWarehouse = { warehouse: null };
    })();


    function getHierarchy() {
      wholesalerService.getOrgChartData().then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data && res.data.payoutUsers && res.data.payoutUsers.length > 0) {
            apiData = res.data.payoutUsers;
            data.push({
              ownerId: 0,
              userId: apiData[0].userId,
              userType: apiData[0].userType,
              userName: apiData[0].userName,
              userMobile: apiData[0].userMobile
            });
            createNewNode(apiData[0]);
            searchAndReassign();
          } else {
            growl.success("No records found")
          }
        } else {
          growl.error("Invalid Id to get Chart")
        }
      }).catch(function (err) {
        growl.error("No heirarchy records found")
        console.log("err", err)
      })
    }

    function createNewNode(arr) {
      if (Array.isArray(arr.children)) {
        arr.children.forEach(child => {
          let obj = {};
          obj.userId = child.userId;
          obj.userType = child.userType;
          obj.userName = child.userName;
          obj.userMobile = child.userMobile;
          obj.ownerId = child.ownerId;
          data.push(obj);
          createNewNode(child);
          if (obj.userName === $scope.warehouseAgent || obj.userId === $scope.warehouseAgent) {
            $scope.warehouseAgent = obj.ownerId;
            list.push(obj.userName);
          }
        });
      }
    }

    function searchAndReassign() {
      let newWarehouseList = [];
      $scope.warehouse_list.forEach(warehouse => {
        if (list.indexOf(warehouse.warehouseName) >= 0 || list.indexOf(warehouse.warehouseTypeValue) >= 0) {
          newWarehouseList.push(warehouse);
        }
      });
      list = [];
      data = [];
      $scope.warehouseAgent = null;
      // change to warehouse list
      $scope.to_warehouse_list = newWarehouseList;
      $scope.isDisabled = false;
    }

    function checkWarehouseTypes(clicked) {
      let agentWarehouses = [], typeWholesaler = false, mfWarehouses = [], typeMF = false;
      for (let i = 0; i < $scope.warehouse_list.length; i++) {
        if ($scope.warehouse_list[i].warehouseType === "Agent") {
          agentWarehouses.push(i);
          if ($scope.warehouse_list[i].warehouseId === $rootScope.loginData.warehouseId || $scope.warehouse_list[i].warehouseId === clicked) {
            $scope.warehouseAgent = $scope.warehouse_list[i].warehouseTypeValue;
            $scope.isDisabled = true;
            break;
          }
        }
        if ($scope.warehouse_list[i].warehouseId === $rootScope.loginData.warehouseId || $scope.warehouse_list[i].warehouseId === clicked) {
          if ($scope.warehouse_list[i].warehouseType === "Wholesalers") {
            typeWholesaler = true;
            localStorageService.remove('transportInvParams'); // don't need params as there's no back button
          }
          if ($scope.warehouse_list[i].warehouseType === "MasterFranchise") {
            typeMF = true;
          }
        } else {
          if ($scope.warehouse_list[i].warehouseType === "MasterFranchise") {
            mfWarehouses.push(i);
          }
        }
      }
      if ($scope.warehouseAgent) {
        getHierarchy();
      }

      if (typeMF && mfWarehouses.length > 0) {
        // append mf and agent arrays
        agentWarehouses.push(...mfWarehouses);
      }
      agentWarehouses.sort((a, b) => a - b);
      // console.log("Warehouses removed",agentWarehouses)
      if ((typeMF || typeWholesaler) && agentWarehouses.length > 0) {
        for (let i = agentWarehouses.length - 1; i >= 0; i--) {
          $scope.warehouse_list.splice(agentWarehouses[i], 1);
        }
      }
    }
    (function () {
      $scope.warehouseItemsListLoading = true;
      // $scope.showWarehouseItems = false;

      $scope.dateRangePicker = {};

      $scope.dateRangePicker.dateInput = {
        startDate: $moment($global.projectInceptionDate, "DD-MMM-YYYY").toDate(),
        endDate: $moment(new Date(), "DD-MMM-YYYY").toDate()
      };
      // $scope.selectedWarehouse = null; //FIXME handle this variable to get the reload button working
      $scope.selectedWarehouse = { warehouseId: null };
      // pagination init data
      $scope.currentPage = 1;

      // date options for the date-range-picker
      $scope.opts = {
        locale: {
          applyClass: 'btn-green',
          applyLabel: "Apply",
          fromLabel: "From",
          format: "DD-MMM-YYYY",
          toLabel: "To",
          cancelLabel: 'Cancel',
          customRangeLabel: 'Custom range'
        },
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')]
        }
      };

      $scope.errorDiable = true;
      $scope.Categories = [];
    })();

    (function () {
      if ($rootScope.loginData.role === "WAREHOUSEUSER") {
        warehouseService.getProfile($rootScope.loginData.warehouseId)
          .then(function (res) {
            // $scope.addTransportInventory(res.data.warehouse);
            let currentWarehouse = res.data.warehouse;
            warehouseService.getWarehouse(-1)
              .then(function (res) {
                if (res.status === "SUCCESS" && res.data.warehouses.length > 1) {
                  $scope.warehouse_list = res.data.warehouses;
                  // checkWarehouseTypes();
                  if (currentWarehouse) {
                    $scope.addTransportInventory(currentWarehouse);
                  }
                } else {
                  $scope.noWarehouseFound = true
                }
              })
              .catch(function (err) {
                console.error("Failed to get warehouse list ", err)
              })

          })
          .catch(function (err) {
            console.error("Failed to get warehouse ", err)
          });
      } else {
        warehouseService.getWarehouse(-1)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.warehouses.length > 1) {
              $scope.warehouse_list = res.data.warehouses;
              $scope.to_warehouse_list = $scope.warehouse_list;
              angular.copy($scope.warehouse_list, $scope.warehouse_list_copy);
              if ($scope.warehouseRedirected) {
                $scope.addTransportInventory($scope.warehouseRedirected);
              }
            } else {
              $scope.noWarehouseFound = true
            }
          })
          .catch(function (err) {
            console.error("Failed to get warehouse list ", err)
          })
      }
    })();
    $scope.checkFromToWarehouse = function (selectedWarehouse) {
      $scope.addTransportFormData.toWarehouseId = selectedWarehouse.warehouseId;
      if ($scope.addTransportFormData.toWarehouseId === $scope.addTransportFormData.fromWarehouseId) {
        growl.error("From and To Warehouse Cannot Be Same");
        $scope.addTransportFormData.toWarehouseId = null;
        $scope.toWarehouse.warehouse = null;
        $scope.errorDiable = true;
      } else {
        if ($scope.Categories.length > 0) {
          $scope.errorDiable = false;
          for (let category of $scope.Categories) {
            if (category.categoryName === null || category.quantity === null) {
              $scope.errorDiable = true;
              break;
            }
          }
        }
      }
    };

    $scope.transactionChange = function () {
      // let startDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      // let endDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      // if ($scope.selectedWarehouse && $scope.selectedWarehouse.warehouseId) {
      getTransportInventoriesForAWarehouse();
      // }
    };

    $scope.$watch("dateRangePicker.dateInput", function (newDate) {
      $scope.transactionChange()
    }, false);

    $scope.backClick = function () {
      angular.copy($scope.warehouse_list_copy, $scope.to_warehouse_list);
      $scope.Categories = [];
      // console.log("$stateParams",$stateParams)
      // $scope.showWarehouseItems = false;
    };

    $scope.nextDate = function () {
      $scope.dateRangePicker.dateInput = {
        startDate: $moment($scope.dateRangePicker.dateInput.startDate).add(1, "days"),
        endDate: $moment($scope.dateRangePicker.dateInput.startDate).add(1, "days")
      };

      // let startDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      // let endDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");

      getTransportInventoriesForAWarehouse();
    };

    $scope.previousDate = function () {

      $scope.dateRangePicker.dateInput = {
        startDate: $moment($scope.dateRangePicker.dateInput.startDate).subtract(1, "days"),
        endDate: $moment($scope.dateRangePicker.dateInput.startDate).subtract(1, "days")
      };

      // let startDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      // let endDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");

      getTransportInventoriesForAWarehouse();

    };

    $scope.refreshButtonClicked = function (warehouse) {
      // $scope.showWarehouseItems = false;
      $scope.addTransportFormData = {};
      $scope.selectedWarehouse = null;
      $scope.fromWareHouseName = null;
      $scope.addTransportFormData.fromWarehouseId = null;
      $scope.distinctCategories = null;
      $scope.Categories = [];
      $scope.errorDiable = true;
      $scope.addTransportInventory(warehouse);
    };

    $scope.addTransportInventory = function (warehouse) {
      // console.log("sw",warehouse);
      $scope.backClick();
      if (!(typeof warehouse === "object")) {
        let warehouseId = warehouse;
        // let warehouse=null;
        for (let i = 0; i < $scope.warehouse_list.length; i++) {
          if ($scope.warehouse_list[i].warehouseId === warehouseId) {
            warehouse = $scope.warehouse_list[i];
            break;
          }
        }
      }
      $scope.toWarehouse.warehouse = null;
      if (warehouse.warehouseType &&
        (warehouse.warehouseType == "Agent" || warehouse.warehouseType == "Wholesalers" || warehouse.warehouseType == "MasterFranchise")) {
        checkWarehouseTypes(warehouse.warehouseId);
      }
      // console.log("warehouse = ", warehouse);
      // $scope.showWarehouseItems = true;
      // $scope.Categories = [];
      $scope.addTransportFormData = {};
      $scope.selectedWarehouse = warehouse;
      $scope.fromWareHouseName = warehouse.warehouseName;
      $scope.addTransportFormData.fromWarehouseId = warehouse.warehouseId;
      // getProductsCategories();
      // let startDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      // let endDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      getTransportInventoriesForAWarehouse();
    };

    const getTransportInventoriesForAWarehouse = function () {

      // warehouseService.getTransportInventoriesForAWarehouse(warehouse, page, fromDate, toDate)
      //   .then(function(res) {
      //     if (res && res.data && res.data.transportInventories) {
      //       $scope.transportInventories = res.data.transportInventories;
      //       $scope.totalTransportInventoriesRecords = res.data.totalRecords;
      //     }
      //   })
      //   .catch(function(err) {
      //     console.error('Error in getTransportInventoriesForAWarehouse', err);
      //   })
      var transferRequestToOpen = null;
      $scope.totalCount = 0;
      $scope.pendingCount = 0;
      $scope.approvedCount = 0;
      $scope.rejectedCount = 0;
      $scope.dispatchedCount = 0;
      let obj = { fromWarehouseId: null, toWarehouseId: null, fromDate: null, toDate: null };
      if ($stateParams && $stateParams.fromWarehouseId) {
        $scope.selectedWarehouse.warehouseId = "" + $stateParams.fromWarehouseId;
        $stateParams = null;
      }
      obj.fromWarehouseId = ($scope.selectedWarehouse && $scope.selectedWarehouse.warehouseId) ? $scope.selectedWarehouse.warehouseId : "ALL";
      obj.toWarehouseId = ($scope.toWarehouse && $scope.toWarehouse.warehouse) ? $scope.toWarehouse.warehouse : "ALL";
      obj.fromDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      obj.toDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      warehouseService.getTransportInventories(obj).then(function (res) {
        // console.log("Res",res);
        if (res && res.status === "SUCCESS") {
          if (res && res.data && res.data.transportInventories) {
            $scope.transportInventories = res.data.transportInventories;
            $scope.totalTransportInventoriesRecords = res.data.totalRecords;
            if ($scope.transportInventories.length === 0) {
              $scope.noInventoriesFound = true;
            } else {
              $scope.transportInventories.forEach(function (element, index) {
                var totalAmount = 0.0;
                element.items.forEach(function (elem, indx) {
                  elem.amount = parseFloat(elem.amount.replace(',', ''));
                  totalAmount = totalAmount + parseFloat(elem.amount);
                });
                element['costOfScrap'] = totalAmount;

                if (element.status === "PENDING") {
                  element['statusNo'] = 1;
                  $scope.pendingCount = $scope.pendingCount + 1;
                } else if (element.status === "APPROVED") {
                  element['statusNo'] = 2;
                  $scope.approvedCount = $scope.approvedCount + 1;
                } else if (element.status === "REJECTED") {
                  element['statusNo'] = 3;
                  $scope.rejectedCount = $scope.rejectedCount + 1;
                } else if (element.status === "DISPATCHED") {
                  element['statusNo'] = 4;
                  $scope.dispatchedCount = $scope.dispatchedCount + 1;
                }
                if ($scope.isRedirected && $scope.isRedirected == element.confirmationId) {
                  transferRequestToOpen = element;
                  $scope.isRedirected = null;
                  $global.removeLocalItem("transferRequestId");
                }
              });
              // console.log("$scope.noContent",$scope.noContent);
              if ($scope.gridOptions && $scope.gridOptions.api) {
                $scope.gridOptions.api.setRowData($scope.transportInventories);
              }
              $scope.noInventoriesFound = false;

              if ($scope.dispatchedCount > 0 || $scope.rejectedCount > 0
                || $scope.approvedCount > 0 || $scope.pendingCount > 0) {
                if ($scope.noContent) {
                  $scope.noContent = false;
                }
                $scope.noInventoriesFound = false;
                $scope.totalCount = $scope.pendingCount + $scope.approvedCount + $scope.rejectedCount + $scope.dispatchedCount;
                // if(($scope.selectedStatusId===1 && $scope.pendingCount===0) || ($scope.selectedStatusId===2 && $scope.approvedCount===0)){
                //   $scope.noContent=true;
                // }
                if (transferRequestToOpen) {
                  $scope.viewTransportInventory(null, transferRequestToOpen);
                }
              } else {
                $scope.noInventoriesFound = true;
              }
            }
          }
        } else {
          $scope.noInventoriesFound = true;
        }
      }).catch(function (err) {
        console.log("Err", err);
      })
    };

    $scope.pageChangedCompletedTransactions = function (currentPage) {
      // let startDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      // let endDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      $scope.currentPage = currentPage;
      if ($scope.selectedWarehouse && $scope.selectedWarehouse.warehouseId) {
        getTransportInventoriesForAWarehouse();
      }
    };
    const getProductsCategories = function () {
      products.getProducts()
        .then(function (res) {
          // get distinct categories
          let distinctCategoryNames = [];
          let productCategoriesArr = [];
          for (let productCategory of res.data.categories) {
            if (!distinctCategoryNames.includes(productCategory.name)) {
              distinctCategoryNames.push(productCategory.name); //using this space to keep track of distinct category names
              let productCategoriesObj = {};
              productCategoriesObj.categoryName = productCategory.name;
              productCategoriesObj.quantity = 0;
              productCategoriesArr.push(productCategoriesObj)
            }
          }
          $scope.distinctCategories = productCategoriesArr;
        })
        .catch(function (err) {
          console.error('Error in products.getProducts() API ', err);
        })
    };


    $scope.viewTransportInventory = function (url, requestDetails) {
      // console.log("requestDetails",requestDetails);
      let pickupInstance = $uibModal.open({
        templateUrl: 'views/view-transportInv-details.html',
        controller: 'ViewTansportInvModalCtrl',
        size: "lg",
        resolve: {
          request: function () {
            return requestDetails;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        getTransportInventoriesForAWarehouse();
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        getTransportInventoriesForAWarehouse();
      });
    };

    $scope.statusSelect = function (option) {
      // console.log("OPTION", $scope.selectedStatus);
      if ($scope.selectedStatus === 'PENDING' && $scope.pendingCount > 0) {
        // console.log("Here");
        $scope.selectedStatusId = 1;
        $scope.noContent = false;
      } else if ($scope.selectedStatus === 'APPROVED' && $scope.approvedCount > 0) {
        $scope.noContent = false;
        $scope.selectedStatusId = 2;
      } else if ($scope.selectedStatus === 'REJECTED' && $scope.rejectedCount > 0) {
        $scope.selectedStatusId = 3;
        $scope.noContent = false;
      } else if ($scope.selectedStatus === 'DISPATCHED' && $scope.dispatchedCount > 0) {
        $scope.selectedStatusId = 4;
        $scope.noContent = false;
      } else if ($scope.selectedStatus === 'ALL' && ($scope.pendingCount > 0 || $scope.approvedCount > 0 || $scope.rejectedCount > 0 || $scope.dispatchedCount > 0)) {
        $scope.noContent = false;
        $scope.selectedStatus = null;
        $scope.selectedStatusId = null;
      } else {
        switch ($scope.selectedStatus) {
          case 'PENDING': $scope.selectedStatusId = 1; break;
          case 'APPROVED': $scope.selectedStatusId = 2; break;
          case 'REJECTED': $scope.selectedStatusId = 3; break;
          case 'DISPATCHED': $scope.selectedStatusId = 4; break;
          case 'ALL': $scope.selectedStatus = null;
            $scope.selectedStatusId = null;
            break;
        }
        // if(!$scope.transportInventories || $scope.transportInventories.length===0 || $scope.selectedStatus || $scope.selectedStatusId){
        $scope.noContent = true;
        //   $scope.noInventoriesFound=true;
        // } else {
        //   $scope.noContent = false;
        //   $scope.noInventoriesFound=false;
        // }
      }
    };
  });