'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.prospect
 * @description
 * # prospect
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('prospect', function(httpService, $global) {
        return {
            getConsumerByText: function(text) {
                var url = $global.getApiUrl() + $global.getApiObject().consumerSearch;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getConsumer: function(type,key) {
                var url = $global.getApiUrl() + $global.getApiObject().getConsumer.replace(":userType", type).replace(":text", key);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            usersLoad: function(type, status) {
                var url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", type) + "&status=PENDING";
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getProfile: function(agentId) {
                var agentId = agentId;
                var url = $global.getApiUrl() + $global.getApiObject().getProspectProfile.replace(":aid", agentId);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },
            getAddress: function(id) {
                var consumerId = id;
                var url = $global.getApiUrl() + $global.getApiObject().getAddress + "?userid=" + consumerId;
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },
            refferalCode: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().refferalCode.replace(":regId",data);
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },
            deleteAddres:function(id){
                var url = $global.getApiUrl() + $global.getApiObject().deleteAddress.replace(":id",id);
                var $request = httpService.httpRequest(url, "P");
                return $request;
            },
            updateStatus: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().updateProspectStatus;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getCounts: function() {
                var url = $global.getApiUrl() + $global.getApiObject().getProspectCounts;
                var $request = httpService.httpRequest(url, "G");
                return $request;
            },
        };
    });
