'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:VendorItemsCtrl
 * @description
 * # VendorItemsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('VendorItemsCtrl', function($scope, products, ngTableParams, $state, $global, $uibModal, envService, editableOptions, growl, vendorService, $log) {
        

        // products.getProducts().then(function(res) {
        //     if (res.data.categories) {
        //         $scope.data = res.data.categories;

        //         var addresstypes = ["Residence", "Shop", "Office", "Hotel", "Automobile", "Hospital"];
        //         var dataset = {};

        //         res.data.categories.forEach(function(catdata, idx1) {

        //             if (!dataset[catdata.name]) {
        //                 dataset[catdata.name] = {}
        //             }
        //             addresstypes.forEach(function(type, idx) {
        //                 if (type == catdata.consumercategoryname) {

        //                     if (!dataset[catdata.name][type]) {
        //                         dataset[catdata.name][type] = {}
        //                     }
        //                     dataset[catdata.name][type].price = catdata.price;
        //                     dataset[catdata.name][type].categoryid = catdata.categoryId;
        //                 }
        //             });
        //         });

        //         console.log(dataset)
        //         $scope.dataset = dataset;
        //     }
        // });


        // ag-grid init
        (function () {
            var columnDefs = [
                {
                    headerName: "Category",
                    field: "productCategory",
                    cellStyle: { 'text-align': 'left',"padding-left":'100px' }
                },
                {
                    headerName: "Product Name",
                    field: "productName",
                    resizable: true,
                    minWidth:150,
                    cellStyle: { 'text-align': 'left',"padding-left":'90px' }
                },
                {
                    headerName: "Vendor Product Name",
                    field: "itemName",
                    cellStyle: { 'text-align': 'left',"padding-left":'60px' }
                },
                {
                    headerName: "UoM",
                    field: "uom",
                    cellStyle: { 'text-align': 'left',"padding-left":'130px' }
                    // hide:true
                },
                {
                    headerName: "Inception Date",
                    field: "createdOn",
                    hide:true
                },
               ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    // minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true
                },
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                paginationPageSize: 1000,
                // paginationAutoPageSize:true,
                rowData: rowData,
                suppressCsvExport: true,
                suppressExcelExport: true,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    // params.api.sizeColumnsToFit();
                    // params.columnApi.autoSizeAllColumns();
                    $scope.gridOptions.api.setRowData($scope.vendorPricing_list);
                },
                // onCellClicked: function (event) {
                //     $scope.consumerDetails(event.rowIndex);
                // },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                },
            };
        })();



        $scope.addItems= function(){
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-vendor-items.html',
                controller: 'AddVendorItemsCtrl',
                size: "md",
                resolve: {
                    items: function() {
                        return "VENDOR";
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
                console.log("hi")
                $scope.price();
            }, function() {
                console.log('Modal dismissed at: ' + new Date());
                $scope.price();
            });
        }

        $scope.changeSort = function(type) {
            if (type === "productCategory") {
                $scope.productCategorySort = !$scope.productCategorySort;
                $scope.sortType = "productCategory";
                $scope.sortValue = $scope.productCategorySort;
            }
            if (type === "productName") {
                $scope.productNameSort = !$scope.productNameSort;
                $scope.sortType = "productName";
                $scope.sortValue = $scope.productNameSort;
            }
            if (type === "itemName") {
                $scope.itemNameSort = !$scope.itemNameSort;
                $scope.sortType = "itemName";
                $scope.sortValue = $scope.itemNameSort;
            }
        }

        $scope.price = function(){

            vendorService.retrieveAllItems().then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    if (res.data.vendorItems.length>0) {
                        $scope.vendorPricing_list = res.data.vendorItems;
                        $scope.gridOptions.api.setRowData($scope.vendorPricing_list);
                    }
                }
            });
        }
        $scope.price();
    });
