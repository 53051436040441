'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:CreateSaleOrderModalCtrl
 * @description
 * # CreateSaleOrderModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('CreateSaleOrderModalCtrl', function($scope, $filter, $uibModal, vendorService, products, saleOrderService, $moment, $global, growl, $uibModalInstance, $state, $timeout, $rootScope,itemsList,warehouseId,vendorId, warehouseInventoryService) {
        const addresstypes = ["Residence", "Shop", "Office", "Hotel", "Automobile", "Hospital", "Shopper"];
        $scope.imagePath = $global.getApiUrl() + $global.getApiObject().getImageFileById;
        
        $scope.textAreaContent="";
        $scope.dateInput = $moment(new Date()).format("DD-MMM-YYYY");
        $scope.date = {
          startDate: $scope.dateInput,
          endDate: $scope.dateInput
        };
        $scope.opts = {
          format: "DD-MMM-YYYY",
          singleDatePicker: true
        };

        $scope.errorDiable = true;
        $scope.clearanceModal=false;
        $scope.itemsValidated=false;
        $scope.validatedItems=[];
        $scope.disableSubmit=false;
        $scope.unitPrices={};
        $scope.categoryImages={};
        $scope.selectedVendorItems={};
        $scope.productToVendor=[];
        // $scope.selectedProduct={category:null};
        // console.log("itemsList",itemsList,"warehouseId",warehouseId);
        if(itemsList && itemsList.length>0 && warehouseId && !vendorId){
            $scope.clearanceModal=true;
        }
        $scope.data = {};
        $scope.vm = {};
        $scope.location = {
            latitude: 0,
            longitude: 0
        };

        console.log($rootScope.loginData.userid);
        $scope.incomingInventoryDetails = {};

        $scope.categoryNames =[];
        var a = [];

        products.getProducts().then(function(res) {
            // console.log("CATEGORIES  :::",res);
            if (res.status == 'SUCCESS') {
                $scope.categories = res.data.categories;
                $scope.categories.forEach(function(element, index){
                    if(element.consumercategoryname==='Residence'){
                        $scope.unitPrices[element.name]=element.weightedPriceAverage == null ? element.price : element.weightedPriceAverage;
                        $scope.categoryImages[element.name]=element.image;
                    } else {
                        let cont=true;
                        for(let i=0;cont && i<addresstypes.length;i++){
                            if(element.consumercategoryname===addresstypes[i]){
                                $scope.unitPrices[element.name]=element.weightedPriceAverage == null ? element.price : element.weightedPriceAverage;
                                $scope.categoryImages[element.name]=element.image;
                                cont=false;
                            }
                        }
                    }
                    a.push(element.name)
                });
                $scope.categoryNames = a.filter( onlyUnique );
                // $scope.statuses = $scope.categoryNames;
                console.log(Object.keys($scope.unitPrices).length,$scope.unitPrices,"unitPrices");
                console.log(Object.keys($scope.categoryImages).length,$scope.categoryImages,"categoryImages");
                $scope.validatedItems.forEach(item => {
                    item.icon = item.icon ? item.icon : $scope.categoryImages[item.clearanceItem];
                });
                }
         });
        
        vendorService.vendorLoad('VENDOR', -1, true).then(function(res) {
            if (res.status == "SUCCESS") {
                $scope.vendors = res.data;
                if (res.data.vendors.length > 0) {
                    $scope.vendor_list = res.data.vendors;
                    // console.log("vendorId",vendorId);
                    if(vendorId){
                        $scope.selectedVendorId=vendorId.split('$')[0];
                        $scope.saleOrderConfirmationId=vendorId.split('$')[1];
                        $scope.dateInput = vendorId.split('$')[2]?vendorId.split('$')[2]:$scope.dateInput;
                        $scope.selectVendor();
                    }
                }
                if(res.data.message == "No records found"){
                    $scope.noVendorFound = true;
                }
            } else if (res.status == "FAILURE") {
                $scope.noVendorFound = true;
            } else {
                $scope.noVendorFound = true;
            }
        }, function(err) {

        });

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        $scope.users = [];
        $scope.groups = [];
        $scope.loadGroups = function() {
            return $scope.groups.length ? null : $http.get('/groups').success(function(data) {
                $scope.groups = data;
            });
        };

        $scope.showGroup = function(user) {
            if(user.group && $scope.groups.length) {
              var selected = $filter('filter')($scope.groups, {id: user.group});
              return selected.length ? selected[0].text : 'Not set';
            } else {
              return user.groupName || 'Not set';
            }
        };

        $scope.showStatus = function(user) {
            
            var selected = [];
            if (user) {
                if(user.itemName) {
                    // selected = $filter('filter')($scope.statuses, {value: user.items});
                    selected = user.itemName
                }
            }
            return user.itemName;
        };

        $scope.checkName = function(data, id) {
            // if (id === 2 && data !== 'awesome') {
            //   return "Username 2 should be `awesome`";
            // }
        };

        $scope.saveUser = function(data, id) {
            if (data.itemName == null) {
                // console.log("select item");
                // growl.error("Select an Item")
                $scope.errorDiable = true;
                return false;
            }else if(data.quantity == null ) {
                // console.log("selct quantity")
                // growl.error("Enter Quantity")
                $scope.errorDiable = true;
                return false;
            }else if(data.quantity <= 0){
                // growl.error("Invalid Quantity")
                $scope.errorDiable = true;
                return false;
            }else{
                console.log($scope.users)
                $scope.errorDiable = false;
                return true;
            }

            return true;
        };

          // remove user
        $scope.removeUser = function(index) {
            $scope.users.splice(index, 1);
        };

          // add user
        $scope.addUser = function() {
            $scope.inserted = {
                itemName : null,
                quantity : null
            };
            $scope.users.push($scope.inserted);
            $scope.errorDiable = true;

        };

        if($scope.users == null){
            $scope.errorDiable = true;
        }
        
        $scope.noItemsFound = true;
        $scope.selectVendor = function(){
            $scope.textAreaContent="";
            let itemsNames=[];
            $scope.itemsValidated=false;
            $scope.validatedItems=[];
            $scope.disableSubmit=false;
            $scope.selectedVendorItems={};
            $scope.productToVendor=[];
            // if(!$scope.clearanceModal){
                vendorService.retrievePricingForVendor($scope.selectedVendorId).then(function(res){
                console.log(res)
                if (res.status=="SUCCESS") {
                    if (res.data.items.length > 0) {
                        $scope.vendorItems_list = res.data.items;
                        console.log("$scope.vendorItems_list",$scope.vendorItems_list);
                        $scope.noItemsFound = false;
                        if(vendorId){
                            for(let i=0;i<itemsList.length;i++){
                                let quantity=itemsList[i].totalQuantity;
                                $scope.users.push({ itemName:itemsList[i].itemName,
                                                    quantity:Number(quantity.replace(/,/g, ''))})
                            }
                        }
                        if($scope.vendorItems_list && $scope.vendorItems_list.length>0){
                            $scope.vendorItems_list.forEach( item => {
                                if(item && item.itemName){
                                    $scope.textAreaContent+= item.itemName + ", ";
                                }
                            });
                        }
                    } else {
                        $scope.noItemsFound = true;
                        }
                    }
                })
            // }
             //    if($scope.clearanceModal){
                $scope.validatedItems=[];
                itemsList.forEach(elem => {
                    delete elem.lastClearedDate;
                    delete elem.select;
                });
                let obj={
                    warehouseId: warehouseId,
                    vendorId: $scope.selectedVendorId,
                    clearanceItems: itemsList
                };
                vendorService.retrieveValidatedItems(obj).then(function(res){
                    if (res.status=="SUCCESS") {
                        let i=0,j=0,k=0;
                        res.data.matchingItems.forEach((elem, idx) => {
                            if(elem.totalQuantity<=0){
                                k++;
                            }
                            let obj={product:null,vendorItems:null};
                            obj.product=elem.clearanceItem;
                            if(elem.vendorMatchingItems && elem.vendorMatchingItems.length>0){
                                elem.vendorMatchingItems.sort(function(a,b) {return (b.vendorPrice > a.vendorPrice) ? 1 : ((a.vendorPrice > b.vendorPrice) ? -1 : 0);} );
                                elem.selectedVendorItem= itemsList[idx].vendorItemName ? itemsList[idx].vendorItemName : elem.vendorMatchingItems[0].vendorItemName;
                                elem.selectedVendorPrice=itemsList[idx].price ? Number(itemsList[idx].price) : elem.vendorMatchingItems[0].vendorPrice; // coming in thousands
                                elem.currentVendorPrice=elem.vendorMatchingItems[0].vendorPrice; // Is not right!
                                elem.selectedVendorPriceUnit=itemsList[idx].uom ? itemsList[idx].uom : elem.vendorMatchingItems[0].uom;
                                elem.selectedTotalVendorPrice=itemsList[idx].totalPrice ? Number(itemsList[idx].totalPrice) : elem.vendorMatchingItems[0].totalVendorPrice;

                                if(elem.totalQuantity!==0){
                                    $scope.selectedVendorItems[elem.selectedVendorItem]=i;
                                    $scope.validatedItems.splice(i,0,elem);
                                    i++;
                                }
                                obj.vendorItems=elem.vendorMatchingItems;
                            } else {
                                if(elem.totalQuantity!==0){
                                    j++;
                                    $scope.validatedItems.push(elem);
                                }
                            }
                            elem.icon=$scope.categoryImages[elem.clearanceItem]?$scope.categoryImages[elem.clearanceItem]:$scope.categoryImages[elem.itemName];

                            //No duplicate products
                            if ($scope.productToVendor.filter(e => e.product === obj.product).length <= 0) {
                                $scope.productToVendor.push(obj);
                              }
                        });
                        // console.log("$scope.selectedVendorItems",$scope.selectedVendorItems,"$scope.validatedItems",$scope.validatedItems);
                        if(j>0 || $scope.validatedItems.length===0){ // || k>0
                            $scope.disableSubmit=true;
                        }
                        $scope.itemsValidated=true;
                        } else {
                            console.log("Error validatedItems:",res);
                        }

                        //Set stock for items in Clearance Modal
                        if($scope.clearanceModal) {
                            $scope.setOriginalQuantities(itemsList);
                        }

                    }).catch(function(err){
                        console.log("Error validatedItems:",err);
                    })
            // }
         //   }
        }

        $scope.getProductToVendorData = function() {

            let obj = {};
            obj.id = warehouseId;
            warehouseInventoryService.getWarehouseInventories(obj).then(function (res) {
               
               let  warehouseData = res.data.warehouseInventories[0].sales;
                for(let i = 0; i < warehouseData.length; i++) {

                    for( let j = 0; j < $scope.vendorItems_list.length; j++) {

                        let productUpdated = false;

                        if(warehouseData[i].itemName === $scope.vendorItems_list[j].productName) {

                            // When product already exists
                            $scope.productToVendor.forEach(pToV => {
                                if(warehouseData[i].itemName === pToV.product) {

                                    productUpdated = true;
                                    let vendorItemExists = false;
                                    pToV.totalQuantity = warehouseData[i].totalQuantity;
                                    pToV.vendorItems = (pToV.vendorItems !== null) ? pToV.vendorItems : [];
                                    let vendorItemsObj = {};
                                    // Check if VendorItem exists
                                    pToV.vendorItems.forEach(item => {
                                        if(item.vendorItemName === $scope.vendorItems_list[j].itemName) {
                                             vendorItemExists = true;
                                        }
                                    })
                                    if(!vendorItemExists) {
                                        vendorItemsObj.vendorItemName = $scope.vendorItems_list[j].itemName;
                                        vendorItemsObj.vendorPriceId = $scope.vendorItems_list[j].vendorPriceId;
                                        vendorItemsObj.vendorItemsId = $scope.vendorItems_list[j].vendorItemsId;
                                        vendorItemsObj.vendorPrice = Number($scope.vendorItems_list[j].vendorPrice);
                                        vendorItemsObj.uom = $scope.vendorItems_list[j].uom;
                                        pToV.vendorItems.unshift(vendorItemsObj);
                                    }
                                }
                            })

                            //When product does not exist
                            if(!productUpdated) {
                                let product = $scope.vendorItems_list[j].productName;
                                let totalQuantity = warehouseData[i].totalQuantity;
                                let vendorItems = [];
                                let vendorItemsObj = {};
                                vendorItemsObj.vendorItemName = $scope.vendorItems_list[j].itemName;
                                vendorItemsObj.vendorPriceId = $scope.vendorItems_list[j].vendorPriceId;
                                vendorItemsObj.vendorItemsId = $scope.vendorItems_list[j].vendorItemsId;
                                vendorItemsObj.vendorPrice = Number($scope.vendorItems_list[j].vendorPrice);
                                vendorItemsObj.uom = $scope.vendorItems_list[j].uom;
                                vendorItems.unshift(vendorItemsObj);
                                let obj = {}
                                obj.product = product;
                                obj.totalQuantity = totalQuantity;
                                obj.vendorItems = vendorItems;
    
                                $scope.productToVendor.unshift(obj);
                            }

                        }
                    }

                }
                $scope.setOriginalQuantities(warehouseData);

            })
        }


        $scope.setOriginalQuantities = function (warehouseData) {

            if ($scope.clearanceModal) {
                warehouseData.forEach(item => {
                    $scope.productToVendor.forEach(pToV => {

                        if (pToV.product === item.itemName) {
                            pToV.totalQuantity = item.totalQuantity ? item.totalQuantity : item.originalTotalQuantity;
                        }

                    })

                })
            }
            else {
                warehouseData.forEach(wData => {

                    $scope.validatedItems.forEach(item => {

                        if (item.clearanceItem === wData.itemName) {
                            item.originalTotalQuantity = wData.totalQuantity;
                        }

                    })
                })
            }
        }


        //Watch for date changes
        $scope.$watch('dateInput', function(newDate) {
            // console.log("$scope.dateInput", $scope.dateInput)
            $scope.date.startDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
            $scope.date.endDate = $moment($scope.dateInput).format("DD-MMM-YYYY");
            // console.log("$scope.date", $scope.date.startDate, $scope.date.endDate);
            // when date changes
        }, false);

        $scope.test = function (){
            $scope.errorDiable = false;
        };
        
        $scope.nextDate = function() {
            $scope.dateInput = $moment($scope.dateInput).add(1, "days").format("DD-MMM-YYYY");
            $scope.date = {
            startDate: $scope.dateInput,
            endDate: $scope.dateInput,
            };
        };
        
        $scope.previousDate = function() {
            $scope.dateInput = $moment($scope.dateInput).subtract(1, "days").format("DD-MMM-YYYY");
            $scope.date = {
            startDate: $scope.dateInput,
            endDate: $scope.dateInput,
            };
        };

        $scope.cancel = function() {
            $uibModalInstance.close('Close');
        };

        $scope.submitEditedSaleOrderData = function() {
            if(!($scope.dateInput) && !($scope.dateInput._d)){
                growl.error("Pickup Date Missing!");
                return;
            }

                        // Vendor Price validation
                        $scope.validatedItems.forEach((item,index) => {
                            if(item.modified){
                                if(!item.selectedVendorPrice){
                                    growl.error("VendorPrice cannot be null or 0 - deleted");
                                    item.totalQuantity = 0;
                                    item.selectedTotalVendorPrice = 0;
                                    $scope.validatedItems.splice(index, 1);
                                }
                                else {
                                    item.vendorMatchingItems.forEach(matchingItem => {
                                        if(item.selectedVendorItem === matchingItem.vendorItemName && item.selectedVendorPrice !== item.vendorPrice && item.selectedVendorPrice !== item.currentVendorPrice) {
                                            item.vendorPrice = item.currentVendorPrice = item.selectedVendorPrice;
                                            $scope.priceData = {
                                                "vendorId": vendorId,
                                                "vendorItemsId": matchingItem.vendorItemsId,
                                                "vendorPrice": matchingItem.selectedVendorPrice,
                                                "vendorPriceId": matchingItem.vendorPriceId
                                            };
                                    $scope.updateVendorPrice($scope.priceData);
            
                                        }
                                    })
                                }
                            }
                        })

            $scope.finalValidatedItemsList = [];
            $scope.validatedItems.forEach(function(element,index){

                $scope.finalValidatedItems = {};
                $scope.finalValidatedItems.itemName = element.selectedVendorItem;
                $scope.finalValidatedItems.quantity = element.totalQuantity;
                $scope.finalValidatedItems.unitPrice = element.selectedVendorPrice;
                $scope.finalValidatedItems.totalPrice = element.selectedTotalVendorPrice;

                element.vendorMatchingItems.forEach(matchingItem => {
                    if(element.selectedVendorItem === matchingItem.vendorItemName) {
                        $scope.finalValidatedItems.vendorItemsId = matchingItem.vendorItemsId;
                        $scope.finalValidatedItems.vendorPriceId = matchingItem.vendorPriceId;
                    }
                });

                $scope.finalValidatedItemsList.unshift($scope.finalValidatedItems);
                })
            $scope.inputData = {
                "saleOrderConfirmationId": $scope.saleOrderConfirmationId,
                "warehouseId" : warehouseId,
                "vendorId" : $scope.selectedVendorId,
                "pickUpDate" : $moment($scope.dateInput._d).format("DD-MMM-YYYY"),
                "vendorSaleOrderItems" : $scope.finalValidatedItemsList
            };
            console.log($scope.inputData)
            saleOrderService.createSaleOrder($scope.inputData).then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    let msg = "Sale Order Created";
                    if(res.data && res.data.message) {
                        msg=res.data.message;
                    }
                    growl.success(msg);
                    if($scope.saleOrderConfirmationId){
                        $global.setLocalItem("saleOrderId", $scope.saleOrderConfirmationId, true);
                        $uibModalInstance.close("Reload");
                    }
                    $uibModalInstance.close("Close");
                }
            })

        };

        // when delete icon is clicked to delete a row
        $scope.deleteItem=function(index,obj){
            if($scope.allowDelete && $scope.selectedVendorItems[obj.selectedVendorItem]>=0){
                delete $scope.selectedVendorItems[obj.selectedVendorItem];
            }
            if($scope.allowDelete){
                $scope.validatedItems.splice(index,1);
            }
        };

        $scope.splitItem=function(index,obj){
            if($scope.allowDelete){
                let modalInstance = $uibModal.open({
                templateUrl: 'views/split-quantity-modal.html',
                controller: 'SplitModalCtrl',
                size: "md",
                backdrop: 'static',
                resolve: {
                item: function() {
                            return obj;
                        }
                    }
                });

                modalInstance.result.then(function(){

                },function(enteredValue){
                    $scope.enteredValue=enteredValue;
                    let currentTotalQty=$scope.validatedItems[index].totalQuantity;
                    console.log("$scope.enteredValue",$scope.enteredValue)
                    if($scope.enteredValue && $scope.enteredValue!=="cancel"){
                        currentTotalQty-=$scope.enteredValue;
                        $scope.validatedItems[index].totalQuantity=currentTotalQty;
                        $scope.valueChanged(currentTotalQty,obj);
                        let objToAdd =  {   clearanceItem:obj.clearanceItem,
                                            totalQuantity: $scope.enteredValue,
                                            costOfScrap:0,
                                            vendorMatchingItems:null,
                                            selectedVendorPrice:0,
                                            selectedTotalVendorPrice:0
                                        };
                        $scope.validatedItems.splice(index+1,0,objToAdd);
                        $scope.itemSelected(objToAdd.clearanceItem,index+1);
                        objToAdd.totalQuantity = $scope.enteredValue
                    }
                });
            }
        }

        // when Edit button is clicked to edit the table
        $scope.allowEdit=function(tableform){
            $scope.disableSubmit=true;
            $scope.itemsSetCopy=[];
            angular.copy($scope.validatedItems,$scope.itemsSetCopy);
            $scope.allowDelete=true;
            tableform.$show();
        };

        // when Cancel button is clicked to cancel changes done in the table
        $scope.cancelChanges=function(tableform){
            $scope.validatedItems=$scope.itemsSetCopy;
            $scope.allowDelete=false;
            $scope.disableSubmit=false;
            $scope.selectedVendorItems={};
            for(let i=0;i<$scope.validatedItems.length;i++){
                if(!$scope.validatedItems[i].selectedVendorItem || !$scope.validatedItems[i].totalQuantity){
                    $scope.disableSubmit=true;
                }
                if($scope.validatedItems[i].selectedVendorItem){
                    $scope.selectedVendorItems[$scope.validatedItems[i].selectedVendorItem]=i;
                }
            }
            if($scope.validatedItems.length===0){
                $scope.disableSubmit=true;
            }
            tableform.$cancel();
        };

        // validation after changes in table are done and Save button is clicked
        $scope.Validate=function(tableform){
            // console.log("$scope.validatedItems",$scope.validatedItems);
            $scope.disableSubmit=false;
            $scope.errorDiable = false;
            let vendorId = $scope.vendorItems_list[0].vendorId;

            // // Vendor Price validation
            // $scope.validatedItems.forEach((item,index) => {
            //     if(item.modified){
            //         if(!item.selectedVendorPrice){
            //             growl.error("VendorPrice cannot be null or 0 - deleted");
            //             item.totalQuantity = 0;
            //             item.selectedTotalVendorPrice = 0;
            //             $scope.validatedItems.splice(index, 1);
            //         }
            //         else {
            //             item.vendorMatchingItems.forEach(matchingItem => {
            //                 if(item.selectedVendorItem === matchingItem.vendorItemName && item.selectedVendorPrice !== item.vendorPrice) {
            //                     item.vendorPrice = item.selectedVendorPrice;
            //                     $scope.priceData = {
            //                         "vendorId": vendorId,
            //                         "vendorItemsId": matchingItem.vendorItemsId,
            //                         "vendorPrice": matchingItem.selectedVendorPrice,
            //                         "vendorPriceId": matchingItem.vendorPriceId
            //                     };
            //             $scope.updateVendorPrice($scope.priceData);

            //                 }
            //             })
            //         }
            //     }
            // })
        
          //  console.log($scope.updateVendorPriceData);
            for(let i=0;i<$scope.validatedItems.length;i++){
                let obj=$scope.validatedItems[i];
                if(obj.totalQuantity===0){
                    if(obj.costOfScrap===0){
                        console.log("319",obj);
                        growl.error("Quantity cannot be 0 - Item Removed");
                    }
                    // $scope.disableSubmit=true;
                    if($scope.allowDelete && $scope.selectedVendorItems[obj.selectedVendorItem]>=0){
                        delete $scope.selectedVendorItems[obj.selectedVendorItem];
                    }
                    if($scope.allowDelete){
                        $scope.validatedItems.splice(i,1);
                    }
                    continue;
                }
                if(obj.totalQuantity > obj.originalTotalQuantity) {
                    growl.error("Total Quantity changed to total available quantity")
                    obj.totalQuantity = obj.originalTotalQuantity;
                }
                if(!obj.clearanceItem || obj.clearanceItem===''){
                    growl.error("Table contains invalid items - Item Removed");
                    obj.clearanceItem='empty';
                    obj.totalQuantity=0;
                    obj.costOfScrap=0;
                    obj.vendorMatchingItems=null;
                    obj.selectedVendorPrice=0
                    obj.selectedTotalVendorPrice=0;
                    if($scope.allowDelete && $scope.selectedVendorItems[obj.selectedVendorItem]>=0){
                        delete $scope.selectedVendorItems[obj.selectedVendorItem];
                    }
                    obj.selectedVendorItem=null;
                    if($scope.allowDelete){
                        $scope.validatedItems.splice(i,1);
                    }
                    continue;
                    // $scope.disableSubmit=true;
                }
                if(!$scope.validatedItems[i].selectedVendorItem){
                    growl.error("Table contains invalid items");
                    $scope.validatedItems[i].vendorMatchingItems=null;
                    $scope.disableSubmit=true;
                    $scope.errorDiable = true;
                }
                if(obj.totalQuantity<0.001){
                    if(obj.totalQuantity>0 && obj.totalQuantity<0.001){
                        growl.error("Quantity cannot be less than 1kg(0.001 tons)")
                    }
                    growl.error("Table contains invalid items");
                    $scope.disableSubmit=true;
                    $scope.errorDiable = true;
                }
            }
            if($scope.validatedItems.length===0){
                $scope.disableSubmit=true;
                $scope.errorDiable = true;
            }
            console.log("$scope.validatedItems",$scope.validatedItems);
            console.log("$scope.selectedVendorItems",$scope.selectedVendorItems);
            $scope.allowDelete=false;
            tableform.$hide();
            if(!$scope.disableSubmit){
                growl.success("Items Updated");
            }
            return true;
        };

        // called when a new row is added
        $scope.addItem=function(tableform){
            // $scope.disableSubmit=true;
            $scope.allowEdit(tableform);
            let obj = { clearanceItem:"",
                        icon:null,
                        totalQuantity:1,
                        costOfScrap:0,
                        vendorMatchingItems:null,
                        // vendorItemName:null,
                        selectedVendorPrice:0,
                        selectedTotalVendorPrice:0,
                        // vendorItemsId:true //to ask
                    };
            
            $scope.validatedItems.push(obj);
            // $scope.allowDelete=true;
            // tableform.$show();
        };

        // called when the totalQuantity in a row is entered
        $scope.valueChanged=function(input,obj){

            obj.originalTotalQuantity = obj.originalTotalQuantity ? obj.originalTotalQuantity : obj.totalQuantity;
            if(input===0){
                growl.error("Quantity cannot be 0");
                obj.costOfScrap=0;
                obj.selectedTotalVendorPrice=0;
                obj.isrowModified = true;
            }
            else if(input > obj.originalTotalQuantity) {
                growl.error("Quantity cannot be greater than the available stock: "+ obj.originalTotalQuantity + " (Kgs/units)");
            }
            else {
                if(!obj.costOfScrap) obj.costOfScrap=0;
                if(!obj.selectedTotalVendorPrice) obj.selectedTotalVendorPrice=0;
                obj.totalQuantity=input;
                obj.costOfScrap=$scope.unitPrices[obj.clearanceItem]*input;  //*1000; //amount only in 1000s
                obj.selectedTotalVendorPrice=input*obj.selectedVendorPrice;  //*1000;
                obj.isrowModified = true;
            }
        };

        // called when a Vendor item is changed from Vendor Item dropdown
        $scope.vendorItemChanged=function(obj,data,index){
            let oldValue=$scope.validatedItems[index].selectedVendorItem;
            obj.isrowModified = true;
            $scope.validatedItems[index].selectedVendorItem=data;
            console.log("403",obj,oldValue,$scope.selectedVendorItems[data],index);
            if($scope.selectedVendorItems[data]>=0 && $scope.selectedVendorItems[data]!==index){
                growl.error("Item Already Selected");
                delete $scope.selectedVendorItems[oldValue];
                obj.selectedVendorItem=null;
                obj.vendorMatchingItems=null;//fix
                obj.selectedVendorPrice=0;
                obj.selectedVendorPriceUnit=null;
                obj.selectedTotalVendorPrice=0;
                console.log("411",obj);
                return;
            } else if (data!==null) {
                console.log(data,"obj 414",obj,$scope.selectedVendorItems[oldValue]); //amount only in 1000s
                let cont=true,unitPrice=0,unitValue;
                for(let i=0;cont && i<obj["vendorMatchingItems"].length;i++){
                    if(obj["vendorMatchingItems"][i].vendorItemName===data){
                        unitPrice=obj["vendorMatchingItems"][i].vendorPrice;
                        unitValue=obj["vendorMatchingItems"][i].uom;
                        cont=false;
                    }
                }
                obj.selectedVendorPrice=unitPrice;
                obj.selectedVendorPriceUnit=unitValue.toLowerCase();
                obj.selectedTotalVendorPrice=unitPrice*obj.totalQuantity;  //*1000;
                obj.costOfScrap=$scope.unitPrices[obj.clearanceItem]*obj.totalQuantity;
                if($scope.selectedVendorItems[oldValue]===index){
                    delete $scope.selectedVendorItems[oldValue];
                }
                $scope.selectedVendorItems[data]=index;
            } else {
                obj.selectedVendorPrice=0;
                obj.selectedTotalVendorPrice=0;
            }
        };

        //Called when Vendor Price is changed
        $scope.vendorPriceChanged = function (obj, data) {
            console.log(obj);
            obj.modified = true;
            if(!data) {
                growl.error("Vendor Price cannot be 0 or null");
                obj.selectedTotalVendorPrice=0;
            } else {
                    obj.vendorMatchingItems.forEach(matchingItem => {
                        if(matchingItem.vendorItemName === obj.selectedVendorItem) {
                             obj.selectedVendorPrice = data;
                             matchingItem.selectedVendorPrice = data;
                        }
                    })
                if(!obj.selectedTotalVendorPrice) obj.selectedTotalVendorPrice=0;
                obj.selectedTotalVendorPrice = data*obj.totalQuantity;  //*1000;

            }

        }

             $scope.updateVendorPrice = function(priceData) {
                 if(!priceData) {
                    growl.error('Vendor Pricing data cannot be null');
                 }
             vendorService.updateVendorPricing(priceData).then(function (res) {
                console.log(res);
                if (res.status === "SUCCESS") {
                    growl.success("Vendor Price Updated Successfully.");
                   // $uibModalInstance.close('Close');
                } else {
                    growl.error("Failure while updating the price");
                }
            })
        }


        // called when a new Row is added and an Item is selected
        $scope.itemSelected=function(item,index){
            console.log("item 547",item,index,$scope.validatedItems.length-1);
            $scope.validatedItems[index].clearanceItem=item;
            $scope.validatedItems[index].icon=$scope.categoryImages[item]?$scope.categoryImages[item]:null;
            let cont=true,foundAt=-1;
            for(let i=0;cont && i<$scope.productToVendor.length;i++){
                if(item===$scope.productToVendor[i].product){
                    $scope.validatedItems[index].totalQuantity = $scope.productToVendor[i].totalQuantity ? $scope.productToVendor[i].totalQuantity : 0;
                    foundAt=i;
                    cont=false;
                }
            }
            if(foundAt===-1){
                $scope.validatedItems[index].vendorMatchingItems=null;
            } else {
                // index===$scope.validatedItems.length-1 &&
                if($scope.validatedItems[index].clearanceItem){
                    $scope.validatedItems[index].costOfScrap=$scope.unitPrices[item]*$scope.validatedItems[index].totalQuantity;  //*1000;
                    let allowedVendorItems=[];
                    if($scope.productToVendor[foundAt].vendorItems){
                            $scope.productToVendor[foundAt].vendorItems.forEach(elem => {
                            if(!($scope.selectedVendorItems[elem.vendorItemName]>=0)){
                                allowedVendorItems.push(elem);
                            }
                        });
                    } else {
                        allowedVendorItems=null;
                    }
                    $scope.validatedItems[index].vendorMatchingItems=allowedVendorItems;
                } else{
                    $scope.validatedItems[index].vendorMatchingItems=$scope.productToVendor[foundAt].vendorItems;
                }
            }
        };

        $scope.submitValidatedItems = function(){
            // console.log("productToVendor",$scope.productToVendor,$scope.dateInput);

                        // Vendor Price validation
                        $scope.validatedItems.forEach((item,index) => {
                            if(item.modified){
                                if(!item.selectedVendorPrice){
                                    growl.error("VendorPrice cannot be null or 0 - deleted");
                                    item.totalQuantity = 0;
                                    item.selectedTotalVendorPrice = 0;
                                    $scope.validatedItems.splice(index, 1);
                                }
                                else {
                                    item.vendorMatchingItems.forEach(matchingItem => {
                                        if(item.selectedVendorItem === matchingItem.vendorItemName && item.selectedVendorPrice !== item.vendorPrice && item.selectedVendorPrice !== item.currentVendorPrice) {
                                            item.vendorPrice = item.currentVendorPrice = item.selectedVendorPrice;
                                            $scope.priceData = {
                                                "vendorId": vendorId,
                                                "vendorItemsId": matchingItem.vendorItemsId,
                                                "vendorPrice": matchingItem.selectedVendorPrice,
                                                "vendorPriceId": matchingItem.vendorPriceId
                                            };
                                    $scope.updateVendorPrice($scope.priceData);
            
                                        }
                                    })
                                }
                            }
                        })

            $scope.disableSubmit = true;
            if(!($scope.dateInput) && !($scope.dateInput._d)){
                growl.error("Pickup Date Missing!");
                return;
            }
            let obj={
                warehouseId:warehouseId,
                vendorId:$scope.selectedVendorId,
                pickUpDate:$moment($scope.dateInput._d).format("DD-MMM-YYYY"),
                vendorSaleOrderItems:[]
            }
            $scope.validatedItems.forEach(elem => {
                let saleOrderItem={},vendorItemObj;
                for(let i=0;i<$scope.productToVendor.length;i++){
                    if(elem.clearanceItem===$scope.productToVendor[i].product){
                        vendorItemObj=$scope.productToVendor[i].vendorItems;
                        break;
                    }
                }
                
                for(let j=0;j<vendorItemObj.length;j++){
                    if(elem.selectedVendorItem===vendorItemObj[j].vendorItemName){
                        saleOrderItem.itemName=vendorItemObj[j].vendorItemName;
                        saleOrderItem.vendorItemsId=vendorItemObj[j].vendorItemsId;
                        saleOrderItem.vendorPriceId=vendorItemObj[j].vendorPriceId;
                        break;
                    }
                }
                saleOrderItem.unitPrice=elem.selectedVendorPrice;
                saleOrderItem.quantity=elem.totalQuantity;
                saleOrderItem.totalPrice=elem.selectedTotalVendorPrice;
                obj.vendorSaleOrderItems.push(saleOrderItem);
            });
            console.log("vendor items to send",obj);

            vendorService.generateSaleOrder(obj).then(function(res){
                console.log("submitted",res)
                    if (res.status=="SUCCESS") {
                        growl.success("Sale Order Generated");
                        $uibModalInstance.close('Reload');
                    } else {
                        if(res && res.data && res.data.message){
                            growl.error(res.data.message);
                        } else if(res && res.error && res.error.message){
                            growl.error(res.error.message);
                        } else {
                            growl.error("Failed to create sale order");
                        }
                    }
                }).catch(function(err){
                    growl.error("Failed to create sale order");
                    console.log("Failed to create sale order",err);
                })
        };

        //No custom dimensions
        // $scope.loaded = function(){
        //     console.log("window.innerWidth",window.innerWidth);
        //     if(window.innerWidth >= 992 && !($scope.clearanceModal) && !($scope.saleOrderConfirmationId)){
        //         let modal=$('.modal-dialog')[0];
        //         modal.style.width = 900 + 'px';
        //         return;
        //     }
        //     if(window.innerWidth >= 778 && !($scope.clearanceModal) && !($scope.saleOrderConfirmationId)){
        //         let modal=$('.modal-dialog')[0];
        //         modal.style.width = 500 + 'px';
        //         return;
        //     }
        // }
    });
