'use strict';

/**
 * Called in the following scenarios
 *
 * 1. when appointment is deleted from transaction section of the agent module
 * 2. when appointment is deleted from appointments section of apartments module
 * 3. when appointment is deleted from appointments section of appointments module
 * 4. when appointment is deleted from appointments section of consumers module
 * 5. not being called but being added in wholesalers.js
 */
angular.module('scrapQcrmApp')
  .controller('DeleteCtrl', function($scope, $global, authentication, $uibModalInstance, items, SellNow, growl, apartments) {
    
    $global.displayLog('Inside DeleteCtrl ');
    $global.displayLog('items DI value', 'items', items);
    
    /*======================================================default init===========================================================*/
    (function() {
      $scope.items = items;
      console.log($scope.items)
      $scope.confirmationIdVM = items.confirmationId;
      $scope.items.agentObjId = items.agentObjId;
    })();
    /*======================================================core functions================================================*/
    $scope.decline = function() {
      var obj = {
        'sellObjId': items.sellObjId,
        'agentObjId': items.agentObjId,
      };
      SellNow.declineRequest(obj)
        .then(function(res) {
          if (res.status === "SUCCESS") {
            growl.success(res.data.message);
            $scope.ok(res);
          } else if (res.status === "FAILURE" && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl.error("Failed to delete, only ASSIGNED_TO_AGENT, DECLINED_BY_AGENT and CANCELLED_BY_CONSUMER are allowed");
          }
        })
        .catch(function(err) {
          console.error('Error in declining sell request ', err);
        })
    };
    
    $scope.cancelSell = function() {
      if ($scope.items.confirmationId) {
        var id = $scope.items.confirmationId;
        $scope.items.agentObjId = true;
        SellNow.cancelSellRquests(id)
          .then(function(res) {
            if (res.status === "SUCCESS") {
              // $scope.todayAppointment.splice(index, 1);
              growl.success(res.data.message);
              $scope.ok(res);
            } else if (res.status === "FAILURE") {
              growl.error(res.error.errors[0].message);
            }
          })
          .catch(function(err) {
            console.error('Error in cancelling sell appointment ', err);
          })
      }
      if ($scope.items.communityAppointmentId && !$scope.items.confirmationId) {
        apartments.cancelAppointment($scope.items.communityAppointmentId)
          .then(function(res) {
            if (res.status === "SUCCESS") {
              // $scope.todayAppointment.splice(index, 1);
              growl.success(res.data.message);
              $scope.ok(res);
            } else if (res.status === "FAILURE") {
              growl.error(res.error.errors[0].message);
            }
          })
          .catch(function(err) {
            console.error('Error in cancelling apartment appointment ', err);
          })
      }
      
    };
    
    $scope.cancelSellRecurring = function() {
      var id = $scope.items.confirmationId;
      if (confirm("Are you sure you want to delete all recurring appointments following " + id + "?")) {
        $scope.items.agentObjId = true;
        SellNow.cancelSellRquestsRecurring(id)
          .then(function(res) {
            if (res.status === "SUCCESS") {
              // $scope.todayAppointment.splice(index, 1);
              growl.success(res.data.message);
              $scope.ok(res);
            } else if (res.status === "FAILURE") {
              growl.error(res.error.errors[0].message);
            }
          })
          .catch(function(err) {
            console.error('Error in cancelling cancelSellRquestsRecurring() ', err);
          })
      }
    };
    
    /*======================================================helper functions=============================================*/
    $scope.ok = function(res) {
      $uibModalInstance.close(res);
    };
    
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    
  });
