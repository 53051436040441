'use strict';

/**
 * Only one place this being called, reschedule action button in transaction section of the agent module
 */
angular.module('scrapQcrmApp')
  .controller('ReschduleCtrl', function($scope, $uibModalInstance, $moment, $global, authentication, SellNow, $state, growl, items) {
    /*==============================================loggers=================================================================*/
    $global.displayLog('Inside ReschduleCtrl');
    $global.displayLog('DI value for items', 'items', items);
    
    /*==============================================default init=================================================================*/
    $scope.reschedule = items;
    $scope.vm = {
      opened: false,
    };
  
    (function() {
      SellNow.getSlots()
        .then(function(res) {
          $scope.allslots = res.data;
          $scope.slots = res.data.presentDaySlots;
          for (var i = $scope.slots.length - 1; i >= 0; i--) {
            if ($scope.slots[i].status !== "Disabled") {
              $scope.reschedule.preferredSlot = $scope.slots[i].slotId;
              break;
            }
          }
          $scope.dateOptions = {};
          if (!$scope.reschedule.preferredSlot) {
            $scope.slots = res.data.allSlots;
            $scope.reschedule.preferredSlot = $scope.slots[0].slotId;
            var date = new Date();
            $scope.dateOptions.minDate = $scope.reschedule.preferredDate = date.setDate(date.getDate() + 1);
          } else {
            $scope.dateOptions.minDate = $scope.reschedule.preferredDate = new Date();
          }
        })
        .catch(function(err) {
          console.error('Error in getting slot information ', err);
        })
    })();
    
    /*==============================================core functions=================================================================*/
    $scope.submitReschedule = function() {
      $scope.reschedule.preferredDate = $moment($scope.reschedule.preferredDate).format('DD-MMM-YYYY');
      SellNow.reschedule($scope.reschedule)
        .then(function(res) {
          if (res.status === 'SUCCESS' && res.data && res.data.message) {
            growl.success(res.data.message);
            $scope.ok('ok');
          } else if (res.status === 'FAILURE' && res.error && res.error.message) {
            growl.error(res.error.message);
          } else {
            growl.error('Failed to reschedule');
          }
        })
        .catch(function(err) {
          console.error('Error in rescheduling the appointment ', err)
        })
    };
   
    /*==============================================helper functions=================================================================*/
    $scope.ok = function(data) {
      $uibModalInstance.close(data);
    };
  
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  
    $scope.openDatePicker = function() {
      $scope.vm.opened = true;
    };
  });
