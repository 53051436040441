'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.products
 * @description
 * # products
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
    .factory('products', function(httpService, $global, $rootScope) {
        // Service logic
        // ...

        //var meaningOfLife = 42;

        // Public API here
        $rootScope.loginData = {};
        $rootScope.loginData = $global.getLocalItem("loginData", true);
        return {
            getProducts: function() {
                var city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().productsWithCity.replace(":city",city);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getProductsForConsumer: function(consumerid) {
                var url = $global.getApiUrl() + $global.getApiObject().products + "/" + consumerid;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            getProductsForCommunitySell: function(communityAddressId) {
                var url = $global.getApiUrl() + $global.getApiObject().productsForCommunitySell + "/" + communityAddressId;
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            addProduct: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addProducts;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            editProduct: function(id) {
                var url = $global.getApiUrl() + $global.getApiObject().editProduct.replace(":id", id);
                var $request = httpService.httpRequest(url, "G", "");
                return $request;
            },
            updateProduct: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().updateProducts;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            priceUpdate: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().priceUpdate;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            addCustomPrice: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().addCustomPrice;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            editCustomPrice: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().editCustomPrice;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            deleteCustomPrice: function(data) {
                data.city = $rootScope.loginData.city;
                var url = $global.getApiUrl() + $global.getApiObject().deleteCustomPrice;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getProductWisePurchaseReports: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().getProductWisePurchaseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            geProductWiseSalesReports: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().geProductWiseSalesReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },
            getConsolidatedProductWiseReports: function(data) {
                var url = $global.getApiUrl() + $global.getApiObject().getConsolidatedProductWiseReports;
                var $request = httpService.httpRequest(url, "P", data);
                return $request;
            },

        };
    });
