'use strict';
/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:ApartmentUpdateCtrl
 * @description
 * # ApartmentUpdateCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('ApartmentUpdateCtrl', function ($window, $timeout, consumer, $scope, $global, growl, authentication, $uibModalInstance, apartments, items, NgMap) {

        $scope.data = {
            numberOfBlocks: 0
        };
        $scope.data.blocksList = [];
        $scope.params = items;
        // apartments.getApartmentById(items.apartmentId).then(function (res) {
        //     if (res.status == "SUCCESS") {
        //         $scope.items = res.data.apartment;
        //         console.log($scope.items)
        //     } else if (res.status == "FAILURE") {
        //         $scope.noApartmentFound = "No apartment found";
        //     }

        // }, function (err) {
        //     console.log(err);
        // })

        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };
        $scope.addBlocks = function (communityId) {
            $scope.data.blocksList.forEach(function (obj) {
                obj["communityId"] = communityId;
                consumer.addBlocks(obj).then(function (res) {
                    if (res.status == "SUCCESS") {
                        growl.success("Block " + obj.blockName + " Added Successfully.");
                    } else {
                        growl.error("Failure while Adding " + obj.blockName + " Block");
                    }
                })
            });
            $uibModalInstance.dismiss();
        }
        $scope.updateBlock = function () {
            consumer.updateBlocks($scope.params.blockData).then(function (res) {
                if (res.status == "SUCCESS") {
                    growl.success("Block " + $scope.params.blockData.blockName + " Updated Successfully.");
                } else {
                    growl.error("Failure while Updating " + $scope.params.blockData.blockName + " Block");
                }
                $uibModalInstance.dismiss();
            })
        }
        $scope.blocksChanged = function () {
            if ($scope.data.numberOfBlocks > $scope.data.blocksList.length) {
                for (let i = $scope.data.blocksList.length; i < $scope.data.numberOfBlocks; i++) {
                    $scope.data.blocksList.push({});
                }
            } else {
                $scope.data.blocksList.length = $scope.data.numberOfBlocks;
            }
        }
        $scope.save = function () {


            if ($scope.UpdateApartmentForm.$invalid) {
                growl.error("Please enter valid inputs before saving");
                return;
            }

            if ($scope.params.actionType == "ADD") {
                console.log($scope.data)
                $scope.addBlocks($scope.params.communityId);
                growl.success("Community Blocks Created successfully");

            }
            if ($scope.params.actionType == "EDIT") {
                console.log($scope.params.blockData)
                $scope.updateBlock();
            }

        };
    });
