'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:MakePaymentCtrl
 * @description
 * # MakePaymentCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('MakePaymentCtrl', function($scope, $rootScope, paymentType, $moment,$uibModalInstance, SellNow, items, $global, $uibModal, DTOptionsBuilder, $window, paymentService, growl, $log , $stateParams) {
    	
        $scope.data = {}; 

        $scope.cancel = function(res) {
            $uibModalInstance.dismiss(res);
            console.log(res)
        };

        console.log(paymentType)
        $scope.otpStatus = false;
        $scope.paymentType = paymentType;
        paymentService.getWallets().then(function(res){
            $scope.noWalletFound = false;
            console.log(res)
            if (res.status == "SUCCESS") {
                $scope.wallets = res.data.walletVMS;
            }else if (res.status == "FAILURE") {
                $scope.noWalletFound = true;
            }
        })  
   
        $scope.data = {};
        $scope.consumerDetails = items;   
        $scope.makePayment = function(){
            console.log($scope.data.walletGuid)
            SellNow.payToConfirmationId($scope.consumerDetails.confirmationId, $scope.paymentType).then(function(res){
                console.log(res)
                if (res.status == "SUCCESS") {
                    if (res.data && res.data.payment) {
                        if (res.data.payment.finalStatus == "SUCCESS") {
                            rowl.success("Payment done successfully!");
                            $uibModalInstance.close();
                        }
                    }
                } else {
                    growl.error("Failed to do payment");
                }
            })
        };
        
        $scope.makePaymentTWallet = function(){
            console.log("OTP",$scope.data.otp);
            if (!$scope.otpStatus) {
                paymentService.twalletOtp($scope.consumerDetails.confirmationId).then(function(res){
                    console.log(res);
                    if (res.status == "SUCCESS") {
                        $scope.otpStatus = true;
                    }
                })
            }
            if ($scope.otpStatus) {
                paymentService.twalletOtpPayment($scope.consumerDetails.confirmationId, $scope.data.otp).then(function(res){
                    console.log(res);
                    if (res.status == "SUCCESS") {
                        if (res.data) {
                            if (res.data.payment) {
                                if (res.data.payment.finalStatus == "Success") {
                                    growl.success("Payment done successfully!");
                                    $uibModalInstance.close();
                                }else{
                                    growl.error("Failed to do payment");
                                }
                            }
                        }
                    } else{
                        growl.error("Failed to do payment");
                    }
                })
            }
        };
    });