'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:ProductsCtrl
 * @description
 * # ProductsCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('ReoPricingCtrl', function ($scope, products, ngTableParams, $state, $stateParams, $global, $uibModal, consumer, envService, editableOptions, growl, pricingService) {
        // $scope.customerPriceEnabled = false;


        // (function() {
        //     if (true) {
        // console.log("hitting the reo pricing controller....")

        //====================================
        // ag-grid init
        (function () {
            var columnDefs = [
                {
                    headerName: "Name",
                    field: "name",
                    // minWidth:195,
                    cellStyle: { 'text-align': 'left', "padding-left": '100px' }
                },
                {
                    headerName: "Description",
                    field: "description",
                    resizable: true,
                    // minWidth: 150,
                    cellStyle: { 'text-align': 'left', "padding-left": '90px' }
                },
                {
                    headerName: "Number of Agents",
                    field: "agentsAssigned",
                    cellStyle: { 'text-align': 'center','color': "#449c45",'text-decoration': 'underline' }
                },
                {
                    headerName: "Date of Creation",
                    field: "createdOn",
                    cellStyle: { 'text-align': 'center' }
                    // hide:true
                },
                {
                    headerName: "",
                    field: "0",
                    cellRenderer: ActionCellRenderer,
                    reresizable: false,
                    maxWidth: 30,
                    skipHeader: true,
                    // pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
                },
                {
                    headerName: "",
                    field: "1",
                    cellRenderer: DeleteCellRenderer,
                    resizable: false,
                    maxWidth: 30,
                    skipHeader: true,
                    // pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'color': "#449c45" }
                },
                {
                    headerName: "",
                    field: "2",
                    cellRenderer: defaultPriceCellRenderer,
                    resizable: false,
                    maxWidth: 50,
                    skipHeader: true,
                    // pinned:'right',
                    // outerWidth: '60px',
                    cellStyle: { 'height': '100%', 'display': 'flex', 'padding': '0', 'color': "#449c45" }
                },
            ];

            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    // minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true
                },
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                paginationPageSize: 1000,
                // paginationAutoPageSize:true,
                rowData: rowData,
                suppressCsvExport: true,
                suppressExcelExport: true,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    //   params.api.sizeColumnsToFit();
                    //   params.columnApi.autoSizeAllColumns();
                    $scope.gridOptions.api.setRowData($scope.pricing_list_agent);
                },
                onCellClicked: function (event) {
                    console.log("check what are the things", event)
                    if (event && event.data && event.column.colId == "0") {
                        $scope.editPricingCapsule('AGENT', event.rowIndex, event.data.id);
                    }
                    if (event && event.data && event.column.colId == "agentsAssigned") {
                        $scope.redirectToReos(event.data.id);
                    }
                    if (event && event.data && event.column.colId == "1") {
                        $scope.deletePriceCapsule(event.rowIndex, event.data);
                    }
                    if (event && event.data && event.column.colId == "2" && !event.data.default) {
                        $scope.markDefaultPriceCapsule(event.rowIndex, event.data, 'AGENT');
                    }
                },
                //   onFirstDataRendered: function (params) {
                //       params.api.sizeColumnsToFit();
                //   },
            };
        })();

        function ActionCellRenderer() {
        }

        ActionCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                $scope.actionButtonText = "Pay";
                $scope.actionButtonClassList = "";
                // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

                let iconsDiv = `<i class="md md-mode-edit"></i>`;
                this.eGui.innerHTML = iconsDiv;
            }
        };
        ActionCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function DeleteCellRenderer() {
        }

        DeleteCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.style.textAlign = 'center';
            console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                $scope.actionButtonText = "Pay";
                $scope.actionButtonClassList = "";
                // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

                let iconsDiv = `<i class="md md-delete"></i>`;
                this.eGui.innerHTML = iconsDiv;
            }
        };
        DeleteCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };

        function defaultPriceCellRenderer() {
        }

        defaultPriceCellRenderer.prototype.init = function (params) {
            this.eGui = document.createElement('div');
            this.eGui.setAttribute('data-toggle','tooltip');
            this.eGui.setAttribute('tooltip-popup-delay', '0');
            this.eGui.setAttribute('data-placement', 'top');
            this.eGui.style.textAlign = 'center';
            this.eGui.style.width = '100%';
            console.log("what are the params", params);
            if (params.value !== "" || params.value !== undefined || params.value !== null) {
                $scope.actionButtonText = "Pay";
                $scope.actionButtonClassList = "";
                let iconsDiv;
                if (params.data.default) {
                    iconsDiv = `<i class="md md-check" style="margin: 0 auto"></i>`;
                    this.eGui.setAttribute('title','The default Price Capsule!')
                } else {
                    iconsDiv = `<i class="md md-beenhere"  style="margin: 0 auto"></i>`;
                    this.eGui.setAttribute('title','Set as default Price Capsule?')
                }
                this.eGui.innerHTML = iconsDiv;
            }
        };
        defaultPriceCellRenderer.prototype.getGui = function () {
            return this.eGui;
        };


        $scope.editPricingCapsule = function (pricingcategory, index, id) {
            const modalInstance = $uibModal.open({
                templateUrl: 'views/product-pricing-modal.html',
                controller: 'ProductPricingModalCtrl',
                size: "lg",
                resolve: {
                    items: function () {
                        if (true) {
                            return 'EDIT+' + JSON.stringify($scope.pricing_list_agent[index]);
                        } else {
                            return 'EDIT+' + JSON.stringify($scope.pricing_list_wholesaler[index]);
                        }
                    },
                    role: function () {
                        return pricingcategory;
                    }
                }
            });

            modalInstance.result.then(function () {
                reloadPage();
            }, function () {
                reloadPage();
            });

        };


        $scope.redirectToReos = function (data) {
            console.log("price capsule's data", data);
            $state.go("root.agents", { priceCapsuleId: data });
        };

        $scope.deletePriceCapsule = function (index, row) {
            console.log(index, row, "in delete");
            if (confirm("Are you sure you want to delete " + row.name + "?")) {
                pricingService.deletePricingCapsule(row.id)
                    .then(function (res) {
                        if (res.status === "SUCCESS") {
                            growl.success("Deleted Successfully");
                            reloadPage();
                        } else {
                            if (res && res.error && res.error.errors) {
                                let errors = res.error.errors;
                                for (let i = errors.length - 1; i >= 0; i--) {
                                    $scope[errors[i].code] = errors[i].message;
                                    growl.error(errors[i].message)
                                }
                                if (res.error.errors.length === 0) {
                                    growl.error(res.error.message);
                                }
                            }
                        }
                    })
                    .catch(function (err) {
                        console.error('Error in deleting pricing capsule', err);
                    });
            }
        };

        // add pricing open modal for agent, wholesalers
        $scope.addPricingCapsule = function (pricingcategory) {
            const modalInstance = $uibModal.open({
                templateUrl: 'views/product-pricing-modal.html',
                controller: 'ProductPricingModalCtrl',
                size: "lg",
                resolve: {
                    items: function () {
                        return 'ADD';
                    },
                    role: function () {
                        return pricingcategory;
                    }
                }
            });

            modalInstance.result.then(function () {
                reloadPage();
            }, function () {
                reloadPage();
            });
        };


        // mark default pricing capsule
        $scope.markDefaultPriceCapsule = function (index, row, userType) {
            if (confirm("Are you sure you want to set " + row.name + " as default?")) {
                $global.displayLog('marking default price capsule ', 'row', row);
                pricingService.markDefaultPriceCapsule(row.id, userType)
                    .then(function (res) {
                        if (res && res.status === "SUCCESS" && res.data && res.data.message) {
                            growl.success(row.name + " set as default");
                            reloadPage();
                        } else {
                            if (res && res.error && res.error.errors) {
                                let errors = res.error.errors;
                                for (let i = errors.length - 1; i >= 0; i--) {
                                    $scope[errors[i].code] = errors[i].message;
                                    growl.error(errors[i].message)
                                }
                                if (res.error.errors.length === 0) {
                                    growl.error(res.error.message);
                                }
                            }
                        }
                    })
                    .catch(function (err) {
                        console.error("Error in marking default ", err);
                    })
            }
        };
        //====================================
        const type = 'AGENT';
        pricingService.getPricingCapsule(type)
            .then(function (res) {
                if (res.status === "SUCCESS") {
                    if (res && res.data && !res.data.results) {
                        $scope.pricing_list_agent = [];
                        return;
                    }
                    $scope.pricing_list_agent = res.data.results;
                    if ($scope.gridOptions && $scope.gridOptions.api) {
                    $scope.gridOptions.api.setRowData($scope.pricing_list_agent);
                    }
                    console.log(res.data.results, "agent table data")
                }
            })
            .catch(function (err) {
                console.error('Error in getting agent pricing details ', err);
            })
        //     }
        //   })();

        $scope.editedCustomerPrice;
        pricingService.retrieveCustomPrice(0).then(function (res) {
            try {
                res.data.customPrice.forEach(element => {
                    if (element.createdOn != '') {
                        element.createdOn = moment(element.createdOn).format('YYYY-MMM-DD');
                    }
                });
            } catch (e) {
                console.log(e);
            }

            if (res.status == "SUCCESS") {
                if (res.data) {
                    if (res.data.customPrice.length > 0) {
                        $scope.customPrice_list = res.data.customPrice;
                    } else {
                        $scope.noCustomPriceList = true;
                    }
                }
            }
        })


        $scope.changeSort = function (type) {
            if (type === "consumerName") {
                $scope.consumerNameSort = !$scope.consumerNameSort;
                $scope.sortType = "consumerName";
                $scope.sortValue = $scope.consumerNameSort;
            }
            if (type === "categoryName") {
                $scope.catNameSort = !$scope.catNameSort;
                $scope.sortType = "catName";
                $scope.sortValue = $scope.catNameSort;
            }
            if (type === "productName") {
                $scope.productNameSort = !$scope.productNameSort;
                $scope.sortType = "productName";
                $scope.sortValue = $scope.productNameSort;
            }
            if (type === "customPrice") {
                $scope.customPriceSort = !$scope.customPriceSort;
                $scope.sortType = "customPrice";
                $scope.sortValue = $scope.customPriceSort;
            }
            if (type === "createdOn") {
                $scope.createdOnSort = !$scope.createdOnSort;
                $scope.sortType = "createdOn";
                $scope.sortValue = $scope.createdOnSort;
            }
        };

        $scope.editCustomPrice = function (price) {
            $scope.editedCustomerPrice = price.customPriceId;
        }

        $scope.saveCustomPrice = function (id) {
            console.log(id);
        }

    // helper function to refresh the current page
    function reloadPage() {
        setTimeout(function () {
          $state.go('root.reopricing', { type: $scope.showType }, { reload: true });
        }, 1500);
    }
    });