'use strict';

angular.module('scrapQcrmApp')
  .service('holiday', function(httpService, $global, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      addHoliday: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().addHoliday;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      addAreaHoliday: function(data) {
        data.city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().addAreaHoliday;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      getHoliday: function(date, count) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getHoliday + "?date=" + date + "&count=" + count + "&city=" + city;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAreaHoliday: function(date, areaId) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getAreaHoliday + "?date=" + date + "&areaId=" + areaId + "&city=" + city;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAreaHolidayForEdit: function(date, areaId) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getAreaHolidayForEdit + "?date=" + date + "&areaId=" + areaId + "&city=" + city;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getAreaHolidaysForConsumer: function(addressId) {
        var url = $global.getApiUrl() + $global.getApiObject().getAreaHolidaysForConsumer.replace(":addressId", addressId);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      changeAppointmentDateAndSendSMS: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().changeAppointmentDateAndSendSMS;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      },
      deleteHoliday: function(date, city) {
        var url = $global.getApiUrl() + $global.getApiObject().deleteHoliday.replace(":date", date).replace(":city", city).replace(":recurringId", "null");
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      deleteAreaHoliday: function(date, areaId) {
        var url = $global.getApiUrl() + $global.getApiObject().deleteAreaHoliday.replace(":date", date).replace(":areaId", areaId).replace(":recurringId", "null");
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }
    };
  });
