'use strict';

//$dialogue
angular.module('scrapQcrmApp')
    .controller('RouteMapCtrl', function($scope, $rootScope, $window, ngToast, agentService, SellNow, $moment, BoardService, BoardDataFactory, graphhopperService, agentAppointments, $timeout, $global, $q, Geocoder, Directions, Crossfilter) {

  $scope.Math = window.Math;

  $scope.isShowAll = true;
  $scope.data = {};

  $scope.offset = 1;

  function toast(message) {
    ngToast.create({
      content: '<a href="#" class="">' + message + '</a>',
      dismissButton: true,
      timeout: 10000
    });
  }

  $scope.accordian = true;

  var tomorrow  = $moment(new Date()).add(1,'days');

  $scope.vm = {
    params: {"city": $rootScope.loginData.city,"singledayquery": true,"todate":$moment(tomorrow).format('DD-MMM-YYYY'),"fromdate":$moment(tomorrow).format('DD-MMM-YYYY'),"page":-1}
  };

  $scope.agentArr = [];
  $scope.tempAgentArr = [];

  SellNow.allRequests($scope.vm.params).then(function(res){
    console.log(res)
    res.data.sells.forEach(function(element, index){
      var template = {
          "name":"",
          "userId":"",
          "address":{}
        };
      if ($scope.tempAgentArr.indexOf(element.agentName) === -1 && element.agentName != "") {
          $scope.tempAgentArr.push(element.agentName)
          template.name = element.agentName;
          template.userId = element.agentObjId;
          template.address = element.agentAddress;
          $scope.agentArr.push(template);
        }
      
    });
  });
  console.log($scope.agentArr)

  $scope.itemsMoved = true;

  $scope.userSearchPlaceholder = "Please Enter Agent Name";
   $scope.selectedUser = false;

  $scope.user = {
      "type": "AGENT"
    };

  // $scope.start = new google.maps.LatLng(-39.419, 175.57);
  // $scope.end = new google.maps.LatLng(-39.419, 175.57);
  $scope.waypoints = [];

  $scope.Math = window.Math;

  $scope.notimeconstraint = false;

  // loadUser($scope.user.type);
  $scope.search = '';

  function initMapWithoutCenter(){
      $scope.map = new google.maps.Map(document.getElementById('map1'), mapOptions);
  }

  var directionsDisplay;
  var directionsService = new google.maps.DirectionsService();
  var map;

  function initialize() {
      directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
      var myOptions = {
          zoom: 17,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          center: new google.maps.LatLng(17.3944809,78.4417806)
      }
      $scope.map = new google.maps.Map(document.getElementById("map1"), myOptions);
      directionsDisplay.setMap($scope.map);
      // calc`Route();
  }

  function calcRoute() {
      var waypts = [];
      directionsDisplay.set('directions', null);
      console.log(directionsDisplay)
      stop = new google.maps.LatLng(-39.419, 175.57);
      waypts.push({
          location:stop,
          stopover:true});


      var start  = $scope.start;
      var end = $scope.end;
      var request = {
          origin: start,
          destination: end,
          waypoints: $scope.waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.DirectionsTravelMode.WALKING
      };
      directionsService.route(request, function(response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
              var route = response.routes[0];

          }
      });
  }

  var routedatastruct = {
  "configuration": {
    "routing": {
      "calc_points": true,
      "consider_traffic": true
    }
  },
  "objectives": [
    {
      "type": "min",
      "value": "transport_time"
    }
  ],
  "vehicles": [
    {
      "vehicle_id": "",
      "start_address": {
        "location_id": "",
        "lon": 78.3891043,
        "lat": 17.4709115
      },
      "return_to_depot": false,
      "type_id": "default_type",
      "earliest_start": 1530838800
    }
  ],
  "vehicle_types": [
    {
      "type_id": "",
      "profile": "car"
    }
  ],
  "services": [

    ]
  };

var routedata = JSON.parse(JSON.stringify(routedatastruct));

var agentData = null;

$scope.kanbanBoard = BoardService.kanbanBoard(BoardDataFactory.kanban);


function initializeAgents(data, timepadding, agentname, isOrderChange) {


  if (!isOrderChange){
    $scope.kanbanBoard = {};
    $scope.kanbanBoard = BoardService.kanbanBoard(BoardDataFactory.kanban);
  }

  if (!timepadding) {
    timepadding = "00:00:00"
  }
  toast("Started optimization for : " + agentname + " with " + timepadding + " hour deviation");

  routedata = JSON.parse(JSON.stringify(routedatastruct));
  $scope.routingcomplete = false;
  $scope.failure = false;

      data.forEach(function (elem, idx) {
        var temp_service = {
        "id": "SQ00019109",
        "type": "pickup",
        "name": "Ramakrishna",
        "address": {
          "location_id": "5b337c72769d7d4990ff5529",
          "name": "Sanjeeva Reddy Nagar",
          "lon": 78.4398365760447,
          "lat": 17.44304222055501,
          "street_hint": "Sanjeeva"
        },
        "duration": 2800,
        "time_windows": [

        ],
        "required_skills": [],
        "allowed_vehicles": [],
        "disallowed_vehicles": [],
        "priority": 1,
        "preparation_time": 900
      }
      console.log(elem)
        var address = elem.consumerAddress.formattedAddress;
        var slot = elem.preferredSlot;
        var confirmationid = elem.confirmationId;
        var name = elem.consumerName;
        var category = elem.consumerAddress.categories[0]['value'];

        var datestringstart = elem.preferredDate + " " + elem.preferredSlot.split("-")[0] + " +5:30";
        var datestringend = elem.preferredDate + " " + elem.preferredSlot.split("-")[1] + " +5:30";

        //15-Jul-2018 14:00f

        var momentstart = $moment(datestringstart, 'DD-MMM-YYYY HH:mm ZZ');
        var momentend = $moment(datestringend, 'DD-MMM-YYYY HH:mm ZZ');

        var momentagentstart = $moment(datestringend, 'DD-MMM-YYYY HH:mm ZZ');

        var moment_timepadding = moment.duration(timepadding || "00:00:00");

        var offset = moment.duration("05:30:00");

        temp_service['id'] = confirmationid;
        temp_service['name'] = name;
        temp_service['address']['location_id'] = elem.consumerAddress.addressId;
        temp_service['address']['name'] = elem.consumerAddress.formattedAddress;
        temp_service['address']['lon']= parseFloat(elem.consumerAddress.longitude);
        temp_service['address']['lat'] = parseFloat(elem.consumerAddress.latitude);
        temp_service['address']['street_hint'] = elem.consumerAddress.formattedAddress;

        if ($scope.notimeconstraint == false) {
          temp_service['time_windows'].push({
            "earliest": momentstart.subtract(moment_timepadding).unix(),
            "latest": momentend.add(moment_timepadding).unix()
          })

          // temp_service['time_windows'][0]['earliest'] =
          // temp_service['time_windows'][0]['latest'] = momentend.unix();
        }
        temp_service['duration'] = 2800;

        routedata.services.push(temp_service);

        $scope.kanbanBoard.columns.forEach(function(col, idx2) {

          var tempcard = {
                     "title": "",
                     "details": "",
                     "newTime": "",
                     "oldTime": "",
                     "hasError": "",
                     "needChange": false,
                     "newSlot": ""
                   }

          if (col.name == slot) {
            tempcard.title = confirmationid;
            tempcard.details = name;
            tempcard.oldTime = slot;
            $scope.kanbanBoard.columns[idx2].cards.push(tempcard);
          }
        })
        // $scope.addLocation(address);
      })
      $scope.firstBoard = angular.copy($scope.kanbanBoard);
}

$scope.solutionpercentage = 0;

$scope.attempt = 0;

$scope.useBoard = function(index){
  console.log(index)
  console.log($scope.solnArr[index].routes[0].activities)
  console.log($scope.kanbanBoard.columns)
  
  if($scope.countAttempt >= 4){
    $scope.solnArr[index].routes[0].activities.forEach(function(elem, idx) {    
      $scope.kanbanBoard.columns.forEach(function(elem2, idx3){
        elem2.cards.forEach(function(elem3, idx4){
          if (elem3.title == $scope.solnArr[index].routes[0].activities[idx]["id"]) {
            $scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime'] = moment.unix(elem.arr_time).format("HH:mm");
            if (!ifTimeIsBetweenRange($scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime'],
              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['oldTime'])) {
              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['needChange'] = true;
              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['newSlot'] = findSlot($scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime']);
            }
          }
        })
      })
      console.log($scope.kanbanBoard.columns) 
    });
  }

};

function ifTimeIsBetweenRange(time, range) {
  var format = 'hh:mm'
  try {
    var time = moment(time,format),
      beforeTime = moment(range.split("-")[0], format),
      afterTime = moment(range.split("-")[1], format);
      if (time.isBetween(beforeTime, afterTime)) {
        return true;
      } else {
        return false;
      }
  } catch(e) {
    return true;
  }
}

function findSlot(time) {
  var format = 'hh:mm';
  var time = moment(time,format);
  var all_slots = ['06:00-08:00', '08:00-10:00', '10:00-12:00', '12:00-14:00', '14:00-16:00', '16:00-18:00']

  var final_slot = "";

  for (var i = 0; i < all_slots.length; i++) {
    var elem = all_slots[i];
    var beforeTime = moment(elem.split("-")[0], format),
        afterTime = moment(elem.split("-")[1], format);

    if (time.isBetween(beforeTime, afterTime)) {
      final_slot = elem;
      return final_slot;
    } else {
    }
  }
  return "empty";

}

$scope.solnArr = [];

var markerDetails= {};

initialize();
$scope.attempt = 0;

function graphhopperSolve(routedata, solveone) {
  console.log("Attempt : ",$scope.attempt," Rotue Data : " ,routedata)
  graphhopperService.submitProblem(routedata).then(function(res){
    console.log(res)
        if(res.job_id != null){

          setTimeout(function (){
            graphhopperService.getResults(res.job_id).then(function(res){
              console.log("RES::::::0",res);
              if(res != null){
                $scope.routingcomplete = true;
                $scope.solution = res.solution;
                try {
                  $scope.countAttempt += 1;
                  if ($scope.attempt == 0) {
                    $scope.solution.tableHeading = "Exact Timings";
                  }else if ($scope.attempt == 1) {
                    $scope.solution.tableHeading = "Timings extended by two hours";
                  }else if ($scope.attempt == 2) {
                    $scope.solution.tableHeading = "Timings extended by four hours";
                  }else if ($scope.attempt == 3) {
                    $scope.solution.tableHeading = "Auto suggested timings";
                  }
                  $scope.solution.routes[0].activities.forEach(function(elem, idx) {
                    if (idx != 0 ) {
                      var cid = $scope.solution.routes[0].activities[idx].id;
                      $scope.solution.routes[0].activities[idx].slot = jsonSell[cid].preferredSlot;
                    }
                    $scope.solution.routes[0].activities[idx].arr_date_time = moment.unix(elem.arr_time).format("HH:mm");
                    $scope.solution.routes[0].activities[idx].end_date_time = moment.unix(elem.end_time).format("HH:mm");
                    routedata.services.forEach(function(value,pos){
                        if (value.id == elem.id) {
                          $scope.solution.routes[0].activities[idx].name = value.name;
                        }
                    });
                  });
                  if (!solveone) {
                    $scope.solution.attempt = $scope.attempt.toString();
                    $scope.solnArr.push($scope.solution);
                  }

                  if ($scope.solution.unassigned.services.length > 0 ) {
                    toast("Attempt " + $scope.attempt + " has incomplete solution");
                    $scope.unassignedArr = [];
                    $scope.solution.unassigned.services.forEach(function(val,idexx){

                      var templte = {"connfirmationId":"","slot":""};
                      templte.connfirmationId = val;
                      templte.slot = jsonSell[val].preferredSlot;
                      $scope.unassignedArr.push(templte);
                    })
                    $scope.solution.unassignedArr = $scope.unassignedArr;
                    
                    if (!solveone){
                      
                      throw "Too big";
                    }
                  }
                  $scope.solution.routes[0].activities.forEach(function(elem, idx) {    
                    if (idx == 0) {
                      console.log("START::::",elem);
                      $scope.start = new google.maps.LatLng(elem.address.lat, elem.address.lon);
                      var markerStart = new MarkerWithLabel({
                        position: $scope.start,
                        map:$scope.map,
                        labelContent: "Start",
                        labelClass: "labels-start",
                        icon: {
                          url:"https://1x1px.me/FF4D00-0.8.png",
                          scale: 0
                        },
                        size: new google.maps.Size(0, 0),
                        draggable:false,
                        optimized: false,
                        visible: true
                      });
                      markerStart.setMap($scope.map);
                    } else if (idx == $scope.solution.routes[0].activities.length -1 ) {
                      console.log("END::::",elem);
                      $scope.end = new google.maps.LatLng(elem.address.lat, elem.address.lon);
                      var markerEnd = new MarkerWithLabel({
                        position:$scope.end,
                        map:$scope.map,
                        draggable: false,
                        labelContent: elem.id + '<span>&emsp;&emsp;&emsp;</span>' +moment.unix(elem.arr_time).format("HH:mm") +'<br /><br />'+ elem.name.substring(0,16)+'...',
                        labelClass: "labels",
                        icon: {
                          url:"https://1x1px.me/FF4D00-0.8.png",
                          scale: 0
                        },
                        size: new google.maps.Size(0, 0),
                        optimized: false,
                        visible: true
                      });
                      markerEnd.setMap($scope.map);
                    } else {
                      $scope.kanbanBoard.columns.forEach(function(elem2, idx3){
                        elem2.cards.forEach(function(elem3, idx4){
                          if (elem3.title == $scope.solution.routes[0].activities[idx]["id"]) {
                            $scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime'] = moment.unix(elem.arr_time).format("HH:mm");
                            if (!ifTimeIsBetweenRange($scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime'],
                              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['oldTime'])) {
                              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['needChange'] = true;
                              $scope.kanbanBoard.columns[idx3]['cards'][idx4]['newSlot'] = findSlot($scope.kanbanBoard.columns[idx3]['cards'][idx4]['newTime']);
                            }
                          }
                        })
                      })
                      console.log($scope.kanbanBoard.columns) 
                      var marker = new MarkerWithLabel({
                        position: new google.maps.LatLng(elem.address.lat, elem.address.lon),
                        map:$scope.map,
                        draggable: false,
                        labelContent: elem.id + '<span>&emsp;&emsp;&emsp;</span>' +moment.unix(elem.arr_time).format("HH:mm") +'<br /><br />'+ elem.name.substring(0,16)+'...',
                        labelClass: "labels",
                        icon: {
                          url:"https://1x1px.me/FF4D00-0.8.png",
                          scale: 0
                        },
                        size: new google.maps.Size(0, 0),
                        optimized: false,
                        visible: true
                      });
                      marker.setMap($scope.map);
                      $scope.waypoints.push({
                        location: new google.maps.LatLng(elem.address.lat, elem.address.lon),
                      stopover:true});
                    }
                  });

                    calcRoute();
                    console.log($scope.solnArr)

                  if (solveone) {
                      $scope.solution.tableHeading = "Exact Timings";
                      $scope.solution.attempt = $scope.attempt.toString();
                      $scope.tempSoln = $scope.solution;
                      $scope.solnArr[0] = angular.copy($scope.tempSoln);
                      return;
                    }
                } catch (e) {
                  console.log(e)
                  $scope.failure = true;
                  switch($scope.attempt){
                    case 0:
                      toast("Attempt " + $scope.attempt + " failed")
                      $scope.selectAgent(false,agentData, "1:00:00");
                      $scope.attempt += 1 ;
                      $scope.failure = false;
                      $scope.countAttempt += 1;
                      break;
                    case 1:
                      toast("Attempt " + $scope.attempt + " failed");
                      $scope.selectAgent(false,agentData, "2:00:00");
                      $scope.attempt += 1 ;
                      $scope.failure = false;
                      $scope.countAttempt += 1;
                      break;
                    case 2:
                      toast("Attempt " + $scope.attempt + " failed")
                      $scope.attempt += 1 ;
                      $scope.notimeconstraint = true;
                      $scope.selectAgent(false,agentData);
                      $scope.failure = false;
                      $scope.countAttempt += 1;
                      break;
                  }
                }

                var mapOptions = {
                    zoom: 17,
                    mapTypeId: 'roadmap'
                };

                // initialize();
                // initMapWithoutCenter();
              }
            }, function(err){
            })
          }, 1000)


        }
      }, function(err){});
}

  var jsonSell={};
  
  $scope.selectAgent = function(bool, val, timepadding, isOrderChange){
    if (bool == true) {
      $scope.solnArr = [];
      $scope.attempt = 0;
      $scope.waypoints = [];
      // directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
      if(directionsDisplay != null) {
          directionsDisplay.setMap(null);
          directionsDisplay = null;
      }
      initialize();
      $scope.start = new google.maps.LatLng($scope.dropdownvalue.address.latitude,$scope.dropdownvalue.address.longitude)
      
      console.log("IINNNN IIIIIIIFFFFFFFFFFF")
    }
      val = $scope.dropdownvalue;
      if (!isOrderChange) {
        isOrderChange = false;
      }
      agentData = val;
      $scope.data.agentId = val.userId;
      $scope.selectedUser = val.name;
      $scope.search = $scope.selectedUser;
      console.log(val)
      console.log($scope.selectedUser)
      // {
      //   "vehicle_id": "kantesh",
      //   "start_address": {
      //     "location_id": "kantesh-home",
      //     "lon": 78.3891043,
      //     "lat": 17.4709115
      //   },
      //   "return_to_depot": false,
      //   "type_id": "default_type",
      //   "earliest_start": 1530838800
      // }

      routedata.vehicles[0]["vehicle_id"] = $scope.selectedUser.replace(/\s/g, "_");
      routedata.vehicle_types[0]["type_id"] = $scope.selectedUser.replace(/\s/g, "_");
      routedata.vehicles[0]["earliest_start"] = tomorrow.set({hour:6,minute:0,second:0,millisecond:0}).unix();
      routedata.vehicles[0]["start_address"]["location_id"] = $scope.selectedUser.replace(/\s/g, "_") + "_home";
      routedata.vehicles[0]["start_address"]["lon"] = parseFloat(val.address.longitude);
      routedata.vehicles[0]["start_address"]["lat"] = parseFloat(val.address.latitude);

      $scope.kanbanSortOptions = {

        //restrict move across columns. move only within column.
        /*accept: function (sourceItemHandleScope, destSortableScope) {
         return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
         },*/
        itemMoved: function (event) {
          $scope.itemsMoved = false;
          event.source.itemScope.modelValue.status = event.dest.sortableScope.$parent.column.name;

          console.log($scope.kanbanBoard.columns)
          console.log(routedata.services)
          $scope.kanbanBoard.columns.forEach(function(elemnt,index){
            if(elemnt.cards.length > 0 ){
              elemnt.cards.forEach(function(elemnt1,indx1){
                routedata.services.forEach(function(element, inndex){
                  if (elemnt1.title == element.id) {
                    var slotTime = elemnt.name ;
                    if(slotTime != ""){
                      var startSlot =slotTime.split("-")[0];
                      var endSlot = slotTime.split("-")[1];
                      var momentend = $moment(endSlot, 'DD-MMM-YYYY HH:mm ZZ');
                      element.time_windows[0]['earliest'] = tomorrow.set({hour:startSlot.split(":")[0],minute:startSlot.split(":")[1]}).unix();
                      element.time_windows[0]['latest'] = tomorrow.set({hour:endSlot.split(":")[0],minute:endSlot.split(":")[1]}).unix();
                      
                    }
                  }
                });
              });
            }
          });
          console.log(routedata)
          graphhopperSolve(routedata, true);
        },
        orderChanged: function (event) {
        },
          containment: '#board'
      };

      $scope.removeCard = function (column, card) {
          BoardService.removeCard($scope.kanbanBoard, column, card);
      };

      $scope.addNewCard = function (column) {
          BoardService.addNewCard($scope.kanbanBoard, column);
      }

      // getLocation($scope.user.type);
      agentAppointments.getTomorrowAgentAppointments($scope.data.agentId, tomorrow.format('DD-MMM-YYYY')).then(function (appointmentdata){
          var data = appointmentdata.data.sells;
          
          data.forEach(function(elnt,idex){
            
            var jsonTemp = {
                              "agentObjId": "",
                              "agentName": "",
                              "agentMobileNo": "",
                              "consumerMobileNo": "",
                              "consumerName": "",
                              "consumerObjId": "",
                              "preferredDate": "",
                              "preferredSlot": "",
                              "preferredSlotId": 0,
                              "requestedDate": "",
                              "requestedTime": "",
                              "sellObjId": "",
                              "status": "",
                              "totalAmount": "",
                              "totalQuantity": "",
                              "type": ""
                            };
            var confirmnID = elnt.confirmationId;
            jsonTemp.agentObjId = elnt.agentObjId;
            jsonTemp.agentName = elnt.agentName;
            jsonTemp.agentMobileNo = elnt.agentMobileNo;
            jsonTemp.consumerMobileNo = elnt.consumerMobileNo;
            jsonTemp.consumerName = elnt.consumerName;
            jsonTemp.consumerObjId = elnt.consumerObjId;
            jsonTemp.preferredDate = elnt.preferredDate;
            jsonTemp.preferredSlot = elnt.preferredSlot;
            jsonTemp.preferredSlotId = elnt.preferredSlotId;
            jsonTemp.requestedDate = elnt.requestedDate;
            jsonTemp.requestedTime = elnt.requestedTime;
            jsonTemp.sellObjId = elnt.sellObjId;
            jsonTemp.status = elnt.status;
            jsonTemp.totalAmount = elnt.totalAmount;
            jsonTemp.totalQuantity = elnt.totalQuantity;
            jsonTemp.type = elnt.type;

            jsonSell[confirmnID] = jsonTemp;
          });
          initializeAgents(data, timepadding, val.name, isOrderChange);

          graphhopperSolve(routedata, false);
      });
  }

var sellOrderData = [];

  $scope.setSellOrderData = function() {
      var order = 0 ;
      $scope.kanbanBoard.columns.forEach(function (elemnt, indx) {
        if($scope.kanbanBoard.columns[indx].cards.length > 0 ){
          $scope.kanbanBoard.columns[indx].cards.forEach(function(elemnt1,indx1){
            var orderData = {
              "confirmationId":"",
              "order":0,
              "preferredDate":"",
              "preferredSlot":0
            };
            order += 1;
            orderData.confirmationId = $scope.kanbanBoard.columns[indx].cards[indx1].title;
            orderData.order = order;
            orderData.preferredDate = tomorrow.format();
            orderData.preferredSlot = indx;
            sellOrderData.push(orderData);
          });
        }
      });

      var requestData = {};
      requestData['sellOrderVMS'] = sellOrderData;
      requestData['agentId'] = $scope.data.agentId;

      SellNow.sellOrder(requestData).then(function(res){
        if (res.status == "SUCCESS") {
          $scope.itemsMoved = true;
          toast("Order Saved Scucessfully");
        }else{
          toast("Failure in saving order");
          $scope.itemsMoved = false;
        }
      })
  };

  $scope.setBoard = function(){
    $scope.kanbanBoard = angular.copy($scope.firstBoard);
  }
  function loadUser(type){
    if(type == "AGENT"){
      agentService.usersLoadAll(type).then(function(res){
        if(res.status == "SUCCESS"){

          $scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
        }
      }, function(err){});
    }else{
      wholesalerService.usersLoadAll(type).then(function(res){
        if(res.status == "SUCCESS"){
          $scope.$ngc = new Crossfilter(res.data.users, 'name', 'persistent');
        }
      }, function(err){});
    }
  }

  var mapOptions = {
    zoom: 13,
    mapTypeId: 'roadmap'
  }

  function getCurrentLocation(){
    $global.getCurrentLocation().then(function(res){
      if(res){
        $scope.map.setCenter({lat: res.latitude, lng: res.longitude});
      }
    }, function(err){
      console.log(err);
    })
  }

  // $scope.map = new google.maps.Map(document.getElementById('map1'), mapOptions);
  getCurrentLocation();

  $scope.alerts = [];
  $scope.markers = [];
  $scope.legs = [];
  $scope.locationText = {value: ''};

  $scope.tripInfo = {
    duration: null,
    distance: null
  };

  $scope.mapOptions = {
    center: new google.maps.LatLng(50, 0),
    zoom: 9,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };


  // $scope.items = ["Abc", "Def", "ghi"];

  $scope.dragControlListeners = {
      // accept: function (sourceItemHandleScope, destSortableScope) {return boolean},
      itemMoved: function (event) {console.log(event)},
      orderChanged: function(event) {},
      containment: '#board',
      clone: false,
      allowDuplicates: false
  };

  // $scope.dragControlListeners1 = {
  //     containment: '#board',
  //     allowDuplicates: false,
  //     clone: false
  // };

  $scope.toggleAccordion = function(){
    var elem = angular.element(document.querySelector('.toggleAccordions'));
    elem.removeClass('show');
  }


  // Center the map based on the user's current location
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      $scope.map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
      return $scope.$apply();
    }
    , function(error) {
      $scope.alerts.push("We weren't able to determine your current location.");
      return $scope.$apply();
    });
  }

  // Remove an alert
  return $scope.closeAlert = index => $scope.alerts.splice(index, 1);
});
