"use strict";

angular
  .module("scrapQcrmApp")
  .controller("TransportInventoryModalCtrl", function($scope, $filter, $http, $state, $sce, $uibModalInstance, $rootScope, $global, ngToast, products, $uibModal, $window, SellNow, growl, $log, warehouseService, wholesalerService, $stateParams, transferRequestData, localStorageService, DTOptionsBuilder, DTColumnDefBuilder, $moment, $timeout) {
    
    $scope.editRequest = !!(transferRequestData && transferRequestData.from === "Transport");
    var data = [], apiData, list = [];
    // $scope.warehouse_list_copy=[];
    $scope.toWarehouse = {warehouse: null};
    
    $scope.opts = {
      format: "DD-MMM-YYYY",
      singleDatePicker: true
    };
    $scope.dateInput = {val: $moment().format("DD-MMM-YYYY")};
    $scope.date = {
      startDate: $scope.dateInput,
      endDate: $scope.dateInput
    };
    
    function getHierarchy() {
      wholesalerService.getOrgChartData()
        .then(function(res) {
          if (res.status === "SUCCESS") {
            if (res.data && res.data.payoutUsers && res.data.payoutUsers.length > 0) {
              apiData = res.data.payoutUsers;
              data.push({
                ownerId: 0,
                userId: apiData[0].userId,
                userType: apiData[0].userType,
                userName: apiData[0].userName,
                userMobile: apiData[0].userMobile
              });
              createNewNode(apiData[0]);
              searchAndReassign();
            } else {
              growl.success("No records found")
            }
          } else {
            growl.error("Invalid Id to get Chart")
          }
      })
      .catch(function(err) {
        growl.error("No heirarchy records found")
      })
    }
    
    function createNewNode(arr) {
      if (Array.isArray(arr.children)) {
        arr.children.forEach(child => {
          let obj = {};
          obj.userId = child.userId;
          obj.userType = child.userType;
          obj.userName = child.userName;
          obj.userMobile = child.userMobile;
          obj.ownerId = child.ownerId;
          data.push(obj);
          createNewNode(child);
          if (obj.userName === $scope.warehouseAgent || obj.userId === $scope.warehouseAgent) {
            $scope.warehouseAgent = obj.ownerId;
            list.push(obj.userName);
          }
        });
      }
    }
    
    function searchAndReassign() {
      let newWarehouseList = [];
      $scope.warehouse_list.forEach(warehouse => {
        if (list.indexOf(warehouse.warehouseName) >= 0 || list.indexOf(warehouse.warehouseTypeValue) >= 0) {
          newWarehouseList.push(warehouse);
        }
      });
      list = [];
      data = [];
      $scope.warehouseAgent = null;
      $scope.warehouse_list = newWarehouseList;
      $scope.isDisabled = false;
    }
    
    function checkWarehouseTypes(clicked) {
      let agentWarehouses = [], typeWholesaler = false, mfWarehouses = [], typeMF = false;
      for (let i = 0; i < $scope.warehouse_list.length; i++) {
        if ($scope.warehouse_list[i].warehouseType === "Agent") {
          agentWarehouses.push(i);
          if ($scope.warehouse_list[i].warehouseId === $rootScope.loginData.warehouseId || $scope.warehouse_list[i].warehouseId === clicked) {
            $scope.warehouseAgent = $scope.warehouse_list[i].warehouseTypeValue;
            $scope.isDisabled = true;
            break;
          }
        }
        if ($scope.warehouse_list[i].warehouseId === $rootScope.loginData.warehouseId || $scope.warehouse_list[i].warehouseId === clicked) {
          if ($scope.warehouse_list[i].warehouseType === "Wholesalers") {
            typeWholesaler = true;
            localStorageService.remove('transportInvParams'); // don't need params as there's no back button
          }
          if ($scope.warehouse_list[i].warehouseType === "MasterFranchise") {
            typeMF = true;
          }
        } else {
          if ($scope.warehouse_list[i].warehouseType === "MasterFranchise") {
            mfWarehouses.push(i);
          }
        }
      }
      if ($scope.warehouseAgent) {
        getHierarchy();
      }
      
      if (typeMF && mfWarehouses.length > 0) {
        // append mf and agent arrays
        agentWarehouses.push(...mfWarehouses);
      }
      agentWarehouses.sort((a, b) => a - b);
      if ((typeMF || typeWholesaler) && agentWarehouses.length > 0) {
        for (let i = agentWarehouses.length - 1; i >= 0; i--) {
          $scope.warehouse_list.splice(agentWarehouses[i], 1);
        }
      }
    }
    
    // when routed from inventory reports > warehouse inventory > initiate transport button
    (function() {
      if (transferRequestData && transferRequestData.initiateTransportInventoryItems) {
        
        if (transferRequestData.from === "Transport") {
          $scope.confirmationId = transferRequestData.initiateTransportInventoryItems.confirmationId;
          $scope.editRequest = true;
        }
        
        let initiateTransportInventoryItems = transferRequestData.initiateTransportInventoryItems;
        let selectedWarehouseId = initiateTransportInventoryItems.selectedWarehouseId;
        $scope.dateInput.val = initiateTransportInventoryItems.pickUpDate ? initiateTransportInventoryItems.pickUpDate : $scope.dateInput.val;
        
        warehouseService.getProfile(selectedWarehouseId)
          .then(function(res) {
            if (res.status === "SUCCESS" && res.data && res.data.warehouse) {
              initiateTransportInventoryItems.warehouse = res.data.warehouse;
              
              // open the details page
              $scope.warehouseRedirected = initiateTransportInventoryItems.warehouse;
              
              if (initiateTransportInventoryItems.transportInventoryItems && initiateTransportInventoryItems.transportInventoryItems.length > 0) { // empty array init for the table
                $scope.Categories = [];
                $scope.errorDiable = false;
                for (let i = 0; i < initiateTransportInventoryItems.transportInventoryItems.length; i++) {
                  
                  let transportInventoryItem = initiateTransportInventoryItems.transportInventoryItems[i];
                  let categoryName = transportInventoryItem.itemName;
                  let quantity = transportInventoryItem.totalQuantity;
                  let buyPrice = transportInventoryItem.totalCost;
                  
                  let data = {};
                  data.categoryName = categoryName;
                  data.quantity = quantity;
                  data.buyPrice = buyPrice;
                  $scope.Categories.push(data);
                }
              } else {
                growl.error("Empty inventory items")
              }
            } else {
              console.error("Unable to get warehouse object", res);
            }
          })
          .catch(function(err) {
            console.error("Unable to get warehouse object", err);
          })
      }
    })();
    
    (function() {
      $scope.warehouseItemsListLoading = true;
      //   $scope.showWarehouseItems = false;
      
      $scope.selectedWarehouse = null; //FIXME handle this variable to get the reload button working
      
      $scope.errorDiable = true;
      $scope.Categories = [];
    })();
    
    (function() {
      if ($rootScope.loginData.role === "WAREHOUSEUSER") {
        warehouseService.getProfile($rootScope.loginData.warehouseId)
          .then(function(res) {
            let currentWarehouse = res.data.warehouse;
            warehouseService.getWarehouse(-1)
              .then(function(res) {
                if (res.status === "SUCCESS" && res.data.warehouses.length > 1) {
                  $scope.warehouse_list = res.data.warehouses;
                  if (currentWarehouse) {
                    $scope.addTransportInventory(currentWarehouse);
                  }
                } else {
                  $scope.noWarehouseFound = true
                }
              })
              .catch(function(err) {
                console.error("Failed to get warehouse list ", err)
              })
            
          })
          .catch(function(err) {
            console.error("Failed to get warehouse ", err)
          });
      } else {
        warehouseService.getWarehouse(-1)
          .then(function(res) {
            if (res.status === "SUCCESS" && res.data.warehouses.length > 1) {
              $scope.warehouse_list = res.data.warehouses;
              angular.copy($scope.warehouse_list, $scope.warehouse_list_copy);
              if ($scope.warehouseRedirected) {
                $scope.addTransportInventory($scope.warehouseRedirected);
              }
            } else {
              $scope.noWarehouseFound = true
            }
          })
          .catch(function(err) {
            console.error("Failed to get warehouse list ", err)
          })
      }
    })();
    
    // when save button inside edit window is clicked
    $scope.saveCategory = function(data, index, category) {
      if (data.categoryName == null) {
        growl.error("Select an Item");
        $scope.Categories[index].categoryName = null;
        $scope.errorDiable = true;
        return false
      } else if (data.quantity == null) {
        growl.error("Select a Quantity");
        $scope.Categories[index].quantity = null;
        $scope.errorDiable = true;
        return false
      } else if (data.quantity <= 0) {
        growl.error("Select a Quantity");
        $scope.Categories[index].quantity = null;
        $scope.errorDiable = true;
        return false
      } else {
        let count = 0;
        for (let category of $scope.Categories) {
          if (category.categoryName === data.categoryName && count !== index) {
            growl.error("Item Already Selected");
            $scope.Categories[index].categoryName = null;
            $scope.Categories[index].quantity = null;
            $scope.errorDiable = true;
            return false
          }
          count++;
        }
        $scope.errorDiable = false;
        return true;
      }
    };
    
    //Watch for date changes
    $scope.$watch('dateInput.val', function(newDate) {
      $scope.date.startDate = $moment($scope.dateInput.val._d).format("DD-MMM-YYYY");
      $scope.date.endDate = $moment($scope.dateInput.val._d).format("DD-MMM-YYYY");
      
      // when date changes
    }, false);
    
    $scope.nextDate = function() {
      $scope.dateInput = $moment($scope.dateInput).add(1, "days").format("DD-MMM-YYYY");
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput,
      };
    };
    
    $scope.previousDate = function() {
      $scope.dateInput = $moment($scope.dateInput).subtract(1, "days").format("DD-MMM-YYYY");
      $scope.date = {
        startDate: $scope.dateInput,
        endDate: $scope.dateInput,
      };
    };
    
    // remove user
    $scope.removeCategory = function(index) {
      $scope.Categories.splice(index, 1);
      if ($scope.Categories.length === 0) {
        $scope.errorDiable = true
      }
      if ($scope.Categories.length !== 0) {
        $scope.errorDiable = false
      }
    };
    
    // add user
    $scope.addCategory = function() {
      $scope.inserted = {
        categoryName: null,
        quantity: null,
        buyPrice: null
      };
      $scope.Categories.push($scope.inserted);
      $scope.errorDiable = true;
    };
    
    $scope.showStatus = function(category, data) {
      let selected = [];
      if (category) {
        if (category.categoryName) {
          // selected=$filter("filter")($scope.statuses, { value: user.items })
          selected = category.categoryName
        }
      }
      
      category.categoryName = data;
      return category.categoryName;
    };
    
    $scope.calculateBuyPrice = function(data, category) {
      let ppu = 0;
      if ($scope.distinctCategories && $scope.distinctCategories.length > 0 && category && category.categoryName) {
        for (let i = 0; i < $scope.distinctCategories.length; i++) {
          let selectedCategory = $scope.distinctCategories[i];
          if (selectedCategory.categoryName === category.categoryName) {
            ppu = selectedCategory.pricePerUnit;
            break;
          }
        }
        category.buyPrice = ppu * data;
      }
    };
    
    $scope.checkFromToWarehouse = function(selectedWarehouse) {
      $scope.addTransportFormData.toWarehouseId = selectedWarehouse.warehouseId;
      if ($scope.addTransportFormData.toWarehouseId === $scope.addTransportFormData.fromWarehouseId) {
        growl.error("From and To Warehouse Cannot Be Same");
        $scope.addTransportFormData.toWarehouseId = null;
        $scope.toWarehouse.warehouse = null;
        $scope.errorDiable = true;
      } else {
        if ($scope.Categories.length > 0) {
          $scope.errorDiable = false;
          for (let category of $scope.Categories) {
            if (category.categoryName === null || category.quantity === null) {
              $scope.errorDiable = true;
              break;
            }
          }
        }
      }
    };
    
    $scope.refreshButtonClicked = function(warehouse) {
      //   $scope.showWarehouseItems = false;
      $scope.addTransportFormData = {};
      $scope.selectedWarehouse = null;
      $scope.fromWareHouseName = null;
      $scope.addTransportFormData.fromWarehouseId = null;
      $scope.distinctCategories = null;
      $scope.Categories = [];
      $scope.errorDiable = true;
      $scope.addTransportInventory(warehouse);
    };
    
    $scope.addTransportInventory = function(warehouse) {
      $scope.toWarehouse.warehouse = null;
      if (transferRequestData.initiateTransportInventoryItems.toWarehouse) {
        $scope.toWarehouse.warehouse = transferRequestData.initiateTransportInventoryItems.toWarehouse;
        $scope.isDisabled = true;
      }
      //QUICK FIX - Uncomment to have all warhouses in the dropdown
      // if(warehouse.warehouseType &&
      //   (warehouse.warehouseType == "Agent" || warehouse.warehouseType == "Wholesalers"  || warehouse.warehouseType == "MasterFranchise")){
      //   checkWarehouseTypes(warehouse.warehouseId);
      // }
      $scope.addTransportFormData = {};
      $scope.selectedWarehouse = warehouse;
      $scope.fromWareHouseName = warehouse.warehouseName;
      $scope.addTransportFormData.fromWarehouseId = warehouse.warehouseId;
      if ($scope.toWarehouse && $scope.toWarehouse.warehouse) {
        $scope.addTransportFormData.toWarehouseId = $scope.toWarehouse.warehouse["warehouseId"];
      }
      getProductsCategories();
    };
    
    
    const getProductsCategories = function() {
      products.getProducts()
        .then(function(res) {
          // get distinct categories
          let distinctCategoryNames = [];
          let productCategoriesArr = [];
          for (let productCategory of res.data.categories) {
            if (!distinctCategoryNames.includes(productCategory.name)) {
              distinctCategoryNames.push(productCategory.name); //using this space to keep track of distinct category names
              let productCategoriesObj = {};
              productCategoriesObj.categoryName = productCategory.name;
              productCategoriesObj.quantity = 0;
              productCategoriesObj.pricePerUnit = productCategory.weightedPriceAverage == null ? productCategory.price : productCategory.weightedPriceAverage;
              productCategoriesArr.push(productCategoriesObj)
            }
          }
          $scope.distinctCategories = productCategoriesArr;
        })
        .catch(function(err) {
          console.error('Error in products.getProducts() API ', err);
        })
    };
    
    $scope.cancel = function() {
      $uibModalInstance.close('Close');
    };
    
    $scope.saveTransportInventory = function() {
      $scope.errorDiable = true;
      let vmDistinctCategories = [];
      for (let added of $scope.Categories) {
        for (let category of $scope.distinctCategories) {
          let vmDistinctCategoriesObj = {};
          let addedItem = added.categoryName;
          if (added.quantity !== 0 && addedItem === category.categoryName) {
            vmDistinctCategoriesObj.categoryName = category.categoryName;
            vmDistinctCategoriesObj.quantity = added.quantity;
            vmDistinctCategoriesObj.pricePerUnit = category.pricePerUnit;
            vmDistinctCategories.push(vmDistinctCategoriesObj)
          }
        }
      }
      
      $scope.addTransportFormData.items = vmDistinctCategories;
      
      if (!$scope.addTransportFormData.fromWarehouseId) {
        growl.error('Missing fromWarehouse value');
        return;
      }
      
      if (!$scope.addTransportFormData.toWarehouseId) {
        growl.error('Missing toWarehouse value');
        return;
      }
      
      if (!($scope.dateInput.val) && !($scope.dateInput.val._d)) {
        growl.error("Missing Pick Up Date value");
        return;
      }
      
      if (!$scope.addTransportFormData.items) {
        growl.error('Empty items');
        return;
      }
      
      $scope.addTransportFormData.pickUpDate = $moment($scope.dateInput.val._d).format("DD-MMM-YYYY");
      if (transferRequestData.from === "Transport") { // invoke the API call to save transport inventory
        $scope.addTransportFormData.confirmationId = transferRequestData["initiateTransportInventoryItems"]["confirmationId"];
      }
      warehouseService.saveTransportInventory($scope.addTransportFormData)
        .then(function(res) {
          if (res.status === $global.status.SUCCESS) {
            growl.success("Successfully added inventory");
            $timeout(function() {
              if ($scope.addTransportFormData && $scope.addTransportFormData.confirmationId) {
                $global.setLocalItem("transferRequestId", $scope.addTransportFormData.confirmationId, true);
              }
              $scope.refreshButtonClicked($scope.selectedWarehouse);
              $scope.backClick();
              $uibModalInstance.close('Updated');
            }, 1500);
          } else {
            if (res && res.data && res.data.message) {
              growl.error(res.data.message);
            } else if (res && res.error && res.error.message) {
              growl.error(res.error.message);
            } else {
              growl.error("Failed to create transfer order");
            }
          }
        })
        .catch(function(err) {
          growl.error("Failed to create transfer order");
          console.error("Failed to create transfer order", err);
        })
    };
    
    $scope.backClick = function() {
      $scope.Categories = [];
      if (transferRequestData && transferRequestData.id) {
        let objToSend = {
          index: transferRequestData.index,
          page: transferRequestData.page,
          size: transferRequestData.size,
          sort: transferRequestData.sort,
          key: null
        };
        if (transferRequestData.key) {
          objToSend.key = transferRequestData.key;
        }
        if (transferRequestData.from === "Warehouse") {
          $global.setLocalItem("fromTransportInv", $scope.fromWareHouseName, true);
          localStorageService.remove('transportInvParams');
          $state.go('root.inventoryList', objToSend);
        } else if (transferRequestData.from === "Transport") {
          localStorageService.remove('transportInvParams');
          $state.go('root.transportInventory', {}, {reload: true});
        } else {
          if (transferRequestData.category) {
            objToSend.category = transferRequestData.category;
          }
          localStorageService.remove('transportInvParams');
          $state.go('root.productsInventory', objToSend);
        }
      }
      //   $scope.showWarehouseItems = false;
    };
    
    $scope.loaded = function() {
      if (window.innerWidth >= 992 && ($scope.editRequest) && ($scope.confirmationId)) {
        let modal = $('.modal-dialog')[0];
        modal.style.width = 620 + 'px';
        return;
      }
      if (window.innerWidth >= 778 && ($scope.editRequest) && ($scope.confirmationId)) {
        let modal = $('.modal-dialog')[0];
        modal.style.width = 500 + 'px';
        return;
      }
    }
    
  });
