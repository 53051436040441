'use strict';

/**
 */
angular.module('scrapQcrmApp')
    .controller('ViewImageController', function ($scope, $uibModalInstance, $sce, imageData) {




        $scope.imageData = imageData;
        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        }

        // $scope.content = $sce.trustAsResourceUrl(items);
    });
