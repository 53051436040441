/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

class Directions {
  constructor($q, $timeout) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.directions = new google.maps.DirectionsService();
    this.directionsModes = {
      'Driving': google.maps.TravelMode.DRIVING,
      'Cycling': google.maps.TravelMode.BICYCLING,
      'Transit': google.maps.TravelMode.TRANSIT,
      'Walking': google.maps.TravelMode.WALKING
    };
  }

  getDirections(markers, options) {
    // Take a copy so we don't modify the original markers array
    markers = markers.slice();

    options = angular.extend(options, {
      origin: markers.shift().getPosition(),
      destination: markers.pop().getPosition(),
      provideRouteAlternatives: false,
      waypoints: [],
      optimizeWaypoints: true
    });

    // Any markers leftover are waypoints
    for (let marker of Array.from(markers)) {
      options.waypoints.push({
        location: marker.getPosition(),
        stopover: true
      });
    }

    const deferred = this.$q.defer();

    this.directions.route(options, (results, status) => {
      return this.$timeout(function() {
        if (status === google.maps.DirectionsStatus.OK) {
          return deferred.resolve(results);
        } else {
          return deferred.reject(status);
        }
      });
    });

    return deferred.promise;
  }
}

angular.module('scrapQcrmApp')
.factory('Directions', ($timeout, $q) => new Directions($q, $timeout));
