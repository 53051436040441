'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ProductWiseReportsController
 * @description
 * # ProductWiseReportsController
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('ProductWiseReportsController', function ($scope, $http, $state,authentication , $filter, $moment, $stateParams, $global, $rootScope, products, dashboard, $uibModal, $log, consumer, prospect, growl, BidService) {

        $scope.stateParams = $stateParams;
        $scope.inrFilter = $filter('INR');

        $scope.date = {
            startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
            endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
        };

        $scope.vm = {
            params: { "city": $rootScope.loginData.city }
        };


        $rootScope.loginData = $global.getLocalItem("loginData", true);

        (function () {
            let type = "";

            if ($stateParams.type.toUpperCase() == "PURCHASE") {
                type = "Purchase";
            }
            else if ($stateParams.type.toUpperCase() == "SALES") {
                type = "Sales";
            }
            else {
                type = "Consolidated";
            }

            var data = {
                "city": $rootScope.loginData.city,
                "userType": $rootScope.loginData.role,
                "module": "Reports",
                "subModule": "ProductWise",
                "type": type
            };

            authentication.getUserRoleMetrics(data).then(function (res) {
                if (res.status == "SUCCESS") {
                    $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
                    $scope.gridOptions.columnDefs.forEach(function (col, index) {
                        if ($scope.disabledFeilds.includes(col.field)) {
                            $scope.gridOptions.columnDefs.splice(index, 1);
                        }
                    });
                    $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs);
                } else {
                    $scope.disabledFeilds = [];
                }
            }).catch(function (err) {
                $scope.disabledFeilds = [];
            })
        })();


        (function () {
            let columnDefs = [
                {
                    headerName: "Product",
                    field: "product",
                    flex: 2,
                    cellStyle: { 'text-align': 'left' },
                },
            ];
            var purChaseColumns = [
                {
                    headerName: "Qty",
                    field: "quantity",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer

                },
                {
                    headerName: "GTV",
                    field: "gtv",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer
                },
                {
                    headerName: "Avg Price",
                    field: "averagePrice",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer

                }
            ];

            var salesColumns = [
                {
                    headerName: "Item",
                    field: "item",
                    cellStyle: { 'text-align': 'left' }
                },
                {
                    headerName: "Total Sales Qty",
                    field: "totalSaleQuantity",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer

                },
                {
                    headerName: "Total Sale Price",
                    field: "totalSalePrice",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer
                },
                {
                    headerName: "Average Sale Price",
                    field: "averageSalePrice",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer
                }
            ]

            var consolidatedColumns = [
                {
                    headerName: "Items",
                    field: "items",
                    cellStyle: { 'text-align': 'left' }
                },
            ]

            var exportCsvConfig = {
                suppressCsvExport: true,
                suppressExcelExport: true,
            };

            if ($stateParams.type.toUpperCase() == "PURCHASE") {
                exportCsvConfig = {
                    suppressCsvExport: false,
                    suppressExcelExport: false,
                };
                columnDefs = columnDefs.concat(purChaseColumns);
                $scope.type = "Purchase";
            }
            else if ($stateParams.type.toUpperCase() == "SALES") {
                exportCsvConfig = {
                    suppressCsvExport: false,
                    suppressExcelExport: false,
                };
                columnDefs = columnDefs.concat(salesColumns);
                $scope.type = "Sales";
            }
            else {
                $scope.type = "Consolidated";
                columnDefs = columnDefs.concat(purChaseColumns).concat(salesColumns).concat(consolidatedColumns);
                let itemHeaderIndex = columnDefs.findIndex(function (header) {
                    console.log(header)
                    if (header.headerName == "Item") {
                        return header
                    }
                });
                columnDefs.splice(itemHeaderIndex, 1);
            }


            columnDefs.forEach(function (colObj) {
                colObj.headerTooltip = colObj.headerName;
            })
            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                tooltipShowDelay: 0,
                suppressCsvExport: false,
                suppressExcelExport: false,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.reports);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            }; //End of gridOptions//

            $scope.gridOptions = Object.assign($scope.gridOptions, exportCsvConfig);

            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '0.00';
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

        })();

        (function () {

            $scope.setGridData = function (res) {

                if (res.status.toUpperCase() == "SUCCESS") {
                    $scope.reports = res.data.reports;
                    $scope.gridOptions.api.setRowData($scope.reports);
                } else {
                    if (res.status.toUpperCase() == "FAILURE" && res.error && res.error.code == "EC_MAX_DATE_RANGE_EXCEEDED") {
                        growl.error(res.error.message);
                    } else {
                        $scope.reports = [];
                        $scope.gridOptions.api.setRowData($scope.reports);
                    }
                }
            }
            $scope.loadData = function () {

                let data = {
                    "fromDate": $scope.vm.params.fromdate,
                    "toDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                };

                if ($stateParams.type.toUpperCase() == "PURCHASE") {
                    products.getProductWisePurchaseReports(data).then(function (res) {
                        $scope.labelCounts = {
                            totalGtv: res.data && res.data.totalGTV ? $scope.inrFilter(res.data.totalGTV) : "0.00",
                            totalProducts: res.data && res.data.totalProductsCount ? res.data.totalProductsCount : 0,
                            totalQty: res.data && res.data.totalQTY ? $scope.inrFilter(res.data.totalQTY) : "0.00",
                        }
                        $scope.setGridData(res);
                    })
                }
                else if ($stateParams.type.toUpperCase() == "SALES") {

                    products.geProductWiseSalesReports(data).then(function (res) {
                        $scope.labelCounts = {
                            totalVendorItemsCount: res.data && res.data.totalVendorItemsCount ? res.data.totalVendorItemsCount : 0,
                            totalSaleQuantity: res.data && res.data.totalSaleQTY ? $scope.inrFilter(res.data.totalSaleQTY) : "0.00",
                            totalSaleGtv: res.data && res.data.totalSaleGTV ? $scope.inrFilter(res.data.totalSaleGTV) : "0.00"
                        }
                        $scope.setGridData(res);
                    })
                }
                else {
                    products.getConsolidatedProductWiseReports(data).then(function (res) {
                        $scope.labelCounts = {
                            totalGtv: res.data && res.data.totalGTV ? $scope.inrFilter(res.data.totalGTV) : "0.00",
                            totalProducts: res.data && res.data.totalProductsCount ? res.data.totalProductsCount : 0,
                            totalQty: res.data && res.data.totalQTY ? $scope.inrFilter(res.data.totalQTY) : "0.00",
                            totalSaleQuantity: res.data && res.data.totalSaleQTY ? $scope.inrFilter(res.data.totalSaleQTY) : "0.00",
                            totalSaleGtv: res.data && res.data.totalSaleGTV ? $scope.inrFilter(res.data.totalSaleGTV) : "0.00"
                        }
                        $scope.setGridData(res);
                    })
                }
            }
            $scope.loadData();

        })();




        console.log('4stateparam    : ', $stateParams.fromdate);
        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        //Watch for date changes
        $scope.$watch('date', function (newDate) {
            $scope.selectedRange = $('#daterange3').data('daterangepicker').chosenLabel;

            $scope.transactionChange();
        }, false);

        $scope.transactionChange = function () {

            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;

            if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
                $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
                $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

                if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
                    $scope.vm.params.singledayquery = true;
                }
                $scope.loadData();
            } else {
                console.error("Invalid date, please select valid date range");
                console.error("startDate = ", $scope.date.startDate);
                console.error("endDate = ", $scope.date.endDate);
                growl.error("Invalid date, please select valid date range");
            }
        };

        $scope.nextDate = function () {
            $scope.paging = false;
            $scope.date = $global.getNextDateRanges($scope.selectedRange, $scope.date.endDate);
            // $scope.date = {
            //     startDate: moment($scope.date.endDate).add(1, "days"),
            //     endDate: moment($scope.date.endDate).add(1, "days")
            // };
            $scope.transactionChange();
        }
        $scope.previousDate = function () {
            $scope.paging = false;
            $scope.date = $global.getPrevDateRanges($scope.selectedRange, $scope.date.startDate);
            // $scope.date = {
            //     startDate: moment($scope.date.startDate).subtract(1, "days"),
            //     endDate: moment($scope.date.startDate).subtract(1, "days")
            // };
            $scope.transactionChange();
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });
