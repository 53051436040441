'use strict';

class Geocoder {
  constructor($q, $timeout) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.geocoder = new google.maps.Geocoder();
  }

  geocode(options) {
    const deferred = this.$q.defer();

    this.geocoder.geocode(options, (results, status) => {
      return this.$timeout(function() {
        if (status === google.maps.GeocoderStatus.OK) {
          return deferred.resolve(results);
        } else {
          return deferred.reject(status);
        }
      });
    });

    return deferred.promise;
  }

  getLocation(latLng) {
    return this.geocode({ 'latLng': latLng });
  }

  getLatLng(location) {
    return this.geocode({ 'address': location });
  }
}

angular.module('scrapQcrmApp')
.factory('Geocoder', ($timeout, $q) => new Geocoder($q, $timeout));
