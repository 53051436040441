'use strict';

angular.module('scrapQcrmApp')
  .controller('AddHolidayCtrl', function ($scope, holiday, area, $moment, $global, growl, $uibModal, $uibModalInstance, $state, $timeout, $rootScope, dateClicked, areaId) {
    (function () {
      $scope.data = {
        type: ""
      };
      $scope.status = {
        opened: false
      };

      $scope.isEdit = false;
      $scope.datepickerObjectPopup = {};
      $scope.datepickerObjectPopup.inputDate = new Date();
      $scope.dt = new Date();

      $scope.type = ["ONETIME", "RECURRING"];
      $scope.frequency = ["WEEKLY", "MONTHLY", "YEARLY"];
      $scope.showFrequency = false;

      $scope.nextShow = false;

      $scope.smsShow = false;


      $scope.getCityHoliday = function(dateClicked) {
        holiday.getHoliday(dateClicked, 1)
            .then(function (res) {
              $global.displayLog('get holiday for dateClicked', 'res', res.data.holidays[0]);
              let dateClickedHolidayObj = res.data.holidays[0];
              $scope.data.id = dateClickedHolidayObj.id;
              $scope.data.name = dateClickedHolidayObj.name;
              $scope.data.description = dateClickedHolidayObj.description;
              $scope.datepickerObjectPopup.inputDate = $moment(dateClicked, "DD-MMM-YYYY").toDate();
              $scope.data.type = 'ONETIME';
            })
            .catch(function (err) {
              $global.displayLog('get holiday for dateClicked', 'err', err);
            });
      }
  
      $scope.getAreaHoliday = function(dateClicked) {
        holiday.getAreaHolidayForEdit(dateClicked, $scope.data.areaId)
            .then(function (res) {
              $global.displayLog('get holiday for dateClicked', 'res', res.data.holidays[0]);
              let dateClickedHolidayObj = res.data.holidays[0];
              $scope.data.id = dateClickedHolidayObj.id;
              $scope.data.name = dateClickedHolidayObj.name;
              $scope.data.description = dateClickedHolidayObj.description;
              $scope.datepickerObjectPopup.inputDate = $moment(dateClicked, "DD-MMM-YYYY").toDate();
              $scope.data.type = 'ONETIME';
            })
            .catch(function (err) {
              $global.displayLog('get holiday for dateClicked', 'err', err);
            });
      }
  

      if (dateClicked) {
        $scope.isEdit = true;
        if (areaId) {
         $scope.data.areaId = areaId;
         $scope.getAreaHoliday(dateClicked);
        }
        $scope.getCityHoliday(dateClicked);
        $scope.currentEditOperation = 'updateHolidayOperation';
        $scope.headerTitle = 'Edit Holiday';
        
      } else {
        $scope.currentEditOperation = 'createHolidayOperation';
        $scope.headerTitle = 'Create Holiday';
      }

      $global.displayLog('Inside AddHolidayCtrl');
      $global.displayLog('DI value for dateClicked ', 'dateClicked', dateClicked);
      $global.displayLog('Value of $scope.currentEditOperation', '$scope.currentEditOperation', $scope.currentEditOperation);

    })();

    (function () {
      let maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 1);
      $scope.dateOptionsPick = {
        maxDate: maxDate,
        minDate: new Date(),
        format: "dd-MMM-yyyy",
        startingDay: 1
      };
    })();

    (function () {
      area.getArea().then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data && res.data.area) {
            $scope.areaList = res.data.area;
          }
        } else {
          growl.error(res.error.message ? res.error.message : "Unable to retrieve areas");
        }
      });

    })();

    // open calendar function
    $scope.open = function ($event) {
      $scope.status.opened = true;
    };

    // holiday types
    $scope.typeSelect = function () {
      if ($scope.data.type === "ONETIME") {
        $scope.showFrequency = false;
      }
      if ($scope.data.type === "RECURRING") {
        $scope.showFrequency = true;
      }
    };

    // create a holiday
    $scope.addHoliday = function () {
      let year = $scope.datepickerObjectPopup.inputDate.getFullYear();
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let month = $scope.datepickerObjectPopup.inputDate.getMonth();
      let date = $scope.datepickerObjectPopup.inputDate.getDate();
      $scope.data.date = date + '-' + months[month] + '-' + year;
      $scope.data.city = $rootScope.loginData.city;
      console.log('$scope.data: ', $scope.data);
      if ($scope.data.areaId) {
        $scope.addAreaHoliday($scope.data);
      } else {
        $scope.addCityHoliday($scope.data);
      }
    };

    $scope.addCityHoliday = function (data) {
      holiday.addHoliday(data)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            if (res.data && res.data.holidays && res.data.holidays.length > 0) {
              $scope.holidays_List = res.data.holidays;
              if (res.data.message) {
                growl.success(res.data.message);
              } else {
                growl.success("Holiday saved Successfully");
              }
              $scope.cancel();
            } else {
              growl.error("Error in adding holiday");
            }
          } else if (res.error.message) {
            growl.error(res.error.message);
            console.error(res.error.message);
          } else {
            growl.error('Holiday failed to save');
            console.error('Error in calling the addHoliday API ');
          }
        })
        .catch(function (err) {
          console.error('Error in calling the addHoliday API ', err);
        })
    }

    $scope.addAreaHoliday = function (data) {
      holiday.addAreaHoliday(data)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            if (res.data && res.data.holidays && res.data.holidays.length > 0) {
              $scope.holidays_List = res.data.holidays;
              if (res.data.message) {
                growl.success(res.data.message);
              } else {
                growl.success("Holiday saved Successfully");
              }
              $scope.cancel();
            } else {
              growl.error("Error in adding holiday");
            }
          } else if (res.error.message) {
            growl.error(res.error.message);
            console.error(res.error.message);
          } else {
            growl.error('Holiday failed to save');
            console.error('Error in calling the addHoliday API ');
          }
        })
        .catch(function (err) {
          console.error('Error in calling the addHoliday API ', err);
        })
    }

    // delete a holiday
    $scope.deleteHoliday = function () {
      let modalInstance = $uibModal.open({
        templateUrl: 'views/delete-holiday-modal.html',
        controller: 'DeleteHolidayCtrl',
        size: "md",
        backdrop: 'static',
        resolve: {
          items: function () {
            return {
              date: dateClicked,
              areaId: areaId
            }
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $uibModalInstance.close('Close');
      });
    };

    // next button in second screen (The SMS screen)
    $scope.nextClicked = function () {
      $scope.nextShow = true;
      $scope.smsShow = true;
      let year = $scope.datepickerObjectPopup.inputDate.getFullYear();
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let month = $scope.datepickerObjectPopup.inputDate.getMonth();
      let date = $scope.datepickerObjectPopup.inputDate.getDate();
      let data = {
        date: date + '-' + months[month] + '-' + year,
        message: $scope.data.smsText,
        type: $scope.data.type,
        frequency: $scope.data.frequency,
        occurrences: $scope.data.occurrences,
        city: $rootScope.loginData.city
      };

      holiday.changeAppointmentDateAndSendSMS(data)
        .then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success("Appointments Date have been changed Successfully");
            $uibModalInstance.close('Close');
          } else {
            growl.error("Error in changing appointment dates.");
          }
        })
        .catch(function (err) {
          console.error('Error in calling changeAppointmentDateAndSendSMS() ', err);
        })
    };

    /*================================================helper functions=================================================================*/
    $scope.cancel = function () {
      $uibModalInstance.close('Close');
    };

    $scope.radioButtonSelected = function (value) {
      $scope.smsRadio = value;
    };

  });
