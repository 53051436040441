'use strict';

/**
 * @ngdoc service
 * @name scrapQApp.wholesalerPickups
 * @description
 * # warehousesServ
 * Factory in the scrapQApp.
 */
angular.module('scrapQcrmApp')
  .factory('warehouseService', function(httpService, $global, $http, $rootScope) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);
    return {
      addWarehouses: function(data) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().addWarehouses;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }, getWarehouse: function(page) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getWarehouse.replace(":city", city);
        if (page === -1) {
          url = url + "&page=" + page;
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, getProfile: function(warehouseId) {
        var warehouseId = warehouseId;
        var url = $global.getApiUrl() + $global.getApiObject().getWarehouseProfile.replace(":id", warehouseId);
        var $request = httpService.httpRequest(url, "G");
        return $request;
      }, searchWarehouse: function(type, key) {
        var city = $rootScope.loginData.city;
        var url = $global.getApiUrl() + $global.getApiObject().getWarehouse.replace(":city", city) + "&keyword=" + key;
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, saveAddress: function(data) {
        var warehouseId = $global.warehouseId;
        var url = $global.getApiUrl() + $global.getApiObject().saveAddress;
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }, updateProfile: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().updateWarehouse.replace(":id", data.warehouseId);
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }, warehousesLoad: function(type, page, nodecrement) {
        var url;
        var city = $rootScope.loginData.city;

        // if page == -1, the api fetches all the records
        if (page && page != '-1') {
          if (!nodecrement) {
            page = page - 1;
          }
          url = $global.getApiUrl() + $global.getApiObject().warehouseLoad.replace(":city", city) + "&page=" + page;
        } else if(page == '-1') {
          url = $global.getApiUrl() + $global.getApiObject().warehouseLoad.replace(":city", city) + "&page=" + page;
        }else {
          url = $global.getApiUrl() + $global.getApiObject().warehouseLoad.replace(":city", city);
        }
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, getWarehouseInventory: function(fromDate, toDate, warehouseId, page, size) {
        if (fromDate == null && toDate == null && warehouseId == null && page == null && size == null) {
          var page = -1;
          var url = $global.getApiUrl() + $global.getApiObject().getWarehouseInventory + "?page=" + page;
          var $request = httpService.httpRequest(url, "G", "");
        } else if (fromDate == null && toDate == null && warehouseId != null && page == null && size == null) {
          var page = -1;
          var url = $global.getApiUrl() + $global.getApiObject().getWarehouseInventory + "?warehouseId=" + warehouseId + "&page=" + page;
          var $request = httpService.httpRequest(url, "G", "");
        } else if (fromDate != null && toDate != null && warehouseId == null && page == null && size == null) {
          var page = -1;
          var url = $global.getApiUrl() + $global.getApiObject().getWarehouseInventory + "?page=" + page + "&fromDate=" + fromDate + "&toDate=" + toDate;
          var $request = httpService.httpRequest(url, "G", "");
        } else if (fromDate != null && toDate != null && warehouseId != null && page == null && size == null) {
          var page = -1;
          var url = $global.getApiUrl() + $global.getApiObject().getWarehouseInventory + "?warehouseId=" + warehouseId + "&page=" + page + "&fromDate=" + fromDate + "&toDate=" + toDate;
          var $request = httpService.httpRequest(url, "G", "");
        }
        return $request;
      }, addInventory: function(warehouseId, data) {
        // var warehouseid = $global.warehouseId;
        var warehouseid = warehouseId;
        if (null == warehouseId) {
          var url = $global.getApiUrl() + $global.getApiObject().addInventory;
        } else {
          var url = $global.getApiUrl() + $global.getApiObject().addInventory.replace(":warehouseId", warehouseId);
        }
        var $request = httpService.httpRequest(url, "P", data);
        return $request;
      }, retrieveTotalInventory: function(warehouseId, fromDate, toDate, city) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveTotalInventory.replace(":warehouseId", warehouseId).replace(":fromDate", fromDate).replace(":toDate", toDate).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      retrieveActualInventory: function(warehouseId, fromDate, toDate, city) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveActualInventory.replace(":warehouseId", warehouseId).replace(":fromDate", fromDate).replace(":toDate", toDate).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, retrieveInventoryCountforWarehouse: function(warehouseId, fromDate, toDate, city) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveInventoryCountforWarehouse.replace(":id", warehouseId).replace(":fromdate", fromDate).replace(":toDate", toDate).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, retrieveAgentItems: function(warehouseId, fromDate, toDate, city) {
        var url = $global.getApiUrl() + $global.getApiObject().retrieveAgentItems.replace(":warehouseId", warehouseId).replace(":fromDate", fromDate).replace(":toDate", toDate).replace(":city", city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      }, distinctCategories: function() {
        var url = $global.getApiUrl() + $global.getApiObject().distinctCategories.replace(":city", $rootScope.loginData.city);
        var $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      retrieveMasterFranchise: function() {
        let city = $rootScope.loginData.city;
        let url = $global.getApiUrl() + $global.getApiObject().retrieveMasterFranchise;
        url += "?city=" + city;
        return httpService.httpRequest(url, "G", "");
      },
      retrieveSuperAdmin: function() {
        let city = $rootScope.loginData.city;
        let url;
        url = $global.getApiUrl() + $global.getApiObject().usersLoad.replace(":userType", "ADMIN").replace(":city", city) + "&page=" + "-1"+ "&superAdmin=true";
        return httpService.httpRequest(url, "G", "");
      },
      saveTransportInventory: function(data) {
        let url = $global.getApiUrl() + $global.getApiObject().saveTransportInventory;
        return httpService.httpRequest(url, "P", data);
      },
      getTransportInventoryByConfirmationId: function(confirmationId) {
        let url = $global.getApiUrl() + $global.getApiObject().getTransportInventoriesForAWarehouse + "?confirmationId=" + confirmationId;
        return httpService.httpRequest(url, "G", "");
      },
      getTransportInventoriesForAWarehouse: function(fromWarehouseId, page, fromDate, toDate) {
        if (page) {
          page -= 1; //because pagination buttons are 1-based in the CRM and 0-based in the API
        } else {
          page = 0;
        }
        let size = 10;
        let url = $global.getApiUrl() + $global.getApiObject().getTransportInventoriesForAWarehouse + "?fromWarehouseId=" + fromWarehouseId + "&page=" + page + "&size=" + size;
        
        if (fromDate && toDate) {
          url = url + "&fromDate=" + fromDate + "&toDate=" + toDate;
        }
        
        return httpService.httpRequest(url, "G", "");
      },
      retrieveAllInventories: function(warehouseId, toDate, city, page) {
        if (page) {
          page -= 1; //because pagination buttons are 1-based in the CRM and 0-based in the API
        } else {
          page = 0;
        }
        let size = 10;
        let url = $global.getApiUrl() + $global.getApiObject().retrieveAllInventories + "?city=" + city + "&warehouseId=" + warehouseId + "&toDate=" + toDate;
        url = url + "&page=" + page + "&size=" + size;
        let $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getBalanceInventoryForWarehouse: function(warehouseId, fromDate, toDate, city, page) {
        if (page) {
          page -= 1; //because pagination buttons are 1-based in the CRM and 0-based in the API
        } else {
          page = 0;
        }
        let size = 10;
        let url = $global.getApiUrl() + $global.getApiObject().getBalanceInventoryForWarehouse;
        url += "?city=" + city + "&warehouseId=" + warehouseId + "&fromDate=" + fromDate + "&toDate=" + toDate;
        url += "&page=" + page + "&size=" + size;
        let $request = httpService.httpRequest(url, "G", "");
        return $request;
      },
      getTransportInventories: function(data){
          data.city = $rootScope.loginData.city;
          var  url = $global.getApiUrl() + $global.getApiObject().getTransportInventories.replace(":fromWarehouseId", data.fromWarehouseId).replace(":toWarehouseId", data.toWarehouseId)
                      .replace(":fromDate", data.fromDate).replace(":toDate", data.toDate).replace(":city", data.city);
          var $request = httpService.httpRequest(url, "G","");
          return $request;
      },
      acceptTransferRequest: function(data){
          var  url = $global.getApiUrl() + $global.getApiObject().acceptTransferRequest.replace(":confirmationId", data);
          var $request = httpService.httpRequest(url, "G","");
          return $request;
      },
      rejectTransferRequest: function(data){
          var  url = $global.getApiUrl() + $global.getApiObject().rejectTransferRequest.replace(":confirmationId", data);
          var $request = httpService.httpRequest(url, "G","");
          return $request;
      },
      dispatchTransferRequest: function(data,billNo,truckNo){
          var  url = $global.getApiUrl() + $global.getApiObject().dispatchTransferRequest.replace(":confirmationId", data).replace(":billNo",billNo).replace(":truckNo",truckNo);
          var $request = httpService.httpRequest(url, "G","");
          return $request;
      },
      disableWarehouse: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().disableWarehouse;
        var $request = httpService.httpRequest(url, "P", data);
        return $request
      },
      getWarehouseWiseReports: function(data) {
        var url = $global.getApiUrl() + $global.getApiObject().getWarehouseWiseReports;
        var $request = httpService.httpRequest(url, "P", data);
        return $request
      },
    };
  });
