'use strict';

/**
 * Agents controller module.
 */
angular.module('scrapQcrmApp')
  .controller('AgentsCtrl', function ($scope, $global, $rootScope, $uibModal, $state, SellNow, growl, $log, documents, payoutService, agentService, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, $filter, $timeout, $moment) {

    /*===============================================default init values============================================================*/
    $scope.labelCounts = {
      totalAgents: 0,
      activeAgents: 0,
      inActiveAgents: 0
    };
    $scope.resetLabelCounts = function () {
      $scope.labelCounts = {
        totalAgents: 0,
        activeAgents: 0,
        inActiveAgents: 0
      };
    };
    (function () {
      $scope.pages = -1;
      $scope.vm = { params: {} };
      $scope.currentPage = 1;
      $scope.agentlistloading = true;
      $scope.selectedInventory = "all";
      $scope.url = $global.apiUrl;

      // date for attendance entries
      $scope.dateRangePicker = {};
      $scope.dateRangePicker.dateInput = {
        startDate: $moment(new Date()).subtract(7, "days"),
        endDate: $moment(new Date()).toDate(),
      };

      // date options for the date-range-picker
      $scope.opts = {
        locale: {
          applyClass: 'btn-green',
          applyLabel: "Apply",
          fromLabel: "From",
          format: "DD-MMM-YYYY",
          toLabel: "To",
          cancelLabel: 'Cancel',
          customRangeLabel: 'Custom range'
        },
        ranges: {
          'Today': [$moment(), $moment()],
          'Yesterday': [$moment().subtract(1, 'days'), $moment().subtract(1, 'days')],
          'Last 7 Days': [$moment().subtract(6, 'days'), $moment()],
          'Last 30 Days': [$moment().subtract(29, 'days'), $moment()],
          'This Month': [$moment().startOf('month'), $moment().endOf('month')]
        }
      };

      $scope.attendanceCurrentPage = 1;

      if ($stateParams.priceCapsuleId) {
        $scope.priceCapsuleId = $stateParams.priceCapsuleId;
        // $scope.loadAgents({});
      }


      // ag-grid init
      var columnDefs = [
        {
          headerName: "Name",
          field: "name",
        },
        {
          headerName: "Mobile Number",
          cellRenderer: HideMobileNumber,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Inception Date",
          field: "createdOn",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Partner",
          field: "wholesalername",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Warehouse",
          field: "warehouseName",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Price Capsule",
          field: "priceCapsule.name",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Area",
          field: "area",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        suppressCsvExport: true,
        suppressExcelExport: true,
        rowStyle: { background: '#f4f4f4' },
        pagination: true,
        // paginationAutoPageSize: true,
        rowData: rowData,
        // suppressPaginationPanel:true,
        paginationPageSize: 1000,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          // params.api.sizeColumnsToFit();
          // params.columnApi.autoSizeAllColumns();
          $scope.gridOptions.api.setRowData($scope.agent_list);
        },
        onCellClicked: function (event) {
          $scope.agentDetails(event.data.userId);
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };

    })();

    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    function HideMobileNumber() {
    }
    HideMobileNumber.prototype.init = function (params) {
      // console.log("where is mobile no.",params.data);
      this.mobileNumberDiv = document.createElement('div');
      if (params.data != "" || params.data != "undefined") {
        if ($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER") {
          let mobileNo = params.data.mobile;
          this.mobileNumberDiv.innerHTML = "<div>" + mobileNo + "</div>";
        } else {
          let mobileNo = params.data.mobile;
          let hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
          this.mobileNumberDiv.innerHTML = "<div>" + hiddenMobileNo + "</div>";
        }
      } else {
        this.mobileNumberDiv.innerHTML = "";
      }
    }

    HideMobileNumber.prototype.getGui = function () {
      return this.mobileNumberDiv;
    }

    //Documents Ag-Grid
    var documentsColDefs = [
      {
        headerName: "Document Type",
        field: "name",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "",
        field: "download",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionCellRenderer,
        maxWidth: 70,
      },
      {
        headerName: "",
        field: "delete",
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionDeleteCellRenderer,
        maxWidth: 70,
      }
    ];

    $scope.userDocuments = [];

    $scope.documentsGridOptions = {
      columnDefs: documentsColDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.documentsGridOptions && $scope.documentsGridOptions.api) {
            $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        console.log(event)
        if (event.column.colId == "delete") {
          $scope.deleteDoc(event.data);
        }
        if (event.column.colId == "download") {
          $scope.downloadDoc(event.data.url);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };


    // Transactions Ag-grid
    //Documents Ag-Grid
    var transactionColDef = [
      {
        headerName: "Conf. Id",
        field: "confirmationId",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Date",
        field: "preferredDate",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "Slot",
        field: "preferredSlot",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "Consumer Name",
        field: "consumerName",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "Address",
        field: "download",
        cellStyle: { textAlign: 'center' },
        valueFormatter: function (params) {
          return params.data.consumerAddress.formattedAddress
        }
      }
    ];

    $scope.transactionsGridOptions = {
      columnDefs: transactionColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.transactionsGridOptions && $scope.transactionsGridOptions.api) {
            $scope.transactionsGridOptions.api.setRowData($scope.todayAppointment);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.downloaddoc(event.data.value)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    //Payout History Ag-Grid
    var payoutHistoryColDef = [
      {
        headerName: "Date",
        field: "paymentDate",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Amount",
        field: "totalPayableAmount",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "status",
        field: "status",
        cellStyle: { textAlign: 'center' },
      }

    ];

    $scope.payOutHistoryGridOptions = {
      columnDefs: payoutHistoryColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.payOutHistoryGridOptions && $scope.payOutHistoryGridOptions.api) {
            $scope.payOutHistoryGridOptions.api.setRowData($scope.payoutHistoryList);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.downloaddoc(event.data.value)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    //Inventory History Ag-Grid
    var inventoryColDef = [
      {
        headerName: "Category",
        field: "_id",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Quantity",
        field: "totalquantity",
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "Total(Rs)",
        field: "totalamount",
        cellStyle: { textAlign: 'center' },
      }

    ];

    $scope.inventoryGridOptions = {
      columnDefs: inventoryColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.inventoryGridOptions && $scope.inventoryGridOptions.api) {
            $scope.inventoryGridOptions.api.setRowData($scope.inventory);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.downloaddoc(event.data.value)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    //Attendance  Ag-Grid
    var attendanceColDef = [
      {
        headerName: "Date",
        field: "attendanceEntryDate",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Present/Absent",
        field: "isPresent",
        cellStyle: { textAlign: 'center' },
        valueFormatter: function (params) {
          return params.data.isPresent ? 'Present' : 'Absent'
        }
      }
    ];

    $scope.attendanceGridOptions = {
      columnDefs: attendanceColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.attendanceGridOptions && $scope.attendanceGridOptions.api) {
            $scope.attendanceGridOptions.api.setRowData($scope.agentAttendanceList);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        $scope.downloaddoc(event.data.value)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };


    //Price Capsule Ag-Grid
    var priceCapsuleColDef = [
      {
        headerName: "Items",
        field: "productName",
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: "Type",
        field: "priceType",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 1",
        field: "tier1",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 2",
        field: "tier2",
        cellStyle: { textAlign: 'center' },

      },
      {
        headerName: "Tier 3",
        field: "tier3",
        cellStyle: { textAlign: 'center' },

      }
    ];

    $scope.priceCapsuleGridOptions = {
      columnDefs: priceCapsuleColDef,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        setTimeout(function () {
          if ($scope.priceCapsuleGridOptions && $scope.priceCapsuleGridOptions.api) {
            $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);
          }
        }, 1500);
      },
      onCellClicked: function (event) {
        // $scope.OpenRequest(event.data)
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    function ActionCellRenderer() {
    }

    ActionCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-download-alt"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    function ActionDeleteCellRenderer() {
    }
    ActionDeleteCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";

        let iconsDiv = `<i class="glyphicon glyphicon-trash"></i>`;
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ActionDeleteCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };
    /*================================================default service declarations============================================================*/

    $scope.getUserDocuments = function (userId) {
      $scope.userDocuments = [];
      documents.retrieveUserDocuments({ userId: userId }).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.userDocuments = res.data.documents;
          $scope.documentsGridOptions.api.setRowData($scope.userDocuments);
        }
      })
    }
    $scope.loadAgents = function (params) {
      var type = 'AGENT';
      $scope.submitted = true;
      $scope.paging = false;
      $scope.transactionStatus = "pending";
      $scope.noAgentFound = false;

      if (!params || !params.page) {
        params = {};
        params.page = 1;
      }

      // ag-grid pagination to work we need all records. -1 fetches all records
      params.page = -1;
      $scope.agentlistloading = true;

      agentService.usersLoad(type, params.page, $scope.priceCapsuleId).then(function (res) {
        $scope.agentlistloading = false;
        if (res.status === "SUCCESS") {
          $scope.agents = res.data;
          // console.log("$scope.agents", $scope.agents);
          $scope.totalAgentRecords = res.data.totalRecords;
          res.data.users.forEach(function (elem, idx) {
            try {
              var addrsplit = elem.address.formattedAddress.split(/,+/);
              res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
              if (!res.data.users[idx]['warehouseName']) {
                res.data.users[idx]['warehouseName'] = '-';
              }
              if (!res.data.users[idx]['area']) {
                res.data.users[idx]['area'] = '-';
              }
            } catch (e) {
              console.error('Error in splitting the formattedAddress ', e);
            }
          });
          $scope.length0 = false;
          $scope.agentId = false;
          if (res.data.message === "No records found") {
            $scope.noAgentFound = true;
          }
          $scope.agent_list = res.data.users;
          $scope.labelCounts = {
            totalAgents: res.data.totalUsers,
            activeAgents: res.data.totalActiveUsers,
            inActiveAgents: res.data.totalInactiveUsers
          };
          $scope.gridOptions.api.setRowData($scope.agent_list);

        } else if (res.status === "FAILURE") {
          $scope.noAgentFound = true;
        } else {
          $scope.noAgentFound = true;
        }
      }, function (err) {
        $scope.resetLabelCounts();
        console.error('Error in calling the loadAgents() ', err);
      })
    };

    /*================================================default service calls============================================================*/
    (function () {
      $scope.loadAgents($scope.vm.params);
      getAllAgentPriceCapsulesPromises()
        .then(function (res) {
          // logger
          $global.displayLog('getAllAgentPriceCapsulesPromises', 'res', res);
          $scope.allPriceCapsuleDetails = res.data.results;
          if ($stateParams.agentName) {
            $scope.searchAgent($stateParams.agentName);
          }
        })
        .catch(function (err) {
          console.error(err);
        })
    })();

    /*======================================================core functions===========================================================*/
    $scope.searchAgent = function (key) {
      if (key) {
        key = key.trim();
        if (key.length > 3) {
          var type = 'AGENT';
          $scope.submitted = true;
          $scope.paging = true;
          $scope.transactionStatus = "pending";
          $scope.noAgentFound = false;

          $scope.agentlistloading = true;
          agentService.getAgent(type, key).then(function (res) {
            $scope.agentlistloading = false;
            if (res.status === "SUCCESS") {
              $scope.agents = res.data;
              res.data.users.forEach(function (elem, idx) { //TODO extract this to global function, i think i've seen this in multiple places
                try {
                  var addrsplit = elem.address.formattedAddress.split(/,+/); //TODO 4 places
                  res.data.users[idx]['area'] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                } catch (e) {
                  console.error('Error in splitting the formattedAddress ', e);
                }
              });

              if (res.data.users.length === 1) {
                $scope.length0 = true;
                $scope.agentDetails(res.data.users[0].userId);
              } else {
                $scope.agent_list = res.data.users;
                $scope.length0 = false;
                $scope.agentId = false;
              }
              if (res.data.message === "No records found") {
                $scope.noAgentFound = true;
              }
            } else if (res.status === "FAILURE") {
              $scope.noAgentFound = true;
            } else {
              $scope.noAgentFound = true;
            }
          }, function (err) {
            console.error("Error in calling the API agentService.getAgent(type, key) ", err);
          })
        }
      } else {
        $scope.loadAgents($scope.vm.params);
      }
    };

    $scope.downloadDoc = function (url) {
      window.open(url, "_blank");
    };

    $scope.deleteDoc = function (data) {

      documents.disableUserDocuments({ documentId: data.id }).then(function (res) {
        if (res.status == "SUCCESS") {
          growl.success("Document Deleted Successfully");
          $scope.getUserDocuments($scope.agentId);
        } else {
          growl.error("Unable to delete document please try again")
        }
      }).catch(function () {
        growl.error("Unable to delete document please try again")
      })

    }

    $scope.disableAgent = function () {
      if (confirm(`Are you sure you want to disable ${$scope.profileEdit.name}?`)) {
        var mobile = $scope.profileEdit.mobileNo;
        var updatedata = {
          "id": mobile
        };
        agentService.disableAgent(updatedata).then(function (res) {
          if (res.status === "SUCCESS") {
            growl.success('Agent disabled successfully');
            $timeout(function () {
              $scope.loadAgents($scope.vm.params);
            }, 1500);
          } else if (res.status === "FAILURE") {
            growl.error(res.error.message);
          } else {
            $scope.loadAgents($scope.vm.params);
          }
        }, function (err) {
          console.error('Error in disabling agent API ', err);
          growl.error('Failed to disable agent');
        })
      }
    };

    /*======================================================helper functions===========================================================*/
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withDisplayLength($scope.length);

    $scope.pageChanged = function (currentPage) {
      $scope.vm.params.page = currentPage;
      $scope.loadAgents($scope.vm.params);
    };

    $scope.agentDetails = function (id) {
      $scope.length0 = true;
      $scope.agentId = id;
      getAgentProfile($scope.agentId);
      $scope.agent_list = false;
      $scope.vm.params = {
        "agentid": id,
        "size": 10,
        "page": 0,
        "status": "pending"
      };
      getAgentAppointments($scope.vm.params);

      let page = $scope.attendanceCurrentPage;
      let agentId = $scope.agentId;
      let fromDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      let toDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      $scope.getPayoutHistory(toDate);
      getAgentAttendance(agentId, page, fromDate, toDate);
      $scope.getInventory("all");
    };

    $scope.getPayoutHistory = function (toDate) {
      payoutService.getPayoutsHistory($scope.agentId, toDate).then(function (res) {
        // console.log("Res", res);
        if (res.status == "SUCCESS") {
          if (res.data && res.data.payoutHistoryList && res.data.payoutHistoryList.length > 0) {
            $scope.payoutHistoryList = res.data.payoutHistoryList;
            $scope.payOutHistoryGridOptions.api.setRowData($scope.payoutHistoryList);

            $scope.payoutHistoryList.forEach(val => {
              // val.partialPayment=val.partialPayment?"Yes":"No";
              val.status = "Completed";
            });
            $scope.payoutLastPaymentDate = $scope.payoutHistoryList[0].paymentDate;
            $scope.payoutLastPaymentAmount = $scope.payoutHistoryList[0].totalPayableAmount;
            $scope.payoutPendingAmount = $scope.payoutHistoryList[0].pendingAmount;
          } else {
            $scope.payoutHistoryList = [];
            $scope.payoutLastPaymentDate = null;
            $scope.payoutLastPaymentAmount = null;
            $scope.payoutPendingAmount = null
          }
        } else {
          growl.error("Failed");
          $scope.payoutHistoryList = [];
          $scope.payoutLastPaymentDate = null;
          $scope.payoutLastPaymentAmount = null;
          $scope.payoutPendingAmount = null
        }
      }).catch(function (err) {
        console.log("Err", err);
      });
    };

    $scope.transactionChange = function (status) {
      $scope.totalAgentRecords = 0;
      $scope.currentPage = 1;
      $scope.vm.params.page = 0;
      $scope.vm.params.status = status;
      getAgentAppointments($scope.vm.params);
    };

    $scope.pageChangedAgents = function (currentPage) {
      $scope.vm.params.page = currentPage - 1;
      getAgentAppointments($scope.vm.params);
    };

    $scope.selectPage = function () {
      //FIXME why empty block here macha?
    };

    $scope.getInventory = function (val) {
      if (val == 'all') {
        var fromDate = '01-Dec-2016';
        var toDate = new Date();
        var d = new Date(toDate).getDate() + 1;
        toDate.setDate(d);
        toDate = $filter('date')(toDate, "dd-MMM-yyyy");
        var data = {};
        data.fromDate = fromDate;
        data.toDate = toDate;
        getInventory(data);
      }
      if (val == 'today') {
        var fromDate = new Date();
        fromDate = $filter('date')(fromDate, "dd-MMM-yyyy");
        var toDate = new Date();
        var d = new Date(toDate).getDate() + 1;
        toDate.setDate(d);
        toDate = $filter('date')(toDate, "dd-MMM-yyyy");
        var data = {};
        data.fromDate = fromDate;
        data.toDate = toDate;
        getInventory(data);
      }
      if (val == 'month') {
        var fromDate = new Date();
        var toDate = new Date();
        var d = new Date(toDate).getDate() + 1;
        toDate.setDate(d);
        toDate = $filter('date')(toDate, "dd-MMM-yyyy");
        fromDate.setDate('1');
        fromDate = $filter('date')(fromDate, "dd-MMM-yyyy");
        var data = {};
        data.fromDate = fromDate;
        data.toDate = toDate;
        getInventory(data);
      }
      if (val == 'week') {
        var fromDate = new Date();
        var toDate = new Date();
        var d = new Date(toDate).getDate() + 1;
        toDate.setDate(d);
        toDate = $filter('date')(toDate, "dd-MMM-yyyy");
        var d = new Date(fromDate).getDate() - 6;
        fromDate.setDate(d);
        fromDate = $filter('date')(fromDate, "dd-MMM-yyyy");
        var data = {};
        data.fromDate = fromDate;
        data.toDate = toDate;
        getInventory(data);
      }
    };

    function getInventory(data) {
      data.agentId = $scope.agentId;
      agentService.getInventory(data).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.inventory = res.data.inventory;
          $scope.totalAmount = 0;
          for (var item in $scope.inventory) {
            $scope.totalAmount = $scope.totalAmount + $scope.inventory[item].totalamount;
          }
          $scope.inventoryGridOptions.api.setRowData($scope.inventory);

          if ($scope.inventory.length === 0) {
            $scope.noInventory = true;
          } else {
            $scope.noInventory = false;
          }
        } else {
          $scope.noInventory = true;
        }
      }, function (err) {
        console.error('Error in calling the API agentService.getInventory(data) ', err);
      });
    }

    function getAgentProfile(agentId) {
      $scope.getUserDocuments(agentId)

      agentService.getProfile(agentId).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.profileEdit = res.data.agent;
          $scope.documentsGridOptions.api.setRowData($scope.profileEdit.documents);

          $scope.profileEdit.rating = parseInt($scope.profileEdit.rating);
          // console.log("$scope.profileEdit", $scope.profileEdit);
          let keepGoing = true;
          if ($scope.profileEdit.priceCapsule && $scope.profileEdit.priceCapsule.id && $scope.profileEdit.userSalaried === false) {
            //checking for agent's price capsule when there's a priceCapsule field in agent's data available
            $scope.allPriceCapsuleDetails.forEach(elem => {
              if (keepGoing && $scope.profileEdit.priceCapsule.id === elem.id) {
                $scope.priceCapsuleProducts = elem.productsPricing;
                $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);

                keepGoing = false;
              }
            });
          } else {
            // checking for default price capsule
            $scope.priceCapsuleProducts = [];
            $scope.defaultPriceCapsule = '';
            if ($scope.allPriceCapsuleDetails && $scope.profileEdit.userSalaried === false) {
              $scope.allPriceCapsuleDetails.forEach(elem => {
                if (keepGoing && elem.default) {
                  $scope.defaultPriceCapsule = elem.name;
                  $scope.priceCapsuleProducts = elem.productsPricing;
                  $scope.priceCapsuleGridOptions.api.setRowData($scope.priceCapsuleProducts);

                  keepGoing = false;
                }
              });
            }
          }

          //get area names from array of area objects and set it VM in $scope.profileEdit.areaName
          $scope.profileEdit.areaName = false;
          if ($scope.profileEdit.area && $scope.profileEdit.area.length > 0) {
            $scope.profileEdit.areaName = [];
            for (let area of $scope.profileEdit.area) {
              $scope.profileEdit.areaName.push(area.area);
            }
          }

          // document parsing logic
          try {
            if (res && res.data && res.data.agent && res.data.agent.documents && res.data.agent.documents.length > 0) {
              $scope.profileEdit.documentcount = res.data.agent.documents.length;
            }
          }
          catch (e) {
            console.error('Error in getting document length ', e);
            $scope.profileEdit.documentcount = 0;
          }

          // get agent terms and conditions

          if (res && res.data && res.data.agent && res.data.agent.agreementSignature) {
            $scope.profileEdit.agreementSignature = res.data.agent.agreementSignature;
          }
          if (res && res.data && res.data.agent && res.data.agent.agreementPhoto) {
            $scope.profileEdit.agreementPhoto = res.data.agent.agreementPhoto;
          }
          if (res && res.data && res.data.agent && res.data.agent.agreementAccepted) {
            $scope.profileEdit.agreementAccepted = res.data.agent.agreementAccepted;
          }
          if (res && res.data && res.data.agent && res.data.agent.userSalaried) {
            $scope.profileEdit.userSalaried = res.data.agent.userSalaried;
          }

        } else if (res.status === "FAILURE") {
          $scope.noAgentFound = "No agent record found";
        } //TODO why no fall back to else condition
      }, function (err) {
        console.error('Error in calling the API agentService.getProfile(agentId) ', err);
      });
      agentService.retrieveAgentSellDetails(agentId).then(function (res) {
        if (res.status === "SUCCESS") {
          if (res.data) {
            $scope.acceptedCount = res.data.acceptedCount;
            $scope.lateCount = res.data.lateCount;
            $scope.completedCount = res.data.completedCount;
            $scope.assignedCount = res.data.assignedCount;
            $scope.averageRating = res.data.averageRating;
            $scope.transcationsAmount = Math.round(res.data.transcationsAmount * 100) / 100;
          }
        }
      }); //FIXME no error handling here
    }

    function getAgentAppointments(params) {
      agentService.getSellRquests(params).then(function (res) {
        $scope.totalAgentRecords = res.data.totalRecords;
        if (res.status === 'SUCCESS') {
          $scope.todayAppointment = [];
          $scope.upcomingAppointment = [];
          $scope.todayAppointment = res.data.sells;
          $scope.transactionsGridOptions.api.setRowData($scope.todayAppointment);

        } //FIXME no else condition
      }, function (err) {
        console.error('Error in calling the API agentService.getSellRquests(params) ', err);
      })
    }

    // get agent attendance
    function getAgentAttendance(agentId, currentPage, fromDate, toDate) {
      $global.displayLog("Inside get agent attendance", 'agentId', agentId);
      let data = {};
      data.agentId = agentId;
      data.page = currentPage;
      data.fromDate = fromDate;
      data.toDate = toDate;
      agentService.getAgentAttendance(data)
        .then(function (res) {
          if (res && res.data && res.data.agentAttendanceList) {
            $scope.agentAttendanceList = res.data.agentAttendanceList;
          }
          if (res && res.data && res.data.totalRecords) {
            $scope.agentAttendanceTotalRecords = res.data.totalRecords;
          }
        })
        .catch(function (err) {
          console.error("err = ", err);
        })
    }

    function getAllAgentPriceCapsulesPromises() {
      return new Promise((resolve, reject) => {
        agentService.getAgentPriceCapsules()
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data && res.data.results) {
              resolve(res);
            } else {
              reject('Unable to retrieve agent price capsule details');
            }
          })
          .catch(function (err) {
            reject(err);
          })
      })
    };

    // view attendance image
    $scope.viewInvoiceReceipt = function (url) {
      let pickupInstance = $uibModal.open({
        templateUrl: 'views/view-receipt-modal.html',
        controller: 'ViewReceiptModalController',
        size: "lg",
        resolve: {
          items: function () {
            return url;
          },
          pageTitle: function () {
            return "View Attendance Image";
          },
          request: function () {
            return false;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        //FIXME no close functionality here
      });
    };

    $scope.nextDate = function () {
      $scope.dateRangePicker.dateInput = {
        startDate: $moment($scope.dateRangePicker.dateInput.startDate).add(1, "days"),
        endDate: $moment($scope.dateRangePicker.dateInput.startDate).add(1, "days")
      };

      let page = $scope.attendanceCurrentPage;
      let agentId = $scope.agentId;
      let fromDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      let toDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      getAgentAttendance(agentId, page, fromDate, toDate);
    };

    $scope.previousDate = function () {

      $scope.dateRangePicker.dateInput = {
        startDate: $moment($scope.dateRangePicker.dateInput.startDate).subtract(1, "days"),
        endDate: $moment($scope.dateRangePicker.dateInput.startDate).subtract(1, "days")
      };

      let page = $scope.attendanceCurrentPage;
      let agentId = $scope.agentId;
      let fromDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      let toDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      getAgentAttendance(agentId, page, fromDate, toDate);

    };

    $scope.pageChangedAgentAttendance = function (currentPage) {
      $scope.attendanceCurrentPage = currentPage;
      let page = $scope.attendanceCurrentPage;
      let agentId = $scope.agentId;
      let fromDate = $moment($scope.dateRangePicker.dateInput.startDate).format("DD-MMM-YYYY");
      let toDate = $moment($scope.dateRangePicker.dateInput.endDate).format("DD-MMM-YYYY");
      getAgentAttendance(agentId, page, fromDate, toDate);
    };

    /*======================================================modal functions===========================================================*/
    $scope.addProfileImage = function (agentid, checkIfCorp) {
      if (!checkIfCorp) {
        checkIfCorp = null;
      }
      $scope.documentagentid = agentid;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-profileimage.html',
        controller: 'ProfileImageCtrl',
        size: "lg",
        backdrop: 'static', //TODO this value might not help in closing the modal
        resolve: {
          items: function () {
            return agentid;
          },
          invokedby: function () {
            return "agent";
          },
          corpimage: function () {
            return checkIfCorp;
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        getAgentProfile(agentid);
      });
    };

    $scope.addAgents = function () {
      $state.go("root.add-reo", { type: "AGENT", fromPage: "root.agents" });
      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/add-user.html',
      //   controller: 'AddUserCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return 'AGENT';
      //     }
      //   }
      // });
      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   $log.info('Modal dismissed at: ' + new Date());
      //   $scope.loadAgents();
      // });
    };

    $scope.addDocument = function (agentid) { //FIXME typo in argument casing
      $scope.documentagentid = agentid;
      var modalInstance = $uibModal.open({
        templateUrl: 'views/add-document.html',
        controller: 'AddDocumentCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          reqId: function () {
            return agentid;
          },
          from: function () {
            return "AGENT";
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        $scope.getUserDocuments(agentid);
        getAgentProfile(agentid);
      });
    };

    $scope.showPricerate = function () { //FIXME typo in method call
      var modalInstance = $uibModal.open({
        templateUrl: 'views/agents-prices.html',
        controller: 'PriceCtrl',
        size: "md", //FIXME why size is md here
        resolve: {
          items: function () {
          }
        }
      });





      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        //FIXME no close functionality here
      });
    };

    $scope.editAgentDetails = function () {


      $state.go("root.edit-reo", { id: $scope.profileEdit.agentId });
      // var modalInstance = $uibModal.open({
      //   templateUrl: 'views/agent-edit-profile.html',
      //   controller: 'AgentEditProfileCtrl',
      //   size: "lg",
      //   resolve: {
      //     items: function () {
      //       return $scope.profileEdit.agentId;
      //     }
      //   }
      // });
      // modalInstance.result.then(function (selectedItem) {
      //   $scope.selected = selectedItem;
      // }, function () {
      //   getAgentProfile($scope.agentId);
      // });
    };

    $scope.viewAppointment = function (view) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/agent-today-appointments.html',
        controller: 'AgentTodayAppointmentsCtrl',
        size: "lg",
        resolve: {
          items: function () {
            return view;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        //FIXME no close functionality here
      });
    };

    $scope.upcomingAppointments = function (upview) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/agent-upcoming-appointments.html',
        controller: 'AgentUpcomingAppointmentsCtrl',
        size: "md", //FIXME why size is md here
        resolve: {
          items: function () {
            return upview;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        //FIXME no close functionality here
      });
    };

    $scope.agentBid = function (bid) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/agent-bids.html',
        controller: 'AgentBidsCtrl',
        size: "md", //FIXME why size is md here
        resolve: {
          items: function () {
            return bid;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        //FIXME no close functionality here
      });
    };

    $scope.updateDetails = function (id) {
      let item, address;
      SellNow.getSellById(id)
        .then(function (res) {
          item = res.data.sell;
          address = res.data.sell.consumerAddress;

          var modalInstance = $uibModal.open({
            templateUrl: 'views/add-items.html',
            controller: 'AddItemsCtrl',
            size: "lg",
            resolve: {
              items: function () {
                return id; //confirmation id
              },
              item: function () {
                return item; //detailed info of the sell obj associated with the confirmation id
              },
              address: function () {
                return address; //consumer address
              }
            }
          });
          modalInstance.result.then(function (data) {
            getAgentAppointments($scope.vm.params);
          }, function () {
            //FIXME no close functionality here
          });

        })
        .catch(function (err) {
          console.error('Error in getting sell item by it\'s id', err);
        });
    };

    $scope.rescheduleConfirmation = function (sell, index) {
      var reschedule = $uibModal.open({
        templateUrl: 'views/reschedule-modal.html',
        controller: "ReschduleCtrl",
        size: "lg",
        resolve: {
          items: function () {
            return sell;
          }
        }
      });
      reschedule.result.then(function (selectedItem) {
        getAgentAppointments($scope.vm.params);
      }, function () {
        //FIXME no close functionality here
      });

    };

    $scope.declineSellItem = function (sell, index) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/deleteConfirmation.html',
        controller: "DeleteCtrl",
        size: "md",
        resolve: {
          items: function () {
            return sell;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        // console.log("$scope.agentId = ", $scope.agentId);
        getAgentProfile($scope.agentId);
      });
    };

  });
