'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddUserCtrl
 * @description
 * # AddUserCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddPricingCtrl', function($scope, authentication, wholesalerService, agentService, pricingService, role, products, consumer, $moment, items, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $log) {
  
    /*========================================global variables============================================================*/
    var consolidatedproductlist = {};
  
    /*==========================================default values init===================================================*/
    (function () {
      $scope.setHeader = items;
      $scope.role = role;
      $scope.vm = {};
    })();
    
    /*=========================================default service calls==============================================================*/
  
    /**
     * Get all wholesaler records
     */
    const getAllWholesalers = () => {
      return new Promise((resolve, reject) => {
        wholesalerService.usersLoad("WHOLESALER", "-1", true)
          .then(function(res) {
            if (res.status === "SUCCESS") {
              if (res.data.users.length > 0) { // non-empty list
                $scope.wholesalers = {
                  wholesalerid: null,
                  availableOptions: res.data.users
                };
                resolve($global.status.SUCCESS);
              } else { // empty list
                $scope.wholesaler_list = res.data.users;
                resolve($global.status.SUCCESS);
              }
            } else if (res.status === "FAILURE") {
              $scope.noWholesalerFound = true;
              reject($global.status.FAILURE);
            } else {
              $scope.noWholesalerFound = true;
              reject($global.status.FAILURE);
            }
          })
          .catch(function(err) {
            console.error('Error in calling wholesalerService.usersLoad() ', err);
            reject(err);
          })
      });
    };
  
    /**
     * Get all agents records
     */
    const getAllAgents = () => {
      return new Promise((resolve, reject) => {
        agentService.usersLoad("AGENT")
          .then(function(res) {
            if (res.status === "SUCCESS") {
              if (res.data.users.length > 0) {
                
                res.data.users.unshift({"name": "ALL", "userId": "ALL"});
                $scope.agents = {
                  agentid: null,
                  availableOptions: res.data.users,
                  commissiontypes: ["FLAT", "PERCENTAGE"],
                  tiers: ["TIER-1", "TIER-2", "TIER-3"]
                };
                resolve($global.status.SUCCESS);
              } else {
                $scope.agent_list = res.data.users;
                resolve($global.status.SUCCESS);
              }
              if (res.data.message === "No records found") {
                $scope.noAgentFound = true;
                reject($global.status.FAILURE);
              }
            } else if (res.status === "FAILURE") {
              $scope.noAgentFound = true;
              reject($global.status.FAILURE);
            } else {
              $scope.noAgentFound = true;
              reject($global.status.FAILURE);
            }
          })
          .catch(function(err) {
            console.error('Error in calling agentService.usersLoad() ', err);
            reject(err);
          });
      });
    };
  
    /**
     * Get all product categories
     */
    const getAllProductCategories = () => {
      return new Promise((resolve, reject) => {
        products.getProducts()
          .then(function(res) {
            if (res.status === "SUCCESS") {
              $scope.allproducts = res.data.categories;
              res.data.categories.forEach(function(elem, idx) {
                if (!consolidatedproductlist[elem.code]) {
                  consolidatedproductlist[elem.code] = [];
                }
                consolidatedproductlist[elem.code].push(elem.categoryId);
              });
              $scope.product_list = consolidatedproductlist;
              $scope.products = {
                agentid: null,
                availableOptions: consolidatedproductlist
              };
              if (res.data.message === "No records found") {
                $scope.noProductFound = true;
                reject($global.status.FAILURE);
              }
              resolve($global.status.SUCCESS);
            } else if (res.status === "FAILURE") {
              $scope.noProductFound = true;
              reject($global.status.FAILURE);
            } else {
              $scope.noProductFound = true;
              reject($global.status.FAILURE);
            }
          })
          .catch(function(err) {
            console.error('Error in calling getProducts() API ', err);
            reject(err);
          });
      });
    };
  
    (function() {
      getAllWholesalers()
        .then((allWholesalersResults) => {
          console.log("===================================wholesalerService.usersLoad()==================================================");
          console.log("allAgentsResults = ", allWholesalersResults);
          return getAllAgents();
        })
        .then((allAgentsResults) => {
          console.log("===================================agentService.usersLoad()==================================================");
          console.log("allAgentsResults = ", allAgentsResults);
          return getAllProductCategories();
        })
        .then((allProductCategories) => {
          console.log("===================================getAllProductCategories()==================================================");
          console.log("allProductCategories = ", allProductCategories);
        })
        .catch(error => {
          console.error('Error ', error);
        })
    })();
    
    
    $scope.addPricing = function() {
      
      $scope.submitted = true;
      $scope.data.role = $scope.role;
      
      $scope.allproducts.forEach(function(elem, idx) {
        if ($scope.data.categoryId[0] === elem.categoryId) {
          $scope.data.categoryName = elem.code;
        }
      });
  
      pricingService.addPricing($scope.data).then(function(res) {
        if (res.status === $global.status.SUCCESS) {
          growl.success(res.data.message);
          $scope.cancel();
        } else {
          let errors = res.error.errors;
          for (let i = errors.length - 1; i >= 0; i--) {
            $scope[errors[i].code] = errors[i].message;
            growl.error(errors[i].message)
          }
          if (res.error.errors.length === 0) {
            growl.error(res.error.message);
          }
        }
      });
    };
  
    $scope.cancel = function(res) {
      $uibModalInstance.dismiss(res);
    };
  
    function reloadPage() {
      setTimeout(function(){
        $state.go('root.products', {}, {reload: true});
      }, 500);
    }
    
  });
