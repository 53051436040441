'use strict';

angular.module('scrapQcrmApp')
  .controller('LoginCtrl', function($state, $scope, $rootScope, $global, $window, authentication, $cacheFactory, dashboard) {
    // Init function
    (function() {
      $global.removeLocalItem("authentication");
      $scope.city = ["Hyderabad"];
      dashboard.getAllCity().then(function(res) {
        if (res.status === 'SUCCESS' && res.data && res.data.cities) {
          $scope.city = [];
          for (var City in res.data.cities) {
            if ($scope.city.indexOf(res.data.cities[City].name) === -1) {
              $scope.city.push(res.data.cities[City].name);
            }
          }
        }
      })
    })();
    
    $scope.validate = function() {
      $scope.isUsername = $scope.crmLoginForm.userName.$error.required;
      $scope.isPassword = $scope.crmLoginForm.password.$error.required;
    };
    
    $scope.loginInfo = {};
    $scope.loginInfo.userName = '';
    $scope.loginInfo.password = '';
    $scope.loginInfo.city = '';
    $window.document.title = "ScrapQ CRM Login";
    
    // login onClick functionality
    $scope.doLogin = function(data) {
      $scope.loginError = null;
      $scope.submit = true;
      if (data && $scope.crmLoginForm.$valid) {
        authentication.login(data).then(function(res) {
          if (res.status === 'SUCCESS') {
            $global.setLocalItem("authentication", res, true);
            var cache = $cacheFactory.get('auth') || $cacheFactory('auth');
            cache.put("log", res);
            
            $rootScope.loginData = {};
            
            if (res.data.city) {//check city dropDown is equal to res.data.city
              if ($scope.loginInfo.city !== res.data.city) { //if equal then allow to login, else throw an error message
                $scope.loginError = "Invalid login. Kindly contact customer support";
                return;
              } else { // this is used to populate city dropdown in headers
                $rootScope.loginData.adminCity = res.data.city;
              }
            }
            
            $rootScope.loginData.username = res.data.name;
            $rootScope.loginData.role = res.data.role;
            $global.displayLog('checking login role data', '$rootScope.loginData', $rootScope.loginData);
            if (res.data.features) {
              $rootScope.loginData.features = res.data.features.split(',');
            }
            $rootScope.loginData.userid = res.data.userId;
            
            if (res.data.warehouseId) {
              $rootScope.loginData.warehouseId = res.data.warehouseId;
            }
            
            if ($scope.loginInfo.city) {
              $rootScope.loginData.city = $scope.loginInfo.city;
            } else {
              $rootScope.loginData.city = "Hyderabad";
            }
            
            $global.setLocalItem("loginData", $rootScope.loginData, true);
            $state.go("root.dashboard");
          } else if (res.status === 'FAILURE') {
            $scope.loginError = "Invalid username or password";
          }
        }, function(err) {
          console.error("Failed in authenticating ", err);
        });
      }
    }
  });
