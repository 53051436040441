'use strict';

angular.module('scrapQcrmApp')
    .directive('resizable', () =>
  ({
    link(scope, element, attrs, ctrl) {
      const options = angular.extend({}, scope.$eval(attrs.resizable));
      return element.resizable(options);
    }
  })
);
