'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ConsumersCtrl
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AquisitionCtrl', function ($scope, $http, $state, $rootScope, dashboard, $uibModal, $log, $global, consumer, wholesalerService, growl, BidService, $moment) {
    //Define the variable for page
    var searchData = {}, skip = 0, limit = 50, skipfactor = 50;

    $scope.itemsPerPage = 50;

    // ag-grid init
    (function () {
      var columnDefs = [
        {
          headerName: "Approve",
          cellRenderer: ApproveCellRenderer,
          resizable: true,
          maxWidth: 120,
          cellStyle: { 'height': '100%', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center' }
        },
        {
          headerName: "UserType",
          field: "userType",
          maxWidth: 120
        },
        {
          headerName: "Sales Person Name",
          field: "addedBy.name",
        },
        {
          headerName: "Customer Name",
          field: "name",
        },
        {
          headerName: "Mobile Number",
          // cellRenderer: HideMobileNumber,
          field: "mobileNo",
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: "Area",
          field: "address.address",
          cellStyle: { 'text-align': 'left', 'margin-left': '10px' }
        },
        {
          headerName: "Inception Date",
          field: "createdAt",
          cellRenderer: DateCellRenderer,
        },
        {
          headerName: "Interested",
          field: "status",
          maxWidth: 120,
          cellStyle: { 'padding-left': '50px' }
        },
        {
          headerName: "Customer Type",
          field: "categories.value",
          cellStyle: { 'padding-left': '30px' }
        },
      ];

      let rowData = [];

      $scope.gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
          minWidth: 150,
          filter: true,
          sortable: true,
          resizable: true
        },
        rowStyle: { background: '#f4f4f4' },
        // pagination: true,
        // paginationAutoPageSize: true,
        rowData: rowData,
        suppressCsvExport: false,
        suppressExcelExport: false,
        // suppressPaginationPanel:true,
        paginationPageSize: 10,
        getRowStyle: function (params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'white' };
          }
        },
        onGridReady: function (params) {
          params.api.sizeColumnsToFit();
          params.columnApi.autoSizeAllColumns();
          $scope.gridOptions.api.setRowData($scope.consumer_list);
          // console.log("this is on ready",params);
        },
        onCellClicked: function (event) {
          if (event && event.data && event.column.colId == "0") {
            if (event.data.isApproved) {
              return;
            }
            if (event.data.status = "Yes") {
              let consumerDet = event.data;
              $scope.approve(consumerDet);
            }
          } else {
          }
        },
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        }
      };

    })();

    //Approve Button Functionality
    function ApproveCellRenderer() {
    }

    ApproveCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        if (params.data.isApproved && params.data.status == "Yes") {
          var iconsDiv = `<button ng-click="approve()"  style="padding: 2px 4px;background-color : green" class="btn btn-default fa fa-check waves-effect waves-light pull-right changecursor" data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a"></button>`;
          this.eGui.setAttribute('title', 'Already approved as customer');
        } else if (!params.data.isApproved && params.data.status == "Yes") {
          var iconsDiv = `<button  style="padding: 2px 4px; color : #5fbeaa ;background-color:white;border:1px solid #5fbeaa" class="btn fa fa-ellipsis-h waves-effect waves-light pull-right changecursor" data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200"></button>`;
          this.eGui.setAttribute('title', 'Approve aquisition');
        } else {
          var iconsDiv = `<button  style="padding: 2px 4px;color : red;background-color:white;" class="btn fa fa-ban waves-effect waves-light pull-right changecursor" data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a"></button>`;
          this.eGui.setAttribute('title', 'Approve uninterested customer');
        }
        this.eGui.innerHTML = iconsDiv;
      }
    };

    ApproveCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    //UNCOMMENT THE BELOW CODE TO HIDE MOBILE NUMBERS FOR OTHER USERS
    //Hide mobile number of the Customers to all except for Super Admin and CRM User
    // function HideMobileNumber(){
    // }
    // HideMobileNumber.prototype.init = function(params) {
    //   // console.log("where is mobile no.",params.data);
    //   this.mobileNumberDiv = document.createElement('div');
    //   if(params.data != "" || params.data != "undefined"){
    //     if($rootScope.loginData.role == "ADMIN" || $rootScope.loginData.role == "CRMUSER"){
    //       let mobileNo = params.data.mobileNo ? params.data.mobileNo : "";
    //       this.mobileNumberDiv.innerHTML = "<div>"+mobileNo+"</div>";
    //     } else {
    //       let mobileNo = params.data.mobileNo ? params.data.mobileNo : "";
    //       let  hiddenMobileNo = mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, '*');
    //       this.mobileNumberDiv.innerHTML = "<div>"+hiddenMobileNo+"</div>";
    //     }
    //   }else {
    //     this.mobileNumberDiv.innerHTML = "";
    // }
    // }

    // HideMobileNumber.prototype.getGui = function(){
    //   return this.mobileNumberDiv;
    // }

    function DateCellRenderer() {
    }

    DateCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      if (params.data != "" || params.data != undefined || params.data != null) {
        console.log("params.data.createdAt = ", params.data.createdAt);
        let dateModified = $moment(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss');
        console.log("dateModified = ", dateModified);
        var iconsDiv = "<div>" + dateModified + "</div>";
        this.eGui.innerHTML = iconsDiv;
      }
    };

    DateCellRenderer.prototype.getGui = function () {
      return this.eGui;
    };

    // get all the sub areas of a city and send that array to sales/searchuser API request body
    const getSubAreasPromise = () => {
      return new Promise(((resolve, reject) => {
        dashboard.getAllCity()
          .then(function (res) {
            $scope.subAreasOfCurrentCity = [];
            if (res.status === 'SUCCESS' && res.data && res.data.cities) {
              $rootScope.allCityData = res.data.cities;
              $rootScope.allCityData.forEach(function (elem, idx) {
                if (elem.name === $rootScope.loginData.city && !$scope.subAreasOfCurrentCity.includes(elem.subarea)) {
                  $scope.subAreasOfCurrentCity.push(elem.subarea);
                }
              });
              resolve();
            }
          })
          .catch(function (err) {
            console.error("Error in fetching city details", err);
            reject();
          })
      }))
    };

    $scope.currentPage = 1;
    $scope.sortAscName = true;
    $scope.sortDscDate = true;
    
    //Load the consumer
    $scope.loadConsumer = function (refresh) {
      if ($scope.nameSortActivated) {
        if ($scope.sortAscName) {
          searchData.sort = {
            "byAgentName": 1
          };
        }
        if ($scope.sortDscName) {
          searchData.sort = {
            "byAgentName": -1
          };
        }
      }
      if ($scope.dateSortActivated) {
        if ($scope.sortAscDate) {
          searchData.sort = {
            "byCreatedDate": 1
          };
        }
        if ($scope.sortDscDate) {
          searchData.sort = {
            "byCreatedDate": -1
          };
        }
      }

      if ($scope.status !== undefined) {
        searchData.status = $scope.status;
      }

      if ($scope.isApproved !== undefined) {
        searchData.isApproved = $scope.isApproved;
      }

      getSubAreasPromise()
        .then(function () { // no res needed
          if ($scope.subAreasOfCurrentCity) {
            searchData.subAreasOfCurrentCity = $scope.subAreasOfCurrentCity;
          }
          if (refresh) {
            $scope.paging = false;
            searchData.skip = skip;
            searchData.limit = limit;
          }

          console.log("What is the searchData?",searchData);

          consumer.loadConsumer(searchData).then(function (res) {
            if (res.status === "Success") {
              $scope.initCalledOnce = true;
              if (refresh) {
                $scope.paging = true;
                $scope.currentPage = 1;
                $scope.totalRecords = res.total;
                $scope.totalPending = res.pending;
                $scope.totalCompleted = res.completed;
                $scope.totalApproved = res.approved;
              }
              if (res.data.length) {
                $scope.consumer_list = res.data;
                console.log("what is the response",$scope.consumer_list);
                if ($scope.consumer_list.length > 0) {
                  $scope.consumer_list.forEach((x, i) => {
                    if ($scope.consumer_list[i].status == "INTERESTED") {
                      $scope.consumer_list[i].status = "Yes";
                    } else if ($scope.consumer_list[i].status == "NOTINTERESTED") {
                      $scope.consumer_list[i].status = "No";
                    }
                  })
                }
                //ag-grid will display $scope.consumer_list in the data table
                $scope.gridOptions.api.setRowData($scope.consumer_list);

                $scope.noConsumerFound = false;
                if (res.data.length) {
                  $scope.consumer_list = res.data;
                  $scope.noConsumerFound = false;
                  for (var i in res.data) {
                    try {
                      var addrsplit = res.data[i].address.formattedAddress.split(/\,+/);
                      res.data[i]["area"] = addrsplit[addrsplit.length - 4].trim() + ", " + addrsplit[addrsplit.length - 3].trim();
                    } catch (e) {
                    }
                  }
                }
              } else {
                $scope.noConsumerFound = true;
                $scope.consumer_list = false;
              }
            } else {
              $scope.noConsumerFound = true;
              $scope.consumer_list = false;
            }
          }, function (err) {
            $scope.noConsumerFound = true;
            $scope.consumer_list = false;
          });
        })
        .catch(function (err) {
          console.error("Error in sales search user API", err);
        })
    }

    //Sort by name
    $scope.sortByName = function (type) {
      $scope.nameSortActivated = true;
      $scope.dateSortActivated = false;
      if (type == "ASC") {
        $scope.sortAscName = false;
        $scope.sortDscName = true;
      }
      if (type == "DSC") {
        $scope.sortDscName = false;
        $scope.sortAscName = true;
      }
      $scope.loadConsumer();
    }

    //Sort by date
    $scope.sortByDate = function (type) {
      $scope.nameSortActivated = false;
      $scope.dateSortActivated = true;
      if (type == "ASC") {
        $scope.sortAscDate = false;
        $scope.sortDscDate = true;
      }
      if (type == "DSC") {
        $scope.sortDscDate = false;
        $scope.sortAscDate = true;
      }
      $scope.loadConsumer();
    }

    //Pagination
    $scope.pageChanged = function (page) {
      searchData.skip = skipfactor * page - skipfactor;
      $scope.loadConsumer();
    }

    //Consumer details
    $scope.consumerDetails = function (consumer) {
      // console.log("what is consumerDetaisl", consumer);
      $scope.consumer = consumer;
      $scope.viewsList = false;
      $scope.viewsDetails = true;
    }

    //Date picker configaration
    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };

    Date.prototype.isValid = function () {
      // An invalid date object returns NaN for getTime() and NaN is the only
      // object not strictly equal to itself.
      return this.getTime() === this.getTime();
    };

    //Watch for date changes
    $scope.$watch('date', function () {
      var startDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
      var endDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
      if (!isNaN(new Date(startDate).getTime())) {
        searchData.fromDate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
      }
      if (!isNaN(new Date(endDate).getTime())) {
        searchData.toDate = $moment($scope.date.endDate).format('DD-MMM-YYYY');
      }
      if (searchData.fromDate && searchData.toDate) {
        $scope.loadConsumer(true);
      }
    }, false);

    //Watch for status changes and approve
    $scope.statusChanged = function () {
      $scope.loadConsumer(true);
    };

    //Init function self calling
    var init = function () {
      searchData = {
        fromDate: null,
        toDate: null,
        status: "ALL",
        sort: {
          "byCreatedDate": -1
        },
        isApproved: false,
        skip: skip,
        limit: limit
      };
      $scope.viewsList = true;
      $scope.viewsDetails = false;
      $scope.date = {
        startDate: null,
        endDate: null
      };
      //Load the consumer
      $scope.loadConsumer(true);
      $scope.nameSortActivated = false;
      $scope.dateSortActivated = true;
    }

    $scope.callDate = function () {
      if (!$scope.date.startDate && !$scope.date.endDate) {
        $scope.date = {
          startDate: $moment(new Date(), 'DD-MMM-YYYY').toDate(),
          endDate: $moment(new Date(), 'DD-MMM-YYYY').toDate()
        }
        angular.forEach(angular.element(".ranges > ul > li"), function (value, key) {
          var a = angular.element(value);
          var dateRangKey = value.getAttribute("data-range-key");
          if (dateRangKey == "Today") {
            a.addClass("active");
          } else {
            a.removeClass("active");
          }
        });
      }
    }

    //Initial call
    init();

    //Reset button action
    $scope.reset = function () {
      init();
    }

    //Back button action
    $scope.goBack = function () {
      init();
    }

    //Approve
    $scope.approve = function (consumerDet) {

      let items = consumerDet;
      items.referrer = "Aquisitions";
      
      if (consumerDet.userType == "Customer") {
        items.contact = items.name;
        items.name = items.organizationName;
        items.categories = [items.categories];
        consumer.setConsumerDetails(items);
        $state.go("root.add-consumer");
      } else if (consumerDet.userType == "Partner") {
        items.passCode = items.password;
        items.emailId = items.email;
        items.pannumber = items.panNumber;
        items.upiId = items.upiID;
        items.bankaccountnumber = items.accountNumber;
        items.ifsccode = items.ifsc;
        wholesalerService.setSalesDetails(items);
        $state.go("root.add-reo", { type: "WHOLESALER", fromPage: "root.vendors" });
      } else if (consumerDet.userType == "Agent") {
        items.passCode = items.password;
        items.emailId = items.email;
        items.pannumber = items.panNumber;
        items.upiId = items.upiID;
        items.bankaccountnumber = items.accountNumber;
        items.ifsccode = items.ifsc;
        wholesalerService.setSalesDetails(items);
        $state.go("root.add-reo", { type: "AGENT", fromPage: "root.vendors" });
      } else if (consumerDet.userType == "Vendor") {
        items.passCode = items.password;
        items.emailId = items.email;
        items.pannumber = items.panNumber;
        items.upiId = items.upiID;
        items.bankaccountnumber = items.accountNumber;
        items.ifsccode = items.ifsc;
        wholesalerService.setSalesDetails(items);
        $state.go("root.add-vendor", { fromPage: "root.vendors", type: "ADD" });
      }
    }

  });
