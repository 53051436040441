'use strict';

angular.module('scrapQcrmApp')
  .controller('DashboardCtrl', function($scope, $rootScope, $window, $interval, $global, dashboard, SellNow, $moment, uiCalendarConfig, $state) {
    
    // ============================================= $scope default init variables ==================================================
    (function() {
      $scope.todayDate = moment().format('DD-MMM-YYYY');
      $scope.fetchingstatus = true;
      
      $rootScope.loginData = {};
      $rootScope.loginData = $global.getLocalItem("loginData", true);
      console.log("$rootScope.loginData = ", $rootScope.loginData);
      
      $scope.status = "unavailable";
      
      $scope.datefetched = "TODAY";
      
      $scope.isCountAvailable = false;
    })();
    
    // ============================================= $window default init variables ==================================================
    (function() {
      $window.document.title = "ScrapQ";
      $window.document.title += " - " + $rootScope.loginData.username + "(" + $rootScope.loginData.role + ")";
    })();
    
    // ============================================= $scope default events and config ==================================================
    (function() {
      $scope.changeTo = 'Hungarian';
      $scope.events = [];
      $scope.monthEvents = [];
      $scope.eventSources = [];
    })();
    
    // ============================================= clear the stats ==================================================
    function clearStats() {
      $global.displayLog("clearing stats");
      $scope.pending = 0;
      $scope.completed = 0;
      $scope.declined = 0;
      $scope.cancelled = 0;
      $scope.req_initiated = 0;
      $scope.req_closed = 0;
      $scope.rej_by_crm = 0;
      $scope.accepted_by_agent = 0;
      $scope.late = 0;
      $scope.averagelate = 0;
      $scope.averagelatestr = "";
      $scope.preferredSlotExpiredCount = 0;
    }
    
    if ($rootScope.loginData.role === "WAREHOUSEUSER") {
      $state.go('root.inventoryList', {index: 0});
    }
    
    getSellCountsForDate('TODAY');
    
    dashboard.unavailable($scope.status)
      .then(function(res) {
        $scope.UnavailableAgentCount = res.data.counts[0].value;
      })
      .catch(function(err) {
        console.error("Error in dashboard.unavailable($scope.status) API ", err);
      });
    
    function getSellCountsForDate(date, notextupdate) {
      if (!notextupdate) {
        $scope.hoverDate = "Fetching data for " + date;
        $scope.fetchingstatus = true;
      }
      $scope.datefetched = date;
      dashboard.sellCount(date)
        .then(function(res) {
          $global.displayLog("dashboard sell counts check");
          console.log(res.data.counts);
          $scope.hoverDate = date + ' APPOINTMENTS';
          $scope.fetchingstatus = false;
          
          $scope.pending = null;
          $scope.completed = null;
          $scope.declined = null;
          $scope.cancelled = null;
          $scope.req_initiated = null;
          $scope.req_closed = null;
          $scope.rej_by_crm = null;
          $scope.accepted_by_agent = null;
          
          angular.forEach(res.data.counts, function(data, key) {
            
            if (data.name === "ASSIGNED_TO_AGENT") {
              $scope.pending = data.value;
            }
            if (data.name === "REQUEST_COMPLETED") {
              $scope.completed = data.value;
            }
            if (data.name === "DECLINED_BY_AGENT") {
              $scope.declined = data.value;
            }
            if (data.name === "CANCELLED_BY_CONSUMER") {
              $scope.cancelled = data.value;
            }
            if (data.name === "REQUEST_INITIATED") {
              $scope.req_initiated = data.value;
            }
            if (data.name === "REQUEST_CLOSED") {
              $scope.req_closed = data.value;
            }
            if (data.name === "REJECTED_BY_CRM") {
              $scope.rej_by_crm = data.value;
            }
            if (data.name === "ACCEPTED_BY_AGENT") {
              $scope.accepted_by_agent = data.value;
            }
          });
          
          if (res.data && res.data.slotCounts.length > 0) {
            $scope.isCountAvailable = true;
            var slotCounts = res.data.slotCounts;
            $scope.slotCountArr = [0, 0, 0, 0, 0, 0];
            (slotCounts).forEach(function(slot, index) {
              $scope.slotCountArr[slot.id] = slot.slotCount;
            });
          } else {
            $scope.isCountAvailable = false;
          }
        })
        .catch(function(err) {
          console.error("Error in dashboard.sellCount(date) ", err);
        })
    }
    
    $scope.allRequests = function(params) {
      $scope.pending = 0;
      $scope.completed = 0;
      $scope.declined = 0;
      $scope.cancelled = 0;
      $scope.req_initiated = 0;
      $scope.req_closed = 0;
      $scope.rej_by_crm = 0;
      $scope.accepted_by_agent = 0;
      $scope.late = 0;
      $scope.averagelate = 0;
      $scope.averagelatestr = "";
      $scope.preferredSlotExpiredCount = 0;
      
      SellNow.allRequests(params)
        .then(function(res) {
          $scope.totalAgentRecords = res.data.totalRecords;
          $scope.pendingSells = res.data.sells;
          
          $scope.pendingSells.forEach(function(elem, idx) {
            if ($scope.pendingSells[idx].agentarrived) {
              if ($scope.pendingSells[idx].statusCode === "ACCEPTED_BY_AGENT") {
                $scope.pendingSells[idx].status = "Arrived";
                $scope.pendingSells[idx].isArrivedEffect = true;
              }
            }
            $scope.pendingSells[idx]['preferredSlotEnd'] = $scope.pendingSells[idx]['preferredSlot'].replace(/.*-/, '');
            $scope.pendingSells[idx]['preferredSlotEndDate'] = $scope.pendingSells[idx]['preferredDate'] + " " + $scope.pendingSells[idx]['preferredSlotEnd'];
            
            if (moment() > moment($scope.pendingSells[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm') &&
              $scope.pendingSells[idx].status.match(/assigned|initiated|accepted|declined/i)) {
              $scope.pendingSells[idx]['preferredSlotExpired'] = true;
              $scope.preferredSlotExpiredCount += 1;
            }
            
            console.log("===========================================================================================================");
            console.log("completed date = ", moment($scope.pendingSells[idx]['completedDate'] + " " + $scope.pendingSells[idx]['completedTime'], 'DD-MMM-YYYY HH:mm').toString());
            console.log("preferred date = ", moment($scope.pendingSells[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm').toString());
            console.log("is completed greater than preferred date = ", moment($scope.pendingSells[idx]['completedDate'] + " " + $scope.pendingSells[idx]['completedTime'], 'DD-MMM-YYYY HH:mm') > moment($scope.pendingSells[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm'));
            
            if (moment($scope.pendingSells[idx]['completedDate'] + " " + $scope.pendingSells[idx]['completedTime'], 'DD-MMM-YYYY HH:mm') > moment($scope.pendingSells[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm')) {
              
              $global.displayLog("Inside late logic");
              
              //completed
              var completed = moment($scope.pendingSells[idx]['completedDate'] + " " + $scope.pendingSells[idx]['completedTime'], 'DD-MMM-YYYY HH:mm');
              
              //preffered
              var preferred = moment($scope.pendingSells[idx]['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm');
              
              var ms = completed.diff(preferred);
              var d = moment.duration(delayby);
              var delayby = moment.utc(ms).format("HH:mm:ss");
              console.log(completed, preferred, delayby, ms);
              $scope.pendingSells[idx]['completedAfterPreferredSlotExpired'] = true;
              $scope.late += 1;
              
              $scope.averagelate += ms;
              console.log();
              var duration = moment.duration($scope.averagelate / $scope.late, 'milliseconds');
              $scope.averagelatestr = duration.hours() + "H:" + duration.minutes() + 'M:' + duration.seconds() + "S"
            }
          })
        })
        .catch(function(err) {
          console.error("Error in SellNow.allRequests(params) ", err);
        })
    };
    
    // for default page load, get today's stats
    $scope.allRequests({
      city: $rootScope.loginData.city,
      fromdate: $moment().format('DD-MMM-YYYY'),
      page: -1,
      todate: $moment().format('DD-MMM-YYYY')
    });
    
    dashboard.statistics()
      .then(function(res) {
        if (res.status === "SUCCESS") {
          var statistics = res.data.userList;
          for (var i = statistics.length - 1; i >= 0; i--) {
            if (statistics[i].usertype === 'AGENT') {
              $scope.agentCount = statistics[i].count;
            }
            if (statistics[i].usertype === 'CONSUMER') {
              $scope.consumerCount = statistics[i].count;
            }
          }
        }
      })
      .catch(function(err) {
        console.error(err);
      });
    
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    
    var reqObj;
    
    function getCalenderInfo(from, to) {
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEvents');
      dashboard.getCalendar(from, to)
        .then(function(res) {
          reqObj = res.data.sellCalendars;
          parseCalenderObj(reqObj);
        })
        .catch(function(err) {
          console.error(err);
        });
    }
    
    $scope.dateHover = function(event, jsview, view) {
      clearStats();
      
      var hoverdate = moment(event.start, 'YYYY-MM-DD').format('DD-MMM-YYYY');
      
      getSellCountsForDate(hoverdate);
      
      // get hover date's stats
      $scope.allRequests({
        city: $rootScope.loginData.city,
        fromdate: hoverdate,
        page: -1,
        todate: hoverdate
      });
      
      $scope.dateHover = hoverdate + ' Appointments';
    };
    
    $scope.dateHoverOut = function(event, jsview, view) {
      // console.log("Event hover out date: ", moment(event.start, 'YYYY-MM-DD').format('DD-MMM-YYYY'));
      clearStats();
      
      getSellCountsForDate('TODAY');
      
      // get today's stats
      $scope.allRequests({
        city: $rootScope.loginData.city,
        fromdate: $moment().format('DD-MMM-YYYY'),
        page: -1,
        todate: $moment().format('DD-MMM-YYYY')
      });
      
      $scope.dateHover = "Today Appointments";
    };
    
    function heatMapColorforValue(value, date) {
      var h = (1 - value) * 230;
      var l = value * 90;
      return "hsl(" + h + ", 100%, " + l + "%)";
    }
    
    function parseCalenderObj(reqObj) {
      var maxdaycount = Math.max.apply(Math, reqObj.map(function(o) {
        return o.dayCount;
      }));
      
      for (var i = reqObj.length - 1; i >= 0; i--) {
        if (maxdaycount < reqObj[i].dayCount) {
          maxdaycount = reqObj[i].dayCount
        }
      }
      var monthdata = [];
      for (var i = reqObj.length - 1; i >= 0; i--) {
        var month = {
          title: reqObj[i].dayCount,
          start: reqObj[i].day,
          stick: true,
          backgroundColor: heatMapColorforValue(reqObj[i].dayCount / maxdaycount, reqObj[i].day),
          textColor: "#fff",
          borderColor: "#eee"
        };
        if (reqObj[i].dayCount / maxdaycount > 0.62) {
          month.textColor = "#000"
        }
        $scope.monthEvents.push(month);
      }
    }
    
    $scope.eventSources.push($scope.monthEvents);
    
    $scope.appointmentList = function(date, jsEvent, view) {
      var caseNumber = Math.floor((Math.abs(jsEvent.offsetX + jsEvent.currentTarget.offsetLeft) / $(this).parent().parent().width() * 100) / (100 / 7));
      var table = $(this).parent().parent().parent().parent().children();
      $(table).each(function() {
        if ($(this).is('thead')) {
          var tds = $(this).children().children();
          var dateClicked = $(tds[caseNumber]).attr("data-date");
          dateClicked = moment(dateClicked, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          $state.go('root.appointments', {fromdate: dateClicked, todate: dateClicked})
        }
      });
      
    };
    
    /* config object */
    $scope.uiConfig = {
      calendar: {
        height: 450,
        header: {
          left: 'title',
          center: '',
          right: 'prev,next'
        },
        events: function(start, end) {
          getCalenderInfo(start.format("DD-MMM-YYYY"), end.format("DD-MMM-YYYY"));
        },
        eventRender: function(event, element, view) {
          if (view.name === "month") {
            element.find('.fc-title').html(event.dayCount);
          } else {
            event.title = event.title;
          }
        },
        eventMouseover: $scope.dateHover,
        eventMouseout: $scope.dateHoverOut,
        eventClick: $scope.appointmentList,
        loading: function(bool) {
        },
        eventAfterAllRender: function(view) {
        }
      }
    };
  });
