'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:DeleteCustomPriceCtrl
 * @description
 * # DeleteCustomPriceCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
    .controller('DeleteCustomPriceCtrl', function($scope, $rootScope, $moment,$uibModalInstance, products, items, $global, $uibModal, $window, growl, $log , $stateParams) {
    	
        $scope.cancel = function(res) {
            $uibModalInstance.dismiss(res);
            console.log(res)
        };
   
        $scope.priceDetails = items;   
        $scope.deletePrice = function(){
            products.deleteCustomPrice($scope.priceDetails).then(function(res){
                console.log(res)
                if (res.status=="SUCCESS") {
                    growl.success("Custom Price deleted successfully!");
                    $uibModalInstance.dismiss(res);
                }else{
                    growl.error("Custom Price could not be deleted!");
                }
            })
        };
        
    });