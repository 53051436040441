'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:ConsumerPickupsModalCtrl
 * @description
 * # ConsumerPickupsModalCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('OpenLocationModalCtrl', function($scope, $uibModalInstance, growl, $filter, items, consumer, $uibModal, SellNow) {
        $scope.feedItem = {};

        $scope.render = true;

        $scope.lat = parseFloat(items.latitude);
        $scope.long = parseFloat(items.longitude);

        $scope.center = [$scope.lat, $scope.long];
        console.log($scope.lat, $scope.long)

        $scope.$on('mapInitialized', function (event, map)
        {
            window.setTimeout(function() {
                var center = map.getCenter();
                window.google.maps.event.trigger(map, 'resize');
                map.setCenter(center);
            }, 100)
        });


        $scope.cancel = function(res) {
            $uibModalInstance.close(res);
        };


    });
