'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AccountingReportsController
 * @description
 * # AccountingReportsController
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('AccountingReportsController', function ($scope, $http, $state,authentication, $filter, $moment, $stateParams,$global, $rootScope, saleOrderService, dashboard, $uibModal, $log, consumer, prospect, growl, BidService) {

        $scope.inrFilter = $filter('INR');

        $scope.date = {
            startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
            endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
        };

        $scope.vm = {
            params: { "city": $rootScope.loginData.city }
        };
        $rootScope.loginData = $global.getLocalItem("loginData", true);

        (function () {
            var data = {
                "city": $rootScope.loginData.city,
                "userType": $rootScope.loginData.role,
                "module": "Reports",
                "subModule": "Accounting",
                "type": "Sales"
            };
            authentication.getUserRoleMetrics(data).then(function (res) {
                if (res.status == "SUCCESS") {
                    $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
                    $scope.gridOptions.columnDefs.forEach(function (col, index) {
                        if ($scope.disabledFeilds.includes(col.field)) {
                            $scope.gridOptions.columnDefs.splice(index, 1);
                        }
                    });
                    $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs);
                } else {
                    $scope.disabledFeilds = [];
                }
            }).catch(function (err) {
                $scope.disabledFeilds = [];
            })
        })();
        (function () {
            let columnDefs = [
                {
                    headerName: "Gate Pass Number",
                    field: "gatePassNumber",
                    flex: 2,
                    cellStyle: { 'text-align': 'center' },
                    maxWidth: 170,
                    

                },
                {
                    headerName: "Sale Date",
                    field: "dateSale",
                    cellStyle: { 'text-align': 'center' },
                },
                {
                    headerName: "Vendor Name",
                    field: "vendorName",
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Vendor City",
                    field: "vendorCity",
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Warehouse Name",
                    field: "warehouseName",
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    headerName: "Warehouse City",
                    field: "warehouseCity",
                    cellStyle: { 'text-align': 'left' }
                },
                {
                    headerName: "Status",
                    field: "status",
                    cellStyle: { 'text-align': 'left' }
                },
                {
                    headerName: "Total Qty",
                    field: "totalQuantity",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer

                },
                {
                    headerName: "Sale Value",
                    field: "saleValue",
                    cellStyle: { 'text-align': 'right' },
                    cellRenderer: AmountCellRenderer

                }
            ];
            columnDefs.forEach(function (colObj) {
                colObj.headerTooltip = colObj.headerName;
            })
            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                tooltipShowDelay: 0,
                suppressCsvExport: false,
                suppressExcelExport: false,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.reports);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                }
            }; //End of gridOptions//


            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '0.00';
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

        })();

        (function () {

            $scope.loadData = function () {

                let data = {
                    "fromDate": $scope.vm.params.fromdate,
                    "toDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                };
                saleOrderService.getSalesAccountingReports(data).then(function (res) {
                    $scope.labelCounts={
                        totalGtv:res.data && res.data.totalGTV ? $scope.inrFilter(res.data.totalGTV) : "0.00",
                        totalVendors:res.data && res.data.totalVendorsCount ? res.data.totalVendorsCount : 0,
                        totalQty:res.data && res.data.totalQTY ? $scope.inrFilter(res.data.totalQTY) : "0.00",
                        totalSaleOrders:res.data && res.data.totalSaleOrdersCount ? res.data.totalSaleOrdersCount : 0
                    }
                    if (res.status.toUpperCase() == "SUCCESS") {
                        $scope.reports = res.data.reports;

                        if ($scope.reports && res.data.distinctItems) {
                            var columns = [];
                            res.data.distinctItems.forEach(function (item) {
                                columns.push({
                                    headerName: item,
                                    field: "distinctItem",
                                    flex: 2,
                                    cellStyle: { 'text-align': 'right' },
                                    valueFormatter: $scope.stringFormatter,
                                })
                            });
                            $scope.gridOptions.api.setColumnDefs($scope.gridOptions.columnDefs.concat(columns));
                            $scope.gridOptions.api.setRowData($scope.reports);
                        }
                    } else {
                        if (res.status.toUpperCase() == "FAILURE" && res.error && res.error.code == "EC_MAX_DATE_RANGE_EXCEEDED") {
                            growl.error(res.error.message);
                        } else {
                            $scope.reports = [];
                            $scope.gridOptions.api.setRowData($scope.reports);
                        }
                    }
                })
            }
            $scope.loadData();
        })();

        $scope.stringFormatter = function (params) {
            if (params.colDef.field == "distinctItem") {
                try {
                    return params.data.items[params.colDef.headerName]?params.data.items[params.colDef.headerName]:"0";
                } catch (err) {
                    return "0"
                }
            }
        }



        console.log('4stateparam    : ', $stateParams.fromdate);
        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };

        $scope.setStartDate = function () {
            $scope.date.startDate = moment().subtract(4, "days").toDate();
        };

        $scope.setRange = function () {
            $scope.date = {
                startDate: moment().subtract(5, "days"),
                endDate: moment()
            };
        };

        //Watch for date changes
        $scope.$watch('date', function (newDate) {
            $scope.selectedRange = $('#daterange3').data('daterangepicker').chosenLabel;
            $scope.transactionChange();
        }, false);

        $scope.transactionChange = function () {

            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;

            if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
                $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
                $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

                if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
                    $scope.vm.params.singledayquery = true;
                }
                $scope.loadData();
            } else {
                console.error("Invalid date, please select valid date range");
                console.error("startDate = ", $scope.date.startDate);
                console.error("endDate = ", $scope.date.endDate);
                growl.error("Invalid date, please select valid date range");
            }
        };

        $scope.nextDate = function () {
            $scope.paging = false;
            $scope.date = $global.getNextDateRanges($scope.selectedRange, $scope.date.endDate);
            // $scope.date = {
            //     startDate: moment($scope.date.endDate).add(1, "days"),
            //     endDate: moment($scope.date.endDate).add(1, "days")
            // };
            $scope.transactionChange();
        }
        $scope.previousDate = function () {
            $scope.paging = false;
            $scope.date = $global.getPrevDateRanges($scope.selectedRange, $scope.date.startDate);
            // $scope.date = {
            //     startDate: moment($scope.date.startDate).subtract(1, "days"),
            //     endDate: moment($scope.date.startDate).subtract(1, "days")
            // };
            $scope.transactionChange();
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };
    });
