'use strict';

/**
 * @ngdoc function
 * @name scrapQcrmApp.controller:UnAssignedCtrl
 * @description
 * # UnAssignedCtrl
 * Controller of the scrapQcrmApp
 */
angular.module('scrapQcrmApp')
  .controller('UnAssignedCtrl', function ($scope, $filter, apartments, $moment, $uibModal, $interval, $global, dashboard, SellNow, $log, $stateParams, agentAppointments, DTOptionsBuilder, DTColumnDefBuilder, $rootScope, growl) {
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    $scope.inrFilter = $filter('INR');

    $scope.date = {
      startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
      endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
    };

    $scope.payMethod = false;

    $scope.resetCountLabels = function () {
      $scope.countLabels = {
        groupAppointments: 0,
        appointments: 0,
        completed: 0,
        pending: 0,
        cancelled:0
      }
    }

    // TODO - Make these dynamic
    $scope.uniqueStatuses = [
      "ACCEPTED_BY_AGENT",
      "ASSIGNED_TO_AGENT",
      "CANCELLED_BY_CONSUMER",
      "DECLINED_BY_AGENT",
      "REQUEST_COMPLETED",
      "REQUEST_INITIATED",
      "AGENT_ARRIVED",
    ];

    $scope.uniqueUnassignedStatuses = [
      "DECLINED_BY_AGENT",
      "REQUEST_INITIATED"
    ];

    $scope.uniqueTypes = [
      "Residence",
      "Corporate"
    ];

    $scope.uniqueTags = [
      "CRM",
      "CONSUMER",
      "WEBSITE",
      "SMS",
      "WEB",
      "AGENT",
      "MOBILE",
      "MYGATE",
      "WHATSAPP"
    ];

    $scope.uniqueSlots = [
      "06:00-08:00",
      "08:00-10:00",
      "10:00-12:00",
      "12:00-14:00",
      "14:00-16:00",
      "16:00-18:00",
      "18:00-20:00",
      "20:00-22:00",
      "22:00-00:00",
      "00:00-02:00",
      "02:00-04:00",
      "04:00-06:00"
    ];

    // Appointments Grid Init
    function CellRender() {
    }
    CellRender.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.style.textAlign = 'center';
      if (params.value !== "" || params.value !== undefined || params.value !== null) {
        $scope.actionButtonClassList = "";
        let iconsDiv = "";
        if (params.column.colId == "type" && params.data) {
          iconsDiv = `<i class="fa ` + params.data.image + `"></i>`;
        }
        if (params.column.colId == "confirmationId" && params.data) {
          iconsDiv = `<p style="line-height:3" class="` + params.data.latestatus + `">` + params.data.confirmationId + `</p>`;
        }
        if (params.column.colId == "communityAppointmentId" && params.data) {
          iconsDiv = `<p style="line-height:3" class="` + params.data.latestatus + `">` + params.data.communityAppointmentId + `</p>`;
        }
        if (params.column.colId == "tags" && params.data) {
          if (params.data.isRescheduledCount) {
            iconsDiv = `<span  class="label label-danger">RESCHEDULED <span>x` + params.data.isRescheduledCount + `</span></span>`;
          }
          if (params.data.firstrequest) {
            iconsDiv = iconsDiv + `<span  class="label label-primary">NEW</span>`;
          }
          if (params.data.source) {
            iconsDiv = iconsDiv + `<span  class="label label-primary">` + params.data.source + `</span>`;
          }
        }
        if (params.column.colId == "source" && params.data) {
          if (params.data.isRescheduledCount) {
            iconsDiv = `<span  class="label label-danger">RESCHEDULED <span>x` + params.data.isRescheduledCount + `</span></span>`;
          }
          if (params.data.firstrequest) {
            iconsDiv = iconsDiv + `<span  class="label label-primary">NEW</span>`;
          }
          if (params.data.source) {
            iconsDiv = iconsDiv + `<span  class="label label-primary">` + params.data.source + `</span>`;
          }
        }
        if (params.column.colId == "rescheduledCount" && params.data) {
          if (params.data.rescheduledCount) {
            iconsDiv = `<span  class="label label-danger">RESCHEDULED <span>x` + params.data.rescheduledCount + `</span></span>`;
          }
        }
        this.eGui.innerHTML = iconsDiv;
      }
    };
    CellRender.prototype.getGui = function () {
      return this.eGui;
    };

    function cellStyleFormmatter(params) {
      // console.log(params.data)
      if (params.column.colId == "status" && params.data) {
        return {
          color: 'white',
          fontSize: '11px !important',
          fontWeight: '500',
          backgroundColor: params.data.statusColor,
          textAlign: 'center',
          animation: params.data.animation
        }
      }
    }

    function valueFormatter(params) {
      if (params.column.colId == "status")
        return $global.formatAppointmentStatus(params.value)
    }

    var appointmentsColumnDefs = [
      {
        headerName: "Type",
        field: "type",
        cellRenderer: CellRender,
        maxWidth: 35,
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueTypes; // fetch values 
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
      },
      {
        headerName: "ID",
        field: "confirmationId",
        cellRenderer: CellRender,
        cellStyle: stringStyleFormatter,
        filter: 'agTextColumnFilter',
        maxWidth: 101,
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Slot",
        field: "preferredSlot",
        maxWidth: 85,
        valueFormatter: params => params.data.preferredSlotName,
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueSlots; // fetch values 
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
      },
      {
        headerName: "Customer",
        field: "consumerName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Agent",
        field: "agentName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Status",
        field: "status",
        maxWidth: 100,
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueStatuses; // fetch values from server
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
        cellStyle: cellStyleFormmatter,
        valueFormatter: valueFormatter
      },
      {
        headerName: "Pay Mode",
        field: "payment",
        maxWidth: 100,
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Amount",
        field: "totalAmountWithCharges",
        maxWidth: 80,
        cellStyle: { 'textAlign': 'right' },
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00'
      },
      {
        headerName: "Date",
        field: "completedOn",
        maxWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'],
          closeOnApply: true,
        },
        valueFormatter: params => params.data.completedOn ? $moment(params.data.completedOn).format('DD-MM-YYYY HH:mm') : ""
      },
      {
        headerName: "Area",
        field: "area",
        maxWidth: 105,
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "App",
        field: "source",
        cellRenderer: CellRender,
        filter: 'agSetColumnFilter',
        maxWidth: 80,
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueTags; // fetch values from server
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
      },
      {
        headerName: "Rescheduled",
        field: "rescheduledCount",
        cellRenderer: CellRender,
        filter: 'agNumberColumnFilter',
        maxWidth: 110,
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Preferred Date",
        field: "preferredDate",
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'],
          closeOnApply: true,
        },
        valueFormatter: params => params.data.preferredDate ? $moment(params.data.preferredDate).format('DD-MM-YYYY') : "",
        hide: true
      }
    ];
    // implements ag-grid dataSource interface
    $scope.appointmentsDataSource = {
      getRows(params) {

        let fromdate = moment($scope.date.startDate, 'WWW MMM DD YYYY').format("DD-MMM-YYYY");
        let todate = moment($scope.date.endDate, 'WWW MMM DD YYYY').format("DD-MMM-YYYY");

        let city = $rootScope.loginData.city;

        let requestBody = {};
        requestBody.fromDate = fromdate;
        requestBody.toDate = todate;

        if (city) {
          requestBody.city = city;
        }

        let startRow = params.request.startRow;
        let endRow = params.request.endRow;
        let sortModel = params.request.sortModel;
        let filterModel = params.request.filterModel;

        if (!filterModel.hasOwnProperty("status")) {
          filterModel = Object.assign(filterModel, {
            status: {
              values: $scope.uniqueUnassignedStatuses,
              filterType: "set"
            }
          });
        }

        if (filterModel.hasOwnProperty("preferredSlot")) {
          filterModel.preferredSlot.values = filterModel.preferredSlot.values.map(value => {
            return $scope.uniqueSlots.indexOf(value);
          });
        }

        let agGridRequestParams = {};
        // pagination is present by default, no validation needed here
        agGridRequestParams.startRow = startRow;
        agGridRequestParams.endRow = endRow;

        if (sortModel && sortModel.length > 0) {
          agGridRequestParams.sortModel = sortModel;
        }
        agGridRequestParams.filterModel = filterModel;

        requestBody.agGridRequestParams = agGridRequestParams;

        console.log("requestBody = ", JSON.stringify(requestBody, null, 2));

        SellNow.getAppointmentsGrid(requestBody)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.sells && res.data.sells.length > 0) {
              $scope.sells = $scope.formatCells(res.data.sells);

              $scope.countLabels.appointments = res.data.totalSellsCount;
              if ($scope.showSellRequests || !$scope.showGroupRequests) {
                $scope.countLabels.pending = res.data.totalPendingSellsCount;
                $scope.countLabels.completed = res.data.totalCompletedSellsCount;
                $scope.countLabels.cancelled = res.data.totalCancelledSellsCount;

              };
              params.successCallback($scope.sells, res.data.totalRecordsCount);
            } else if (res.status === "SUCCESS") {
              $scope.countLabels.appointments = res.data.totalSellsCount;
              if ($scope.showSellRequests || !$scope.showGroupRequests) {
                $scope.countLabels.pending = res.data.totalPendingSellsCount;
                $scope.countLabels.completed = res.data.totalCompletedSellsCount;
                $scope.countLabels.cancelled = res.data.totalCancelledSellsCount;

                console.error(res);
                growl.error("No results found");
              };
              $scope.appointmentsGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();
              params.failCallback()
            } else {
              $scope.countLabels.completed = 0;
              $scope.countLabels.pending = 0;
              $scope.countLabels.cancelled = 0;

              console.error(res);
              if ($scope.showSellRequests || !$scope.showGroupRequests) {
                growl.error("No results found");
              }
              $scope.appointmentsGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();
              params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
            }
          })
          .catch(function (err) {
            if ($scope.showSellRequests || !$scope.showGroupRequests) {
              $scope.countLabels.completed = 0;
              $scope.countLabels.pending = 0;
              $scope.countLabels.cancelled = 0;

            }
            console.error(err);
            growl.error("No results found");
            $scope.appointmentsGridOptions.api.showNoRowsOverlay();
            $rootScope.hideAgGridLoadingRow();
            params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
          })
      }
    };

    $scope.appointmentsGridOptions = {
      columnDefs: appointmentsColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      rowHeight: 39,
      // suppressCsvExport: true,
      // suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      rowModelType: 'serverSide', // use the server-side row model instead of the default 'client-side'
      pagination: true,
      cacheBlockSize: 1000,
      blockLoadDebounceMillis:100,

      paginationPageSize: 1000,

      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        // $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);

      },
      onCellClicked: function (event) {
        OpenRequest(event.data, $scope.allRequests, $scope.vm.params);
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };
    $scope.getSellRequests = function () {
      $scope.resetCountLabels();
      if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
        $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);
      }
      if ($scope.groupAppointmentsGridOptions && $scope.groupAppointmentsGridOptions.api) {
        $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      }
      $scope.showSellRequests = true;
      $scope.showGroupRequests = false;
    }
    $scope.getSellRequests();

    $scope.getGroupRequests = function () {
      $scope.resetCountLabels();
      $scope.showSellRequests = false;
      $scope.showGroupRequests = true;
      if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
        $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);
      }
      if ($scope.groupAppointmentsGridOptions && $scope.groupAppointmentsGridOptions.api) {
        $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      }
    }

    // implements ag-grid dataSource interface
    $scope.groupAppointmentsDataSource = {
      getRows(params) {

        let fromdate = moment($scope.date.startDate, 'WWW MMM DD YYYY').format("DD-MMM-YYYY");
        let todate = moment($scope.date.endDate, 'WWW MMM DD YYYY').format("DD-MMM-YYYY");

        let city = $rootScope.loginData.city;

        let requestBody = {};
        requestBody.fromDate = fromdate;
        requestBody.toDate = todate;

        if (city) {
          requestBody.city = city;
        }

        let startRow = params.request.startRow;
        let endRow = params.request.endRow;
        let sortModel = params.request.sortModel;
        let filterModel = params.request.filterModel;

        if (!filterModel.hasOwnProperty("status")) {
          filterModel = Object.assign(filterModel, {
            status: {
              values: $scope.uniqueUnassignedStatuses,
              filterType: "set"
            }
          });
        }

        let agGridRequestParams = {};
        // pagination is present by default, no validation needed here
        agGridRequestParams.startRow = startRow;
        agGridRequestParams.endRow = endRow;

        if (sortModel && sortModel.length > 0) {
          agGridRequestParams.sortModel = sortModel;
        }
        agGridRequestParams.filterModel = filterModel;

        requestBody.agGridRequestParams = agGridRequestParams;

        console.log(requestBody)

        SellNow.getCommunityAppointmentsGrid(requestBody)
          .then(function (res) {
            if (res.status === "SUCCESS" && res.data.communityAppointments && res.data.communityAppointments.length > 0) {
              $scope.communityAppointments = $scope.formatCells(res.data.communityAppointments);
              $scope.countLabels.groupAppointments = res.data.totalCommunityAppointmentsCount;
              if ($scope.showGroupRequests) {
                $scope.countLabels.completed = res.data.totalCompletedCommunityAppointmentsCount;
                $scope.countLabels.pending = res.data.totalPendingCommunityAppointmentsCount;
                $scope.countLabels.cancelled = res.data.totalCancelledCommunityAppointmentsCount;

              }
              params.successCallback($scope.communityAppointments, res.data.totalRecordsCount);
            } else if (res.status === "SUCCESS") {
              $scope.countLabels.groupAppointments = res.data.totalCommunityAppointmentsCount;
              if ($scope.showGroupRequests) {
                $scope.countLabels.completed = res.data.totalCompletedCommunityAppointmentsCount;
                $scope.countLabels.pending = res.data.totalPendingCommunityAppointmentsCount;
                $scope.countLabels.cancelled = res.data.totalCancelledCommunityAppointmentsCount;

                console.error(res);
                growl.error("No results found");
              };
              $scope.groupAppointmentsGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();

              params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
            } else {
              if ($scope.showGroupRequests) {
                $scope.countLabels.completed = 0;
                $scope.countLabels.pending = 0;
                $scope.countLabels.cancelled = 0;

              }
              console.error(res);
              if ($scope.showGroupRequests) {
                growl.error("No results found");
              }
              $scope.groupAppointmentsGridOptions.api.showNoRowsOverlay();
              $rootScope.hideAgGridLoadingRow();

              params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
            }
          })
          .catch(function (err) {
            console.error(err);
            $scope.countLabels.completed = 0;
            $scope.countLabels.pending = 0;
            $scope.countLabels.cancelled = 0;

            if ($scope.showGroupRequests) {
              growl.error("No results found");
            }
            $scope.groupAppointmentsGridOptions.api.showNoRowsOverlay();
            $rootScope.hideAgGridLoadingRow();

            params.failCallback() // TODO https://www.ag-grid.com/javascript-grid/component-overlay/#example-custom-overlay-components
          })
      }
    };

    let groupAppointmentsColumnDefs = [
      {
        headerName: "group",
        field: "sells",
        maxWidth: 40,
        cellRenderer: 'agGroupCellRenderer'
      },
      {
        headerName: "ID",
        field: "communityAppointmentId",
        cellRenderer: CellRender,
        maxWidth: 120,
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Slot",
        field: "slot",
        maxWidth: 100,
      },
      {
        headerName: "Community",
        field: "communityName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Agent",
        field: "agentName",
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Area",
        field: "area",
        maxWidth: 110,
        filter: 'agTextColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
      },
      {
        headerName: "Community Type",
        field: "communityCategoryName",
        cellStyle: { 'textAlign': 'center' }
      },
      {
        headerName: "Status",
        field: "status",
        maxWidth: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply', 'cancel'],
          values: params => {
            const values = $scope.uniqueStatuses; // fetch values from server
            params.success(values); // supply values to the set filter
          },
          closeOnApply: true
        },
        cellStyle: cellStyleFormmatter,
        valueFormatter:valueFormatter

      },
      {
        headerName: "Quantity",
        field: "totalQuantity",
        maxWidth: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        cellStyle: { 'textAlign': 'right' },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00',

      },
      {
        headerName: "Amount",
        field: "totalAmount",
        maxWidth: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['reset', 'apply', 'cancel']
        },
        valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00',
        cellStyle: { 'textAlign': 'right' }
      },
      {
        headerName: "Date",
        field: "completedOn",
        maxWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'],
          closeOnApply: true,
        },
        valueFormatter: params => params.data.completedOn ? $moment(params.data.completedOn).format('DD-MM-YYYY HH:mm') : ""
      },
      {
        headerName: "Preferred Date",
        field: "preferredDate",
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply', 'cancel'],
          closeOnApply: true,
        },
        valueFormatter: params => params.data.preferredDate ? $moment(params.data.preferredDate).format('DD-MM-YYYY') : "",
        hide: true
      }
    ];

    $scope.groupAppointmentsGridOptions = {
      columnDefs: groupAppointmentsColumnDefs,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      rowHeight: 39,
      // suppressCsvExport: true,
      // suppressExcelExport: true,
      rowStyle: { background: '#f4f4f4' },
      pagination: true,
      rowData: [],
      paginationPageSize: 1000,
      cacheBlockSize: 1000,
      blockLoadDebounceMillis:100,

      rowModelType: 'serverSide', // use the server-side row model instead of the default 'client-side'

      masterDetail: true,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: 'confirmationId',
            },
            {
              headerName: 'Consumer',
              field: 'consumerName'
            },
            {
              headerName: 'Date',
              field: 'completedOn',
              valueFormatter: params => params.value ? $moment(params.value).format('DD-MM-YYYY HH:mm') : ""
            },
            {
              headerName: 'Payment type',
              field: 'payment',
            },
            {
              headerName: 'Amount',
              field: 'totalAmount',
              maxWidth: 120,
              valueFormatter: params => params.value ? $scope.inrFilter(params.value) : '0.00',
              cellStyle: { 'textAlign': 'right' }
            },
            {
              headerName: 'Status',
              field: 'status',
              cellStyle: cellStyleFormmatter,
              valueFormatter:valueFormatter
            },
            {
              headerName: 'Tags',
              field: 'source',
              cellRenderer: CellRender
            }
          ],
          defaultColDef: {
            flex: 1,
            minWidth: 150,
            filter: true,
            sortable: true,
            resizable: true
          },
          rowHeight: 39,
          onCellClicked: function (event) {
            // console.log('event: ',event);
            OpenRequest(event.data, $scope.allRequests, $scope.vm.params);
          },
        },
        getDetailRowData: function (params) {
          apartments.retrieveSellsForAppointment(params.data.communityAppointmentId).then((res) => {
            if (res.status !== "SUCCESS") {
                console.error(res);
                return;
            }
            res.data.sells.forEach(elem => {
              elem.status = elem.statusCode;
              elem.completedOn = elem.completedDate;
              elem.payment = elem.actualPaymentMethod ? elem.actualPaymentMethod : elem.preferredPaymentMethod;
            });
            params.data.sells = res.data.sells;
          });
          params.data.sells = $scope.formatCells(params.data.sells);
          params.successCallback(params.data.sells)
        },
      },
      getRowStyle: function (params) {
        if (params.node.rowIndex % 2 === 0) {
          return { background: 'white' };
        }
      },
      onGridReady: function (params) {
        // $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      },
      onCellClicked: function (event) {
        if (event && event.column.colId !== "preferredDate") {
          $scope.openAppointmentsRequest(event.data);
        }
      },
      onFirstDataRendered: function (params) {
        params.api.sizeColumnsToFit();
      }
    };

    $scope.formatCells = function (data) {

      data.forEach(function (elem, idx) {

        $scope.payMethod = false;
        if (elem.preferredPaymentMethod === "Cash") {
          $scope.payMethod = true;
        }

        if (elem.consumerCategory != null && elem.consumerCategory.name != null) {
          if (elem.type === 'Residence') {
            imageVal = "fa-home";
            elem.image = imageVal;
          } else {
            imageVal = "fa-industry";
            elem.image = imageVal;
          }
        }
        if (elem.status === 'ASSIGNED_TO_AGENT') {
          elem.statusColor = "#b5723b";
        } else if (elem.status === 'ACCEPTED_BY_AGENT') {
          elem.statusColor = 'blue';
        } else if (elem.status === 'CANCELLED_BY_CONSUMER') {
          elem.statusColor = 'black';
        } else if (elem.status === 'REQUEST_COMPLETED') {
          elem.statusColor = 'green';
        } else if (elem.status === 'DECLINED_BY_AGENT') {
          elem.statusColor = 'red';
        } else if (elem.status === 'REQUEST_INITIATED') {
          elem.statusColor = 'orange';
        } else if (elem.status === 'AGENT_ARRIVED') {
          elem.statusColor = 'blue';
          elem.animation = 'color 2s infinite both';
        } else if (elem.status === 'PAYMENT_PENDING') {
          elem.statusColor = '#9d319d';
        }

        if (elem.preferredSlotName) {
          elem['preferredSlotEnd'] = elem['preferredSlotName'].replace(/.*\-/, '');
          elem['preferredSlotEndDate'] = elem['preferredDate'] + " " + elem['preferredSlotEnd'];
        } else if (elem.slot) {
          elem['preferredSlotEnd'] = elem['slot'].replace(/.*\-/, '');
          elem['preferredSlotEndDate'] = elem['preferredDate'] + " " + elem['preferredSlotEnd'];
        }

        if (moment() > moment(elem['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm').subtract(30, "minutes") &&
          elem.status.match(/assigned|initiated|accepted|declined/i)) {
          elem['preferredSlotExpired'] = true;
          elem['latestatus'] = "late-not-closed";
        }
        if (moment(elem['completedOn'], 'DD-MMM-YYYY HH:mm') > moment(elem['preferredSlotEndDate'], 'DD-MMM-YYYY HH:mm')) {
          elem['completedAfterPreferredSlotExpired'] = true;
        }

        elem['show'] = true;
        if (elem['status'] === "ASSIGNED_TO_AGENT") {
          $scope.pending += 1;
        }
        if (elem['status'] === "REQUEST_COMPLETED") {
          $scope.completed += 1;
        }
        if (elem['status'] === "DECLINED_BY_AGENT") {
          $scope.declined += 1;
        }
        if (elem['status'] === "CANCELLED_BY_CONSUMER") {
          $scope.cancelled += 1;
        }
        if (elem['status'] === "REQUEST_INITIATED") {
          $scope.req_initiated += 1;
        }
        if (elem['status'] === "REQUEST_CLOSED") {
          $scope.req_closed += 1;
        }
        if (elem['status'] === "REJECTED_BY_CRM") {
          $scope.rej_by_crm += 1;
        }
        if (elem['status'] === "ACCEPTED_BY_AGENT") {
          $scope.accepted_by_agent += 1;
        }

      });

      data = data.filter(function (obj) {
        return obj.show == true;
      });

      return data;
    }
    function stringStyleFormatter(params) {
      if (params.column.colId == "confirmationId") {
        if (params.data.completedAfterPreferredSlotExpired) {
          return { color: 'red', textAlign: 'center' };
        } else {
          return { textAlign: 'center' }
        }
      }
      if (params.column.colId == "status") {
        return $scope.getStatusColor(params.data);
      }
    }
    $scope.getStatusColor = function (item) {
      let obj = {
        "color": "black",
        textAlign: 'center'
      };
      switch (item.status) {
        case "Assigned To REO":
          obj.color = "yellow";
          break;
        case "Accepted By Agent":
          obj.color = "blue";
          break;
        case "Request Completed":
          obj.color = "green";
          break;
        case "Cancelled By Consumer":
          obj.color = "black";
          break;
        case "Declined By Agent":
          obj.color = "red";
          break;
        case "Request Initiated":
          obj.color = "orange";
          break;
      }
      return obj;
    }


    $scope.opts = {
      locale: {
        applyClass: 'btn-green',
        applyLabel: "Apply",
        fromLabel: "From",
        format: "DD-MMM-YYYY",
        toLabel: "To",
        cancelLabel: 'Cancel',
        customRangeLabel: 'Custom range'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      }
    };

    $scope.setStartDate = function () {
      $scope.date.startDate = moment().subtract(4, "days").toDate();
    };

    $scope.setRange = function () {
      $scope.date = {
        startDate: moment().subtract(5, "days"),
        endDate: moment()
      };
    };

    $scope.vm = {
      params: { "city": $rootScope.loginData.city },
      hidecompleted: true
    };

    $scope.currentPage = 1;

    $scope.transactionChange = function () {

      $scope.vm.params.singledayquery = false;
      $scope.totalAgentRecords = 0;
      // $scope.currentPage = 1;
      $scope.vm.params.page = -1;

      if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
        $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
        $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

        if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
          $scope.vm.params.singledayquery = true;
        }
        $scope.allRequests($scope.vm.params);
      } else {
        console.error("Invalid date, please select valid date range");
        console.error("startDate = ", $scope.date.startDate);
        console.error("endDate = ", $scope.date.endDate);
        growl.error("Invalid date, please select valid date range");
      }

    };
    $scope.pageChanged = function (currentPage) {
      // $scope.vm.params.page = currentPage - 1;
      // $scope.vm.params.page = $scope.pageNumber;
      $scope.allRequests($scope.vm.params);
    }

    $scope.makePayment = function (confirmationId, paymentType) {

      var consumerDetails = {};
      consumerDetails = elem.find(x => x.confirmationId === confirmationId)

      var pickupInstance = $uibModal.open({
        templateUrl: 'views/make-payment-modal.html',
        controller: 'MakePaymentCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return consumerDetails;
          },
          paymentType: function () {
            return paymentType;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      }, function () {
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      });

    }

    var intervalPromise, animateIntervalStart;

    var shows = {};

    var image = "image";
    var imageVal;

    var sortingOrder = [];
    var sameDay = "sameDay";
    var sameDate = "sameDate";
    var colorStatus = "colorStatus";

    $scope.allRequests = function (params) {
      $rootScope.displayArr = [];
      $scope.pending = 0;
      $scope.completed = 0;
      $scope.declined = 0;
      $scope.cancelled = 0;
      $scope.req_initiated = 0;
      $scope.req_closed = 0;
      $scope.rej_by_crm = 0;
      $scope.accepted_by_agent = 0;
      $scope.late = 0;
      $scope.averagelate = 0;
      $scope.averagelatestr = "";
      $scope.pendingPayemt = 0;
      $scope.avgRating = 0.0;
      $scope.count = 0;

      $scope.resetCountLabels();
      if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
        $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);
      }
      if ($scope.groupAppointmentsGridOptions && $scope.groupAppointmentsGridOptions.api) {
        $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      }
    }


    $scope.length = 1000;
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withDisplayLength($scope.length)
      .withOption('order', [1, 'desc']);

    $scope.all = function () {
      $scope.paging = false;
      $scope.vm.params.page = -1;
      $scope.allRequests($scope.vm.params);
    }

    //Watch for date changes
    $scope.$watch('date', function (newDate) {
      $scope.transactionChange();
    }, false);

    $scope.nextDate = function () {
      $scope.paging = false;
      $scope.date = {
        startDate: moment($scope.date.endDate).add(1, "days"),
        endDate: moment($scope.date.endDate).add(1, "days")
      };
      $scope.resetCountLabels();
      if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
        $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);
      }
      if ($scope.groupAppointmentsGridOptions && $scope.groupAppointmentsGridOptions.api) {
        $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      }
      // $scope.transactionChange();
    }
    $scope.previousDate = function () {
      $scope.paging = false;
      $scope.date = {
        startDate: moment($scope.date.startDate).subtract(1, "days"),
        endDate: moment($scope.date.startDate).subtract(1, "days")
      };
      $scope.resetCountLabels();
      if ($scope.appointmentsGridOptions && $scope.appointmentsGridOptions.api) {
        $scope.appointmentsGridOptions.api.setServerSideDatasource($scope.appointmentsDataSource);
      }
      if ($scope.groupAppointmentsGridOptions && $scope.groupAppointmentsGridOptions.api) {
        $scope.groupAppointmentsGridOptions.api.setServerSideDatasource($scope.groupAppointmentsDataSource);
      }      // $scope.transactionChange();
    }



    var OpenRequest = function (pickup, pendingSells, vmParams) {

      sortingOrder = [];
      if ($('#example').dataTable().fnSettings() == null) {
        sortingOrder = [];
      } else {
        sortingOrder = $('#example').dataTable().fnSettings().aaSorting;
      }

      var pickupInstance = $uibModal.open({
        templateUrl: 'views/appointments-modal.html',
        controller: 'AppointmentsModalCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return pickup;
          },
          vmParams: function () {
            return vmParams;
          }
        }
      });

      pickupInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      });
    };

    $scope.openAppointmentsRequest = function (pickup) {
      var pickupInstance = $uibModal.open({
        templateUrl: 'views/apartment-appointment-details.html',
        controller: 'AppointmentDetailsCtrl',
        size: "lg",
        backdrop: 'static',
        resolve: {
          items: function () {
            return pickup;
          }
        }
      });
      pickupInstance.result.then(function (selectedItem) {
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      }, function () {
        $scope.showSellRequests ? $scope.getSellRequests() : $scope.getGroupRequests();
      });
    };

    //Animate effect
    function animateEffect() {
      animateIntervalStart = $interval(function () {
        $scope.applyAnimate = !$scope.applyAnimate;
      }, 300);
    }

    animateEffect();

  });
