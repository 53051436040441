'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:EditCustomPriceCtrl
 * @description
 * # EditCustomPriceCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('EditCustomPriceCtrl', function($scope, $rootScope, products, ngTableParams, items, $uibModalInstance, $state, $global, $uibModal, envService, editableOptions, growl, pricingService, $log) {
        console.log(items)
        $scope.priceDetails = items;
        $scope.cancel = function() {
            $uibModalInstance.close('cancel');
        };

        $scope.priceUpdate = function(categoryId, price) {
            var pricedata = {
                "categoryId": categoryId,
                "price": price
            };
            products.priceUpdate(pricedata).then(function(res) {
                if (res.status == "SUCCESS") {
                    return true;
                } else if (res.status == "FAILURE") {
                    growl.error("Failure");
                    return false;
                }
            });
        }
        $scope.addCustomPricing = function() {
            // console.log("pricing for " + pricingcategory)
            var pricingcategory = null;
            console.log("opening add-custom-pricing ")
            var modalInstance = $uibModal.open({
                templateUrl: 'views/add-custom-pricing.html',
                controller: 'AddCustomPricingCtrl',
                size: "lg",
                resolve: {
                    items: function() {
                        return 'PRICING';
                    },
                    role: function() {
                        return pricingcategory;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem) {
                $scope.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
                 $scope.loadWholesalers();
            });

        };

        $scope.editPrice = function() {
            products.editCustomPrice($scope.priceDetails).then(function(res) {
                console.log(res)
                if (res.status == "SUCCESS") {
                    growl.success("Successfully updated custom price");
                    $uibModalInstance.dismiss(res);
                } else if (res.status == "FAILURE") {
                    growl.error(res.error.code);
                }
            });

        }
    });
