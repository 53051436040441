'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddCustomerCtrl
 * @description
 * # AddCustomerCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddCustomerCtrl', function ($scope, Upload, $sce, authentication, $stateParams, wholesalerService, vendorService, consumer, documents, $moment, $global, growl, $state, $timeout, NgMap, $rootScope) {

    let items = consumer.getConsumerDetails();
    $scope.consumerBucketType = $stateParams.type;
    console.log($scope.consumerBucketType)
    const previewOptions = "#&toolbar=0";

    // For documents section
    $scope.url = $global.getApiUrl() + '/fileManager/uploadFile';
    $scope.documentData = [];
    $scope.addDocumentData = [];
    $scope.isAddRowOn = false;
    $scope.id = 1; // Used as index or id in documents table
    $scope.documentTypesList = [];
    $scope.blobURLs = [];

    $scope.userType = "CONSUMER";
    $scope.data = {};
    $scope.vm = {};
    $scope.location = {
      latitude: 0,
      longitude: 0
    };

    // Scale marker icon for map
    $scope.icon = {
      url: "./images/consumer.svg",
      scaledSize: [55, 55]
    }

    $scope.addAddress = false;

    $scope.title = (items && items.referrer !== "Aquisitions") ? "EDIT CONSUMER" : "ADD CONSUMER";

    $scope.category = null;
    if ($scope.consumerBucketType == "RWA") {
      $scope.category = "RWA";
      $scope.communityCatogories = [{ name: "Villas" }, { name: "Apartments" }]
    }
    $scope.referrer = items ? items.referrer : "";

    $scope.blocks = [];
    $scope.getBlocks = function (communityId) {
      consumer.getBlocks(communityId).then(function (res) {
        if (res.status == "SUCCESS") {
          $scope.blocks = res.data.blocks;
          $scope.existingBlocks = $scope.blocks.length;
        }
      })
    }


    if (items) {
      console.log("Is it coming from items??", items);
      $scope.consumerId = items.consumerId;
      $scope.communityId = items.consumerId;
      $scope.getBlocks($scope.communityId);
      $scope.setHeader = items.setHeader;
      $scope.data.name = items.name;
      $scope.data.mobileNo = items.mobileNo;
      $scope.data.passCode = items.password;
      $scope.data.email = items.email;
      $scope.data.contact = items.contact;
      $scope.data.pannumber = items.pannumber;
      $scope.data.gstNumber = items.gstNumber;
      $scope.data.upiId = items.upiId;
      $scope.data.code = items.communityCode;

      if (items.payLater) {
        $scope.data.payLater = "true"
      } else {
        $scope.data.payLater = "false"
      }

      $scope.data.numberOfFlats = items.numberOfFlats;
      $scope.data.numberOfBlocks = items.numberOfBlocks;
      if (items.communityCategory) {
        $scope.data.communityCategory = { id: items.communityCategory.communityCategoryId, name: items.communityCategory.communityCategoryName };
      }

      $scope.vm.customadd = items.address.landmark;
      $scope.vm.formattedAddress = items.address.formattedAddress.replaceAll("<br>", "");
      var center = [];
      center.push(items.address.latitude);
      center.push(items.address.longitude);
      $scope.center = center.join();
      $scope.category = items.categories[0].value;
      console.log('category is : ', $scope.category);
      $scope.location = {
        latitude: items.address.latitude,
        longitude: items.address.longitude
      };
      $scope.chosenPlace = items.address.address;
      $scope.data.comments = items.comments ? items.comments : [];
      $scope.data.comments.forEach(comment => {
        comment.time = $moment(comment.time).format("DD-MM-YY");
      })
    } else {
      $scope.consumerId = null;
      $scope.setHeader = items;
    }
    $rootScope.loginData = {};
    $rootScope.loginData = $global.getLocalItem("loginData", true);

    var citymatcher = "";
    if ($rootScope.allCityData && $rootScope.allCityData.length > 0) {
      $rootScope.allCityData.forEach(function (elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
    }


    $scope.blocksChanged = function (numberOfBlocks) {
      $scope.numberOfBlocks = numberOfBlocks;
      if ($scope.numberOfBlocks > $scope.blocks.length) {
        for (let i = $scope.blocks.length; i < $scope.numberOfBlocks; i++) {
          $scope.blocks.push({});
        }
      } else {
        $scope.blocks.length = $scope.numberOfBlocks;
      }
    }
    function Init() {
      $scope.getCommunityCatogories()
    }

    $scope.getCommunityCatogories = function () {
      console.log("Get Communities");
      let city = $rootScope.loginData.city;
      consumer.getCommunityCatogories(city).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.communityCatogories = res.data.communityCategories;
        } else {
          console.log("Catogories Not Found")
        }
      })
    }

    Init();


    $scope.checkradio = function () {
      console.log("Radio clicked");
      setTimeout(function () {
        var category = $scope.categories[$scope.categories.length - 1];
        $scope.categories = [];
        $scope.categories.push(category);
      }, 300);
    };

    $scope.categories = [];
    consumer.userCategories().then(function (res) {
      if (res.status === "SUCCESS") {
        $scope.categoriesList = res.data.categories;
        $scope.category = $scope.category;
      } else if (res.status === "FAILURE") {
        growl.error(res.error.message)
      }
    });

    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng).then(function (res) {
        console.log("$global.getLocationByLatLng", res);
        $scope.place = res;
        $scope.vm.formattedAddress = res.formatted_address;
        $scope.chosenPlace = res.formatted_address;
      })
    }

    function reRenderMap() {
      $timeout(function () {
        angular.forEach($scope.maps, function (map) {
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }

    $scope.setCurrentLocation = function () {
      console.log("is setcurrentlocation being called");
      $global.getCurrentLocation().then(function (res) {
        $scope.center = $scope.getLatLng(res);
        var rem = {};
        rem.lat = res.latitude;
        rem.lng = res.longitude;
        //setPlaceObject(rem);
        reRenderMap();
      });
    };

    $scope.getLatLng = function (obj) {

      if (obj && obj.latitude && obj.longitude) {
        var latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      }

    };
    //$scope.setCurrentLocation();

    $scope.placeChanged = function () {
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        var obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";

      }

    };

    $scope.setLocation = function (obj) {

      var center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
    };

    var map;
    $scope.maps = [];
    $scope.$on('mapInitialized', function (evt, evtMap) {
      $scope.maps.push(evtMap);

    });


    NgMap.getMap().then(function (evtMap) {
      map = evtMap;
    });
    $scope.setLocations = function () {
      if ($scope.locationadd) {

      }
    };
    $scope.setCurrentLocation();
    $scope.markerDragEnd = function (event) {

      console.log("Is $scope.markerDragEnd getting called when dragged???", event);

      $timeout(function () {
        var latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);

      })
      // $scope.chosenPlace = $scope.vm.formattedAddress;
    };

    $scope.addUsers = function (address, latitude, longitude) {
      $scope.categories = [];
      $scope.categories.push($scope.category);

      $scope.catalog = "Please select any one category";
      $scope.data.registerType = "CRM";
      $scope.data.userType = $scope.userType;
      $scope.data.city = $rootScope.loginData.city;
      $scope.data.formattedAddress = $scope.vm.formattedAddress;

      if ($scope.data.payLater && $scope.data.payLater === "true") {
        $scope.data.payLater = true;
      } else {
        $scope.data.payLater = false;
      }

      $global.setLocalItem("registration", $scope.authorization, true);
      $scope.submitted = true;

      console.log('Data to be registered is : ', $scope.addUserForm);

      if ($scope.addUserForm.$error.required) {
        growl.error("Please add the required fields");
        return;
      }

      if ($scope.addUserForm.$error.pattern) {
        growl.error("Please enter valid input");
        return;
      }

      var geocoder = new google.maps.Geocoder();
      //First Geocode
      geocoder.geocode({ 'address': address }, function (results, status) {
        //console.log(google.maps.GeocoderStatus.OK);
        if (status === google.maps.GeocoderStatus.OK) {
          //Register main user
          console.log("$scope.data to be registered is:  ", $scope.data);

          if ($scope.title === "ADD CONSUMER") {
            $scope.addConsumerRegistration(results, latitude, longitude);
          } else if ($scope.title === "EDIT CONSUMER") {
            $scope.data.userid = $scope.consumerId;
            $scope.updateConsumerRegistration(results, latitude, longitude);
          }
        } else {
          growl.error("Failed to find lat,long for the address, please enter a better address");
        }
      });
    };

    $scope.addBlocks = function (communityId) {
      $scope.blocks.forEach(function (obj) {
        obj["communityId"] = communityId;

        if (obj.blockId) {
          consumer.updateBlocks(obj).then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success("Block " + obj.blockName + " Updated Successfully.");
            } else {
              growl.error("Failure while Updating " + obj.blockName + " Block");
            }
          })
        } else {
          consumer.addBlocks(obj).then(function (res) {
            if (res.status === "SUCCESS") {
              growl.success("Block " + obj.blockName + " added Successfully.");
            } else {
              growl.error("Failure while adding " + obj.blockName + " Block");
            }
          })
        }
      })

    }
    $scope.addConsumerRegistration = function (results, latitude, longitude) {
      try {
        if ($scope.data.communityCategory) {
          $scope.data.communityCategory = {
            "communityCategoryId": $scope.data.communityCategory.id,
            "communityCategoryName": $scope.data.communityCategory.name
          }
        }

        $scope.data.comments = $scope.data.comments ? $scope.data.comments : [];
        if ($scope.data.comment && $scope.addUserForm.comment.$dirty) {
          let comment = {
          "comment": $scope.data.comment,
          }
          $scope.data.comments.push(comment);
        }

        authentication.register($scope.data).then(function (res) {

          if (res.status !== "SUCCESS") {
            growl.error(res.error.message ? res.error.message : "Unable to add consumer");
            console.error(res.error);
            return;
          }
          $scope.consumerId = res.data.userId;
          $scope.addBlocks(res.data.userId);

          var addresses = $global.getAddressObj(results[0]);
          //override geocoded lat, long
          if (latitude) {
            addresses.latitude = latitude;
            addresses.longitude = longitude
          }

          addresses.userId = $scope.consumerId;
          addresses.userType = $scope.userType;
          addresses.landmark = $scope.data.landmark;
          addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
          addresses.categories = [];
          addresses.agentType = $scope.agentType;
          angular.forEach($scope.categories, function (value, key) {
            angular.forEach($scope.categoriesList, function (catvalue, key) {
              if (value === catvalue.name) {
                addresses.categories.push({
                  key: catvalue.consumerCategoryId,
                  value: catvalue.name
                })
              }
            });
          });

          authentication.saveCosumerAddress(addresses).then(function (res) {

            if (res.status !== "SUCCESS") {
              growl.error("Unable to add consumer address");
              console.error(res);
              return;
            }
            growl.success("Consumer added successfully.");
            console.log("what is the respone of saveconsumerAddress", res);

            // Approve Acquistion in Sales DB
            if (items && items.referrer === "Aquisitions") {
              let obj = {
                id: items._id,
                isApproved: true
              };
              consumer.updateConsumer(obj).then(function (res) {
                if (res.status === "Success") {
                  console.log("User approved successfully");
                } else {
                  growl.error("Consumer added successfully but unable to approve");
                }
              })
                .catch((err) => {
                  console.log('err in Approving user: ', err);
                  growl.error("Consumer added successfully but unable to approve");
                });
            }

            $state.go("root.consumer");
          });
        });

      } catch (e) {
        growl.error("Error in adding consumer", e);
        console.error(e);
      }
    }

    $scope.updateConsumerRegistration = function (results, latitude, longitude) {

      try {

        if ($scope.data.communityCategory) {
          $scope.data.communityCategory = {
            "communityCategoryId": $scope.data.communityCategory.id,
            "communityCategoryName": $scope.data.communityCategory.name
          }
        }

        $scope.data.comments = [];

        if ($scope.data.comment && $scope.addUserForm.comment.$dirty) {
          let comment = {
          "comment": $scope.data.comment,
          }
          $scope.data.comments.push(comment);
        }

        $scope.data.addressId = $scope.addressId;
        console.log('Data to be updated: ', $scope.data);
        consumer.updateConsumerRegistration($scope.data).then(function (res) {

          if (res.status !== "SUCCESS") {
            growl.error(res.error.message ? res.error.message : "Unable to update consumer");
            console.error(res);
            return;
          }
          if ($scope.communityId) {
            $scope.addBlocks($scope.communityId);
          }
          if ($scope.addAddress) {

            $scope.consumerId = res.data.userId;

            var addresses = $global.getAddressObj(results[0]);
            //override geocoded lat, long
            if (latitude) {
              addresses.loc = {};
              addresses.loc.latitude = latitude;
              addresses.loc.longitude = longitude
            }

            addresses.userId = $scope.consumerId;
            addresses.userType = $scope.userType;
            addresses.landmark = $scope.data.landmark;
            addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
            addresses.categories = [];
            addresses.setAsDefault = true;
            addresses.agentType = $scope.agentType;
            angular.forEach($scope.categories, function (value, key) {
              angular.forEach($scope.categoriesList, function (catvalue, key) {
                if (value === catvalue.name)
                  addresses.categories.push({
                    key: catvalue.consumerCategoryId,
                    value: catvalue.name
                  })
              });
            });

            authentication.saveCosumerAddress(addresses).then(function (res) {
              console.log("what is the respone of saveconsumerAddress", res);
              if (res.status === "SUCCESS") {
              }
            });
          }

          growl.success(res.data.message ? res.data.message : "Consumer updated successfully");
          $state.go("root.consumer");
        });

      } catch (e) {
        growl.error("Error in updating consumer", e);
        console.error(e);
      }
    }

    $scope.addLocation = function (address, latitude, longitude) {

      var addresses = $global.getAddressObj(address);

      //override geocoded lat, long
      if (latitude) {
        addresses.latitude = latitude;
        addresses.longitude = longitude
      }

      addresses.userId = $scope.consumerId;
      addresses.userType = $scope.userType;
      addresses.landmark = $scope.data.landmark;
      addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
      //addresses.formattedAddress = address.formatted_address;
      authentication.saveCosumerAddress(addresses).then(function (res) {

        // $scope.data.addressId = res.data.address.addressId;
        // growl.success(res.data.message)
      })

    };

    $scope.searchForLocation = function () {
      let data = $scope.vm.formattedAddress;
      console.log("$scope.vm.formattedAddress", $scope.vm.formattedAddress);
      authentication.getLocations(data).then(function (res) {
        console.log("response", res);
        $scope.predictions = res.predictions;
        $scope.showPredictions = true;
      }).catch(function (err) {
        console.log("err", err);
      })
    };

    $scope.locationSelected = function (obj) {
      console.log("clicked item", obj);
      $scope.vm.formattedAddress = obj.description;
      $scope.showPredictions = false;
      authentication.getLatLng(obj.place_id).then(function (res) {
        console.log("response", res);
        let place = res.result;
        let obj = {};
        obj.lat = place.geometry.location.lat;
        obj.lng = place.geometry.location.lng;
        $scope.setLocation(obj);
      }).catch(function (err) {
        console.log("err", err);
      });
    }

    $scope.addRow = function () {
      let obj = {};
      obj.documentType = null;
      obj.documentFile = null;
      // row id only for newly/manually added documents
      obj.id = $scope.id;
      $scope.id++;
      $scope.addDocumentData.push(obj);
      $scope.anyEditingDocuments();
    };

    $scope.removeRow = function (id) {
      $scope.addDocumentData.forEach((row, index) => {
        if (row.id === id) {
          $scope.addDocumentData.splice(index, 1);
        }
      });
      $scope.anyEditingDocuments();
    };

    $scope.anyEditingDocuments = function () {
      for (let i = 0; i < $scope.addDocumentData.length; i++) {
        // Check for pending rows
        if ($scope.addDocumentData[i].id) {
          return true;
        }
      }
      return false;
    }

    $scope.saveDocuments = function () {

      if (!$scope.anyEditingDocuments) {
        growl.error("Please add all the documents before submitting");
      }
      $scope.addDocumentData.forEach((document, index) => {
        // Upload document if the file is present
        let file = document.documentFile;
        // Allow only images and pdfs
        if (!file.type.includes("image") && !file.type.includes("pdf")) {
          growl.error("Only image and pdf files are accepted!")
          return;
        }

        let fileFormat = file.name.split('.').pop();

        var reader = new FileReader();
        reader.onloadend = function () {
          $scope.savingDocument = true;
          let data = {
            "userId": $scope.consumerId,
            "documentId": document.documentType.id,
            "document": reader.result,
            "documentFormat": fileFormat
          };
          console.log('data : ', data);
          $scope.uploadConsumerDocuments(data).then((val, index, array) => {

            document.id = 0;
            $scope.addDocumentData.splice(index);
            let obj = {};
            obj.id = 0;
            obj._id = document.id;
            obj.documentType = val.data.consumerDocument.name;
            obj.fileFormat = val.data.consumerDocument.url.split('.').pop();
            obj.img = (obj.fileFormat.includes('png') || obj.fileFormat.includes('jpg') || obj.fileFormat.includes('jpeg')) ? true : false;
            obj.name = obj.documentType + "." + obj.fileFormat;
            obj.url = $sce.trustAsResourceUrl(val.data.consumerDocument.url);
            obj.previewUrl = $sce.trustAsResourceUrl(val.data.consumerDocument.url+previewOptions);
            $scope.documentData.push(obj);
            $scope.generateBlobURL(val.data.consumerDocument.url, $scope.documentData.indexOf(obj), $scope.documentData); // For auto-download purpose
            $scope.documentFile = null;
            $scope.documentType = null;

            $scope.savingDocument = false;
          });
        };
        reader.readAsDataURL(file);
      });
    }

    // To Generate Downloadable blobs
    $scope.generateBlobURL = function (url, ind, arr) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // $apply used to refresh bindings
          $scope.$apply(function () {
            let blobURL = URL.createObjectURL(blob);
            arr[ind].documentDownloadURL = blobURL;
            $scope.blobURLs.unshift(blobURL);
            // Enable download button
            arr[ind].isDocumentDownloadable = true;
          });

        })
        .catch((error) => {
          console.error('Error:', error);
          console.error('url: ', url)
        });
    };

    // Get all address of the user
    if ($scope.title === "EDIT CONSUMER") {
      consumer.getAddress($scope.consumerId).then(function (res) {
        $scope.addresses = res.data.addresses;
        if ($scope.addresses.length > 0) {
          var address = res.data.addresses[0];
          $scope.addressSelected(address);
        }
      });
    }

    /* When the user clicks on the button, 
    toggle between hiding and showing the dropdown content */
    $scope.toggleDropdown = function () {
      const addressDropdown = document.querySelector("#addr-dropdown");
      if (addressDropdown) {
        addressDropdown.classList.toggle("show");
      }
    }

    $scope.addressSelected = function (address) {

      console.log('address: ', address);
      $scope.address = address.formattedAddress;
      $scope.addressId = address.addressId;

      var center = [];
      center.push(address.latitude);
      center.push(address.longitude);
      $scope.center = center.join();
      $scope.category = address.categories[0].value;
    }

    $scope.addANewAddress = function () {
      console.log('addAddress is : ', $scope.addAddress);
      $scope.addAddress = true;
    }

    $scope.retrieveConsumerDocuments = function () {
      let data = {
        "userId": $scope.consumerId
      }
      documents.retrieveConsumerDocuments(data).then(function (res) {
        if (res.status === "SUCCESS") {
          $scope.documentData = [];
          let consumerDocuments = res.data.consumerDocuments;
          if (consumerDocuments && consumerDocuments[0]) {
            consumerDocuments.forEach((document, index, array) => {
              let obj = {};
              obj.id = 0;
              obj._id = document.id;
              obj.documentType = document.name;
              obj.url = $sce.trustAsResourceUrl(document.url);
              obj.previewUrl = $sce.trustAsResourceUrl(document.url+previewOptions);
              obj.fileFormat = document.url.split('.').pop();
              obj.img = (obj.fileFormat.includes('png') || obj.fileFormat.includes('jpg') || obj.fileFormat.includes('jpeg')) ? true : false;
              obj.name = obj.documentType + "." + obj.fileFormat;
              $scope.documentData.push(obj);
              $scope.generateBlobURL(document.url, index, $scope.documentData); // For auto-download purpose
            });
          }
        } else {
        }
      });
    }
    if ($scope.title === "EDIT CONSUMER") {
      $scope.retrieveConsumerDocuments();
    }

    $scope.uploadConsumerDocuments = function (data) {
      return new Promise((resolve, reject) => {

        documents.uploadConsumerDocuments(data).then(function (res) {
          if (res.status === "SUCCESS") {

            resolve(res);
          } else {
            reject(res);
          }
        });
      });
    }

    $scope.disableConsumerDocument = function (id) {
      let confirmationMessage = `Are you sure you want to delete this document?`
      if (confirm(confirmationMessage)) {
        let data = {};
        data.id = id;
        documents.disableConsumerDocuments(data).then(function (res) {
          if (res.status === "SUCCESS") {

            growl.success("Document deleted successfully");
            $state.go($state.current, {}, { reload: true });

          } else {

            growl.error("Unable to delete document");
            console.error(res);
            $state.go($state.current, {}, { reload: true });
          }
        });
      }
    }

    let data = {
      "userType": $scope.userType
    }
    documents.retrieveDocuments(data).then(function (res) {
      if (res.status === "SUCCESS") {
        $scope.documentTypesList = res.data.documents;
      }
    });

    $scope.cancel = function () {
      $state.go("root.consumer");
    }

  });
