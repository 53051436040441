'use strict';

angular.module('scrapQcrmApp')
    .controller('AreaCtrl', function ($scope, area, $rootScope, $global, $stateParams, $http, $state, $uibModal, $log, growl) {
        $scope.vm = { params: {} };

        var letters = '0123456789ABCDEF';
        function getRandomColor() {
            var color = '#'; // <-----------
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        function polygonCenter(poly) {
            var lowx,
                highx,
                lowy,
                highy,
                lats = [],
                lngs = [],
                vertices = poly;

            for (var i = 0; i < vertices.length; i++) {
                lngs.push(vertices[i].longitude);
                lats.push(vertices[i].latitude);
            }

            lats.sort();
            lngs.sort();
            lowx = lats[0];
            highx = lats[vertices.length - 1];
            lowy = lngs[0];
            highy = lngs[vertices.length - 1];
            var center_x = lowx + ((highx - lowx) / 2);
            var center_y = lowy + ((highy - lowy) / 2);
            return (new google.maps.LatLng(center_x, center_y));
        }

        function getCurrentLocation() {
            $global.getCurrentLocation().then(function (res) {
                if (res) {
                    $scope.map.setCenter({ lat: res.latitude, lng: res.longitude });
                }
            }, function (err) {
                console.log(err);
            })
        }

        getCurrentLocation();

        function init() {
            var mapOptions = {
                zoom: 13,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                streetViewControl: false,
                scaleControl: false,
                fullscreenControl: false,
                center: new google.maps.LatLng(17.3944809, 78.4417806)
            }
            console.log("MAP OPTIONS", mapOptions)
            $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            console.log("MAP", $scope.map);
        }

        init();


        (function () {
            let columnDefs = [
                {
                    maxWidth: 50,
                    cellRenderer: EditCellRenderer,
                    field: "edit",
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    maxWidth: 50,
                    cellRenderer: DeleteCellRenderer,
                    field: "delete",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    // maxWidth: 250,
                    headerName: "Area Name",
                    field: "area",
                    cellStyle: { 'text-align': 'left' },
                },
                {
                    maxWidth: 180,
                    headerName: "Pin Code",
                    field: "pincode",
                    cellStyle: { 'text-align': 'center' },
                },
                {
                    maxWidth: 180,
                    headerName: "Appointment Limit",
                    field: "appointmentsLimit",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    maxWidth: 220,
                    headerName: "Serviceable Days",
                    filter: "agTextColumnFilter",
                    field: "serviceableDays",
                    cellStyle: { 'text-align': 'right' },
                },
                {
                    maxWidth: 180,
                    field: "serviceability",
                    cellRenderer: ToggleActionCellRenderer,
                    cellStyle: { 'text-align': 'center' },
                }
            ];
            console.log($scope.disabledFeilds)
            columnDefs.forEach(function (colObj) {
                colObj.headerTooltip = colObj.headerName;
            })
            let rowData = [];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                defaultColDef: {
                    flex: 1,
                    minWidth: 150,
                    filter: true,
                    sortable: true,
                    resizable: true,
                    columnsMenuParams: {
                        suppressColumnFilter: true,
                        contractColumnSelection: true,
                    }
                },
                tooltipShowDelay: 0,
                suppressCsvExport: false,
                suppressExcelExport: false,
                rowStyle: { background: '#f4f4f4' },
                pagination: true,
                rowData: rowData,
                paginationPageSize: 1000,
                getRowStyle: function (params) {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: 'white' };
                    }
                },
                onGridReady: function (params) {
                    $scope.gridOptions.api.setRowData($scope.salesAgentsData);
                },
                onFirstDataRendered: function (params) {
                    params.api.sizeColumnsToFit();
                },
                onCellClicked: function (event) {
                    console.log("ANYTHING HAPPENING HERE????", event);
                    if (event && event.data && event.column.colId == "edit") {
                        console.log("what si the event...", event.data);
                            $scope.editArea(event.data);
                    }
                    if (event && event.data && event.column.colId == "delete") {
                            if(confirm("Do you want to Delete the Area?")){
                              $scope.deleteArea(event.data);
                            }
                    }
                    if (event && event.data && event.column.colId == "serviceability") {
                        $scope.data = Object.assign({}, event.data);
                        $scope.data.LatLngObj = null;
                        $scope.data.serviceability = !$scope.data.serviceability;
                        $scope.data.areaCoordinates = $scope.data.areaCoordinates.map(item => `${item.latitude},${item.longitude}`);
                        area.editArea($scope.data).then(function (res) {
                            if (res.status == "SUCCESS") {
                                event.data.serviceability = !event.data.serviceability;
                                event.api.refreshCells({ rowNodes: [event.node], force: true });
                            }
                        });
                    }
                }
            }; //End of gridOptions//


            function AmountCellRenderer() {
            }

            AmountCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // let val= params.value?(params.value).toFixed(2):'-';
                    let val = params.value ? $scope.inrFilter(params.value) : '0.00';
                    let iconsDiv = "<div> " + val + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                } //inr symbol - &#x20b9;
            };

            AmountCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            function EditCellRenderer() {
            }

            EditCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                this.eGui.style.textAlign = 'center';
                console.log("what are the edit params", params.data);
                if (params.data != "" || params.data != undefined || params.data != null) {
                    console.log("GETIING INNNNNN")
                    // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";
                    let iconsDiv = `<i class="md md-mode-edit"></i>`;
                    this.eGui.innerHTML = iconsDiv;
                    this.eGui.setAttribute('title', 'Edit lead')
                }
            };
            EditCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            function DeleteCellRenderer() {
            }

            DeleteCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                this.eGui.style.textAlign = 'center';
                // console.log("what are the params", params);
                if (params.value !== "" || params.value !== undefined || params.value !== null) {
                    // var iconsDiv="<div style=\"display: inline-table\" ng-click=\"boxClicked(warehouse,$index)\"><div class=\"valign\"><img uib-tooltip=\"{{warehouse.sales[0].itemName}}\" ng-cache src=" + params.value[0]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[1].itemName}}\" ng-cache src="+params.value[1]+".jpg\" class=\"cat-imgs\"><img uib-tooltip=\"{{warehouse.sales[2].itemName}}\" ng-cache src="+params.value[2]+".jpg\" class=\"cat-imgs\"></div></div>";

                    let iconsDiv = `<i class="md md-delete"></i>`;
                    this.eGui.innerHTML = iconsDiv;
                    this.eGui.setAttribute('title', 'Delete lead')
                }
            };
            DeleteCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            function DateCellRenderer() {
            }

            DateCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                if (params.data != "" || params.data != undefined || params.data != null) {
                    // console.log("params.data.createdAt = ", params.data.createdAt);
                    let dateModified = $moment(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss');
                    // console.log("dateModified = ", dateModified);
                    var iconsDiv = "<div>" + dateModified + "</div>";
                    this.eGui.innerHTML = iconsDiv;
                }
            };
            function ToggleActionCellRenderer() {
            }

            ToggleActionCellRenderer.prototype.init = function (params) {
                this.eGui = document.createElement('div');
                this.eGui.style.textAlign = 'center';
                if (params.value !== "" || params.value !== undefined || params.value !== null) {

                    // let 
                    $scope.actionButtonClassList = "";

                    let className = params.data.serviceability ? 'toggle-active' : 'fa-rotate-180 toggle-inactive';
                    let iconsDiv = `<i class="fa fa-toggle-on ` + className + `" style="font-size: 23px"
                    ng-click="toggleClicked(\'`+ params.data.serviceability + `\')"></i>`;

                    this.eGui.innerHTML = iconsDiv;
                }
            };

            ToggleActionCellRenderer.prototype.getGui = function () {
                return this.eGui;
            };

            function ToggleFieldActionCellRenderer() {
            }
        })();

        (function () {

            $scope.loadData = function () {

                let data = {
                    "fromDate": $scope.vm.params.fromdate,
                    "toDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                };


                area.getArea().then(function (res) {
                    if (res.status.toUpperCase() == "SUCCESS") {
                        $scope.area = res.data.area;
                        $scope.gridOptions.api.setRowData($scope.area);
                        console.log($scope.area)
                        var avgCenter = { lat: 0, lng: 0 }
                        $scope.area.forEach(function (element, index) {
                            var center = polygonCenter(element.areaCoordinates);
                            avgCenter.lat += center.lat();
                            avgCenter.lng += center.lng();
                            var arry = [];
                            element.areaCoordinates.forEach(function (elem, idx) {
                                if (elem.latitude != null && elem.longitude != null) {
                                    arry.push(new google.maps.LatLng(elem.latitude, elem.longitude));
                                }
                            });
                            if (arry != null) {
                                element.LatLngObj = arry;
                            }
                            var color = getRandomColor();
                            var polygonObj = new google.maps.Polygon({
                                paths: element.LatLngObj,
                                strokeColor: color,
                                strokeOpacity: 0.8,
                                strokeWeight: 3,
                                fillColor: color,
                                fillOpacity: 0.2
                            });
                            polygonObj.setMap($scope.map);
                            var marker = new google.maps.Marker({
                                map: $scope.map,
                                position: center,
                                label: { text: element.area, color: color, fontSize: "18px", fontWeight: "600" },
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 0
                                }
                            });
                        });
                        $scope.center = { lat: avgCenter.lat / $scope.area.length, lng: avgCenter.lng / $scope.area.length }
                        $scope.map.setCenter($scope.center);
                    } else {
                        if (res.status.toUpperCase() == "FAILURE" && res.error && res.error.code == "EC_MAX_DATE_RANGE_EXCEEDED") {
                            growl.error(res.error.message);
                        } else {
                            $scope.reports = [];
                            $scope.gridOptions.api.setRowData($scope.reports);
                        }
                    }
                })
            }
            $scope.loadData();

        })();


        $scope.addArea = function () {
            var data = {};
            data.title = "ADD AREA"
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/add-area.html',
                controller: 'AddAreaCtrl',
                size: "lg",
                resolve: {
                    items: function () {
                        return data;
                    }
                }
            });
            pickupInstance.result.then(function () {
                $global.refresh($state);
            }, function () {
                $global.refresh($state);
            });
        };

        $scope.editArea = function (editObj) {
            var data = editObj;
            data.title = "Edit Area"
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/add-area.html',
                controller: 'AddAreaCtrl',
                size: "lg",
                resolve: {
                    items: function () {
                        return data;
                    }
                }
            });
            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $global.refresh($state);
            }, function () {
                $global.refresh($state);
            });
        };

        $scope.deleteArea = function (editObj) {
            var data = editObj;
            var pickupInstance = $uibModal.open({
                templateUrl: 'views/delete-area.html',
                controller: 'DeleteAreaCtrl',
                size: "md",
                resolve: {
                    items: function () {
                        return data;
                    }
                }
            });
            pickupInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
                $global.refresh($state);
            }, function () {
                $global.refresh($state);
            });
        };

    });
