'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:AddSalesAgentCtrl
 * @description
 * # AddSalesAgentCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
  .controller('AddSalesAgentCtrl', function($scope, authentication, salesAgentData, $moment, $global, growl, $uibModalInstance, $state, $timeout, NgMap, $rootScope, salesAgentService) {
    $scope.data = {};
    $scope.vm = {};
    $scope.location = {
      latitude: 0,
      longitude: 0
    };
    $scope.title = "ADD"
    
    if (salesAgentData) {
      $scope.title = "EDIT"
      console.log("Is it coming from items??",salesAgentData);
      $scope.data.name = salesAgentData.name;
      $scope.data.phone = salesAgentData.mobileNo;
      $scope.data.password = salesAgentData.password;
      $scope.data.email = salesAgentData.email;
      $scope.data.userId = salesAgentData.userId;
      $scope.vm.formattedAddress = salesAgentData.address.formattedAddress;
      var center = [];
      center.push(salesAgentData.address.latitude);
      center.push(salesAgentData.address.longitude);
      $scope.center = center.join();
      $scope.location = {
        latitude: salesAgentData.address.latitude,
        longitude: salesAgentData.address.longitude
      };
      // $scope.chosenPlace = salesAgentData.address.address;
    }

    var citymatcher = "";
    if ($rootScope.allCityData && $rootScope.allCityData.length > 0) {
      $rootScope.allCityData.forEach(function(elem, idx) {
        if (elem.name === $rootScope.loginData.city) {
          if (citymatcher) {
            citymatcher += "|"
          }
          citymatcher += elem.subarea;
        }
      });
    }
    
    function setPlaceObject(latlng) {
      $global.getLocationByLatLng(latlng).then(function(res) {
        console.log("$global.getLocationByLatLng",res);
        $scope.place = res;
        $scope.vm.formattedAddress = res.formatted_address;
        $scope.chosenPlace = res.formatted_address;
      })
    }
    
    function reRenderMap() {
      $timeout(function() {
        angular.forEach($scope.maps, function(map) {
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      }, 500);
    }
    
    $scope.setCurrentLocation = function() {
      console.log("is setcurrentlocation being called");
      $global.getCurrentLocation().then(function(res) {
        $scope.center = $scope.getLatLng(res);
        var rem = {};
        $scope.location.latitude = rem.lat = res.latitude;
        $scope.location.longitude = rem.lng = res.longitude;
        // TODO - set formatted address
        //setPlaceObject(rem);
        reRenderMap();
      });
    };
    
    $scope.getLatLng = function(obj) {
      
      if (obj && obj.latitude && obj.longitude) {
        var latLng = [];
        latLng.push(obj.latitude);
        latLng.push(obj.longitude);
        return latLng.join();
      }
      
    };
    //$scope.setCurrentLocation();
    
    $scope.placeChanged = function() {
      if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
        $scope.place = this.getPlace();
        var obj = {};
        obj.lat = $scope.place.geometry.location.lat();
        obj.lng = $scope.place.geometry.location.lng();
        $scope.setLocation(obj);
      } else {
        growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
        $scope.vm.formattedAddress = "";
        $scope.chosenPlace = "";
      }       
    };
    
    $scope.setLocation = function(obj) {
      
      var center = [];
      center.push(obj.lat);
      center.push(obj.lng);
      $scope.center = center.join();
      $scope.chosenPlace = angular.copy($scope.vm.formattedAddress);
    };
    
    var map;
    $scope.maps = [];
    $scope.$on('mapInitialized', function(evt, evtMap) {
      $scope.maps.push(evtMap);
      
    });
    
    
    NgMap.getMap().then(function(evtMap) {
      map = evtMap;
    });
    $scope.setLocations = function() {
      if ($scope.locationadd) {
      
      }
    };
    $scope.setCurrentLocation();
    $scope.markerDragEnd = function(event) {

      console.log("Is $scope.markerDragEnd getting called when dragged???",event);
      
      $scope.location.latitude = event.latLng.lat();
      $scope.location.longitude = event.latLng.lng();
      
      $timeout(function() {
        var latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        setPlaceObject(latlng);
        $scope.center = $scope.getLatLng(latlng);
        
      })
    };

    $scope.cancel = function() {
      $state.go($state.current, {}, { reload: true });
      $uibModalInstance.close('Close');
    };
    
    $scope.addSalesAgent = function(address, latitude, longitude) {
      $scope.categories = [];
      $scope.categories.push($scope.category);
      
      $scope.catalog = "Please select any one category";
      $scope.data.registerType = "CRM";
      $scope.data.userType = "SALESAGENT"
      $scope.data.city = $rootScope.loginData.city;
      
      $scope.data.address = {};
      $scope.data.address.formattedAddress = $scope.vm.formattedAddress;
      $scope.data.address.latitude = $scope.location.latitude;
      $scope.data.address.longitude = $scope.location.longitude;
      
      $global.setLocalItem("registration", $scope.authorization, true);
      $scope.submitted = true;
      if ($scope.addUserForm.$valid) {
        var geocoder = new google.maps.Geocoder();
        //First Geocode
        geocoder.geocode({'address': address}, function(results, status) {
          //console.log(google.maps.GeocoderStatus.OK);
          if (status === google.maps.GeocoderStatus.OK) {
            const geoDetails = results[0];
            // set address object 
            for (var i = geoDetails.address_components.length - 1; i >= 0; i--) {
              if (geoDetails.address_components[i].types[0] == "locality") {
                $scope.data.address.locality = geoDetails.address_components[i].long_name;
              }
              if (geoDetails.address_components[i].types[0] == "administrative_area_level_1") {
                $scope.data.address.state = geoDetails.address_components[i].long_name;
              }
              if (geoDetails.address_components[i].types[0] == "country") {
                $scope.data.address.country = geoDetails.address_components[i].long_name;
              }
              if (geoDetails.address_components[i].types[0] == "postal_code") {
                $scope.data.address.postalCode = geoDetails.address_components[i].long_name;
              }
            };
            if ($scope.title === "ADD") {
              //Register sales agent
              salesAgentService.addSalesAgent($scope.data).then(function(res) {
                if (res.status === "Success") {
                  growl.success("Successfully added the sales agent");
                  $scope.cancel();
                } else {
                  growl.error("Unable to add the sales agent");
                  console.error(res);
                }
              }).catch((function(err) {
                console.error(err);
              }));
            } else if ($scope.title === "EDIT") {
              if ($scope.data.password) {
                $scope.data.sendSMS = $scope.data.sendSMS ? true : false;
              }
              salesAgentService.editSalesAgent($scope.data).then(function(res) {
                if (res.status === "Success") {
                  growl.success("Successfully updated the sales agent");
                  $scope.cancel();
                } else {
                  growl.error("Unable to update the sales agent");
                  console.error(res);
                }
              }).catch((function(err) {
                console.error(err);
              }));
              
            }
          } else {
            growl.error("Failed to find lat,long for the address, please enter a better address");
          }
          
        });
      }
    };
    
    $scope.addLocation = function(address, latitude, longitude) {
      
      var addresses = $global.getAddressObj(address);
      
      //override geocoded lat, long
      if (latitude) {
        addresses.latitude = latitude;
        addresses.longitude = longitude
      }
      
      addresses.userId = $scope.userid;
      addresses.userType = $scope.data.userType;
      addresses.landmark = $scope.data.landmark;
      addresses.formattedAddress = ($scope.vm.customadd !== '' && $scope.vm.customadd !== undefined) ? $scope.vm.customadd + ', ' + $scope.vm.formattedAddress : $scope.vm.formattedAddress;
      //addresses.formattedAddress = address.formatted_address;
      authentication.saveCosumerAddress(addresses).then(function(res) {
        console.log('res : ',res);
        // $scope.data.addressId = res.data.address.addressId;
        // growl.success(res.data.message)
        // $uibModalInstance.dismiss('ok');
      })
      
    };

    $scope.searchForLocation = function(){
			let data=$scope.vm.formattedAddress;
			console.log("$scope.vm.formattedAddress",$scope.vm.formattedAddress);
			authentication.getLocations(data).then(function(res){
				console.log("response",res);
				$scope.predictions=res.predictions;
				$scope.showPredictions=true;
			}).catch(function(err){
				console.log("err",err);
			})
		};

      $scope.locationSelected = function (obj) {
          console.log("clicked item", obj);
          $scope.vm.formattedAddress = obj.description;
          $scope.showPredictions = false;
          authentication.getLatLng(obj.place_id).then(function (res) {
              console.log("response", res);
              if ($scope.vm.formattedAddress && $scope.vm.formattedAddress.match(new RegExp(citymatcher, 'i'))) {
                  let place = res.result;
                  let obj = {};
                  $scope.location.latitude = obj.lat = place.geometry.location.lat;
                  $scope.location.longitude = obj.lng = place.geometry.location.lng;
                  $scope.setLocation(obj);
              } else {
                  growl.error("Address: " + $scope.vm.formattedAddress + " do not contain the city: " + citymatcher.replace("|", " or "));
                  $scope.vm.formattedAddress = "";
                  $scope.chosenPlace = "";
              }
          }).catch(function (err) {
              console.log("err", err);
          });
      }
  });
