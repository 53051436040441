'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:DailySummaryReportController
 * @description
 * # ConsumersCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('DailySummaryReportController', function ($scope, $rootScope, $filter, authentication, $global, $stateParams, $http, growl, consumer, $moment) {
        $scope.inrFilter = $filter('INR');

        // $scope.dateInput = $moment().format("DD-MMM-YYYY");
        $scope.date = {
            startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
            endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
        };

        $scope.vm = {
            params: { "city": $rootScope.loginData.city }
        };

        $rootScope.loginData = $global.getLocalItem("loginData", true);
        $scope.resetCountLabels = function () {
            $scope.labelCounts = {
                "totalUserCount": 0,
                "totalCorporateUserCount": 0,
                "totalResidentialUserCount": 0,
                "newUsersCount": 0,
                "newCorporateUsersCount": 0,
                "newResidentialUsersCount": 0,
                "newCRMUsersCount": 0,
                "newWebsiteUsersCount": 0,
                "newWhatsappUsersCount": 0,
                "newConsumerAppUsersCount": 0,
                "newAgentAppUsersCount": 0,
                "newCorporateCRMUsersCount": 0,
                "newCorporateWebsiteUsersCount": 0,
                "newCorporateWhatsappUsersCount": 0,
                "newCorporateConsumerAppUsersCount": 0,
                "newCorporateAgentAppUsersCount": 0,
                "newResidentialCRMUsersCount": 0,
                "newResidentialWebsiteUsersCount": 0,
                "newResidentialWhatsappUsersCount": 0,
                "newResidentialConsumerAppUsersCount": 0,
                "newResidentialAgentAppUsersCount": 0,
                "totalAppointmentsCount": 0,
                "totalCorporateAppointmentsCount": 0,
                "totalResidentialAppointmentsCount": 0,
                "totalCompletedAppointmentsCount": 0,
                "totalCompletedCorporateAppointmentsCount": 0,
                "totalCompletedResidentialAppointmentsCount": 0,
                "totalCancelledAppointmentsCount": 0,
                "totalCancelledCorporateAppointmentsCount": 0,
                "totalCancelledResidentialAppointmentsCount": 0,
                "totalLateAppointmentsCount": 0,
                "totalLateCorporateAppointmentsCount": 0,
                "totalLateResidentialAppointmentsCount": 0,
                "totalGTV": 0,
                "totalCorporateGTV": 0,
                "totalResidentialGTV": 0,
                ////////////////////////
                "totalCRMUsersCount": 0,
                "totalWebsiteUsersCount": 0,
                "totalWhatsappUsersCount": 0,
                "totalConsumerAppUsersCount": 0,
                "totalAgentAppUsersCount": 0,
                "totalCorporateCRMUsersCount": 0,
                "totalCorporateWebsiteUsersCount": 0,
                "totalCorporateWhatsappUsersCount": 0,
                "totalCorporateConsumerAppUsersCount": 0,
                "totalCorporateAgentAppUsersCount": 0,
                "totalResidentialCRMUsersCount": 0,
                "totalResidentialWebsiteUsersCount": 0,
                "totalResidentialWhatsappUsersCount": 0,
                "totalResidentialConsumerAppUsersCount": 0,
                "totalResidentialAgentAppUsersCount": 0
            };

        }
        $scope.resetCountLabels();

        $scope.feildsAccessObj = {};

        (function () {

            var data = {
                "city": $rootScope.loginData.city,
                "userType": $rootScope.loginData.role,
                "module": "Reports",
                "subModule": "DailyDashboard",
                "type": "Other"
            };

            authentication.getUserRoleMetrics(data).then(function (res) {
                if (res.status == "SUCCESS") {
                    $scope.disabledFeilds = res.data.userRoleMatrix[0].disabledFields;
                    $scope.disabledFeilds.forEach(function (key) {
                        $scope.feildsAccessObj[key] = true;
                    });
                    console.log($scope.feildsAccessObj);
                } else {
                    $scope.disabledFeilds = [];
                    $scope.feildsAccessObj = {};
                }
            }).catch(function (err) {
                $scope.disabledFeilds = [];
                $scope.feildsAccessObj = {};
            })
        })();





        $scope.chartConfig = {};


        $scope.getCustomerGraphData = function () {
            let graphDataQuery = {
                "fromDate": $scope.vm.params.fromdate,
                "toDate": $scope.vm.params.todate,
                "city": $rootScope.loginData.city,
                "periodicity": $scope.periodicity
            }


            consumer.getCustomerGraphData(graphDataQuery).then(function (res) {
                if (res.status.toUpperCase() == "SUCCESS") {
                    $scope.chartConfig.customer = true;
                    let rows = $scope.chartRows(res.data.reports, "CUSTOMERS")
                    $scope.initializeChart($scope.customersColumns, "chart_div_customers", "Number of Customers", rows, "");
                } else {
                    $scope.chartConfig.customer = false;
                    $scope.initializeChart($scope.customersColumns, "chart_div_customers", "Number of Customers");
                }
            })
        }

        $scope.getSellsGraphData = function () {
            let graphDataQuery = {
                "fromDate": $scope.vm.params.fromdate,
                "toDate": $scope.vm.params.todate,
                "city": $rootScope.loginData.city,
                "periodicity": $scope.periodicity
            }
            consumer.getSellsGraphData(graphDataQuery).then(function (res) {
                if (res.status.toUpperCase() == "SUCCESS") {
                    $scope.chartConfig.sellsAndGtv = true;

                    let rowsAppointments = $scope.chartRows(res.data.reports, "APPOINTMENTS");
                    let rowsGtv = $scope.chartRows(res.data.reports, "GTV");

                    $scope.initializeChart($scope.appointmentsColumns, "chart_div_sells", "Number of Appointments", rowsAppointments, "");
                    $scope.initializeChart($scope.gtvColumns, "chart_div_gtv", "GTV", rowsGtv, "");

                } else {
                    $scope.chartConfig.sellsAndGtv = false;

                    $scope.initializeChart($scope.appointmentsColumns, "chart_div_sells", "Number of Appointments");
                    $scope.initializeChart($scope.gtvColumns, "chart_div_gtv", "GTV");
                }
            })
        };

        $scope.periodicityChanged = function () {
            $scope.getCustomerGraphData();
            $scope.getSellsGraphData();
        }


        $scope.periodicity = "Weekly";
        $scope.periodicityList = ["Monthly", "Weekly", "Daily"];

        $scope.customersColumns = ["Residential Customers", "Corporate Customers"];
        $scope.appointmentsColumns = ["Residential Appointments", "Corporate Appointments"];
        $scope.gtvColumns = ["Residential GTV", "Corporate GTV"];


        $scope.getPeriodicity = function () {
            if ($scope.periodicity == "Weekly")
                return "Weeks"
            if ($scope.periodicity == "Monthly")
                return "Months"
            if ($scope.periodicity == "Daily")
                return "Days"
        }


        $scope.initializeChart = function (columns, chart_div_id, y_axis_name, rows, title) {
            // Google Graphs
            function drawStacked() {
                var data = new google.visualization.DataTable();
                data.addColumn('string', 'Periodicity');
                columns.forEach(function (column) {
                    data.addColumn('number', column);
                })

                data.addRows(rows);

                var options = {
                    title: title,
                    isStacked: true,
                    hAxis: {
                        title: $scope.getPeriodicity(),
                        baseline: 0
                    },
                    vAxis: {
                        title: y_axis_name,
                        gridlines: { count: 3 }

                    },
                    height: 500
                };

                if (y_axis_name === "Number of Customers") {
                    let tmp = options.hAxis;
                    options.hAxis = options.vAxis;
                    options.vAxis = tmp;
                    let chart = new google.visualization.BarChart(document.getElementById(chart_div_id));
                    chart.draw(data, options);
                } else {
                    let chart = new google.visualization.ColumnChart(document.getElementById(chart_div_id));
                    chart.draw(data, options);
                }
            }
            google.charts.load('current', { packages: ['corechart', 'bar'] });
            google.charts.setOnLoadCallback(drawStacked);
            //Google Graphs End
        };

        (function () {

            $scope.loadData = function () {
                let data = {
                    "fromDate": $scope.vm.params.fromdate,
                    "toDate": $scope.vm.params.todate,
                    "city": $rootScope.loginData.city
                };
                consumer.getDailySummaryReport(data).then(function (res) {
                    if (res.status.toUpperCase() == "SUCCESS") {
                        $scope.labelCounts = res.data.report;
                        let keys = Object.keys($scope.labelCounts);

                        keys.forEach(function (key) {
                            $scope.labelCounts[key] = Number($scope.labelCounts[key]);
                        })

                    } else {
                        $scope.resetCountLabels();
                    }
                });
                $scope.getCustomerGraphData();
                $scope.getSellsGraphData();
            }
            $scope.loadData();

        })();


        $scope.chartRows = function (reports, chartType) {
            let rows = [];
            if (chartType == "CUSTOMERS") {
                reports.forEach(function (obj) {
                    rows.push([{ f: obj.date.toString() }, obj.newResidentialUsersCount, obj.newCorporateUsersCount])
                })
            }
            if (chartType == "GTV") {
                reports.forEach(function (obj) {
                    rows.push([{ f: obj.date.toString() }, obj.totalResidentialGTV, obj.totalCorporateGTV])
                })
            }
            if (chartType == "APPOINTMENTS") {
                reports.forEach(function (obj) {
                    rows.push([{ f: obj.date.toString() }, obj.totalCompletedResidentialAppointmentsCount, obj.totalCompletedCorporateAppointmentsCount])
                })
            }

            return rows;
        }



        $scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Apply",
                fromLabel: "From",
                format: "DD-MMM-YYYY",
                toLabel: "To",
                cancelLabel: 'Cancel',
                customRangeLabel: 'Custom range'
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')]
            }
        };
        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        //Watch for date changes
        $scope.$watch('date', function (newDate) {
            $scope.selectedRange = $('#daterange3').data('daterangepicker').chosenLabel;

            // $scope.transactionChange();
            let months = monthDiff(new Date($scope.date.startDate), new Date($scope.date.endDate));
            if (months <= 6) {
                $scope.transactionChange();
            } else {
                growl.error("Invalid date range, max allowed date range is 6 months only");
                $scope.date = {
                    startDate: $moment($stateParams.fromdate, 'DD-MMM-YYYY').toDate(),
                    endDate: $moment($stateParams.todate, 'DD-MMM-YYYY').toDate()
                };
            }
        }, false);
        $scope.transactionChange = function () {

            $scope.vm.params.singledayquery = false;
            $scope.totalAgentRecords = 0;
            // $scope.currentPage = 1;
            $scope.vm.params.page = -1;

            if ($moment($scope.date.startDate).isValid() && $moment($scope.date.startDate).year() > 2010 && $moment($scope.date.endDate).isValid() && $moment($scope.date.endDate).year() > 2010) {
                $scope.vm.params.fromdate = $moment($scope.date.startDate).format('DD-MMM-YYYY');
                $scope.vm.params.todate = $moment($scope.date.endDate).format('DD-MMM-YYYY');

                if ($scope.vm.params.todate === $scope.vm.params.fromdate) {
                    $scope.vm.params.singledayquery = true;
                }
                $scope.loadData();
            } else {
                console.error("Invalid date, please select valid date range");
                console.error("startDate = ", $scope.date.startDate);
                console.error("endDate = ", $scope.date.endDate);
                growl.error("Invalid date, please select valid date range");
            }
        };
        $scope.nextDate = function () {
            $scope.paging = false;
            $scope.date = $global.getNextDateRanges($scope.selectedRange, $scope.date.endDate);
            $scope.transactionChange();
        }
        $scope.previousDate = function () {
            $scope.paging = false;
            $scope.date = $global.getPrevDateRanges($scope.selectedRange, $scope.date.startDate);
            $scope.transactionChange();
        }

        $scope.cancel = function (res) {
            $uibModalInstance.dismiss(res);
        };

        $scope.createGoogleGraphUrl = function (data, title) {
            const myUrlWithParams = new URL("https://chart.googleapis.com/chart?");

            myUrlWithParams.searchParams.append("chtt", title ? title : "");
            myUrlWithParams.searchParams.append("cht", "lc:nda");
            myUrlWithParams.searchParams.append("chs", "400x250");
            myUrlWithParams.searchParams.append("chd", "t:" + data);
            myUrlWithParams.searchParams.append("chds", "a");
            myUrlWithParams.searchParams.append("chxt", "y");
            myUrlWithParams.searchParams.append("chxs", "1,888888,13,1,t%7C0,888888,13,1,t");
            myUrlWithParams.searchParams.append("chma", "30,30,30,30");
            myUrlWithParams.searchParams.append("chco", "008000");

            return myUrlWithParams.href;

        }

        $scope.chartUrls = {};

        // $scope.getUsersCountGraph = function (data) {
        //     let newUsers = [];
        //     let newCorpUsers = [];
        //     let newResidentialUsers = [];

        //     data.forEach(function (obj) {
        //         newUsers.push(obj.newUsersCount);
        //         newCorpUsers.push(obj.newCorporateUsersCount);
        //         newResidentialUsers.push(obj.newResidentialUsersCount);
        //     });

        //     newUsers = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340]
        //     newCorpUsers = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].reverse()
        //     newResidentialUsers = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].concat(newCorpUsers)
        //     if (newUsers.length > 0)
        //         $scope.chartUrls.newUsersGraph = $scope.createGoogleGraphUrl(newUsers.join(), "New Customer Registrations");
        //     else
        //         $scope.chartUrls.newUsersGraph = "";
        //     if (newCorpUsers.length > 0)
        //         $scope.chartUrls.newCorpUsersGraph = $scope.createGoogleGraphUrl(newCorpUsers.join(), "Residential Customers");
        //     else
        //         $scope.chartUrls.newCorpUsersGraph = "";
        //     if (newResidentialUsers.length > 0)
        //         $scope.chartUrls.newResidentialUsersGraph = $scope.createGoogleGraphUrl(newResidentialUsers.join(), "Corporate Customers");
        //     else
        //         $scope.chartUrls.newResidentialUsersGraph = "";

        //     console.log($scope.chartUrls);

        // };

        // $scope.getSellsCountGraph = function (data) {
        //     let totalSells = [];
        //     let totalCorpSells = [];
        //     let totalResidentialSells = [];

        //     let totalGtv = [];
        //     let totalCorpGtv = [];
        //     let totalResidentialGtv = [];

        //     data.forEach(function (obj) {
        //         totalSells.push(obj.totalCompletedAppointmentsCount);
        //         totalCorpSells.push(obj.totalCompletedCorporateAppointmentsCount);
        //         totalResidentialSells.push(obj.totalCompletedResidentialAppointmentsCount);

        //         totalGtv.push(obj.totalGTV);
        //         totalCorpGtv.push(obj.totalCorporateGTV);
        //         totalResidentialGtv.push(obj.totalResidentialGTV);
        //     });

        //     // totalSells = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340]
        //     // totalCorpSells = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].reverse()
        //     // totalResidentialSells = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].concat(totalCorpSells);


        //     // totalGtv = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340]
        //     // totalCorpGtv = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].reverse()
        //     // totalResidentialGtv = [100, 20, 3, 45, 90, 56, 78, 44, 76, 54, 340].concat(totalCorpGtv);


        //     if (totalSells.length > 0)
        //         $scope.chartUrls.totalSellsGraph = $scope.createGoogleGraphUrl(totalSells.join(), "Total Completed Appointments");
        //     else
        //         $scope.chartUrls.totalSellsGraph = "";

        //     if (totalCorpSells.length > 0)
        //         $scope.chartUrls.corpSellsGraph = $scope.createGoogleGraphUrl(totalCorpSells.join(), "Corporate Appointments");
        //     else
        //         $scope.chartUrls.corpSellsGraph = "";

        //     if (totalResidentialSells.length > 0)
        //         $scope.chartUrls.residentialSellsGraph = $scope.createGoogleGraphUrl(totalResidentialSells.join(), "Residential Appointments");
        //     else
        //         $scope.chartUrls.residentialSellsGraph = "";


        //     if (totalGtv.length > 0)
        //         $scope.chartUrls.totalGtvGraph = $scope.createGoogleGraphUrl(totalGtv.join(), "Total GTV");
        //     else
        //         $scope.chartUrls.totalGtvGraph = "";

        //     if (totalCorpGtv.length > 0)
        //         $scope.chartUrls.corpGtvGraph = $scope.createGoogleGraphUrl(totalCorpGtv.join(), "Corporate GTV");
        //     else
        //         $scope.chartUrls.corpGtvGraph = "";

        //     if (totalResidentialGtv.length > 0)
        //         $scope.chartUrls.residentialGtvGraph = $scope.createGoogleGraphUrl(totalResidentialGtv.join(), "Residential GTV");
        //     else
        //         $scope.chartUrls.residentialGtvGraph = "";



        //     console.log($scope.chartUrls);

        // };

    });
