'use strict';

/**
 * @ngdoc function
 * @name scrapQApp.controller:HeaderCtrl
 * @description
 * # HeaderCtrl
 * Controller of the scrapQApp
 */
angular.module('scrapQcrmApp')
    .controller('HeaderCtrl', function ($scope, $rootScope, $moment, $global, authentication, $state, growl) {

        $scope.gotoPendingRequests = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.pending-requests', { fromdate: currentDate, todate: currentDate });
        }
        // $scope.logo = $rootScope.brandLogo;
        // document.getElementById('image').src = $rootScope.brandLogo;
        $scope.gotoUnassignedRequests = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.unassigned', { fromdate: currentDate, todate: currentDate });
        }
        $scope.gotoCompletedRequests = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.completed', { fromdate: currentDate, todate: currentDate });
        }
        $scope.gotoAppointments = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.appointments', { fromdate: currentDate, todate: currentDate });
        }

        $scope.loadResidenceConsumers = function () {
            $state.go('root.consumer', { type: "residence", pageTitle: "residence-customers" });
        }

        $scope.loadBusinessConsumers = function () {
            $state.go('root.consumer', { type: "business",pageTitle:"corporate-customers" });
        }

        $scope.loadInvalidConsumers = function () {
            $state.go('root.invalidCustomers', { type: "invalid" });
        }

        $scope.loadConsumers = function () {
            $state.go('root.consumer', { type: null, pageTitle: "all-customers" });
        }
        $scope.loadSalesReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.sales-reports', { fromdate: currentDate, todate: currentDate });
        }
        $scope.loadAppointmentWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.appointment-wise-reports', { fromdate: currentDate, todate: currentDate });
        }

        $scope.loadAgentWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.agent-wise-reports', { fromdate: currentDate, todate: currentDate });
        }
        $scope.loadProductWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.product-wise-reports', { fromdate: currentDate, todate: currentDate });
        }
        $scope.wareHouseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.warehouse-wise-reports', { fromdate: currentDate, todate: currentDate });
        }
        $scope.loadCustomerWiseReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.customer-address-wise-reports', { fromdate: currentDate, todate: currentDate });
        }
        $scope.loadAccountingReports = function () {
            var currentDate = $moment().format('DD-MMM-YYYY');
            $state.go('root.accounting-reports', { fromdate: currentDate, todate: currentDate });
        }



        // Variables
        // ------------------------------
        var headerHeight = 56;

        // ------------------------------
        // Browser Detection Plugin
        // https://github.com/gabceb/jquery-browser-plugin/
        // ------------------------------
        $scope.searchAgent = function (res) {
            $scope.submitted = true;
            if ($scope.search.$valid) {
                $state.go("root.agents", { id: res })
            }
        }
        $scope.crmlogout = function () {
            $global.removeLocalItem("authentication");
            authentication.logout().then(function (res) {
                growl.success("Logged out successfully!");
                $scope.userData = $global.getLocalItem("authentication", true);
                $global.removeLocalItem("authentication");
                $global.init();
                setUserdata();
                $state.go("login");

            });
            console.log($global.getLocalItem("authentication", true))
        }
        /**
         * Theme: Ubold Admin Template
         * Author: Coderthemes
         * Module/App: Main Js
         */


        ! function ($) {
            "use strict";

            var Sidemenu = function () {
                this.$body = $("body"),
                    this.$openLeftBtn = $(".open-left"),
                    this.$menuItem = $("#sidebar-menu a")
            };
            Sidemenu.prototype.openLeftBar = function () {
                $("#wrapper").toggleClass("enlarged");
                $("#wrapper").addClass("forced");

                if ($("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left")) {
                    // When the navbar is minimized
                    $("body").removeClass("fixed-left").addClass("fixed-left-void");
                    $(".side-menu-div").wrap('<li class="li-n"></li>');
                    $(".side-menu-div").addClass("sm-d");
                    $(".side-menu-div").removeClass("side-menu-div");
                    $(".subdrop").removeClass("subdrop");
                    $(".bdr-l-g").removeClass("bdr-l-g");
                    $(".arw-right-t").removeClass("arw-right-t");
                } else if (!$("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left-void")) {
                    // When the navbar is maximized
                    $("body").removeClass("fixed-left-void").addClass("fixed-left");
                    $(".sm-d").addClass("side-menu-div");
                    $(".sm-d").removeClass("sm-d");
                }

                if ($("#wrapper").hasClass("enlarged")) {
                    $(".left ul").removeAttr("style");
                } else {
                    $(".subdrop").siblings("ul:first").show();
                }

                toggle_slimscroll(".slimscrollleft");
                $("body").trigger("resize");
            },
                //menu item click
                Sidemenu.prototype.menuItemClick = function (e) {
                    if (!$("#wrapper").hasClass("enlarged")) {
                        if ($(this).parent().hasClass("has_sub")) {
                            e.preventDefault();
                        }
                        if (!$(this).hasClass("subdrop")) {
                            // hide any open menus and remove all other classes
                            $("ul", $(this).parents("ul:first")).slideUp(350);
                            $("a", $(this).parents("ul:first")).removeClass("subdrop");
                            $("a, div").removeClass("bdr-l-g");
                            $("a", $(this).parents("ul:first")).children("span").children("i").removeClass("arw-right-t");
                            $("#sidebar-menu .pull-right i").removeClass("md-remove").addClass("md-add");

                            // open our new menu and add the open class
                            $(this).next("ul").slideDown(350);
                            $(this).addClass("subdrop");
                            $(this).addClass("bdr-l-g");
                            $(this).parent("li").parent("div").addClass("bdr-l-g");
                            $(this).children("span").children("i").addClass("arw-right-t");
                            $(".pull-right i", $(this).parents(".has_sub:last")).removeClass("md-add").addClass("md-remove");
                            $(".pull-right i", $(this).siblings("ul")).removeClass("md-remove").addClass("md-add");
                        } else if ($(this).hasClass("subdrop")) {
                            // hide our already open menu and remove its classes
                            $(this).removeClass("subdrop");
                            $("a, div").removeClass("bdr-l-g");
                            $(this).children("span").children("i").removeClass("arw-right-t");
                            $(this).next("ul").slideUp(350);
                            $(".pull-right i", $(this).parent()).removeClass("md-remove").addClass("md-add");
                        }
                    }
                },

                //init sidemenu
                Sidemenu.prototype.init = function () {
                    var $this = this;
                    //bind on click
                    $(".open-left").click(function (e) {
                        e.stopPropagation();
                        $this.openLeftBar();
                    });

                    // LEFT SIDE MAIN NAVIGATION
                    $this.$menuItem.on('click', $this.menuItemClick);

                    // NAVIGATION HIGHLIGHT & OPEN PARENT
                    $("#sidebar-menu ul li.has_sub a.active").parents("li:last").children("a:first").addClass("active").trigger("click");
                },

                //init Sidemenu
                $.Sidemenu = new Sidemenu, $.Sidemenu.Constructor = Sidemenu

        }(window.jQuery),


            function ($) {
                "use strict";

                var FullScreen = function () {
                    this.$body = $("body"),
                        this.$fullscreenBtn = $("#btn-fullscreen")
                };

                //turn on full screen
                // Thanks to http://davidwalsh.name/fullscreen
                FullScreen.prototype.launchFullscreen = function (element) {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.webkitRequestFullscreen) {
                        element.webkitRequestFullscreen();
                    } else if (element.msRequestFullscreen) {
                        element.msRequestFullscreen();
                    }
                },
                    FullScreen.prototype.exitFullscreen = function () {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        }
                    },
                    //toggle screen
                    FullScreen.prototype.toggle_fullscreen = function () {
                        var $this = this;
                        var fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled;
                        if (fullscreenEnabled) {
                            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
                                $this.launchFullscreen(document.documentElement);
                            } else {
                                $this.exitFullscreen();
                            }
                        }
                    },
                    //init sidemenu
                    FullScreen.prototype.init = function () {
                        var $this = this;
                        //bind
                        $this.$fullscreenBtn.on('click', function () {
                            $this.toggle_fullscreen();
                        });
                    },
                    //init FullScreen
                    $.FullScreen = new FullScreen, $.FullScreen.Constructor = FullScreen

            }(window.jQuery),



            //main app module
            function ($) {
                "use strict";

                var App = function () {
                    this.VERSION = "1.1.0",
                        this.AUTHOR = "Coderthemes",
                        this.SUPPORT = "coderthemes@gmail.com",
                        this.pageScrollElement = "html, body",
                        this.$body = $("body")
                };

                //on doc load
                App.prototype.onDocReady = function (e) {
                    //FastClick.attach(document.body);
                    //resizefunc.push("initscrolls");
                    //resizefunc.push("changeptype");

                    $('.animate-number').each(function () {
                        $(this).animateNumbers($(this).attr("data-value"), true, parseInt($(this).attr("data-duration")));
                    });

                    //RUN RESIZE ITEMS
                    //$(window).resize(debounce(resizeitems,100));
                    $("body").trigger("resize");

                    // right side-bar toggle
                    $('.right-bar-toggle').on('click', function (e) {
                        e.preventDefault();
                        $('#wrapper').toggleClass('right-bar-enabled');
                    });

                    $rootScope.loginData = {};
                    $rootScope.loginData = $global.getLocalItem("loginData", true);
                },
                    //initilizing
                    App.prototype.init = function () {
                        var $this = this;
                        //document load initialization
                        $(document).ready($this.onDocReady);
                        //init side bar - left
                        $.Sidemenu.init();
                        //init fullscreen
                        $.FullScreen.init();
                    },

                    $.App = new App, $.App.Constructor = App

            }(window.jQuery),

            //initializing main application module
            function ($) {
                "use strict";
                $.App.init();
            }(window.jQuery);



        /* ------------ some utility functions ----------------------- */
        //this full screen
        var toggle_fullscreen = function () {

        }

        function executeFunctionByName(functionName, context /*, args */) {
            var args = [].slice.call(arguments).splice(2);
            var namespaces = functionName.split(".");
            var func = namespaces.pop();
            for (var i = 0; i < namespaces.length; i++) {
                context = context[namespaces[i]];
            }
            return context[func].apply(this, args);
        }
        var w, h, dw, dh;
        var changeptype = function () {
            w = $(window).width();
            h = $(window).height();
            dw = $(document).width();
            dh = $(document).height();

            if (jQuery.browser.mobile === true) {
                $("body").addClass("mobile").removeClass("fixed-left");
            }

            if (!$("#wrapper").hasClass("forced")) {
                if (w > 990) {
                    $("body").removeClass("smallscreen").addClass("widescreen");
                    $("#wrapper").removeClass("enlarged");
                } else {
                    $("body").removeClass("widescreen").addClass("smallscreen");
                    $("#wrapper").addClass("enlarged");
                    $(".left ul").removeAttr("style");
                }
                if ($("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left")) {
                    $("body").removeClass("fixed-left").addClass("fixed-left-void");
                } else if (!$("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left-void")) {
                    $("body").removeClass("fixed-left-void").addClass("fixed-left");
                }

            }
            toggle_slimscroll(".slimscrollleft");
        }


        var debounce = function (func, wait, immediate) {
            var timeout, result;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) result = func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) result = func.apply(context, args);
                return result;
            };
        }

        function resizeitems() {
            if ($.isArray(resizefunc)) {
                for (i = 0; i < resizefunc.length; i++) {
                    window[resizefunc[i]]();
                }
            }
        }

        function initscrolls() {
            if (jQuery.browser.mobile !== true) {
                //SLIM SCROLL
                $('.slimscroller').slimscroll({
                    height: 'auto',
                    size: "5px"
                });

                $('.slimscrollleft').slimScroll({
                    height: 'auto',
                    position: 'right',
                    size: "5px",
                    color: '#dcdcdc',
                    wheelStep: 5
                });
            }
        }

        function toggle_slimscroll(item) {
            if ($("#wrapper").hasClass("enlarged")) {
                $(item).css("overflow", "inherit").parent().css("overflow", "inherit");
                $(item).siblings(".slimScrollBar").css("visibility", "hidden");
            } else {
                $(item).css("overflow", "hidden").parent().css("overflow", "hidden");
                $(item).siblings(".slimScrollBar").css("visibility", "visible");
            }
        }

        function setUserdata() {
            $scope.userData = ($global.authentication) ? $global.authentication : null;

        }

        //Load heat amp
        $scope.loadHeatmap = function () {
            $state.go('root.reporting', { type: 'Heatmap' });
        }

        $scope.date = $moment().format('DD-MMM-YYYY');
        //Download File
        $scope.fileUrl = encodeURI("https://iesoft.nyc3.digitaloceanspaces.com/scrapq/ScrapQ_Itemized_Report.xlsx");

        //Load acquisition statistics
        $scope.loadAcquisitionStatistics = function () {
            $state.go('root.reporting', { type: 'Acquisition Statistics' });
        }

        $scope.loadAppStats = function () {
            $state.go('root.reporting', { type: 'App Stats' });
        }
        $scope.loadCommunity = function () {
            $state.go('root.consumer', { type: 'RWA',pageTitle:"community-customers" });    
        }
    });
